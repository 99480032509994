import { useSelector } from 'react-redux';
import { RootState } from '~/types';

type LocationType = {
  pathname: string;
  search: string;
  hash: string;
  state: any | null;
  key: string;
  query: any;
  action: string;
};

const useLocation = () => {
  const { location, action } = useSelector<RootState, any>((state) => state.router);
  return {
    ...location,
    action,
  } as LocationType;
};

export default useLocation;
