import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TextField } from '~/components';
import { useExternalSource } from '~/hooks/externalSources';
import { extractItems } from '../ra/ExternalSources';

const makeLabel = (key: string): string => `components.TransactionView.externalSource.${key}`;

interface Props {
  id?: string;
  type?: string;
}

const useStyles = makeStyles(() => ({
  breakLongWords: {
    wordBreak: 'break-all',
  },
}));

const ExternalSourceTableRow = (props: { propKey: string; value: string }) => {
  const { propKey, value } = props;

  const classes = useStyles();

  return (
    <TableRow key={propKey}>
      <TableCell className={classes.breakLongWords}>{propKey}</TableCell>
      <TableCell className={classes.breakLongWords}>{value}</TableCell>
    </TableRow>
  );
};

const ExternalSourceReference: FC<Props> = (props) => {
  const { id, type } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(makeLabel(key));

  const { data: externalSource } = useExternalSource(id);

  const properties: { key: string; value: string }[] = externalSource
    ? extractItems(externalSource)
    : [];

  return (
    <>
      {type && (
        <Grid item xs={12}>
          <TextField label={t('type')} textWrap>
            {type}
          </TextField>
        </Grid>
      )}
      {externalSource && (
        <Grid item xs={12}>
          <TextField label={t('name')} textWrap>
            {externalSource.name}
          </TextField>
        </Grid>
      )}
      {properties.length > 0 && (
        <Grid item xs={12}>
          <Table>
            <TableBody>
              {properties.map((p) => (
                <ExternalSourceTableRow propKey={p.key} value={p.value} />
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </>
  );
};

export default ExternalSourceReference;
