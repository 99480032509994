import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { TextField } from '@material-ui/core';

export interface Props {
  value?: string;
  defaultValue?: number;
  label?: string;
  onChange?: (value: number | null) => void;
  [k: string]: any;
}

const NumberInput = ({ value, defaultValue, label, onChange, ...rest }: Props) => {
  const translate = useTranslate();
  const [state, setState] = useState<any>(value);

  const handleBlur = () => {
    let actualValue: number = 0;
    if (state) {
      const value = parseFloat(state + '');
      actualValue = isNaN(value) ? 0 : value;
    } else if (typeof defaultValue !== 'undefined') {
      actualValue = defaultValue;
      setState(defaultValue + '');
    }
    onChange && onChange(actualValue);
  };
  return (
    <div>
      <TextField
        {...rest}
        label={label && translate(label)}
        type="number"
        value={state + ''}
        defaultValue={defaultValue}
        onChange={(e: any) => {
          setState(e.target.value);
        }}
        onBlur={handleBlur}
        onClick={handleBlur} // hacky way to trigger blur on step-button click
      />
    </div>
  );
};

export default NumberInput;
