import React from 'react';
import { InputLabel, FormControl, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import { Autocomplete } from '@material-ui/lab';

type Option<Value> = {
  value: Value;
  text: string;
};

export interface AutocompleteInputProps<Value> {
  options: Option<Value>[];
  label: string;
  name?: string;
  value?: Value;
  onChange?: (e: any) => void;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  disableHelperText?: boolean;
  [x: string]: any;
}

function AutocompleteInput<Value>(props: AutocompleteInputProps<Value>) {
  const {
    options,
    label,
    name,
    value,
    onChange,
    className = '',
    required,
    disableHelperText,
    errorMessage,
    ...rest
  } = props;

  const classes = useStyles();

  console.log(`provided value:`, value);

  return (
    <FormControl className={cx({ [className]: className })}>
      <InputLabel variant="filled" id={'labelId' + name}>
        {label}
      </InputLabel>
      <Autocomplete
        options={options || []}
        freeSolo
        autoSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="dense"
            required={!!required}
            helperText={!disableHelperText && (errorMessage || '\u00A0')}
            error={(errorMessage || '').length > 0}
            className={classes.TextField}
            fullWidth
            {...rest}
          />
        )}
        value={(options || []).find((o) => o.value === value) || value}
        getOptionLabel={(o) => (o as any)?.text || o}
        onChange={(_, value) => {
          const actualValue = (value as any)?.value || value;
          onChange &&
            onChange({
              target: {
                value: actualValue,
              },
            });
        }}
      />
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  TextField: {},
  MenuPaper: {},
}));

export default AutocompleteInput;
