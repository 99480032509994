import axios from '~/utils/axios';

export type PricingFeeType =
  | 'fixedDebitFee'
  | 'fixedDebitFeeDecrease'
  | 'percentDebitFee'
  | 'percentDebitFeeDecrease'
  | 'fixedCreditFee'
  | 'percentCreditFee';

export type FeeLoyaltyDiscountType = 'FULL' | 'PERCENT' | 'FIXED';

export type AcquiringFeeType =
  | 'FIXED_INCREASE'
  | 'FIXED_DECREASE'
  | 'PERCENT_INCREASE'
  | 'PERCENT_DECREASE';

export type PricingSchemaPropertie = {
  type:
    | 'veengu_fee_membership'
    | 'veengu_fee_default'
    | 'veengu_fee_acquiring'
    | 'veengu_service_charges'
    | 'veengu_fee_loyalty_default'
    | 'veengu_limit_default_amount'
    | 'veengu_limit_default_count';
  'v:category': 'fee' | 'loyalty' | 'limit' | 'counter' | 'interest' | 'service';
  'v:feature': string;
  'v:name': string;
  'v:index'?: number;
  'v:default'?: any;
  'v:enum'?: {
    id: PricingFeeType | AcquiringFeeType;
    name: string;
  }[];
};

export type PricingSchema = {
  type: string;
  required: string[];
  properties: {
    [x: string]: PricingSchemaPropertie;
  };
};

export const getPricingSchema = (id: string) => {
  return axios.get<PricingSchema>(`/pricings/${id}/schema`);
};
