import React from 'react';
import { useTranslate } from 'react-admin';
import { Longdash } from '~/utils';

interface Props {
  data?: string;
  children?: string;
}

const CountryField = (props: Props) => {
  const { data, children } = props;
  const countryCode = data || children;

  const translate = useTranslate();

  const getCountryName = (countryCode: string): string => {
    try {
      const name = translate(`countries.${countryCode}`);
      if (name.startsWith('countries.')) throw new Error(`Invalid country code: ${countryCode}`);
      return name;
    } catch (error) {
      console.error(error.message);
      return countryCode;
    }
  };

  return <span>{countryCode ? getCountryName(countryCode) : Longdash}</span>;
};

export default CountryField;
