import React from 'react';
import { JSONSchema6 } from 'json-schema';
import TextField from '~/components/TextField';

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const StringField = ({ formData, uiSchema }: Props) => {
  return <TextField label={uiSchema['ui:title']}>{formData || '—'}</TextField>;
};

export default StringField;
