import { ProductTemplate } from '~/types';
import axios from '~/utils/axios';

export type TenantStatus = 'TEST' | 'DEMO' | 'PRODUCTION';
export type TenantType = {
  id: string;
  code: string;
  name: string;
  status: TenantStatus;
  data: {
    googleMapApiKey?: string;
    locale: string;
    locales?: string[];
    privacyPolicyUrl: string;
    supportEmail: string;
    timeZone: string;
    license?: {
      active: boolean;
      activeBefore: string;
    };
  };
  created: string;
  deleted: boolean;
};
export const getTenant = () => {
  return axios.get<TenantType>('tenant').then((res) => res.data);
};

export const getTenantBranding = () => {
  return axios
    .get<any>('tenant/branding')
    .then((res) => res.data)
    .catch((e: any) => {
      console.log(`failed to load branding; error:`, e);
      return {}; // unable to load branding
    });
};
export interface FileType extends File {
  id: string;
  mimeType?: string;
  rawFile?: FileType;
}
export const getFile = (file: FileType, resource?: string) => {
  return axios.get(
    resource?.startsWith('paymentServices')
      ? `/paymentServices/icons/${file.id}`
      : `/files/${file.id}`,
    { responseType: 'blob' }
  );
};

export const getProductTemplates = () => {
  return axios.get<ProductTemplate[]>('/productTemplates').then((res) => res.data);
};

export type AccountNumbering = {
  id: string;
  name: string;
};

export const getAccountNumberingList = () => {
  return axios.get<AccountNumbering[]>('/accountNumberingList').then((res) => res.data);
};
