import React from 'react';
import { JSONSchemaType } from 'ajv';
import lodashGet from 'lodash/get';

import { Grid } from '@material-ui/core';

import { CountryField, TextField } from '~/components';
import BooleanField from './BooleanField';

interface Props {
  data: any;
  schema: JSONSchemaType<Record<string, any>>;
  propertyColumns?: number;
  exlude?: string[];
  displayMissingProperties?: boolean;
  className?: string;
}

const JsonSchemaView = (props: Props) => {
  const { data, schema, propertyColumns = 3, exlude, displayMissingProperties, className } = props;

  const properties: any = schema.properties || {};

  const propertyItems: JSX.Element[] = [];

  Object.keys(properties)
    .filter((propertyKey) => !(exlude || []).includes(propertyKey))
    .forEach((propertyKey) => {
      const property: any = properties[propertyKey];
      var value = lodashGet(data, propertyKey);
      if (value) {
        switch (property.type) {
          case 'object':
          case 'document':
            break;
          case 'boolean':
            propertyItems.push(
              <Grid item xs={(12 / propertyColumns) as any} key={propertyKey}>
                <BooleanField
                  label={property.title}
                  value={typeof value === 'string' ? value === 'true' : value}
                />
              </Grid>
            );
            break;
          default: {
            let field: JSX.Element;
            if (property.enum) {
              const names = property.enumNames || property.enum;
              for (let i = 0; i < property.enum.length && i < names.length; ++i) {
                if (property.enum[i] === value) {
                  value = names[i];
                  break;
                }
              }
              field = <TextField label={property.title}>{value}</TextField>;
            } else {
              switch (property.format) {
                case 'country':
                case 'solution-country':
                  field = (
                    <TextField label={property.title}>
                      <CountryField>{value}</CountryField>
                    </TextField>
                  );
                  break;
                default:
                  field = <TextField label={property.title}>{value}</TextField>;
                  break;
              }
            }
            propertyItems.push(
              <Grid item xs={(12 / propertyColumns) as any} key={propertyKey}>
                {field}
              </Grid>
            );
            break;
          }
        }
      } else if (displayMissingProperties) {
        propertyItems.push(
          <Grid item xs={(12 / propertyColumns) as any} key={propertyKey}>
            <TextField label={property.title} />
          </Grid>
        );
      }
    });

  return (
    <Grid container item xs={12} spacing={2} className={className}>
      {propertyItems}
    </Grid>
  );
};

export default JsonSchemaView;
