import React from 'react';
import { makeStyles } from '@material-ui/core';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
  collapseToggle: {
    position: 'relative',
    top: 3,
    left: 3,
    marginRight: 2,
  },
  collapseToggleInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    cursor: 'inherit',
  },
});

const CollapseToggle = (props) => {
  const { checked, onChange } = props;
  const classes = useStyles();
  return (
    <span onClick={onChange} className={classes.collapseToggle}>
      <input className={classes.collapseToggleInput} onChange={onChange} tabIndex="-1" />
      {checked ? <ExpandMore /> : <ChevronRight />}
    </span>
  );
};

export default CollapseToggle;
