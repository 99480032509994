import { ProfileType } from '../types/profile';
import { Solution } from './../types/solutions';
import axios from '~/utils/axios';

export type Feature = {
  key: string;
  name: string;
};

export const getFeaturesRequest = () => {
  return axios.get<Feature[]>(`/features?page=1&perPage=9999`);
};

export type ProfileDetail = {
  id: string;
  type: ProfileType;
  title: string;
  solutionId: string;
  solutionName: string;
};

export const getProfileDetails = (id: string) => {
  return axios.get<ProfileDetail>(`profiles/${id}`);
};

export type Configuration = {
  [x: string]: {
    [x: string]: any;
  };
};

export const getConfiguration = (id: string) => {
  return axios.get<Configuration>(`solutions/${id}/configuration`);
};

export const getSolutions = () => {
  return axios.get<Solution[]>(`/solutions?page=1&perPage=9999`);
};
