import { crudGetManyReference } from 'react-admin';
import { useDispatch } from 'react-redux';

import usePath from './usePath';

const useCrudGetManyReference = (resource: string, target: string, id: string) => {
  const {
    params: { page, perPage },
  } = usePath();

  const dispatch = useDispatch();
  return () =>
    dispatch(
      crudGetManyReference(
        resource,
        target,
        id,
        `${target}_${resource}@${target}_${id}`,
        { page, perPage },
        { field: 'id', order: 'DESC' },
        {}
      )
    );
};

export default useCrudGetManyReference;
