import React, { FC, useState } from 'react';
import { Link } from '.';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

interface ExpansionFormProps {
  label: string;
  disabled?: boolean;
  onClose?: () => void;
  defaultOpen?: boolean;
  [x: string]: any;
}

const ExpansionForm: FC<ExpansionFormProps> = (props) => {
  const { children, disabled, defaultOpen, label, className } = props;
  const [open, setOpen] = useState(defaultOpen);

  const handleOpen = () => {
    if (open) {
      // Modal.confirm({
      //   title: t('informationWillBeLost'),
      //   icon: <InfoCircleOutlined />,
      //   content: t('areYouSure'),
      //   okText: t('yes'),
      //   okType: 'primary',
      //   cancelText: t('no'),
      //   onOk() {
      //     setOpen(false);
      //     if (typeof onClose === 'function') {
      //       onClose();
      //     }
      //   },
      // });
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className={className}>
        <Link onClick={handleOpen} disabled={disabled}>
          {open ? <RemoveIcon /> : <AddIcon />} {label || 'Open'}
        </Link>
      </div>
      {open && children}
    </>
  );
};

export default styled(ExpansionForm)`
  margin-bottom: 8px;
  a {
    display: flex;
    align-items: center;
    svg {
      font-size: 1rem;
    }
  }
`;
