import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import SmartphoneIcon from '@material-ui/icons/Smartphone';
import MobileStoreButton from 'react-mobile-store-button';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import StarIcon from '@material-ui/icons/Star';
import DemoAlert from './DemoAlert';

import { H2, H3, Link, Pagination } from '~/components';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& p': {
      color: theme.palette.grey[300],
    },
    '& h3': {
      '& svg': {
        color: '#CACACA',
      },
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'stretch',
  },
  tabText: {
    width: '100%',
    minWidth: 620,
    maxWidth: 1200,
  },
  imgWrapper: {
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      maxHeight: 250,
      padding: theme.spacing(2),
    },
  },
  tabbedShowLayout: {
    boxSizing: 'border-box',
    flexGrow: 1,
    minWidth: 965,
  },
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  downloadButton: {
    position: 'relative',
    height: '50px !important',
    '& > a': {
      height: '74px !important',
      padding: '0 !important',
      margin: -12,
      position: 'absolute',
    },
  },
  downloadButtonIOS: {
    position: 'relative',
    height: '50px !important',
    '& > a': {
      padding: '0 !important',
      position: 'absolute',
    },
  },
}));

const individualId = '38538772-c10c-41d2-b328-10607e804dc5';
const businessId = '4252e253-6b4b-4e34-8336-de79af34636a';

const label = (key: string): string => `resources.welcome.${key}`;

const Container = (props: any) => {
  const { children } = props;
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

const Welcome = () => {
  const [page, setPage] = useState(1);

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const classes = useStyles();

  return (
    <Fragment>
      <DemoAlert />
      <Paper className={classes.root}>
        {page === 1 && (
          <div className={classes.tab}>
            <div className={classes.tabText}>
              <H2 topSpacing={0}>Welcome to Veengu demo</H2>
              <Container>
                <H3 icon={<SmartphoneIcon />} topSpacing={0} noPaddingLeft>
                  {t('downloadApp')}
                </H3>
                <MobileStoreButton
                  store="android"
                  url={'https://play.google.com/store/apps/details?id=com.veengu.wallet'}
                  linkProps={{ title: 'Open Google Play' }}
                  className={classes.downloadButton}
                  width={197}
                />
                <MobileStoreButton
                  store="ios"
                  url={'https://apps.apple.com/us/app/veengu-wallet/id1540285480'}
                  linkProps={{ title: 'Open App Store' }}
                  className={classes.downloadButtonIOS}
                />
                <H3>Sign up to the demo mobile app</H3>
                <Typography color="inherit" variant="body1">
                  Please use your{' '}
                  <strong>
                    real phone number to sign up as we will send a one-time password to it
                  </strong>
                  . Feel free to fill other fields with dummy data. This is demo.
                </Typography>
              </Container>
            </div>
            <div className={classes.imgWrapper}>
              <img src={require('~/img/welcomeDemo1.png')} alt="" />
            </div>
          </div>
        )}
        {page === 2 && (
          <div className={classes.tab}>
            <div className={classes.tabText}>
              <H2 topSpacing={0}>Explore individual wallet</H2>
              <Container>
                <H3 icon={<AttachMoneyIcon />} topSpacing={0} noPaddingLeft>
                  Make P2P payment by phone number
                </H3>
                <Typography color="inherit" variant="body1">
                  Once you created a new profile, our Bot sends you virtual USD 500, so you can
                  transact within the demo system. To make a P2P transfer, use Andy Russels' dummy{' '}
                  <Link color="blue" to={`/individuals/${individualId}/show`} target="_blank">
                    account
                  </Link>{' '}
                  with phone +971551234579 or create a second profile by onboarding your friend and
                  transfer some amount to it.
                </Typography>
              </Container>
              <Container>
                <H3 icon={<DesktopMacIcon />} topSpacing={0} noPaddingLeft>
                  <div>
                    Find just created profile by phone number or name on the{' '}
                    <Link color="blue" to="/individuals" target="_blank">
                      Individuals screen
                    </Link>
                  </div>
                </H3>
                <Typography color="inherit" variant="body1">
                  You will see account instantly created for you, current balance, and limits
                  powered by Veengu financial service. Explore profile details, play with documents,
                  contact information, and notes. View all payments you have just made in the
                  'Transactions' tab.
                </Typography>
              </Container>
            </div>
            <div className={classes.imgWrapper}>
              <img src={require('~/img/welcomeDemo2.png')} alt="" />
            </div>
          </div>
        )}
        {page === 3 && (
          <div className={classes.tab}>
            <div className={classes.tabText}>
              <H2 topSpacing={0}>Demo guide to the solution for business customers</H2>
              <Container>
                <H3 icon={<DesktopMacIcon />} topSpacing={0} noPaddingLeft>
                  <div>
                    Play with business profiles in the{' '}
                    <Link color="blue" to="/businesses" target="_blank">
                      Businesses screen
                    </Link>
                  </div>
                </H3>
                <Typography color="inherit" variant="body1">
                  Open Middle East Leisure Retail{' '}
                  <Link color="blue" to={`/businesses/${businessId}/show`} target="_blank">
                    profile
                  </Link>
                  . You will find account, limits, profile management similar to individual
                  customers screen. But not only as businesses may have a complex organizational
                  structure. You may add a new store as a{' '}
                  <Link
                    color="blue"
                    to={`/businesses/${businessId}/show/locations`}
                    target="_blank"
                  >
                    location
                  </Link>
                  , register a new QR{' '}
                  <Link
                    color="blue"
                    to={`/businesses/${businessId}/show/terminals`}
                    target="_blank"
                  >
                    terminal
                  </Link>{' '}
                  or manage{' '}
                  <Link
                    color="blue"
                    to={`/businesses/${businessId}/show/employees`}
                    target="_blank"
                  >
                    employees
                  </Link>
                  .
                </Typography>
              </Container>
              <Container>
                <H3 topSpacing={1} bottomSpacing={1}>
                  Issue a new static QR code
                </H3>
                <Typography color="inherit" variant="body1">
                  Define a good you would like to{' '}
                  <Link
                    color="blue"
                    to={`/businesses/${businessId}/show/paymentDetails`}
                    target="_blank"
                  >
                    sell
                  </Link>
                  , put a price. Download and open static QR as a PNG image.
                </Typography>
              </Container>
              <Container>
                <H3 icon={<SmartphoneIcon />} topSpacing={0} noPaddingLeft>
                  Purchase this good by scanning generated QR with your mobile app
                </H3>
                <Typography color="inherit" variant="body1">
                  Check the fees and confirm the payment. Then view all transaction details in the
                  Individual and Business screens.
                </Typography>
              </Container>
            </div>
            <div className={classes.imgWrapper}>
              <img src={require('~/img/welcomeDemo3.png')} alt="" />
            </div>
          </div>
        )}
        {page === 4 && (
          <div className={classes.tab}>
            <div className={classes.tabText}>
              <H2 topSpacing={0}>Demo guide to the Veengu settings</H2>
              <Container>
                <H3 icon={<StarIcon />} topSpacing={0} noPaddingLeft>
                  <div>
                    {t('open')}{' '}
                    <Link color="blue" to="/solutions" target="_blank">
                      {t('solutionScreen')}
                    </Link>
                  </div>
                </H3>
                <Typography color="inherit" variant="body1">
                  {t('viewFeatures')}
                </Typography>
              </Container>
              <Container>
                <H3 icon={<AttachMoneyIcon />} topSpacing={0} noPaddingLeft>
                  {t('viewPricing')}
                </H3>
                <Typography color="inherit" variant="body1">
                  {t('forEachTransaction')}
                </Typography>
              </Container>
              <Container>
                <H3 topSpacing={0} bottomSpacing={0}>
                  <div>
                    Any questions? Send a request to{' '}
                    <Link color="blue" href="mailto:sales@veengu.com">
                      sales@veengu.com
                    </Link>{' '}
                    or to a Veengu team member you are already in touch with.
                  </div>
                </H3>
              </Container>
            </div>
            <div className={classes.imgWrapper}>
              <img src={require('~/img/welcomeDemo4.png')} alt="" />
            </div>
          </div>
        )}
        <Pagination variant="contained" page={page} total={4} setPage={setPage} />
      </Paper>
    </Fragment>
  );
};

export default Welcome;
