import { required, number } from 'react-admin';

export default (propertyKey, objectSchema, propertySchema, defaultValue) => {
  const f = [];
  if (
    defaultValue === undefined &&
    objectSchema.required &&
    objectSchema.required.includes(propertyKey)
  ) {
    f.push(required());
  }
  if (propertySchema.type === 'string') {
    if (propertySchema.pattern) {
      const pattern = new RegExp(propertySchema.pattern);
      f.push((value) => {
        if (value && !value.match(pattern)) {
          return { message: 'jsonSchemaValidation.pattern', args: { pattern } };
        }
      });
    }
  }
  if (propertySchema.type === 'array') {
    if (propertySchema.minItems) {
      f.push((value) => {
        if (!value || value.length < propertySchema.minItems) {
          return {
            message: 'jsonSchemaValidation.minItems',
            args: { minItems: propertySchema.minItems },
          };
        }
      });
    }
    if (propertySchema.maxItems) {
      f.push((value) => {
        if (value && value.length >= propertySchema.maxItems) {
          return {
            message: 'jsonSchemaValidation.maxItems',
            args: { maxItems: propertySchema.maxItems },
          };
        }
      });
    }
  }
  if (propertySchema.type === 'integer' || propertySchema.type === 'number') {
    f.push(number());
    if (propertySchema.minimum) {
      f.push((value) => {
        if (!value || value < propertySchema.minimum) {
          return {
            message: 'jsonSchemaValidation.minimum',
            args: { minimum: propertySchema.minimum },
          };
        }
      });
    }
    if (propertySchema.maximum) {
      f.push((value) => {
        if (value > propertySchema.maximum) {
          return {
            message: 'jsonSchemaValidation.maximum',
            args: { maximum: propertySchema.maximum },
          };
        }
      });
    }
  }
  return f;
};
