import { isEmpty } from 'lodash';

const cleanupFormValues = <T extends Record<string, any> = Record<string, any>>(
  data: T,
  mode: 'soft' | 'hard' = 'soft'
): T => {
  if (data && typeof data === 'object' && Object.keys(data)) {
    const result = { ...data };
    for (const key in result) {
      const item = data[key];
      if ((item as any) instanceof File) continue;
      if (item === '' || item === undefined || item === null) {
        delete result[key];
        continue;
      }
      if (Array.isArray(item)) {
        if (mode === 'hard' && !item.length) {
          delete result[key];
          continue;
        } else {
          result[key] = item.filter((arrayItem: any) => arrayItem !== undefined);
          continue;
        }
      }
      if (item && typeof item === 'object') {
        if (mode === 'hard' && isEmpty(item)) {
          delete result[key];
          continue;
        } else {
          result[key] = cleanupFormValues(item, mode);
          if (mode === 'hard' && isEmpty(result[key])) {
            delete result[key];
            continue;
          }
        }
      }
    }
    return result;
  }
  return data;
};

export default cleanupFormValues;
