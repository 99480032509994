import React from 'react';
import { Link as RaLink } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'none',
  },
  blue: {
    color: colors.blue[200],
  },
  inherit: {
    color: 'inherit',
  },
}));

const Link = (props) => {
  const { href, children, className = '', color = '', ...rest } = props;

  const classes = useStyles();
  if (href) {
    return (
      <a
        className={cx(classes.link, { [className]: !!className, [classes[color]]: !!color })}
        href={href}
        children={children}
        {...rest}
      />
    );
  } else {
    return (
      <RaLink
        className={cx(classes.link, { [className]: !!className, [classes[color]]: !!color })}
        {...props}
      />
    );
  }
};

export default Link;
