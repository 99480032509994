import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.primary.main,
  },
  differenceValue: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

const NumberTile = (props) => {
  const { className, title, number, inc, icon, ...rest } = props;

  const classes = useStyles();
  const AvatarIcon = icon;
  const avatarIcon = <AvatarIcon className={classes.icon} />;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify='space-between'>
          <Grid item>
            <Typography
              className={classes.title}
              color='textSecondary'
              gutterBottom
              variant='body2'
            >
              {title}
            </Typography>
            {typeof number === 'number' ? (
              <Typography variant='h3'>
                {number > 10000
                  ? `~${Math.round(number / 1000)}K`
                  : number > 1000
                  ? `${(number / 1000).toFixed(1)}K`
                  : number}
              </Typography>
            ) : (
              <Skeleton variant='text' />
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>{avatarIcon}</Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {typeof number === 'number' ? (
            <Fragment>
              <ArrowUpwardIcon className={classes.differenceIcon} />
              <Typography className={classes.differenceValue} variant='body2'>
                +{inc}
              </Typography>
              <Typography className={classes.caption} variant='caption'>
                in last 30 days
              </Typography>
            </Fragment>
          ) : (
            <Skeleton variant='text' style={{ width: '100%' }} />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

NumberTile.propTypes = {
  className: PropTypes.string,
};

export default NumberTile;
