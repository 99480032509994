import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';

import Drawer from '~/components/Drawer';
import EditButton from '~/components/EditButton';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import ExternalProfileLinkForm from './ExternalProfileLinkForm';

const label = (key: string): string => `components.ExternalProfileLinks.${key}`;

interface Props {
  link: ExternalProfileLink;
}

const EditAction: FC<Props> = (props) => {
  const { link } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <EditButton onClick={handleOpen} />
      <Drawer heading={t('editDrawerTitle')} open={open} onClose={handleClose}>
        <ExternalProfileLinkForm link={link} handleClose={handleClose} />
      </Drawer>
    </>
  );
};

export default EditAction;
