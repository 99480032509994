export const validateRequired = (
  currentTableData: any[],
  newValue: any,
  strict: boolean = false
) => {
  if ((strict || newValue !== undefined) && !newValue) {
    return 'Required';
  } else return '';
};

export const validateId = (
  currentTableData: any[],
  newValue: any,
  oldValue: any,
  strict: boolean = false
) => {
  if (strict || newValue !== undefined) {
    if (!newValue) {
      return 'Required';
    } else if (oldValue !== newValue) {
      const existingLevel = currentTableData.find((item) => {
        return item.id === newValue;
      });
      if (existingLevel) {
        return 'Item with such ID already exists';
      }
    }
  }
  return '';
};

const trimSpaces = (value: string): string => {
  if (value.startsWith(' ')) {
    return trimSpaces(value.slice(1, value.length));
  }
  if (value.endsWith(' ')) {
    return trimSpaces(value.slice(0, value.length - 1));
  }
  return value;
};

export const sanitize = (record: any): any => {
  if (Array.isArray(record)) {
    return record.map(sanitize);
  } else if (record === null) {
    return record;
  } else if (typeof record === 'object') {
    const sanitizedRecord = { ...record };
    for (let key in record) {
      const item = record[key];
      sanitizedRecord[key] = sanitize(item);
    }
    return sanitizedRecord;
  } else if (typeof record === 'string') {
    return trimSpaces(record);
  } else {
    return record;
  }
};

export const alignCenter = {
  headerStyle: {
    paddingLeft: 26,
    paddingRight: 0,
    textAlign: 'center',
  },
  cellStyle: {
    textAlign: 'center',
  },
};
