import React, { useEffect } from 'react';
import { useTranslate, Button } from 'react-admin';
import { TableContainer, TableBody, TableHead, Paper, Grid, SvgIcon } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { H2, Link, OutterTable } from '~/components';
import { ArrowForwardCircleIcon } from '~/img';

import { filterChange } from '~/ducks/virtualizedList';
import {
  getBusinessesEvents,
  getExternalProfileLinksEvents,
  getIndividualEvents,
  getPartnersEvents,
  Response,
} from '~/api/events';
import { useApi, useNotify } from '~/hooks';
import { resource } from '~/resources/events';
import CustomCell from '~/resources/events/components/CustomCell';
import { EventFilters, EventType } from '~/types/Event';
import { RootState } from '~/types';
import { invalidateEventEntities } from '~/hooks/eventEntities';
import TableCell, { TableCellProps } from '~/components/TableCell';
import palette from '~/theme/palette';
import { time } from '~/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  table: {
    tableLayout: 'fixed',
  },
  TableCell: {
    whiteSpace: 'nowrap',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  },
}));

interface TableBodyCellProps extends TableCellProps {
  id: string;
  rowData: EventType;
}

const TableBodyCell = (props: TableBodyCellProps) => {
  const { loading, children, id, rowData, style, width } = props;
  return (
    <TableCell loading={loading} width={width}>
      <div style={style}>
        <CustomCell column={{ id } as any} rowData={rowData}>
          {children}
        </CustomCell>
      </div>
    </TableCell>
  );
};

type Target = 'individuals' | 'businesses' | 'partners' | 'externalProfileLinks';

interface Props extends EventFilters {
  history: any;
  tableStyle?: any;
  objectType: Target;
  objectId: string;
  changelogMode?: boolean;
  selectedChangelogTime?: string;
}

const EventLogWidget = (props: Props) => {
  const { objectId, history, objectType, changelogMode, selectedChangelogTime } = props;
  const lastUpdate = useSelector<RootState, number>((state) => state.eventLog.lastUpdate);
  const [{ data: eventLogData, loading, error }] = useApi<Response>(
    objectType === 'individuals'
      ? () => getIndividualEvents(objectId, { limit: 10, timestampTo: selectedChangelogTime })
      : objectType === 'businesses'
      ? () => getBusinessesEvents(objectId, { limit: 10, timestampTo: selectedChangelogTime })
      : objectType === 'partners'
      ? () => getPartnersEvents(objectId, { limit: 10 })
      : objectType === 'externalProfileLinks'
      ? () => getExternalProfileLinksEvents(objectId, { limit: 10 })
      : () => Promise.resolve(),
    [objectType, objectId, lastUpdate, selectedChangelogTime]
  );

  useEffect(() => {
    invalidateEventEntities();
  }, [lastUpdate]);

  const notify = useNotify();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.list.${key}`);

  useEffect(() => {
    if (error) {
      notify({ message: error.message, type: 'error' });
    }
  }, [error, notify]);

  const dispatch = useDispatch();
  const handleViewAllEventsClick = () => {
    if (history) {
      dispatch(
        filterChange({
          resource,
          filters: {
            timestampFrom: time().subtract(1, 'month'),
            timestampTo: time(),
            objectId,
          },
        })
      );
      history.push('/events?withFilters');
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify='space-between' alignItems='center' className={classes.bottomSpacing}>
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('lastEvents')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          {!changelogMode && (
            <Button label={t('viewAllEvents')} onClick={handleViewAllEventsClick}>
              <SvgIcon>
                <ArrowForwardCircleIcon />
              </SvgIcon>
            </Button>
          )}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <OutterTable className={classes.table}>
          <TableHead className={changelogMode ? classes.changelogMode : undefined}>
            {!error && (
              <TableRow>
                <TableCell width='10%' loading={loading}>
                  {t('timestamp')}
                </TableCell>
                <TableCell width='50%' loading={loading}>
                  {t('topic')}
                </TableCell>
                <TableCell width='20%' loading={loading}>
                  {t('actor')}
                </TableCell>
                <TableCell width='20%' loading={loading}>
                  {t('processingStatuses')}
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody className={changelogMode ? classes.changelogMode : undefined}>
            {!loading
              ? eventLogData?.events.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      component={Link}
                      to={!changelogMode && `/events/${item.id}/show`}
                    >
                      <TableBodyCell id='createdAt' rowData={item} />
                      <TableBodyCell id='topic' rowData={item}>
                        {item.topic}
                      </TableBodyCell>
                      <TableBodyCell id='actor' rowData={item}>
                        {item.actors}
                      </TableBodyCell>
                      <TableBodyCell id='processingStatuses' rowData={item} />
                    </TableRow>
                  );
                })
              : new Array(7).fill(0).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                  </TableRow>
                ))}
            {error?.message && (
              <TableRow>
                <TableCell>{error.message}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </OutterTable>
      </TableContainer>
    </div>
  );
};

export default EventLogWidget;
