import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';
import get from 'lodash/get';

import axios from '~/utils/axios';
import FileSaver from 'file-saver';
import ImageField, { ImageFieldProps } from '~/components/ra/ImageField';
import { File } from '~/types/businesses';
import { PdfIcon, WordIcon, ExcelIcon } from '~/img';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SvgIcon from '@material-ui/core/SvgIcon';
import { CSSProperties } from 'styled-components';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: '15px',
    lineHeight: 1.65,
  },
  textEllipsis: {
    display: 'inline-flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  filePreview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    marginTop: 0,
    marginBottom: 0,
    lineHeight: 1.3,
    textAlign: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    '& svg': {
      paddingBottom: theme.spacing(2),
    },
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  ellipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}));

interface FilePrewiewProps {
  fileName: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  onClick?: any;
}

const FilePreview = (props: FilePrewiewProps) => {
  const { width = 200, height = 200, fileName, onClick } = props;
  const classes = useStyles();

  const getFormat = (fileName: string): string | null => {
    return fileName.split('.').pop() || null;
  };

  const Card = ({ icon, name }: { icon: JSX.Element; name: string }) => {
    return (
      <div className={classes.filePreview} style={{ width, height }} onClick={onClick}>
        <div>
          <SvgIcon>{icon}</SvgIcon>
        </div>
        <div className={classes.ellipsis}>{name}</div>
      </div>
    );
  };

  switch (getFormat(fileName)) {
    case 'pdf':
      return <Card icon={<PdfIcon />} name={fileName} />;
    case 'docx':
      return <Card icon={<WordIcon />} name={fileName} />;
    case 'doc':
      return <Card icon={<WordIcon />} name={fileName} />;
    case 'xlsx':
      return <Card icon={<ExcelIcon />} name={fileName} />;
    case 'xls':
      return <Card icon={<ExcelIcon />} name={fileName} />;
    default:
      return <Card icon={<InsertDriveFileIcon />} name={fileName} />;
  }
};

interface Props {
  record?: any;
  resource?: string;
  source?: string;
  withPreview?: boolean;
  imageFieldProps?: ImageFieldProps;
  ellipsis?: boolean;
}

const FileField = (props: Props) => {
  const classes = useStyles();
  const { record, resource, source, withPreview, imageFieldProps, ellipsis = true } = props;
  const sourceValue: File = source ? get(record, source) : record;

  if (!sourceValue) {
    return null;
  }
  const sourceValueData = sourceValue.rawFile ? sourceValue.rawFile : sourceValue;
  const fileId = sourceValueData.id;
  const fileName: string = sourceValueData.name || fileId;
  const fileType = sourceValueData.type || sourceValueData.mimeType;

  const download = () => {
    if (fileId) {
      axios
        .get(
          resource?.startsWith('paymentServices')
            ? `/paymentServices/icons/${fileId}`
            : `/files/${fileId}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          FileSaver.saveAs(response.data, fileName);
        })
        .catch(() => null);
    }
  };

  if (withPreview && fileType) {
    if (fileType.startsWith('image')) {
      return (
        <div>
          <ImageField record={sourceValue} objectFit='contain' {...imageFieldProps} />
        </div>
      );
    } else {
      return (
        <FilePreview
          onClick={download}
          fileName={fileName}
          width={imageFieldProps?.width}
          height={imageFieldProps?.height}
        />
      );
    }
  } else {
    return (
      <div
        className={cx(classes.root, {
          [classes.textEllipsis]: ellipsis,
        })}
      >
        <Link onClick={download} to={false}>
          {fileName}
        </Link>
      </div>
    );
  }
};

FileField.defaultProps = {
  addLabel: true,
  withPreview: false,
};

export default FileField;
