import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import { useHandbook } from '~/hooks';
import { isEmpty } from 'lodash';

export interface Props {
  solutionId?: string;
  country?: string;
  fullWidth?: boolean;
  [x: string]: any;
}

const BusinessLegalTypeInput = ({ solutionId, country, fullWidth, ...rest }: Props) => {
  // eslint-disable-line
  const { data } = useHandbook(solutionId);

  const translate = useTranslate();

  if (data && country) {
    const countryData = (data?.countries || []).find((c) => c.id === country);
    const legalTypes = (countryData?.businessLegalTypes || []).map((t) => {
      return { id: t.name, name: t.name };
    });

    return (
      <SelectInput
        choices={
          isEmpty(legalTypes)
            ? [{ id: undefined, name: translate('utils.noHandbookValues') }]
            : legalTypes
        }
        fullWidth={fullWidth}
        default
        {...rest}
      />
    );
  } else {
    return <SelectInput choices={[]} fullWidth={fullWidth} {...rest} disabled />;
  }
};

export default BusinessLegalTypeInput;
