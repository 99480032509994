import React, { Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';
import { changePin } from '~/api/cards';

import { Confirmation } from '~/components';
import { l } from './index';
import EditButton from '~/components/EditButton';
import DialpadIcon from '@material-ui/icons/Dialpad';
import onError from '~/errorsHandler';

interface CardChangePinProps {
  refetch: () => void;
  id: string;
}

const CardChangePin: FC<CardChangePinProps> = (props) => {
  const { refetch, id } = props;

  const handleConfirm = async () => {
    try {
      await changePin(id);
      refetch();
    } catch (error) {
      onError(error);
    }
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <Confirmation
        onConfirm={handleConfirm}
        confirmationSettings={{
          variant: 'modal',
          modal: { heading: t('changePin'), content: t('changePinConfirm') },
        }}
      >
        <EditButton label={t('changePin')} icon={<DialpadIcon />} red />
      </Confirmation>
    </Fragment>
  );
};

export default CardChangePin;
