import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { isEmpty } from 'lodash';

import ExploreIcon from '@material-ui/icons/Explore';
import ReportsIcon from '@material-ui/icons/Assessment';
import TagsIcon from '@material-ui/icons/LocalOffer';

import { TextField } from '~/components';
import { StatusField } from '~/components';
import { RibbonIcon, TimeIcon } from '~/img';
import { AsideBase } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import { time } from '~/utils';

interface Props {
  [x: string]: any;
  record?: HeadTransaction;
}

const AsideDetails = (props: Props) => {
  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.aside.details.${key}`);

  const { record: trx } = props;

  const { status } = trx || {};

  if (!trx) {
    return null;
  }

  return (
    <AsideBase className={classes.root}>
      <Grid container spacing={2} className={classes.layout}>
        <Grid item xs={12}>
          <TextField label={t('created')} icon={<TimeIcon />}>
            {time(trx.created).format('ll, LTS')}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('postingDate')} icon={<></>}>
            {trx.postingDate && moment(trx.postingDate).format('ll')}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('status')} icon={<RibbonIcon />}>
            <StatusField status={status} />
          </TextField>
        </Grid>
        {!isEmpty(trx.details.referenceId) && (
          <>
            <Grid item xs={12}>
              <TextField icon={<ExploreIcon />}>{t('referenceId')}</TextField>
            </Grid>
            {trx.details.referenceId?.map((referenceId: any) => (
              <Grid item xs={12} key={referenceId.id}>
                <TextField label={referenceId.id} icon={<></>} textWrap>
                  {referenceId.value}
                </TextField>
              </Grid>
            ))}
          </>
        )}
        {!isEmpty(trx.details.report) && (
          <>
            <Grid item xs={12}>
              <TextField icon={<ReportsIcon />}>{t('reporting')}</TextField>
            </Grid>
            {Object.keys(trx.details.report as any).map((reportType: any) => {
              const report = (trx.details.report || {})[reportType];
              return (
                <Grid item xs={12} key={report.id}>
                  <TextField label={reportType} icon={<></>}>
                    <StatusField status={report.status} />
                  </TextField>
                </Grid>
              );
            })}
          </>
        )}
        {!isEmpty(trx.tags) && (
          <Grid item xs={12}>
            <TextField label={t('tags')} icon={<TagsIcon />}>
              {trx.tags.map((t: string) => (
                <Chip key={t} label={t} size="small" style={{ margin: 1 }} />
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
}));

export default AsideDetails;
