import React, { FC, useEffect, useState } from 'react';
import { SelectInput } from 'react-admin';
import { TextInput, useTranslate, required } from 'react-admin';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import { useAddressConfig } from '~/hooks';
import { AddressFieldDashboardConfig } from '~/hooks/useAddressConfig';
import CountryInput from '../CountryInput';
import HiddenInput from '../HiddenInput';
import { applyExclude, groupFields } from './utils';
import H3 from '~/components/H3';
import GeolocationInput from './GeolocationInput';
import { get } from 'lodash';

export type Address = {
  country: string;
  geolocation?: {
    latitude: string;
    longitude: string;
    plusCode?: string;
  };
} & Record<string, string | undefined>;

export type ExcludeSource = 'country' | 'cityLocalized' | 'geolocation';

interface Props {
  source?: string;
  countrySource?: string;
  solutionId?: string;
  defaultCountry?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  excludeInputs?: ExcludeSource[];
}

const AddressForm: FC<Props> = (props) => {
  const {
    source = 'address',
    countrySource = `${source}.country`,
    defaultCountry,
    solutionId,
    disabled = false,
    fullWidth = true,
    excludeInputs,
  } = props;

  const t = useTranslateAddress();

  const form = useForm();

  useEffect(() => {
    form.destroyOnUnregister = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [countryFieldState, setCountryFieldState] = useState<
    ReturnType<typeof form.getFieldState>
  >();
  const countryFromField = countryFieldState?.value;
  useEffect(() => {
    form.subscribe(
      ({ values }) => {
        if (countryFromField !== get(values, countrySource)) {
          setCountryFieldState(form.getFieldState(countrySource));
        }
        return;
      },
      { values: true }
    );
  }, [countryFromField, countrySource, form]);

  const { data } = useAddressConfig(countryFromField);

  const getField = (fieldConfig: AddressFieldDashboardConfig): JSX.Element | null => {
    switch (fieldConfig.type) {
      case 'text':
        return (
          <TextInput
            source={`${source}.${fieldConfig.source}`}
            label={fieldConfig.label}
            fullWidth={fullWidth}
            disabled={disabled}
            multiline={fieldConfig.multiline}
            validate={fieldConfig.required ? required() : undefined}
          />
        );
      case 'enum':
        return (
          <SelectInput
            source={`${source}.${fieldConfig.source}`}
            label={fieldConfig.label}
            choices={fieldConfig.choices}
            fullWidth={fullWidth}
            disabled={disabled}
            validate={fieldConfig.required ? required() : undefined}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!excludeInputs?.includes('country') || !countryFieldState?.initial ? (
        <CountryInput
          source={countrySource}
          label={t('country')}
          validate={required()}
          fullWidth={fullWidth}
          disabled={disabled}
          solutionId={solutionId}
          // TODO: rewrite this
          defaultValue={defaultCountry}
          initialValue={defaultCountry}
        />
      ) : (
        <HiddenInput source={countrySource} defaultValue={defaultCountry} />
      )}
      {groupFields(applyExclude(data, excludeInputs))([['city', 'cityLocalized']])?.map((item) => {
        if (Array.isArray(item)) {
          return (
            <Grid container spacing={1}>
              {item.map((fieldConfig) => {
                return (
                  <Grid item xs={Math.floor(12 / item.length) as any}>
                    {getField(fieldConfig)}
                  </Grid>
                );
              })}
            </Grid>
          );
        }
        return getField(item);
      })}
      {!excludeInputs?.includes('geolocation') && (
        <>
          <H3>{t('geolocation')}</H3>
          <GeolocationInput
            source={`${source}.geolocation`}
            addLabel={false}
            defaultCountry={defaultCountry}
          />
        </>
      )}
      <TextInput source={`${source}.note`} label={t('note')} fullWidth={fullWidth} multiline />
    </>
  );
};

export const useTranslateAddress = () => {
  const translate = useTranslate();
  return (key: string): string => translate(`components.address.${key}`);
};

export default AddressForm;
