import React from 'react';
import { EditContextProvider } from 'react-admin';
import { useEditController } from 'react-admin';

import CreateAndEditForm, { CreateAndEditFormPropsBase } from '~/components/ra/CreateAndEditForm';

function EditForm<Record>(props: CreateAndEditFormPropsBase<Record>) {
  const { children, ...rest } = props;

  const editControllerProps = useEditController({
    basePath: '',
    ...props,
    id: props.resourceId || props.id || (props.record as any)?.id,
  });

  return (
    <EditContextProvider value={editControllerProps}>
      <CreateAndEditForm {...rest} type="update" withSaveConfirmation>
        {children}
      </CreateAndEditForm>
    </EditContextProvider>
  );
}

export default EditForm;
