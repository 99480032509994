export default {
  name: 'Batch |||| Batches',
  subtitle: 'Grouping of objects for import and export purposes',
  list: {
    filters: {
      fromCreatedAt: 'Date from',
      toCreatedAt: 'Date to',
      type: 'Type',
      actor: 'Actor (ID)',
      object: 'Object (ID)',
      status: 'Status',
      batchType: {
        PAYROLL: 'Payroll',
      },
    },
    empty: 'No batches found',
    timestamp: 'Timestamp',
    type: 'Type and description',
    actor: 'Actor',
    object: 'Object',
    numberOfRecords: '# of records',
    totalAmount: 'Total amount',
    status: 'Status',
  },
  show: {
    tabs: {
      transactions: 'Transactions',
      context: 'Context',
    },
    timestamp: 'Transaction date',
    actor: 'Actor',
    object: 'Object',
    status: 'Status',
    type: 'Type',
    numberOfRecords: '# of records',
    totalAmount: 'Total amount',
    file: 'File',
  },
};
