import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { useRightContentSpacing } from '~/hooks/styles';
import { HeadTransaction } from '~/types/HeadTransaction';
import AsideDetails from './AsideDetails';
import AsideReferences from './AsideReferences';

type Props = {
  record?: HeadTransaction;
};

const RightPanel: FC<any> = (props: Props) => {
  const { record: trx } = props;

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  if (!trx) {
    return null;
  }

  return (
    <Grid container item xs direction="column" spacing={spacing}>
      <Grid item className={classes.asideContent}>
        <AsideDetails record={trx} />
        {(trx.details.deal ||
          trx.details.reversalTransaction ||
          trx.details.revertedTransaction ||
          trx.details.approvalTransaction ||
          trx.details.approvedTransaction ||
          trx.details.settlementTransaction ||
          trx.details.settledTransaction ||
          trx.details.payroll) && <AsideReferences record={trx} />}
      </Grid>
    </Grid>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  asideContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
}));

export default RightPanel;
