import React, { useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import { CurrencyAutocomplete } from '~/components';
import { useHandbook } from '~/hooks';
import { useTenantCurrencies } from '~/hooks/tenant';

export interface Props {
  source: string;
  record?: any;
  label?: string;
  solutionId?: string;
  allChoices?: boolean;
  [k: string]: any;
}

interface CurrencyOption {
  id: string;
  name: string;
}

const CurrencySelect = ({ solutionId, record, source, onChange, allChoices, ...rest }: Props) => {
  const { data } = useHandbook(solutionId);
  const { data: tenantCurrencies } = useTenantCurrencies();
  const translate = useTranslate();

  const {
    input: { onChange: onChangeLocal },
    meta: { touched, error },
    isRequired,
  } = useInput({
    source,
    ...rest,
  });

  const initialValue = (record && lodashGet(record, source)) || null;
  const [value, setValue] = useState(initialValue);

  if (solutionId !== undefined) {
    if (data) {
      const currencies = (data?.currencies || [])
        .map((currency) => {
          return { id: currency.id, name: currency.id };
        })
        .sort((a: CurrencyOption, b: CurrencyOption) => {
          return a.id.localeCompare(b.id);
        });
      return (
        <CurrencyAutocomplete
          {...rest}
          label={translate(rest.label || source)}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            } else {
              onChangeLocal(e.target.value);
            }
            setValue(e.target.value);
          }}
          options={currencies.map((c) => c.id)}
          required={isRequired}
          error={touched && !!error}
          helperText={touched && error ? translate(error) : '\u0020'}
        />
      );
    } else {
      return (
        <CurrencyAutocomplete
          {...rest}
          value={null}
          label={translate(rest.label || source)}
          disabled
          options={[]}
          required={isRequired}
          helperText={touched && error ? translate(error) : '\u0020'}
        />
      );
    }
  } else {
    return (
      <CurrencyAutocomplete
        {...rest}
        label={translate(rest.label || source)}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            onChangeLocal(e.target.value);
          }
          setValue(e.target.value);
        }}
        options={allChoices ? undefined : tenantCurrencies?.map((c) => c.code) || []}
        required={isRequired}
        error={touched && !!error}
        helperText={touched && error ? translate(error) : '\u0020'}
      />
    );
  }
};

export default CurrencySelect;
