import React, { FC } from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import { Alert } from '@material-ui/lab';

import { EditButton } from '..';
import Confirmation from '../Confirmation';
import { useClearSecretWord } from '~/hooks/apiIdentity';
import { useApiIdentityTranslate } from '.';

interface Props {
  identityId: string | undefined;
  className?: string;
  disabled?: boolean;
}

const ClearSecretWordAction: FC<Props> = (props) => {
  const { className, identityId, disabled } = props;
  const t = useApiIdentityTranslate();

  const clearSecretWordMutation = useClearSecretWord();
  const handleClear = () => {
    if (identityId) {
      return clearSecretWordMutation.mutateAsync(identityId);
    }
  };

  return (
    <Confirmation
      onConfirm={handleClear}
      confirmButtonProps={{ loading: clearSecretWordMutation.isLoading }}
      confirmationSettings={{
        variant: 'modal',
        modal: { content: <Alert severity="warning">{t('clearSecretWordWarning')}</Alert> },
      }}
    >
      <EditButton
        className={className}
        label={t('resetCode')}
        icon={<ReplayIcon />}
        disabled={disabled}
        red
      />
    </Confirmation>
  );
};

export default ClearSecretWordAction;
