import React from 'react';
import { Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslate } from 'react-admin';
import { time } from '~/utils';
import { AvatarField } from '~/components/ra';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button } from 'react-admin';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RecordTitleButton } from '~/components';
import palette from '../theme/palette';
import { Changelog } from '~/types/Changelog';
import { getName, getTypeOfIdentity } from '~/utils/identities';
import { useTranslate } from 'react-admin';
import cx from 'classnames';
import { useReferenceEntity } from '~/hooks';
import ReferenceFiled from './ReferenceFiled';

interface Props {
  record: any;
  resource: string;
  avatarSource: string;
  headline: any;
  subtitle: any;
  translate: (key: string) => string;
  openDrawer: () => void;
  hasChangelogPermissionList: boolean;
  hasChangelogPermissionView: boolean;
  hasPathnameProfile: boolean;
  changelogMode: boolean;
  handleTurnOffChangelogMode: () => void;
  changelogsData: Changelog[];
  setSelectedChangelog: any;
  selectedChangelog: number;
  refetch: any;
}

const useStyles = makeStyles((theme) => {
  return {
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    root: {
      display: 'flex',
      width: '100%',
      flexGrow: 1,
    },
    avatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
    },
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '20px',
      paddingBottom: '7px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
    rightContent: {
      flexGrow: 1,
      width: '300px',
      minWidth: '300px',
      flexShrink: 0,
      alignSelf: 'center',
      marginTop: '-8px',
      /* eslint-disable no-useless-computed-key */
      ['@media (min-width: 1280px)']: {
        width: '360px',
      },
    },
    rightPaper: {
      backgroundColor: palette.changelog.yellow,
    },
    rightContainer: {
      padding: '8px',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    textField: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& > div': {
        alignItems: 'center',
        '& > div': {
          width: '145px',
          justifyContent: 'center',
          textAlign: 'center',
          /* eslint-disable no-useless-computed-key */
          ['@media (min-width: 1280px)']: {
            width: '190px',
          },
        },
      },
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline',
    },
    warning: {
      color: palette.warning.main,
    },
    skeleton: {
      display: 'inline-block',
      width: '50px',
      height: '18px',
    },
  };
});

const defaultSubtitle = (record: any, resource: string, translate: any) => {
  const parts = [];
  if (record.id) {
    parts.push(`${translate(`resources.${resource}.name`, 1)} #${record.id}`);
  }
  const created = record.created || record.createdAt;
  if (created) {
    const m = time(created);
    parts.push(
      translate('components.RecordTitle.createdAt') + ` ${m.format('l')} ${m.format('LT')}. `
    );
  }
  return parts.join(', ');
};

const RecordTitle = (props: Props) => {
  const {
    record,
    resource,
    avatarSource,
    headline,
    subtitle = defaultSubtitle,
    translate,
    openDrawer,
    hasChangelogPermissionList,
    hasChangelogPermissionView,
    hasPathnameProfile,
    changelogMode,
    handleTurnOffChangelogMode,
    changelogsData,
    setSelectedChangelog,
    selectedChangelog,
    refetch,
  } = props;
  const classes = useStyles();
  const lastUpdatedById: string = record?.lastUpdatedBy?.id ?? '';
  const lastUpdatedByType: string = getTypeOfIdentity(record?.lastUpdatedBy?.type ?? 'SYSTEM');
  const lastUpdatedBy = { id: lastUpdatedById, type: lastUpdatedByType };
  const { data, isLoading } = useReferenceEntity(lastUpdatedBy, hasChangelogPermissionList);

  // Current index of selected changelog.
  const currentItemIndex = changelogsData.findIndex((item) => item.id === selectedChangelog);
  // Navigation to prev and next changelog.
  const goToPrevChangelog = () => {
    setSelectedChangelog((prevId: number) => {
      const currentIndex = changelogsData.findIndex((item) => item.id === prevId);
      const nextIndex = currentIndex < changelogsData.length - 1 ? currentIndex + 1 : currentIndex;
      return changelogsData[nextIndex].id;
    });
  };
  const goToNextChangelog = () => {
    setSelectedChangelog((prevId: number) => {
      const currentIndex = changelogsData.findIndex((item) => item.id === prevId);
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      return changelogsData[prevIndex].id;
    });
  };
  const originatorName = getName?.[lastUpdatedBy.type]?.(data ?? {}) ?? lastUpdatedBy.id;
  const translateLabel = useTranslate();
  const label = (key: string, arg?: any) => translateLabel(`components.RecordTitle.${key}`, arg);
  return record ? (
    <div className={classes.gridContainer}>
      <div className={classes.root}>
        <div className={classes.avatar}>
          <AvatarField
            record={record}
            source={avatarSource}
            resource={resource}
            noText={true}
            size='50px'
          />
        </div>
        <div>
          {headline ? (
            <Typography variant='h2' className={classes.headline}>
              {headline(record, resource, translate)}
            </Typography>
          ) : null}
          {subtitle ? (
            <Typography variant='subtitle1' className={classes.subtitle}>
              {subtitle(record, resource, translate)}
              {hasChangelogPermissionList && hasPathnameProfile && !changelogMode && (
                <>
                  {label('lastUpdated', {
                    date: time(record.lastUpdatedAt).format('l'),
                    time: time(record.lastUpdatedAt).format('LT'),
                    by: isLoading ? label('loading') : originatorName,
                  })}
                  <RecordTitleButton
                    label={label('history')}
                    onClick={() => {
                      refetch();
                      openDrawer();
                    }}
                  />
                </>
              )}
              {hasChangelogPermissionList &&
                hasChangelogPermissionView &&
                hasPathnameProfile &&
                changelogMode && (
                  <>
                    <span className={classes.warning}>{label('warning')}</span>
                    {label('view')}
                    <RecordTitleButton
                      label={label('current')}
                      onClick={handleTurnOffChangelogMode}
                    />
                    {'. '}
                    <RecordTitleButton
                      label={label('history')}
                      onClick={() => {
                        refetch();
                        openDrawer();
                      }}
                    />
                  </>
                )}
            </Typography>
          ) : null}
        </div>
      </div>
      {hasChangelogPermissionList && hasChangelogPermissionView && changelogMode && (
        <div className={classes.rightContent}>
          <Paper className={classes.rightPaper}>
            <Grid className={classes.rightContainer} container xs={12} justify='space-between'>
              <Grid item>
                <Button
                  label={label('prev')}
                  onClick={() => goToPrevChangelog()}
                  disabled={currentItemIndex === changelogsData.length - 1}
                >
                  <ChevronLeftIcon />
                </Button>
              </Grid>
              <Grid item>
                <ReferenceFiled
                  className={cx(classes.textField, classes.ellipsis)}
                  label={`${label('version')} ${time(record?.lastUpdatedAt).format('l')} ${time(
                    record?.lastUpdatedAt
                  ).format('LT')}`}
                  record={lastUpdatedBy}
                />
              </Grid>
              <Grid item>
                <Button
                  label={label('next')}
                  alignIcon='right'
                  onClick={() => goToNextChangelog()}
                  disabled={currentItemIndex === 0}
                >
                  <ChevronRightIcon />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </div>
  ) : (
    <div />
  );
};

export default withTranslate(RecordTitle);
