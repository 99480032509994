import { Beneficiary, BeneficiaryAccount } from '~/types/beneficiaries';
import axios, { ResponseType } from '~/utils/axios';

export const getBeneficiariesList = (id: string, resource: 'businesses' | 'individuals') => {
  return axios.get<Beneficiary[]>(`/${resource}/${id}/beneficiaries?page=1&perPage=999`);
};

export const deleteBeneficiary = (id: string): ResponseType<void> => {
  return axios.delete(`/beneficiaries/${id}`);
};

export const getBeneficiaryAccounts = (
  beneficiaryId: string,
  params: any
): ResponseType<BeneficiaryAccount[]> => {
  return axios.get(`/beneficiaries/${beneficiaryId}/accounts`, { params });
};

export const deleteBeneficiaryAccount = (beneficiaryId: string, id: string): ResponseType<void> => {
  return axios.delete(`/beneficiaries/${beneficiaryId}/accounts/${id}`);
};
