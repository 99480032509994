import React from 'react';
import { Button, Link, withTranslate } from 'react-admin';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import { buildName } from '~/utils';

const ReferenceCreateButton = ({
  record,
  parentType,
  parentName,
  referenceType,
  translate,
  routerState,
  ...rest
}) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: `/${referenceType}/create`,
        state: {
          ...rest,
          parentId: record.id,
          parentName: parentName || buildName(parentType, record),
          resource: referenceType,
          parentType,
          ...(routerState ? routerState(record) : {}),
        },
      }}
      label={translate(`resources.${referenceType}.reference.create`)}
      style={{ marginTop: 8, marginBottom: 16 }}
    >
      <ChatBubbleIcon />
    </Button>
  );
};

export default withTranslate(ReferenceCreateButton);
