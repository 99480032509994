import React from 'react';
import MetricProgressField from './MetricProgressField';

import MetricSingleValueField from './MetricSingleValueField';

interface MetricFieldProps {
  name: string;
  value: string;
  target?: string;
}

export const MetricField = (props: MetricFieldProps) => {
  const { name, value, target } = props;

  if (target) {
    const progressPercent = Math.ceil((Number(value) / Number(target)) * 100);
    return (
      <MetricProgressField
        name={name}
        value={value}
        target={target}
        progressPercent={progressPercent}
      />
    );
  } else {
    return <MetricSingleValueField name={name} value={value} />;
  }
};
