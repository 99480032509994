import React, { FC } from 'react';
import { SelectArrayInput as RaSelectArrayInput } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  MenuPaper: {
    marginTop: 50,
  },
}));

const SelectArrayInput: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <RaSelectArrayInput
      options={{
        MenuProps: {
          classes: {
            paper: classes.MenuPaper,
          },
          variant: 'menu',
          getContentAnchorEl: null,
        },
      }}
      {...props}
    />
  );
};

export default SelectArrayInput;
