import React from 'react';
import { useTranslate } from 'react-admin';
import SelectInput from './SelectInput';

interface Props {
  onChange: (e: any) => void;
  value: string;
  name?: string;
  label?: string;
  optionsFilter?: (o: any) => boolean;
  [x: string]: any;
}

const OperationTypeInput = (props: Props) => {
  const { onChange, value, name, label, optionsFilter = () => true, ...rest } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(OperationTypeInput.l(key));

  const choices = OperationTypeInput.getChoices(translate, false) as any[];

  return (
    <SelectInput
      label={label || t('operationType')}
      options={choices.filter(optionsFilter) as any}
      onChange={onChange}
      value={value || ''}
      name={name || 'operationType'}
      {...rest}
    />
  );
};

OperationTypeInput.l = (key: string): string =>
  `components.TransactionsHistory.cellComponents.OperationTypeField.${key}`;

OperationTypeInput.getChoices = (translate: (str: string) => string, forReactAdmin = true) => {
  const t = (key: string) => translate(OperationTypeInput.l(key));
  const choices = [
    { value: '', text: t('any') },
    {
      value: 'P2P_TRANSFER',
      text: t('P2P_TRANSFER'),
    },
    { value: 'PURCHASE', text: t('PURCHASE') },
    { value: 'MEMBERSHIP', text: t('MEMBERSHIP') },
    { value: 'BILL_PAYMENT', text: t('BILL_PAYMENT') },
    { value: 'TOP_UP', text: t('TOP_UP') },
    { value: 'CASH_DEPOSIT', text: t('CASH_DEPOSIT') },
    { value: 'CASH_WITHDRAWAL', text: t('CASH_WITHDRAWAL') },
    { value: 'OWN_ACCOUNTS_TRANSFER', text: t('OWN_ACCOUNTS_TRANSFER') },
    { value: 'PAYOUT', text: t('PAYOUT') },
    { value: 'REMITTANCE', text: t('REMITTANCE') },
    { value: 'REMITTANCE_PAYOUT', text: t('REMITTANCE_PAYOUT') },
    { value: 'REVERSAL', text: t('REVERSAL') },
    { value: 'SERVICE_CHARGE', text: t('SERVICE_CHARGE') },
    { value: 'SETTLEMENT', text: t('SETTLEMENT') },
    { value: 'LOYALTY_TRANSFER', text: t('LOYALTY_TRANSFER') },
    { value: 'WRITE_OFF', text: t('WRITE_OFF') },
    { value: 'ACCOUNT_TRANSFER', text: t('ACCOUNT_TRANSFER') },
    { value: 'FORCED_APPROVAL', text: t('FORCED_APPROVAL') },
    { value: 'INCOMING_TRANSFER', text: t('INCOMING_TRANSFER') },
  ].sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
  return forReactAdmin ? choices.map((item) => ({ id: item.value, name: item.text })) : choices;
};

export default OperationTypeInput;
