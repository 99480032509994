import { useQuery } from 'react-query';
import { getProductTemplates } from '~/api/utils';
import { ProductTemplate } from '~/types';

interface UseProductTemplatesProps {
  enabled?: boolean;
  onSuccess?: (res: ProductTemplate[]) => void;
  onError?: (err?: any) => void;
}

export const useProductTemplates = (props?: UseProductTemplatesProps) => {
  const { enabled, onSuccess, onError } = props || {};
  return useQuery('productTemplates', getProductTemplates, {
    staleTime: Infinity,
    enabled,
    onSuccess(res) {
      onSuccess?.(res);
    },
    onError(err) {
      onError?.(err);
    },
  });
};
