import { isEqual } from 'lodash';
import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStackState,
  setStackStateAction,
  setStackStateWithCallbackAction,
} from '~/ducks/stackState';

type SetReduxStete<T> = (arg: T | ((prev: T) => T)) => void;

const useReduxState = <T = undefined>(key: string, initialState?: T): [T, SetReduxStete<T>] => {
  const dispatch = useDispatch();
  const isSetInitialState = useRef(false);
  const state = useSelector(getStackState(key));
  const resultState = isSetInitialState.current || state ? state : (initialState as T);

  const setReduxStateCallback: SetReduxStete<T> = (arg) => {
    if (typeof arg === 'function') {
      const callback = arg as (prev: T) => T;
      dispatch(setStackStateWithCallbackAction({ key, callback }));
    } else {
      dispatch(setStackStateAction({ key, data: arg }));
    }
  };
  const setReduxStateRef = useRef<SetReduxStete<T>>();
  setReduxStateRef.current = setReduxStateCallback;
  const setState = useCallback(
    (arg) => {
      setReduxStateRef.current && setReduxStateRef.current(arg);
    },
    [setReduxStateRef]
  );

  useEffect(() => {
    if (
      isSetInitialState.current === false &&
      (state !== undefined || isEqual(state, initialState))
    ) {
      isSetInitialState.current = true;
    }
  }, [state, initialState]);

  useEffect(() => {
    // console.log('initialState', initialState, isSetInitialState.current);
    if (initialState !== undefined && isSetInitialState.current === false) {
      if (setState) {
        setState(initialState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [resultState, setState];
};

export default useReduxState;
