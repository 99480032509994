import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import BankAccountForm from './BankAccountForm';
import { l } from './BankAccountList';

const BankAccountEdit: FC<{ permissions?: string[]; record: any; resource: string }> = (props) => {
  const { permissions, record, resource } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('bank.account.update')} />
      <Drawer heading={t('editBank')} open={open} onClose={handleClose}>
        <EditForm resource={resource} record={record} closeParent={handleClose}>
          <BankAccountForm edit />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default BankAccountEdit;
