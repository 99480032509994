import React, { FC, Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';

import { Link } from '~/components';
import { OpenIcon } from '~/img';
import { buildProfileLink, Longdash } from '~/utils';
import Scrollbars from 'react-custom-scrollbars';
import { useCopy } from '~/hooks/clipboard';

export interface ShowComponent {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  record?: any;
  [x: string]: any;
}

type Children = JSX.Element | JSX.Element[] | string | number | undefined | false | null;

interface Props {
  icon?: any;
  label?: string;
  children?: Children;
  className?: string;
  title?: string;
  multiline?: boolean;
  textWrap?: boolean;
  ellipsis?: boolean;
  loading?: boolean;
  showComponent?: (props: ShowComponent) => JSX.Element;
  record?: any;
  addLabel?: boolean;
  error?: Error;
  showLink?: string | false;
  noPaddingLeft?: boolean;
  compact?: boolean;
  copyable?: boolean | string;
  style?: any;
  skeletonWidth?: number;
  [x: string]: any;
}

const TextField = (props: Props) => {
  const {
    icon,
    label,
    children,
    className = '',
    title,
    multiline,
    textWrap,
    loading,
    showComponent,
    record,
    addLabel = true,
    error,
    showLink,
    noPaddingLeft = false,
    compact = false,
    copyable: defaultCopyable = true,
    style,
    ellipsis,
    skeletonWidth = 100,
    ...rest
  } = props;
  const valueToCopy =
    typeof defaultCopyable === 'string'
      ? defaultCopyable
      : typeof children === 'string'
      ? children
      : '';
  const copyable = !!valueToCopy;

  const translate = useTranslate();
  const classes = useStyles({ copyable });

  const handleChildren = (children: Children) => {
    switch (typeof children) {
      case 'number':
        return children;
      default:
        return children || Longdash;
    }
  };

  const copy = useCopy();

  const handleCopy: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    if (copyable) {
      e.stopPropagation();
      await copy(valueToCopy);
    }
  };
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (copyable) {
      e.stopPropagation();
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      className={cx(
        classes.root,
        cx({
          [className]: !!className && !className.includes('RaReferenceField-link'),
          [classes.noPaddingLeft]: noPaddingLeft,
        })
      )}
    >
      {icon &&
        (loading ? (
          <Skeleton className={classes.icon} variant="circle" width={20} height={20} />
        ) : (
          <SvgIcon className={classes.icon}>{icon}</SvgIcon>
        ))}
      <div
        className={cx(classes.labeledField, {
          [classes.fullWidth]: multiline,
          [classes.compactLabel]: compact,
        })}
      >
        {addLabel && (
          <label>
            {loading ? (
              <Skeleton width={40} height={10} />
            ) : label?.includes('.') ? (
              translate(label)
            ) : (
              label
            )}
          </label>
        )}
        <div
          title={title}
          className={cx(classes.children, {
            [className]: className?.includes('RaReferenceField-link'),
            [classes.ellipsis]: !multiline && !textWrap && !showComponent,
            [classes.multilineEllipsisBack]: multiline,
            [classes.inlineFlex]: !ellipsis && (showComponent || showLink || copyable),
            [classes.error]: error,
            [classes.owner]: showLink === buildProfileLink.disabled,
          })}
          style={style}
          onDoubleClick={handleCopy}
          onClick={stopPropagation}
        >
          {error ? (
            error.message
          ) : multiline ? (
            <Scrollbars style={{ width: '100%' }} autoHide autoHeight autoHeightMax={64}>
              <div className={classes.multilineEllipsis}>{children}</div>
            </Scrollbars>
          ) : loading ? (
            <Skeleton width={skeletonWidth} height={16} />
          ) : (
            handleChildren(children)
          )}
          {showComponent && (
            <Fragment>
              <OpenButtom handleOpen={handleOpen} />
              {showComponent({ open, handleClose, handleOpen, record, ...rest })}
            </Fragment>
          )}
          {showLink &&
            !loading &&
            children &&
            (showLink === buildProfileLink.disabled ? (
              <OpenButtom disabled={showLink === buildProfileLink.disabled} />
            ) : (
              <Link
                href={showLink}
                target="_blank"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <OpenButtom />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

const OpenButtom: FC<{ handleOpen?: () => void; [x: string]: any }> = (props) => {
  const { handleOpen, ...rest } = props;
  const classes = useOpenButtonStyles();
  return (
    <div className={classes.openIcon}>
      <IconButton onClick={handleOpen} {...rest}>
        <SvgIcon>
          <OpenIcon title="show" />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  noPaddingLeft: {
    marginLeft: theme.spacing(4) * -1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  labeledField: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    '& label': {
      marginBottom: 2,
      fontSize: 10,
    },
  },
  compactLabel: {
    '& label': {
      marginBottom: 0,
    },
  },
  children: {
    fontWeight: 500,
    fontSize: 14,
    cursor: ({ copyable }: any) => (copyable ? 'copy' : 'default'),
  },
  ellipsis: {
    display: 'inline',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  multilineEllipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '&:hover': {
      display: 'inline',
      overflow: 'auto',
    },
  },
  multilineEllipsisBack: {
    transition: `background-color ${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shorter}ms`,
    maxHeight: 64,
    '&:hover': {
      marginTop: -2,
      marginBottom: -2,
      marginLeft: -4,
      marginRight: -4,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  inlineFlex: {
    display: 'inline-flex',
    overflow: 'unset',
  },
  fullWidth: {
    width: '100%',
  },
  owner: {
    color: theme.palette.warning.main,
  },
}));

const useOpenButtonStyles = makeStyles(() => ({
  openIcon: {
    position: 'relative',
    width: 14,
    height: 14,
    marginRight: 3,
    '& button': {
      position: 'absolute',
      top: -8,
      left: -1,
      width: 14,
      height: 14,
      '& svg': {
        width: 14,
        height: 14,
      },
    },
  },
}));

export default TextField;
