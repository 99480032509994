import React, { Fragment, useState } from 'react';
import { useTranslate, Button, crudGetManyReference } from 'react-admin';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { FlashIcon } from '~/img';
import { Drawer, PhoneNumberInput } from '~/components';
import { Employee } from '~/types';
import { createAccessCode } from '~/api/employees';
import usePath from '~/hooks/usePath';
import onError from '~/errorsHandler';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  accessCode: {
    paddingBottom: theme.spacing(3),
  },
}));

interface Props {
  employeeRecord: Employee;
  permissions: string[];
}

const GetAccessCode = (props: Props) => {
  const { employeeRecord, permissions } = props;
  const employeeLinkedPhone = employeeRecord.phoneNumber;

  const [loading, setLoading] = useState(false);
  const [accessCode, setAccesssCode] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [phone, setPhone] = useState(employeeLinkedPhone || '');
  const handlePhone = (value: string) => setPhone(value);

  const dispatch = useDispatch();
  const {
    params: { page, perPage },
  } = usePath();

  const handleAccessCode = async () => {
    setLoading(true);
    try {
      const resource = 'employees';
      const target = 'businesses';
      const businessId = employeeRecord.businessId;
      const { data } = await createAccessCode(employeeRecord.id, phone);
      dispatch(
        crudGetManyReference(
          resource,
          target,
          businessId,
          `${target}_${resource}@${target}_${businessId}`,
          { page, perPage },
          { field: 'id', order: 'DESC' },
          {}
        )
      );
      setAccesssCode(data.accessCode);
    } catch (error) {
      onError(error);
    }
    setLoading(false);
  };

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.employees.${key}`);

  const classes = useStyles();

  return (
    <Fragment>
      <Button
        label={translateLabel('getAccessCode')}
        onClick={handleOpen}
        className={classes.warningColor}
        disabled={!permissions.includes('business.employee.update')}
      >
        <SvgIcon>
          <FlashIcon />
        </SvgIcon>
      </Button>
      <Drawer
        heading={`${translateLabel('getAccessCodeFor')} ${employeeRecord.firstName || ''} ${
          employeeRecord.lastName || ''
        }`}
        open={open}
        onClose={handleClose}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <PhoneNumberInput
              label={translateLabel('phoneSmsCode')}
              value={phone}
              onChange={handlePhone}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              label={translateLabel('getAccessCode')}
              variant="contained"
              onClick={handleAccessCode}
              loading={loading}
            >
              <SvgIcon>
                <FlashIcon />
              </SvgIcon>
            </Button>
          </Grid>
        </Grid>
        <Drawer
          heading={`${translateLabel('getAccessCodeFor')} ${employeeRecord.firstName || ''} ${
            employeeRecord.lastName || ''
          }`}
          open={!!accessCode}
          onClose={() => setAccesssCode('')}
        >
          <Typography variant="h3" className={classes.accessCode}>
            {accessCode}
          </Typography>
          <Typography variant="body2">{translateLabel('accessCodeMessage')}</Typography>
        </Drawer>
      </Drawer>
    </Fragment>
  );
};

export default GetAccessCode;
