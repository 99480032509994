import React, { ChangeEventHandler, FC, Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate } from 'react-admin';
import { time } from '~/utils';
import InputBase from '@material-ui/core/InputBase';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { EditButton, H2, HilightChanges, StatusField, TextField } from '~/components';
import { Section } from '~/layout';
import { ActionReport } from '~/types';
import { resource } from '../ActionReportsList';
import { buildProfileLink } from '~/utils';
import { Button } from '~/components/ra';
import { useActionReportSchemaFields } from '~/hooks/actionReports';
import RevokeAction from './RevokeAction';

const ReportData: FC<any> = (implicitProps) => {
  const { record, onChange, initialState, onEditStart, onEditEnd } = implicitProps as {
    record: ActionReport;
    initialState?: ActionReport;
    onChange?: (record: ActionReport) => void;
    basePath: string;
    resource: string;
    onEditStart?: () => void;
    onEditEnd?: () => void;
  };
  const reportSchemaQuery = useActionReportSchemaFields(record.system, record.reportType);

  const [edititonState, setEditionState] = useState(record);
  const [isDataEdit, setDataEdit] = useState(false);
  const handleDataEditStart = () => {
    setDataEdit(true);
    if (onEditStart) onEditStart();
  };
  const handleDataEditCancel = () => {
    setDataEdit(false);
    setEditionState(record);
    if (onEditEnd) onEditEnd();
  };
  const handleApplyChanges = () => {
    setDataEdit(false);
    if (onChange) onChange(edititonState);
    if (onEditEnd) onEditEnd();
  };

  const handleChangeData = (
    name: string
  ): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> => (e) => {
    const value = e.currentTarget?.value || '';
    setEditionState((prev) => ({
      ...prev,
      data: { ...prev.data, [name]: value },
    }));
  };

  const data = isDataEdit ? edititonState : record;
  const getFieldsData = () => {
    let hasFirstEditable = false;
    return (
      data.data &&
      reportSchemaQuery.data
        ?.filter(({ key }) => data.data[key] !== undefined)
        .map((item) => {
          let isFirstEditable = false;
          if (item.isEditable && !hasFirstEditable) {
            isFirstEditable = true; // used to focus first editable input in the table
            hasFirstEditable = true;
          }
          return { ...item, value: data.data[item.key], isFirstEditable };
        })
    );
  };

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);

  const classes = useStyles();
  return (
    <Fragment>
      <Section noPaddings className={classes.bottomSpacing3}>
        <Grid container justify="space-between">
          <Grid item>
            <H2 topSpacing={0} bottomSpacing={2}>
              {t('details')}
            </H2>
          </Grid>
          {!onChange && data.status === 'INITIAL' && (
            <Grid item>
              <RevokeAction report={data} />
            </Grid>
          )}
        </Grid>
        <Section>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField label={t('system')}>{data.system}</TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField label={t('reportType')}>{data.reportType}</TextField>
            </Grid>
            <Grid item xs={4}>
              {data.status && (
                <TextField label={t('status')}>
                  <StatusField status={data.status} />
                </TextField>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField label={t('objectType')}>{t(data.objectType)}</TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t('objectTitle')}
                showLink={buildProfileLink('', data.objectId, data.objectType)}
              >
                {data.objectName}
              </TextField>
            </Grid>
          </Grid>
        </Section>
      </Section>

      <Section noPaddings className={classes.bottomSpacing3}>
        <Grid container justify="space-between">
          <Grid item>
            <H2 topSpacing={0} bottomSpacing={2}>
              {t('data')}
            </H2>
          </Grid>
          {onChange && (
            <Grid
              item
              container
              spacing={2}
              xs={4}
              justify="flex-end"
              id={isDataEdit ? '' : 'section-actions'}
            >
              {isDataEdit ? (
                <Fragment>
                  <Grid item>
                    <Button label="ra.action.cancel" onClick={handleDataEditCancel} />
                  </Grid>
                  <Grid item>
                    <Button label={t('apply')} variant="contained" onClick={handleApplyChanges} />
                  </Grid>
                </Fragment>
              ) : (
                <Grid item>
                  <EditButton onClick={handleDataEditStart} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Table className={cx(classes.table, { [classes.autoWidth]: !onChange })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('field')}</TableCell>
                  <TableCell>{t('data')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFieldsData()?.map(({ key, value, isEditable, isFirstEditable }) => {
                  return (
                    <TableRow key={key}>
                      <TableCell title={key}>{makeShorter(key)}</TableCell>
                      <TableCell>
                        {isDataEdit ? (
                          <InputBase
                            autoFocus={isFirstEditable}
                            className={cx(classes.noPaddingInput, {
                              [classes.editableInput]: isEditable,
                            })}
                            value={value}
                            onChange={handleChangeData(key)}
                            disabled={!isEditable}
                            multiline={isEditable}
                            fullWidth
                          />
                        ) : initialState ? (
                          <HilightChanges prev={initialState.data[key]} curr={value} emptyText="">
                            {value}
                          </HilightChanges>
                        ) : (
                          value
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Section>

      {data.log && (
        <Section noPaddings className={classes.bottomSpacing3}>
          <Grid container justify="space-between">
            <Grid item>
              <H2 topSpacing={0} bottomSpacing={2}>
                {t('log')}
              </H2>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Table style={{ width: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 200 }}>{t('dateAndTime')}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{t('action')}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{t('actor')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.log.map(({ timestamp, message, identity }) => {
                    return (
                      <TableRow key={timestamp}>
                        <TableCell>{time(timestamp).format('LL LT')}</TableCell>
                        <TableCell>{message}</TableCell>
                        <TableCell>{identity?.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Section>
      )}
    </Fragment>
  );
};

const makeShorter = (key: string, maxDepth = 2) => {
  return key
    .split('.')
    .map((value, index, arr) => {
      const canBeFull = index >= arr.length - maxDepth;
      return canBeFull ? value : value.slice(0, 1);
    })
    .join('.');
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    paddingBottom: theme.spacing(2),
  },
  bottomSpacing3: {
    paddingBottom: theme.spacing(3),
  },
  noPaddingInput: {
    '& > input': {
      padding: 0,
      paddingRight: 8,
    },
  },
  editableInput: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    margin: -4,
  },
  table: {
    '& > tbody': {
      '& > tr': {
        '& > td': {
          maxWidth: 500,
          overflowWrap: 'break-word',
        },
      },
    },
  },
  autoWidth: {
    width: 'auto',
  },
}));

export default ReportData;
