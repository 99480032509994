import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Container, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import H2 from '~/components/H2';

interface Props {
  open: boolean;
  onClose: () => void;
  heading: string;
  submitLabel?: string;
  subtitle?: string;
  loading?: boolean;
  onSubmit?: () => void;
  submitDisabled?: boolean;
}

const FullscreenDrawer: FC<Props> = (props) => {
  const {
    open,
    onClose,
    heading,
    subtitle,
    children,
    // submitLabel = 'ra.action.create',
    // loading,
    // onSubmit,
    // submitDisabled,
  } = props;

  const classes = useStyles();

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={7}>
          <Grid container item xs={12} alignItems="center" justify="space-between">
            <Grid item xs>
              <H2 topSpacing={0} bottomSpacing={0} className={classes.title}>
                {heading}
              </H2>
              {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
            </Grid>
            <Grid container item justify="flex-end" spacing={2} xs={4}>
              <Grid item>
                <IconButton aria-label="Close" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
                {/* <Button
                  label="ra.action.cancel"
                  onClick={onClose}
                  className={classes.red}
                  size="large"
                >
                  <SvgIcon>
                    <CloseCircleIcon />
                  </SvgIcon>
                </Button> */}
              </Grid>
              {/* <Grid item>
                <Button
                  label={submitLabel}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={onSubmit}
                  loading={loading}
                  disabled={submitDisabled}
                >
                  <SvgIcon>
                    <ArrowForwardCircleIcon />
                  </SvgIcon>
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Container>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: '32px',
  },
  red: {
    color: theme.palette.error.main,
  },
}));

export default FullscreenDrawer;
