import React, { FC } from 'react';

import { ExportButton } from '~/components';
import BackgroundTask, { useBackgroundTaskTranslate } from '~/components/BackgroundTask';
import { HeadTransaction } from '~/types/HeadTransaction';

interface Props {
  headTransaction: HeadTransaction;
}

const ExportInvoice: FC<Props> = (props) => {
  const { headTransaction } = props;
  const t = useBackgroundTaskTranslate();

  const hasInvoice =
    (headTransaction.details.deal || headTransaction.details.remittance) &&
    (headTransaction.operationType === 'REMITTANCE' ||
      headTransaction.operationType === 'BILL_PAYMENT' ||
      headTransaction.operationType === 'PAYOUT');
  if (!hasInvoice) {
    return null;
  }

  return (
    <BackgroundTask
      type='TENANT_INVOICE'
      queryArguments={{ headTransactionId: headTransaction?.id }}
      dialogTitle={t('downloadPDF')}
      dialogType='export'
    >
      <ExportButton label={t('invoice')} />
    </BackgroundTask>
  );
};

export default ExportInvoice;
