import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { Drawer, TextField, StatusField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Pricing, Solution } from '~/types';
import PricingEdit from './PricingEdit';
import { Longdash } from '~/utils';
import { useHandbook } from '~/hooks';
import { Alert } from '@material-ui/lab';

export const l = (key: string): string => `resources.solutions.pricings.${key}`;

interface Props {
  record: Pricing | null;
  solutionRecord: Solution;
  onClose: () => void;
  basePath: string;
  permissions?: string[];
}

const PricingShow = (props: Props) => {
  const { record, solutionRecord, onClose, basePath } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { data } = useHandbook(record?.solutionId);
  const kycLevels = data?.kycLevels || [];
  const kycLevel = kycLevels.find((level) => level.id === record?.kycLevel);
  const kycLevelName = kycLevel?.name;
  if (!record) return null;
  return (
    <Fragment>
      <Drawer heading={t('showPricing')} open={!!record} onClose={onClose}>
        <Grid container item spacing={2}>
          {record.format === 'v2' ? (
            <>
              <Grid item xs={12}>
                <TextField label={t('name')}>{record.name}</TextField>
              </Grid>

              {record.productId && (
                <Grid item xs={12}>
                  <ReferenceField
                    source='productId'
                    record={record}
                    basePath={basePath}
                    reference='products'
                    link={false}
                  >
                    <RaTextField source='name' label={t('paymentInstrument')} />
                  </ReferenceField>
                </Grid>
              )}
              {record.externalSourceType && (
                <Grid item xs={12}>
                  <TextField label={t('paymentInstrument')}>{record.externalSourceType}</TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField label={t('profileKycLevel')}>{kycLevelName}</TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('profileStatus')}>
                  <StatusField status={record.accountStatus} allowEmpty emptyText={t('any')} />
                </TextField>
              </Grid>
              {/*
            <Grid item xs={12}>
              <TextField label={t('tags')} textWrap>
                {record.tags.map((name) => {
                  return <ChipField record={{ name }} source="name" />;
                })}
              </TextField>
            </Grid>
              */}
              <Grid item xs={12}>
                <TextField label={t('validityFrom')}>
                  {record.activeFrom && moment(record.activeFrom).format('LL')}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('validityTo')}>
                  {record.activeFrom && moment(record.activeTill).format('LL')}
                </TextField>
              </Grid>
              <Grid item container justify='flex-end'>
                <Grid item>
                  <PricingEdit record={record} solutionRecord={solutionRecord} onClose={onClose} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Alert severity='warning'>{t('showOldWarning')}</Alert>
              <Grid item xs={12}>
                <TextField label={t('name')}>{record.name}</TextField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField
                  source='productId'
                  record={record}
                  basePath={basePath}
                  reference='products'
                  link={false}
                >
                  <RaTextField source='name' label={t('product')} allowEmpty emptyText={Longdash} />
                </ReferenceField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('kycLevel')}>{kycLevelName}</TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('accountStatus')}>
                  <StatusField status={record.accountStatus} allowEmpty emptyText={t('any')} />
                </TextField>
              </Grid>
              {/*
            <Grid item xs={12}>
              <TextField label={t('tags')} textWrap>
                {record.tags.map((name) => {
                  return <ChipField record={{ name }} source="name" />;
                })}
              </TextField>
            </Grid>
              */}
              <Grid item xs={12}>
                <TextField label={t('activeFrom')}>
                  {record.activeFrom && moment(record.activeFrom).format('LL')}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('activeTill')}>
                  {record.activeFrom && moment(record.activeTill).format('LL')}
                </TextField>
              </Grid>
              <Grid item container justify='flex-end'>
                <Grid item>
                  <PricingEdit record={record} solutionRecord={solutionRecord} onClose={onClose} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default PricingShow;
