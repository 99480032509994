import { getExternalSource, getExternalSources } from './../api/externalSources';
import { useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { useQuery } from 'react-query';
import { getExternalSourceBalance } from '~/api/externalSources';

export const useGetAllExternalSources = (
  params: { resource: 'businesses' | 'individuals'; profileId: string },
  options: { enabled?: boolean } = {}
) => {
  const { resource, profileId } = params;

  const { permissions } = usePermissions();
  const hasAccess = useMemo((): boolean => {
    if (!permissions) return false;
    return permissions.includes('externalSource.list');
  }, [permissions]);

  return useQuery(
    [resource, profileId, 'externalSource'],
    () => getExternalSources(resource, profileId),
    {
      enabled: hasAccess,
      ...options,
    }
  );
};

export const useExternalSourceBalance = (id?: string) => {
  return useQuery(['externalSource', id, 'balance'], () => getExternalSourceBalance(id!), {
    enabled: !!id,
  });
};

export const useExternalSource = (id?: string) => {
  return useQuery(['externalSource', id], () => getExternalSource(id!), { enabled: !!id });
};
