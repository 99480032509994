import React from 'react';
import { Button } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';

import { OpenIcon } from '~/img';

import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { useTranslate } from 'react-admin';
import { Link } from '~/components';

interface Props {
  link: ExternalProfileLink;
}

interface Props {
  link: ExternalProfileLink;
  [x: string]: any;
}

const LinkButton = (props: Props) => {
  const { link, ...rest } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ExternalProfileLinks.${key}`);

  const onClick = () => {
    return false;
  };

  return (
    <Link href={`/externalProfileLinks/${link.id}/show`} target="_blank">
      <Button label={t('linkAction')} onClick={onClick} {...rest}>
        <SvgIcon>
          <OpenIcon title="show" />
        </SvgIcon>
      </Button>
    </Link>
  );
};

export default LinkButton;
