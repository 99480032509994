import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useEditController, ComponentPropType } from 'ra-core';

import { ShowActions as DefaultActions } from 'react-admin';

import { buildName } from '../utils';
import RecordTitle from './RecordTitle';

const Edit = (props) => <EditView {...props} {...useEditController(props)} />;

Edit.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string.isRequired,
  title: PropTypes.node,
  successMessage: PropTypes.string,
};

export const EditView = ({
  actions,
  aside,
  basePath,
  children,
  classes: classesOverride,
  className,
  component: Content,
  hasShow,
  record,
  redirect,
  resource,
  save,
  saving,
  title = <RecordTitle headline={(record, resource) => buildName(resource, record) || '???'} />,
  undoable,
  version,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  if (typeof actions === 'undefined' && hasShow) {
    actions = <DefaultActions />;
  }
  if (!children) {
    return null;
  }
  return (
    <div className={classnames('edit-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      <div style={{ display: 'flex' }}>
        {title &&
          cloneElement(title, {
            record,
            resource,
            ...title.props,
          })}
        {actions &&
          cloneElement(actions, {
            basePath,
            data: record,
            // hasShow,
            // hasList,
            resource,
            //  Ensure we don't override any user provided props
            ...actions.props,
          })}
      </div>
      <div
        className={classnames(classes.main, {
          [classes.noActions]: !actions,
        })}
      >
        <Content className={classes.card}>
          {record ? (
            cloneElement(Children.only(children), {
              basePath,
              record,
              redirect:
                typeof children.props.redirect === 'undefined' ? redirect : children.props.redirect,
              resource,
              save,
              saving,
              undoable,
              version,
            })
          ) : (
            <CardContent>&nbsp;</CardContent>
          )}
        </Content>
        {aside &&
          React.cloneElement(aside, {
            basePath,
            record,
            resource,
            version,
            save,
            saving,
          })}
      </div>
    </div>
  );
};

EditView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  component: ComponentPropType,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
  version: PropTypes.number,
};

EditView.defaultProps = {
  classes: {},
  component: Card,
};

const useStyles = makeStyles(
  {
    root: {},
    main: {
      display: 'flex',
    },
    noActions: {
      marginTop: '1em',
    },
    card: {
      flex: '1 1 auto',
    },
  },
  { name: 'RaEdit' }
);

/* eslint-disable */
const sanitizeRestProps = ({
  data,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  id,
  loading,
  loaded,
  saving,
  resource,
  title,
  version,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  undoable,
  successMessage,
  translate,
  ...rest
}) => rest;
/* eslint-enable */

export default Edit;
