import React from 'react';
import { Filter, TextInput, SelectInput, useTranslate } from 'react-admin';
import { List } from '~/layout';
import { PricingDatagrid } from '~/resources/solutions/SolutionShow';
import { LocalSort, DateTimeInput, ReferenceInput, KycLevelSelect } from '~/components/ra';
import { makeStyles } from '@material-ui/core/styles';

const PricingFilters = (props: any) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.pricings.${key}`);
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.form}>
      <TextInput className={classes.formControl} label='Name' source='name' alwaysOn />
      <ReferenceInput
        className={classes.formControl}
        source='solutionId'
        reference='solutions'
        allowEmpty
        emptyText={t('any')}
        alwaysOn
      >
        <SelectInput optionText='name' />
      </ReferenceInput>
      <KycLevelSelect
        className={classes.formControl}
        label={t('kycLevel')}
        source='kycLevel'
        alwaysOn
      />
      <DateTimeInput
        className={classes.formControl}
        source='activeFrom'
        label={t('activeFrom')}
        options={{ format: 'LL LT', clearable: true }}
        fullWidth
        alwaysOn
      />
    </Filter>
  );
};

const PricingList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      filters={<PricingFilters />}
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <LocalSort>
        <PricingDatagrid rowClick='show' size='medium' />
      </LocalSort>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: {
    width: 200,
  },
}));

export default PricingList;
