import React from 'react';
import { Tab, useTranslate } from 'react-admin';

import { AsideBase, PaperTabbedShowLayout, RecordTitle, Show } from '~/layout';
import { Deal } from '~/types/Deal';
import { amountToString } from '~/utils/amountFormat';
import ShowDetails from './components/ShowDetails';
import BeneficiaryInfo from './components/BeneficiaryInfo';
import { NoPermissions } from '~/components/ra';
import Grid from '@material-ui/core/Grid';

import { useRightContentSpacing } from '~/hooks/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AmountField, CountryField, Originator, StatusField, TextField } from '~/components';
import { useShowController } from 'react-admin';
import OperationTypeField from '~/components/OperationTypeField';
import { time } from '~/utils';
import { RibbonIcon, TimeIcon } from '~/img';
import PublicIcon from '@material-ui/icons/Public';

const label = (key: string): string => `resources.deals.${key}`;

const DealShow = (props: any) => {
  const { permissions } = props;
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const t = (key: string) => translate(`resources.deals.show.details.${key}`);

  // Get the record data
  const showController = useShowController(props);
  const showRecord: Deal | undefined = showController.record;

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });
  if (!showRecord) return null;
  if (!permissions) return null;
  if (!permissions?.includes('deal.view')) return <NoPermissions />;
  return (
    <Show
      {...props}
      title={
        <RecordTitle
          headline={(record: Deal) => {
            const remitterAmountText = amountToString(record.remitterAmount);
            const beneficiaryAmountText = amountToString(record.beneficiaryAmount);
            const recordTitle = translateLabel(`recordTitle`);
            return `${recordTitle} ${record.senderCountry} → ${record.recipientCountry} (${remitterAmountText} → ${beneficiaryAmountText})`;
          }}
        />
      }
      actions={null}
    >
      <PaperTabbedShowLayout>
        <Tab label={translateLabel('tabs.details')}>
          <BeneficiaryInfo />
          <ShowDetails permissions={permissions} />
        </Tab>
      </PaperTabbedShowLayout>
      <Grid container item xs direction='column' spacing={spacing}>
        <Grid item className={classes.rightContent}>
          <AsideBase>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label={t('sentAt')} icon={<TimeIcon />}>
                  {showRecord.sentAt && time(showRecord.sentAt).format('ll, LTS')}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('receivedAt')} icon={<></>}>
                  {showRecord.receivedAt && time(showRecord.receivedAt).format('ll, LTS')}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('status')} icon={<RibbonIcon />}>
                  <StatusField status={showRecord.status} />
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('operationType')} icon={<PublicIcon />}>
                  <OperationTypeField operationType={showRecord.operationType} />
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <AmountField
                  amount={showRecord.dealAmount.value === 0 ? undefined : showRecord.dealAmount}
                  label={t('dealAmount')}
                  icon={<></>}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.marginLeftAside}>
                  <Grid item xs={5}>
                    <TextField label={t('sentFrom')}>
                      <CountryField>{showRecord.senderCountry}</CountryField>
                    </TextField>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField label={t('sentTo')}>
                      <CountryField>{showRecord.recipientCountry}</CountryField>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.marginLeftAside}>
                  <Grid item xs={5}>
                    <AmountField amount={showRecord.remitterAmount} label={t('remitterAmount')} />
                  </Grid>
                  <Grid item xs={5}>
                    <AmountField
                      amount={showRecord.beneficiaryAmount}
                      label={t('beneficiaryAmount')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AsideBase>
        </Grid>
        <Originator record={showRecord.originator} />
      </Grid>
    </Show>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  marginLeftAside: {
    marginLeft: '24px',
  },
}));

export default DealShow;
