import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { useHandbook } from '~/hooks';

import * as hb from '~/types/Handbook';
import { isEmpty } from 'lodash';

interface Props {
  source: string;
  solutionIdSource?: string;
  solutionId?: string;
  record?: any;
  label?: string;
  disabled?: boolean;
}

const LanguageSelect = (props: Props) => {
  const { source, record, solutionIdSource, label, disabled } = props;

  const translate = useTranslate();

  const getSolutionId = () => {
    if (props.solutionId) return props.solutionId;
    return lodashGet(record, solutionIdSource || 'solutionId');
  };

  const getChoices = (data?: hb.Handbook) => {
    if (!isEmpty(data?.localizations)) {
      return (data?.localizations || []).map((item: hb.GenericRecord) => ({
        id: item.id,
        name: item.name,
      }));
    } else {
      return [{ id: undefined, name: translate('utils.noHandbookValues') }];
    }
  };

  const { data } = useHandbook(getSolutionId());

  return (
    <SelectInput
      label={label}
      source={source}
      choices={getChoices(data)}
      disabled={disabled}
      defaultValue="en-US"
    />
  );
};

LanguageSelect.defaultProps = {
  label: 'components.ra.inputs.LanguageSelect',
  disabled: false,
};

export default LanguageSelect;
