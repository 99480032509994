import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import RoleForm from './RoleForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { l } from './RoleShow';
import { Role } from '~/types';

interface Props {
  role?: Role;
  permissions?: string[];
}

const IdentityEdit = (props: Props) => {
  const { role, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  if (!role) return null;
  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('dashboardRole.update')} />
      <Drawer heading={t('editRole')} open={open} onClose={handleClose}>
        <EditForm resource="roles" record={role}>
          <RoleForm edit role={role} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default IdentityEdit;
