import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import cx from 'classnames';

import { hasTranslation, Longdash } from '~/utils';
import { OperationType } from '~/types/OperationType';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    fontSize: '12px',
  },
}));

const label = (key: string): string => `operationType.${key}`;

interface Props {
  operationType?: OperationType;
  variant?: 'text' | 'dot';
  allowEmpty?: boolean;
  emptyText?: any;
}

const OperationTypeField = (props: Props) => {
  const { operationType, variant = 'text', allowEmpty, emptyText = Longdash } = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (!operationType) {
    if (allowEmpty) return <span>{emptyText}</span>;
    return null;
  }

  const st = (classes as any)[operationType] || {};

  return (
    <div className={cx(classes.root, { [st]: !!operationType })}>
      {variant === 'text' &&
        (hasTranslation(label(`${operationType}`))
          ? translate(label(`${operationType}`))
          : operationType)}
      {variant === 'dot' && <FiberManualRecordIcon className={classes.dot} />}
    </div>
  );
};

export default OperationTypeField;
