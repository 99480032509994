import React from 'react';
import { ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';

import { TextField } from '~/components/ra';
import { StatusField } from '~/components';
import { RibbonIcon } from '~/img';
import { Partner } from '~/types';
import { AsideBase } from '~/layout';

interface Props {
  [x: string]: any;
  record?: Partner;
}

const Aside = (props: Props) => {
  const classes = useStyles();

  const { status, deleted } = props.record || {};

  return (
    <AsideBase>
      <Grid container spacing={2} className={classes.layout}>
        <Grid item xs={12}>
          <TextField label={label('status._')} icon={<RibbonIcon />}>
            <StatusField status={status} deleted={deleted} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <ReferenceField
            source="solutionId"
            reference="solutions"
            link={false}
            addLabel={false}
            basePath="/"
          >
            <TextField source="name" label={label('solutionId')} icon={<StarIcon />} />
          </ReferenceField>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
}));

const label = (key: string): string => `resources.businesses.fields.${key}`;

export default Aside;
