import React, { FC } from 'react';

import { ExportButton } from '~/components';
import { CreateBackgroundTaskParams } from '~/api/backgroundTasks';
import BackgroundTask, { useBackgroundTaskTranslate } from './BackgroundTask';

interface Props {
  type: CreateBackgroundTaskParams['type'];
  queryArguments?: any;
}

const BackgroundTaskExport: FC<Props> = (props) => {
  const { type, queryArguments = {} } = props;
  const t = useBackgroundTaskTranslate();

  return (
    <>
      <BackgroundTask
        type={type}
        queryArguments={{
          ...queryArguments,
          exportFormat: 'CSV',
        }}
        dialogTitle={t('downloadCSV')}
        dialogType='export'
      >
        <ExportButton label='CSV' />
      </BackgroundTask>
      <BackgroundTask
        type={type}
        queryArguments={{
          ...queryArguments,
          exportFormat: 'XLSX',
        }}
        dialogTitle={t('downloadExcel')}
        dialogType='export'
      >
        <ExportButton label='Excel' />
      </BackgroundTask>
    </>
  );
};

export default BackgroundTaskExport;
