import React, { Children, cloneElement } from 'react';

interface Props {
  [x: string]: any;
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}

const PassProps = (props: Props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={className}>
      {children &&
        Children.toArray(children).map((element: any) => cloneElement(element, { ...rest }))}
    </div>
  );
};

export default PassProps;
