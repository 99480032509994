import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import DivLayout from '~/components/ra/DivLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    display: 'flex',
    lineHeight: 1.65,
  },
  spaceBetween: {
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  label: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  data?: string | number;
  label: string;
  children?: any;
  className?: string;
  spaceBetween?: boolean;
}

const HorizontalLabeledField = (props: Props) => {
  const { data, label, children, className, spaceBetween, ...rest } = props;
  const classes = useStyles();

  let result = null;

  if (data || typeof data === 'number') {
    result = data.toString();
  } else if (children) {
    result = <DivLayout {...rest}>{children}</DivLayout>;
  }

  return (
    <div
      className={cx(classes.root, {
        [className as string]: !!className,
        [classes.spaceBetween]: spaceBetween,
      })}
    >
      <span className={classes.label}>{label}:</span>&nbsp;
      <span className={cx({ [className as string]: className?.includes('RaReferenceField-link') })}>
        {result || '—'}
      </span>
    </div>
  );
};

HorizontalLabeledField.defaultProps = {
  spaceBetween: false,
};

export default HorizontalLabeledField;
