export default {
  root: {
    '& > div': {
      boxSizing: 'border-box',
      minHeight: 57,
      '& > div': {
        height: 'unset',
      },
    },
  },
  chip: {
    margin: 0,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 4,
    height: 24,
  },
};
