import React from 'react';
import {
  TabbedShowLayout,
  Tab,
  ReferenceField,
  useShowController,
  useTranslate,
  usePermissions,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Show } from '~/layout';
import { NoPermissions, TextField } from '~/components/ra';
import { TextField as TextFieldComponent, StatusField, H2 } from '~/components';
import IdentityEdit from './IdentityEdit';
import IdentityAuditLog from './IdentityAuditLog';
import { Identity } from '~/types';
import IdentityDelete from './IdentityDelete';

export const l = (key: string) => `resources.team.fields.${key}`;

const IdentityShow = (props: any) => {
  const { permissions } = usePermissions();

  const showController = useShowController(props);
  const showRecord: Identity | undefined = showController.record;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  if (!permissions) return null;
  if (!permissions.includes('identity.view')) return <NoPermissions />;

  return (
    <Show {...props} actions={null}>
      <TabbedShowLayout className={classes.tabbedShowLayout}>
        <Tab label={l('profile')}>
          <div className={classes.section}>
            <Grid
              container
              className={classes.bottomSpacing2}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {t('personalData')}
                </H2>
              </Grid>
              <Grid item container xs={4} spacing={2} id="section-actions" justify="flex-end">
                {permissions?.includes('identity.update') && (
                  <Grid item>
                    <IdentityEdit identityRecord={showRecord} permissions={permissions} />
                  </Grid>
                )}
                {permissions.includes('identity.delete') && showRecord?.roleId !== 'support' && (
                  <Grid item>
                    <IdentityDelete identityId={showRecord?.id as any} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <div className={classes.container}>
              <Grid container item spacing={2} xs={10}>
                <Grid item xs={4}>
                  <TextField source="name" label={t('name')} {...showController} />
                </Grid>
                <Grid item xs={4}>
                  <TextField source="email" label={t('email')} {...showController} />
                </Grid>
                <Grid item xs={4}>
                  <TextField source="phoneNumber" label={t('phoneNumber')} {...showController} />
                </Grid>
                <Grid item xs={4}>
                  {showRecord?.deleted ? (
                    <TextFieldComponent label={t('status')}>
                      <StatusField status="BLOCKED" />
                    </TextFieldComponent>
                  ) : (
                    <TextFieldComponent label={t('status')}>
                      <StatusField status="ACTIVE" />
                    </TextFieldComponent>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <ReferenceField
                    source="roleId"
                    reference="roles"
                    link={false}
                    {...showController}
                  >
                    <TextField source="name" label={t('roleId')} />
                  </ReferenceField>
                </Grid>
                <Grid item xs={4}>
                  <TextField source="username" label={t('username')} {...showController} />
                </Grid>
              </Grid>
            </div>
          </div>
        </Tab>
        <Tab label={l('actionLog')} path="actionLog">
          <IdentityAuditLog identityId={showRecord?.id as string} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const useStyles = makeStyles((theme) => ({
  tabbedShowLayout: {
    minHeight: 500,
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  section: {
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default IdentityShow;
