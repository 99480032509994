import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { useRightContentSpacing } from '~/hooks/styles';
import { EventType } from '~/types/Event';
import { ChipField, TextField } from '~/components';
import { RibbonIcon, TimeIcon } from '~/img';
import { time } from '~/utils';
import { isEmpty } from 'lodash';
import TagsIcon from '@material-ui/icons/LocalOffer';
import TocIcon from '@material-ui/icons/Toc';
import { AsideBase } from '~/layout';
import { useTranslateEventsShow } from '~/resources/events';

type Props = {
  record?: EventType;
};

const RightPanel: FC<any> = (props: Props) => {
  const { record: event } = props;

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });
  const t = useTranslateEventsShow();

  if (!event) {
    return null;
  }

  return (
    <Grid container item xs direction="column" spacing={spacing}>
      <Grid item className={classes.asideContent}>
        <AsideBase className={classes.root}>
          <Grid container spacing={2} className={classes.layout}>
            <Grid item xs={12}>
              <TextField
                label={t('topic')}
                icon={<TocIcon />}
                textWrap
                className={classes.wrapTopic}
              >
                {event.topic}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('timestamp')} icon={<TimeIcon />}>
                {time(event.timestamp).format('ll, LTS')}
              </TextField>
            </Grid>
            {!isEmpty(event.tags) && (
              <Grid item xs={12}>
                <TextField label={t('tags')} icon={<TagsIcon />} textWrap>
                  {event.tags.map((t: string) => (
                    <ChipField key={t} label={t} />
                  ))}
                </TextField>
              </Grid>
            )}
            {event.processing?.state && !isEmpty(event.processing?.state) && (
              <>
                <Grid xs={12} item style={{ paddingBottom: 0 }}>
                  <TextField icon={<RibbonIcon />}>{t('processingStatuses')}</TextField>
                </Grid>
                {Object.entries(event.processing?.state).map(([label, status]) => {
                  const reason = event?.processing?.reasons[label];
                  const reasonLabel = reason ? ' : ' + reason : '';
                  return (
                    <Grid item xs={12} key={label}>
                      <TextField label={label} icon={<></>} textWrap>
                        {status + reasonLabel}
                      </TextField>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </AsideBase>
      </Grid>
    </Grid>
  );
};

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  asideContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  wrapTopic: {
    wordBreak: 'break-all',
  },
}));

export default RightPanel;
