import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useShowController, ShowContextProvider } from 'ra-core';

import { ShowActions as DefaultActions } from 'react-admin';

import { buildName } from '../utils';
import ShowTitle from './RecordTitle';
import { PassProps } from '~/components/ra';

const Show = (props) => {
  const { icon, iconFn, ...rest } = props;
  const controllerProps = useShowController(rest);
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView
        title={
          <ShowTitle
            icon={icon}
            iconFn={iconFn}
            headline={(record, resource) => buildName(resource, record) || '???'}
          />
        }
        {...rest}
        {...controllerProps}
      />
    </ShowContextProvider>
  );
};

Show.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string.isRequired,
  title: PropTypes.node,
  icon: PropTypes.element,
  iconFn: PropTypes.func,
};

export const ShowView = ({
  actions,
  aside,
  basePath,
  children,
  classes: classesOverride,
  className,
  component: Content,
  hasEdit,
  hasList,
  record,
  resource,
  title,
  version,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultTitle,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  if (typeof actions === 'undefined' && hasEdit) {
    actions = <DefaultActions />;
  }
  if (!children) {
    return null;
  }

  return (
    <div className={classnames('show-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      <div style={{ display: 'flex' }}>
        {title &&
          cloneElement(title, {
            record,
            resource,
            ...title.props,
          })}
        {actions &&
          cloneElement(actions, {
            basePath,
            data: record,
            hasList,
            hasEdit,
            resource,
            //  Ensure we don't override any user provided props
            ...actions.props,
          })}
      </div>
      <div
        className={classnames(classes.main, {
          [classes.noActions]: !actions,
        })}
      >
        {record &&
          (children.length > 1 ? (
            <PassProps
              className={classes.manyChildrensWrapper}
              resource={resource}
              basePath={basePath}
              record={record}
              version={version}
            >
              {children}
            </PassProps>
          ) : (
            <Content className={classes.card}>
              {record &&
                cloneElement(Children.only(children), {
                  resource,
                  basePath,
                  record,
                  version,
                })}
            </Content>
          ))}
        {aside &&
          cloneElement(aside, {
            resource,
            basePath,
            record,
            version,
          })}
      </div>
    </div>
  );
};

ShowView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  title: PropTypes.any,
  version: PropTypes.node,
};

ShowView.defaultProps = {
  classes: {},
  component: Card,
};

const useStyles = makeStyles(
  {
    root: {},
    main: {
      display: 'flex',
    },
    manyChildrensWrapper: {
      display: 'flex',
      flexGrow: 1,
    },
    noActions: {
      marginTop: '1em',
    },
    card: {
      flex: '1 1 auto',
    },
  },
  { name: 'RaShow' }
);

/* eslint-disable */
const sanitizeRestProps = ({
  actions,
  aside,
  title,
  children,
  className,
  id,
  data,
  loading,
  loaded,
  resource,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  version,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  translate,
  ...rest
}) => rest;
/* eslint-disable */

export default Show;
