import React from 'react';
import Grid from '@material-ui/core/Grid';
import { HeadTransaction } from '~/types/HeadTransaction';
import CustomModule from '~/modules';

import ReversalAction from './ReversalAction';
import ApprovalAction from './ApprovalAction';
import CancelAction from './CancelAction';

interface Props {
  transaction: HeadTransaction;
  history?: any;
}

const Actions = (props: Props) => {
  const { transaction, history } = props;

  return (
    <>
      <Grid item xs={6} container spacing={1} justify="flex-end">
        <Grid item>
          <CancelAction transaction={transaction} history={history} />
        </Grid>
        <Grid item>
          <ReversalAction transaction={transaction} history={history} />
        </Grid>
        <Grid item>
          <ApprovalAction transaction={transaction} history={history} />
        </Grid>
        <Grid item>
          <CustomModule name="ExportInvoice" headTransaction={transaction} />
        </Grid>
      </Grid>
    </>
  );
};

export default Actions;
