import React from 'react';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Column, Align } from './index';

const useStyles = makeStyles({
  cell: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface Props<RowData> {
  column: Column<RowData>;
  children: any;
  align?: Align;
  style?: React.CSSProperties;
}

function Cell<RowData>(props: Props<RowData>) {
  const {
    column: { width, style = {} },
    children,
    align = 'left',
    style: styleProp,
  } = props;

  const classes = useStyles();

  const cellStyle: React.CSSProperties = {
    flexGrow: width ? 0 : 1,
    ...style,
    ...styleProp,
  };
  if (width) {
    cellStyle.width = cellStyle.minWidth = cellStyle.maxWidth = cellStyle.flexBasis = width;
  }

  return (
    <TableCell
      className={classes.cell}
      style={cellStyle}
      title={typeof children === 'string' ? children : ''}
      align={align}
      component='div'
    >
      {children}
    </TableCell>
  );
}

export default Cell;
