import React, { FC } from 'react';
import { Tab, usePermissions } from 'react-admin';

import { PaperTabbedShowLayout, RecordTitle, Show } from '~/layout';
import TransactionsTable from './components/TransactionsTable';
import Aside from './components/Aside';
import { useTranslateBatchesShow } from './index';
import { FileField, NoPermissions } from '~/components/ra';
import { time } from '~/utils';
import { Batch } from '~/types/Batch';
import { TextField } from '~/components';

const BatchShow: FC<any> = (implicitProps) => {
  const { permissions } = usePermissions();
  const t = useTranslateBatchesShow();
  if (!permissions) return null;
  if (!permissions.includes('batchPayment.view')) return <NoPermissions />;
  const { record } = implicitProps;

  return (
    <Show
      {...implicitProps}
      title={
        <RecordTitle
          headline={(record: Batch) => {
            return `Batch [${record.type.toLowerCase()}]: "${record.data?.description}"`;
          }}
          subtitle={(
            record: Batch,
            resource: string,
            translate: (key: string, opt: any) => string
          ) => {
            const parts = [];
            if (record.id) {
              parts.push(`${translate(`resources.${resource}.name`, 1)} #${record.id}`);
            }
            const created = record.created;
            if (created) {
              const m = time(created);
              parts.push(`created at ${m.format('l')} ${m.format('LTS')}`);
            }
            return parts.join(', ');
          }}
        />
      }
      actions={null}
    >
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.transactions')}>
          <TransactionsTable {...implicitProps} />
        </Tab>
        <Tab label={t('tabs.context')} path="context">
          <FileLink record={record} />
        </Tab>
      </PaperTabbedShowLayout>
      <Aside />
    </Show>
  );
};

const FileLink: FC<{ record: Batch }> = ({ record }) => {
  const t = useTranslateBatchesShow();
  return record?.fileId ? (
    <>
      <TextField>{t('file')}</TextField>
      <FileField
        record={{
          name: `BATCH_${time(record.created).format('YYYY_MM_DD_hh_mm_ss')}.csv`,
          id: record?.fileId,
        }}
      />
    </>
  ) : null;
};

export default BatchShow;
