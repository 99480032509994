import React, { useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { cleanupVirtualizedListCache } from '~/ducks/virtualizedList';
import Row from './Row';
import Cell from './Cell';
import { useDebouncedCallback } from 'use-debounce/lib';

export type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type Column<RowData> = {
  header: string;
  selectCellData: (rowData: RowData) => any;
  id?: string; // used to determine particular column by cellDataWrapperComponent
  cellDataWrapperComponent?: any;
  headerAlign?: Align;
  cellAlign?: Align;
  style?: React.CSSProperties;
  width?: number;
};

type LoadMore = (startIndex: number, stopIndex: number) => Promise<any> | null;

interface Props<RowData> {
  resource: string;
  data: RowData[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: () => any;
  filters?: {
    [x: string]: any;
  };
  columns: Column<RowData>[];
  emptyText?: string;
  className?: string;
  onRowClick?: (data: RowData) => any;
  outterTable?: boolean;
  rowHeight?: number;
  getRowLink?: (data: RowData) => string | undefined;
  openLinkInNewTab?: boolean;
}

function VirtualizedList<RowData = any>(props: Props<RowData>) {
  const {
    resource,
    data,
    hasNextPage,
    loadNextPage,
    filters,
    columns,
    emptyText,
    onRowClick,
    outterTable = false,
    className = '',
    rowHeight = 37,
    getRowLink,
    openLinkInNewTab,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  // We create a reference for the InfiniteLoader.
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const hasMountedRef = useRef(false);

  // Each time the filters changed we called the method resetloadMoreItemsCache
  // to clear the cache and refetch data with applied filters.
  useEffect(() => {
    if (filters && hasMountedRef.current) {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true);
      }
    }
    hasMountedRef.current = true;
  }, [filters]);

  useEffect(() => {
    const current = infiniteLoaderRef.current;
    return () => {
      if (current) {
        current.resetloadMoreItemsCache(true);
      }
      if (resource) {
        dispatch(cleanupVirtualizedListCache({ resource }));
      }
    };
  }, [dispatch, resource]);

  // Must be greater than 1 cause it's source of bug: loadNextPage will never be called
  const loadIndicatorRowsCount = 12;
  // If there are more data to be loaded then add an extra row to hold a loading indicator.
  const itemCount: number = hasNextPage ? data.length + loadIndicatorRowsCount : data.length;

  // Only load 1 page of data at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  // Following optimisation is cause of internal react-window-infinite-loader bug. https://youtrack.veengu.org/issue/CORE-74#focus=streamItem-4-202.0-0
  //const loadMoreItems = isNextPageLoading ? {} : loadNextPage;
  const loadMoreItems = useDebouncedCallback(loadNextPage, 0);

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number): boolean => !hasNextPage || index < data.length;

  return (
    <div className={cx(classes.container, className)}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className={cx(classes.root, { [classes.outterTable]: outterTable })}
            component="div"
          >
            <TableHead className={classes.head} component="div">
              <TableRow className={classes.rowHead} component="div">
                {columns.map((column, i) => {
                  return (
                    <Cell key={i} align={column.headerAlign} column={column}>
                      {column.header}
                    </Cell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody
              component="div"
              className={classes.body}
              style={{
                width: width,
                height: height - rowHeight,
              }}
            >
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems as LoadMore}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    height={height - rowHeight}
                    itemSize={rowHeight}
                    width={width}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    // If List receives 0 after data has fetched, it will never be updated
                    itemCount={itemCount || 1}
                    style={{
                      overflowY: 'scroll',
                    }}
                  >
                    {({ index, style }) => (
                      <Row
                        columns={columns}
                        index={index}
                        style={style}
                        data={data}
                        isItemLoaded={isItemLoaded}
                        loadIndicatorRowsCount={loadIndicatorRowsCount}
                        emptyText={emptyText}
                        onRowClick={onRowClick}
                        getRowLink={getRowLink}
                        openLinkInNewTab={openLinkInNewTab}
                      />
                    )}
                  </List>
                )}
              </InfiniteLoader>
            </TableBody>
          </Table>
        )}
      </AutoSizer>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      minHeight: 500,
      height: '65vh',
    },
    root: {},
    body: {
      display: 'flex',
    },
    cellHead: {},
    head: {},
    rowHead: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'auto',
      scrollbarGutter: 'stable',
    },
    outterTable: {
      backgroundColor: (theme.palette.background as any).outterTable,
    },
  };
});

export default VirtualizedList;
