import * as React from 'react';

import { Labeled } from 'react-admin';
import { Box } from '@material-ui/core';

import * as hb from '~/types/Handbook';
import BusinessDocumentParameterTable from './BusinessDocumentParameterTable';

export interface Props {
  document: hb.BusinessDocument;
  setDocument: hb.SetBusinessDocument;
}

const BusinessDocumentDetails = ({ document, setDocument }: Props) => {
  return (
    <div style={{ backgroundColor: '#fafafa', paddingLeft: '56px' }}>
      <Box p={1} style={{ paddingRight: 0 }}>
        <Labeled label="Parameters">
          <BusinessDocumentParameterTable document={document} setDocument={setDocument} />
        </Labeled>
      </Box>
    </div>
  );
};

export default BusinessDocumentDetails;
