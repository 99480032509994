import React, { useState, Fragment } from 'react';
import { SaveButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteButton } from '~/components';
import cx from 'classnames';
import BlockButton from '~/components/BlockButton';
import { useFormState } from 'react-final-form';
import { isEqual } from 'lodash';
import { cleanupFormValues } from '~/utils';
import { ConfirmationSettings, ConfirmationBase } from '~/components/Confirmation';
import Toolbar from '../Toolbar';
import { useDebouncedCallback } from 'use-debounce/lib';

export interface BottomToolbarBaseProps<Record> {
  disabled?: boolean;
  withDelete?: boolean;
  deleteButtonProps?: {
    variant: 'delete' | 'block' | 'close';
    [x: string]: any;
  };
  saveButtonProps?: {
    color: 'default' | 'red';
    [x: string]: any;
  };
  classes?: {
    bottomToolbar: string;
  };
  externalState?: Record;
  withSaveConfirmation?: boolean;
  withDeleteConfirmation?: boolean;
  saveConfirmationSettings?: ConfirmationSettings<Record>;
  deleteConfirmationSettings?: ConfirmationSettings<Record>;
}

interface BottomToolbarProps<Record = any> extends BottomToolbarBaseProps<Record> {
  onSave: (newRecord: Record) => void;
  onDelete: () => void;
  deletionLoading?: boolean;
  // Props ejected by react-admin
  [x: string]: any;
}

function BottomToolbar<Record>(props: BottomToolbarProps<Record>) {
  const classes = useStyles();
  const {
    onSave,
    onDelete,
    withDelete,
    deleteButtonProps = {
      variant: 'delete',
    },
    saveButtonProps = {
      color: 'default',
    },
    disabled,
    deletionLoading,
    classes: cssApi = {
      bottomToolbar: '',
    },
    externalState,
    record,
    saveConfirmationSettings,
    deleteConfirmationSettings,
    withSaveConfirmation,
    withDeleteConfirmation,
    // Props ejected by react-admin
    ...rest
  } = props;

  const handleSave = useDebouncedCallback(onSave, 100);

  const formState = useFormState<Record>();
  const prevRecord = formState.initialValues;
  const newRecord = formState.values;

  const { color: saveButtonColor, ...saveButtonPropsRest } = saveButtonProps;

  const isPrestine = () => {
    if (externalState) {
      return isEqual(cleanupFormValues(record, 'hard'), cleanupFormValues(externalState, 'hard'));
    } else {
      return isEqual(cleanupFormValues(prevRecord, 'hard'), cleanupFormValues(newRecord, 'hard'));
    }
  };

  // Confirmation modal or drawer toggler
  const [openSaveConfirmation, setSaveOpen] = useState(false);
  const handleSaveOpen = () => setSaveOpen(true);
  const handleSaveClose = () => setSaveOpen(false);

  const [opeDeleteConfirmation, setDeleteOpen] = useState(false);
  const hanDeleteOpen = () => setDeleteOpen(true);
  const hanDeleteClose = () => setDeleteOpen(false);

  const handleDelete = withDeleteConfirmation ? hanDeleteOpen : onDelete;

  return (
    <Fragment>
      <ConfirmationBase<Record>
        open={openSaveConfirmation}
        handleClose={handleSaveClose}
        onConfirm={() => handleSave(formState.values)}
        confirmationSettings={saveConfirmationSettings}
        prevRecord={prevRecord}
        newRecord={newRecord}
      />
      <ConfirmationBase<Record>
        open={opeDeleteConfirmation}
        handleClose={hanDeleteClose}
        onConfirm={onDelete}
        confirmationSettings={deleteConfirmationSettings}
        prevRecord={prevRecord}
        newRecord={newRecord}
      />
      <Toolbar
        className={cx(classes.toolbar, { [cssApi.bottomToolbar]: cssApi.bottomToolbar })}
        {...rest}
      >
        <SaveButton
          className={cx({ [classes.red]: saveButtonColor === 'red' })}
          disabled={disabled || isPrestine()}
          onSave={withSaveConfirmation ? handleSaveOpen : handleSave}
          {...saveButtonPropsRest}
        />
        {(() => {
          const { variant, ...rest } = deleteButtonProps;
          if (withDelete) {
            switch (variant) {
              case 'delete':
                return (
                  <DeleteButton
                    disabled={disabled}
                    onClick={handleDelete}
                    loading={deletionLoading}
                    {...rest}
                  />
                );
              case 'block':
                return (
                  <BlockButton
                    disabled={disabled}
                    onClick={handleDelete}
                    loading={deletionLoading}
                    red
                    {...rest}
                  />
                );
              case 'close':
                return (
                  <BlockButton
                    disabled={disabled}
                    label="close"
                    onClick={handleDelete}
                    loading={deletionLoading}
                    red
                    {...rest}
                  />
                );
              default:
                return null;
            }
          } else return null;
        })()}
      </Toolbar>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default BottomToolbar;
