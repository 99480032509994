import React, { FC, useState } from 'react';
import { required } from 'react-admin';
import { TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Drawer from '~/components/Drawer';
import { SimpleForm } from '~/components/ra';
import Toolbar from '~/components/ra/Toolbar';
import { useClearBlock } from '~/hooks/apiIdentity';
import { useApiIdentityTranslate } from '..';
import { LockType } from '~/types/apiIdentity';
import { IdentityLockView } from './IdentityLocksDrawer';
import DeleteButton from '~/components/DeleteButton';
import { SaveButton } from 'react-admin';

interface Props {
  identityId: string;
  record: LockType;
}

const DeleteIdentityLock: FC<Props> = (props) => {
  const { identityId, record } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteLockMutation = useClearBlock();
  const handleSave = async () => {
    await deleteLockMutation.mutateAsync({ identityId, blockId: record.id });
    handleClose();
  };
  const t = useApiIdentityTranslate();
  const classes = useStyles();

  return (
    <>
      <DeleteButton label={t('deleteLock')} onClick={handleOpen} />
      <Drawer heading={t('deleteIdentityLock')} open={open} onClose={handleClose}>
        <div className={classes.bottomSpacing2}>
          <IdentityLockView record={record} />
        </div>
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton
                label={t('delete')}
                className={classes.deleteButton}
                onSave={handleSave}
                icon={<DeleteForeverIcon />}
              />
            </Toolbar>
          }
          saving={deleteLockMutation.isLoading}
        >
          <TextInput
            source="reason"
            label={t('reasonToRemoveLock')}
            multiline
            rows={5}
            rowsMax={20}
            validate={required()}
            fullWidth
          />
        </SimpleForm>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(3),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
}));

export default DeleteIdentityLock;
