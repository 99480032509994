import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate, usePermissions } from 'react-admin';

import { StatusField, VirtualizedList } from '~/components';
import { Section } from '~/layout';

import { Batch } from '~/types/Batch';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Column } from '~/resources/headTransactions/components/list/Column';
import ListDateTimeField from '~/resources/headTransactions/components/list/ListDateTimeField';
import ListAmountField from '~/resources/headTransactions/components/list/ListAmountField';
import ListAccountField from '~/resources/headTransactions/components/list/ListAccountField';
import { CellProps } from '~/resources/headTransactions/components/list/CellProps';
import ColoredField from '~/resources/headTransactions/components/list/ColoredField';
import OperationTypeField from '~/components/OperationTypeField';

import { useDispatch, useSelector } from 'react-redux';
import {
  getVirtualizedListBegin,
  getVirtualizedListFail,
  getVirtualizedListSuccess,
  selectors,
} from '~/ducks/virtualizedList';
import { getTransactions } from '~/api/headTransactions';
import {
  getRecipientAccount,
  getSenderAccount,
  RowField,
  resource as transactionResource,
  i18nPath,
} from '~/resources/headTransactions/TransactionList';

const DetailsTab: FC<any> = (props) => {
  const { record } = props;

  const list = useSelector(selectors.getList(transactionResource));
  const { hasNextPage, isNextPageLoading, data } = list;

  const { permissions } = usePermissions();
  const dispatch = useDispatch();

  const translate = useTranslate();
  const getRowLink = (data: HeadTransaction) => {
    if (permissions?.includes('headTransaction.view')) {
      return `/headTransactions/${data.id}/show`;
    }
  };

  const loadNextPage = async () => {
    if (isNextPageLoading) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource: transactionResource, queryToken }));

      const { data } = await getTransactions({
        next: list.cursors.next,
        filters: { referenceId: batch.id },
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions as any[],
      };

      dispatch(
        getVirtualizedListSuccess({ resource: transactionResource, responseData, queryToken })
      );
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource: transactionResource, error: err, queryToken }));
    }
  };

  const classes = useStyles();

  const columns: Column[] = [
    {
      selectCellData: (t: HeadTransaction) => t.created,
      cellDataWrapperComponent: RowField(ListDateTimeField),
      header: translate(i18nPath + 'list.created'),
      style: {
        minWidth: 50,
        flexBasis: 100,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => t.details.baseAmount || t.amount,
      cellDataWrapperComponent: RowField(ListAmountField),
      id: 'baseAmount',
      header: translate(i18nPath + 'list.baseAmount'),
      style: {
        minWidth: 50,
        flexBasis: 100,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => getSenderAccount(t),
      cellDataWrapperComponent: RowField(ListAccountField),
      header: translate(i18nPath + 'list.sender'),
      style: {
        minWidth: 100,
        flexBasis: 150,
        maxWidth: 200,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => getRecipientAccount(t),
      cellDataWrapperComponent: RowField(ListAccountField),
      header: translate(i18nPath + 'list.recipient'),
      style: {
        minWidth: 100,
        flexBasis: 150,
        maxWidth: 200,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => t.operationType,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <ColoredField {...props}>
          <OperationTypeField operationType={props.children} />
        </ColoredField>
      )),
      header: translate(i18nPath + 'list.operationType'),
      style: {
        minWidth: 50,
        flexBasis: 100,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <StatusField status={props.children} withColor={false} style={props.style} />
      )),
      id: 'status',
      header: translate(i18nPath + 'list.status'),
      style: {
        minWidth: 50,
        flexBasis: 100,
        maxWidth: 150,
      },
    },
  ];

  if (!record) return null;

  const batch = record as Batch;
  return (
    <Section noPaddings className={classes.section}>
      <VirtualizedList<HeadTransaction>
        resource={transactionResource}
        columns={columns}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        data={data}
        loadNextPage={loadNextPage}
        rowHeight={48}
        getRowLink={getRowLink}
      />
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
}));

export default DetailsTab;
