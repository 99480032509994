import React, { Fragment } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';

import OperationTypeField from '~/components/OperationTypeField';

import { StatusField, VirtualizedList, Title, BooleanField } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';

import { getTransactions } from '~/api/headTransactions';
import { HeadTransaction, HeadTransactionFilters } from '~/types/HeadTransaction';
import { Column } from './components/list/Column';

import Filters from './components/list/ListFilters';
import { NoPermissions } from '~/components/ra';
import theme from '~/theme';
import ListDateTimeField from './components/list/ListDateTimeField';
import ListAmountField from './components/list/ListAmountField';
import ListAccountField from './components/list/ListAccountField';
import ColoredField from './components/list/ColoredField';
import ListReportingStatusField from './components/list/ListReportingStatusField';
import { CellProps } from './components/list/CellProps';

export const resource = 'headTransactions';
export const i18nPath = 'resources.headTransactions.';

interface Props {
  permissions: string[];
  history: any;
}

const TransactionList = (props: Props) => {
  const { history } = props;
  const { permissions } = usePermissions();

  const translate = useTranslate();
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<HeadTransactionFilters>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getTransactions({
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions as any[],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column[] = [
    {
      selectCellData: (t: HeadTransaction) => t.created,
      cellDataWrapperComponent: RowField(ListDateTimeField),
      header: translate(i18nPath + 'list.created'),
      style: {
        flexBasis: 150,
        minWidth: 100,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => t.details.baseAmount || t.amount,
      cellDataWrapperComponent: RowField(ListAmountField),
      id: 'baseAmount',
      header: translate(i18nPath + 'list.baseAmount'),
      headerAlign: 'right',
      cellAlign: 'right',
      style: {
        flexBasis: 150,
        minWidth: 100,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => getSenderAccount(t),
      cellDataWrapperComponent: RowField(ListAccountField),
      header: translate(i18nPath + 'list.sender'),
      style: {
        flexBasis: 350,
        minWidth: 210,
        maxWidth: 350,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => getRecipientAccount(t),
      cellDataWrapperComponent: RowField(ListAccountField),
      header: translate(i18nPath + 'list.recipient'),
      style: {
        flexBasis: 350,
        minWidth: 210,
        maxWidth: 350,
      },
    },
    {
      selectCellData: (t: HeadTransaction) => t.operationType,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <ColoredField {...props}>
          <OperationTypeField operationType={props.children} />
        </ColoredField>
      )),
      header: translate(i18nPath + 'list.operationType'),
      width: 200,
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <StatusField status={props.children} withColor={false} style={props.style} />
      )),
      id: 'status',
      header: translate(i18nPath + 'list.status'),
      width: 150,
    },
    {
      selectCellData: (rowData) => rowData.details.settlementTransaction?.id,
      cellDataWrapperComponent: RowField((props: CellProps) => {
        console.log(props);
        return <BooleanField value={props.children ? true : false} nullOnFalse />;
      }),
      id: 'settled',
      header: translate(i18nPath + 'list.settled'),
      style: {
        flexBasis: 80,
        minWidth: 80,
        maxWidth: 80,
      },
    },
    {
      selectCellData: (rowData) => rowData.details.report,
      cellDataWrapperComponent: RowField(ListReportingStatusField),
      id: 'status',
      header: translate(i18nPath + 'list.reportingStatus'),
      style: {
        flexBasis: 200,
        minWidth: 200,
        maxWidth: 350,
      },
    },
  ];

  const getRowLink = (data: HeadTransaction) => {
    if (permissions?.includes('headTransaction.view')) {
      return `/headTransactions/${data.id}/show`;
    }
  };

  const { hasNextPage, isNextPageLoading, data } = list;
  if (!permissions) return null;
  if (!permissions.includes('headTransaction.list')) return <NoPermissions />;
  return (
    <Fragment>
      <Title resource={resource} headline={translate(i18nPath + 'list.title')} />
      <Filters cleanFilters={history.action !== 'POP'} />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<HeadTransaction>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          filters={filters}
          data={data}
          loadNextPage={loadNextPage}
          outterTable
          rowHeight={48}
          getRowLink={getRowLink}
        />
      </Paper>
    </Fragment>
  );
};

export const getSenderAccount = (t: HeadTransaction) => {
  return t.accounts.find((a) => a.id === t.sender);
};

export const getRecipientAccount = (t: HeadTransaction) => {
  return t.accounts.find((a) => a.id === t.recipient);
};

export const RowField = (Field: any) => {
  return (props: CellProps) => {
    const style =
      props.rowData.status === 'REJECTED' ? { color: theme.palette.error.main } : undefined;
    return (
      <Field {...props} style={style}>
        {props.children}
      </Field>
    );
  };
};

const fixBottomShadow = { paddingBottom: 2 };

export default TransactionList;
