import React, { CSSProperties } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { time } from '~/utils';

import { AmountField } from '~/components';
import { BankSummaryType } from '~/api/bankAccounts';
import { Column } from '~/types';

const ColoredCell = (props: Props) => {
  const { rowData, children, style } = props;
  const theme = useTheme();

  const todayStyles = (): CSSProperties => {
    if (time(rowData.date).format('L') === time().format('L')) {
      return { color: theme.palette.warning.main, fontStyle: 'italic' };
    }
    return {};
  };
  return <div style={{ ...todayStyles(), ...style }}>{children}</div>;
};

interface Props {
  children: any;
  rowData: BankSummaryType;
  column: Column;
  style?: CSSProperties;
}

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;

  switch (column.id) {
    case 'bankingDay': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children && time(children).format('L')}
        </ColoredCell>
      );
    }
    case 'amount': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <AmountField amount={children} />
        </ColoredCell>
      );
    }
    default: {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children}
        </ColoredCell>
      );
    }
  }
};

export default CustomCell;
