import { store } from './App';
import { notification } from '~/ducks/snackbarNotifications';

const showErrorMessage = (message: string, duration = 5 * 1000) =>
  store.dispatch(
    notification({
      severity: 'error',
      message,
      autoHideDuration: duration,
      disableClickawayClose: true,
    })
  );

const onError = (error: any): void => {
  const code = error?.body?.code;
  const message = error?.body?.message;
  switch (code) {
    case 'NOT_FOUND':
      break;
    case 'RESOURCE_NOT_AVAILABLE': {
      showErrorMessage(message || code || '');
      break;
    }
    default: {
      if (process.env.NODE_ENV === 'development') {
        showErrorMessage(`${code || 'Error'} ${message || ''}`);
      } else {
        showErrorMessage(`Error ${code || ''}`);
      }
    }
  }
};

export default onError;
