import { RootState } from '~/types/RootState';
import { createAction, handleActions } from 'redux-actions';

const VIRTUALIZED_LIST_FILTER_CHANGE = 'virtualizedList/filter/change';
const VIRTUALIZED_LIST_CLEANUP_FILTERS = 'virtualizedList/cleanup/filters';
const GET_VIRTUALIZED_LIST_BEGIN = 'get/virtualizedList/begin';
const GET_VIRTUALIZED_LIST_SUCCESS = 'get/virtualizedList/success';
const GET_VIRTUALIZED_LIST_FAIL = 'get/virtualizedList/fail';
const CLEANUP_VIRTUALIZED_LIST_CACHE = 'cleanup/virtualizedList/cache';
const ADD_RELATED_DATA = 'virtualizedList/addRelatedData';

type ResponseData = {
  cursors: {
    next?: string;
    [x: string]: string | undefined;
  };
  records: any[];
};

type GetBeginType = {
  resource: string;
  queryToken: any;
};
type GetSuccessType = {
  resource: string;
  responseData: ResponseData;
  queryToken: any;
};
type GetFailType = {
  resource: string;
  error: Error;
  queryToken: any;
};
type FilterChangeType = {
  resource: string;
  filters: {
    [x: string]: any;
  };
};
type FilterCleanupType = {
  resource: string;
};
type CleanupListCacheType = {
  resource: string;
};
type AddRelatedDataType = {
  resource: string;
  relatedData: any;
};

export const filterChange = createAction<FilterChangeType>(VIRTUALIZED_LIST_FILTER_CHANGE);
export const cleanupFilters = createAction<FilterCleanupType>(VIRTUALIZED_LIST_CLEANUP_FILTERS);
export const getVirtualizedListBegin = createAction<GetBeginType>(GET_VIRTUALIZED_LIST_BEGIN);
export const getVirtualizedListSuccess = createAction<GetSuccessType>(GET_VIRTUALIZED_LIST_SUCCESS);
export const getVirtualizedListFail = createAction<GetFailType>(GET_VIRTUALIZED_LIST_FAIL);
export const cleanupVirtualizedListCache = createAction<CleanupListCacheType>(
  CLEANUP_VIRTUALIZED_LIST_CACHE
);
export const addRelatedData = createAction<AddRelatedDataType>(ADD_RELATED_DATA);

export type ResourceListType<RowData> = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  cursors: {
    next: string | null;
  };
  data: RowData[];
};
export type Resource<RowData = any> = {
  filters: {
    [x: string]: any;
  };
  list: ResourceListType<RowData>;
  error?: Error;
  queryToken: any;
  relatedData: {
    [x: string]: any;
  };
};
export type State<RowData = any> = {
  [resource: string]: Resource<RowData>;
};

const initialResource: Resource = {
  filters: {},
  list: {
    hasNextPage: true,
    isNextPageLoading: false,
    cursors: {
      next: null,
    },
    data: [],
  },
  queryToken: null,
  relatedData: {},
};

export default handleActions<State, any>(
  {
    // Fetch logic
    [GET_VIRTUALIZED_LIST_BEGIN]: (state, { payload }: { payload: GetBeginType }) => {
      const { resource } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...oldResourceData,
          queryToken: payload.queryToken,
          list: {
            ...oldResourceData.list,
            isNextPageLoading: true,
            hasNextPage: true,
          },
        },
      };
    },
    [GET_VIRTUALIZED_LIST_SUCCESS]: (state, { payload }: { payload: GetSuccessType }) => {
      const { resource, responseData } = payload;
      const oldResourceData = state[resource];
      if (oldResourceData.queryToken === payload.queryToken) {
        return {
          ...state,
          [resource]: {
            ...oldResourceData,
            list: {
              ...oldResourceData.list,
              isNextPageLoading: false,
              hasNextPage: Boolean(responseData.cursors.next),
              cursors: {
                next: responseData.cursors.next || null,
              },
              data: oldResourceData.list.data.concat(responseData.records),
            },
          },
        };
      } else {
        return state;
      }
    },
    [GET_VIRTUALIZED_LIST_FAIL]: (state, { payload }: { payload: GetFailType }) => {
      const { resource, error } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...oldResourceData,
          list: {
            ...oldResourceData.list,
            isNextPageLoading: false,
            hasNextPage: false,
          },
          error,
        },
      };
    },
    // Add helpful data related to current resource
    [ADD_RELATED_DATA]: (state, { payload }: { payload: AddRelatedDataType }) => {
      const { resource, relatedData } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...oldResourceData,
          relatedData: {
            ...oldResourceData.relatedData,
            ...relatedData,
          },
        },
      };
    },
    // Clear resource without filters
    [CLEANUP_VIRTUALIZED_LIST_CACHE]: (state, { payload }: { payload: CleanupListCacheType }) => {
      const { resource } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...initialResource,
          filters: {
            ...oldResourceData.filters,
          },
        },
      };
    },

    // Filters
    [VIRTUALIZED_LIST_FILTER_CHANGE]: (state, { payload }: { payload: FilterChangeType }) => {
      const { resource } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...oldResourceData,
          list: {
            ...initialResource.list,
          },
          filters: {
            ...oldResourceData.filters,
            ...payload.filters,
          },
        },
      };
    },
    [VIRTUALIZED_LIST_CLEANUP_FILTERS]: (state, { payload }: { payload: FilterCleanupType }) => {
      const { resource } = payload;
      const oldResourceData = state[resource] || initialResource;
      return {
        ...state,
        [resource]: {
          ...oldResourceData,
          list: {
            ...initialResource.list,
          },
          filters: {
            ...initialResource.filters,
          },
        },
      };
    },
  },
  {}
);

export const selectors = {
  getList: (resource: string) => (state: RootState) => {
    const res = state.virtualizedList[resource];
    return res ? res.list : initialResource.list;
  },
  getFilters: <F = Record<string, any>>(resource: string) => (state: RootState) => {
    const res = state.virtualizedList[resource];
    return res ? (res.filters as F) : (initialResource.filters as F);
  },
  getResource: (resource: string) => (state: RootState) => {
    const res = state.virtualizedList[resource];
    return res ? res : initialResource;
  },
  getRelatedData: (resource: string) => (state: RootState) => {
    const res = state.virtualizedList[resource];
    return res ? res.relatedData : initialResource.relatedData;
  },
};
