import React from 'react';
import { usePermissions } from 'react-admin';
import { Grid } from '@material-ui/core';

import { Confirmation, DeleteButton } from '~/components';
import { PaymentService } from '~/types/paymentServices';
import { useDeletePaymentService } from '~/hooks/paymentServices';
import { useNotify } from '~/hooks';
import { Alert } from '@material-ui/lab';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';

interface Props {
  record?: PaymentService;
}

const useDeleteTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.paymentServices.delete.${key}`);
};

const PaymentServiceDeleteAction = (props: Props) => {
  const { record } = props;
  const { permissions } = usePermissions();
  const t = useDeleteTranslate();
  const history = useHistory();

  const deletePaymentServiceMutation = useDeletePaymentService({
    onSuccess: () => {
      history.push(`/paymentServices?page=1&perPage=10`);
    },
  });
  const handleDelete = () => {
    if (record?.id) {
      return deletePaymentServiceMutation.mutateAsync(record.id);
    }
  };

  const notify = useNotify();

  const onConfirm = async () => {
    if (record?.id) {
      return deletePaymentServiceMutation.mutateAsync(record.id).then(() => {
        notify({ message: t('success'), type: 'success' });
      });
    }
  };

  if (!permissions?.includes('paymentService.delete')) return null;

  return (
    <Confirmation
      onConfirm={onConfirm}
      confirmButtonProps={{
        loading: deletePaymentServiceMutation.isLoading,
      }}
      confirmationSettings={{
        variant: 'modal',
        modal: {
          heading: t('attention'),
          content: (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="warning">{t('alert')}</Alert>
              </Grid>
            </Grid>
          ),
        },
      }}
    >
      <DeleteButton onClick={handleDelete} />
    </Confirmation>
  );
};

export default PaymentServiceDeleteAction;
