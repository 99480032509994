import React from 'react';
import { withTranslate } from 'react-admin';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';

import { options } from './OnboardingChart.options';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const OnboardingChart = (props) => {
  const { className, data, translate, ...rest } = props;
  const theme = useTheme();

  const adaptedData = data && {
    labels: data.labels.map((l) => {
      return translate(`days.${l.toLowerCase()}.full`);
    }),
    datasets: data.datasets.map((d, i) => {
      return {
        label: translate(`resources.${d.label}.name`, 2),
        data: d.data,
        backgroundColor: theme.palette.charts[i],
      };
    }),
  };

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={translate('home.onboardingChart.title')} />
      <Divider />
      <CardContent>
        {adaptedData ? (
          <div className={classes.chartContainer}>
            <Bar
              data={adaptedData}
              options={options(theme)}
              maintainAspectRatio={false}
              height={100}
            />
          </div>
        ) : (
          <Skeleton varian="rect" />
        )}
      </CardContent>
    </Card>
  );
};

OnboardingChart.propTypes = {
  className: PropTypes.string,
};

export default withTranslate(OnboardingChart);
