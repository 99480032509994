const buildName = (
  resource:
    | 'businesses'
    | 'individuals'
    | 'employees'
    | 'partners'
    | 'accounts'
    | 'bank/accounts'
    | 'tenant/accounts'
    | 'headTransactions'
    | 'deals'
    | 'actionReports'
    | 'externalProfileLinks'
    | 'events'
    | 'batches',
  record: Record<string, any> | undefined
): string => {
  if (!record) return '';
  switch (resource) {
    case 'businesses':
      return record.legalName;
    case 'individuals':
      return [record.firstName, record.lastName].filter(Boolean).join(' ');
    case 'employees':
      return [record.firstName, record.lastName].filter(Boolean).join(' ');
    case 'partners':
      return record.name;
    case 'accounts':
    case 'bank/accounts':
    case 'tenant/accounts':
      return record.alias;
    case 'headTransactions':
      return record.id;
    case 'deals':
      return record.id;
    case 'actionReports':
      return record.reportType;
    case 'externalProfileLinks':
      return `${record.externalSourceSystem} ${(record.referenceId || [])[0] || ''}`;
    case 'events':
      return record.topic;
    case 'batches':
      return `Batch [${record.type.toLowerCase()}]: "${record.data?.description}"`;
    default:
      return record.name || record.label;
  }
};

export default buildName;
