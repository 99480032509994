import React from 'react';
import { ChipField } from 'react-admin';
import lodashGet from 'lodash/get';

import { Longdash } from '~/utils';
import { isEmpty } from 'lodash';
import { useAllSolutions } from '~/hooks/useSolution';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface Props {
  record?: {
    solutionId: string;
    [key: string]: any;
  };
  source?: string;
  type?: 'text' | 'chipField';
  allowEmpty?: boolean;
  emptyText?: string;
  [x: string]: any;
}

const SolutionField = ({
  record,
  source,
  type = 'chipField',
  allowEmpty,
  emptyText = Longdash,
  ...rest
}: Props) => {
  const { data } = useAllSolutions();
  const classes = useStyles();
  const value = lodashGet(record, source || '');
  if (!value) return <>{Longdash}</>;
  const renderWithOptions = () => {
    const solution = (data?.data || []).find((level) => level.id === value);
    if (type === 'text') return <div>{solution?.name || Longdash}</div>;
    return (
      <ChipField
        record={{ value: solution?.name || `[${value}]` }}
        source='value'
        className={classes.chip}
        {...rest}
      />
    );
  };

  const renderDisabled = () => {
    return <ChipField record={{ value: '...' }} source='value' />;
  };
  if ((!value || isEmpty(value)) && allowEmpty) return <span>{emptyText}</span>;
  return data ? renderWithOptions() : renderDisabled();
};

const useStyles = makeStyles(() => ({
  chip: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
  },
}));

SolutionField.defaultProps = {
  addLabel: true,
};

export default SolutionField;
