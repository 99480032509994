import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { useHandbook } from '~/hooks';
import { Longdash } from '~/utils';
import { isEmpty } from 'lodash';

interface Props {
  [x: string]: any;
  source?: string;
  country: string;
  solutionId?: string;
  legalType?: string;
  onlyPrimary?: boolean;
  fullWidth?: boolean;
  validate?: any;
  disabled?: boolean;
  allowEmpty?: boolean;
  emptyText?: boolean;
  autoFocus?: boolean;
}

const DocumentTypeSelect = (props: Props) => {
  const {
    source,
    record,
    country,
    solutionId,
    fullWidth,
    legalType,
    onlyPrimary,
    label,
    validate,
    disabled,
    allowEmpty,
    emptyText,
    autoFocus,
  } = props;

  const { data } = useHandbook(solutionId);
  let documentOptions: any[] = [];

  const translate = useTranslate();

  const translateDocumentType = (docType: string): string => {
    const key = `components.ra.fields.DocumentCard.documentType.${docType}`;
    const translation = translate(key);
    return key === translation ? docType : translation;
  };

  if (data && country) {
    const countryData = (data?.countries || []).find((c) => c.id === country);

    if (legalType) {
      const legalTypeData = (countryData?.businessLegalTypes || []).find(
        (lt) => lt.name === legalType
      );
      const documents = legalTypeData?.documents || [];

      if (onlyPrimary) {
        documentOptions = documents
          .filter((d) => d.primary)
          .map((d) => {
            return { id: d.type, name: translateDocumentType(d.type) };
          });
      } else {
        documentOptions = documents.map((d) => {
          return { id: d.type, name: translateDocumentType(d.type) };
        });
      }
    } else {
      const documents = countryData?.individualDocuments || [];

      documentOptions = documents.map((d) => {
        return { id: d.type, name: translateDocumentType(d.type) };
      });
    }
  }

  return (
    <SelectInput
      source={source}
      record={record}
      label={label}
      choices={
        isEmpty(documentOptions)
          ? [{ id: undefined, name: translate('utils.noHandbookValues') }]
          : documentOptions
      }
      fullWidth={fullWidth}
      validate={validate}
      disabled={disabled}
      allowEmpty={allowEmpty}
      emptyText={emptyText}
      autoFocus={autoFocus}
      style={{ minWidth: 256 }}
    />
  );
};

DocumentTypeSelect.defaultProps = {
  source: 'document.type',
  fullWidth: true,
  onlyPrimary: false,
  label: 'components.ra.inputs.DocumentTypeSelect',
  disabled: false,
  allowEmpty: false,
  emptyText: Longdash,
};

export default DocumentTypeSelect;
