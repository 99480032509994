import { Product } from '~/types';
import axios from '~/utils/axios';

export const getProducts = () => {
  return axios.get(`/products/deprecated`).then((response) => {
    return response.data;
  });
};

export const getProductById = (id: string) => {
  return axios.get<Product>(`/products/${id}`);
};

export type IntegrationApiIdentityType = {
  id: string;
  solutionId: string;
  partnerId: string;
  tokenPrefix: string;
  actions: string[];
  createdAt: string;
};

export const getIntegrationApiIdentity = (id: string) => {
  return axios.get<IntegrationApiIdentityType>(`/partners/${id}/identity`);
};

export type CreatedIntegrationApiIdentityType = {
  identity: IntegrationApiIdentityType;
  token: string;
};

export const createIntegrationApiIdentity = (partnerId: string) => {
  return axios.post<CreatedIntegrationApiIdentityType>(`partners/${partnerId}/identity/reset`);
};

export const updateIntegrationApiIdentity = (props: { partnerId: string; data: any }) => {
  const { partnerId, data } = props;
  return axios.put<CreatedIntegrationApiIdentityType>(`partners/${partnerId}/identity`, data);
};

export const getSolutionProducts = (solutionId: string) => () => {
  return axios.get<Product[]>(`/solutions/${solutionId}/products?page=1&perPage=25`);
};
