import React from 'react';

export default function useTimeout(callback: () => void, timeout = 0) {
  const timeoutId = React.useRef<NodeJS.Timeout>();

  const callbackRef = React.useRef<typeof callback>(callback);
  callbackRef.current = callback;

  const handler = React.useMemo(() => {
    return {
      start(overrideTimeout?: number) {
        handler.stop();
        timeoutId.current = setTimeout(
          callbackRef.current,
          overrideTimeout === undefined ? timeout : overrideTimeout
        );
      },

      stop() {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
      },

      restart() {
        handler.stop();
        handler.start();
      },
    };
  }, [callbackRef, timeout]);

  React.useEffect(() => {
    return () => {
      handler.stop();
    };
  }, [handler]);

  return handler;
}
