import React, { useState, Fragment, useRef } from 'react';
import { useTranslate, FormDataConsumer, TextInput, SelectInput, required } from 'react-admin';
import { useForm } from 'react-final-form';

import { Drawer, EditButton, AddDeleteButton, BlockOrUnblockProfile } from '~/components';
import { CountryInput, DateInput, EditForm } from '~/components/ra';
import { KYCLevelCheck, UpdatePhoneNumber } from './index';
import { Individual } from '~/types';
import { useHistory } from 'react-router-dom';
import KycResetProgress from './KycResetProgress';

const label = (key: string): string => `resources.individuals.fields.${key}`;

type FormData = {
  formData: Individual;
  [x: string]: any;
};

interface AddNamesProps {
  addNames: string[];
  index: number;
  [x: string]: any;
}

const AddNamesField = (props: AddNamesProps) => {
  const { addNames, index, ...rest } = props;

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const form = useForm<Individual>();

  return (
    <AddDeleteButton
      {...rest}
      onDeleteClick={() => {
        if (addNames) {
          form.change(
            'addNames',
            addNames.filter((item, i) => i !== index)
          );
        }
      }}
    >
      <TextInput
        source={`addNames[${index}]`}
        label={`${translateLabel('additionalName')} (${index + 1})`}
        fullWidth
      />
    </AddDeleteButton>
  );
};

interface Props {
  record?: Individual;
  actionsId?: string;
  resource: string;
  permissions: string[];
}

const EditAccountInfo = (props: Props) => {
  const { record, actionsId = 'section-actions', resource, permissions } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const formRef = useRef<any>(null);

  const focusLastAddNameInput = (e: any, lastIndex: number) => {
    e.target && e.target.blur();
    setTimeout(() => {
      const input = formRef.current?.getElementsByTagName('input')[`addNames[${lastIndex}]`];
      if (input) input.focus();
    }, 0);
  };

  return (
    <Fragment>
      <div id={actionsId}>
        <EditButton onClick={handleOpen} disabled={!permissions?.includes('individual.update')} />
        {permissions?.includes('individual.block') && record?.status === 'ACTIVE' && (
          <BlockOrUnblockProfile profileId={record.id} profileType='INDIVIDUAL' action='block' />
        )}
        {permissions?.includes('individual.unblock') && record?.status === 'BLOCKED' && (
          <BlockOrUnblockProfile profileId={record.id} profileType='INDIVIDUAL' action='unblock' />
        )}
        {permissions?.includes('kyc.workflow.start') && (
          <KYCLevelCheck record={record} resource={resource} permissions={permissions} />
        )}
        {permissions?.includes('kyc.workflow.start') && (
          <KycResetProgress record={record} resource={resource} />
        )}
        {permissions?.includes('superuser.action') && (
          <UpdatePhoneNumber record={record} permissions={permissions} />
        )}
      </div>
      <Drawer heading={translateLabel('editProfileInfo')} open={open} onClose={handleClose}>
        <div ref={formRef}>
          {record && (
            <EditForm
              resource='individuals'
              record={record}
              withDelete={permissions?.includes('individual.delete')}
              withDeleteConfirmation
              onDeleteSuccess={() => {
                history && history.push('/individuals');
              }}
            >
              <FormDataConsumer>
                {(props: FormData) => {
                  const { formData } = props;
                  const { addNames } = formData;
                  const lastAddNameIndex = (addNames && addNames.length) || 0;
                  return (
                    <Fragment>
                      <TextInput source='firstName' validate={required()} autoFocus fullWidth />
                      <TextInput source='lastName' validate={required()} fullWidth />
                      {addNames?.map((item, index) => (
                        <AddNamesField key={index} addNames={addNames} index={index} />
                      ))}
                      <TextInput
                        source={`addNames[${addNames?.length || 0}]`}
                        label={`${translateLabel('additionalName')} (${lastAddNameIndex + 1})`}
                        fullWidth
                        onChange={(e: any) => focusLastAddNameInput(e, lastAddNameIndex)}
                      />
                      <DateInput
                        label={label('birthDate')}
                        source='birthDate'
                        options={{ fullWidth: true }}
                      />
                      <SelectInput
                        source='gender'
                        allowEmpty
                        label={label('gender._')}
                        emptyText='-'
                        choices={[
                          { id: 'MALE', name: label('gender.MALE') },
                          { id: 'FEMALE', name: label('gender.FEMALE') },
                        ]}
                        fullWidth
                      />
                      <CountryInput source='nationality' label={label('nationality')} fullWidth />
                      <TextInput source='placeOfBirth' fullWidth />
                    </Fragment>
                  );
                }}
              </FormDataConsumer>
            </EditForm>
          )}
        </div>
      </Drawer>
    </Fragment>
  );
};

export default EditAccountInfo;
