import { useQuery } from 'react-query';
import { getCurrencies } from '~/api/currencies';

export const useAllCurrencies = () => {
  return useQuery('currencies', () => getCurrencies().then((res) => res.data));
};

export const useMasterCurrency = () => {
  const { data, ...rest } = useAllCurrencies();
  return { data: data?.find((item) => item.isMaster), ...rest };
};
