import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column, Transfer } from '~/types';
import { getTransactions } from '~/api/transactionsHistory';
import { RowMenu } from '~/components/TransactionsHistory/cellComponents';
import CustomCell from './CustomCell';
import TransferView from './TransferView';
import Filters from './Filters';

export const resource = 'bank/accounts';
export const target = 'bank/accounts';

interface Props {
  record?: any;
  permissions?: string[];
}

const TransfersHistory = (props: Props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string): string =>
    translate(`resources.bank/accounts.transactionsHistory.${key}`);
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading || !record) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getTransactions({
        target,
        resourceId: record.id,
        type: 'transfers',
        next: list.cursors.next,
        filters: {
          ...filters,
        },
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions || data.transfers || [],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column<Transfer>[] = [
    {
      selectCellData: (rowData) => rowData.timestamp,
      cellDataWrapperComponent: CustomCell,
      id: 'bankingDay',
      header: t('bankingDay'),
      width: 95,
      style: {
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => {
        if (rowData.from.bankAccountId === record.id) {
          return { ...rowData.amount, value: -rowData.amount.value };
        } else {
          return rowData.toAmount || rowData.amount;
        }
      },
      cellDataWrapperComponent: CustomCell,
      id: 'postedAmount',
      header: t('postedAmount'),
      cellAlign: 'right',
      headerAlign: 'right',
      style: {
        minWidth: 140,
        flexBasis: 140,
        maxWidth: 300,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => {
        if (rowData.from.bankAccountId === record.id) {
          return { ...rowData.to };
        } else {
          return rowData.from;
        }
      },
      cellDataWrapperComponent: CustomCell,
      id: 'cpBankAccount',
      header: t('cpBankAccount'),
      style: {
        minWidth: 140,
        flexBasis: 140,
        maxWidth: 300,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => {
        if (rowData.from.bankAccountId === record.id) {
          return rowData.from.accountNumber;
        } else {
          return rowData.to.accountNumber;
        }
      },
      cellDataWrapperComponent: CustomCell,
      header: t('partyAccount'),
      id: 'partyAccount',
      style: {
        minWidth: 110,
        flexBasis: 110,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => {
        if (rowData.from.bankAccountId === record.id) {
          return rowData.to.accountNumber;
        } else {
          return rowData.from.accountNumber;
        }
      },
      cellDataWrapperComponent: CustomCell,
      header: t('cpAccount'),
      id: 'cpAccount',
      style: {
        minWidth: 110,
        flexBasis: 110,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.timestamp,
      cellDataWrapperComponent: CustomCell,
      id: 'dateAndTime',
      header: t('dateAndTime'),
      //width: 160,
      style: {
        minWidth: 160,
        flexBasis: 160,
        // maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.transactionId,
      cellDataWrapperComponent: CustomCell,
      header: t('trnId'),
      id: 'trnId',
      style: {
        minWidth: 110,
        flexBasis: 300,
        // maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => ({ id: rowData.transactionId }),
      cellDataWrapperComponent: RowMenu,
      header: '',
      id: 'transferMenu',
      //width: 50,
      cellAlign: 'right',
      style: {
        minWidth: 50,
        flexBasis: 50,
        paddingLeft: 10,
      },
    },
  ];

  const [showData, setShowData] = useState<Transfer | null>(null);
  const handleShowOpen = (data: Transfer) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  const { hasNextPage, isNextPageLoading, data } = list;
  const classes = useStyles();
  if (!record) return null;
  return (
    <Fragment>
      <Filters record={record} resource={resource} />
      <VirtualizedList<Transfer>
        resource={resource}
        columns={columns}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        data={data}
        filters={filters}
        loadNextPage={loadNextPage}
        onRowClick={handleShowOpen}
        className={classes.root}
      />
      <TransferView transfer={showData} onClose={handleShowClose} />
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 1060,
  },
}));

export default TransfersHistory;
