import React from 'react';

interface Props {
  baseCurrency: string;
  quoteCurrency: string;
  rate: number;
}

const FxRateField = ({ baseCurrency, quoteCurrency, rate, ...rest }: Props) => {
  let amountText: string =
    (1 as number).toLocaleString('en-US', {
      style: 'currency',
      currency: baseCurrency,
      maximumFractionDigits: 6,
    }) +
    ' = ' +
    rate.toLocaleString('en-US', {
      style: 'currency',
      currency: quoteCurrency,
      maximumFractionDigits: 6,
    });

  return <div {...rest}>{amountText}</div>;
};

export default FxRateField;
