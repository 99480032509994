import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { SettingsIcon } from '~/img';
import { TextField, H3, HilightChanges } from '~/components';

import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';

interface DefaultCountLimitContentProps {
  record?: any;
  prevRecord?: any;
}

export const DefaultCountLimitContent = (props: DefaultCountLimitContentProps) => {
  const { record = {}, prevRecord = {} } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('limitConfiguration')}
        </H3>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('limits_.count')}>
          <HilightChanges curr={record?.count} prev={prevRecord?.count}>
            <TextField>{record?.count}</TextField>
          </HilightChanges>
        </TextField>
      </Grid>
    </Fragment>
  );
};

interface Props {
  record?: any;
  prevRecord?: any;
}

const DefaultCountLimitConfirmationField = (props: Props) => {
  const { record, prevRecord } = props;

  return (
    <Section>
      <Grid item container spacing={2}>
        <DefaultCountLimitContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default DefaultCountLimitConfirmationField;
