import React from 'react';
import cx from 'classnames';

import MuiAccordionSummary, { AccordionSummaryProps } from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

interface Props extends AccordionSummaryProps {
  expanded?: boolean;
  heading?: string | JSX.Element;
  actionNode?: JSX.Element;
  loading?: boolean;
  content?: JSX.Element;
  compact?: boolean;
}

const AccordionSummary = (props: Props) => {
  const {
    classes: classesFromProps,
    expanded,
    heading,
    actionNode = null,
    loading,
    content = null,
    compact = false,
    ...rest
  } = props;

  const classes = useStyles();
  return (
    <MuiAccordionSummary
      classes={{
        root: classes.AccordionSummaryRoot,
        content: cx(classes.AccordionSummary, compact && classes.deals),
        ...classesFromProps,
      }}
      {...rest}
    >
      <div className={classes.AccordionSummaryTop}>
        <div className={classes.headingWrapper}>
          <IconButton
            classes={{
              root: cx(classes.ExpandMoreIcon, {
                [classes.ExpandMoreIconExpanded]: expanded,
              }),
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          <div className={classes.heading}>
            {loading ? <Skeleton width="100px" height="19px" /> : heading}
          </div>
        </div>
        <div onClick={(e: any) => e.stopPropagation()} id={ACTIONS_ID}>
          {loading ? <Skeleton width="64px" height="30px" /> : actionNode}
        </div>
      </div>
      {content && <div className={cx(classes.content, compact && classes.compactView)}>{content}</div>}
    </MuiAccordionSummary>
  );
};

const ACTIONS_ID = 'accordion-actions';

const useStyles = makeStyles((theme) => ({
  AccordionSummary: {
    display: 'flex',
    flexDirection: 'column',
    [`& #${ACTIONS_ID}`]: {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      [`& #${ACTIONS_ID}`]: {
        opacity: 1,
      },
    },
  },
  deals: {
    flexDirection: 'row',
  },
  AccordionSummaryTop: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  AccordionSummaryRoot: {
    paddingLeft: 0,
  },
  ExpandMoreIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    padding: 10,
    marginLeft: 2,
    marginRight: 2,
  },
  ExpandMoreIconExpanded: {
    transform: 'rotate(180deg)',
  },
  headingWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  heading: {
    fontSize: '16px',
  },
  content: {
    padding: '8px 0 16px 48px',
  },
  compactView: {
    padding: '8px 0 8px 0',
    width: '100%',
    '&& > div > div:last-child': {
      textAlign: 'right',
    },
  },
}));

export default AccordionSummary;
