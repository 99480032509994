import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCell from '~/components/TableCell';

import { AmountField, FxRateField, H2, H3, TextField } from '~/components';
import { Section } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import OperationTypeField from '~/components/OperationTypeField';
import ProfileField from '~/components/ProfileField';

import { SenderIcon, RecipientIcon } from '~/img';

import AccountProfileField from './AccountProfileField';
import BankTransferList from './BankTransferList';
import Actions from './actions/Actions';
import { ExternalSourceField } from './context/ExternalSourceSection';

const DetailsTab: FC<any> = (props) => {
  const { record, history } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  const classes = useStyles();

  if (!record) return null;

  const trx = record as HeadTransaction;

  const senderAccount = trx.accounts.find((a) => a.id === trx.sender)!;

  const recipientAccount = trx.accounts.find((a) => a.id === trx.recipient)!;

  const senderAccountEffect = trx.accountEffects.find((e) => e.accountId === trx.sender)!;

  const recipientAccountEffect = trx.accountEffects.find((e) => e.accountId === trx.recipient)!;

  const senderExchangeRate = trx?.details.currencyConversion?.sender?.rate;
  const senderInstructedExchangeRate = trx?.details.currencyConversion?.sender?.instructedRate;
  const recipientExchangeRate = trx?.details.currencyConversion?.recipient?.rate;
  const recipientInstructedExchangeRate =
    trx?.details.currencyConversion?.recipient?.instructedRate;

  const baseAmount = trx.details.baseAmount || trx.amount;

  const payer = trx.details.payer;
  const payee = trx.details.payee;

  return (
    <Section noPaddings className={classes.section}>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('transactionDetails')}
        </H2>
        <Actions transaction={trx} history={history} />
      </header>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          <Grid item xs={4}>
            <TextField label={t('operationType')}>
              <OperationTypeField operationType={trx.operationType} />
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <AmountField amount={trx.amount} label={t('amount')} />
          </Grid>
          <Grid item xs={4}>
            <AmountField amount={baseAmount} label={t('baseAmount')} />
          </Grid>
          <Grid item xs={4}>
            <AmountField
              amount={trx.details.beneficiaryAmount || trx.amount}
              label={t('beneficiaryAmount')}
            />
          </Grid>
          <Grid item xs={4}>
            <AmountField
              amount={trx.instructedAmount || trx.details.baseAmount || trx.amount}
              label={t('instructedAmount')}
            />
          </Grid>
          {!!senderInstructedExchangeRate && (
            <Grid item xs={4}>
              <TextField label={t('instructedExchangeRate')}>
                <FxRateField
                  baseCurrency={
                    (trx.details.beneficiaryAmount || trx.details.baseAmount || trx.amount).currency
                  }
                  quoteCurrency={senderAccount.currency}
                  rate={senderInstructedExchangeRate}
                />
              </TextField>
            </Grid>
          )}
          {!!recipientInstructedExchangeRate && (
            <Grid item xs={4}>
              <TextField label={t('instructedExchangeRate')}>
                <FxRateField
                  baseCurrency={
                    (trx.details.beneficiaryAmount || trx.details.baseAmount || trx.amount).currency
                  }
                  quoteCurrency={recipientAccount.currency}
                  rate={recipientInstructedExchangeRate}
                />
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          <Grid item xs={6}>
            <Section noPaddings>
              <H3 icon={<SenderIcon />} noPaddingLeft>
                {t('sender')}
              </H3>
              <Grid container spacing={2}>
                {trx.details.senderExternalSource && (
                  <Grid item xs={12}>
                    {trx.details.senderExternalSource.id ? (
                      <ExternalSourceField
                        label={t('externalSource')}
                        id={trx.details.senderExternalSource.id}
                      />
                    ) : (
                      <TextField label={t('externalSource')}>
                        {trx.details.senderExternalSource.type}
                      </TextField>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField label={`${t('account')} ${trx.sender}`}>
                    {senderAccount.number}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <AccountProfileField label={t('accountOwner')} account={senderAccount} />
                </Grid>
                {payer && (
                  <Grid item xs={6}>
                    <ProfileField label={t('payer')} actor={payer} />
                  </Grid>
                )}
                {senderExchangeRate && (
                  <Grid item xs={12}>
                    <TextField label={t('senderExchangeRate')}>
                      <FxRateField
                        baseCurrency={trx.amount.currency}
                        quoteCurrency={senderAccount.currency}
                        rate={senderExchangeRate}
                      />
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Table style={{ width: 'auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 200 }}>{t('entry.category')}</TableCell>
                        <TableCell style={{ minWidth: 150 }}>{t('entry.amount')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {senderAccountEffect.entries.map((entry, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {entry.description ||
                              (entry.category === 'TRANSACTION'
                                ? t('entry.transactionAmount')
                                : entry.category)}
                          </TableCell>
                          <TableCell>
                            <AmountField amount={entry.amount} />
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>{t('entry.totalAmount')}</TableCell>
                        <TableCell>
                          <AmountField
                            amount={{
                              value: senderAccountEffect.totalBalanceEffect,
                              currency: senderAccount.currency,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item xs={6}>
            <Section>
              <H3 icon={<RecipientIcon />} noPaddingLeft>
                {t('recipient')}
              </H3>
              <Grid container spacing={2}>
                {trx.details.recipientExternalSource && (
                  <Grid item xs={12}>
                    {trx.details.recipientExternalSource.id ? (
                      <ExternalSourceField
                        label={t('externalSource')}
                        id={trx.details.recipientExternalSource.id}
                      />
                    ) : (
                      <TextField label={t('externalSource')}>
                        {trx.details.recipientExternalSource.type}
                      </TextField>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField label={`${t('account')} ${trx.recipient}`}>
                    {recipientAccount.number}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <AccountProfileField label={t('accountOwner')} account={recipientAccount} />
                </Grid>
                {payee && (
                  <Grid item xs={6}>
                    <ProfileField label={t('payee')} actor={payee} />
                  </Grid>
                )}
                {recipientExchangeRate && (
                  <Grid item xs={12}>
                    <TextField label={t('recipientExchangeRate')}>
                      <FxRateField
                        baseCurrency={trx.amount.currency}
                        quoteCurrency={recipientAccount.currency}
                        rate={recipientExchangeRate}
                      />
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Table style={{ width: 'auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 200 }}>{t('entry.category')}</TableCell>
                        <TableCell style={{ minWidth: 150 }}>{t('entry.amount')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recipientAccountEffect.entries.map((entry, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {entry.description ||
                              (entry.category === 'TRANSACTION'
                                ? t('entry.transactionAmount')
                                : entry.category)}
                          </TableCell>
                          <TableCell>
                            <AmountField amount={entry.amount} />
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>{t('entry.totalAmount')}</TableCell>
                        <TableCell>
                          <AmountField
                            amount={{
                              value: recipientAccountEffect.totalBalanceEffect,
                              currency: recipientAccount.currency,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Section>
          </Grid>
        </Grid>
      </Section>
      <Grid container justify='space-between' className={classes.bottomSpacing}>
        <Grid item xs={12}>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('transfers')}
          </H2>
          <Section>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('transfer.amount')}</TableCell>
                  <TableCell>{t('transfer.from')}</TableCell>
                  <TableCell>{t('transfer.to')}</TableCell>
                  <TableCell>{t('transfer.type')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trx.transfers.map((transfer) => (
                  <TableRow key={transfer.id}>
                    <TableCell>
                      <AmountField amount={transfer.amount} />
                    </TableCell>
                    <TableCell>
                      {(() => {
                        const a = trx.accounts.find((a) => a.id === transfer.from);
                        return a?.alias ? (
                          <div>
                            <div style={{ fontSize: 11, opacity: 0.5 }}>{a?.alias}</div>
                            <div>{a?.number}</div>
                          </div>
                        ) : (
                          <span>{a?.number}</span>
                        );
                      })()}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        const a = trx.accounts.find((a) => a.id === transfer.to);
                        return a?.alias ? (
                          <div>
                            <div style={{ fontSize: 11, opacity: 0.5 }}>{a?.alias}</div>
                            <div>{a?.number}</div>
                          </div>
                        ) : (
                          <span>{a?.number}</span>
                        );
                      })()}
                    </TableCell>
                    <TableCell>
                      <OperationTypeField operationType={transfer.operationType} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </Grid>
      </Grid>
      {trx.status === 'COMPLETED' && (
        <Grid container justify='space-between' className={classes.bottomSpacing}>
          <Grid item xs={12}>
            <H2 topSpacing={2} bottomSpacing={2}>
              {t('bankAccountTransfers')}
            </H2>
          </Grid>
          <Grid item xs={12}>
            <Section>
              <BankTransferList transaction={trx} {...props} />
            </Section>
          </Grid>
        </Grid>
      )}
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  labelBottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
  reversal: {
    color: theme.palette.warning.main,
  },
  settlement: {
    // color: theme.palette.success.main,
  },
}));

export default DetailsTab;
