import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import lodashGet from 'lodash/get';

const useStyles = makeStyles({
  root: {
    display: 'none',
  },
});

const HiddenInput = (props) => {
  const { defaultValue, source } = props;
  const classes = useStyles();
  const form = useForm();
  const fieldState = lodashGet(form.getState()?.values, source);

  useEffect(() => {
    if (!!defaultValue && typeof defaultValue !== 'string' && !fieldState) {
      form.change(source, defaultValue);
    }
  }, [defaultValue, source, form, fieldState]);

  if (defaultValue && typeof defaultValue !== 'string') {
    return null;
  }
  return <TextInput {...props} className={classes.root} />;
};

export default HiddenInput;
