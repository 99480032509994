import React from 'react';
import { ArrayField, BooleanField, Datagrid, Labeled, SingleFieldList } from 'react-admin';
import get from 'lodash/get';

import ObjectFieldFactory from './ObjectField';
import { PrimitiveArrayField } from '~/components/ra';
import { DateField, TextField, NumberField, FeeField, FileField } from './fields';

const buildFields = (rootProps) => {
  const { context, objectSchema, sourcePrefix = '', defaultData, exclude, ...props } = rootProps;

  const label = (s) => {
    return `jsonSchema.${context}.${s}`;
  };

  function toSource(key) {
    if (sourcePrefix) {
      return `${sourcePrefix}.${key}`;
    } else {
      return key;
    }
  }

  function buildField(objectSchema, propertyKey) {
    const source = toSource(propertyKey);
    const propertySchema = objectSchema.properties[propertyKey];

    const defaultValue = defaultData ? get(defaultData, source) : undefined;

    switch (propertySchema.inputType) {
      case 'textarea':
        return (
          <TextField {...props} source={source} label={label(source)} defaultValue={defaultValue} />
        );
      default: // do nothing
    }
    switch (propertySchema.type) {
      case 'integer':
      case 'number':
        return (
          <NumberField
            {...props}
            source={source}
            label={label(source)}
            defaultValue={defaultValue}
          />
        );
      case 'string':
        switch (propertySchema.format) {
          case 'date-time':
            return (
              <DateField
                {...props}
                source={source}
                label={`${label(source)}`}
                defaultValue={defaultValue}
                showTime
              />
            );
          case 'date':
            return (
              <DateField
                {...props}
                source={source}
                label={`${label(source)}`}
                defaultValue={defaultValue}
              />
            );
          default: // do nothing
        }
        if (propertySchema.enum) {
          return (
            <TextField
              {...props}
              source={source}
              label={`${label(source)}._`}
              defaultValue={defaultValue}
            />
          );
        } else {
          return (
            <TextField
              {...props}
              source={source}
              label={label(source)}
              defaultValue={defaultValue}
            />
          );
        }
      case 'boolean':
        return (
          <BooleanField
            {...props}
            source={source}
            label={label(source)}
            defaultValue={defaultValue}
          />
        );
      case 'array':
        if (propertySchema.items.type === 'file') {
          return (
            <ArrayField
              {...props}
              source={source}
              label={`${label(source)}`}
              defaultValue={defaultValue}
            >
              <SingleFieldList linkType={null}>
                <FileField />
              </SingleFieldList>
            </ArrayField>
          );
        } else if (propertySchema.items.type === 'string') {
          return (
            <PrimitiveArrayField
              {...props}
              source={source}
              label={`${label(source)}`}
              defaultValue={defaultValue}
            />
          );
        } else {
          return (
            <ArrayField
              {...props}
              source={source}
              label={`${label(source)}._`}
              defaultValue={defaultValue}
            >
              <Datagrid>
                {buildFields({
                  context: `${context}.${source}`,
                  objectSchema: propertySchema.items,
                  addLabel: false,
                  ...props,
                })}
              </Datagrid>
            </ArrayField>
          );
        }
      case 'object':
        if (get(props.record, source) || (defaultData && get(defaultData, source))) {
          return (
            <ObjectField
              {...props}
              context={context}
              objectSchema={propertySchema}
              defaultData={defaultData}
              source={source}
            />
          );
        }
        break;
      case 'file':
        return <FileField source={source} />;
      case 'veengu.product':
        return (
          <TextField {...props} label={label(source)} source={source} defaultValue={defaultValue} />
        );
      case 'veengu.fee':
        return (
          <FeeField {...props} label={label(source)} source={source} defaultValue={defaultValue} />
        );
      default: // do nothing
    }
    return null;
  }

  const result = Object.keys(objectSchema.properties)
    .filter((propertyKey) => {
      const source = toSource(propertyKey);
      return !exclude || !exclude(source);
    })
    .sort((a, b) => {
      return (a + '').localeCompare(b);
    })
    .sort((a, b) => {
      const p1 = objectSchema.properties[a],
        p2 = objectSchema.properties[b];
      if ((p1.formIndex || 999) < (p2.formIndex || 999)) {
        return -1;
      } else if ((p1.formIndex || 999) > (p2.formIndex || 999)) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((propertyKey) => {
      return buildField(objectSchema, propertyKey);
    })
    .filter((field) => {
      return field && true;
    })
    .map((field) => {
      return field.props.addLabel ? (
        <Labeled {...props} label={field.props.label} source={field.props.source} disabled={false}>
          {field}
        </Labeled>
      ) : (
        field
      );
    });
  return result;
};

const ObjectField = ObjectFieldFactory(buildFields);

export default buildFields;
