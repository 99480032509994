import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { time } from '~/utils';

import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { debounce } from 'lodash';
import { l } from '../BankAccountList';
import { ExportToCSV } from '~/components';
import { BankSummaryType } from '~/api/bankAccounts';

interface Props {
  resource: string;
  record: any;
  data: BankSummaryType[];
}

const BankDaySummaryFilters = (props: Props) => {
  const { resource, record, data } = props;

  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`bankDaySummary.${key}`));

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };
  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);

  const handleChange = (name: string) => (eventOrData: any) => {
    switch (name) {
      case 'fromDate':
      case 'toDate': {
        setLocalValues((prev) => ({
          ...prev,
          [name]: eventOrData as Moment,
        }));
        dispatchFilterChange(name, eventOrData as Moment);
        break;
      }
      default: {
        const value = eventOrData?.target?.value || '';
        setLocalValues((prev) => ({
          ...prev,
          [name]: value as string,
        }));
        debouncedDispatchFilterChange(name, value as string);
      }
    }
  };

  useEffect(() => {
    dispatch(
      filterChange({
        resource,
        filters: {
          fromDate: time().subtract(1, 'months'),
          toDate: time(),
        },
      })
    );
    return () => {
      dispatch(cleanupFilters({ resource }));
    };
  }, [dispatch, resource]);

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.inputsGroup}>
            <DatePicker
              className={classes.datePickers}
              format="YYYY-MM-DD"
              margin="normal"
              label={t('fromDate')}
              value={localValues.fromDate}
              onChange={handleChange('fromDate')}
            />
            <DatePicker
              className={classes.datePickers}
              format="YYYY-MM-DD"
              margin="normal"
              label={t('toDate')}
              value={localValues.toDate}
              onChange={handleChange('toDate')}
            />
          </div>
          <ExportToCSV<any>
            data={() => transformToExport(data, record)}
            fileName={`'${record?.alias || 'Bank account'}' day summary ${time().format('L LT')}`}
          />
        </div>
      )}
    </Fragment>
  );
};

const transformToExport = (data: BankSummaryType[], record: any) => {
  if (!data) return [];
  return data.map((item) => {
    const { amount, bankAccountId, count, date } = item;

    return {
      bankAccountId,
      count,
      date,
      amount_currency: record.currency,
      amount_value: amount,
    };
  });
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 200,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default BankDaySummaryFilters;
