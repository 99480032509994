import axios from '~/utils/axios';

export interface CurrenciesRates {
  id: string;
  key?: string;
  version?: string;
  name: string;
  currencyPairs: string[];
  sourceSystem?: string;
  buyRateSource: 'INPUT' | 'CALCULATION' | 'MID_RATE';
  sellRateSource: CurrenciesRates['buyRateSource'];
  buyMargin?: number;
  sellMargin?: number;
  profitAccountId: string;
  data: {
    currencyPair?: string;
    baseCurrency: string;
    quoteCurrency: string;
    country?: string;
    midRate?: number;
    buyRate?: number;
    sellRate?: number;
    buyMargin?: number;
    sellMargin?: number;
    fallbackBuyMargin?: number;
    fallbackSellMargin?: number;
    updatedAt: string | undefined;
  }[];
  settlementData?: {
    system: string;
    accounts: Record<string, string>;
  };
  useForFxProcessing: boolean;
  created?: string;
  deleted?: boolean;
  updated?: string;
  additionalConfiguration: {
    tablesIds: string[];
  };
}

export const getCurrenciesRates = () => {
  return axios.get<CurrenciesRates[]>(`/fxrates`).then((res) => {
    const { data, ...rest } = res;
    const sortedData = data.sort((a, b) => (a.name > b.name ? 1 : -1));
    return { data: sortedData, ...rest };
  });
};

export const createCurrenciesRates = (body: CurrenciesRates) => {
  return axios.post<CurrenciesRates>(`/fxrates`, body);
};

export const getSourceSystems = () => {
  return axios.get<string[]>('/fxrates/sourceSystems').then((res) => res.data);
};

export const getSettlementSystems = () => {
  return axios.get<string[]>('/fxrates/settlementSystems').then((res) => res.data);
};

export const getSourceSystemSchema = (sourceSystem: string) => {
  return axios
    .get<{
      pairs: {
        baseCurrency: string;
        quoteCurrency: string;
        country?: string;
        buyMargin?: boolean; // indicates if it is possible to setup buy margin
        sellMargin?: boolean; // indicates if it is possible to setup sell margin
        sellRate?: boolean; // indicates if it is possible to retriwe sell rate
        buyRate?: boolean; // indicates if it is possible to retriwe buy rate
        fallbackBuyMargin?: boolean; // indicates if it is possible to setup fallback buy margin
        fallbackSellMargin?: boolean; // indicates if it is possible to setup fallback sell margin
      }[];
    }>(`/fxrates/sourceSystems/${sourceSystem}/schema`)
    .then((res) => res.data);
};

export const getSourceSystemRate = (
  sourceSystem: string,
  params: {
    baseCurrency: string;
    quoteCurrency: string;
    operation: 'sell' | 'buy';
    country?: string;
  }
) => {
  return axios
    .get<{
      baseCurrency: string;
      quoteCurrency: string;
      operation: 'sell' | 'buy';
      country?: string;
      rate: number;
    }>(`/fxrates/sourceSystems/${sourceSystem}/rate`, { params })
    .then((res) => res.data);
};

export const getFXRatesById = (id: string) => {
  return axios.get<CurrenciesRates>(`/fxrates/${id}`).then((res) => res.data);
};

export type RateBody = {
  fxRatesId?: string;
  baseCurrency: string;
  quoteCurrency: string;
  buyNotSell: boolean;
  country?: string;
};

export const checkFXRates = (body: Record<string, RateBody>) => {
  return axios.post<Record<string, number>>(`/fxrates/check`, body).then((res) => res.data);
};
