import * as React from 'react';
import { useQueryWithStore, useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';

import { CircularProgress, makeStyles, Tabs, Tab, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import * as hb from '~/types/Handbook';

import GenericRecordTable from './GenericRecordTable';
import KycLevelTable from './KycLeveltable';
import CountryTable from './CountryTable';
import CurrencyTable from './CurrencyTable';
import LocalizationTable from './LocalizationTable';
import { sanitize } from './utils';

import { handbookUpdate } from '~/ducks/solutions';
import { useReduxState } from '~/hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ paddingLeft: 7 }}>{children}</div>}
    </Typography>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginLeft: '-16px',
  },
}));

export const useUpdateHanbookLoading = () => {
  return useReduxState('handbookUpdating', false);
};

const HandbookField = ({
  initialState,
  solutionId,
}: {
  initialState: hb.Handbook;
  solutionId: string;
}) => {
  const dispatch = useDispatch();
  // main state
  const [state, setState] = React.useState<Partial<hb.Handbook>>(initialState);
  const [, setLoading] = useUpdateHanbookLoading();

  const dataProvider = useDataProvider();

  const updateAndSetState: hb.SetState = (patch) => {
    const newState = sanitize({ ...state, ...patch });
    setLoading(true);
    return dataProvider
      .updateSolutionHandbook('handbook', {
        solutionId,
        handbook: newState,
      })
      .then(() => {
        setState(newState);
        dispatch(handbookUpdate({ solutionId, data: newState }));
        return Promise.resolve();
      })
      .catch((error: any) => {
        console.log('error: ', error);
        return Promise.reject();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // tabs state
  const classes = useStyles();
  const [tabsValue, setTabsValue] = React.useState(0);

  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  // render
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabsValue}
        onChange={handleTabsChange}
        className={classes.tabs}
      >
        <Tab label="Countries" {...a11yProps(0)} />
        <Tab label="Currencies" {...a11yProps(1)} />
        <Tab label="Localizations" {...a11yProps(2)} />
        <Tab label="KYC levels" {...a11yProps(3)} />
        <Tab label="MCC" {...a11yProps(4)} />
        <Tab label="Industries" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={tabsValue} index={0}>
        <CountryTable handbook={state} setState={updateAndSetState} />
      </TabPanel>
      <TabPanel value={tabsValue} index={1}>
        <CurrencyTable handbook={state} setState={updateAndSetState} />
      </TabPanel>
      <TabPanel value={tabsValue} index={2}>
        <LocalizationTable handbook={state} setState={updateAndSetState} />
      </TabPanel>
      <TabPanel value={tabsValue} index={3}>
        <KycLevelTable handbook={state} setState={updateAndSetState} />
      </TabPanel>
      <TabPanel value={tabsValue} index={4}>
        <GenericRecordTable
          handbook={state}
          setState={updateAndSetState}
          property="merchantCategoryCodes"
        />
      </TabPanel>
      <TabPanel value={tabsValue} index={5}>
        <GenericRecordTable handbook={state} setState={updateAndSetState} property="industries" />
      </TabPanel>
    </div>
  );
};

export interface Props {
  solutionId: string;
}

const HandbookFieldWrapper = ({ solutionId }: Props) => {
  const { loading, error, data } = useQueryWithStore({
    type: 'getSolutionHandbook',
    resource: 'handbook',
    payload: { solutionId },
  });

  if (loading) {
    return <CircularProgress />;
  } else if (error) {
    return <Alert severity="error">Failed to load handbook</Alert>;
  } else {
    const initialState: hb.Handbook = data || [];
    return <HandbookField initialState={initialState} solutionId={solutionId} />;
  }
};

export default HandbookFieldWrapper;
