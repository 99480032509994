import React, { FC } from 'react';
import { Datagrid, Filter, TextField, TextInput, usePermissions } from 'react-admin';

import { List } from '~/layout';
import { NoPermissions } from '~/components/ra';
import { useApiIdentitiesT } from './utils';

const ApiIdentitiesList: FC<any> = (props) => {
  const t = useApiIdentitiesT();

  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('apiIdentity.list')) return <NoPermissions />;

  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={
        <Filter>
          <TextInput label={t('name')} source="name" alwaysOn style={filterInputStyle} />
          <TextInput label={t('username')} source="username" alwaysOn style={filterInputStyle} />
          <TextInput
            label={t('emailAddress')}
            source="emailAddress"
            alwaysOn
            style={filterInputStyle}
          />
          <TextInput
            label={t('phoneNumber')}
            source="phoneNumber"
            alwaysOn
            style={filterInputStyle}
          />
        </Filter>
      }
      // actions={<ApiIdentitiesActions {...props} />}
    >
      <Datagrid rowClick="show" size="medium" selectedIds={null}>
        <TextField label={t('name')} source="name" sortable={false} />
        <TextField label={t('username')} source="username" sortable={false} />
        <TextField label={t('emailAddress')} source="emailAddress" sortable={false} />
        <TextField label={t('phoneNumber')} source="phoneNumber" sortable={false} />
      </Datagrid>
    </List>
  );
};

const filterInputStyle = { minWidth: 212 };

export default ApiIdentitiesList;
