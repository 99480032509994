import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AmountField, H2, H3, StatusField, TextField } from '~/components';
import { Section } from '~/layout';
import { l } from '../BankAccountList';
import BankAccountEdit from '../BankAccountEdit';

const Details: FC<any> = (props) => {
  const { permissions, record, resource } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  if (!record) return null;
  const { balance } = record;
  return (
    <Section noPaddings>
      <Grid container justify="space-between">
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('accountDetails')}
          </H2>
        </Grid>
        <Grid item id="section-actions">
          <BankAccountEdit permissions={permissions} record={record} resource={resource} />
        </Grid>
      </Grid>
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField label={t('alias')}>{record.alias}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('accountNumber')}>{record.number}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('currency')}>{record.currency}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('description')}>{record.description}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('status')}>
              <StatusField status="ACTIVE" />
            </TextField>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <H3 icon={<AttachMoneyIcon />} noPaddingLeft>
          {t('accountSummary')}
        </H3>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('balance')}</TableCell>
              <TableCell>{t('amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t('total')}</TableCell>
              <TableCell className={classes.amountCell}>
                <AmountField amount={balance} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Section>
    </Section>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
}));

export default Details;
