import React from 'react';
import { useTranslate, ReferenceField as RaReferenceField } from 'react-admin';
import { TextField as RaTextField } from '~/components/ra';
import Grid from '@material-ui/core/Grid';

import { H2, TextField } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './ContextTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';
import ProfileField from '~/components/ProfileField';

const OriginatorSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`originator.${key}`));

  const originator = trx?.details.originator;
  const tradeName = trx?.details.tradeName;
  const employee = trx?.details.employee;
  const location = trx?.details.location;
  const terminal = trx?.details.terminal;
  const paymentToken = trx?.details.paymentToken;
  const mcc = trx?.details.mcc;

  const hasOriginator =
    [originator, tradeName, employee?.id, location?.id, terminal?.id, paymentToken, mcc].filter(
      (item) => !!item
    ).length > 0;

  const makeReferenceProps = (reference: string, id: string) => {
    return {
      reference,
      basePath: `/${reference}`,
      source: 'id',
      record: { id },
      link: false,
      addLabel: false,
    };
  };

  const makeReferenceValue = (reference: string, record: any) => {
    switch (reference) {
      case 'terminals':
        return record.name || record.label;
      case 'employees':
        return record.name || `${record.firstName} ${record.lastName}`;
      case 'locations':
        return record.name;
      default:
        return record.name;
    }
  };

  const makeReferenceLink = (reference: string, record: any): string => {
    switch (reference) {
      case 'terminals':
        return `/businesses/${record.businessId}/show/terminals`;
      case 'employees':
        return `/businesses/${record.businessId}/show/employees`;
      case 'locations':
        return `/businesses/${record.businessId}/show/locations`;
      default:
        return `/${reference}/${record.id}/show`;
    }
  };

  const ReferenceField = (props: { reference: string; id: string; label: string }) => {
    const { reference, id, label } = props;
    return (
      <RaReferenceField {...makeReferenceProps(reference, id)}>
        <RaTextField
          fn={(record: any) => makeReferenceValue(reference, record)}
          label={label}
          allowEmpty
          showLink={(record: any) => makeReferenceLink(reference, record)}
          textWrap
        />
      </RaReferenceField>
    );
  };

  return hasOriginator ? (
    <>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('_')}
        </H2>
      </header>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          <Grid item xs={4}>
            {originator ? (
              <ProfileField actor={originator} label={t('_')} />
            ) : (
              <TextField label={t('_')}>{t('unknownOriginator')}</TextField>
            )}
          </Grid>
          {tradeName && (
            <Grid item xs={4}>
              <TextField label={t('tradeName')}>{tradeName}</TextField>
            </Grid>
          )}
          {employee?.id && (
            <Grid item xs={4}>
              <ReferenceField reference="employees" id={employee.id} label={t('employee')} />
            </Grid>
          )}
          {location?.id && (
            <Grid item xs={4}>
              <ReferenceField reference="locations" id={location.id} label={t('location')} />
            </Grid>
          )}
          {terminal?.id && (
            <Grid item xs={4}>
              <ReferenceField reference="terminals" id={terminal.id} label={t('terminal')} />
            </Grid>
          )}
          {paymentToken && (
            <Grid item xs={4}>
              <TextField label={t(`paymentToken.${paymentToken.type}`)}>
                {paymentToken.id}
              </TextField>
            </Grid>
          )}
          {mcc && (
            <Grid item xs={4}>
              <TextField label={t(`mcc`)}>{mcc}</TextField>
            </Grid>
          )}
        </Grid>
      </Section>
    </>
  ) : null;
};

export default OriginatorSection;
