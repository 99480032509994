import React, { ChangeEvent, FC, Fragment, useMemo } from 'react';
import { useTranslate, required, NumberInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-final-form';

import { AmountField } from '~/components';
import { CurrenciesRates } from '~/api/fxrates';

import { EditForm, HilightInputChanges } from '~/components/ra';
import { l } from '../CurrenciesList';
import { NumberEx } from '~/utils';
import { makeStyles } from '@material-ui/core/styles';

interface RateDataTableProps {
  data: CurrenciesRates['data'];
  record: CurrenciesRates;
  className?: string;
  refetch: () => void;
  onClose: () => void;
}

const RateDataTableEdit: FC<RateDataTableProps> = (props) => {
  const { data, record, refetch, onClose } = props;
  const actualRecord = { ...record, data };

  const onSubmit = (
    newRecord: CurrenciesRates,
    submitQuery: (newRecord: any, onSuccess?: () => void, onFailure?: () => void) => void
  ) => {
    submitQuery(newRecord, () => {
      refetch();
      onClose();
    });
  };

  return (
    <EditForm<CurrenciesRates>
      resource="fxrates"
      record={actualRecord}
      onSubmit={onSubmit}
      withSaveConfirmation
    >
      <RateDataForm {...props} record={actualRecord} />
    </EditForm>
  );
};

const RateDataForm: FC<RateDataTableProps> = (props) => {
  const { data, record } = props;
  const { buyRateSource, sellRateSource, buyMargin = 0, sellMargin = 0 } = record;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const form = useForm();

  const handleMidRateChange = (buyName: string, sellName: string) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const roundPositive = (value: number) => NumberEx.positive(NumberEx.round(value));
    if (buyRateSource === 'CALCULATION') {
      const buyValue = Number(value) * (1 - buyMargin);
      form.change(buyName, roundPositive(buyValue));
    }
    if (buyRateSource === 'MID_RATE') {
      form.change(buyName, value);
    }
    if (sellRateSource === 'CALCULATION') {
      const sellValue = Number(value) * (1 + sellMargin);
      form.change(sellName, roundPositive(sellValue));
    }
    if (sellRateSource === 'MID_RATE') {
      form.change(sellName, value);
    }
  };

  const defaultNumberInputProps = useMemo(
    () => ({
      validate: [
        required(),
        (value: number) =>
          value === 0 ? { message: 'components.ra.inputs.AmountInput.nonNullable' } : undefined,
      ],
      step: 0.01,
      record,
      min: 0,
      parse: NumberEx.positive,
    }),
    [record]
  );

  const classes = useStyles();

  return (
    <Fragment>
      {data &&
        data.map((item, index) => {
          const { baseCurrency, quoteCurrency } = item;
          const pairString = `${baseCurrency}/${quoteCurrency}`;
          const getSource = (source: string): string => `data[${index}].${source}`;

          return (
            <Grid className={classes.root} container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item className={classes.gridTextItem}>
                <div>{pairString}</div>
              </Grid>
              <Grid item className={classes.gridTextItem}>
                <AmountField
                  className={classes.gridAmount}
                  amount={{ value: 1, currency: baseCurrency }}
                />
                <div>=</div>
              </Grid>
              <Grid item>
                <HilightInputChanges source={getSource('midRate')}>
                  <NumberInput
                    label={t('mid')}
                    source={getSource('midRate')}
                    onChange={handleMidRateChange(getSource('buyRate'), getSource('sellRate'))}
                    {...defaultNumberInputProps}
                  />
                </HilightInputChanges>
              </Grid>
              <Grid item>
                <HilightInputChanges source={getSource('buyRate')}>
                  <NumberInput
                    label={t('buy')}
                    source={getSource('buyRate')}
                    disabled={buyRateSource === 'CALCULATION' || buyRateSource === 'MID_RATE'}
                    {...defaultNumberInputProps}
                  />
                </HilightInputChanges>
              </Grid>
              <Grid item>
                <HilightInputChanges source={getSource('sellRate')}>
                  <NumberInput
                    label={t('sell')}
                    source={getSource('sellRate')}
                    disabled={sellRateSource === 'CALCULATION' || buyRateSource === 'MID_RATE'}
                    {...defaultNumberInputProps}
                  />
                </HilightInputChanges>
              </Grid>
              <Grid item className={classes.gridTextItem}>
                <div>{quoteCurrency}</div>
              </Grid>
            </Grid>
          );
        })}
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  gridAmount: {
    fontSize: '18px !important',
    marginRight: 4,
  },
  gridTextItem: {
    display: 'flex',
    paddingBottom: 20,
    fontSize: 18,
    fontWeight: 500,
    width: 105,
  },
}));

export default RateDataTableEdit;
