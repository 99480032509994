import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { time } from '~/utils';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Amount, Column } from '~/types';
import { BankSummaryType, getBankDaySummary } from '~/api/bankAccounts';
import BankDaySummaryFilters from './BankDaySummaryFilters';
import isEmpty from 'lodash/isEmpty';
import CustomCell from './BankDaySummaryCustomCell';

export const resource = 'bankDaySummary';

interface Props {
  record?: any;
  permissions?: string[];
}

const BankDaySummary = (props: Props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.bank/accounts.bankDaySummary.${key}`);
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading || !record) return;
    const actualFilters = isEmpty(filters)
      ? {
          fromDate: time().subtract(1, 'months'),
          toDate: time(),
        }
      : filters;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getBankDaySummary({
        id: record.id,
        next: list.cursors.next,
        filters: actualFilters,
      });
      const responseData = {
        cursors: {
          next: undefined,
        },
        records: data?.reverse(),
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column<BankSummaryType>[] = [
    {
      selectCellData: (rowData) => rowData.date,
      cellDataWrapperComponent: CustomCell,
      id: 'bankingDay',
      header: t('bankingDay'),
      width: 95,
    },
    {
      selectCellData: (rowData) => rowData.count,
      cellDataWrapperComponent: CustomCell,
      id: 'count',
      header: t('totalOperations'),
      width: 180,
    },
    {
      selectCellData: (rowData) => ({ value: rowData.amount, currency: record.currency } as Amount),
      cellDataWrapperComponent: CustomCell,
      id: 'amount',
      header: t('totalAmount'),
      cellAlign: 'right',
      headerAlign: 'right',
      width: 150,
    },
  ];

  // const [showData, setShowData] = useState<BankSummaryType | null>(null);
  // const handleShowOpen = (data: BankSummaryType) => setShowData(data);
  // const handleShowClose = () => setShowData(null);

  const { hasNextPage, isNextPageLoading, data } = list;
  const classes = useStyles();
  if (!record) return null;
  return (
    <Fragment>
      <BankDaySummaryFilters data={data} record={record} resource={resource} />
      <VirtualizedList<BankSummaryType>
        resource={resource}
        columns={columns}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        data={data}
        filters={filters}
        loadNextPage={loadNextPage}
        // onRowClick={handleShowOpen}
        className={classes.root}
      />
      {/* <TransferView transfer={showData} onClose={handleShowClose} /> */}
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 1060,
  },
}));

export default BankDaySummary;
