import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '~/ducks/virtualizedList';
import Title from '~/components/Title';
import Filters from './Filters';
import AuditLogTable from './AuditLogTable';

export const resource = 'auditLog';

const AuditLogList: FC<any> = () => {
  const filters = useSelector(selectors.getFilters(resource));

  return (
    <>
      <Title resource={resource} />
      <Filters />
      <AuditLogTable filters={filters} outterTable />
    </>
  );
};

export default AuditLogList;
