import React from 'react';
import { useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, colors } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
    },
    avatar: {
      width: 50,
      height: 50,
    },
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '20px',
      paddingBottom: '7px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
  };
});

interface Props {
  resource: string;
  icon?: JSX.Element;
  headline?: any;
  subtitle?: any;
  style?: object;
}

const Title = (props: Props) => {
  const { resource, style, icon, headline, subtitle } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.${resource}.${key}`, 2);

  const classes = useStyles();

  return (
    <div className={classes.root} style={style || {}}>
      {icon && <div className={classes.avatarWrapper}>{icon}</div>}
      <div>
        <Typography variant="h2" className={classes.headline}>
          {headline || t('name')}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle || t('subtitle')}
        </Typography>
      </div>
    </div>
  );
};

export default Title;
