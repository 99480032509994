import React, { Component, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { MenuItemLink, WithPermissions } from 'react-admin';

import { useTranslate } from 'react-admin';

// icons
// https://material.io/resources/icons/?icon=person&style=baseline
import DashboardIcon from '@material-ui/icons/Dashboard';
import Solutions from '@material-ui/icons/Star';
import BusinessesIcon from '@material-ui/icons/Store';
import PricingIcon from '@material-ui/icons/AttachMoney';
import DealIcon from '@material-ui/icons/Public';
import ReportsIcon from '@material-ui/icons/Assessment';
import ApiIdentitiesIcon from '@material-ui/icons/Fingerprint';
import FeedbackIcon from '@material-ui/icons/StarHalfRounded';
import BatchIcon from '@material-ui/icons/DynamicFeed';
import PaymentServicesIcon from '@material-ui/icons/Category';
import LocalizationIcon from '@material-ui/icons/Language';
import RolesIcon from '@material-ui/icons/HowToReg';
import LinkIcon from '@material-ui/icons/Link';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import {
  PersonIcon as IndividualsIcon,
  FileTrayFullIcon as AuditIcon,
  TrailSign as PartnersIcon,
  PersonCircleIcon as TeamIcon,
  ShieldCheckmarkIcon as KYCAppIcon,
  ArchiveIcon as PnLIcon,
  AlbumsIcon as BankAccountIcon,
  ReaderIcon as TransactionIcon,
  SyncCircleIcon as CurrenciesIcon,
} from '~/img';

import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import { RootState } from '~/types';
import { getKycCredentials, kycAppLogin, openKycApp } from '~/api/kyc';
import onError from '~/errorsHandler';

interface Props {
  onMenuClick?: () => void;
  permissions: string[];
  logout?: any;
  dense?: any;
}

const Menu = ({ onMenuClick, permissions, dense }: Props) => {
  const translate = useTranslate();
  useSelector<RootState>((state) => state.theme); // force rerender on theme change

  const open = useSelector<RootState>((state) => state.admin.ui.sidebarOpen);
  useSelector<RootState>((state) => state.theme); // force rerender on theme change

  const [kycLoading, setKycLoading] = useState(false);
  const handleKycAppClick = async () => {
    setKycLoading(true);
    try {
      const {
        data: { username, password },
      } = await getKycCredentials();
      await kycAppLogin(username, password);
      openKycApp();
    } catch (error) {
      onError(error);
    }
    setKycLoading(false);
  };

  const isFullWidth = true;
  const items = [];
  items.push(
    <MenuItemLink
      key='dashboard'
      onClick={onMenuClick}
      to='/'
      primaryText={isFullWidth && translate('layout.menu.home')}
      leftIcon={<DashboardIcon />}
      dense={dense}
      sidebarIsOpen={open}
      exact
    />
  );
  items.push(<Divider key='div0' />);
  items.push(
    <MenuItemLink
      key='accounts'
      to={`/accounts`}
      primaryText={isFullWidth && translate(`layout.menu.accounts`)}
      leftIcon={
        <SvgIcon>
          <AccountBalanceWallet />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('account.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='tenant/accounts'
      to={`/tenant/accounts`}
      primaryText={isFullWidth && translate(`layout.menu.pnlAccounts`)}
      leftIcon={
        <SvgIcon>
          <PnLIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('tenant.account.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='bank/accounts'
      to={`/bank/accounts`}
      primaryText={isFullWidth && translate(`layout.menu.bankAccounts`)}
      leftIcon={
        <SvgIcon>
          <BankAccountIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('bank.account.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/headTransactions`}
      key='headTransactions'
      primaryText={isFullWidth && translate('layout.menu.headTransactions')}
      leftIcon={
        <SvgIcon>
          <TransactionIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('headTransaction.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/deals`}
      key='deals'
      primaryText={isFullWidth && translate('layout.menu.deals')}
      leftIcon={<DealIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('deal.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/actionReports`}
      key='actionReports'
      primaryText={isFullWidth && translate('layout.menu.actionReports')}
      leftIcon={<ReportsIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('report.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/events`}
      key='events'
      primaryText={isFullWidth && translate('layout.menu.events')}
      leftIcon={<FeedbackIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('event.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/batches`}
      key='batches'
      primaryText={isFullWidth && translate('layout.menu.batches')}
      leftIcon={<BatchIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('batchPayment.list')}
    />
  );
  /*
  items.push(
    <MenuItemLink
      key="tenant/accounts/transactions"
      to={`/tenant/accounts/transactions`}
      primaryText={isFullWidth && translate(`layout.menu.transactions`)}
      leftIcon={
        <SvgIcon>
          <TransactionIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled
    />
  );
  items.push(
    <MenuItemLink
      key="tenant/accounts/reports"
      to={`/tenant/accounts/reports`}
      primaryText={isFullWidth && translate(`layout.menu.reports`)}
      leftIcon={
        <SvgIcon>
          <ReportsIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled
    />
  );
   */
  items.push(<Divider key='div0.5' />);
  items.push(
    <MenuItemLink
      key='individuals'
      to={`/individuals`}
      primaryText={isFullWidth && translate(`layout.menu.individuals`)}
      leftIcon={
        <SvgIcon>
          <IndividualsIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('individual.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='businesses'
      to={`/businesses`}
      primaryText={isFullWidth && translate('layout.menu.businesses')}
      leftIcon={<BusinessesIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
      dense={dense}
      disabled={!permissions?.includes('business.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='partners'
      to={`/partners`}
      primaryText={isFullWidth && translate('layout.menu.partners')}
      leftIcon={
        <SvgIcon>
          <PartnersIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('partner.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='externalProfileLinks'
      to={`/externalProfileLinks`}
      primaryText={isFullWidth && translate('layout.menu.externalProfileLinks')}
      leftIcon={<LinkIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('externalProfileLink.list')}
    />
  );
  items.push(<Divider key='div1' />);
  items.push(
    <MenuItem
      key='kycApp'
      onClick={handleKycAppClick}
      dense={dense}
      disabled={!permissions?.includes('kyc.app')}
    >
      <ListItemIcon>
        {kycLoading ? (
          <CircularProgress size={24} />
        ) : (
          <SvgIcon>
            <KYCAppIcon />
          </SvgIcon>
        )}
      </ListItemIcon>
      <div>{isFullWidth && translate('layout.menu.kycApp')}</div>
    </MenuItem>
  );
  items.push(<Divider key='div2' />);
  items.push(
    <MenuItemLink
      key='solutions'
      to={`/solutions`}
      primaryText={isFullWidth && translate('layout.menu.solutions')}
      leftIcon={<Solutions />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('solution.list')}
    />
  );
  items.push(
    <MenuItemLink
      key='pricings'
      to={`/pricings`}
      primaryText={isFullWidth && translate('layout.menu.pricing')}
      leftIcon={
        <SvgIcon>
          <PricingIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('solution.pricing.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/fxrates`}
      key='fxrates'
      primaryText={isFullWidth && translate(`layout.menu.currencyConversion`)}
      leftIcon={
        <SvgIcon>
          <CurrenciesIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      sidebarIsOpen={open}
      dense={dense}
      disabled={!permissions?.includes('fxrates.data.view')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/paymentServices`}
      key='paymentServices'
      primaryText={isFullWidth && translate('layout.menu.paymentServices')}
      leftIcon={<PaymentServicesIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('paymentService.list')}
    />
  );
  items.push(<Divider key='div3' />);
  items.push(
    <MenuItemLink
      to={`/apiIdentities`}
      key='apiIdentities'
      primaryText={isFullWidth && translate('layout.menu.apiIdentities')}
      leftIcon={<ApiIdentitiesIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('apiIdentity.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/team`}
      key='team'
      primaryText={isFullWidth && translate('layout.menu.settings.team')}
      leftIcon={
        <SvgIcon>
          <TeamIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('identity.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/roles`}
      key='roles'
      primaryText={isFullWidth && translate('layout.menu.settings.roles')}
      leftIcon={<RolesIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={
        !(
          permissions?.includes('dashboardRole.create') ||
          permissions?.includes('dashboardRole.update') ||
          permissions?.includes('dashboardRole.delete')
        )
      }
    />
  );
  items.push(
    <MenuItemLink
      to={`/auditLog`}
      key='auditLog'
      primaryText={isFullWidth && translate(`layout.menu.settings.auditLog`)}
      leftIcon={
        <SvgIcon>
          <AuditIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      sidebarIsOpen={open}
      dense={dense}
      disabled={!permissions?.includes('auditLog.list')}
    />
  );
  items.push(
    <MenuItemLink
      to={`/localization`}
      key='localization'
      primaryText={isFullWidth && translate('layout.menu.settings.localization')}
      leftIcon={
        <SvgIcon>
          <LocalizationIcon />
        </SvgIcon>
      }
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
      disabled={!permissions?.includes('localization.list')}
    />
  );

  return <div>{items}</div>;
};

class MenuWithPermissions extends Component {
  render() {
    const { pathname } = this.props as any;
    return (
      <WithPermissions
        render={({ permissions }: any) => {
          return <Menu permissions={permissions} {...this.props} />;
        }}
        // it's only way to make WithPermissions return the actual permissionas. We enforce state update by 'authParams' change
        // https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/auth/WithPermissions.tsx
        authParams={pathname}
      />
    );
  }
}

export default connect((state: any) => ({ pathname: state.router.location.pathname }))(
  MenuWithPermissions
);
