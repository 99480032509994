import React, { FC, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useGetMapApiKey, useViewportCountryCache } from './controller';

const GoogleMap: FC<
  Omit<GoogleMapReact.Props, 'bootstrapURLKeys' | 'defaultCenter' | 'defaultZoom'> & {
    defaultCountry?: string;
  }
> = (props) => {
  const { center, zoom, defaultCountry, ...rest } = props;

  const { data: GOOGLE_MAP_API_KEY } = useGetMapApiKey();
  const [countryCoordsCache, setCounryCoordsCache] = useViewportCountryCache(defaultCountry);
  const shouldRequestdefaultCountryCoords = !countryCoordsCache && !isValidCoords(center);
  const shouldSetViewportToCachedCountry = countryCoordsCache && !isValidCoords(center);

  const [isMapReady, setIsMapReady] = useState(false);

  const handleSetContryCoordsRef = useRef<(country: string) => void>();
  const handleApiLoaded = (map: any, maps: any) => {
    if (!map || !maps) return;
    if (shouldSetViewportToCachedCountry) {
      map.fitBounds(countryCoordsCache);
    }

    const geocoder = new maps.Geocoder();
    const handleSetContryCoords = (country: string) => {
      if (geocoder) {
        geocoder.geocode({ address: `Country: ${country}` }, (res: any) => {
          if (!res) return;
          const [{ geometry }] = res;
          setCounryCoordsCache(geometry.viewport);
          map.fitBounds(geometry.viewport);
        });
      }
    };
    handleSetContryCoordsRef.current = handleSetContryCoords;
    setIsMapReady(true);
  };

  useEffect(() => {
    if (
      shouldRequestdefaultCountryCoords &&
      defaultCountry &&
      isMapReady &&
      handleSetContryCoordsRef.current
    ) {
      handleSetContryCoordsRef.current(defaultCountry);
    }
  }, [isMapReady, defaultCountry, shouldRequestdefaultCountryCoords]);

  if (!GOOGLE_MAP_API_KEY) return null;
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
      defaultCenter={{ lat: 0, lng: 0 }}
      center={center}
      defaultZoom={5}
      zoom={zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      {...rest}
    />
  );
};

export const isValidCoords = (coords: any) => {
  if (!coords) return false;
  return !Object.values(coords).every((item) => item === 0);
};

export default GoogleMap;
