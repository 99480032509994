import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { SettingsIcon } from '~/img';
import { TextField, H3, AmountField, HilightChanges } from '~/components';

import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';

interface DefaultAmountLimitContentProps {
  record?: any;
  prevRecord?: any;
}

export const DefaultAmountLimitContent = (props: DefaultAmountLimitContentProps) => {
  const { record = {}, prevRecord = {} } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('limitConfiguration')}
        </H3>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('limits_.amount')}>
          <HilightChanges curr={record?.amount} prev={prevRecord?.amount}>
            <AmountField amount={record?.amount} />
          </HilightChanges>
        </TextField>
      </Grid>
    </Fragment>
  );
};

interface Props {
  record?: any;
  prevRecord?: any;
}

const DefaultAmountLimitConfirmationField = (props: Props) => {
  const { record, prevRecord } = props;

  return (
    <Section>
      <Grid item container spacing={2}>
        <DefaultAmountLimitContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default DefaultAmountLimitConfirmationField;
