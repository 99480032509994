import React, { FC } from 'react';
import { SelectInput, useTranslate, required } from 'react-admin';
import { useForm } from 'react-final-form';

import { useSourceSystems } from '~/hooks/fxrates';
import { l } from '../CurrenciesList';
import { hasTranslation } from '~/utils';

const RatesSourceSelect: FC = () => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const sourceSystemsQuery = useSourceSystems();
  const sourceSystemsChoices =
    sourceSystemsQuery.data?.map((item) => ({
      id: item,
      name: hasTranslation(l(item)) ? t(item) : item,
    })) || [];

  const form = useForm();

  return (
    <SelectInput
      source='sourceSystem'
      label={t('ratesSourceSystem')}
      choices={[{ id: MANUAL_SOURCE_SYSTEM, name: t(MANUAL_SOURCE_SYSTEM) }].concat(
        sourceSystemsChoices
      )}
      validate={required()}
      defaultValue={MANUAL_SOURCE_SYSTEM}
      onChange={(e: any) => {
        const { id, name, version, created, deleted } = form.getState().values;
        form.change('', {
          id,
          name,
          data: [],
          sourceSystem: e.target?.value,
          currencyPairs: [],
          version,
          created,
          deleted,
        });
      }}
    />
  );
};

export const MANUAL_SOURCE_SYSTEM = 'MANUAL';

export default RatesSourceSelect;
