import React from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { Longdash } from '~/utils';
import CustomChip from '~/components/ChipField';

interface Props {
  [x: string]: any;
  className?: string;
  source?: string;
  i18nPath?: string;
  data?: any;
}

const ChipField = (props: Props) => {
  const { source = '', record, data, i18nPath, className } = props;
  const dataToDisplay = data || lodashGet(record, source);
  const translate = useTranslate();

  return (
    <CustomChip
      className={className}
      label={i18nPath ? translate(`${i18nPath}.${dataToDisplay}`) : dataToDisplay || Longdash}
    />
  );
};

export default ChipField;
