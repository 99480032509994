import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import AccountForm from './AccountForm';
import { l } from './index';

interface AccountCreateProps {
  resource: 'businesses' | 'individuals' | 'partners';
  refetch: () => void;
  solutionId: string;
  profileId: string;
  switchToLimits?: boolean;
}

const AccountCreate: FC<AccountCreateProps> = (props) => {
  const { refetch, solutionId, resource, profileId, switchToLimits } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton variant="add" onClick={handleOpen} />
      <Drawer
        heading={!switchToLimits ? t('addAccount') : t('addLimitPackage')}
        open={open}
        onClose={handleClose}
      >
        <CreateForm resource="accounts" closeParent={handleClose}>
          <Fragment>
            <AccountForm
              solutionId={solutionId}
              resource={resource}
              profileId={profileId}
              switchToLimits={switchToLimits}
            />
          </Fragment>
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default AccountCreate;
