import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

interface Props {
  children: any;
  className?: string;
  noPaddings?: boolean;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

const Section: FC<Props> = (props) => {
  const {
    children,
    className = '',
    noPaddings = false,
    noPaddingLeft = false,
    noPaddingRight = false,
    fullWidth = false,
    style,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={cx(className, classes.root, {
        [classes.paddingLeft]: !noPaddings && !noPaddingLeft,
        [classes.paddingRight]: !noPaddings && !noPaddingRight,
        [classes.fullWidth]: fullWidth,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export const SECTION_ACTIONS_ID = 'section-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    [`& #${SECTION_ACTIONS_ID}`]: {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      [`& #${SECTION_ACTIONS_ID}`]: {
        opacity: 1,
      },
    },
  },
  paddingLeft: {
    paddingLeft: theme.spacing(3),
  },
  paddingRight: {
    paddingRight: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
}));

export default Section;
