import React from 'react';
import { Tab, useTranslate } from 'react-admin';

import { PaperTabbedShowLayout, RecordTitleWithAvatar, Show } from '~/layout';
import { PaymentService } from '~/types/paymentServices';
import { NoPermissions } from '~/components/ra';
import Details from './components/Details';
import References from './components/References';

const l = (key: string): string => `resources.paymentServices/groups.${key}`;

const PaymentGroupShow = (props: any) => {
  const { permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  if (!permissions) return null;
  if (!permissions?.includes('paymentService.view')) return <NoPermissions />;
  return (
    <Show
      {...props}
      title={
        <RecordTitleWithAvatar
          headline={(record: PaymentService) => {
            const recordTitle = t(`show.recordTitle`);
            return `${recordTitle} ${record.name}`;
          }}
          avatarSource="branding.icon"
          colorSource="branding.color"
          resource="paymentServices/groups"
        />
      }
      actions={null}
    >
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.details')}>
          <Details />
        </Tab>
        <Tab path="references" label={t('tabs.references')}>
          <References />
        </Tab>
      </PaperTabbedShowLayout>
    </Show>
  );
};

export default PaymentGroupShow;
