import { ObjectType } from '~/types/ObjectType';
import {
  Business,
  Card,
  Employee,
  ExternalSource,
  Identity,
  Individual,
  Partner,
  Product,
  Solution,
  Terminal,
  Location,
  Account,
} from '~/types';
import { TenantType } from '~/api/utils';
import { ApiIdentity } from '~/types/apiIdentity';
import { HeadTransaction } from '~/types/HeadTransaction';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { Beneficiary, BeneficiaryAccount } from '~/types/beneficiaries';
import { Deal } from '~/types/Deal';
import { Entity } from '~/types/Entity';
import { EventType } from '~/types/Event';

export const getName: Record<ObjectType, (r: any) => string | undefined> = {
  INDIVIDUAL: (i: Individual) => (i.firstName || i.lastName) && `${i.firstName} ${i.lastName}`,
  TENANT: (t: TenantType) => t.name,
  BUSINESS: (b: Business) => b.legalName,
  API_IDENTITY: (i: ApiIdentity) => i.name || i.username || i.phoneNumber || i.emailAddress,
  DASHBOARD_IDENTITY: (i: Identity) => i.name,
  EMPLOYEE: (e: Employee) => e.name || `${e.firstName} ${e.lastName}`,
  TERMINAL: (t: Terminal) => t.label,
  EXTERNAL_SOURCE: (e: ExternalSource) => e.name,
  CARD: (c: Card) => c.maskedPAN || c.lastFour || 'card',
  HEAD_TRANSACTION: (h: HeadTransaction) => h.id,
  DEAL: (d: Deal) => d.id,
  PARTNER: (p: Partner) => p.name,
  EXTERNAL_PROFILE_LINK: (e: ExternalProfileLink) => e.externalSourceSystem,
  ACCOUNT: (a: Account) => a.number,
  LOCATION: (l: Location) => l.name,
  INTEGRATION_IDENTITY: (i: Partner) => i.name,
  BENEFICIARY: (b: Beneficiary) => b.name,
  BENEFICIARY_ACCOUNT: (b: BeneficiaryAccount) => b.name,
  SOLUTION: (s: Solution) => s.name,
  PRODUCT: (p: Product) => p.name,
  EVENT: (e: EventType) => e.topic,
  SYSTEM: () => 'System',
};

export const buildProfileLink = (
  profileType?: ObjectType,
  profileId?: string,
  parentProfileType?: ObjectType,
  parentProfileId?: string,
  partnerId?: string
): string | undefined => {
  switch (profileType) {
    case 'INDIVIDUAL':
      return profileId && `/individuals/${profileId}/show`;
    case 'BUSINESS':
      return profileId && `/businesses/${profileId}/show`;
    case 'PARTNER':
      const id = partnerId || profileId;
      return id && `/partners/${id}/show`;
    case 'TRANSACTION':
    case 'HEAD_TRANSACTION':
      return profileId && `/headTransactions/${profileId}/show`;
    case 'DEAL':
      return profileId && `/deals/${profileId}/show`;
    case 'EXTERNAL_PROFILE_LINK':
      return profileId && `/externalProfileLinks/${profileId}/show`;
    case 'EXTERNAL_SOURCE':
    case 'CARD':
      return parentProfileId && `/individuals/${parentProfileId}/show`;
    case 'LOCATION':
      return parentProfileId && `/businesses/${parentProfileId}/show/terminals`;
    case 'EMPLOYEE':
      return parentProfileId && `/businesses/${parentProfileId}/show/employees`;
    case 'TERMINAL':
      return parentProfileId && `/businesses/${parentProfileId}/show/locations`;
    case 'DASHBOARD_IDENTITY':
      return profileId && `/team/${profileId}/show`;
    case 'API_IDENTITY':
      return profileId && `/apiIdentities/${profileId}/show`;
    case 'SOLUTION':
      return profileId && `/solutions/${profileId}/show`;
    case 'PRODUCT':
      return parentProfileId && `/solutions/${parentProfileId}/show/products`;
    case 'TENANT':
      return undefined;
    case 'INTEGRATION_IDENTITY':
      return partnerId && `/partners/${partnerId}/show`;
    case 'EVENT':
      return profileId && `/events/${profileId}/show`;
    case 'REPORT':
      return profileId && `/actionReports/${profileId}/show`;
    case 'ACCOUNT':
      switch (parentProfileType) {
        case 'INDIVIDUAL':
          return parentProfileId && `/individuals/${parentProfileId}/show`;
        case 'BUSINESS':
          return parentProfileId && `/businesses/${parentProfileId}/show`;
        case 'PARTNER':
          return parentProfileId && `/partners/${parentProfileId}/show`;
        default:
          return undefined;
      }
    case 'BENEFICIARY':
    case 'BENEFICIARY_ACCOUNT':
      switch (parentProfileType) {
        case 'INDIVIDUAL':
          return parentProfileId && `/individuals/${parentProfileId}/show/beneficiaries`;
        case 'BUSINESS':
          return parentProfileId && `/businesses/${parentProfileId}/show/beneficiaries`;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

export const findParent = (
  profileType: ObjectType,
  entity?: Entity
):
  | {
    id: string;
    type: ObjectType;
  }
  | undefined => {
  if (!entity) return undefined;
  switch (profileType) {
    case 'EXTERNAL_SOURCE':
    case 'CARD':
      return { type: 'INDIVIDUAL', id: entity?.profileId };
    case 'LOCATION':
    case 'EMPLOYEE':
    case 'TERMINAL':
      return { type: 'BUSINESS', id: entity?.businessId };
    case 'PRODUCT':
      return { type: 'SOLUTION', id: entity?.solutionId };
    case 'ACCOUNT':
    case 'BENEFICIARY':
    case 'BENEFICIARY_ACCOUNT':
      return { type: entity?.profileType, id: entity?.profileId };
    default:
      return undefined;
  }
};

export const getTypeOfIdentity = (identity?: string): string => {
  switch (identity) {
    case 'INTEGRATION':
      return 'INTEGRATION_IDENTITY';
    case 'API':
      return 'API_IDENTITY';
    case 'DASHBOARD':
      return 'DASHBOARD_IDENTITY';
    default:
      return 'SYSTEM';
  }
};
