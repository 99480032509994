import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import thunkMiddleware from 'redux-thunk';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as ducks from './ducks';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['context', 'router'],
};

interface Args {
  authProvider: any;
  dataProvider: any;
  history: any;
}

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

const disableRaNotificationsMiddleware = () => (next: any) => (action: any) => {
  if (action.type === 'RA/SHOW_NOTIFICATION') {
    switch (action.payload.message) {
      case 'Unauthorized':
        return null;
      case 'ra.notification.item_doesnt_exist':
        return null;
      case 'ra.message.invalid_form':
        return null;
      default:
        break;
    }
  }
  return next(action);
};

export default ({ authProvider, dataProvider, history }: Args) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    ...ducks,
  });
  const persistedReducer = persistReducer(persistConfig, reducer);
  const resettableAppReducer = (state: any, action: any) =>
    persistedReducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      ((window as unknown) as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      ((window as unknown) as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
        thunkMiddleware,
        disableRaNotificationsMiddleware
      )
      // add your own enhancers here
    )
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(saga);

  return { store, persistor };
};
