import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { H2, SelectInput, TableCell } from '~/components';
import { Button, TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import { PaymentGroup } from '~/types/paymentServices';
import {
  useReferencesByGroupQuery,
  useReferenceDelete,
  useReferenceCreate,
  useAllServicesQuery,
} from '~/hooks/paymentServices';
import { ReferenceField } from 'react-admin';
import { useNotify } from '~/hooks';

interface Props {
  loading?: boolean;
  record?: PaymentGroup;
  permissions?: string[];
}

const l = (key: string): string => `resources.paymentServices/groups.${key}`;

const References = (props: Props) => {
  const { loading, record } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`references.${key}`));

  const classes = useStyles();

  const { data: references, isLoading } = useReferencesByGroupQuery(record?.id as string);
  const { data: allServices } = useAllServicesQuery();

  const notify = useNotify();

  const { mutate: mutateDelete } = useReferenceDelete({
    onSuccess: () => {
      notify({ message: t('deleteSuccess'), type: 'success' });
    },
    onError: () => {
      notify({ message: t('deleteError'), type: 'error' });
    },
  });

  const deleteReference = (serviceId: string) => () => {
    mutateDelete({
      serviceId,
      groupId: record?.id as string,
    });
  };

  const { mutate: mutateCreate } = useReferenceCreate({
    onSuccess: () => {
      notify({ message: t('createSuccess'), type: 'success' });
    },
    onError: () => {
      notify({ message: t('createError'), type: 'error' });
    },
  });

  const createReference = (serviceId: string) => {
    mutateCreate({
      serviceId,
      groupId: record?.id as string,
    });
  };

  const [open, setOpen] = useState<boolean>(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const [service, setService] = useState<string>();

  const addReference = () => {
    if (!service) {
      return;
    }
    closeDialog();
    createReference(service);
  };

  return (
    <Section noPaddings>
      <Grid container justify="space-between" className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
            {translate(l(`show.referencesHeading`))}
          </H2>
        </Grid>
        <Grid item container xs={4} id="section-actions" spacing={2} justify="flex-end">
          <Grid item>
            <Button label={t('addReference')} onClick={openDialog} />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        <Table className={classes.bottomSpacing2}>
          <TableBody>
            {!references || references.length === 0 || isLoading ? (
              <TableRow>
                <TableCell>{t('empty')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              references.map((ref) => (
                <TableRow key={`${ref.groupId}-${ref.serviceId}`}>
                  <TableCell>
                    <ReferenceField
                      record={ref}
                      source="serviceId"
                      reference="paymentServices"
                      link={false}
                    >
                      <RaTextField
                        addLabel={false}
                        source="name"
                        showLink={(record: any) => `/paymentServices/${record.id}/show`}
                      />
                    </ReferenceField>
                  </TableCell>
                  <TableCell style={{ width: 150 }}>
                    <Button label={t('deleteReference')} onClick={deleteReference(ref.serviceId)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Section>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
        <DialogTitle>{t('addReferenceDialogTitle')}</DialogTitle>
        <DialogContent>
          <SelectInput
            options={(allServices || [])
              .filter((s) => !references?.find((r) => r.serviceId === s.id))
              .map((r) => ({ value: r.id, text: r.name }))}
            label={t('addReferenceLabel')}
            value={service}
            onChange={(e) => {
              setService(e.target.value);
            }}
            style={{ minWidth: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={closeDialog} />
          <Button
            label={t('confirm')}
            variant="contained"
            onClick={addReference}
            disabled={!service}
          />
        </DialogActions>
      </Dialog>
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default References;
