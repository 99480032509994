import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '~/theme';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';

interface Props {
  children: JSX.Element | string;
  topSpacing?: number;
  bottomSpacing?: number;
  loading?: boolean;
  style?: object;
  className?: string;
}

const H2 = (props: Props) => {
  const { children, topSpacing = 3, bottomSpacing = 3, loading, className } = props;
  const classes = useStyles();

  return (
    <h2
      style={{
        marginTop: theme.spacing(topSpacing),
        marginBottom: theme.spacing(bottomSpacing),
        ...(props.style || {}),
      }}
      className={cx(classes.root, className)}
    >
      {loading ? <Skeleton variant="text" width={200} height={24} /> : children}
    </h2>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    fontSize: 21,
    fontWeight: 500,
    margin: 0,
  },
}));

export default H2;
