import lodashGet from 'lodash/get';
import axios from '~/utils/axios';
import { Pricing, PaymentDetail } from '~/types';
import { isEmpty, isEqual } from 'lodash';
import elasticlunr from 'elasticlunr';
import { time } from '~/utils';

type Params = {
  pagination: {
    page: number;
    perPage: number;
  };
  filter: {
    [x: string]: any;
  };
  target?: string;
  [x: string]: any;
};

const getPaginatedData = <Data = any>(data: Data[], pagination: Params['pagination']) => {
  if (!isEmpty(data) && pagination) {
    const { page, perPage } = pagination;
    return data.slice((page - 1) * perPage, page * perPage);
  } else return [];
};

const applyFilters = (data: any[], filter: Params['filter'], resource: string) => {
  switch (resource) {
    case 'pricings': {
      if (isEmpty(filter)) return data;
      const list: any = {};

      const index = elasticlunr<any>(function () {
        this.addField('name');
      });
      if (!isEmpty(data)) {
        data.forEach((item) => {
          const { id, name } = item as Pricing;
          index.addDoc({ id, name });
          list[id] = item;
        });
      }

      const { name, ...restFilters } = filter;
      const result = index.search(name);

      return (name ? result.map((item) => list[item.ref]) : data).filter((item) => {
        if (isEmpty(restFilters)) return true;
        let isMatch = true;
        for (let key in restFilters) {
          if (!isMatch) break;
          const filterValue = restFilters[key];
          if (key === 'activeFrom') {
            const itemDate = item[key];
            if (!itemDate) {
              isMatch = false;
              break;
            } else {
              const minutesDiff = Math.abs(time(item[key]).unix() - time(filterValue).unix()) / 60;
              isMatch = minutesDiff < 60 * 12;
            }
          } else {
            isMatch = item[key] === filterValue;
          }
        }
        return isMatch;
      });
    }
    case 'paymentDetails': {
      if (isEmpty(filter)) return data;
      const list: any = {};

      const index = elasticlunr<any>(function () {
        this.addField('name');
      });
      if (!isEmpty(data)) {
        data.forEach((item) => {
          const { id, name } = item as PaymentDetail;
          index.addDoc({ id, name });
          list[id] = item;
        });
      }
      const { name, ...restFilters } = filter;
      const result = index.search(name);

      return (name ? result.map((item) => list[item.ref]) : data).filter((item) => {
        if (isEmpty(restFilters)) return true;
        let isMatch = true;
        for (let key in restFilters) {
          if (!isMatch) break;

          let filterValue = null;
          let itemValue = null;
          if (key === 'currency') {
            itemValue = lodashGet(item, `amount.${key}`);
            filterValue = lodashGet(restFilters, key);
          } else if (key === 'value') {
            itemValue = Math.ceil(parseFloat(lodashGet(item, `amount.${key}`)) * 100).toString();
            filterValue = Math.ceil(parseFloat(lodashGet(restFilters, key) || 0) * 100).toString();
          } else {
            itemValue = lodashGet(item, key);
            filterValue = lodashGet(restFilters, key);
          }

          if (isEmpty(filterValue) || filterValue === '0') continue;
          isMatch = isEqual(itemValue, filterValue);
        }
        return isMatch;
      });
    }
    default:
      return data;
  }
};

const requestWithFilters = async (url: string, resource: string, params: Params) => {
  const { filter, pagination } = params;
  const response = await axios.get<any[]>(url);
  const filtered = applyFilters(response.data, filter, resource);
  return {
    data: getPaginatedData(filtered, pagination),
    total: filtered.length,
  };
};

const withLocalFilters = (controller: (resource: string, params: Params) => Promise<any>) => {
  return async (resource: string, params: Params) => {
    switch (resource) {
      case 'pricings': {
        const { target, id } = params;
        if (target && id) {
          return controller(resource, params);
        }
        const url = `${resource}?page=1&perPage=9999`;
        return await requestWithFilters(url, resource, params);
      }
      default:
        return controller(resource, params);
    }
  };
};

export default withLocalFilters;
