import React, { useEffect, useState } from 'react';
import { ReferenceField, useTranslate } from 'react-admin';
import { time } from '~/utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { getTransfers } from '~/api/bankAccounts';
import { Transfer } from '~/types';
import { HeadTransaction } from '~/types/HeadTransaction';
import { AmountField } from '~/components';

export const resource = 'bank/accounts';
export const target = 'bank/accounts';

const makeLabel = (key: string): string => `resources.headTransactions.show.details.${key}`;

const BankAccountAlias = ({ record }: any) => {
  return record.alias;
};

interface Props {
  transaction: HeadTransaction;
  permissions?: string[];
}

const BankTransferList = (props: Props) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(makeLabel(key));

  const [transfers, setTransfers] = useState<Transfer[]>();

  useEffect(() => {
    async function fetchData() {
      const { data } = await getTransfers({
        filters: {
          transactionId: props.transaction.id,
          fromTimestamp: props.transaction.postedAt
            ? time(props.transaction.postedAt).subtract(1, 'second')
            : undefined,
          toTimestamp: props.transaction.postedAt
            ? time(props.transaction.postedAt).add(1, 'second')
            : undefined,
        },
      });
      setTransfers(data.transfers);
    }
    fetchData();
  }, [props.transaction.id, props.transaction.postedAt]);

  if (!transfers) return null;

  const makeProps = (account: any) => ({
    reference: 'bank/accounts',
    basePath: '/bank/accounts',
    source: 'bankAccountId',
    record: account,
    link: false,
    addLabel: false,
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('bankTransfer.amount')}</TableCell>
          <TableCell>{t('bankTransfer.from')}</TableCell>
          <TableCell>{t('bankTransfer.to')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transfers.length > 0 ? (
          transfers.map((transfer) => {
            return (
              <TableRow key={transfer.id}>
                <TableCell>
                  <AmountField amount={transfer.amount} />
                </TableCell>
                <TableCell>
                  <ReferenceField {...makeProps(transfer.from)}>
                    <BankAccountAlias />
                  </ReferenceField>
                </TableCell>
                <TableCell>
                  <ReferenceField {...makeProps(transfer.to)}>
                    <BankAccountAlias />
                  </ReferenceField>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={3} style={{ textAlign: 'center', height: '2.5em' }}>
              {t('noBankTransfers')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BankTransferList;
