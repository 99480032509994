import React, { FC } from 'react';
import { useTenant } from '~/hooks/tenant';

// Modules
import EditDealDetails from './clickSendNow/EditDealDetails';
import DealAdditionalProperties from './clickSendNow/DealAdditionalProperties';
import ExportInvoice from './clickSendNow/ExportInvoice';
import ImportAction from './innBucks/ImportIndividuals';
//

const tc = {
  clickSendNow: 'clicksendnow',
  mtn: 'mtn',
  abela: 'abela',
  innbucks: 'innbucks',
};

type ModuleName =
  | 'EditDealDetails'
  | 'DealAdditionalProperties'
  | 'ExportInvoice'
  | 'IndividualImport';

interface Props {
  name: ModuleName;
  children?: any;
  [x: string]: any;
}

const CustomModule: FC<Props> = (props) => {
  const { name, children = null, ...rest } = props;
  const { data: tenantInfo } = useTenant();
  if (!tenantInfo) return children;

  const isClickSendNow = tenantInfo.code?.includes(tc.clickSendNow);
  const isMTN = tenantInfo.code?.includes(tc.mtn);
  const isAbela = tenantInfo.code?.includes(tc.abela);
  const isInnbucks = tenantInfo.code?.includes(tc.innbucks);

  switch (name) {
    case 'EditDealDetails':
      if (isClickSendNow) {
        return <EditDealDetails {...rest} />;
      } else {
        return children;
      }
    case 'DealAdditionalProperties':
      if (isClickSendNow) {
        return <DealAdditionalProperties {...rest} />;
      } else {
        return children;
      }
    case 'ExportInvoice':
      if (isClickSendNow || isMTN || isAbela || isInnbucks) {
        return <ExportInvoice {...(rest as any)} />;
      } else {
        return children;
      }
    case 'IndividualImport':
      if (isInnbucks) {
        return <ImportAction />;
      } else {
        return children;
      }
    default:
      return children;
  }
};

export default CustomModule;
