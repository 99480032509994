import React from 'react';
import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
  },
  card: {
    boxSizing: 'border-box',
    minWidth: 425,
    padding: '40px 52px',
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  privacyWrapper: {
    paddingBottom: theme.spacing(4),
    fontSize: 11,
  },
}));

const CardLayout = ({ children, privacyComponent }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.cardWrapper}>
        <MuiCard className={classes.card}>{children}</MuiCard>
      </div>
      {privacyComponent && <div className={classes.privacyWrapper}>{privacyComponent}</div>}
    </div>
  );
};

export default CardLayout;
