import React, { Fragment } from 'react';
import { useTranslate, TextInput, required, SelectInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import { H3, StatusField } from '~/components';
import { PhoneNumberInput, HiddenInput, ReferenceInput, ModalOrSelectInput } from '~/components/ra';
import { Business, Location } from '~/types';
import { Longdash } from '~/utils';

interface Props {
  businessRecord: Business;
  record?: any;
  create?: boolean;
}

const EmployeeForm = (props: Props) => {
  const { businessRecord, create } = props;
  const { solutionId, id: businessId } = businessRecord;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);

  return (
    <Fragment>
      {create && (
        <Fragment>
          <HiddenInput source="solutionId" defaultValue={solutionId} />
          <HiddenInput
            source="businessId"
            defaultValue={businessId || '00000000-0000-0000-0000-000000000000'}
          />
        </Fragment>
      )}
      <TextInput source="label" label={t('label')} validate={required()} fullWidth />
      <PhoneNumberInput source="linkedPhone" fullWidth />
      <H3>{t('additionalData')}</H3>
      <TextInput
        label={t('additionalProperties.NFCTerminal')}
        source="additionalProperties.NFCTerminal"
        fullWidth
      />
      {create && (
        <ReferenceInput
          source="locationId"
          reference="locations"
          filter={{ businessId }}
          allowEmpty
          emptyText={Longdash}
          label={t('location')}
        >
          <ModalOrSelectInput<Location> optionText="name" fullWidth>
            {(item) => {
              return (
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <StatusField status={item.status} variant="dot" />
                  </Grid>
                  <Grid item>{item.name}</Grid>
                </Grid>
              );
            }}
          </ModalOrSelectInput>
        </ReferenceInput>
      )}
      <ReferenceInput source="accountId" reference="accounts" filter={{ businessId }}>
        <SelectInput validate={required()} optionText="number" fullWidth />
      </ReferenceInput>
    </Fragment>
  );
};

export default EmployeeForm;
