import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import IdentityForm from './IdentityForm';
import { CreateButton, Drawer } from '~/components';
import { CreateForm } from '~/components/ra';
import { l } from './IdentityShow';

interface Props {
  permissions?: string[];
}

const IdentityCreate = (props: Props) => {
  const { permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton onClick={handleOpen} disabled={!permissions?.includes('identity.create')} />
      <Drawer heading={t('addTeamMember')} open={open} onClose={handleClose}>
        <CreateForm resource="team" closeParent={handleClose}>
          <IdentityForm />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default IdentityCreate;
