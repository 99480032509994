import React from 'react';
import { Button } from 'react-admin';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  red: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  onClick?: () => void;
  warning?: boolean;
  red?: boolean;
  label?: string;
  [x: string]: any;
}

const UnblockButton = (props: Props) => {
  const { onClick, warning, red, label, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      label={label || `components.ra.fields.UnblockButton`}
      className={cx({ [classes.warning]: warning, [classes.red]: red })}
      onClick={onClick}
      {...rest}
    >
      <LockOpenIcon />
    </Button>
  );
};

export default UnblockButton;
