import React, { Fragment, useState } from 'react';
import { useTranslate, usePermissions, required, TextInput } from 'react-admin';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { Account, ExternalSource } from '~/types';
import { Drawer, EditButton } from '.';
import { EditForm } from './ra';

interface Props {
  source: 'accounts' | 'externalSources';
  resource?: 'businesses' | 'individuals' | 'partners' | 'tenant/accounts';
  record: ExternalSource | Account;
  refetch?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Account[] | ExternalSource[]>>;
}

const EditSourceName = (props: Props) => {
  const { source, resource, record, refetch } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch && refetch();
    setOpen(false);
  };

  const translate = useTranslate();
  const label = (key: string): string => translate(`components.EditSourceName.${key}`);

  const { permissions } = usePermissions();
  const hasAccountUpdatePermission =
    resource === 'individuals'
      ? permissions?.includes('individual.account.alias.update')
      : permissions?.includes('business.account.alias.update');
  const hasExternalSourceUpdatePermission = permissions?.includes('externalSource.name.update');
  if (!resource) return null;
  if (hasExternalSourceUpdatePermission && source === 'externalSources') {
    return (
      <>
        <EditButton label={label('editNameAndAlias')} onClick={handleOpen} />
        <Drawer heading={label('editAccountNameAndAlias')} open={open} onClose={handleClose}>
          <EditForm
            resource={source}
            record={record}
            subresource={'names'}
            closeParent={handleClose}
          >
            <Fragment>
              <TextInput validate={required()} source='name' label={label('name')} fullWidth />
              <TextInput source='alias' label={label('alias')} fullWidth />
            </Fragment>
          </EditForm>
        </Drawer>
      </>
    );
  } else if (hasAccountUpdatePermission && source === 'accounts') {
    return (
      <>
        <EditButton label={label('editAlias')} onClick={handleOpen} />
        <Drawer heading={label('editAccountAlias')} open={open} onClose={handleClose}>
          <EditForm resource={source} record={record} subresource='alias' closeParent={handleClose}>
            <TextInput source='alias' label={label('alias')} fullWidth />
          </EditForm>
        </Drawer>
      </>
    );
  } else {
    return null;
  }
};

export default EditSourceName;
