import * as React from 'react';
import { Moment } from '~/types';
import { Props } from './';
import ColoredField from './ColoredField';

import { time } from '~/utils';

const DateTimeField = (props: Props<Moment>) => {
  const { children } = props;
  const data = time(children).format('lll');

  return <ColoredField {...props}>{data}</ColoredField>;
};

export default DateTimeField;
