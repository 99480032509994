import React from 'react';
import { Button, Link, withTranslate } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';

const ReferenceEditButton = ({
  record,
  referenceType,
  parentId,
  parentType,
  parentName,
  translate,
  country,
  ...rest
}) => (
  <Button
    component={Link}
    to={{
      pathname: `/${referenceType}/${record?.id}`,
      state: {
        solutionId: record?.solutionId,
        country,
        parentId,
        parentName,
        parentType,
      },
    }}
    label={translate('ra.action.edit')}
    disabled={!record}
    {...rest}
  >
    <EditIcon />
  </Button>
);

export default withTranslate(ReferenceEditButton);
