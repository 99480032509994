import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import cx from 'classnames';

import { TransactionStatus } from '~/types/TransactionStatus';
import { Status as LocationStatus } from '~/types/locations';
import { Status as TerminalStatus } from '~/types/terminals';
import { DealStatus } from '~/types/Deal';
import { hasTranslation, Longdash } from '~/utils';
import { Card } from '~/types/Card';
import { BeneficiaryStatus } from '~/types/beneficiaries';
import { ActionReport } from '~/types';
import { CSSProperties } from 'styled-components';
import { ExternalProfileLinkStatus } from '~/types/ExternalProfileLink';
import { BatchStatus } from '~/types/Batch';
import { TagsStatus } from '~/types/checks';

export type Status =
  | LocationStatus
  | TransactionStatus
  | TerminalStatus
  | Card['status']
  | DealStatus
  | BeneficiaryStatus
  | ActionReport['status']
  | ExternalProfileLinkStatus
  | BatchStatus
  | 'DELETED'
  | 'ARCHIVED'
  | TagsStatus;

export const statusLabel = (key: string): string => `components.ra.fields.StatusField.${key}`;

interface Props {
  status?: Status | string;
  deleted?: boolean;
  variant?: 'text' | 'dot';
  allowEmpty?: boolean;
  emptyText?: any;
  Icon?: any;
  withColor?: boolean;
  style?: CSSProperties;
}

const StatusField = (props: Props) => {
  const {
    status,
    deleted,
    variant = 'text',
    allowEmpty,
    emptyText = Longdash,
    Icon = FiberManualRecordIcon,
    withColor = true,
    style,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (!status) {
    if (allowEmpty) return <span>{emptyText}</span>;
    return null;
  }

  const statusUppercase: Status = deleted ? 'DELETED' : (status.toUpperCase() as Status);

  return (
    <div
      className={cx(
        classes.root,
        withColor ? { [classes[statusUppercase]]: !!status } : { [classes.noColor]: true }
      )}
      style={style}
    >
      {variant === 'text' &&
        (hasTranslation(statusLabel(`${statusUppercase}`))
          ? translate(statusLabel(`${statusUppercase}`))
          : statusUppercase)}
      {variant === 'dot' && <Icon className={classes.dot} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  noColor: {
    fontWeight: 400,
  },
  dot: {
    fontSize: '12px',
  },
  ACTIVE: {
    color: theme.palette.success.main,
  },
  APPROVED: {
    color: theme.palette.success.main,
  },
  BLOCKED: {
    color: theme.palette.warning.main,
  },
  CANCELLED: {
    color: theme.palette.warning.main,
  },
  CANCELLING: {
    color: theme.palette.warning.main,
  },
  REISSUING: {
    color: theme.palette.warning.main,
  },
  CLOSED: {
    color: theme.palette.error.main,
  },
  COMPLETED: {
    color: theme.palette.success.main,
  },
  CREATED: {
    color: '#CACACA',
  },
  DELETED: {
    color: theme.palette.error.main,
  },
  FAILED: {
    color: theme.palette.error.main,
  },
  LOST: {
    color: theme.palette.error.main,
  },
  STOLEN: {
    color: theme.palette.error.main,
  },
  LOCKED: {
    color: theme.palette.error.main,
  },
  TO_CLOSE: {
    color: theme.palette.error.main,
  },
  EXPIRED: {
    color: theme.palette.error.main,
  },
  RESTRICTED: {
    color: theme.palette.error.main,
  },
  NOT_ACTIVATED: {
    color: theme.palette.warning.main,
  },
  PAID_OUT: {
    color: theme.palette.success.main,
  },
  PENDING: {
    color: '#CACACA',
  },
  PROCESSING: {},
  AUTHORIZED: {},
  REJECTED: {
    color: theme.palette.error.main,
  },
  REVERSED: {
    color: theme.palette.info.main,
  },
  REVOKED: {
    color: '#CACACA',
  },
  SENT: {},
  SENDING: {},
  INITIAL: {},
  SUBMITTED: {},
  ACCEPTED: {
    color: theme.palette.success.main,
  },
  INVALID: {
    color: theme.palette.error.main,
  },
  ARCHIVED: {
    color: theme.palette.info.main,
  },
  REQUESTED: {},
  NOT_READY: {
    color: theme.palette.warning.main,
  },
  CONSENT: {},
  IN_PROGRESS: {},
  COMPLETED_WITH_ERRORS: {
    color: theme.palette.warning.main,
  },
  PASSED: { color: theme.palette.success.main },
}));

export default StatusField;
