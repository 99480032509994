import { get } from 'lodash';
import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';

import { CountryAutocomplete } from '~/components';
import { useHandbook } from '~/hooks';
import { hasTranslation } from '~/utils';

export interface Props {
  source: string;
  /**
   * @deprecated Input recievs it's state by inner useFormState()
   */
  record?: any;
  label?: string;
  solutionId?: string;
  [k: string]: any;
}

interface CountryOption {
  id: string;
  name: string;
}

const CountryInput = ({
  solutionId,
  source,
  disabled,
  fullWidth,
  defaultValue,
  className,
  disableHelperText,
  ...rest
}: Props) => {
  const { data } = useHandbook(solutionId);

  const translate = useTranslate();

  const label = rest.label || source;
  const translatedLabelLabel = hasTranslation(label) ? translate(label) : label;

  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useInput({
    source,
    ...rest,
  });

  const formValues = useFormState().values;
  const value = get(formValues, source) || defaultValue || null;

  if (solutionId !== undefined) {
    if (data) {
      const countries = (data?.countries || [])
        .map((country) => {
          return { id: country.id, name: country.id };
        })
        .sort((a: CountryOption, b: CountryOption) => {
          return a.id.localeCompare(b.id);
        });
      return (
        <CountryAutocomplete
          label={translatedLabelLabel}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          options={countries.map((c) => c.id)}
          required={isRequired}
          error={touched && error}
          helperText={(touched && translate(error)) || ' '}
          style={fullWidth ? {} : { maxWidth: 256 }}
          fullWidth={fullWidth}
          disabled={disabled}
        />
      );
    } else {
      return (
        <CountryAutocomplete
          value={null}
          label={translatedLabelLabel}
          disabled
          options={[]}
          required={isRequired}
          style={fullWidth ? {} : { maxWidth: 256 }}
          fullWidth={fullWidth}
          helperText={' '}
        />
      );
    }
  } else {
    return (
      <CountryAutocomplete
        label={translatedLabelLabel}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        required={isRequired}
        error={touched && error}
        helperText={!disableHelperText && ((touched && translate(error)) || ' ')}
        style={fullWidth ? {} : { maxWidth: 256 }}
        fullWidth={fullWidth}
        disabled={disabled}
        className={className}
      />
    );
  }
};

CountryInput.defaultProps = {
  disabled: false,
  label: 'components.ra.inputs.country',
};

export default CountryInput;
