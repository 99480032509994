import { useQuery } from 'react-query';
import {
  CurrenciesRates,
  checkFXRates,
  getSettlementSystems,
  getSourceSystemRate,
  getSourceSystems,
  getSourceSystemSchema,
  RateBody,
} from '~/api/fxrates';

export const useSourceSystems = () => {
  return useQuery(['fxrates', 'sourceSystems'], getSourceSystems, { staleTime: Infinity });
};

export const useSettlementSystems = () => {
  return useQuery(['fxrates', 'settlementSystems'], getSettlementSystems);
};

export const useSourceSystemSchema = (sourceSystem: string) => {
  return useQuery(['fxrates', 'sourceSystems', sourceSystem], () =>
    getSourceSystemSchema(sourceSystem)
  );
};

export const useFxRatesCheck = (
  currencyRate?: CurrenciesRates,
  options?: { enabled?: boolean }
) => {
  const requests: ({ id: string } & RateBody)[] = (currencyRate?.data ?? [])
    .map((item, index) => {
      const result = [];
      const requestTemplate = {
        fxRatesId: currencyRate?.id,
        baseCurrency: item.baseCurrency,
        quoteCurrency: item.quoteCurrency,
        country: item.country,
      };
      if (item.buyRate) {
        result.push({
          ...requestTemplate,
          id: `${index}.buy`,
          buyNotSell: true,
        });
      }
      if (item.sellRate) {
        result.push({
          ...requestTemplate,
          id: `${index}.sell`,
          buyNotSell: false,
        });
      }
      return result;
    })
    .flat();

  const body: any = requests.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});

  return useQuery(['fxrates', 'check', currencyRate?.id], () => checkFXRates(body), {
    enabled: options?.enabled && !!currencyRate,
    retry: false,
  });
};

export const useSourceSystemRate = (
  sourceSystem: string,
  params: {
    baseCurrency: string;
    quoteCurrency: string;
    country?: string;
    operation: 'sell' | 'buy';
    enabled?: boolean;
  }
) => {
  return useQuery(
    [
      'fxrates',
      'rate',
      sourceSystem,
      params.baseCurrency,
      params.quoteCurrency,
      params.operation,
      params.country,
    ],
    () => getSourceSystemRate(sourceSystem, params),
    { enabled: params.enabled }
  );
};
