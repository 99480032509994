import React from 'react';
import { useTranslate, Tab, useShowController, usePermissions, ReferenceField } from 'react-admin';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import TagsIcon from '@material-ui/icons/LocalOffer';
import StarIcon from '@material-ui/icons/Star';
import { RibbonIcon } from '~/img';
import { Longdash, time } from '~/utils';
import { Show, RecordTitle, PaperTabbedShowLayout, AsideBase, Section } from '~/layout';
import { AccountsActions, H2, Originator, StatusField, TextField } from '~/components';
import { TextField as TextFieldRa, NoPermissions } from '~/components/ra';
import LimitsAndCounters from '~/components/ra/Accounts/LimitsAndCounters';
import BalancesTable from '~/components/ra/Accounts/BalancesTable';
import { useRightContentSpacing } from '~/hooks/styles';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import EditSourceName from '~/components/EditSourceName';
import { useRefresh } from 'react-admin';
import AffiliatedObjects from '~/components/AffiliatedObjects';
import { Account } from '~/types/account';

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  marginLeftAside: {
    marginLeft: '24px',
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  headerSection: {
    '& .sectionActions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& .sectionActions': {
        opacity: 1,
      },
    },
  },
}));

const AccountsShow = (props: any) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const refetch = useRefresh();
  const translateLabel = (key: string, args?: any): string =>
    translate(`resources.accounts.${key}`, args);
  const t = (key: string) => translate(`resources.accounts.show.details.${key}`);

  // Get the record data
  const showController = useShowController(props);
  const showRecord: Account = showController.record;
  const showResource = showController.resource;

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  const { data: productTemplates, isLoading } = useProductTemplates();
  const producTemplateAlias = productTemplates?.find(
    (item) => item.code === showRecord?.productTemplate?.code
  );

  if (!showRecord) return null;
  let resource: 'businesses' | 'individuals' | 'partners' | 'tenant/accounts' | undefined;
  switch (showRecord.profileType) {
    case 'INDIVIDUAL':
      resource = 'individuals';
      break;
    case 'BUSINESS':
      resource = 'businesses';
      break;
    case 'PARTNER':
      resource = 'partners';
      break;
    case 'TENANT':
      resource = 'tenant/accounts';
      break;
    default:
      resource = undefined;
      break;
  }
  if (!permissions) return null;

  const hasAccontsUpdatePermission = permissions?.includes('account.update');
  let hasUpdatePermission = false;
  if (hasAccontsUpdatePermission) {
    hasUpdatePermission = true;
  } else if (resource === 'businesses') {
    hasUpdatePermission = permissions?.includes('business.account.update');
  } else if (resource === 'individuals') {
    hasUpdatePermission = permissions?.includes('individual.account.update');
  } else {
    hasUpdatePermission = false;
  }

  if (!permissions?.includes('account.view')) return <NoPermissions />;
  return (
    <Show
      {...props}
      title={
        <RecordTitle
          headline={(record: any) => {
            const recordTitle = record.number;
            return recordTitle;
          }}
          subtitle={(record: any) => {
            const id = record.id;
            const created = time(record.created);
            return translateLabel('subheader', {
              id: id,
              date: created.format('l'),
              time: created.format('LT'),
            });
          }}
        />
      }
    >
      <PaperTabbedShowLayout>
        <Tab label={translateLabel('tabs.details')}>
          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12} className={classes.headerSection}>
              <Grid container justify='space-between' alignItems='flex-start'>
                <Grid item>
                  <H2 topSpacing={0} bottomSpacing={2}>
                    {t('systemInfo')}
                  </H2>
                </Grid>
                <Grid item className='sectionActions'>
                  {resource !== 'partners' && resource !== 'tenant/accounts' && (
                    <AccountsActions
                      status={showRecord.status}
                      resource={resource}
                      record={showRecord}
                      refetch={refetch as any}
                    />
                  )}
                </Grid>
              </Grid>
              <Section>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      loading={isLoading}
                      label={t('productTemplateName')}
                      className={`${classes.bottomSpacing}`}
                    >
                      {producTemplateAlias?.alias}
                    </TextField>
                    {showRecord.bankAccountId ? (
                      <ReferenceField
                        source='bankAccountId'
                        reference='bank/accounts'
                        link={false}
                        addLabel={false}
                        {...props}
                      >
                        <TextFieldRa
                          source='alias'
                          label={t('bankAccount')}
                          showLink={`/bank/accounts/${showRecord.bankAccountId}/show`}
                        />
                      </ReferenceField>
                    ) : (
                      <TextField label={t('bankAccount')} />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('productTemplateCode')}
                      className={`${classes.bottomSpacing}`}
                    >
                      {showRecord.productTemplate.code}
                    </TextField>
                    <TextField label={t('currency')}>{showRecord.currency}</TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('productTemplateCategory')}
                      className={`${classes.bottomSpacing}`}
                    >
                      {showRecord.productTemplate.category}
                    </TextField>
                    <TextField label={t('currentDay')}>
                      {time(showRecord.currentDay).format('l')}
                    </TextField>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>

          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12} className={classes.headerSection}>
              <Grid container justify='space-between' alignItems='flex-start'>
                <Grid item>
                  <H2 topSpacing={0} bottomSpacing={2}>
                    {t('aliasAndDescription')}
                  </H2>
                </Grid>
                <Grid item className='sectionActions'>
                  <EditSourceName source='accounts' resource={resource} record={showRecord} />
                </Grid>
              </Grid>
              <Section>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField label={t('alias')}>{showRecord.alias}</TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField multiline label={t('description')}>
                      {showRecord.description || Longdash}
                    </TextField>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
          <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
            <Grid item xs={12}>
              <H2 topSpacing={0} bottomSpacing={0}>
                {t('balancesAndCounters')}
              </H2>
              <Section>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item xs={12}>
                    {showRecord.balances && showRecord.balances.length > 0 && (
                      <BalancesTable balances={showRecord.balances} resource={resource} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {showRecord.id && <LimitsAndCounters id={showRecord.id} />}
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
        </Tab>
        <Tab disabled label={translateLabel('tabs.transactions')}></Tab>
        <Tab disabled label={translateLabel('tabs.events')}></Tab>
      </PaperTabbedShowLayout>
      <Grid container item xs direction='column' spacing={spacing}>
        <Grid item className={classes.rightContent}>
          <AsideBase>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label={t('status')} icon={<RibbonIcon />}>
                  <StatusField status={showRecord.status} />
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField
                  source='productId'
                  reference='products'
                  link={false}
                  addLabel={false}
                  {...props}
                >
                  <TextFieldRa source='name' label={t('product')} icon={<StarIcon />} />
                </ReferenceField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('type')} icon={<TagsIcon />}>
                  {showRecord.type}
                </TextField>
              </Grid>
            </Grid>
          </AsideBase>
        </Grid>
        <Originator record={showRecord} header={t('owner')} />
        {(resource === 'individuals' || resource === 'businesses') && (
          <AffiliatedObjects
            id={showRecord.id}
            references={showRecord.references}
            resource={showResource}
            refetch={refetch}
            withAdd={hasUpdatePermission}
            withArchive={hasUpdatePermission}
            withViewAll
          />
        )}
      </Grid>
    </Show>
  );
};

export default AccountsShow;
