import * as React from 'react';

import { useTheme } from '@material-ui/core/styles';

import { AmountField } from '~/components';
import { Amount } from '~/types';

type Props = {
  amount: Amount;
};

const ColoredAmountField = ({ amount }: Props) => {
  const theme = useTheme();

  let style = {};

  if (amount?.value > 0) {
    style = {
      color: theme.palette.success.main,
    };
  }

  return <AmountField amount={amount} style={style} />;
};

export default ColoredAmountField;
