import React, { useState, Fragment } from 'react';
import { useTranslate, Button, useMutation } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { DeleteButton } from '~/components';
import { Business, Individual } from '~/types';

import { useNotify } from '~/hooks';
import { Beneficiary } from '~/types/beneficiaries';

const label = (key: string): string => `resources.businesses.fields.${key}`;

interface Props {
  closeDrawer: () => void;
  record: Business | Individual | Beneficiary;
  index: number;
  resource: 'businesses' | 'individuals';
}

const DeleteDocument = (props: Props) => {
  const { closeDrawer, record, index, resource } = props;

  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const translate = useTranslate();
  const notify = useNotify();

  const [mutate, { loading }] = useMutation();
  const update = (newRecord: any) =>
    mutate(
      {
        type: 'update',
        resource: resource,
        payload: { id: record.id, data: newRecord },
      },
      {
        onSuccess: () => {
          notify({
            message: translate('utils.snackbarNotifications.dataUpdatedSuccess'),
            type: 'success',
          });
          closeDrawer();
        },
        onFailure: (error: any) => notify({ message: error.message, type: 'error' }),
      }
    );

  const handleDelete = () => {
    const result = {
      ...record,
      documents: record.documents?.filter((_item: Document, i: number) => i !== index),
    };
    closeDialog();
    update(result);
  };

  return (
    <Fragment>
      <DeleteButton onClick={openDialog} />
      <Dialog open={open} onClose={closeDialog} aria-labelledby='form-dialog-title'>
        <DialogTitle>{translate(label('deleteDialog._'))}</DialogTitle>
        <DialogActions>
          <Button label='ra.action.cancel' onClick={closeDialog} />
          <DeleteButton onClick={handleDelete} loading={loading} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeleteDocument;
