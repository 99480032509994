import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';

import { VirtualizedList } from '~/components';
import { AuditLogType, RootState } from '~/types';
import { ResourceListType, selectors } from '~/ducks/virtualizedList';
import { Column } from '~/components/VirtualizedList';
import {
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { l } from './IdentityShow';
import { getAuditLog } from '~/api/auditLog';
import CustomCell from '~/resources/auditLog/CustomCell';
import ActionShow from '~/resources/auditLog/ActionShow';

const resource = 'identityAuditLog';

interface Props {
  identityId?: string;
}

const IdentityAuditLog = (props: Props) => {
  const { identityId } = props;
  const { data, hasNextPage, isNextPageLoading } = useSelector<
    RootState,
    ResourceListType<AuditLogType>
  >(selectors.getList(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const dispatch = useDispatch();

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const columns: Column<AuditLogType>[] = [
    {
      selectCellData: (rowData) => rowData.timestamp,
      cellDataWrapperComponent: CustomCell,
      id: 'timestamp',
      header: t('timestamp'),
      style: {
        minWidth: 200,
        maxWidth: 220,
      },
    },
    {
      selectCellData: (rowData) => rowData.message,
      cellDataWrapperComponent: CustomCell,
      id: 'action',
      header: t('action'),
      style: {
        minWidth: 400,
      },
    },
  ];

  const loadNextPage = async () => {
    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getAuditLog({ next: cursors.next, limit: 100, identityId });

      dispatch(getVirtualizedListSuccess({ resource, responseData: data, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const [showData, setShowData] = useState<AuditLogType | null>(null);
  const handleShowOpen = (data: AuditLogType) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  return (
    <Fragment>
      <ActionShow showData={showData} onClose={handleShowClose} />
      <VirtualizedList<AuditLogType>
        resource={resource}
        columns={columns}
        data={data}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        loadNextPage={loadNextPage}
        onRowClick={handleShowOpen}
      />
    </Fragment>
  );
};

export default IdentityAuditLog;
