import React, { Fragment } from 'react';
import { withTranslate, FormDataConsumer } from 'react-admin';
import { Field } from 'react-final-form';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';

import get from 'lodash/get';
import unset from 'lodash/unset';

const useStyles = makeStyles({
  collapseToggle: {
    position: 'relative',
    top: 3,
    left: 3,
    marginRight: 2,
  },
  collapseToggleInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    cursor: 'inherit',
  },
});

const CollapseToggle = (props) => {
  const { checked, onChange } = props;
  const classes = useStyles();
  return (
    <span onClick={onChange} className={classes.collapseToggle}>
      <input className={classes.collapseToggleInput} onChange={onChange} tabIndex="-1" />
      {checked ? <ExpandMore /> : <ChevronRight />}
    </span>
  );
};

class NullInput extends React.Component {
  render() {
    const {
      input: { value, onChange },
      formData,
      source,
    } = this.props;
    // eslint-disable-next-line eqeqeq
    if (value != '') {
      // dirty hack to unset nullable property
      setTimeout(() => {
        onChange('');
        unset(formData, source);
      }, 100);
    }
    return <span />;
  }
}

export default (buildInputs) => {
  class ObjectInput extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: true,
        provided: undefined,
      };

      this.setCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };

      this.setProvided = (subformVisible) => {
        return () => {
          this.setState({
            provided: !subformVisible,
          });
        };
      };
    }
    render() {
      const { context, objectSchema, defaultData, source, required, translate } = this.props;
      const { collapsed, provided } = this.state;

      return (
        <Fragment>
          <FormDataConsumer>
            {({ formData }) => {
              const obj = get(formData, source) || get(defaultData, source);
              const subformVisible =
                required || (!(provided === false) && (provided === true || obj)) || false;
              return (
                <Fragment>
                  <FormControlLabel
                    checked={!collapsed}
                    control={<CollapseToggle onChange={this.setCollapsed} />}
                    label={translate(`jsonSchema.${context}.${source}._`)}
                  />
                  <Collapse in={!collapsed}>
                    {!required && (
                      <div style={{ marginLeft: 14 }}>
                        <span>
                          Enabled:{' '}
                          <Switch
                            checked={subformVisible}
                            onChange={this.setProvided(subformVisible)}
                          />
                        </span>
                      </div>
                    )}
                    {subformVisible ? (
                      <div style={{ marginLeft: 16 }}>
                        {buildInputs({
                          context,
                          objectSchema,
                          defaultData,
                          sourcePrefix: source,
                        }).map((input) => {
                          return <div className="ra-input">{input}</div>;
                        })}
                      </div>
                    ) : (
                      <Field
                        name={source}
                        component={NullInput}
                        formData={formData}
                        source={source}
                      />
                    )}
                  </Collapse>
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </Fragment>
      );
    }
  }
  return withTranslate(ObjectInput);
};
