import React, { Fragment, useState } from 'react';
import { NumberInput } from 'react-admin';
import { required } from 'react-admin';
import { useTranslate } from 'react-admin';

import { Drawer } from '~/components';
import { AutocompleteArrayTextInput } from '~/components/ra';
import { EditForm } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { Pricing } from '~/types';
import cyrb53 from '~/utils/cyrb53';

import { ByFeature } from '.';
import ForkButton from './ForkButton';

export interface PricingDataForkProps {
  schema: ByFeature;
  pricingRecord: Pricing;
  record: any;
  setMainState: (newState: any) => void;
  currency: string;
}

const PricingDataFork = (props: PricingDataForkProps) => {
  const { pricingRecord, record, schema, setMainState } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setTags([]);
    setOpen(true);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  const [tags, setTags] = useState<string[]>([]);
  const propHash = cyrb53(tags.sort().join('.'));
  const propKey = `${schema.propertyKey}-${propHash}`;

  const handleSubmit = (data: any, submitQuery?: (a: any) => any) => {
    const newData = { ...record };

    console.log(`propKey=${propKey}`);
    if (!propKey.startsWith('serviceCharges.')) {
      newData[propKey] = { ...data[propKey], tags };
    } else {
      newData['serviceCharges'] = newData['serviceCharges'] ? { ...newData['serviceCharges'] } : {};
      newData['serviceCharges'][propKey.substring('serviceCharges.'.length)] = {
        ...data[propKey],
        tags,
      };
      console.log(`newData:`, newData);
    }

    setMainState(newData);
    if (submitQuery) {
      submitQuery({
        ...pricingRecord,
        data: newData,
      });
      handleClose();
    }
  };

  return (
    <Fragment>
      <ForkButton label={t('fork')} onClick={handleOpen} style={editButtonStyles} />
      <Drawer heading={t('forkHeading')} open={open} onClose={handleClose}>
        <EditForm<any>
          record={record}
          resource="pricings"
          id={pricingRecord.id}
          onSubmit={handleSubmit}
        >
          <AutocompleteArrayTextInput
            label={t('tags')}
            initialValue={tags}
            source={`${propKey}.tags`}
            validate={[required()]}
            onChange={(e: any, value: string[], reason: string) => {
              console.log(reason, value);
              switch (reason) {
                case 'create-option':
                  setTags(value);
                  break;
                case 'remove-option':
                  setTags(value);
                  break;
                case 'clear':
                  setTags([]);
                  break;
                default:
                  break;
              }
            }}
          />
          <NumberInput
            source={`${propKey}.index`}
            label={t('index')}
            validate={[required()]}
            style={{ width: '100%' }}
          />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

const editButtonStyles = { marginTop: -6 };

export default PricingDataFork;
