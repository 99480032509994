import InternalSection from './Section';
import InternalHeading from './Heading';

type InternalSectionType = typeof InternalSection;

interface SectionType extends InternalSectionType {
  Heading: typeof InternalHeading;
}

const Section = InternalSection as SectionType;

Section.Heading = InternalHeading;

export default Section;
