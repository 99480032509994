export default {
  name: 'Individual |||| Individuals',
  subtitle: 'To search for Individual type in some values into filter inputs below',
  create: {
    createNewProfile: 'Create new individual profile',
    enterThePrimaryDetails: 'Enter the primary details',
    '1row': 'Select solution and country',
    '2row': 'Input primary identification information',
    alreadyRegistered: 'Phone number is already in use.',
    youCantCreateProfile:
      'This phone number is already used by another profile. You cannot create new profile with a phone number that is already in use.',
  },
  fields: {
    profileInfo: 'Profile information',
    viewProfileHistory: 'View profile history',
    viewHistory: 'View history',
    additionalProperties: {
      _: 'Additional properties',
      badSchema: 'Additional properties schema is invalid. Check feature configuration!',
      edit: 'Edit additional properties',
      empty: 'No additional properties defined',
    },
    editProfileInfo: 'Edit profile information',
    editContacts: 'Edit contacts',
    contacts: 'Contacts',
    accountNumber: 'Account Number',
    additionalName: 'Additional name',
    editAddress: 'Edit address',
    address: {
      _: 'Address',
      addressLine: 'Address line',
      city: 'City',
      country: 'Country',
      postalCode: 'Postal code',
      region: 'Region',
    },
    birthDate: 'Birth date',
    country: 'Country',
    documents: {
      _: 'Documents',
      add: 'Add document',
      edit: 'Edit document',
      files: 'Files',
      type: 'Type',
      number: 'Number',
      dateOfIssue: 'Date of issue',
      dateOfExpiry: 'Date of expiry',
      dateOfBirth: 'Date of birth',
    },
    documentNumber: 'Document number',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    name: 'Name',
    phoneNumber: 'Phone number',
    createdAtFrom: 'Created after',
    createdAtTo: 'Created before',
    affiliationType: 'Affiliation type',
    affiliatedObjectId: 'Affiliated object UUID',
    status: 'Status',
    solutionId: 'Solution',
    kycLevel: 'KYC level',
    kycStatus: 'KYC status',
    ACTIVE: 'Active',
    PENDING: 'Pending',
    withDeleted: 'With deleted',
    any: 'Any',
    active: 'Active',
    pending: 'Pending',
    blocked: 'Blocked',
    closed: 'Closed',
    INVITED_BY: 'Invited by',
    CLONED_FROM: 'Copied from',
    createdAt: 'Created at',
    primaryDocument: 'Primary document',
    icon: 'Individual picture / photo / avatar',
    gender: {
      _: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female',
      OTHER: 'Other',
    },
    addNames: 'Add names',
    noContacts: 'No contacts added',
    noAddress: 'There is no provided address',
    noDocuments: 'No documents added',
    beneficiaries: 'Beneficiaries',
    primaryAccountNumber: 'Primary account number',
    tags: 'Tags',
    selectKYCtoCheck: 'Select KYC Level to check',
    checkKYC: 'Check KYC',
    kycResetProgress: 'Reset KYC',
    kycResetProgressWarning:
      'Are you sure you want to reset all KYC progress? All KYC-related data would be cleared from profile.',
    kycResetInitiated: 'KYC reset initiated',
    nationality: 'Nationality',
    placeOfBirth: 'Place of birth',
    importCSV: 'Import CSV',
  },
  updatePhoneNumber: {
    button: 'Update phone',
    title: 'Update phone number',
    warning:
      "Phone number is a primary identifier of individual's profile. Change it with extra care!",
    newPhoneNumber: 'New phone number',
    applyChanges: 'Update',
    requiredError: 'This value is required',
    formatError: 'Phone number format is invalid',
    note: 'Note',
    success: 'Phone number has been changed successfully!',
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
    check: 'Continue',
    duplicateError:
      'Profile with given phone number is already exists in current solution. Unable to change phone number. It should be unique across single solution.',
    existingProfile: 'Existing profile with given phone number:',
    profilesToLink: 'Individual would get access to following profiles:',
    profilesToUnlink: 'Access to following profiles would be lost for individual:',
  },
  tabs: {
    summary: 'Summary',
    profile: 'Profile',
    accounts: 'Accounts',
    log: 'Action log',
    transactions: 'Transactions',
    transactionHistory: 'Transactions',
    beneficiaries: 'Beneficiaries',
    note: 'Note',
    apiIdentity: 'Identity',
    feedback: 'Feedback',
    systemLog: 'System log',
    checks: 'Checks',
    eventLog: 'Events',
  },
};
