/* eslint-disable no-template-curly-in-string */
export const HOST_NAME = process.env.REACT_APP_API_HOST;

export const LYC_API_URL = HOST_NAME
  ? (HOST_NAME.startsWith('1') ? `http` : `https`) + `://${HOST_NAME}`
  : '';

export const API_URL = HOST_NAME
  ? (HOST_NAME.startsWith('1') ? `http` : `https`) + `://${HOST_NAME}/dashboard/api`
  : '/dashboard/api';

export const TENANT_CODE = process.env.REACT_APP_TENANT_CODE; // CORE-30 No need to set TENANT_CODE explicitly for production
