import React, { Fragment, useState } from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  SvgIcon,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';

import { useNotify } from '~/hooks';
import { EditButton, TextField, TextInput } from '~/components';
import { Individual } from '~/types';
import { PhoneIcon } from '~/img';
import validatePhoneNumber from '~/utils/validatePhoneNumber';
import { checkPhoneNumberUpdate, updatePhoneNumber } from '~/api/individual';
import { updateEventLog } from '~/ducks/eventLog';
import { buildProfileLink } from '~/utils';

const label = (key: string): string => `resources.individuals.updatePhoneNumber.${key}`;

interface Props {
  record: Individual | undefined;
  permissions: string[];
}

const Warning = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.individuals.updatePhoneNumber.${key}`);
  return <Alert severity="warning">{t('warning')}</Alert>;
};

const DuplicateError = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.individuals.updatePhoneNumber.${key}`);
  return <Alert severity="error">{t('duplicateError')}</Alert>;
};

const UpdatePhoneNumber = (props: Props) => {
  const { record } = props;

  const notify = useNotify();
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const dispatch = useDispatch();
  const forceRefresh = useRefresh();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPhoneNumber(undefined);
    setNote(undefined);
    setCheckData(undefined);
  };

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [note, setNote] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState<any>();

  const validateNumber = () => {
    const isEmpty: boolean = phoneNumber !== undefined && !phoneNumber;
    const isInvalidFormat: boolean = !isEmpty && !!validatePhoneNumber(phoneNumber!)?.message;

    return {
      error: isEmpty || isInvalidFormat,
      errorMessage: isEmpty ? t('requiredError') : isInvalidFormat ? t('formatError') : undefined,
    };
  };

  const validateNote = () => {
    const isEmpty: boolean = note !== undefined && !note.trim();

    return {
      error: isEmpty,
      errorMessage: isEmpty ? t('requiredError') : undefined,
    };
  };

  const phoneNumberState = validateNumber();
  const noteState = validateNote();

  const classes = useStyles();

  const handlePhoneNumberInput = ({ target }: any) => {
    setPhoneNumber(target.value);
  };

  const handleNoteInput = ({ target }: any) => {
    setNote(target.value);
  };

  const handleCheck = async () => {
    try {
      setLoading(true);
      const response = await checkPhoneNumberUpdate(record!.id, phoneNumber!);
      setCheckData(response.data);
    } catch (error) {
      notify({ message: error.body?.message || error.message, type: 'error' });
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updatePhoneNumber(record!.id, phoneNumber!, note!);
      forceRefresh();
      dispatch(updateEventLog());
      setOpen(false);
      notify({ message: t('success'), type: 'success' });

      setPhoneNumber(undefined);
      setNote(undefined);
    } catch (error) {
      notify({ message: error.body?.message || error.message, type: 'error' });
    }
    setLoading(false);
  };

  const existingProfile: any =
    checkData &&
    checkData.profilesToLink &&
    checkData.profilesToLink.find((p: any) => p.solutionId === record?.solutionId);

  const profilesToLink = checkData && checkData.profilesToLink;
  const profilesToUnlink = checkData && checkData.profilesToUnlink;

  return (
    <Fragment>
      <EditButton
        label={t('button')}
        icon={
          <SvgIcon>
            <PhoneIcon />
          </SvgIcon>
        }
        onClick={handleOpen}
      />
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle className={classes.DialogTitle} aria-label="simple-dialog-title">
          {t('title')}
        </DialogTitle>
        <DialogContent style={{ maxWidth: 450 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {existingProfile ? <DuplicateError /> : <Warning />}
            </Grid>
            <Grid item xs={12}>
              {checkData ? (
                <>
                  <TextInput label={t('newPhoneNumber')} value={phoneNumber} disabled required />
                  {existingProfile ? (
                    <>
                      <Typography style={{ marginBottom: '1em' }}>
                        {t('existingProfile')}
                      </Typography>
                      <TextField
                        label={t(existingProfile.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS')}
                        showLink={buildProfileLink(
                          '',
                          existingProfile.id,
                          existingProfile.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS'
                        )}
                      >
                        {existingProfile.phoneNumber
                          ? `${existingProfile.firstName} ${existingProfile.lastName}`
                          : existingProfile.legalName}
                      </TextField>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {profilesToLink?.length > 0 && (
                          <Grid xs={profilesToUnlink?.length > 0 ? 6 : 12} item>
                            <Typography style={{ marginBottom: '0.5em' }}>
                              {t('profilesToLink')}
                            </Typography>
                            {profilesToLink.map((p: any) => {
                              return (
                                <TextField
                                  key={p.id}
                                  label={t(p.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS')}
                                  showLink={buildProfileLink(
                                    '',
                                    p.id,
                                    p.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS'
                                  )}
                                >
                                  {p.phoneNumber ? `${p.firstName} ${p.lastName}` : p.legalName}
                                </TextField>
                              );
                            })}
                          </Grid>
                        )}
                        {profilesToUnlink?.length > 0 && (
                          <Grid xs={profilesToLink?.length > 0 ? 6 : 12} item>
                            <Typography style={{ marginBottom: '0.5em' }}>
                              {t('profilesToUnlink')}
                            </Typography>
                            {profilesToUnlink.map((p: any) => {
                              return (
                                <TextField
                                  key={p.id}
                                  label={t(p.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS')}
                                  showLink={buildProfileLink(
                                    '',
                                    p.id,
                                    p.phoneNumber ? 'INDIVIDUAL' : 'BUSINESS'
                                  )}
                                >
                                  {p.phoneNumber ? `${p.firstName} ${p.lastName}` : p.legalName}
                                </TextField>
                              );
                            })}
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <TextInput
                            label={t('note')}
                            onChange={handleNoteInput}
                            value={note}
                            multiline
                            error={noteState.error}
                            helperText={noteState.errorMessage}
                            required
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <TextInput
                  label={t('newPhoneNumber')}
                  onChange={handlePhoneNumberInput}
                  value={phoneNumber}
                  error={phoneNumberState.error}
                  helperText={phoneNumberState.errorMessage}
                  required
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('ra.action.cancel')}
          </Button>
          {checkData ? (
            <Button
              className={classes.applyButton}
              disabled={noteState.error || !note}
              onClick={handleSubmit}
              color="primary"
            >
              {loading ? <CircularProgress size={24} thickness={2} /> : t('applyChanges')}
            </Button>
          ) : (
            <Button
              className={classes.applyButton}
              disabled={phoneNumberState.error || !phoneNumber}
              onClick={handleCheck}
              color="primary"
            >
              {loading ? <CircularProgress size={24} thickness={2} /> : t('check')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: '100%',
    justifyContent: 'center',
  },
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  DialogTitle: {
    '& > h2': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  applyButton: {
    minWidth: 100,
  },
}));

export default UpdatePhoneNumber;
