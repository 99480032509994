import React, { Fragment } from 'react';
import { TextInput, SelectInput, required, FormDataConsumer, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';

import { Alert } from '@material-ui/lab';

import { l } from './PartnerCreate';
import { HiddenInput, ReferenceInput } from '~/components/ra';

interface Props {
  partnerDetailsEdit?: boolean;
}

const ParnterForm = (props: Props) => {
  const { partnerDetailsEdit } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const form = useForm();

  const resetField = (source: string) => () => {
    form.change(source, undefined);
  };

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        const { solutionId } = formData;
        return (
          <Fragment>
            {partnerDetailsEdit && (
              <Alert severity="warning" style={{ marginBottom: '1em' }}>
                {t('editWarn')}
              </Alert>
            )}
            <TextInput source="name" label={t('name')} validate={required()} />
            <TextInput source="description" label={t('description')} multiline rows={4} />
            <SelectInput
              source="transfersDirection"
              label={t('transfersDirection')}
              choices={[
                { id: 'INCOMING', name: t('INCOMING') },
                { id: 'OUTGOING', name: t('OUTGOING') },
              ]}
              validate={required()}
            />
            <TextInput source="externalId" label={t('externalId')} validate={required()} />
            {partnerDetailsEdit ? (
              <HiddenInput source="solutionId" />
            ) : (
              <ReferenceInput
                source="solutionId"
                label={t('solutionId')}
                reference="solutions"
                onChange={resetField('productId')}
              >
                <SelectInput optionText="name" label={t('name')} validate={required()} />
              </ReferenceInput>
            )}
            {solutionId ? (
              <ReferenceInput
                source="productId"
                label={t('productId')}
                reference="products"
                filter={{ solutionId }}
                disabled={partnerDetailsEdit}
              >
                <SelectInput optionText="name" validate={required()} />
              </ReferenceInput>
            ) : (
              <TextInput source="productId" label={t('productId')} validate={required()} disabled />
            )}
            <TextInput
              source="accountNumber"
              label={t('accountNumber')}
              disabled={partnerDetailsEdit}
            />
            <TextInput source="accountAlias" label={t('accountAlias')} />
            {partnerDetailsEdit && (
              <TextInput source="reason" label={t('reason')} multiline rows={4} />
            )}
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default ParnterForm;
