export default {
  name: 'Name',
  paymentDetails: 'Payment details',
  amount: 'Amount',
  terminal: 'Terminal',
  comment: 'Comment for customer',
  qrCode: 'QR Code',
  qrCodes: 'QR Codes',
  inactiveTerminalAlert: 'Linked to inactive Terminal. Please activate the terminal first',
  downloadQr: 'Download QR',
  editPaymentDetail: 'Edit QR',
  addPaymentDetail: 'Add QR',
  noQr: "There are no added QR's here",
  fields: {
    accountId: 'Account',
    amount: {
      value: 'Amount',
      currency: 'Currency',
    },
    currency: 'Currency',
    name: 'Name',
    terminalId: 'Terminal',
  },
  reference: {
    create: 'Create details',
    show: 'Go to details',
  },
  DocumentCard: {
    name: 'Name',
    amount: 'Amount',
    terminal: 'Terminal',
  },
};
