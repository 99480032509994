import React, { FC } from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';

import { useTenant } from '~/hooks/tenant';

interface Props {
  date: moment.MomentInput;
  format?: string;
  timeZone?: string;
  className?: string;
}

const DateTimeField: FC<Props> = (props) => {
  const { date, format = 'LL LT', timeZone, className } = props;
  const { data: tenant } = useTenant();
  return (
    <span className={cx(className)}>
      {moment(date)
        .tz(tenant?.data.timeZone || timeZone || '')
        .format(format)}
    </span>
  );
};

export default DateTimeField;
