import React from 'react';
import { SelectInput } from 'react-admin';
import { useTranslate } from 'react-admin';
import { getCurrenciesRates, CurrenciesRates } from '~/api/fxrates';
import { useApi } from '~/hooks';

const l = (key: string): string => `resources.paymentServices.${key}`;

const FXRatesSelect = () => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));
  const [{ data, loading }] = useApi<CurrenciesRates[]>(getCurrenciesRates);
  const choices = data?.map((item) => ({ id: item.id, name: item.name }));
  if (!data) return null;
  if (loading) return null;
  return <SelectInput source='directFxRatesId' choices={choices} allowEmpty label={t('fxRates')} />;
};

export default FXRatesSelect;
