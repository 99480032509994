import React, { useState } from 'react';
import cx from 'classnames';
import Chip from '@material-ui/core/Chip';
import RaChipField from '~/theme/overrides/RaChipField';
import { makeStyles } from '@material-ui/core/styles';
import { Longdash } from '~/utils';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  chip: {
    ...RaChipField.chip,
  },
  loading: {
    margin: RaChipField.chip.margin,
    borderRadius: RaChipField.chip.borderRadius,
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  label?: string;
  className?: string;
  loading?: boolean;
  primary?: boolean;
  style?: React.CSSProperties;
}

const ChipField = (props: Props) => {
  const { label, className, loading, primary, style } = props;
  const [width] = useState(50 + Math.random() * 130);
  const classes = useStyles();

  if (loading) {
    return <Skeleton variant='rect' height={24} width={width} className={classes.loading} />;
  }
  return (
    <Chip
      style={style}
      className={cx(classes.chip, className, { [classes.primary]: primary })}
      label={label || Longdash}
    />
  );
};

export default ChipField;
