import { useQuery } from 'react-query';
import { getTenant, TenantType } from '~/api/utils';
import { getPnlAccounts } from '~/api/pnlAccounts';
import moment from 'moment-timezone';
import { getSolutionProducts } from '~/api/product';
import { getCurrencies } from '~/api/currencies';

export const tenantQueryKey = 'tenant';

interface UseTenantProps {
  enabled?: boolean;
  onSuccess?: (res: TenantType) => void;
  onError?: (err?: any) => void;
}

export const useTenant = (props?: UseTenantProps) => {
  const { enabled, onSuccess, onError } = props || {};
  return useQuery(tenantQueryKey, getTenant, {
    staleTime: Infinity,
    enabled,
    onSuccess(res) {
      try {
        moment.tz.setDefault(res.data.timeZone);
        moment.locale(res.data.locale);
      } catch (error) {
        console.log(error);
      }
      onSuccess?.(res);
    },
    onError(err) {
      onError?.(err);
    },
  });
};

export const useTenantProducts = () => {
  const { data: tenant } = useTenant();
  return useQuery(
    'tenantProducts',
    () => {
      return getSolutionProducts(tenant!.id)().then((response) => {
        return response.data;
      });
    },
    {
      enabled: !!tenant,
    }
  );
};

export const useTenantAccounts = () => {
  return useQuery(
    'tenantAccounts',
    () => {
      return getPnlAccounts().then((response) => {
        return response.data;
      });
    },
    {}
  );
};

export const useTenantCurrencies = () => {
  return useQuery(
    'tenantCurrencies',
    () => {
      return getCurrencies().then((response) => {
        return response.data;
      });
    },
    {}
  );
};
