import React, { Children, cloneElement } from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';

import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useCheckMinimumRequiredProps, useCreateController } from 'ra-core';

import CreateTitle from './CreateTitle';
import { isEmpty } from 'lodash';

const CustomToolbar = (props) => {
  const formState = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton disabled={!isEmpty(formState.errors)} />
    </Toolbar>
  );
};

const Create = (props) => <CreateView {...props} {...useCreateController(props)} />;

Create.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  title: PropTypes.node,
  record: PropTypes.object,
  hasList: PropTypes.bool,
  successMessage: PropTypes.string,
  undoable: PropTypes.bool,
};

export const CreateView = (props) => {
  const {
    actions,
    aside,
    basePath,
    children,
    classes: classesOverride,
    className,
    component: Content,
    hasList,
    record = {},
    redirect,
    resource,
    save,
    saving,
    title = <CreateTitle />,
    version,
    ...rest
  } = props;
  useCheckMinimumRequiredProps('Create', ['children'], props);
  const classes = useStyles({ classes: classesOverride });
  return (
    <div
      className={classnames('create-page', classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <div style={{ display: 'flex' }}>
        {title &&
          cloneElement(title, {
            resource,
            ...title.props,
          })}
        {actions &&
          cloneElement(actions, {
            basePath,
            resource,
            hasList,
            //  Ensure we don't override any user provided props
            ...actions.props,
          })}
      </div>
      <div
        className={classnames(classes.main, {
          [classes.noActions]: !actions,
        })}
      >
        <Content className={classes.card}>
          {cloneElement(Children.only(children), {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === 'undefined' ? redirect : children.props.redirect,
            resource,
            save,
            saving,
            version,
            toolbar: <CustomToolbar />,
          })}
        </Content>
        {aside &&
          cloneElement(aside, {
            basePath,
            record,
            resource,
            save,
            saving,
            version,
          })}
      </div>
    </div>
  );
};

CreateView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
};

CreateView.defaultProps = {
  classes: {},
  component: Card,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: 'flex',
    },
    noActions: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '1em',
      },
    },
    card: {
      flex: '1 1 auto',
    },
  }),
  { name: 'RaCreate' }
);

/* eslint-disable */
const sanitizeRestProps = ({
  actions,
  children,
  className,
  crudCreate,
  loading,
  loaded,
  saving,
  resource,
  title,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  successMessage,
  translate,
  undoable,
  ...rest
}) => rest;
/* eslint-enable */

export default Create;
