import React, { FC } from 'react';
import { SelectInput } from '~/components';
import { useLoadHandbooksBySolutions } from '~/hooks/useHandbook';
import { useAllSolutions } from '~/hooks/useSolution';
import palette from '../theme/palette';
import { Solution } from '~/types';
import { KycLevel } from '~/types/Handbook';
import { SelectInputProps } from '~/components/SelectInput';

export interface FilterProps {
  withFeature?: string;
}

const KycLevelSelect: FC<Omit<SelectInputProps<any>, 'options'> & FilterProps> = (props) => {
  const { value, onChange, disabled, withFeature, label, ...rest } = props;

  const { data: allSolutions, isLoading: isLoadingSolutions } = useAllSolutions();

  const solutions = withFeature
    ? allSolutions?.data.filter((solution: Solution) => solution.features.includes(withFeature))
    : allSolutions?.data;

  const solutionMap = new Map(solutions?.map((solution) => [solution.id, solution.name]));

  const { data: handbooks, loading: isLoadingHandbooks } = useLoadHandbooksBySolutions(
    solutions || []
  );

  const options =
    handbooks
      ?.filter((handbook) => !!handbook.kycLevels)
      ?.map((handbook) => {
        const { kycLevels, solutionId } = handbook;
        return kycLevels?.map((kycLevel: KycLevel) => {
          const solutionName = solutionMap.get(solutionId);
          return { value: kycLevel.id, text: formatOptionText(kycLevel.name, solutionName) };
        });
      })
      .flat() ?? [];

  return (
    <SelectInput<string | undefined>
      label={label}
      value={value}
      onChange={onChange}
      options={options}
      disabled={isLoadingHandbooks || isLoadingSolutions || disabled}
      {...rest}
    />
  );
};

const formatOptionText = (kycLevel: string, solutionName?: string) => {
  return (
    <span>
      {kycLevel}
      {solutionName && (
        <span style={{ color: palette.text.secondary, opacity: 0.5 }}>{` (${solutionName})`}</span>
      )}
    </span>
  );
};
export default KycLevelSelect;
