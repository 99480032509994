export default {
  name: 'Accounts',
  fields: {
    number: 'Number',
    type: 'Type',
    alias: 'Alias',
    currency: 'Currency',
    owner: 'Owner',
    createdAt: 'Created at',
    status: 'Status',
    withDeleted: 'With deleted',
  },
  tabs: {
    details: 'Details',
    transactions: 'Transactions',
    events: 'Events',
  },
  subheader: 'Id:  %{id}. Created:  %{date}  %{time}',
  show: {
    details: {
      status: 'Status',
      product: 'Product',
      type: 'Type',
      balancesAndCounters: 'Balances and counters',
      systemInfo: 'System information',
      aliasAndDescription: 'Alias and description',
      productTemplateName: 'Product template name',
      productTemplateCode: 'Product template code',
      productTemplateCategory: 'Product template category',
      bankAccount: 'Bank account',
      currency: 'Currency',
      currentDay: 'Current day',
      alias: 'Alias',
      description: 'Description',
      owner: 'Owner',
    },
  },
};
