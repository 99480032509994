import React from 'react';
import { ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import LanguageIcon from '@material-ui/icons/Language';
import { TextField, KycLevelField } from '~/components/ra';
import {
  CountryField,
  TextField as TextFieldComponent,
  StatusField,
  PhoneField,
} from '~/components';
import { ShieldCheckmarkIcon, RibbonIcon } from '~/img';
import { Individual } from '~/types';
import { AsideBase } from '~/layout';
import palette from '~/theme/palette';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  }
}));

const label = (key: string): string => `resources.individuals.fields.${key}`;

interface Props {
  [x: string]: any;
  record?: Individual;
  changelogMode?: boolean;
}

const Aside = (props: Props) => {
  const classes = useStyles();
  const { changelogMode } = props;
  const { country, status, kycStatus, phoneNumber, deleted } = props.record || {};

  return (
    <AsideBase className={changelogMode ? classes.changelogMode : undefined}>
      <Grid container spacing={2} className={classes.layout}>
        <Grid item xs={12}>
          <TextField label={label('status')} icon={<RibbonIcon />} {...props}>
            <StatusField status={status} deleted={deleted} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <ReferenceField
            source="solutionId"
            reference="solutions"
            link={false}
            addLabel={false}
            {...props}
          >
            <TextField source="name" label={label('solutionId')} icon={<StarIcon />} />
          </ReferenceField>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent label={label('country')} icon={<LanguageIcon />}>
            <CountryField>{country}</CountryField>
          </TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent label={label('phoneNumber')} icon={<SmartphoneIcon />}>
            <PhoneField number={phoneNumber} />
          </TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TextField label={label('kycLevel')} icon={<ShieldCheckmarkIcon />} {...props}>
            <KycLevelField source="kycLevel" type="text" addLabel={false} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={label('kycStatus')} icon={<FindInPageIcon />} {...props}>
            <StatusField status={kycStatus || 'ACTIVE'} withColor={false} />
          </TextField>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

export default Aside;
