export default {
  name: 'System log',
  actionLog: 'Action log',
  subtitle: 'Search for logs using filters below',
  timestamp: 'Timestamp',
  profile: 'Profile',
  action: 'Action',
  actor: 'Actor',
  channel: 'Channel',
  profileId: 'Profile ID',
  timeframe: 'Timeframe',
  fromTimestamp: 'Timestamp from',
  toTimestamp: 'Timestamp to',
  empty: 'Please use one of the filters above to retrieve logs.',
  message: 'Message',
  auditInfo: 'Audit information',
  ip: 'IP address',
  hash: 'Hash',
  solutionId: 'Solution ID',
  solution: 'Solution',
  tags: 'Tags',
  reference: 'Reference',
  lastEntries: 'Last entries (for the last month)',
  viewAllEntries: 'View all entries',
  identityName: 'Actor',
  identityId: 'Actor (UUID)',
  logLevel: 'Log level',
};
