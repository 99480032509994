import { createActionReportDraft, revokeActionReport } from './../api/actionReports';
import { ActionReport } from '~/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getActionReportSchema,
  getActionReports,
  submitActionReport,
  GetActionReportsParams,
  getAllReportSystemsAndTypes,
} from '~/api/actionReports';
import { useDispatch } from 'react-redux';
import { crudGetOne } from 'react-admin';
import onError from '~/errorsHandler';

export const useActionReports = ({ objectId, objectType }: GetActionReportsParams) => {
  return useQuery(['actionReports', objectType, objectId], () =>
    getActionReports({ objectId, objectType }).then((res) => res.data)
  );
};

export const useActionReportSchema = () => {
  return useQuery('actionReportSchema', getActionReportSchema, { staleTime: Infinity });
};

export const useActionReportSchemaFields = (
  system: ActionReport['system'],
  reportType: ActionReport['reportType']
) => {
  const { data, ...rest } = useActionReportSchema();
  const resultData =
    data && system && reportType && data[system] && data[system][reportType]
      ? data[system][reportType].fields
      : undefined;
  return { data: resultData, ...rest };
};

export const useCreateActionReportDraft = () => {
  return useMutation(createActionReportDraft);
};

export const useSubmitActionReport = () => {
  const queryClient = useQueryClient();
  return useMutation(submitActionReport, {
    onSuccess({ objectId, objectType }) {
      queryClient.invalidateQueries(['actionReports', objectType, objectId]);
    },
  });
};

export const useAllReportSystemsAndTypes = () => {
  return useQuery(['getAllReportSystemsAndTypes'], getAllReportSystemsAndTypes, {
    staleTime: Infinity,
  });
};

export const useRevokeActionReport = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(revokeActionReport, {
    onSuccess({ id, objectId, objectType }) {
      queryClient.invalidateQueries(['actionReports', objectType, objectId]);
      dispatch(crudGetOne('actionReports', id, '/actionReports'));
    },
    onError(error, variables) {
      const { report } = variables;
      dispatch(crudGetOne('actionReports', report.id, '/actionReports'));
      onError(error);
    },
  });
};
