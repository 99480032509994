import React from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import { hasTranslation } from '~/utils';

const TranslateField = (props: any) => {
  const { record, source, i18nPath } = props;
  const data = lodashGet(record, source) || '';
  const translate = useTranslate();
  const translatedData = hasTranslation(`${i18nPath}.${data}`)
    ? translate(`${i18nPath}.${data}`)
    : data;
  return <div>{translatedData}</div>;
};

export default TranslateField;
