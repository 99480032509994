import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { H3 } from '~/components';
import Skeleton from '@material-ui/lab/Skeleton';
import { CalculatorIcon } from '~/img';
import { useGetAccountLimits } from '~/hooks/accounts';
import { groupBy } from 'lodash';
import { VelocityControlGridItems } from '~/components/ProfileLimits';
import MetricSingleValueField from '~/components/MetricField/MetricSingleValueField';
import { amountToString } from '~/utils/amountFormat';

const label = (key: string): string => `components.ra.AccountList.${key}`;

interface Props {
  id: string;
}

const LimitsAndCounters: FC<Props> = (props) => {
  const { id } = props;
  const { data, isLoading } = useGetAccountLimits(id);
  const dataByType = groupBy(data, 'type');

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  return (
    <>
      <H3 icon={<CalculatorIcon />} noPaddingLeft bottomSpacing={2} loading={isLoading}>
        {t('countersAndLimits._')}
      </H3>
      <Grid container spacing={6}>
        <Grid item container xs={6} spacing={2} direction="column">
          {isLoading ? (
            <Grid item>
              <Skeleton variant="rect" width="100%" height={222} />
            </Grid>
          ) : (
            <>
              {dataByType.VELOCITY_CONTROL?.map((item) => {
                return (
                  <VelocityControlGridItems
                    id={item.id}
                    key={item.id}
                    name={item.name}
                    velocityControl={item.velocityControl}
                    inline
                  />
                );
              })}
            </>
          )}
        </Grid>
        <Grid item container xs={6} spacing={2} direction="column">
          {isLoading ? (
            <Grid item xs>
              <Skeleton variant="rect" width="100%" height={222} />
            </Grid>
          ) : (
            <>
              {dataByType.BALANCE?.map((item) => {
                return (
                  <Grid item key={item.id}>
                    <MetricSingleValueField
                      name={item.name}
                      value={amountToString(item.maxBalance!)}
                    />
                  </Grid>
                );
              })}
              {dataByType.SINGLE_AMOUNT?.map((item) => {
                return (
                  <Grid item key={item.id}>
                    <MetricSingleValueField
                      name={item.name}
                      value={amountToString(item.singleAmount!)}
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LimitsAndCounters;
