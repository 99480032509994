import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column, Partner, Transaction } from '~/types';
import { getTransactions } from '~/api/transactionsHistory';
import { RowMenu } from '~/components/TransactionsHistory/cellComponents';
import TransactionView from '~/components/TransactionsHistory/TransactionView';
import CustomCell from './CustomCell';
import Filters from './Filters';
import { isEmpty } from 'lodash';

export const resource = 'tenant/accounts';
export const target = 'tenant';

interface Props {
  record?: Partner;
  permissions?: string[];
}

const TransactionsHistory = (props: Props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string): string =>
    translate(`resources.tenant/accounts.transactionsHistory.${key}`);
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading || !record || isEmpty(filters)) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getTransactions({
        target,
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions as any[],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column[] = [
    {
      selectCellData: (rowData) => rowData.postingDate,
      cellDataWrapperComponent: CustomCell,
      id: 'bankingDay',
      header: t('bankingDay'),
      width: 95,
      style: {
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.totalAmount,
      cellDataWrapperComponent: CustomCell,
      id: 'postedAmount',
      header: t('totalAmount'),
      cellAlign: 'right',
      headerAlign: 'right',
      style: {
        minWidth: 140,
        flexBasis: 140,
        maxWidth: 300,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.operationType,
      cellDataWrapperComponent: CustomCell,
      id: 'type',
      header: t('type'),
      style: {
        minWidth: 95,
        flexBasis: 150,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) =>
        record?.id === rowData.sender?.accountId
          ? rowData.recipient?.accountNumber
          : rowData.sender?.accountNumber,
      cellDataWrapperComponent: CustomCell,
      header: t('cpAccount'),
      id: 'cpAccount',
      style: {
        minWidth: 110,
        flexBasis: 110,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.createdAt,
      cellDataWrapperComponent: CustomCell,
      id: 'dateAndTime',
      header: t('dateAndTime'),
      //width: 160,
      style: {
        minWidth: 160,
        flexBasis: 160,
        // maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: CustomCell,
      id: 'status',
      header: t('status'),
      //width: 120,
      style: {
        minWidth: 100,
        flexBasis: 100,
        // maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData,
      cellDataWrapperComponent: RowMenu,
      header: '',
      //width: 50,
      cellAlign: 'right',
      style: {
        minWidth: 50,
        flexBasis: 50,
        paddingLeft: 10,
      },
    },
  ];

  const [showData, setShowData] = useState<Transaction | null>(null);
  const handleShowOpen = (data: Transaction) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  const { hasNextPage, isNextPageLoading, data } = list;
  const classes = useStyles();
  if (!record) return null;
  return (
    <Fragment>
      <Filters record={record} target={target} resource={resource} />
      <VirtualizedList<Transaction>
        resource={resource}
        columns={columns}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        data={data}
        filters={filters}
        loadNextPage={loadNextPage}
        onRowClick={handleShowOpen}
        className={classes.root}
      />
      <TransactionView
        record={record}
        target={target}
        transaction={showData}
        onClose={handleShowClose}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 1060,
  },
}));

export default TransactionsHistory;
