import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1) / 2,
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
  buttons: {
    display: 'flex',
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
  counter: {
    ...theme.typography.body2,
    fontSize: '13px',
    paddingLeft: theme.spacing(1),
  },
}));

export interface TablePaginationProps {
  page: number;
  perPage?: number;
  total: number;
  setPage: (page: number) => void;
}

const TablePagination = ({ page, perPage = 1, total, setPage }: TablePaginationProps) => {
  const nbPages = Math.ceil(total / perPage) || 1;
  const classes = useStyles();

  return nbPages > 1 ? (
    <div className={classes.root}>
      <div className={classes.counter}>{`${page}/${nbPages}`}</div>
      <div className={classes.buttons}>
        <Button
          color="primary"
          size="small"
          key="prev"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <ChevronLeft />
          Prev
        </Button>
        <Button
          color="primary"
          size="small"
          key="next"
          onClick={() => setPage(page + 1)}
          disabled={page === nbPages}
        >
          Next
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : null;
};

export default TablePagination;
