import React, { FC, Fragment, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import styled from 'styled-components';
import { Grid, Checkbox } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { H2, TableCell, SaveButton, EditButton, CreateButton } from '~/components';
import { l } from '../CurrenciesList';
import { CurrencyPairsType, updateCurrenciesPairs } from '~/api/currencies';
import { isEmpty, isEqual } from 'lodash';
import { Longdash } from '~/utils';
import { UseApiReturn } from '~/hooks/useApi';
import { FlagIcon } from '~/img';
import onError from '~/errorsHandler';

type OpenMode = 'add' | 'edit' | false;

interface CurrencyPairsProps {
  useApiReturn: UseApiReturn<CurrencyPairsType[]>;
  className?: string;
  permissions?: string[];
}

const CurrencyPairs: FC<CurrencyPairsProps> = (props) => {
  const { className, permissions, useApiReturn } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  // const [{ data: pairs, loading }, refetch] = useApi<CurrencyPairsType[]>(getCurrenciesPairs);
  const [{ data: pairs, loading }, refetch] = useApiReturn;

  const [open, setOpen] = useState<OpenMode>(false);
  const handleAdd = () => setOpen('add');
  const handleEdit = () => setOpen('edit');
  const handleClose = () => setOpen(false);

  return (
    <Grid container direction="column" spacing={1} className={className}>
      <Grid container item justify="space-between">
        <Grid item xs>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('currencyPairs')}
          </H2>
        </Grid>
        <Grid container item xs spacing={1} justify="flex-end">
          {!open ? (
            <Fragment>
              {permissions?.includes('currency.pair.create') && (
                <Grid item>
                  <CreateButton variant="add" onClick={handleAdd} />
                </Grid>
              )}
              {permissions?.includes('currency.pair.update') && (
                <Grid item>
                  <EditButton red onClick={handleEdit} />
                </Grid>
              )}
            </Fragment>
          ) : (
            <Grid item>
              <Button label={'ra.action.cancel'} onClick={handleClose} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        {!open ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('pair')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(pairs)
                ? pairs?.map((item) => {
                    const { baseCurrency, quoteCurrency, enabled } = item;
                    const pairString = `${baseCurrency}/${quoteCurrency}`;
                    if (!enabled) return null;
                    return (
                      <TableRow key={pairString}>
                        <TableCell>
                          {pairString} {item.isMaster && <FlagIcon />}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : !loading && (
                    <TableRow>
                      <TableCell>{Longdash}</TableCell>
                    </TableRow>
                  )}
              {loading &&
                [{}, {}, {}].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell loading={loading} />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          pairs && (
            <EditCurrencyPairs pairs={pairs} refetch={refetch} onClose={handleClose} open={open} />
          )
        )}
      </Grid>
    </Grid>
  );
};

interface EditCurrencyPairsProps {
  pairs: CurrencyPairsType[];
  refetch: () => void;
  onClose: () => void;
  open: OpenMode;
}

const EditCurrencyPairs: FC<EditCurrencyPairsProps> = (props) => {
  const { pairs, refetch, onClose, open } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [state, setState] = useState<CurrencyPairsType[]>(pairs);
  const handleChange = (index: number, checked: boolean) => () => {
    setState((prev) => {
      const prevCopy = [...prev];
      prevCopy[index] = { ...prevCopy[index], enabled: !checked };
      return prevCopy;
    });
  };

  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    setSaving(true);
    try {
      await updateCurrenciesPairs(state.filter((item) => !item.isMaster));
      refetch();
      onClose();
    } catch (error) {
      onError(error);
    }
    setSaving(false);
  };

  return (
    <Grid container item spacing={2} direction="column">
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="CheckboxCell" />
              <TableCell>{t('pair')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.map((item, index) => {
              const { baseCurrency, quoteCurrency, enabled, isMaster } = item;
              const pairString = `${baseCurrency}/${quoteCurrency}`;
              return (
                <TableRow key={pairString}>
                  <TableCell className="CheckboxCell">
                    <Checkbox
                      checked={enabled}
                      onChange={handleChange(index, enabled)}
                      className="Checkbox"
                      disabled={isMaster || (open === 'add' && pairs[index].enabled)}
                    />
                  </TableCell>
                  <TableCell>
                    {pairString} {item.isMaster && <FlagIcon />}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item>
        <SaveButton
          onClick={handleSave}
          disabled={isEqual(state, pairs) || saving}
          loading={saving}
        />
      </Grid>
    </Grid>
  );
};

export default styled(CurrencyPairs)`
  margin-top: 16px;
  max-width: 400px;
  .CheckboxCell {
    padding-right: 0;
    width: 20px;
  }
  .Checkbox {
    margin: -16px;
    margin-left: -11px;
  }
`;
