import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import TableForm from './TableForm';
import { l } from '../CurrenciesList';
import { MANUAL_SOURCE_SYSTEM } from './RatesSourceSelect';

interface TableCreateProps {
  refetch: () => void;
}

const TableCreate: FC<TableCreateProps> = (props) => {
  const { refetch } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton label={t('addTable')} onClick={handleOpen} />
      <Drawer heading={t('configureFXRateTable')} open={open} onClose={handleClose}>
        <CreateForm
          resource="fxrates"
          closeParent={handleClose}
          parseRecordBeforeSubmit={(fxRate) => {
            if (fxRate.sourceSystem === MANUAL_SOURCE_SYSTEM) {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                sourceSystem,
                ...rest
              } = fxRate;
              return rest;
            } else {
              return fxRate;
            }
          }}
        >
          <TableForm />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default TableCreate;
