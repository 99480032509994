export default {
  number: 'Account number',
  created: 'Open on',
  status: 'Status',
  accounts: 'Accounts',
  alias: 'Alias',
  productId: 'Product',
  addAccount: 'Add account',
  addLimitPackage: 'Add limit package',
  description: 'Description',
  blockAccount: 'Block',
  unblockAccount: 'Unblock',
  balances: {
    _: 'Balances',
    accountSummary: 'Account Summary',
    balance: 'Balance',
    amount: 'Amount',
    code: {
      individuals_CURRENT_AVAILABLE: 'Available',
      individuals_CURRENT_UNAVAILABLE: 'Unavailable',
      individuals_CURRENT_POSTED: 'Posted',
      CURRENT_AVAILABLE: 'Available for payouts',
      CURRENT_UNAVAILABLE: 'Unavailable',
      CURRENT_POSTED: 'Posted amount',
      CURRENT_PENDING: 'Pending (received but not available yet)',
      PROCESSED_IN_REALTIME: 'Processed in Real-Time',
      CURRENT_FLOAT: 'Float',
    },
    total: 'Total amount',
  },
  productTemplate: {
    code: {
      USD_CONSUMER_CURRENT_ACCOUNT: 'eMoney Account',
      USD_MERCHANT_CURRENT_ACCOUNT: 'Business eMoney Account',
    },
  },
  noAccounts: 'No accounts found',
  countersAndLimits: {
    _: 'Counters and limits',
    counter: 'Counter',
    currentValue: 'Current value',
    limitValue: 'Limit value',
    purchasesToday: 'Purchases today',
    purchasesThisMonth: 'Purchases this month',
    refundsToday: 'Refunds today',
    refundsThisMonth: 'Refunds this month',
    businesses: {
      VELOCITY_CONTROL: 'Purchase acceptance per day',
    },
    individuals: {
      VELOCITY_CONTROL: 'Spending per month',
    },
  },
  linkedAccounts: 'Linked accounts',
  shared: 'SHARED',
  owner: 'Owner',
};
