import accounts from './accounts';
import businesses from './businesses';
import headTransactions from './headTransactions';
import individuals from './individuals';
import paymentDetails from './paymentDetails';
import welcome from './welcome';
import solutions from './solutions';
import auditLog from './auditLog';
import pricings from './pricings';
import partners from './partners';
import pnlAccount from './pnlAccount';
import deals from './deals';
import bankAccount from './bankAccount';
import currencies from './currencies';
import beneficiaries from './beneficiaries';
import dealBeneficiaries from './dealBeneficiaries';
import actionReports from './actionReports';
import apiIdentities from './apiIdentities';
import events from './events';
import batches from './batches';
import feedback from './feedback';
import localization from './localization';
import paymentServices from './paymentServices';
import paymentServicesGroups from './paymentServices/groups';
import roles from './roles';

import configuratiosPricing from './configurations/pricing';
import settings from './configurations/settings';
import externalProfileLinks from './externalProfileLinks';

export default {
  agents: {
    name: 'Agent |||| Agents',
  },
  welcome,
  solutions,
  businesses,
  auditLog,
  pricings,
  partners,
  'configurations/settings': settings,
  'configurations/pricing': configuratiosPricing,
  employees: {
    name: 'Employee |||| Employees',
    reference: {
      create: 'Create employee',
      show: 'Go to employee',
    },
  },
  headTransactions,
  individuals,
  accounts,
  kycLevels: {
    name: 'KYC level |||| KYC levels',
    fields: {
      name: 'Name',
      priority: 'Priority',
    },
    reference: {
      create: 'Create KYC level',
      edit: 'Edit level',
      show: 'Go to level',
    },
  },
  locations: {
    name: 'Location |||| Locations',
    reference: {
      create: 'Create location',
      show: 'Go to location',
    },
  },
  paymentDetails,
  terminals: {
    name: 'Terminal |||| Terminals',
    reference: {
      create: 'Create terminal',
      show: 'Go to terminal',
    },
  },
  team: {
    name: 'Team member |||| Team members',
    subtitle: 'Manage your team members and their access grants',
    tabs: {
      actionLog: 'Action log',
      sessionHistory: 'Session history',
    },
    fields: {
      username: 'Login',
      name: 'Full name',
      email: 'Email',
      phoneNumber: 'Phone number',
      roleId: 'Role',
      lastLogin: 'Last login',
      status: 'Status',
      // Show
      personalData: 'Personal data',
      profile: 'Profile',
      actionLog: 'Action log',
      sessionHistory: 'Session history',
      // Edit
      editTeamMember: 'Edit team member',
      // Create
      addTeamMember: 'Add team member',
      // Delete
      deleteTeamMemberWarning:
        'By pressing "Confirm" the user will be completely deleted from the system. The user will not be able to log in to the system and perform any actions. Please confirm.',
      // Audit log
      action: 'Action',
      ipAddress: 'IP address',
      timestamp: 'Timestamp',
      twoFactorAuthWarning:
        'Two-factor authentication would be enabled for this team member when and only when phone number is provided.',
    },
  },
  'tenant/accounts': {
    name: "Tenant's Accounts",
    subtitle: "Tenant's Accounts monitoring and configuration",
    ...pnlAccount,
  },
  'bank/accounts': {
    name: 'Bank accounts',
    subtitle:
      'Shadow bank accounts are used for alternative accounting of e-money transactions mapped to real bank accounts of Tenant.',
    ...bankAccount,
  },
  fxrates: {
    name: 'Currency Conversion',
    subtitle: 'Last update',
    tagsHelperText: "To add tag input it's name and press Enter",
    BEST_RATES_OF: 'Best rates of',
    TWO_STEP_CROSSRATE: 'Two-step cross rate',
    ...currencies,
  },
  beneficiaries,
  dealBeneficiaries,
  deals,
  actionReports,
  apiIdentities,
  feedback,
  events,
  batches,
  paymentServices,
  'paymentServices/groups': paymentServicesGroups,
  localization,
  externalProfileLinks,
  roles,
};
