import React from 'react';

import ReactJson, { ReactJsonViewProps } from 'react-json-view';

import { Longdash } from '~/utils';

interface Props {
  json?: any;
  reactJsonOptions?: Omit<ReactJsonViewProps, 'src'>;
}

const JsonField = ({ json, reactJsonOptions }: Props) => {
  if (!json) return <span>{Longdash}</span>;

  return <ReactJson {...reactJsonOptions} src={json || {}} />;
};

export default JsonField;
