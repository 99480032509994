import React from 'react';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { Longdash } from '~/utils';
import CopyButton from './CopyButton';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  number?: string;
}

const PhoneField = (props: Props) => {
  const { number } = props;
  const classes = useStyles();
  if (!number) return <span>{Longdash}</span>;
  if (number.indexOf('*') >= 0) {
    return <span>{number}</span>;
  } else {
    return (
      <div className={classes.root}>
        <span>
          {isValidPhoneNumber(number) ? parsePhoneNumber(number)?.formatInternational() : number}
        </span>
        <CopyButton value={number} classes={classes} />
      </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 30,
    color: theme.palette.grey[600],
  },
  listItemText: {
    marginBottom: 0,
  },
  button: {
    padding: 0,
    color: theme.palette.grey[500],
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: '1rem',
  },
}));

export default PhoneField;
