export default {
  transfer: 'Transfer',
  transferHeadline: 'Run transfer',
  transferSubtitle: 'Create new transaction of requested operation type',
  approve: 'Approve',
  inputDetails: 'Input transfer parameters',
  details: {
    // noModeSelected: 'Select transfer mode '
  },
  selectOperationType: 'Select operation type',
  operationType: 'Operation type',
  amount: 'Amount',
  creditAccount: 'Credit account',
  debitAccount: 'Debit account',
  creditAccountNumber: 'Credit account number',
  debitAccountNumber: 'Debit account number',
  checkConsent: 'Check transfer details',
  account: 'Account',
  sender: 'Debit',
  recipient: 'Credit',
  senderAmounts: 'Debit amounts',
  recipientAmounts: 'Credit amounts',
  entry: {
    category: 'Category',
    transactionAmount: 'Transfer amount',
    totalAmount: 'Total amount',
    amount: 'Amount',
  },
  from: 'From',
  to: 'To',
  transferDone: 'Transfer done!',
  comment: 'Comment',
  reference: 'Reference ID',
  exchangeRate: 'FX rate',
  instructedExchangeRate: 'FX rate for instructed amount',
};
