import React, { Fragment } from 'react';
import { FormDataConsumer } from 'react-admin';
import { AmountInput } from '~/components/ra';

export const billingDateFormat = 'MMMM DD';

interface Props {
  getSource: (key: string) => string;
  currency: string;
  defaultValue?: number;
}

const DefaultAmountLimitForm = (props: Props) => {
  const { getSource, currency, defaultValue } = props;

  return (
    <FormDataConsumer>
      {() => {
        return (
          <Fragment>
            <AmountInput
              source={getSource('amount')}
              permanentSelectedCurrency={currency}
              required
              fullWidth
              initialValue={defaultValue}
            />
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default DefaultAmountLimitForm;
