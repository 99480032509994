export default {
  name: 'Roles',
  tabTitle: 'Role',
  data: 'Data',
  fields: {
    id: 'ID',
    name: 'Name',
    readonly: 'Readonly / Predefined',
    addRole: 'Add role',
    editRole: 'Edit role',
    deleteRoleWarning:
      'By pressing "Confirm" the role will be completely deleted from the system. Any use with this role will not be able to log in to the system and perform any actions. Please confirm.',
  },
  permissions: {
    _: 'Permissions',
    name: 'Permission',
    emptyMessage: 'There are no permissions',
    'account.block': 'Account: block',
    'account.close': 'Account: close',
    'account.list': 'Account: list',
    'account.unblock': 'Account: unblock',
    'account.update': 'Account: update',
    'account.view': 'Account: view',
    'superuser.action': 'Superuser permission',
    'individual.list': 'Individual: list',
    'individual.search.extended': 'Individual: extended search',
    'individual.view': 'Individual: view',
    'individual.create': 'Individual: create',
    'individual.update': 'Individual: update',
    'individual.delete': 'Individual: delete',
    'individual.block': 'Individual: block',
    'individual.unblock': 'Individual: unblock',
    'individual.account.alias.update': 'Individual → Account → Alias: update',
    'individual.changelog.list': 'Individual → Changelog: list',
    'individual.changelog.view': 'Individual → Changelog: view',
    'individual.account.view': 'Individual → Account: view',
    'individual.account.list': 'Indiviudal → Account: list',
    'individual.account.create': 'Individual → Account: create',
    'individual.account.update': 'Individual → Account: update',
    'individual.account.block': 'Individual → Account: block',
    'individual.account.unblock': 'Individual → Account: unblock',
    'individual.account.close': 'Individual → Account: close',
    'individual.cards.list': 'Individual → Cards: list',
    'individual.transaction.list': 'Individual → Transaction: list',
    'individual.transaction.view': 'Individual → Transaction: view',
    'individual.export': 'Individual: export',
    'individual.import': 'Individual: import',
    'individual.event.list': 'Individual → Event: list',
    'business.list': 'Business: list',
    'business.view': 'Business: view',
    'business.create': 'Business: create',
    'business.update': 'Business: update',
    'business.delete': 'Business: delete',
    'business.block': 'Business: block',
    'business.unblock': 'Business: unblock',
    'business.changelog.list': 'Business → Changelog: list',
    'business.changelog.view': 'Business → Changelog: view',
    'business.account.view': 'Business → Account: view',
    'business.account.list': 'Business → Account: list',
    'business.account.create': 'Business → Account: create',
    'business.account.update': 'Business → Account: update',
    'business.account.block': 'Business → Account: block',
    'business.account.unblock': 'Business → Account: unblock',
    'business.account.close': 'Business → Account: close',
    'business.account.alias.update': 'Business → Account → Alias: update',
    'business.location.list': 'Business → Location: list',
    'business.location.view': 'Business → Location: view',
    'business.location.create': 'Business → Location: create',
    'business.location.update': 'Business → Location: update',
    'business.location.delete': 'Business → Location: delete',
    'business.terminal.list': 'Business → Terminal: list',
    'business.terminal.view': 'Business → Terminal: view',
    'business.terminal.create': 'Business → Terminal: create',
    'business.terminal.update': 'Business → Terminal: update',
    'business.terminal.delete': 'Business → Terminal: delete',
    'business.terminal.issueActivationCode': 'Business → Terminal: issue activation code',
    'business.terminal.getActivationCode': 'Business → Terminal: get activation code',
    'business.terminal.deactivate': 'Business → Terminal: deactivate',
    'business.employee.list': 'Business → Employee: list',
    'business.employee.view': 'Business → Employee: view',
    'business.employee.create': 'Business → Employee: create',
    'business.employee.update': 'Business → Employee: update',
    'business.employee.delete': 'Business → Employee: delete',
    'business.employee.createAccessCode': 'Business → Employee: create access code',
    'business.paymentDetails.list': 'Business → Payment details: list',
    'business.paymentDetails.view': 'Business → Payment details: view',
    'business.paymentDetails.create': 'Business → Payment details: create',
    'business.paymentDetails.update': 'Business → Payment details: update',
    'business.paymentDetails.delete': 'Business → Payment details: delete',
    'business.paymentDetails.generateQRCode': 'Business → Payment details: generate QR',
    'note.list': 'Dashboard note: list',
    'note.create': 'Dashboard note: create',
    'note.update': 'Dashboard note: update',
    'note.delete': 'Dashboard note: delete',
    'business.transaction.list': 'Business → Transaction: list',
    'business.transaction.view': 'Business → Transaction: view',
    'business.event.list': 'Business → Event: list',
    'partner.list': 'Partner: list',
    'partner.view': 'Parnert: view',
    'partner.create': 'Partner: create',
    'partner.update': 'Partner: update',
    'partner.delete': 'Partner: delete',
    'partner.close': 'Partner: close',
    'partner.account.view': 'Partner → Account: view',
    'partner.account.list': 'Partner → Account: list',
    'partner.account.create': 'Partner → Account: create',
    'partner.account.update': 'Partner → Account: update',
    'partner.account.block': 'Partner → Account: block',
    'partner.account.unblock': 'Partner → Account: unblock',
    'partner.account.close': 'Partner → Account: close',
    'partner.configuration.view': 'Partner → Configuration: view',
    'partner.configuration.update': 'Partner → Configuration: update',
    'partner.transaction.list': 'Partner → Transaction: list',
    'partner.transaction.view': 'Partner → Transaction: view',
    'partner.event.list': 'Partner → Event: list',
    'tenant.account.list': 'Tenant → Account: list',
    'tenant.account.view': 'Tenant → Account: view',
    'tenant.account.create': 'Tenant → Account: create',
    'tenant.account.update': 'Tenant → Account: update',
    'tenant.account.daySummary.list': 'Tenant → Account: day summary',
    'tenant.account.transaction.list': 'Tenant → Account → Transaction: list',
    'tenant.account.transaction.view': 'Tenant → Account → Transaction: view',
    'bank.account.list': 'Bank account: list',
    'bank.account.view': 'Bank account: view',
    'bank.account.create': 'Bank account: create',
    'bank.account.update': 'Bank account: update',
    'bank.account.daySummary.list': 'Bank account: day summary',
    'bank.account.transfer.list': 'Bank account → Transfer: list',
    'bank.account.transfer.view': 'Bank account → Transfer: view',
    'solution.list': 'Solution: list',
    'solution.view': 'Solution: view',
    'solution.create': 'Solution: create',
    'solution.update': 'Solution: update',
    'solution.delete': 'Solution: delete',
    'solution.settings.view': 'Solution → Settings: view',
    'solution.settings.update': 'Solution → Settings: update',
    'solution.pricing.list': 'Solution → Pricing: list',
    'solution.pricing.view': 'Solution → Pricing: view',
    'solution.pricing.create': 'Solution → Pricing: create',
    'solution.pricing.update': 'Solution → Pricing: update',
    'solution.pricing.delete': 'Solution → Pricing: delete',
    'solution.pricing.schema.view': 'Solution → Pricing: view schema',
    'solution.product.list': 'Solution → Product: list',
    'solution.product.view': 'Solution → Product: view',
    'solution.product.create': 'Solution → Product: create',
    'solution.product.update': 'Solution → Product: update',
    'solution.product.delete': 'Solution → Product: delete',
    'solution.product.fxmapping.list': 'Solution → Product → FX mapping: list',
    'solution.handbook.view': 'Solution → Handbook: view',
    'solution.handbook.update': 'Solution → Handbook: update',
    'currency.list': 'Currency: list',
    'currency.create': 'Currency: create',
    'currency.pair.list': 'Currency → Pair: list',
    'currency.pair.update': 'Currency → Pair: update',
    'fxrates.list': 'FX rates: list',
    'fxrates.view': 'FX rates: view',
    'fxrates.create': 'FX rates: create',
    'fxrates.update': 'FX rates: update',
    'fxrates.data.view': 'FX rates → Data: view',
    'fxrates.data.update': 'FX rates → Data: update',
    'fxrates.mapping.list': 'FX rates → Mapping: list',
    'fxrates.mapping.view': 'FX rates → Mapping: view',
    'fxrates.mapping.create': 'FX rates → Mapping: create',
    'fxrates.mapping.update': 'FX rates → Mapping: update',
    'fxrates.mapping.delete': 'FX rates → Mapping: delete',
    'identity.list': 'Team: list',
    'identity.view': 'Team: view',
    'identity.create': 'Team: create',
    'identity.update': 'Team: update',
    'identity.delete': 'Team: delete',
    'kyc.app': 'KYC: run app',
    'kyc.workflow.start': 'KYC: start workflow',
    'kyc.resetProgress': 'KYC: reset progress',
    'auditLog.list': 'System log: list',
    'stats.view': 'Home page (view stats)',
    'paymentService.list': 'Payment service: list',
    'paymentService.view': 'Payment service: view',
    'paymentService.create': 'Payment service: create',
    'paymentService.update': 'Payment service: update',
    'paymentService.delete': 'Payment service: delete',
    'headTransaction.list': 'Transaction: list',
    'headTransaction.view': 'Transaction: view',
    'headTransaction.revert': 'Transaction: revert',
    'headTransaction.approve': 'Transaction: approve',
    'headTransaction.cancel': 'Transaction: cancel',
    'beneficiary.list': 'Beneficiary: list',
    'beneficiary.view': 'Beneficiary: view',
    'beneficiary.create': 'Beneficiary: create',
    'beneficiary.update': 'Beneficiary: update',
    'beneficiary.delete': 'Beneficiary: delete',
    'deal.list': 'Deal: list',
    'deal.view': 'Deal: view',
    'deal.code.show': 'Deal: show code',
    'deal.update': 'Deal: update',
    'report.list': 'Report: list',
    'report.view': 'Report: view',
    'report.create': 'Report: create',
    'externalSource.list': 'External source: list',
    'externalSource.view': 'External source: view',
    'externalSource.create': 'External source: create',
    'externalSource.update': 'External source: update',
    'externalSource.delete': 'External source: delete',
    'apiIdentity.list': 'API Identity: list',
    'apiIdentity.view': 'API Identity: view',
    'apiIdentity.update': 'API Identity: update',
    'settlement.runTransfer': 'Settlement: run',
    'accountTransfer.runTransfer': 'Account transfer: run',
    'feedback.list': 'Feedback: list',
    'feedback.statistic': 'Feedback: statistic',
    'event.list': 'Event: list',
    'integrationIdentity.view': 'Integration Identity: view',
    'localization.create': 'Localization: create',
    'localization.update': 'Localization: update',
    'localization.list': 'Localization: list',
    'localization.delete': 'Localization: delete',
    'externalProfileLink.view': 'External profile link: view',
    'externalProfileLink.list': 'External profile link: list',
    'externalProfileLink.create': 'External profile link: create',
    'externalProfileLink.update': 'External profile link: update',
    'externalProfileLink.delete': 'External profile link: delete',
    'externalProfileLink.viewAuthData': 'External profile link: view auth data',
    'externalProfileLink.event.list': 'External profile link → Event: list',
    'metrics.list': 'Metrics: list',
    'batchPayment.view': 'Batch payment: view',
    'batchPayment.list': 'Batch payment: list',
    'dashboardRole.create': 'Dashboard role: create',
    'dashboardRole.update': 'Dashboard role: update',
    'dashboardRole.delete': 'Dashboard role: delete',
    'externalSource.name.update': 'External source → Name: update',
  },
};
