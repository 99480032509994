import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Longdash } from '~/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    padding: 3,
    color: theme.palette.grey[500],
    marginTop: -8,
    marginBottom: -8,
  },
}));

const RowMenuButton = (props: any) => {
  const { children, onClick } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleItemClick = (e: any) => {
    onClick && onClick(e);
    handleClose(e);
  };

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {children || (
          <MenuItem id="copy-uuid" onClick={handleItemClick}>
            <ListItemText primary={Longdash} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default RowMenuButton;
