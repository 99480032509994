export default {
  cards: 'Cards',
  addCard: 'Add card',
  editCardDetails: 'Edit card details',
  cardType: 'Card type',
  alias: 'Alias',
  cardholderName: 'Cardholder name',
  linkedAccount: 'Linked account',
  linkedExternalSource: 'Linked external source',
  status: 'Status',
  expirationDate: 'Expiration date',
  paymentSystem: 'Payment system',
  deliveryStatus: 'Delivery status',
  updateDeliveryStatus: 'Update delivery status',
  deliveryType: 'Delivery type',
  branch: 'Branch',
  issuerInformation: 'Issuer information',
  partner: 'Partner',
  externalId: 'External ID',
  updateStatus: 'Update status',
  reissue: 'Reissue card',
  reissueWarningText: 'A new card with the same parameters will be ordered. Please confirm.',
  setStatus: 'Set status',
  delivery: 'Delivery',
  showCardNumber: 'Show card number',
  hideCardNumber: 'Hide card number',
  changePin: 'Change PIN',
  changePinConfirm: 'New PIN code will be sent to cardholder via SMS. Please confirm.',
  editDeliveryAddress: 'Edit delivery address',
};
