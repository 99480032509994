import React from 'react';

import { ChipField } from '~/components';
import { useAllFeedbackTags } from '~/hooks/useHandbook';

const FeedbackField = (props: { tag: string }) => {
  const { tag } = props;
  const { data } = useAllFeedbackTags();
  return <ChipField label={data[tag] || tag} />;
};

export default FeedbackField;
