import { Card, CardDetails, CardType } from '~/types/Card';
import axios from '~/utils/axios';

export const getCardsTypes = () => {
  return axios.get<CardType>(`/cards/types`);
};

export const getCards = (resource: 'individuals' | 'businesses', profileId: string) => () => {
  return axios.get<Card[]>(`/${resource}/${profileId}/cards?page=1&perPage=999`);
};

export const getCardNumber = (id: string) => {
  return axios.get<string>(`/cards/${id}/number`);
};

export const reissueCard = (id: string) => {
  return axios.post(`/cards/${id}/reissue`);
};

export const changePin = (id: string) => {
  return axios.post(`/cards/${id}/changePin`);
};

export const getDetails = (id: string) => {
  return axios.get<CardDetails>(`/cards/${id}/details`);
};
