import React from 'react';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import { Skeleton } from '@material-ui/lab';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface TableCellProps extends MuiTableCellProps {
  loading?: boolean;
  children?: any;
  style?: React.CSSProperties;
  className?: string;
  isDiff?: boolean;
}

const TableCell = (props: TableCellProps) => {
  const { loading, children, style, className = '', isDiff = false, ...rest } = props;
  const classes = useStyles({ isDiff });
  return (
    <MuiTableCell className={cx(className, classes.diff)} style={style} {...rest}>
      {loading ? <Skeleton width="100%" height="18px" /> : children}
    </MuiTableCell>
  );
};

const useStyles = makeStyles<Theme, { isDiff: boolean }>((theme) => ({
  diff: ({ isDiff }) => ({
    color: isDiff ? theme.palette.warning.main : undefined,
  }),
}));

export default TableCell;
