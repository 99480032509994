import React, { useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deactivateTerminal } from '~/api/terminal';

import { FlashOffIcon } from '~/img';
import useCrudGetManyReference from '~/hooks/useCrudGetManyReference';
import onError from '~/errorsHandler';

interface Props {
  terminalId: string;
  resource?: string;
  businessId: string;
  target?: string;
  permissions: string[];
}

const DeactivateTerminal = (props: Props) => {
  const {
    terminalId,
    resource = 'terminals',
    target = 'businesses',
    businessId,
    permissions,
  } = props;

  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);

  const crudGetManyReference = useCrudGetManyReference(resource, target, businessId);

  const deactivate = async () => {
    setLoading(true);
    try {
      await deactivateTerminal(terminalId);
      crudGetManyReference();
    } catch (error) {
      onError(error);
    }
    setLoading(false);
  };

  const classes = useStyles();

  return (
    <Button
      label={t('makeInactive')}
      onClick={deactivate}
      className={classes.warningColor}
      disabled={loading || !permissions.includes('business.terminal.update')}
    >
      <SvgIcon>
        <FlashOffIcon />
      </SvgIcon>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  warningColor: {
    color: theme.palette.warning.main,
  },
}));

export default DeactivateTerminal;
