import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import cx from 'classnames';
import { Longdash } from '~/utils';

const useStyles = makeStyles((theme) => ({
  changed: {
    color: theme.palette.warning.main,
  },
}));

interface HilightChangesProps {
  curr: any;
  prev: any;
  children: any;
  emptyText?: string;
}

const HilightChanges = (props: HilightChangesProps) => {
  const { curr, prev, children, emptyText = Longdash } = props;
  const classes = useStyles();
  return (
    <div className={cx({ [classes.changed]: !isEqual(curr, prev) })}>{children || emptyText}</div>
  );
};

export default HilightChanges;
