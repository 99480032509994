import React, { FC } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import { Account } from '~/types';
import { TextField as RaTextField } from '~/components/ra';
import { buildProfileLink, Longdash } from '~/utils';

const makeLabel = (key: string): string => `resources.headTransactions.show.details.${key}`;

interface Props {
  account: Account;
  label?: string;
}

const AccountProfileField: FC<Props> = (props) => {
  const { account, label } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(makeLabel(key));

  const makeProps = (reference: string, source?: string) => ({
    reference,
    basePath: `/${reference}`,
    source: source || 'profileId',
    record: account,
    link: false,
    addLabel: false,
  });

  switch (account.profileType) {
    case 'INDIVIDUAL':
      return (
        <ReferenceField {...makeProps('individuals')}>
          <RaTextField
            fn={(r) => `${r.firstName || Longdash} ${r.lastName}`}
            label={label || t('individual')}
            showLink={buildProfileLink(account.id, account.profileId, account.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'BUSINESS':
      return (
        <ReferenceField {...makeProps('businesses')}>
          <RaTextField
            source="legalName"
            label={label || t('business')}
            allowEmpty
            showLink={buildProfileLink(account.id, account.profileId, account.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'PARTNER':
      return (
        <ReferenceField {...makeProps('partners')}>
          <RaTextField
            source="name"
            label={label || t('partner')}
            allowEmpty
            showLink={buildProfileLink(account.id, account.profileId, account.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'TENANT':
      return (
        <ReferenceField {...makeProps('tenant/accounts', 'id')}>
          <RaTextField
            source="alias"
            label={label || t('tenant')}
            allowEmpty
            showLink={buildProfileLink(account.id, account.profileId, account.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    default:
      return null;
  }
};

export default AccountProfileField;
