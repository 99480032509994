import { createAction, handleActions } from 'redux-actions';

const DRAWER_OPEN = 'ui/drawer/open';
const DRAWER_CLOSE = 'ui/drawer/close';

export const openDrawer = createAction<void>(DRAWER_OPEN);
export const closeDrawer = createAction<void>(DRAWER_CLOSE);

export type State = {
  openDrawers: number;
};

const initialState: State = {
  openDrawers: 0,
};

export default handleActions<State, any>(
  {
    [DRAWER_OPEN]: (state) => {
      return {
        ...state,
        openDrawers: state.openDrawers + 1,
      };
    },
    [DRAWER_CLOSE]: (state) => {
      return {
        ...state,
        openDrawers: state.openDrawers - 1,
      };
    },
  },
  initialState
);
