import React, { FC, useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Deal } from '~/types/Deal';
import { showDealCode } from '~/api/deals';
import { useNotify, useTimeout } from '~/hooks';

interface Props {
  record: Deal;
  permissions?: string[];
}

const ShowCodeAction: FC<Props> = (props) => {
  const { record, permissions } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.deals.show.details.${key}`);
  const [code, setDealCode] = useState('');

  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleConfirmationOpen = () => setConfirmationOpen(true);
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setDealCode('');
  };

  const [text, setText] = useState('');
  const handleChange = (e: any) => {
    setText(e.target?.value || '');
  };

  const clearCodeTimeout = useTimeout(() => {
    setDealCode('');
    setLoading(false);
  }, 60 * 1000);

  const notify = useNotify();

  const handleShowCode = async () => {
    if (code) {
      clearCodeTimeout.start(0);
    } else {
      setLoading(true);
      try {
        const { data } = await showDealCode(record.id, text);
        clearCodeTimeout.start();
        setDealCode(data);
      } catch (error) {
        notify({ message: (error as any).message, type: 'error' });
      }
      setLoading(false);
      setText('');
    }
  };

  const classes = useStyles();

  if (record.status?.toLocaleLowerCase() !== 'sent') return null;
  if (!permissions?.find((item) => item === 'deal.code.show')) return null;

  return (
    <>
      <Dialog
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        aria-labelledby='demo-alert-dialog-title'
        aria-describedby='demo-alert-dialog-description'
      >
        <DialogTitle id='demo-alert-dialog-title'>{t('showCode')}</DialogTitle>
        <DialogContent style={{ maxWidth: 400, paddingBottom: 4 }}>
          <DialogContentText id='demo-alert-dialog-description'>
            {code ? (
              <div className={classes.codeContainer}>
                <Typography variant='h3' className={classes.code}>
                  {code}
                </Typography>
                <Alert severity='error'>
                  <div>{t('showCodeAttention1')}</div>
                  <div>{t('showCodeAttention2')}</div>
                </Alert>
              </div>
            ) : (
              <TextField
                name='text'
                label={t('reason')}
                value={text}
                onChange={handleChange}
                multiline
                rows={5}
                rowsMax={20}
                style={{ minWidth: 400 }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonsContainer}>
          {code ? (
            <Button label='ra.action.close' onClick={handleConfirmationClose} />
          ) : (
            <>
              <Button label='ra.action.cancel' onClick={handleConfirmationClose} />
              <Button
                label={translate('utils.confirmation.confirm')}
                onClick={handleShowCode}
                variant='contained'
                disabled={(text || '').length < 1}
              >
                <CheckCircleIcon />
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Button
        label={t(!code ? 'showCode' : 'hideCode')}
        onClick={handleConfirmationOpen}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={18} thickness={2} />
        ) : !code ? (
          <VisibilityIcon />
        ) : (
          <VisibilityOffIcon />
        )}
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  codeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 400,
    height: 109,
  },
  buttonsContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  code: {
    marginBottom: theme.spacing(3),
  },
}));

export default ShowCodeAction;
