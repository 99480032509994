import React from 'react';
import lodashGet from 'lodash/get';
import moment from 'moment';
interface Props {
  record?: any;
  source: string;
  format?: string;
  emptyText?: string;
  [x: string]: any;
}

const DateField = (props: Props) => {
  const { record, source, format = 'LL', emptyText } = props;
  const date = lodashGet(record, source);
  if (date) {
    return <span>{moment(date).format(format)}</span>;
  } else return <span>{emptyText || null}</span>;
};

export default DateField;
