import React from 'react';
import { useTranslate } from 'react-admin';

import { useTheme } from '@material-ui/core/styles';

import { SelectInput } from '~/components';
import { Tag, TagsList } from '~/api/auditLog';
import { TagChip, makeActionColors } from './CustomCell';
import { useGetTags } from '~/hooks/auditLog';

const ColoredTagChip = (props: any) => {
  const { tag, label } = props;
  const theme = useTheme();
  const actionColors: any = makeActionColors(theme);
  const color = actionColors[tag] || 'unset';

  return <TagChip style={{ color, borderColor: color, marginRight: 4 }} label={label} forTable />;
};

interface Props {
  value: Tag[];
  onChange: (e: any) => void;
  className?: string;
  [x: string]: any;
}

const TagsInput = (props: Props) => {
  const { value, onChange, className, ...rest } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.auditLog.${key}`);

  const { data, isLoading, error } = useGetTags();

  const getOptions = (data: TagsList | undefined): { value: Tag; text: string }[] | [] => {
    if (data) {
      return Object.keys(data)
        .filter((key) => {
          const value = key as Tag;
          switch (value) {
            case 'l.info':
            case 'l.warn':
            case 'l.error':
              return false;
            default:
              return true;
          }
        })
        .map((key) => {
          const value = key as Tag;
          return { value, text: data[value] };
        });
    } else return [];
  };
  return (
    <SelectInput<Tag>
      label={t('tags')}
      name="tags"
      value={value}
      className={className}
      options={getOptions(data)}
      onChange={onChange}
      multiple
      disabled={isLoading || !!error}
      renderValue={(tags: Tag[]) => {
        return tags.map((item) => {
          return <ColoredTagChip key={item} tag={item} label={data && data[item]} />;
        });
      }}
      {...rest}
    />
  );
};

export default TagsInput;
