import React from 'react';
import { useTranslate } from 'react-admin';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H3, TextField, AmountField } from '~/components';
import { Section } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import { RecipientIcon, SenderIcon } from '~/img';

import { l } from './RunTransferAction';
import { Account } from '~/types';
import FxRateField from '../FxRateField';

type RunTransferActionConsentProps = {
  consent: HeadTransaction;
  accounts: Account[];
};

const RunTransferActionConsent = ({ consent, accounts }: RunTransferActionConsentProps) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  console.log(`accounts:`, accounts);

  const trx = consent;

  const senderAccount = trx.accounts.find((a) => a.id === trx.sender)!;

  const recipientAccount = trx.accounts.find((a) => a.id === trx.recipient)!;

  const senderAccountAlias = accounts.find((a) => a.id === trx.sender)?.alias;

  const recipientAccountAlias = accounts.find((a) => a.id === trx.recipient)?.alias;

  const senderAccountEffect = trx.accountEffects.find((e) => e.accountId === trx.sender)!;

  const recipientAccountEffect = trx.accountEffects.find((e) => e.accountId === trx.recipient)!;

  const senderExchangeRate = trx?.details.currencyConversion?.sender?.rate;
  const senderInstructedExchangeRate = trx?.details.currencyConversion?.sender?.instructedRate;
  const recipientExchangeRate = trx?.details.currencyConversion?.recipient?.rate;
  const recipientInstructedExchangeRate =
    trx?.details.currencyConversion?.recipient?.instructedRate;

  return (
    <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
      <Grid item xs={6}>
        <Section noPaddings>
          <H3 icon={<SenderIcon />} noPaddingLeft>
            {t('sender')}
          </H3>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label={`${t('from')}`}>{senderAccountAlias}</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={`${t('account')} ${trx.sender}`}>{senderAccount.number}</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField className={`${classes.labelBottomSpacing}`}>
                {t('senderAmounts')}
              </TextField>
              <Table style={{ width: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 150 }}>{t('entry.category')}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{t('entry.amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {senderAccountEffect.entries.map((entry, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {entry.description ||
                          (entry.category === 'TRANSACTION'
                            ? t('entry.transactionAmount')
                            : entry.category)}
                      </TableCell>
                      <TableCell>
                        <AmountField amount={entry.amount} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>{t('entry.totalAmount')}</TableCell>
                    <TableCell>
                      <AmountField
                        amount={{
                          value: senderAccountEffect.totalBalanceEffect,
                          currency: senderAccount.currency,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} container>
              {senderExchangeRate && (
                <Grid item xs={6}>
                  <TextField label={t('exchangeRate')}>
                    <FxRateField
                      baseCurrency={trx.amount.currency}
                      quoteCurrency={senderAccount.currency}
                      rate={senderExchangeRate}
                    />
                  </TextField>
                </Grid>
              )}
              {senderInstructedExchangeRate && (
                <Grid item xs={6}>
                  <TextField label={t('instructedExchangeRate')}>
                    <FxRateField
                      baseCurrency={(trx.details.baseAmount || trx.amount).currency}
                      quoteCurrency={senderAccount.currency}
                      rate={senderInstructedExchangeRate}
                    />
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item xs={6}>
        <Section>
          <H3 icon={<RecipientIcon />} noPaddingLeft>
            {t('recipient')}
          </H3>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label={`${t('to')}`}>{recipientAccountAlias}</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={`${t('account')} ${trx.recipient}`}>
                {recipientAccount.number}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField className={`${classes.labelBottomSpacing}`}>
                {t('recipientAmounts')}
              </TextField>
              <Table style={{ width: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 150 }}>{t('entry.category')}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{t('entry.amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recipientAccountEffect.entries.map((entry, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {entry.description ||
                          (entry.category === 'TRANSACTION'
                            ? t('entry.transactionAmount')
                            : entry.category)}
                      </TableCell>
                      <TableCell>
                        <AmountField amount={entry.amount} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>{t('entry.totalAmount')}</TableCell>
                    <TableCell>
                      <AmountField
                        amount={{
                          value: recipientAccountEffect.totalBalanceEffect,
                          currency: recipientAccount.currency,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} container>
              {recipientExchangeRate && (
                <Grid item xs={6}>
                  <TextField label={t('exchangeRate')}>
                    <FxRateField
                      baseCurrency={trx.amount.currency}
                      quoteCurrency={recipientAccount.currency}
                      rate={recipientExchangeRate}
                    />
                  </TextField>
                </Grid>
              )}
              {recipientInstructedExchangeRate && (
                <Grid item xs={6}>
                  <TextField label={t('instructedExchangeRate')}>
                    <FxRateField
                      baseCurrency={(trx.details.baseAmount || trx.amount).currency}
                      quoteCurrency={recipientAccount.currency}
                      rate={recipientInstructedExchangeRate}
                    />
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Section>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  labelBottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
}));

export default RunTransferActionConsent;
