import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { time } from '~/utils';

import { CurrencyAutocomplete, SelectInput } from '~/components';
import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { ActionReport, Moment } from '~/types';
import { HeadTransactionFilters } from '~/types/HeadTransaction';
import { useAllReportSystemsAndTypes } from '~/hooks/actionReports';
import { ReportStatusSelectInput } from '~/resources/actionReports/Filters';
import {
  SelectSettled,
  SelectTransactionStatus,
  SelectTransactionType,
} from '~/components/TransactionsHistory/Filters';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { HeadTransactionSearchFilterDto } from '~/api/backgroundTasks';
import { cleanupFormValues } from '~/utils';

//--------------------------------------------
const resource = 'headTransactions';

interface Props {
  cleanFilters?: boolean;
}

const Filters = (props: Props) => {
  const { cleanFilters = true } = props;
  const filters = useSelector(selectors.getFilters<HeadTransactionFilters>(resource));
  const [localValues, setLocalValues] = useState<HeadTransactionFilters>(filters);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const t = (key: string): string => translate(`resources.headTransactions.list.filters.${key}`);

  // const [{ data }] = useApi<CurrencyType[]>(getCurrencies);
  // const currencyOptions = data && data.map((item) => item.code);

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 500), []);

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    debouncedDispatchFilterChange(inputType, data);
  };

  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const filtersAreEmpty = isEmpty(filters);
  useEffect(() => {
    if (cleanFilters || filtersAreEmpty) {
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            createdFrom: time().subtract(1, 'months'),
            createdTo: time(),
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty]);

  const schemaQuery = useAllReportSystemsAndTypes();
  const getSystemOptions = () => {
    return (
      schemaQuery.data?.map(({ system }) => ({
        value: system,
        text: system,
      })) || []
    );
  };
  const handleSystemChange = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
      reportStatus: undefined,
    });
    dispatchFilterChange(name, value);
    dispatchFilterChange('reportStatus', '');
  };
  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div>
            <div className={classes.inputsGroup}>
              <DateTimePicker
                className={classes.datePickers}
                format='L - LT'
                margin='normal'
                label={t('from')}
                value={localValues.createdFrom}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdFrom')}
              />
              <DateTimePicker
                className={classes.datePickers}
                format='L - LT'
                margin='normal'
                label={t('to')}
                value={localValues.createdTo}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdTo')}
              />
              <TextField
                label={t('senderAccountNumber')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.senderAccountNumber ?? ''}
                name='senderAccountNumber'
              />
              <TextField
                label={t('recipientAccountNumber')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.recipientAccountNumber ?? ''}
                name='recipientAccountNumber'
              />
              <SelectTransactionType
                name='operationType'
                value={localValues.operationType || ''}
                onChange={handleSelectInput}
                className={classes.formControl}
              />
              <SelectTransactionStatus
                onChange={handleSelectInput}
                value={localValues.status || ''}
                label={t('transactionStatus')}
                className={classes.formControl}
              />
            </div>
            <div className={classes.inputsGroup}>
              <TextField
                label={t('baseAmountFrom')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.baseAmountFrom ?? ''}
                name='baseAmountFrom'
              />
              <TextField
                label={t('baseAmountTo')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.baseAmountTo ?? ''}
                name='baseAmountTo'
              />
              <CurrencyAutocomplete
                label={t('currency')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.currency}
                name='currency'
              />
              <TextField
                label={t('referenceId')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.referenceId ?? ''}
                name='referenceId'
              />
              <SelectInput<ActionReport['system'] | undefined>
                label={t('reportSystem')}
                name='reportSystem'
                value={localValues.reportSystem}
                className={classes.formControl}
                options={getSystemOptions()}
                onChange={handleSystemChange}
                disabled={schemaQuery.isLoading}
                allowEmpty
              />
              <ReportStatusSelectInput
                name='reportStatus'
                value={localValues.reportStatus}
                label={t('reportStatus')}
                className={classes.formControl}
                onChange={handleSelectInput}
                disabled={schemaQuery.isLoading || !localValues.reportSystem}
              />
            </div>
            <div className={classes.inputsGroup}>
              <SelectSettled
                onChange={handleSelectInput}
                value={localValues.settled === undefined ? '' : '' + localValues.settled}
                label={t('settled')}
                className={classes.formControl}
              />
            </div>
          </div>
          <div style={{ minWidth: 150, textAlign: 'right' }}>
            <BackgroundTaskExport
              type='HEAD_TRANSACTION_EXPORT'
              queryArguments={handleFilters(localValues)}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const handleFilters = (filters: HeadTransactionFilters): HeadTransactionSearchFilterDto => {
  if (!filters) return {};
  const { createdFrom, createdTo, currency, settled, ...rest } = filters;
  const settledValue: boolean | null =
    settled === 'true' ? true : settled === 'false' ? false : null;
  return cleanupFormValues({
    createdFrom: handleDate(createdFrom),
    createdTo: handleDate(createdTo),
    baseAmountCurrency: currency,
    settled: settledValue,
    ...rest,
  });
};

const handleDate = (date?: Moment | null) => {
  if (!date) return undefined;
  return date.toISOString();
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 190,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 190,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default Filters;
