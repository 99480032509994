import React, { FC } from 'react';
import { Button } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';

import { OpenIcon } from '~/img';
import { useApiIdentitiesT } from '../utils';
import { Link } from '~/components';

interface Props {
  profileId: string;
  profileType: 'INDIVIDUAL' | 'BUSINESS';
}

const GoToProfile: FC<Props> = (props) => {
  const { profileId, profileType } = props;
  const t = useApiIdentitiesT();

  return (
    <Link
      href={
        profileType === 'INDIVIDUAL'
          ? `/individuals/${profileId}/show`
          : profileType === 'BUSINESS'
          ? `/businesses/${profileId}/show`
          : undefined
      }
      target="_blank"
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button label={t('goToProfile')}>
        <SvgIcon>
          <OpenIcon title="show" />
        </SvgIcon>
      </Button>
    </Link>
  );
};

export default GoToProfile;
