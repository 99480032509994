import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { JSONSchema6 } from 'json-schema';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import { isEqual } from 'lodash';
import { getProducts } from '~/api/product';

interface Value {
  type: string | unknown;
  name: string | unknown;
  [key: string]: any;
}

interface Errors {
  name?: {
    error: boolean;
    message: string;
  };
  [key: string]: any;
}

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const ProductInput = ({ formData, idSchema, uiSchema, formContext, onChange }: Props) => {
  const raTranslate = useTranslate();

  const [state, setState] = useState<string>(formData || '');
  const [products, setProducts] = useState<any[]>([]);
  const [error, onChangeWithError] = React.useState<any>({});

  // load products
  useEffect(() => {
    getProducts().then((items) => {
      setProducts(items);
    });
  }, []);

  useEffect(() => {
    const onValidate = formContext?.onValidate;
    const hasError = Object.keys(error).length !== 0;
    onValidate && onValidate(idSchema.$id, hasError);

    onChange && onChange(state);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = (state: string): any => {
    if (!state) {
      return { error: true, message: raTranslate('ra.validation.required') };
    } else {
      return {};
    }
  };

  const validateAndSetState = (newState: string): void => {
    setState(newState);
    onChangeWithError(validate(newState)); // force update
  };

  // initial validation
  const defaultError = validate(state);
  if (!isEqual(error, defaultError)) {
    onChangeWithError(defaultError);
  }
  {
    const onValidate = formContext?.onValidate;
    const hasError = Object.keys(error).length !== 0;
    onValidate && onValidate(idSchema.$id, hasError);
  }

  // render
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    validateAndSetState((event.target.value as string) || '');
  };

  const options = products.map((product) => (
    <MenuItem key={product.code} value={product.code}>
      {product.code}
    </MenuItem>
  ));

  return (
    <Box>
      <FormControl required error={error?.error} style={{ minWidth: 200 }}>
        <InputLabel>{uiSchema['ui:title']}</InputLabel>
        <Select value={state} onChange={handleChange}>
          {options}
        </Select>
        <FormHelperText>{'\u00A0'}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default ProductInput;
