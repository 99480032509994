const collectProperties = (
  object: any,
  exclude: string[] = []
): { key: string; value: string }[] => {
  const items: any[] = [];

  const scan = (object: any, prefix: string | undefined = undefined) => {
    Object.keys(object).forEach((key: string) => {
      const value = object[key];
      if (!Array.isArray(value)) {
        switch (typeof value) {
          case 'object':
            scan(value, prefix ? `${prefix}.${key}` : key);
            break;
          default:
            items.push({
              key: prefix ? `${prefix}.${key}` : key,
              value,
            });
        }
      }
    });
  };

  scan(object);

  return items
    .filter(
      (item) =>
        exclude.indexOf(item.key) < 0 &&
        exclude.find((x) => item.key.indexOf(`${x}.`) === 0) === undefined
    )
    .sort((a, b) => a.key.localeCompare(b.key));
};

export default collectProperties;
