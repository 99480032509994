import React from 'react';
import { Button, Link, withTranslate } from 'react-admin';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const ReferenceShowButton = ({
  record,
  referenceType,
  parentId,
  parentType,
  parentName,
  solutionId,
  translate,
  country,
}) => (
  <Button
    component={Link}
    to={{
      pathname: `/${referenceType}/${record.id}/show`,
      state: { parentId, parentName, parentType, solutionId, country },
    }}
    label={translate(`resources.${referenceType}.reference.show`)}
  >
    <ChatBubbleIcon />
  </Button>
);

export default withTranslate(ReferenceShowButton);
