import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';

import { selectors, loadConfiguration } from '~/ducks/solutions';
import { selectors as context } from '~/ducks/context';

export interface Result {
  loading: boolean;
  data?: any;
  error?: any;
}

const useConfiguration = (solutionId?: string) => {
  const solutionIdFromStore = useSelector(context.get('solutionId'));
  const actualSolutionId = solutionId || solutionIdFromStore;

  const result = useSelector((state) => {
    if (actualSolutionId) {
      return selectors.getConfigurationForSolution(actualSolutionId)(state);
    } else {
      return undefined;
    }
  }) as Result | undefined;

  const dispatch = useDispatch();

  const throttledLoadConfiguration = useCallback(
    throttle((actualSolutionId: any): any => {
      dispatch(loadConfiguration(actualSolutionId));
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (actualSolutionId) {
      throttledLoadConfiguration(actualSolutionId);
    }
  }, [actualSolutionId, throttledLoadConfiguration]);

  return result || { loading: false };
};

export default useConfiguration;
