import { AppThunk } from './../types/RootState';
import { handleActions } from 'redux-actions';
import { RootState } from '~/types';

const NOTIFICATION_ADD = 'notification/add';
const NOTIFICATION_SHOW = 'notification/show';
const NOTIFICATION_CLOSE = 'notification/close';

export type Notification = {
  id?: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  message: string;
  autoHideDuration?: number;
  disableClickawayClose?: boolean;
};

export type State = {
  queue: Notification[] | [];
  notificationToShow: Notification | null;
};

const setAddToQueue = (payload: Notification) => ({
  type: NOTIFICATION_ADD,
  payload: {
    id: new Date().getTime().toString(),
    autoHideDuration: 3000,
    ...payload,
  },
});

const setAddToShow = () => ({
  type: NOTIFICATION_SHOW,
});

const setRemoveFromShow = (id: string) => ({
  type: NOTIFICATION_CLOSE,
  payload: {
    id,
  },
});

export const notification = (notification: Notification) => {
  return (dispatch: any, getState: () => RootState) => {
    const { queue, notificationToShow } = getState().snackbarNotifications;
    if (notificationToShow) {
      if (notificationToShow.message === notification.message) return;
    }
    if (queue.find((item) => item.message === notification.message)) return;
    dispatch(setAddToQueue(notification));
    dispatch(addToShow());
  };
};

export const closeNotification = (id?: string) => {
  return (dispatch: any, getState: () => RootState) => {
    dispatch(setRemoveFromShow(id || 'closeCurrentNotification'));
    const { queue } = getState().snackbarNotifications;
    if (queue.length) {
      dispatch(addToShow());
    }
  };
};

const addToShow = (): AppThunk => {
  return (dispatch, getState) => {
    const { queue, notificationToShow } = getState().snackbarNotifications;
    if (!notificationToShow && queue.length) {
      dispatch(setAddToShow() as any);
      const toShow = [...queue][0];
      setTimeout(() => {
        dispatch(closeNotification(toShow?.id));
      }, toShow?.autoHideDuration || 0);
    }
  };
};

const initialState: State = {
  queue: [],
  notificationToShow: null,
};

export default handleActions<State, any>(
  {
    [NOTIFICATION_ADD]: (state, { payload }) => {
      return { ...state, queue: state.queue.concat([payload as never]) };
    },
    [NOTIFICATION_SHOW]: (state) => {
      if (!state.queue.length) return state;
      const queue = [...state.queue];
      const notificationToShow = queue.shift();
      return { ...state, queue, notificationToShow } as State;
    },
    [NOTIFICATION_CLOSE]: (state, { payload }) => {
      // close action dispatched by setTimeout allways provides id and we close notification with same id.
      // This is necessary to avoid a bug that closeNotification called by setTimeout and corresponding notification is already closed.
      // If there is no id: action dispatched from component and we close notification forcibly.
      if (payload.id === 'closeCurrentNotification') return { ...state, notificationToShow: null };
      if (state.notificationToShow?.id !== payload.id) return state;
      return { ...state, notificationToShow: null };
    },
  },
  initialState
);

export const selectors = {
  getNotificationToShow: () => (state: RootState) => {
    return state.snackbarNotifications.notificationToShow;
  },
};
