import React from 'react';
import { Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslate } from 'react-admin';

const useStyles = makeStyles(() => {
  return {
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '16px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
  };
});

const CreateTitle = ({ resource, translate }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h2" className={classes.headline}>
        Create new {translate(`resources.${resource}.name`, 1)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Required fields are marked with *
      </Typography>
    </div>
  );
};

export default withTranslate(CreateTitle);
