import React, { useState } from 'react';
import { useInput, useTranslate } from 'react-admin';

import lodashGet from 'lodash/get';

import AutocompleteArrayTextInputComponent from '~/components/AutocompleteArrayTextInput';

const AutocompleteArrayTextInput = (props: any) => {
  const {
    record,
    source,
    label,
    disableHelperText,
    regularHelperText,
    onChange: providedOnChange,
    ...rest
  } = props;
  const translate = useTranslate();

  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useInput({
    source,
    ...rest,
  });

  const initialValue = props.initialValue || (record && lodashGet(record, source)) || [];
  console.log(`initialValue=${initialValue}, record:`, record);
  const [value, setValue] = useState(initialValue);

  return (
    <AutocompleteArrayTextInputComponent
      label={label || source}
      value={value}
      onChange={(e, option, reason) => {
        onChange(option);
        setValue(option);
        providedOnChange && providedOnChange(e, option, reason);
      }}
      required={isRequired}
      errorMessage={touched && error && translate(error)}
      disableHelperText={disableHelperText}
      regularHelperText={regularHelperText}
    />
  );
};

export default AutocompleteArrayTextInput;
