import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import { NumberInput } from 'react-admin';
import { TextInput, SelectInput, required, FormDataConsumer, useTranslate } from 'react-admin';
import {
  AutocompleteArrayTextInput,
  JsonInput,
  ReferenceInput,
  SelectArrayInput,
} from '~/components/ra';
import OperationTypeInput from '~/components/ra/OperationTypeInput';
import { Section } from '~/layout';
import { Longdash } from '~/utils';
import FXRatesSelect from './FXRates/FXRatesSelect';

interface Props {
  edit?: boolean;
  record?: any;
}

const l = (key: string): string => `resources.paymentServices.${key}`;

const PaymentServiceForm = (props: Props) => {
  const { edit, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  return (
    <FormDataConsumer>
      {() => {
        return (
          <Section>
            <TextInput source='id' label={t('id')} validate={required()} disabled={edit} />
            <TextInput source='name' label={t('name')} validate={required()} />
            <SelectInput
              source='status'
              choices={[
                { id: 'ACTIVE', name: translate('components.ra.fields.StatusField.ACTIVE') },
                { id: 'ARCHIVED', name: translate('components.ra.fields.StatusField.ARCHIVED') },
              ]}
              label={t('status')}
              validate={required()}
            />
            <TextInput source='templateId' label={t('templateId')} />
            <TextInput source='partnerId' label={t('partnerId')} />
            <ReferenceInput
              source='accountId'
              reference='tenant/accounts'
              filter={{
                type: ['REGULAR'],
              }}
              allowEmpty
              emptyText={Longdash}
            >
              <SelectInput optionText={(r: any) => `${r.alias} (${r.number})`} />
            </ReferenceInput>
            <OperationTypeInput
              source='operationType'
              label={t('operationType')}
              style={{ marginBottom: '1.5em' }}
              record={record}
            />
            <TextInput source='category' label={t('category')} />
            <TextInput source='description' label={t('description')} multiline />
            <TextInput source='instruction' label={t('instruction')} multiline />
            <NumberInput source='index' label={t('index')} />
            <TextInput source='region' label={t('region')} />
            <JsonInput
              label={t('parameters')}
              source='parameters'
              validationError={'Invalid JSON'}
              initialValue='{}'
            />
            <JsonInput
              label={t('parameterGroups')}
              source='parameterGroups'
              validationError={'Invalid JSON'}
            />
            <JsonInput label={t('amount')} source='amount' validationError={'Invalid JSON'} />
            <JsonInput
              label={t('senderAmount')}
              source='senderAmount'
              validationError={'Invalid JSON'}
            />
            <SelectInput
              source='amountMode'
              choices={[
                { id: 'FIXED', name: t('amountMode.FIXED') },
                { id: 'SUGGESTED', name: t('amountMode.SUGGESTED') },
                { id: 'DEFAULT', name: t('amountMode.DEFAULT') },
              ]}
              allowEmpty
              label={t('amountMode._')}
            />
            <FXRatesSelect />
            <JsonInput
              label={t('recipientExternalSource')}
              source='recipientExternalSource'
              validationError={'Invalid JSON'}
            />
            <JsonInput
              label={t('integration')}
              source='integration'
              validationError={'Invalid JSON'}
            />
            <JsonInput label={t('deal')} source='deal' validationError={'Invalid JSON'} />
            <JsonInput label={t('i18n')} source='i18n' validationError={'Invalid JSON'} />
            <AutocompleteArrayTextInput
              source='tags'
              label={t('tags')}
              record={record}
              regularHelperText={t('tagsHelperText')}
            />
            <JsonInput
              label={t('additionalProperties')}
              source='additionalProperties'
              validationError={'Invalid JSON'}
            />
            <ReferenceArrayInput
              source='fallbackServiceIds'
              reference='paymentServices'
              label={t('fallbackServiceId')}
              perPage={100}
            >
              <SelectArrayInput optionText={(r: any) => (r ? `${r.id} (${r.name})` : '')} />
            </ReferenceArrayInput>
          </Section>
        );
      }}
    </FormDataConsumer>
  );
};

export default PaymentServiceForm;
