export default {
  name: 'Settings',
  fields: {
    id: 'ID',
    name: 'Name',
    parentId: 'Derived from',
    solutionId: 'Solution ID',
    created: 'Created',
    deleted: 'Delete',
  },
  reference: {
    create: 'Create settings',
    show: 'Go to settings',
  },
  veengu_individual_onboarding_self: {
    _title: 'Individual Self-onboarding',
    product: {
      _title: 'Individual Self-onboarding / Product mapping',
      code: { _title: 'Default product code' },
    },
  },
  veengu_individual_onboarding_dashboard: {
    _title: 'Individual onboarding via Dashboard',
    product: {
      _title: 'Individual onboarding via Dashboard / Product mapping',
      code: { _title: 'Default product code' },
    },
  },
  veengu_business_onboarding_self: {
    _title: 'Business Self-onboarding',
    product: {
      _title: 'Business Self-onboarding / Product mapping',
      code: { _title: 'Default product code' },
    },
  },
  veengu_business_onboarding_dashboard: {
    _title: 'Business onboarding via Dashboard',
    product: {
      _title: 'Business onboarding via Dashboard / Product mapping',
      code: { _title: 'Default product code' },
    },
  },
  veengu_email: {
    transmission: {
      _title: 'Email Transmission',
      connection: {
        _title: 'Connection',
        transport: {
          _title: 'Transport',
          SMTP: { _title: 'STMP' },
          SMTPS: { _title: 'STMP SSL' },
          SMTP_TLS: { _title: 'STMP TLS' },
        },
        host: { _title: 'Host' },
        port: { _title: 'Port' },
        username: { _title: 'Username' },
        password: { _title: 'Password' },
        proxy: {
          _title: 'Proxy server',
          host: { _title: 'Host' },
          port: { _title: 'Port' },
          username: { _title: 'Username' },
          password: { _title: 'Password' },
        },
      },
      sender: {
        _title: { _title: 'Sender' },
        address: { _title: 'Address' },
        name: { _title: 'Name' },
      },
      other: {
        _title: 'Other',
        sessionTimeout: { _title: 'Session timeout' },
      },
    },
  },
};
