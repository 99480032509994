import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import TableForm from './TableForm';
import { l } from '../CurrenciesList';
import { CurrenciesRates } from '~/api/fxrates';
import { MANUAL_SOURCE_SYSTEM } from './RatesSourceSelect';

interface TableEditProps {
  record: CurrenciesRates;
  refetch: () => void;
  permissions?: string[];
}

const TableEdit: FC<TableEditProps> = (props) => {
  const { permissions, record, refetch } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch();
    setOpen(false);
  };
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('fxrates.update')} />
      <Drawer heading={t('configureFXRateTable')} open={open} onClose={handleClose}>
        <EditForm
          resource='fxrates'
          record={record}
          closeParent={handleClose}
          parseRecordBeforeSubmit={(fxRate) => {
            if (fxRate.sourceSystem === MANUAL_SOURCE_SYSTEM) {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                sourceSystem,
                ...rest
              } = fxRate;
              return rest;
            } else {
              return fxRate;
            }
          }}
        >
          <TableForm record={record} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default TableEdit;
