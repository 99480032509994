import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    '& > button:last-child': {
      marginLeft: theme.spacing(1),
    },
    color: theme.palette.info.light,
  },
  primary: {
    '& > button': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  secondary: {
    '& > button': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  inherit: {
    '& > button': {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  blue: {
    '& > button': {
      color: theme.typography.body1.color,
      backgroundColor: colors.blue[300],
      '&:hover': {
        backgroundColor: colors.blue[700],
      },
    },
  },
  counter: {
    ...theme.typography.body2,
    fontSize: '13px',
    paddingLeft: theme.spacing(1),
    color: 'inherit',
  },
}));

export interface ContainedPaginationProps {
  page: number;
  total: number;
  setPage: (page: number) => void;
  color?: 'primary' | 'secondary' | 'blue' | 'inherit';
}

const ContainedPagination = (props: ContainedPaginationProps) => {
  const { page, total, setPage, color = 'blue' } = props;
  const nbPages = total || 1;
  const classes = useStyles();

  return nbPages > 1 ? (
    <div className={classes.root}>
      <div className={cx(classes.buttons, { [classes[color]]: true })}>
        <Button
          color="inherit"
          size="medium"
          variant="contained"
          key="prev"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <ChevronLeft />
          Prev
        </Button>
        <Button
          color="inherit"
          size="medium"
          variant="contained"
          key="next"
          onClick={() => setPage(page + 1)}
          disabled={page === nbPages}
        >
          Next
          <ChevronRight />
        </Button>
      </div>
      <div className={classes.counter}>{`${page}/${nbPages}`}</div>
    </div>
  ) : null;
};

export default ContainedPagination;
