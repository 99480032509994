import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import { HttpError } from 'react-admin';

import { API_URL, TENANT_CODE } from '../env';

const DEFAULT_HTTP_HEADERS: any = {
  Accept: 'application/json',
};
if (TENANT_CODE) {
  DEFAULT_HTTP_HEADERS['V-Tenant'] = TENANT_CODE;
}

const instance = axios.create({
  baseURL: API_URL,
  headers: DEFAULT_HTTP_HEADERS,
});

instance.interceptors.request.use(
  (config) => {
    config.headers['V-Access-Token'] = localStorage.getItem('token');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      return Promise.reject(
        new HttpError(error.response.statusText, error.response.status, error.response.data)
      );
    } else {
      return Promise.reject(new HttpError('Connection failed', 0, null));
    }
  }
);

export default instance;

// Create `axios-cache-adapter` instance
const store = localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: 'axios',
});

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  debug: true,
  store,
});
//
const instanceWithCache = axios.create({
  adapter: cache.adapter,
  baseURL: API_URL,
  headers: DEFAULT_HTTP_HEADERS,
});

instanceWithCache.interceptors.request.use(
  (config) => {
    config.headers['V-Access-Token'] = localStorage.getItem('token');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosWithCache = instanceWithCache;

export type ResponseType<R = any> = Promise<AxiosResponse<R>>;
