import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  useCheckMinimumRequiredProps,
  useListController,
  getListControllerProps,
  ComponentPropType,
  ListContextProvider,
} from 'ra-core';

import {
  ListToolbar,
  Pagination as DefaultPagination,
  BulkDeleteButton,
  BulkActionsToolbar,
  ListActions as DefaultActions,
} from 'ra-ui-materialui';

import ListTitle from './ListTitle';

const List = (props) => {
  const controllerProps = useListController(props);
  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContextProvider>
  );
};

List.propTypes = {
  // the props you can change
  actions: PropTypes.element,
  aside: PropTypes.element,
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.element,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: PropTypes.node,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired,
};

List.defaultProps = {
  filter: {},
  perPage: 10,
};

export const ListView = (props) => {
  const {
    actions,
    aside,
    filter,
    filters,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes: classesOverride,
    component: Content,
    exporter = false,
    title = <ListTitle />,
    resource,
    ...rest
  } = props;
  useCheckMinimumRequiredProps('List', ['children'], props);
  const classes = useStyles({ classes: classesOverride });
  const { version } = rest;
  const controllerProps = getListControllerProps({ resource, ...rest });
  console.log(controllerProps);
  return (
    <div className={classnames('list-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      {filters ? (
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            {title &&
              cloneElement(title, {
                resource,
                hasFilters: true,
                ...title.props,
              })}
          </div>
          <div>
            {(filters || actions) && (
              <ListToolbar
                filters={filters}
                {...controllerProps}
                actions={actions}
                exporter={exporter} // deprecated, use ExporterContext instead
                permanentFilter={filter}
              />
            )}
          </div>
        </React.Fragment>
      ) : (
        <div style={{ display: 'flex' }}>
          {title &&
            cloneElement(title, {
              resource,
              hasFilters: false,
              ...title.props,
            })}
          {(filters || actions) && (
            <ListToolbar
              filters={filters}
              {...controllerProps}
              actions={actions}
              exporter={exporter} // deprecated, use ExporterContext instead
              permanentFilter={filter}
            />
          )}
        </div>
      )}

      <div className={classes.main}>
        <Content
          className={classnames(classes.content, {
            [classes.bulkActionsDisplayed]: controllerProps.selectedIds.length > 0,
          })}
          key={version}
        >
          {bulkActionButtons !== false && bulkActionButtons && (
            <BulkActionsToolbar {...controllerProps}>{bulkActionButtons}</BulkActionsToolbar>
          )}
          {children &&
            cloneElement(Children.only(children), {
              ...controllerProps,
              hasBulkActions: bulkActionButtons !== false,
            })}
          {pagination &&
            cloneElement(pagination, { ...controllerProps, className: classes.pagination })}
        </Content>
        {aside && cloneElement(aside, controllerProps)}
      </div>
    </div>
  );
};

ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  component: ComponentPropType,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: PropTypes.node,
  total: PropTypes.number,
  version: PropTypes.number,
};

const DefaultBulkActionButtons = (props) => <BulkDeleteButton {...props} />;

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  component: Card,
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <DefaultPagination />,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: 'flex',
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create('margin-top'),
      position: 'relative',
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
      },
      '& table': {
        backgroundColor: 'white',
      },
    },
    bulkActionsDisplayed: {
      marginTop: -theme.spacing(8),
      transition: theme.transitions.create('margin-top'),
    },
    actions: {
      zIndex: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    pagination: {
      '& > div': {
        backgroundColor: 'white',
      },
    },
    noResults: { padding: 20 },
  }),
  { name: 'RaList' }
);

/* eslint-disable */
const sanitizeRestProps = ({
  actions,
  basePath,
  changeListParams,
  children,
  classes,
  className,
  crudGetList,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  exporter,
  filter,
  filterDefaultValues,
  filters,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  loading,
  loaded,
  locale,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  pagination,
  params,
  permissions,
  perPage,
  push,
  query,
  refresh,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSelectedIds,
  setSort,
  showFilter,
  sort,
  title,
  toggleItem,
  total,
  version,
  translate,
  ...rest
}) => rest;
/* eslint-enable */

export default List;
