import { useState, useEffect } from 'react';
import onError from '~/errorsHandler';

type State<Data> = {
  data?: Data;
  loading: boolean;
  error?: any;
  response?: any;
};

export type UseApiReturn<Data = any> = [State<Data>, () => void];

const useApi = <Data = any>(
  call: () => Promise<any>,
  deps: readonly any[] = []
): UseApiReturn<Data> => {
  const [state, setState] = useState<State<Data>>({ loading: true });

  const query = () => {
    call()
      .then((response) => {
        setState({
          data: response?.data,
          loading: false,
          response,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          error,
        });
        onError(error);
      });
  };

  useEffect(() => {
    query();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, query];
};

export default useApi;
