import palette from '../palette';

export default {
  toolbar: {
    borderTop: `1px solid ${palette.divider}`,
    backgroundColor: palette.background.table,
  },
  select: {
    paddingTop: 7,
    paddingBottom: 5,
  },
};
