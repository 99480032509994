import axios from '~/utils/axios';

export const fetchChangelogById = (resource: string, profileId: string, changelogId: number | undefined) => {
  return axios
    .get(`/${resource}/${profileId}/changelog/${changelogId?.toString()}`)
    .then((res) => res.data);
};

export const fetchChangelogs = (resource: string, profileId: string) => {
  return axios
    .get(`/${resource}/${profileId}/changelog`)
    .then((res) => res.data);
};
