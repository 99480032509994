import { Employee } from './../types/employees';
import { Business } from './../types/businesses';
import axios from '~/utils/axios';

export const getBusinessById = (businessId: string) => {
  return axios.get<Business>(`/businesses/${businessId}`).then((res) => res.data);
};

export const getBusinessEmployess = (businessId: string) => {
  return axios
    .get<Employee[]>(`/businesses/${businessId}/employees?page=1&perPage=999`)
    .then((res) => res.data);
};

export const blockBusiness = (params: { id: string; note: string }) => {
  const { id, note } = params;
  return axios
    .post(`/businesses/${id}/block`, {
      note,
    })
    .then((res) => res.data);
};

export const unblockBusiness = (params: { id: string; note: string }) => {
  const { id, note } = params;
  return axios
    .post(`/businesses/${id}/unblock`, {
      note,
    })
    .then((res) => res.data);
};
