import React from 'react';

import {
  Toolbar,
  SaveButton,
  DeleteButton,
  EditController,
  SimpleForm,
  TextInput,
  Query,
  Loading,
  usePermissions,
} from 'react-admin';
import { required } from 'react-admin';

import { EditView } from '~/layout';
import { withStyles } from '@material-ui/core';
import JsonSchemaForm from './jsonschema/Form';
import { NoPermissions } from '~/components/ra';
import cache from './cache';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const ConfigurationEditToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton
      mutationMode="optimistic"
      redirect={`/solutions/${props.record && props.record.solutionId}/show/configurations`}
    />
  </Toolbar>
));

/* eslint-disable */
const sanitizeControllerProps = ({ loaded, loading, defaultTitle, ...rest }) => rest;
/* eslint-enable */

const ConfigurationEdit = (props) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('solution.settings.update')) return <NoPermissions />;
  return (
    <EditController {...props} undoable={false}>
      {(controllerProps) => {
        return (
          <EditView {...props} {...controllerProps}>
            {controllerProps.record &&
              (cache.lastSchema && controllerProps.record.solutionId === cache.lastSolutionId ? (
                <SimpleForm
                  {...sanitizeControllerProps(controllerProps)}
                  redirect="show"
                  toolbar={<ConfigurationEditToolbar />}
                >
                  <TextInput source="parentId" disabled />
                  <TextInput source="name" validate={required()} />
                  <JsonSchemaForm
                    schema={cache.lastSchema}
                    edit={true}
                    source="data"
                    {...sanitizeControllerProps(controllerProps)}
                  />
                </SimpleForm>
              ) : (
                <Query
                  type="getSolutionConfigurationSchema"
                  resource="solutionConfigurationSchema"
                  payload={{
                    id: controllerProps.record.solutionId,
                    resource: controllerProps.resource,
                  }}
                >
                  {({ data, loading, error }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (error) {
                      return <p>Failed to load solution configuration schema</p>;
                    }
                    return (
                      <SimpleForm
                        {...sanitizeControllerProps(controllerProps)}
                        redirect="show"
                        toolbar={<ConfigurationEditToolbar />}
                      >
                        <TextInput source="name" validate={required()} />
                        <JsonSchemaForm
                          schema={data.configurationSchema}
                          edit={true}
                          source="data"
                          {...sanitizeControllerProps(controllerProps)}
                        />
                      </SimpleForm>
                    );
                  }}
                </Query>
              ))}
          </EditView>
        );
      }}
    </EditController>
  );
};

export default ConfigurationEdit;
