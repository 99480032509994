import moment from 'moment';

const getDivider = (url: string) => {
  return url.includes('?') ? '&' : '?';
};

const urlWithParams = <P = any>(url: string, params: P, dateFormat?: string) => {
  if (Object.values(params)) {
    for (let key in params) {
      const value = params[key];
      if (value) {
        if (value instanceof moment) {
          url += `${getDivider(url)}${key}=${encodeURIComponent(
            (value as any).format(dateFormat)
          )}`;
          continue;
        }
        if (Array.isArray(value)) {
          // eslint-disable-next-line no-loop-func
          value.forEach((item) => {
            url += `${getDivider(url)}${key}=${encodeURIComponent(item + '')}`;
          });
          continue;
        }
        url += `${getDivider(url)}${key}=${encodeURIComponent(value + '')}`;
      }
    }
  }

  return url;
};

export default urlWithParams;
