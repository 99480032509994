import React, { FC } from 'react';
import { Datagrid, Filter, TextInput, usePermissions, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { List } from '~/layout';
import { DateTimeInput, NoPermissions, StatusField } from '~/components/ra';
import { useExternalProfileLinkTranslate } from '.';
import ProfileField from './components/ProfileField';
import ReferenceIdField from './components/ReferenceIdField';
import ListDateTimeField from './components/ListDateTimeField';
import { SelectInput } from 'react-admin';
import { useExternalProfileLinkSystems } from '~/hooks/externalProfileLinks';

const ExternalProfileLinkList: FC<any> = (props) => {
  const t = useExternalProfileLinkTranslate();

  const { permissions } = usePermissions();

  const styles = useStyles();

  const { data: externalProfileLinkSystems, isLoading } = useExternalProfileLinkSystems();

  if (!permissions) return null;
  if (!permissions?.includes('externalProfileLink.list')) return <NoPermissions />;

  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={
        <Filter style={{ maxWidth: 1060 }}>
          <SelectInput
            label={t('filter.profileType')}
            source="profileType"
            choices={[
              { id: 'INDIVIDUAL', name: t('profileTypeInput.INDIVIDUAL') },
              { id: 'BUSINESS', name: t('profileTypeInput.BUSINESS') },
            ]}
            alwaysOn
            allowEmpty
            emptyText={t('profileTypeInput.any')}
            style={filterInputStyle}
          />
          <TextInput
            label={t('filter.profileId')}
            source="profileId"
            alwaysOn
            style={filterInputStyle}
          />
          <SelectInput
            label={t('filter.system')}
            source="externalSourceSystem"
            choices={(externalProfileLinkSystems || []).map((system) => ({
              id: system,
              name: system,
            }))}
            alwaysOn
            allowEmpty
            emptyText={t('systemInput.any')}
            style={filterInputStyle}
            disabled={isLoading}
          />
          <TextInput
            label={t('filter.referenceId')}
            source="referenceId"
            alwaysOn
            style={filterInputStyle}
          />
          <SelectInput
            label={t('filter.status')}
            source="status"
            choices={[
              { id: 'ACTIVE', name: t('statusInput.ACTIVE') },
              { id: 'CLOSED', name: t('statusInput.CLOSED') },
              { id: 'NOT_READY', name: t('statusInput.NOT_READY') },
              { id: 'REJECTED', name: t('statusInput.REJECTED') },
              { id: 'REQUESTED', name: t('statusInput.REQUESTED') },
            ]}
            alwaysOn
            allowEmpty
            emptyText={t('statusInput.any')}
            style={{ minWidth: 125 }}
          />
          <DateTimeInput
            source="createdFrom"
            label={t('filter.createdFrom')}
            options={{ format: 'LL LT', clearable: true }}
            fullWidth
            alwaysOn
            className={styles.dateTimeInput}
          />
          <DateTimeInput
            source="createdTo"
            label={t('filter.createdTo')}
            options={{ format: 'LL LT', clearable: true }}
            fullWidth
            alwaysOn
            className={styles.dateTimeInput}
          />
          <DateTimeInput
            source="updatedFrom"
            label={t('filter.updatedFrom')}
            options={{ format: 'LL LT', clearable: true }}
            fullWidth
            alwaysOn
            className={styles.dateTimeInput}
          />
          <DateTimeInput
            source="updatedTo"
            label={t('filter.updatedTo')}
            options={{ format: 'LL LT', clearable: true }}
            fullWidth
            alwaysOn
            className={styles.dateTimeInput}
          />
        </Filter>
      }
      // actions={<ApiIdentitiesActions {...props} />}
    >
      <Datagrid rowClick="show" size="medium" selectedIds={null}>
        <ProfileField label={t('fields.profile')} />
        <TextField label={t('fields.system')} source="externalSourceSystem" sortable={false} />
        <ReferenceIdField label={t('fields.referenceId')} />
        <StatusField source="status" sortable={false} />
        <ListDateTimeField label={t('fields.created')} source="created" sortable={false} />
        <ListDateTimeField label={t('fields.updated')} source="updated" sortable={false} />
      </Datagrid>
    </List>
  );
};

const filterInputStyle = { minWidth: 212 };

const useStyles = makeStyles(() => ({
  dateTimeInput: {
    minWidth: 212,
  },
}));

export default ExternalProfileLinkList;
