import React, { FC, useState } from 'react';
import { useTranslate, usePermissions } from 'react-admin';

import { EditButton } from '~/components';
import EditDrawer from './EditDrawer';

export const useTranslateDealDetails = () => {
  const translate = useTranslate();
  return (key: string): string => translate(`modules.editDealDetails.${key}`);
};

const EditDealDetails: FC<any> = (props) => {
  const { record } = props;
  const { permissions } = usePermissions();
  const t = useTranslateDealDetails();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!permissions) return null;
  if (!permissions.includes('deal.update')) return null;

  return (
    <>
      <EditDrawer open={open} onClose={handleClose} record={record} />
      <EditButton label={t('editWithReporting')} onClick={handleOpen} />
    </>
  );
};

export default EditDealDetails;
