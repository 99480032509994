import { EventReferenceEntity } from '~/types/Event';
import { EntitiesIdRequest, getEntities } from '~/api/events';
import { groupBy } from 'lodash';
import Dataloader from 'dataloader';
import { Entity } from '~/types/Entity';
import { useQuery } from 'react-query';
import { queryClient } from '~/App';

const groupEventEntitiesIds = (array: readonly EventReferenceEntity[]): EntitiesIdRequest => {
  const entitiesGroup = groupBy(array, 'type');
  return Object.entries(entitiesGroup).reduce(
    (curr, [key, entry]) => ({
      ...curr,
      [key]: [...new Set(entry.map(({ id }) => id))],
    }),
    {}
  );
};

const fetchCharacters = async (
  entitiesRefs: readonly EventReferenceEntity[]
): Promise<Entity[]> => {
  const entitiesGroupIds = groupEventEntitiesIds(entitiesRefs);
  const { data: entities } = await getEntities(entitiesGroupIds);
  return entitiesRefs.map(({ id }) => entities[id] ?? null);
};

const BATCH_DELAY_MS = 500;
const dataloader = new Dataloader<EventReferenceEntity, Entity>(fetchCharacters, {
  cache: false,
  batchScheduleFn: (callback) => setTimeout(callback, BATCH_DELAY_MS),
});

const useEventEntities = () => {
  return dataloader;
};

const key = 'eventEntity';
const useEventEntity = (record: EventReferenceEntity, isEnabled: boolean = true) => {
  const entityLoader = useEventEntities();
  const { id, type } = record;
  return useQuery<Entity>([key, id, type], () => entityLoader.load(record), {
    staleTime: Infinity,
    enabled: isEnabled,
    onError: () => {},
  });
};

export const invalidateEventEntities = () => {
  queryClient.invalidateQueries(key);
};

export default useEventEntity;
