import raMessages from 'ra-language-english';

import { default as countries } from '@umpirsky/country-list/data/en/country.json';
import { default as currencies } from './currency.json';

import home from './home';
import days from './days';
import eventProcessingState from './eventProcessingState';
import jsonSchema from './jsonSchema';
import jsonSchemaValidation from './jsonSchemaValidation';
import layout from './layout';
import months from './months';
import operationType from './operationType';
import resources from './resources';
import components from './components';
import utils from './utils';
import modules from './modules';
import locales from './locales';
import dealsStatus from './dealsStatus';

export default {
  ...raMessages,
  countries,
  currencies: {
    ...currencies,
    GPT: 'Generic Points',
  },
  layout,
  locales,
  resources,
  jsonSchemaValidation,
  jsonSchema,
  days,
  eventProcessingState,
  months,
  home,
  components,
  operationType,
  utils,
  modules,
  Amount: 'Amount',
  Currency: 'Currency',
  dealsStatus,
};
