import { useDispatch } from 'react-redux';
import { notification } from '~/ducks/snackbarNotifications';

interface Props {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  duration?: number;
}

const useNotify = () => {
  const dispatch = useDispatch();
  return ({ type, message, duration = 2000 }: Props) =>
    dispatch(notification({ severity: type, message, autoHideDuration: duration }));
};

export default useNotify;
