export default {
  showHeading: 'Current currency conversion rates',
  currencyConversionSetup: 'Currency conversion setup',
  // currencyConversionRates: 'Currency conversion rates',
  currencies: 'Currencies',
  alphabeticCode: 'Alphabetic code',
  currencyName: 'Currency Name',
  empty: 'Currency list is empty yet',
  currencyPairs: 'Currency pairs',
  pair: 'Pair',
  addTable: 'Add table',
  fxRateTablesConfiguration: 'FX rate tables configuration',
  configureFXRateTable: 'Configure FX rate table',
  // TableForm
  key: 'Key / Code',
  form: {
    name: 'Name',
  },
  useForFxProcessing: 'Use for FX processing',
  forFxProcessing: '(for FX processing)',
  ratesSourceSystem: 'Rates source system',
  MANUAL: 'Manual',
  settlementSystem: 'Settlement system',
  crossCurrencyPairs: 'Cross currency pairs',
  buyAndSellRates: 'Buy and sell rates, margin config',
  noPairsTableForm: 'No data. First fill "CURRENCIES" tab',
  buyRateSource: 'Buy rate source',
  sellRateSource: 'Sell rate source',
  INPUT: 'Input',
  CALCULATION: 'Calculation',
  MID_RATE: 'Mid rate',
  buyMargin: 'Buy margin (0-1)',
  sellMargin: 'Sell margin (0-1)',
  fallbackBuyMargin: 'Fallback buy margin (0-1)',
  fallbackSellMargin: 'Fallback sell margin (0-1)',
  buyMarginNotManual: 'Buy margin (0-1)',
  sellMarginNotManual: 'Sell margin (0-1)',
  profitAccountId: 'Conversion profit account',
  tradingAccountId: 'Trading account',
  currencyConversionPairs: 'Currency conversion pairs',
  baseCurrency: 'Base currency',
  mid: 'Mid',
  buy: 'Buy (bid)',
  sell: 'Sell (ask)',
  lastUpdate: 'Last update',
  sourceBuyRate: 'Source buy rate',
  sourceSellRate: 'Source sell rate',
  fxProcessing: 'FX processing',
  bestRatesOf: 'Best rates of',
  firstTable: 'First table',
  secondTable: 'Second table',
  intermediateCurrency: 'Intermediate currency',
  noTables: 'No data. First add FX rate tables.',
  settlement: 'Settlement',
  settlementAccount: 'Settlement account',
  autoSettlement: 'Auto-settlement',
  autoCalculationFxProfit: 'Auto-calculation of FX profit',
  // FX mapping
  createRatesMapping: 'Create rates mapping',
  editRatesMapping: 'Edit rates mapping',
  solutionId: 'Solution',
  productId: 'Product',
  ratesId: 'Related rate',
  fallbackRatesId: 'Fallback rate',
  operationTypes: 'Operation type',
  tags: 'Tags',
  kycLevels: 'KYC levels',
  fxPlan: 'FX plan',
  tabs: {
    currencies: 'Currencies',
    fxRateTables: 'FX rate tables',
    fxPlans: 'FX plans',
  },
};
