import React, { Fragment, useState, useEffect, FC } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { time } from '~/utils';

import { SelectInput } from '~/components';
import { selectors, filterChange } from '~/ducks/virtualizedList';
import { resource } from '~/resources/auditLog/AuditLogList';
import { Moment } from '~/types';
import { useMutableCallback } from '~/hooks';
import { AuditLogFilters } from '~/api/auditLog';

interface Props {
  defaultFilters: AuditLogFilters;
}

const AuthLogFilters: FC<Props> = (props) => {
  const { defaultFilters } = props;
  const filters = useSelector(selectors.getFilters(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      timeframe: '',
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const setDefaultFilters = useMutableCallback(() => {
    setLocalValues(defaultFilters);
    dispatch(filterChange({ resource, filters: defaultFilters }));
  });
  useEffect(() => {
    setDefaultFilters();
  }, [setDefaultFilters]);

  const handleTimeframeChange = (e: any) => {
    const { name, value } = e.target;
    const newState = {
      [name]: value,
      fromTimestamp: value && time().subtract(value, 'minutes'),
      toTimestamp: value && time(),
    };
    setLocalValues({
      ...localValues,
      ...newState,
    });
    dispatch(
      filterChange({
        resource,
        filters: newState,
      })
    );
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.auditLog.${key}`);

  return (
    <Fragment>
      {filters && (
        <div className={classes.root}>
          <div className={classes.container}>
            <SelectInput<number>
              label={t('timeframe')}
              name="timeframe"
              value={localValues.timeframe || ''}
              className={classes.timeframe}
              options={[
                { value: 5, text: 'Last 5 minutes' },
                { value: 30, text: 'Last 30 minutes' },
                { value: 6 * 60, text: 'Last 6 hours' },
                { value: 24 * 60, text: 'Last 24 hours' },
                { value: 3 * 24 * 60, text: 'Last 3 days' },
                { value: 7 * 24 * 60, text: 'Last 7 days' },
                { value: 2 * 7 * 24 * 60, text: 'Last 2 weeks' },
              ]}
              onChange={handleTimeframeChange}
              allowEmpty
            />
            <DateTimePicker
              className={classes.dateTimePicker}
              format="L - LT"
              margin="normal"
              label={t('fromTimestamp')}
              value={localValues.fromTimestamp || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'fromTimestamp')}
              maxDate={localValues.toTimestamp || time().format()}
            />
            <DateTimePicker
              className={classes.dateTimePicker}
              format="L - LT"
              margin="normal"
              label={t('toTimestamp')}
              value={localValues.toTimestamp || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'toTimestamp')}
              maxDate={time().format()}
              minDate={localValues.fromTimestamp}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1),
      flexGrow: 1,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  dateTimePicker: {
    maxWidth: 230,
  },
  timeframe: {
    maxWidth: 200,
  },
}));

export default AuthLogFilters;
