import { useQuery } from 'react-query';
import { getEmployeesRoles, getEmployeesRolesTranslations } from '~/api/employees';

const useEmployeeRoles = (solutionId: string) => {
  return useQuery(['employeeRole', solutionId], () => getEmployeesRoles(solutionId), {
    staleTime: Infinity,
  });
};

export const useEmployeeRolesTranslations = () => {
  return useQuery(['employeeRoleTranslations'], () => getEmployeesRolesTranslations(), {
    staleTime: Infinity,
    select: (data) => {
      return Object.entries(data).reduce<Record<string, string>>((acc, currentValue) => {
        const [key, value] = currentValue;
        const newKey = key.split('.').pop();
        return newKey ? { ...acc, [newKey]: value } : { ...acc };
      }, {});
    },
  });
};

export default useEmployeeRoles;
