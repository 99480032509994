import React, { FC, Fragment } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { Title, VirtualizedList } from '~/components';
import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ActionReport, RootState } from '~/types';
import {
  selectors,
  ResourceListType,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column } from '~/components/VirtualizedList';
import { getActionReports } from '~/api/actionReports';
import CustomCell from './CustomCell';
import Filters from './Filters';
import { NoPermissions } from '~/components/ra';

export const resource = 'actionReports';

const ActionReportsList: FC<any> = (props) => {
  const { history } = props;
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const { data, hasNextPage, isNextPageLoading } = useSelector<
    RootState,
    ResourceListType<ActionReport>
  >(selectors.getList(resource));
  const filters = useSelector(selectors.getFilters(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);

  const loadNextPage = async () => {
    if (isNextPageLoading) return;
    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } = await getActionReports({
        next: cursors.next || undefined,
        limit: 100,
        ...filters,
      });
      dispatch(
        getVirtualizedListSuccess({
          resource,
          responseData: { cursors: data.cursors, records: data.reports },
          queryToken,
        })
      );
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column<ActionReport>[] = [
    {
      selectCellData: (rowData) => rowData.created,
      cellDataWrapperComponent: CustomCell,
      id: 'created',
      header: t('created'),
      style: {
        flexBasis: 100,
        minWidth: 100,
      },
    },
    {
      selectCellData: (rowData) => rowData.objectType,
      cellDataWrapperComponent: CustomCell,
      id: 'objectType',
      header: t('reportableObject'),
      style: {
        flexBasis: 300,
        // minWidth: 370,
      },
    },
    {
      selectCellData: (rowData) => rowData.system,
      cellDataWrapperComponent: CustomCell,
      id: 'system',
      header: t('system'),
      style: {
        flexBasis: 175,
        minWidth: 175,
      },
    },
    {
      selectCellData: (rowData) => rowData.reportType,
      cellDataWrapperComponent: CustomCell,
      id: 'reportType',
      header: t('reportType'),
      style: {
        flexBasis: 200,
      },
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: CustomCell,
      id: 'status',
      header: t('status'),
      style: {
        flexBasis: 100,
        minWidth: 100,
      },
    },
    {
      selectCellData: (rowData) => rowData.updated,
      cellDataWrapperComponent: CustomCell,
      id: 'updated',
      header: t('updated'),
      style: {
        flexBasis: 100,
        minWidth: 100,
        // maxWidth: 150,
      },
    },
    {
      selectCellData: (rowData) => rowData.hasWarnings,
      cellDataWrapperComponent: CustomCell,
      id: 'hasWarnings',
      header: t('hasWarnings'),
      style: {
        flexBasis: 100,
        // minWidth: 120,
        // maxWidth: 150,
      },
    },
  ];

  if (!permissions) return null;
  if (!permissions.includes('report.list')) return <NoPermissions />;

  return (
    <Fragment>
      <Title resource={resource} />
      <Filters />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<ActionReport>
          resource={resource}
          columns={columns}
          data={data}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          loadNextPage={loadNextPage}
          emptyText={t('empty')}
          filters={filters}
          onRowClick={({ id }) => history.push(`/${resource}/${id}/show`)}
          outterTable
          rowHeight={48}
        />
      </Paper>
    </Fragment>
  );
};

const fixBottomShadow = { paddingBottom: 2 };

export default ActionReportsList;
