import React, { FC } from 'react';
import { Toolbar as RaTooolbar } from 'react-admin';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  className?: string;
  [x: string]: any;
}

const Toolbar: FC<Props> = (props) => {
  const { className, children, ...rest } = props;
  const classes = useStyles();

  return (
    <RaTooolbar className={cx(classes.toolbar, className)} {...rest}>
      {children}
    </RaTooolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(2),
    minHeight: 'auto',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default Toolbar;
