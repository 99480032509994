import * as React from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId, validateRequired } from './utils';

const GenericRecordTable = ({
  handbook,
  setState,
  property,
}: {
  handbook: Partial<hb.Handbook>;
  setState: hb.SetState;
  property: keyof hb.Handbook;
}) => {
  const records: hb.GenericRecord[] = ((handbook[property] as hb.GenericRecord[]) || []).slice();

  const isValid = (
    newRecord: hb.GenericRecord,
    oldRecord: hb.GenericRecord | undefined = undefined
  ) =>
    !validateId(records, newRecord.id, (oldRecord || {}).id, true) &&
    !validateRequired(records, newRecord.name);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'ID',
          field: 'id',
          required: true,
          validate: validateId,
        },
        {
          title: 'Name',
          field: 'name',
          required: true,
          validate: validateRequired,
        },
      ]}
      components={{
        Container: (props: any) => <Paper variant="outlined">{props.children}</Paper>,
      }}
      data={handbook[property]}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newRecord: hb.GenericRecord) => {
            if (isValid(newRecord)) {
              records.push(newRecord);
              return setState({ [property]: records });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newRecord: hb.GenericRecord, oldRecord: hb.GenericRecord) => {
            if (isValid(newRecord, oldRecord)) {
              const index = records.indexOf(oldRecord);
              records[index] = newRecord;
              return setState({ [property]: records });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldRecord: hb.GenericRecord) => {
            const index = records.indexOf(oldRecord);
            records.splice(index, 1);
            return setState({ [property]: records });
          },
        }
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
      }}
    />
  );
};

export default GenericRecordTable;
