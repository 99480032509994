import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  blockBusiness,
  getBusinessById,
  getBusinessEmployess,
  unblockBusiness,
} from '~/api/business';

const businessByIdKey = (id: string) => ['business', id];
const employeesKey = (businessId: string) => businessByIdKey(businessId!).concat(['employees']);

export const useBusinessById = (id: string | undefined) => {
  return useQuery(businessByIdKey(id!), () => getBusinessById(id!), {
    enabled: !!id,
  });
};

export const useBusinessEmployess = (businessId: string | undefined) => {
  return useQuery(employeesKey(businessId!), () => getBusinessEmployess(businessId!), {
    enabled: !!businessId,
  });
};

export const useBlockBusinessMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((note: string) => blockBusiness({ id, note }), {
    onSuccess() {
      queryClient.invalidateQueries(businessByIdKey(id));
    },
  });
};

export const useUnblockBusinessMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((note: string) => unblockBusiness({ id, note }), {
    onSuccess() {
      queryClient.invalidateQueries(businessByIdKey(id));
    },
  });
};
