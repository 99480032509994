import React, { FC } from 'react';
import { Coords } from 'google-map-react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

import { MarkerIcon } from '~/img';

interface MapMarkerProps extends Coords {
  onClick?: () => void;
  name?: string;
  className?: string;
}

const MapMarker: FC<MapMarkerProps> = (props) => {
  const { onClick, name = '', className } = props;
  return (
    <Tooltip placement="top" title={name}>
      <MarkerIcon className={className} onClick={onClick} />
    </Tooltip>
  );
};
const defaultMarkerSize = 36;
const defaultMarkerLeft = defaultMarkerSize / 2;
const scaleOnHover = 1.5;

export default styled(MapMarker)`
  position: absolute;
  left: -${defaultMarkerLeft}px;
  font-size: ${defaultMarkerSize}px;
  bottom: 0;
  cursor: pointer;
  transition: all ease 100ms;
  &:hover {
    left: -${defaultMarkerLeft * scaleOnHover}px;
    font-size: ${defaultMarkerSize * scaleOnHover}px;
  }
`;
