import React from 'react';
import ReportsIcon from '@material-ui/icons/Assessment';
import { ReaderIcon as TransactionIcon } from '~/img';
import { ActionReport } from '~/types';
import SvgIcon from '@material-ui/core/SvgIcon';

const getObjectTypeIcon = (objectType: ActionReport['objectType'] | undefined, props: any = {}) => {
  switch (objectType) {
    // case 'BUSINESS':
    //   return <BusinessesIcon {...props} />;
    // case 'INDIVIDUAL':
    //   return <IndividualsIcon {...props} />;
    // case 'PARTNER':
    //   return <IndividualsIcon {...props} />;
    case 'TRANSACTION':
    case 'HEAD_TRANSACTION':
      return (
        <SvgIcon>
          <TransactionIcon {...props} />
        </SvgIcon>
      );
    default:
      return <ReportsIcon {...props} />;
  }
};

export default getObjectTypeIcon;
