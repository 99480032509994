import { useTranslate } from 'react-admin';
import { Localizer } from './Localizer';

const useLocalizer = (resource: string): Localizer => {
  const translate = useTranslate();
  return {
    getTitle: (key) => {
      if (key === '') {
        return null;
      }
      const translateKey = `resources.${resource}${key}._title`;
      const translation = translate(translateKey);
      return translation; // === translateKey ? null : translation;
    },
    getDescription: (key) => {
      const translateKey = `resources.${resource}${key}._description`;
      const translation = translate(translateKey);
      return translation === translateKey ? null : translation;
    },
    getFieldPrefix: (key) => {
      return `resources.${resource}${key}`;
    },
  };
};

export default useLocalizer;
