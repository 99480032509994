import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import { updateBeneficiaryData } from '~/api/dealBeneficiary';
import { crudGetOne } from 'react-admin';

export const useBeneficiaryDataMutation = (params: {
  dealId: string;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
}) => {
  const { dealId, counterparty } = params;
  const dispatch = useDispatch();
  return useMutation(async (data: any) => {
    await updateBeneficiaryData({ dealId, counterparty, data });
    dispatch(crudGetOne('deals', dealId, '/deals'));
  });
};
