import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import VirtualizedList, { Column } from '~/components/VirtualizedList';
import {
  selectors,
  ResourceListType,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { RootState, AuditLogType } from '~/types';
import { AuditLogFilters, getAuditLog } from '~/api/auditLog';
import ActionShow from './ActionShow';
import CustomCell from './CustomCell';
import { cleanupFormValues } from '~/utils';

export const resource = 'auditLog';

interface Props {
  filters?: AuditLogFilters;
  outterTable?: boolean;
}

const AuditLogTable: FC<Props> = (props) => {
  const { filters = {}, outterTable = false } = props;
  const dispatch = useDispatch();
  const { data, hasNextPage, isNextPageLoading } = useSelector<
    RootState,
    ResourceListType<AuditLogType>
  >(selectors.getList(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.${key}`);

  const columns: Column<AuditLogType>[] = [
    {
      selectCellData: (rowData) => rowData.timestamp,
      cellDataWrapperComponent: CustomCell,
      id: 'timestamp',
      header: t('timestamp'),
      style: {
        flexBasis: 235,
        minWidth: 200,
      },
    },
    {
      selectCellData: (rowData) => rowData.profileId,
      cellDataWrapperComponent: CustomCell,
      id: 'profileId',
      header: t('profileId'),
      width: 90,
    },
    {
      selectCellData: (rowData) => rowData.message,
      cellDataWrapperComponent: CustomCell,
      id: 'action',
      header: t('action'),
      style: {
        flexBasis: 700,
        minWidth: 120,
      },
    },
    {
      selectCellData: (rowData) => rowData.identityName,
      cellDataWrapperComponent: CustomCell,
      id: 'actor',
      header: t('actor'),
      style: {
        flexBasis: 235,
        minWidth: 180,
      },
    },
    {
      selectCellData: (rowData) => rowData.identityType,
      cellDataWrapperComponent: CustomCell,
      id: 'channel',
      header: t('channel'),
      style: {
        flexBasis: 150,
        minWidth: 100,
      },
    },
  ];

  const isEmptyFilters = isEmpty(cleanupFormValues(filters, 'hard'));

  const loadNextPage = async () => {
    if (isEmptyFilters || isNextPageLoading) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } = await getAuditLog({ next: cursors.next, limit: 100, ...filters });
      dispatch(getVirtualizedListSuccess({ resource, responseData: data, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const [showData, setShowData] = useState<AuditLogType | null>(null);
  const handleShowOpen = (data: AuditLogType) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  const classes = useStyles({ outterTable });

  return (
    <>
      <ActionShow showData={showData} onClose={handleShowClose} />
      <Paper classes={classes}>
        <VirtualizedList<AuditLogType>
          resource={resource}
          columns={columns}
          data={data}
          filters={filters}
          hasNextPage={isEmptyFilters ? false : hasNextPage}
          isNextPageLoading={isNextPageLoading}
          loadNextPage={loadNextPage}
          emptyText={isEmptyFilters && t('empty')}
          onRowClick={handleShowOpen}
          outterTable={outterTable}
        />
      </Paper>
    </>
  );
};

const fixBottomShadow = { paddingBottom: 2 };

const useStyles = makeStyles<Theme, { outterTable: boolean }>((theme) => ({
  root: {
    ...fixBottomShadow,
    backgroundColor: ({ outterTable }) =>
      outterTable ? undefined : (theme.palette.background as any).table,
  },
}));

export default AuditLogTable;
