import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { time } from '~/utils';

import { CurrencyAutocomplete } from '~/components';
import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { DealSearchFilterDto } from '~/api/backgroundTasks';
import { cleanupFormValues } from '~/utils';
import { OperationType } from '~/types/OperationType';
import { SelectTransactionType, SelectDealsStatus } from '~/components/TransactionsHistory/Filters';

//--------------------------------------------
const resource = 'deals';

interface Props {
  cleanFilters?: boolean;
}

const Filters = (props: Props) => {
  const { cleanFilters = true } = props;
  const filters = useSelector(selectors.getFilters<DealFilters>(resource));
  const [localValues, setLocalValues] = useState<DealFilters>(filters);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const t = (key: string): string => translate(`resources.deals.list.filters.${key}`);

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 500), []);

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    debouncedDispatchFilterChange(inputType, data);
  };
  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };

  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const filtersAreEmpty = isEmpty(filters);
  useEffect(() => {
    if (cleanFilters || filtersAreEmpty) {
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            createdFrom: time().subtract(1, 'months'),
            createdTo: time(),
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty]);

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div>
            <div className={classes.inputsGroup}>
              <DateTimePicker
                className={classes.datePickers}
                format="L - LT"
                margin="normal"
                label={t('from')}
                value={localValues.createdFrom}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdFrom')}
              />
              <DateTimePicker
                className={classes.datePickers}
                format="L - LT"
                margin="normal"
                label={t('to')}
                value={localValues.createdTo}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdTo')}
              />
              <SelectTransactionType
                name="operationType"
                value={localValues.operationType || ''}
                onChange={handleSelectInput}
                className={classes.formControl}
              />
              <TextField
                label={t('senderAccountNumber')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.senderAccountNumber ?? ''}
                name="senderAccountNumber"
              />
              <TextField
                label={t('recipientAccountNumber')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.recipientAccountNumber ?? ''}
                name="recipientAccountNumber"
              />
              <TextField
                label={t('amountFrom')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.amountFrom ?? ''}
                name="amountFrom"
              />
              <TextField
                label={t('amountTo')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.amountTo ?? ''}
                name="amountTo"
              />
              <CurrencyAutocomplete
                label={t('currency')}
                className={classes.formControl}
                onChange={debouncedHandleInput}
                value={localValues.currency}
                name="currency"
              />
              <SelectDealsStatus
                name="status"
                value={localValues.status || ''}
                onChange={handleSelectInput}
                className={classes.formControl}
              />
            </div>
          </div>
          <div>
            <BackgroundTaskExport
              type="DEAL_EXPORT"
              queryArguments={handleFilters(localValues)}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

type DealFilters = {
  operationType?: OperationType;
  createdFrom?: Moment | null;
  createdTo?: Moment | null;
  senderAccountNumber?: string;
  recipientAccountNumber?: string;
  amountFrom?: number;
  amountTo?: number;
  currency?: string;
  status?: string;
};

const handleFilters = (filters: DealFilters): DealSearchFilterDto => {
  if (!filters) return {};
  const { createdFrom, createdTo, currency, amountFrom, amountTo, ...rest } = filters;
  return cleanupFormValues({
    createdFrom: handleDate(createdFrom),
    createdTo: handleDate(createdTo),
    beneficiaryAmountCurrency: currency,
    beneficiaryAmountFrom: amountFrom,
    beneficiaryAmountTo: amountTo,
    ...rest,
  });
};

const handleDate = (date?: Moment | null) => {
  if (!date) return undefined;
  return date.toISOString();
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 200,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default Filters;
