import React, { FC, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import { time } from '~/utils';
import cx from 'classnames';
import DeliveryAddressIcon from '@material-ui/icons/ContactMail';

import {
  AmountField,
  H2,
  AccordionSummary,
  AccordionDetails,
  TextField,
  StatusField,
  AddressTable,
} from '~/components';
import CardCreate from './CardCreate';
import { useApi } from '~/hooks';
import { getCardNumber, getCards, getCardsTypes, getDetails } from '~/api/cards';
import { Card, CardDetails, CardType } from '~/types/Card';
import { Account, ExternalSource, Individual } from '~/types';
import { detachKeys } from '~/utils';
import CardImage from './CardImage';
import CardEdit from './CardEdit';
import CardEditStatus from './CardEditStatus';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import msk from 'msk';
import CardReissue from './CardReissue';
import CardChangePin from './CardChangePin';
import onError from '~/errorsHandler';
import { useGetAllAccounts } from '~/hooks/accounts';
import { isEmpty } from 'lodash';
import H3 from '~/components/H3';
import { Section } from '~/layout';
import CardEditDeliveryAddress from './CardEditDeliveryAddress';
import { useGetAllExternalSources } from '~/hooks/externalSources';

export const l = (key: string): string => `components.ra.Cards.${key}`;
export const useTCards = () => {
  const translate = useTranslate();
  return (key: string): string => translate(l(key));
};

interface CardsProps {
  solutionId: string | undefined;
  profileId: string;
  resource: 'individuals' | 'businesses';
  record?: Individual;
}

const Cards: FC<CardsProps> = (props) => {
  const { resource, solutionId, profileId, record } = props;

  const classes = useStyles();
  const [{ data, loading }, refetch] = useApi<Card[]>(getCards(resource, profileId));
  const { data: accountsData, isLoading: accountsLoading } = useGetAllAccounts({
    resource,
    profileId,
  });
  const { data: externalSourcesData, isLoading: externalSourcesLoading } = useGetAllExternalSources(
    {
      resource,
      profileId,
    }
  );

  const [{ data: cardsTypes, loading: cardsTypesLoading }] = useApi<CardType[]>(getCardsTypes);

  const [accountsDict] = detachKeys<Account>(accountsData);
  const [externalSourcesDict] = detachKeys<ExternalSource>(externalSourcesData);

  const [cardsTypesDict] = detachKeys<CardType>(cardsTypes);
  const [
    { data: cardNumbers, loading: cardNumberLoading },
    showCardNumber,
    hideCardNumber,
  ] = useCardNumber();
  const t = useTCards();

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t(`cards`)}
        </H2>
        <div id="section-actions">
          {false && solutionId && (
            <CardCreate
              resource={resource}
              solutionId={solutionId!}
              profileId={profileId}
              refetch={refetch}
            />
          )}
        </div>
      </header>
      {(data?.length || 0) === 0 ? (
        <div>No cards found.</div>
      ) : (
        data?.map((item) => {
          const {
            id,
            alias,
            amount,
            maskedPAN,
            lastFour,
            accountId,
            externalSourceId,
            cardholderName,
            status,
            expirationDate,
            cardType,
            paymentSystem,
          } = item;
          const key = item.id;
          const isExpanded = Boolean(expanded[key]);
          const heading = `${alias || 'Card'} ${
            cardNumbers[id]
              ? msk(cardNumbers[id] as string, '**** **** **** ****')
              : maskedPAN || (lastFour ? `**** **** **** ${lastFour}` : '')
          }`;
          const actualAmount =
            amount ||
            (accountId &&
              accountsDict[accountId]?.balances?.find((item) => item.code === 'CURRENT_AVAILABLE')
                ?.amount);

          return (
            <Grid key={key} item xs={12}>
              <Accordion expanded={isExpanded} onChange={handleChange(key, isExpanded)}>
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={heading}
                  actionNode={
                    actualAmount ? (
                      <AmountField amount={actualAmount} style={{ fontSize: '16px' }} />
                    ) : undefined
                  }
                  content={
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={cx(classes.expansionContent, {
                        [classes.shrinkContent]: !isExpanded,
                      })}
                    >
                      <Grid item xs={4}>
                        <CardImage
                          lastFour={lastFour}
                          expirationDate={expirationDate}
                          cardholderName={cardholderName}
                          isExpanded={isExpanded}
                          cardNumber={cardNumbers[id]}
                          maskedPAN={maskedPAN}
                          isActive={status === 'ACTIVE'}
                        />
                      </Grid>
                      <Grid container xs={8} item spacing={2}>
                        <Grid item xs={6}>
                          {accountId && (
                            <TextField label={t('linkedAccount')} loading={accountsLoading}>
                              {accountsDict[accountId]?.number}
                            </TextField>
                          )}
                          {externalSourceId && (
                            <TextField
                              label={t('linkedExternalSource')}
                              loading={externalSourcesLoading}
                            >
                              {externalSourcesDict[externalSourceId]?.name}
                            </TextField>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label={t('status')} loading={loading}>
                            <StatusField status={status} />
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label={t('cardType')} loading={cardsTypesLoading}>
                            {cardType ? cardsTypesDict[cardType]?.name || cardType : cardType}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label={t('expirationDate')} loading={loading}>
                            {expirationDate &&
                              (expirationDate.length > 5
                                ? time(expirationDate).format('MM/YY')
                                : expirationDate)}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label={t('cardholderName')} loading={loading}>
                            {cardholderName}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label={t('paymentSystem')} loading={loading}>
                            {paymentSystem}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
                <AccordionDetails className={classes.cardDetails}>
                  {record?.deliveryAddress && (
                    <Section noPaddings className={classes.bottomSpacing}>
                      <Grid container spacing={2}>
                        <Grid item container xs={12} justify="space-between" alignItems="center">
                          <Grid item>
                            <H3 icon={<DeliveryAddressIcon />} bottomSpacing={0} topSpacing={0}>
                              {t('delivery')}
                            </H3>
                          </Grid>
                          <Grid item id="section-actions">
                            <CardEditDeliveryAddress record={record} resource={resource} />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <AddressTable address={record.deliveryAddress} />
                        </Grid>
                      </Grid>
                    </Section>
                  )}
                  <CardActions
                    record={item}
                    refetch={refetch}
                    showCardNumber={showCardNumber}
                    hideCardNumber={hideCardNumber}
                    cardNumberLoading={!!cardNumberLoading[id]}
                    cardNumber={cardNumbers[id]}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })
      )}
    </div>
  );
};

interface CardActionsProps {
  record: Card;
  refetch: () => void;
  showCardNumber: (id: string) => void;
  hideCardNumber: (id: string) => void;
  cardNumber: string | undefined;
  cardNumberLoading: boolean;
}

const CardActions: FC<CardActionsProps> = (props) => {
  const {
    record,
    /* refetch: refetchCard, */ showCardNumber,
    hideCardNumber,
    cardNumberLoading,
    cardNumber,
  } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [{ data: details /*, loading */ }, refetch] = useApi<CardDetails>(() =>
    getDetails(record.id)
  );
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} spacing={2} justify="flex-end">
        {false && (
          <Grid item>
            <CardReissue id={record.id} refetch={refetch} />
          </Grid>
        )}
        {details?.capabilities.changePin && (
          <Grid item>
            <CardChangePin id={record.id} refetch={refetch} />
          </Grid>
        )}
        {details?.capabilities.getCardNumber && (
          <Grid item>
            {!cardNumber ? (
              <Button
                label={t('showCardNumber')}
                disabled={cardNumberLoading}
                onClick={() => showCardNumber(record.id)}
              >
                <VisibilityIcon />
              </Button>
            ) : (
              <Button
                label={t('hideCardNumber')}
                disabled={cardNumberLoading}
                onClick={() => hideCardNumber(record.id)}
              >
                <VisibilityOffIcon />
              </Button>
            )}
          </Grid>
        )}
        {!isEmpty(details?.capabilities.setStatus) && (
          <Grid item>
            <CardEditStatus record={record} refetch={refetch} />
          </Grid>
        )}
        {details?.capabilities.update && (
          <Grid item>
            <CardEdit record={record} refetch={refetch} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useCardNumber = (): [
  { data: Record<string, string | undefined>; loading: Record<string, boolean | undefined> },
  (id: string) => void,
  (id: string) => void
] => {
  const [state, setState] = useState<Record<string, string | undefined>>({});
  const [loading, setLoading] = useState<Record<string, boolean | undefined>>({});
  const [timeoutId, setTimeoutId] = useState<number>();

  const hideNumber = (id: string) => {
    setState((prev) => ({ ...prev, [id]: undefined }));
    if (timeoutId) clearTimeout(timeoutId);
  };

  const showCardNumber = async (id: string) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    try {
      const { data } = await getCardNumber(id);
      setState((prev) => ({ ...prev, [id]: data }));
      const tId = setTimeout(() => {
        hideNumber(id);
      }, 60 * 1000);
      setTimeoutId((tId as unknown) as number);
    } catch (error) {
      onError(error);
    }
    setLoading((prev) => ({ ...prev, [id]: false }));
  };

  return [{ data: state, loading }, showCardNumber, hideNumber];
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topSpacing2: {
    marginTop: theme.spacing(3),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  expansionContent: {
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  shrinkContent: {
    height: 87,
  },
  cardDetails: {
    marginRight: theme.spacing(2),
  },
}));

export default Cards;
