import React, { FC, useEffect } from 'react';
import { SelectInput, useTranslate, required } from 'react-admin';
import { useForm } from 'react-final-form';

import { useMasterCurrency } from '~/hooks/currencies';
import { usePnlAccounts } from '~/hooks/pnlAccounts';
import { l } from '../CurrenciesList';

const ConversionProfitAccountSelect: FC<any> = () => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const masterCurrencyQuery = useMasterCurrency();
  const pnlAccountsQuery = usePnlAccounts();

  const masterCurrency = masterCurrencyQuery.data?.code;
  const masterPnlAccounts =
    masterCurrency &&
    pnlAccountsQuery.data
      ?.filter((item) => item.currency === masterCurrency && item.type !== 'SETTLEMENT')
      .map((item) => ({ id: item.id, name: item.alias }));

  const form = useForm();
  useEffect(() => {
    return () => {
      form.change('profitAccountId', undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectInput
      source="profitAccountId"
      choices={masterPnlAccounts}
      label={t('profitAccountId')}
      validate={required()}
      disabled={pnlAccountsQuery.isLoading}
    />
  );
};

export default ConversionProfitAccountSelect;
