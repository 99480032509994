import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as RibbonIcon } from './ribbon.svg';
import { ReactComponent as ShieldCheckmarkIcon } from './shieldCheckmark.svg';
import { ReactComponent as FlagIcon } from './flag.svg';
import { ReactComponent as SenderIcon } from './sender.svg';
import { ReactComponent as RecipientIcon } from './recipient.svg';
import { ReactComponent as ExcelIcon } from './excel.svg';
import { ReactComponent as WordIcon } from './word.svg';
import { ReactComponent as PdfIcon } from './pdf.svg';
import { ReactComponent as CalculatorIcon } from './calculator.svg';
import { ReactComponent as ArchiveIcon } from './archive.svg';
import { ReactComponent as AddressIcon } from './address.svg';
import { ReactComponent as FlashIcon } from './flash.svg';
import { ReactComponent as FlashOffIcon } from './flash-off.svg';
import { ReactComponent as BarcodeIcon } from './barcode.svg';
import { ReactComponent as NavigateIcon } from './navigate.svg';
import { ReactComponent as OpenIcon } from './open.svg';
import { ReactComponent as EmailIcon } from './at-circle.svg';
import { ReactComponent as PersonCircleIcon } from './person-circle.svg';
import { ReactComponent as ReceiptIcon } from './receipt.svg';
import { ReactComponent as ArrowForwardCircleIcon } from './arrow-forward-circle.svg';
import { ReactComponent as PersonIcon } from './person.svg';
import { ReactComponent as FileTrayFullIcon } from './file-tray-full.svg';
import { ReactComponent as TrailSign } from './trail-sign.svg';
import { ReactComponent as CubeIcon } from './cube.svg';
import { ReactComponent as TimeIcon } from './time.svg';
import { ReactComponent as InfiniteIcon } from './infinite.svg';
import { ReactComponent as BarChartIcon } from './bar-chart.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as SwapVerticalIcon } from './swap-vertical.svg';
import { ReactComponent as AlbumsIcon } from './albums.svg';
import { ReactComponent as ReaderIcon } from './reader.svg';
import { ReactComponent as SyncCircleIcon } from './sync-circle.svg';
import { ReactComponent as MailUnredIcon } from './mail-unread.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import { ReactComponent as ReloadIcon } from './reload.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { ReactComponent as CloseCircleIcon } from './close-circle.svg';
import { ReactComponent as KeySvg } from './key.svg';
import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as MarkerSvg } from './marker.svg';
import { ReactComponent as ImportIcon } from './import.svg';
import { ReactComponent as PocketIcon } from './pocket.svg';

const KeyIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <KeySvg />
    </SvgIcon>
  );
};

const MarkerIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <MarkerSvg />
    </SvgIcon>
  );
};

export {
  RibbonIcon,
  ShieldCheckmarkIcon,
  FlagIcon,
  SenderIcon,
  RecipientIcon,
  ExcelIcon,
  WordIcon,
  PdfIcon,
  CalculatorIcon,
  ArchiveIcon,
  AddressIcon,
  FlashIcon,
  FlashOffIcon,
  BarcodeIcon,
  NavigateIcon,
  OpenIcon,
  EmailIcon,
  PersonCircleIcon,
  ReceiptIcon,
  ArrowForwardCircleIcon,
  PersonIcon,
  FileTrayFullIcon,
  TrailSign,
  CubeIcon,
  TimeIcon,
  InfiniteIcon,
  BarChartIcon,
  SettingsIcon,
  SwapVerticalIcon,
  AlbumsIcon,
  ReaderIcon,
  SyncCircleIcon,
  MailUnredIcon,
  RefreshIcon,
  ReloadIcon,
  DocumentsIcon,
  CloseCircleIcon,
  KeyIcon,
  PhoneIcon,
  MarkerIcon,
  ImportIcon,
  PocketIcon,
};
