export default {
  name: 'Partner |||| Partners',
  subtitle: 'All partners for incoming, outgoing and exchange transactions',
  addPartner: 'Add Partner',
  tabs: {
    accounts: 'Accounts',
    profile: 'Profile',
    bankDaySummary: 'Bank day summary',
    transactions: 'Transactions',
    apiLogs: 'API Logs',
    configuration: 'Configuration',
    systemLog: 'System Log',
    eventLog: 'Events',
  },
  fields: {
    // Forms
    name: 'Partner Name',
    description: 'Description',
    transfersDirection: 'Transfers Direction',
    INCOMING: 'Incoming',
    OUTGOING: 'Outgoing',
    externalId: 'Partner ID',
    solutionId: 'Solution',
    productId: 'Product',
    accountNumber: 'Partner Account',
    accountAlias: 'Account alias at Partner side',
    status: 'Status',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    partnerChangedPrefix: "Partner's profile changed with the reason",
    // Show
    accountsHeading: {
      INCOMING: 'Incoming Transfer Account',
      OUTGOING: 'Outgoing Transfer Account',
    },
    partnerDetails: 'Partner core settings',
    editPartnerDetails: 'Edit partner details',
    reason: 'Reason',
    editWarn:
      'These settings will be applied on the next banking day. An impact might be critical, including the non-availability of the service.',
    closeWarn:
      'All transactions going through this partner will be blocked immediately after confirmation. Please enter a reason and confirm.',
    closePartner: 'Close Partner',
    closePrefixText: 'Partner closed with reason',
    // Configuration
    channelConfiguration: 'Remote system connection',
    warning:
      'Kindly contact Veengu Customer Support Team to configure the partner connection setup.',
    connectionStatus: 'Connection Status',
    notConfigured: 'Not configured',
    key: 'Key',
    noKey: 'No key applied',
    apiIdentity: 'Integration API identity',
    createdAt: 'Date of creation',
    tokenPrefix: 'Token prefix',
    getCode: 'Get new API access code',
    yourCode: 'Your API code. It will disappear after your leave this page!',
    copyCode: 'Copy to clipboard',
    warningApiCode:
      "If your request new API code the old one will no longer be valid and all API's relying on current code would not work. Do it if you know what you are doing.",
    noIdentity: 'No integration API identity was generated yet.',
    copied: 'API code copied',
    externalSources: 'External sources configuration',
    supportedExternalSourceTypes: 'External source types supported',
    paymentExternalSourceTypes:
      'External source types allowed (for payments initiated by this partner)',
    identityPermissions: 'Permissions',
    identityPermissionName: 'Permission name',
    identityUpdateSuccess: 'Integration API identity was successfully updated',
    identityUpdateError: 'Failed to update integration API identity',
    saveIdentityPermissions: 'Save',
  },
  transactionsHistory: {
    bankingDay: 'Banking day',
    baseAmount: 'Base amount',
    instructedAmount: 'Instructed amount',
    type: 'Type',
    counterParty: 'Counter-party (CP)',
    otherAccountNumber: 'CP Account',
    dateAndTime: 'Date & time',
    trnId: 'Trn ID',
    externalId: 'External ID',
    reconId: 'Recon ID',
    status: 'Status',
    // Filters
    bankingDayFrom: 'Banking day from',
    bankingDayTo: 'Banking day to',
    fromCreatedAt: 'From',
    toCreatedAt: 'To',
    operationType: 'Operation type',
    transactionId: 'Transaction ID',
    referenceNumber: 'Partner transaction ID',
    text: 'Text search',
    amount: 'Base amount',
    currency: 'Base currency',
  },
};
