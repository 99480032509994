import React from 'react';
import Grid from '@material-ui/core/Grid';
import H3 from './H3';
import ReferenceFiled from './ReferenceFiled';
import { useTranslate } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';

interface Props {
  profileId: string;
  profileType: string;
  noPaddingLeft?: boolean;
  topSpacing?: number;
  bottomSpacing?: number;
  withIcon?: boolean;
}

const AccountOwner = (props: Props) => {
  const { profileId, profileType, noPaddingLeft, topSpacing, bottomSpacing, withIcon } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.AccountOwner.${key}`);

  return (
    <>
      <H3
        topSpacing={topSpacing}
        bottomSpacing={bottomSpacing}
        noPaddingLeft={noPaddingLeft}
        icon={withIcon ? <PersonIcon /> : undefined}
      >
        {t('owner')}
      </H3>
      <Grid container>
        <Grid item xs={12}>
          <ReferenceFiled
            record={{
              id: profileId,
              type: profileType,
            }}
            withDefaultIcon
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountOwner;
