import React, { FC, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { useTimeout } from '~/hooks';
import { useCopy } from '~/hooks/clipboard';

interface Prpos {
  value: string;
  classes?: {
    button?: string;
    icon?: string;
  };
}

const CopyButton: FC<Prpos> = (props) => {
  const { value, classes } = props;
  const [copyDone, setCopyDone] = useState(false);
  const resetStateTimeout = useTimeout(() => {
    setCopyDone(false);
  }, 1000);

  const copy = useCopy();

  const handleCopy: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    const copied = await copy(value);
    setCopyDone(copied);
    resetStateTimeout.start();
  };

  const defaultClasses = useStyles({ copyDone });

  return (
    <IconButton onClick={handleCopy} className={classes?.button}>
      <CopyIcon className={cx(defaultClasses.copyIcon, classes?.icon)} />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  copyIcon: {
    color: (props: { copyDone?: boolean } | undefined) =>
      props?.copyDone ? theme.palette.success.main : 'unset',
  },
}));

export default CopyButton;
