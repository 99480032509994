import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { Moment } from '~/types';

export type Tag =
  | 'a.create'
  | 'a.update'
  | 'a.delete'
  | 'a.list'
  | 'a.view'
  | 'o.audit'
  | 'o.audit_block'
  | 'o.system'
  | 'o.auth'
  | 's.individual'
  | 's.business'
  | 's.terminal'
  | 's.location'
  | 's.employee'
  | 's.dashboardNote'
  | 's.partner'
  | 's.beneficiary'
  | 's.account'
  | 's.dealBeneficiary'
  | 's.externalSource'
  | 's.deal'
  | 's.card'
  | 's.apiIdentity'
  | 'l.info'
  | 'l.warn'
  | 'l.error'
  | 't.purchase';

export type AuditLogType = {
  id: string;
  timestamp: string;
  message: string;
  solutionId?: string;
  profileId?: string;
  tags: Tag[];
  objects: string[];
  clientAddress?: string;
  data?: any;
  identityId?: string;
  identityType?: 'API' | 'DASHBOARD' | 'OTHER';
  identityName?: string;
  reference?: string[];
  sequenceNumber: string;
  hash: string;
};

export type Response = {
  cursors: {
    next?: string;
    prev?: string;
  };
  records: AuditLogType[];
};

export interface AuditLogFilters {
  objectId?: string;
  profileId?: string;
  tags?: Tag[];
  identityId?: string;
  identityName?: string;
  fromTimestamp?: Moment;
  toTimestamp?: Moment;
}
interface GetAuditProps extends AuditLogFilters {
  limit?: number;
  next?: string | null;
  prev?: string | null;
  [x: string]: any;
}

export const getAuditLog = (props?: GetAuditProps) => {
  const { timeframe, ...filters } = props || {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  const { tags = [], logLevel, ...restFilters } = filters;
  return axios.get<Response>(
    urlWithParams('/auditLog', { ...restFilters, tags: [...tags, ...(logLevel ? [logLevel] : [])] })
  );
};

export type TagsList = {
  [x in Tag]: string;
};

export const getAuditLogTags = () => {
  return axios.get<TagsList>('/auditLog/tags').then((res) => res.data);
};
