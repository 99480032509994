import { EventReferenceEntity } from '~/types/Event';

import useEventEntity from '~/hooks/eventEntities';

const useReferenceEntity = (
  lastUpdatedBy: EventReferenceEntity,
  hasChangelogPermissionList: boolean = true
) => {
  // Query the entity using the object above.
  const { data, isLoading } = useEventEntity(
    lastUpdatedBy,
    hasChangelogPermissionList && lastUpdatedBy.type !== 'SYSTEM'
  );

  const isIntegrationType: boolean = lastUpdatedBy.type === 'INTEGRATION_IDENTITY';

  // When entity is type 'INTEGRATION' query the partners endpoint to get the partner's data.
  const { data: partnerIdentity, isLoading: partnerIdentityIsLoading } = useEventEntity(
    { id: data?.partnerId, type: 'PARTNER' },
    hasChangelogPermissionList && isIntegrationType && !isLoading && !!data?.partnerId
  );

  if (isIntegrationType) {
    return {
      data: partnerIdentity,
      isLoading: partnerIdentityIsLoading,
      partnerId: data?.partnerId,
    };
  }
  return { data, isLoading };
};

export default useReferenceEntity;
