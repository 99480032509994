import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { time } from '~/utils';

import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment, Partner } from '~/types';
import { l } from '../PartnerCreate';
import { debounce } from 'lodash';
import { SelectTransactionType } from '~/components/TransactionsHistory/Filters';
import { CurrencyAutocomplete } from '~/components';
import { useHandbook } from '~/hooks';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';

interface Props {
  target: 'partners';
  record: Partner;
  resource: string;
}

const Filters = (props: Props) => {
  const { record, resource } = props;
  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const [localValues, setLocalValues] = useState(filters);
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`transactionsHistory.${key}`));

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    console.log(`dispatching ${resource} ${name}: `, value);
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };
  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);

  const handleChange = (name: string) => (eventOrData: any) => {
    switch (name) {
      case 'fromCreatedAt':
      case 'toCreatedAt':
        setLocalValues((prev) => ({
          ...prev,
          [name]: eventOrData as Moment,
        }));
        debouncedDispatchFilterChange(name, eventOrData as Moment);
        break;
      default:
        const value = eventOrData?.target?.value || '';
        setLocalValues((prev) => ({
          ...prev,
          [name]: value as string,
        }));
        debouncedDispatchFilterChange(name, value as string);
        break;
    }
  };

  const handbook = useHandbook(record?.solutionId);

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const recordId = record.id;

  useEffect(() => {
    dispatch(
      filterChange({
        resource,
        filters: {
          fromCreatedAt: time().subtract(1, 'months'),
          toCreatedAt: time(),
          profileId: recordId,
        },
      })
    );
    return () => {
      dispatch(cleanupFilters({ resource }));
    };
  }, [dispatch, resource, recordId]);

  let currencies = handbook.data?.currencies;
  console.log(`currencies: `, currencies);

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.inputsGroup}>
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={t('fromCreatedAt')}
              value={localValues.fromCreatedAt}
              onChange={handleChange('fromCreatedAt')}
            />
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={t('toCreatedAt')}
              value={localValues.toCreatedAt}
              onChange={handleChange('toCreatedAt')}
            />
            <SelectTransactionType
              label={t('operationType')}
              className={classes.formControl}
              onChange={handleChange('operationType')}
              value={localValues.operationType}
            />
            <TextField
              label={t('otherAccountNumber')}
              className={classes.formControl}
              onChange={handleChange('otherAccountNumber')}
              value={localValues.otherAccountNumber}
              name='otherAccountNumber'
            />
            <TextField
              label={t('transactionId')}
              className={classes.formControl}
              onChange={handleChange('transactionId')}
              value={localValues.transactionId}
              name='transactionId'
            />
            <TextField
              label={t('referenceNumber')}
              className={classes.formControl}
              onChange={handleChange('referenceNumber')}
              value={localValues.referenceNumber}
              name='referenceNumber'
            />
            <TextField
              label={t('amount')}
              className={classes.formControl}
              onChange={handleChange('amount')}
              value={localValues.amount}
              name='amount'
            />
            <CurrencyAutocomplete
              label={t('currency')}
              className={classes.formControl}
              onChange={handleChange('currency')}
              value={localValues.amount}
              filterOptions={(all) => all.filter((c) => currencies?.find((hc) => hc.id === c))}
              name='currency'
            />
          </div>
          <BackgroundTaskExport type='BALANCE_TRANSACTION_EXPORT' queryArguments={filters} />
        </div>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 200,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default Filters;
