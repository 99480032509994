import React from 'react';
import { useTranslate } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { H2, H3, TextField, StatusField, JsonField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import { PaymentService } from '~/types/paymentServices';
import OperationTypeField from '~/components/OperationTypeField';
import PaymentServiceEditAction from './PaymentServiceEditAction';
import PaymentServiceDeleteAction from './PaymentServiceDeleteAction';
import { Longdash } from '~/utils';
import { ReferenceField } from 'react-admin';
import FXRatesField from './FXRates/FXRatesField';

interface Props {
  loading?: boolean;
  record?: PaymentService;
  resource?: string;
  permissions?: string[];
}

const l = (key: string): string => `resources.paymentServices.${key}`;

const Details = (props: Props) => {
  const { loading, record } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const classes = useStyles();

  return (
    <Section noPaddings>
      <Grid container justify='space-between' className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
            {translate(l(`show.detailsHeading`))}
          </H2>
        </Grid>
        <Grid item container xs={4} id='section-actions' spacing={2} justify='flex-end'>
          <Grid item>
            <PaymentServiceEditAction record={record} />
          </Grid>
          <Grid item>
            <PaymentServiceDeleteAction record={record} />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        <Grid container spacing={4}>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('id')} loading={loading}>
              {record?.id}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('name')} loading={loading}>
              {record?.name}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('status')} loading={loading}>
              <StatusField status={record?.status} />
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('templateId')} loading={loading}>
              {record?.templateId}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('partnerId')} loading={loading}>
              {record?.partnerId}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            {record?.accountId ? (
              <ReferenceField
                source='accountId'
                reference='tenant/accounts'
                filter={{
                  type: ['REGULAR'],
                }}
                link={false}
                addLabel={false}
              >
                <RaTextField
                  fn={(r: any) => r.alias}
                  label={t('accountId')}
                  showLink={(r: any) => `/tenant/accounts/${r.id}/show`}
                  textWrap
                />
              </ReferenceField>
            ) : (
              <TextField label={t('accountId')}></TextField>
            )}
            <ReferenceField>
              <TextField optionText={(r: any) => `${r.alias} (${r.number})`} />
            </ReferenceField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('operationType')} loading={loading}>
              <OperationTypeField operationType={record?.operationType} allowEmpty />
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('category')} loading={loading}>
              {record?.category}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField
              label={t('description')}
              loading={loading}
              multiline={(record?.description?.length || 0) > 0}
            >
              {record?.description}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField
              label={t('instruction')}
              loading={loading}
              multiline={(record?.instruction?.length || 0) > 0}
            >
              {record?.instruction}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('index')} loading={loading}>
              {record?.index}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('region')} loading={loading}>
              {record?.region}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('amountMode._')} loading={loading}>
              {record?.amountMode}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            {record?.directFxRatesId ? (
              <FXRatesField label={t('fxRates')} directFxRatesId={record?.directFxRatesId} />
            ) : (
              <TextField label={t('fxRates')}></TextField>
            )}
          </Grid>
        </Grid>
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('parameters')}
        </H3>
        <JsonField
          json={record?.parameters}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={4} loading={loading}>
          {t('parameterGroups')}
        </H3>
        <JsonField
          json={record?.parameterGroups}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('amount')}
        </H3>
        <JsonField
          json={record?.amount}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('senderAmount')}
        </H3>
        <JsonField
          json={record?.senderAmount}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('recipientExternalSource')}
        </H3>
        <JsonField
          json={record?.recipientExternalSource}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('integration')}
        </H3>
        <JsonField
          json={record?.integration}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('deal')}
        </H3>
        <JsonField
          json={record?.deal}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('i18n')}
        </H3>
        <JsonField
          json={record?.i18n}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('tags')}
        </H3>
        <div>
          {record?.tags && record.tags.length > 0
            ? record.tags.map((t: string) => (
                <Chip key={t} label={t} size='small' style={{ margin: 1 }} />
              ))
            : Longdash}
        </div>
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('additionalProperties')}
        </H3>
        <JsonField
          json={record?.additionalProperties}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
      <Section>
        <H3 bottomSpacing={1} topSpacing={2} loading={loading}>
          {t('fallbackServiceId')}
        </H3>
        <JsonField
          json={record?.fallbackServiceIds}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </Section>
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default Details;
