import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { useTenant } from '~/hooks/tenant';

interface Props {
  source: string;
  record?: any;
  label?: string;
  disabled?: boolean;
  [x: string]: any;
}

const LocaleSelect = (props: Props) => {
  const { source, record, label, disabled, ...rest } = props;

  const translate = useTranslate();

  const { data: tenant } = useTenant();

  const choices = tenant
    ? (tenant.data.locales || []).map((key) => {
        return { id: key, name: translate(`locales.${key}`) };
      })
    : [];

  return (
    <SelectInput
      label={label}
      source={source}
      record={record}
      choices={choices}
      disabled={disabled}
      {...rest}
    />
  );
};

LocaleSelect.defaultProps = {
  label: 'components.ra.inputs.LocaleSelect',
  disabled: false,
};

export default LocaleSelect;
