import React, { Children, cloneElement, FC, isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import ChangePasswordModal from './ChangePasswordModal';
import { KeyIcon } from '~/img';
import { useLoggedUser } from '~/hooks/user';
import { history } from '~/App';
import ellipsis from '~/utils/ellipsis';

const UserMenu: FC<any> = (props) => {
  const { children, label, icon, logout } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const translate = useTranslate();

  const loggedUserQuery = useLoggedUser();
  const identity = loggedUserQuery.data?.identity;
  const role = loggedUserQuery.data?.role;

  const classes = useStyles();

  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChangePasswordClick = () => {
    setChangePasswordOpen(true);
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    if (identity) {
      history.push(`/team/${identity.id}/show`);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <ChangePasswordModal isOpen={changePasswordOpen} setOpen={setChangePasswordOpen} />
      <Tooltip title={label && translate(label, { _: label })}>
        <IconButton
          aria-label={label && translate(label, { _: label })}
          aria-haspopup
          color="inherit"
          onClick={handleMenu}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditProfile} className={classes.noPadding}>
          <AboutLoggedUser name={identity?.name} roleName={role?.name || role?.id} />
        </MenuItem>
        <MenuItem onClick={handleChangePasswordClick}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary="Change password" />
        </MenuItem>
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
                onClick: handleClose,
              } as any)
            : null
        )}
        {logout}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0,
  },
}));

interface AboutLoggedUserProps {
  name: string | undefined;
  roleName: string | undefined;
}

const AboutLoggedUser: FC<AboutLoggedUserProps> = (props) => {
  const { name, roleName } = props;
  const classes = useAboutLoggedUserStyles();
  if (!name || !roleName) return null;
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <AccountCircle className={classes.icon} />
      </div>
      <div className={classes.ellipsisWrapper}>
        <div className={classes.ellipsis}>
          <Typography variant="h5" component="div" className={classes.inline}>
            {name}
          </Typography>
        </div>
        <div className={classes.ellipsis}>
          <Typography variant="body2" component="div" className={classes.inline}>
            {roleName}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useAboutLoggedUserStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingTop: 3,
    paddingBottom: 3 + theme.spacing(1),
    paddingRight: 12,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  iconContainer: {
    width: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 36,
    color: theme.palette.grey[600],
  },
  ellipsisWrapper: {
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  ellipsis: {
    ...ellipsis,
  },
  inline: {
    display: 'inline',
  },
}));

UserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  logout: PropTypes.element,
  icon: PropTypes.node,
};

UserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
};

export default UserMenu;
