import * as React from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId, validateRequired } from './utils';

const KycLevelTable = ({
  handbook,
  setState,
}: {
  handbook: Partial<hb.Handbook>;
  setState: hb.SetState;
}) => {
  const kycLevels = (handbook.kycLevels || []).slice();

  const isValid = (newLevel: hb.KycLevel, oldLevel: hb.KycLevel | undefined = undefined) =>
    !validateId(kycLevels, newLevel.id, (oldLevel || {}).id, true) &&
    !validateRequired(kycLevels, newLevel.name) &&
    !validateRequired(kycLevels, newLevel.priority);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'ID',
          field: 'id',
          required: true,
          validate: validateId,
        },
        {
          title: 'Name',
          field: 'name',
          required: true,
          validate: validateRequired,
        },
        {
          title: 'Priority',
          field: 'priority',
          type: 'numeric',
          required: true,
          validate: validateRequired,
        },
      ]}
      components={{
        Container: (props: any) => <Paper variant="outlined">{props.children}</Paper>,
        //Container: React.Fragment
      }}
      data={handbook.kycLevels}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newData: hb.KycLevel) => {
            if (isValid(newData)) {
              kycLevels.push(newData);
              return setState({ kycLevels });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newData: hb.KycLevel, oldData: hb.KycLevel) => {
            if (isValid(newData, oldData)) {
              const index = kycLevels.indexOf(oldData);
              kycLevels[index] = newData;
              return setState({ kycLevels });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldData: hb.KycLevel) => {
            const index = kycLevels.indexOf(oldData);
            kycLevels.splice(index, 1);
            return setState({ kycLevels });
          },
        }
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        addRowPosition: 'first',
        paging: false,
        // search: false,
      }}
    />
  );
};

export default KycLevelTable;
