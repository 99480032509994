import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import theme from '~/theme';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

interface FieldWithPlusProps<T> {
  items: T[];
  mainComponent: (item: T) => JSX.Element;
  tooltipsComponent: (item: T) => JSX.Element;
}

const PlusField = <T extends unknown>(props: FieldWithPlusProps<T>) => {
  const { items: itemsProps, mainComponent, tooltipsComponent } = props;
  const classes = useStyles();

  const items = [...itemsProps];
  const firstItemArray = items.splice(0, 1);

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item className={classes.ellipsis}>
        {firstItemArray?.map((item) => mainComponent(item))}
      </Grid>
      {!isEmpty(items) && (
        <Grid item>
          <StyledTooltip
            placement="bottom-end"
            title={items?.map((item) => tooltipsComponent(item))}
          >
            <div className={classes.number}>+{items.length}</div>
          </StyledTooltip>
        </Grid>
      )}
    </Grid>
  );
};

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: colors.grey[300],
    color: theme.palette.text.primary,
  },
})(Tooltip);

const useStyles = makeStyles(() => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
  },
  number: {
    padding: 4,
    paddingBottom: 0,
  },
}));

export default PlusField;
