import React, { Fragment } from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  ReferenceField,
  TextField,
  TextInput,
  SelectInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import { List } from '~/layout';
import { NoPermissions, StatusField } from '~/components/ra';
import PnlAccountCreate from './PnlAccountCreate';
import { RunTransferAction, UpdateContext } from '~/components';
import { useTenant } from '~/hooks/tenant';

export const l = (key: string): string => `resources.tenant/accounts.${key}`;

const PnlAccountListActions = (props: any) => {
  const { permissions } = usePermissions();
  const { resource } = props;

  const { data: tenant } = useTenant();

  if (!permissions || !tenant) return null;

  // const hasSettlementPermission = permissions.includes('settlement.runTransfer');

  const hasRunTransferPermission =
    permissions.includes('settlement.runTransfer') ||
    permissions.includes('accountTransfer.runTransfer');

  return (
    <TopToolbar>
      {hasRunTransferPermission && (
        <RunTransferAction
          profileId={tenant.id}
          resource={resource}
          onDone={() => {
            // refetch();
          }}
          permissions={permissions}
        />
      )}
      <PnlAccountCreate permissions={permissions} resource={resource} />
    </TopToolbar>
  );
};

const PnlAccountList = (props: any) => {
  const { permissions } = usePermissions();
  const { data: tenant } = useTenant();

  if (!permissions) return null;
  if (!permissions?.includes('tenant.account.list')) return <NoPermissions />;

  console.log(`props.selectedIds:`, props.selectedIds);

  return (
    <Fragment>
      <UpdateContext data={{ solutionId: tenant?.id }} />
      <List
        bulkActionButtons={false}
        {...props}
        actions={<PnlAccountListActions {...props} />}
        filters={
          <Filter>
            <SelectInput
              label={l('type')}
              source="type"
              choices={[
                { id: 'REGULAR', name: 'Regular' },
                { id: 'SETTLEMENT', name: 'Settlement' },
                { id: 'META', name: 'Meta' },
              ]}
              alwaysOn
              allowEmpty
              emptyText="Any type"
              style={{ minWidth: 115 }}
            />
            <TextInput
              label={l('accountNumber')}
              source="accountNumber"
              alwaysOn
              style={{ minWidth: 212 }}
            />
          </Filter>
        }
      >
        <Datagrid rowClick="show" size="medium" selectedIds={null}>
          <ChipField source="type" defaultValue="REGULAR" sortable={false} />
          <TextField source="alias" label={l('alias')} sortable={false} />
          <TextField source="number" label={l('accountNumber')} sortable={false} />
          <TextField source="currency" label={l('currency')} sortable={false} />
          <ReferenceField
            source="bankAccountId"
            reference="bank/accounts"
            link={false}
            label={l('bankAccount')}
            sortable={false}
          >
            <TextField source="alias" />
          </ReferenceField>
          <TextField source="description" label={l('description')} sortable={false} />
          <StatusField source="status" defaultValue="ACTIVE" sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default PnlAccountList;
