export default {
  name: 'End-user indentity |||| End-user indentities',
  subtitle: 'All identities linked to individual and business profiles',
  tabs: {
    authMethods: 'Auth methods',
    identityLocks: 'Identity locks',
    authLogs: 'Auth logs',
  },
  fields: {
    name: 'Name',
    username: 'Username',
    emailAddress: 'Email address',
    phoneNumber: 'Phone number',
    authenticationMethods: 'Authentication methods',
    linkedProfiles: 'Linked profiles',
    goToProfile: 'Go to profile',
    activeIdentityLocks: 'Active identity locks',
    addLock: 'Add lock',
    startDatetime: 'Start datetime',
    endDatetime: 'End datetime',
    reason: 'Reason',
    actor: 'Actor',
    outOf: 'out of',
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
    status: 'Status',
    kycLevel: 'KYC Level',
    kybLevel: 'KYB Level',
    solution: 'Solution',
    employee: 'Employee',
  },
};
