import React, { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';
import { Transaction, Column } from '~/types';
import moment from 'moment';

import { time } from '~/utils';
import { AmountField, StatusField } from '~/components';
import { hasTranslation } from '~/utils';
import theme from '~/theme';
import ellipsis from '~/utils/ellipsis';

const ColoredCell = (props: Props) => {
  const { rowData, children, style } = props;
  const actualStyle =
    rowData.status === 'REJECTED' ? { ...style, color: theme.palette.error.main } : style;
  return <div style={{ ...actualStyle }}>{children}</div>;
};

interface Props {
  children: any;
  rowData: Transaction;
  column: Column;
  style?: CSSProperties;
}

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;
  const translate = useTranslate();

  switch (column.id) {
    case 'dateAndTime':
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children && time(children).format('L LT')}
        </ColoredCell>
      );
    case 'bankingDay': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children && moment(children).format('L')}
        </ColoredCell>
      );
    }
    case 'postedAmount': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <AmountField amount={children} />
        </ColoredCell>
      );
    }
    case 'type': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children &&
          hasTranslation(
            `components.TransactionsHistory.cellComponents.OperationTypeField.${children}`
          )
            ? translate(
                `components.TransactionsHistory.cellComponents.OperationTypeField.${children}`
              )
            : children}
        </ColoredCell>
      );
    }
    case 'status': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <StatusField status={children} />
        </ColoredCell>
      );
    }
    case 'trnId':
    case 'cpAccount': {
      return (
        <ColoredCell column={column} rowData={rowData} style={ellipsis}>
          {children}
        </ColoredCell>
      );
    }
    default: {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children}
        </ColoredCell>
      );
    }
  }
};

export default CustomCell;
