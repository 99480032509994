import { API_URL, TENANT_CODE } from './env';
import { history, queryClient, store } from './App';
import { notification, closeNotification } from '~/ducks/snackbarNotifications';
import { tenantQueryKey } from './hooks/tenant';

const loginNotification = () =>
  store.dispatch(
    notification({
      severity: 'info',
      message: 'Session expired. Please login again to continue',
      autoHideDuration: 99999,
      disableClickawayClose: true,
    })
  );

const closeLoginNotification = () => store.dispatch(closeNotification());

export default {
  login: async ({ username, password, challengeToken, smsOtp, emailOtp }) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (TENANT_CODE) {
      headers['V-Tenant'] = TENANT_CODE;
    }
    const tokenFromStorage = localStorage.getItem('token');
    if (tokenFromStorage) {
      headers['V-Access-Token'] = tokenFromStorage;
    }

    var loginReq;
    if (username && password) {
      loginReq = new Request(`${API_URL}/auth/password/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers(headers),
      });
    } else if (smsOtp) {
      loginReq = new Request(`${API_URL}/auth/smsotp/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ challengeToken, otp: smsOtp }),
        headers: new Headers(headers),
      });
    } else if (emailOtp) {
      loginReq = new Request(`${API_URL}/auth/emailotp/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ challengeToken, otp: emailOtp }),
        headers: new Headers(headers),
      });
    } else {
      throw new Error('not enough data for authentication');
    }

    const login = await fetch(loginReq);
    if (login.status < 200 || login.status >= 300) {
      const contentType = login.headers.get('content-type');
      if (contentType === 'application/json') {
        const responseJson = await login.json();
        throw new Error(responseJson.message || login.statusText);
      } else {
        throw new Error(login.statusText);
      }
    }

    const token = login.headers.get('V-Access-Token');
    localStorage.setItem('token', token);
    headers['V-Access-Token'] = token;

    // check if MFA is required
    const responseJson = await login.json();
    const mfa = responseJson['mfa'];
    if (mfa) {
      const error = new Error('mfa');
      error.mfa = mfa;
      throw error;
    } else {
      const permissionsReq = new Request(`${API_URL}/user`, {
        method: 'GET',
        headers: new Headers(headers),
      });
      const permissionsRes = await fetch(permissionsReq);
      if (permissionsRes.status < 200 || permissionsRes.status >= 300) {
        throw new Error(permissionsRes.statusText);
      }
      const permissions = await permissionsRes.json();
      localStorage.setItem('role', JSON.stringify(permissions.role));
      localStorage.setItem('identity', JSON.stringify(permissions.identity));
      localStorage.setItem('justLoggedIn', 'true');
      // need to wrap history.push() in setTimeout() to avoid staying on same page
      setTimeout(async () => {
        closeLoginNotification();
        await queryClient.fetchQuery(tenantQueryKey);
        history.push('/');
      }, 10);
    }
  },
  logout: () => {
    // FIXME should also send request to backend
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('identity');
    localStorage.removeItem('justLoggedIn');
    queryClient.clear();
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      // TODO Fix Unhandled Rejection (Error): Unauthorized and try to implement goBack feature below
      // if (redirectCount > 0 || redirectedToLogin) return;
      // if (history.location.pathname.split('/').length > 2) {
      //   redirectCount++;
      //   history.goBack();
      // } else {
      //   localStorage.removeItem('token');
      //   history.replace('/login');
      //   redirectedToLogin = true;
      //   loginNotification();
      // }
      localStorage.removeItem('token');
      localStorage.removeItem('identity');
      history.replace('/login');
      loginNotification();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (!localStorage.getItem('identity')) {
      loginNotification();
      setTimeout(() => {
        history.replace('/login');
      }, 1);
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const permissions = localStorage.role ? JSON.parse(localStorage.role).permissions : undefined;
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};
