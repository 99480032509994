import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import cx from 'classnames';
import { useField } from 'react-final-form';

interface HilightChangesProps {
  source: string;
}

const HilightInputChanges: FC<HilightChangesProps> = (props) => {
  const { source, children, ...rest } = props;
  const fieldState = useField(source);
  const value = fieldState.input.value;
  const initialValue = fieldState.meta.initial;
  const isChanged = !isEqual(value, initialValue);

  const classes = useStyles();

  return (
    <div
      className={cx({
        [classes.change]: isChanged,
        [classes.majorChage]: isMajorChanges(initialValue, value),
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

const isMajorChanges = (prev: any, curr: any, threshold = 0.4): boolean => {
  if (typeof prev === 'number' && typeof curr === 'number') {
    if (Math.abs(1 - curr / prev) > threshold) return true;
  }
  return false;
};

const useStyles = makeStyles((theme) => ({
  change: {
    '& input': {
      color: theme.palette.warning.main,
      fontWeight: 600,
    },
  },
  majorChage: {
    '& input': {
      color: theme.palette.error.main,
      fontWeight: 600,
    },
  },
}));

export default HilightInputChanges;
