import React, { FC, useState } from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BlockIcon from '@material-ui/icons/Lock';
import UnblockIcon from '@material-ui/icons/LockOpen';

import { useNotify } from '~/hooks';
import { EditButton, TextInput } from '~/components';
import { updateEventLog } from '~/ducks/eventLog';
import Confirmation from './Confirmation';
import { useBlockIndividualMutation, useUnblockIndividualMutation } from '~/hooks/individuals';
import { useBlockBusinessMutation, useUnblockBusinessMutation } from '~/hooks/businesses';

const label = (key: string): string => `components.BlockOrUnblockProfile.${key}`;

interface Props {
  profileId: string;
  profileType: 'INDIVIDUAL' | 'BUSINESS';
  action: 'block' | 'unblock';
}

const BlockOrUnblockProfile: FC<Props> = (props) => {
  const { profileId, profileType, action } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  const notify = useNotify();

  const dispatch = useDispatch();
  const forceRefresh = useRefresh();

  const [note, setNote] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleNoteInput = ({ target }: any) => {
    setErrorMessage(undefined);
    setNote(target.value);
  };

  const blockIndividualMutation = useBlockIndividualMutation(profileId);
  const unblockIndividualMutation = useUnblockIndividualMutation(profileId);
  const blockBusinessMutation = useBlockBusinessMutation(profileId);
  const unblockBusinessMutation = useUnblockBusinessMutation(profileId);

  const blockProfileMutation =
    profileType === 'INDIVIDUAL' ? blockIndividualMutation : blockBusinessMutation;

  const unblockProfileMutation =
    profileType === 'INDIVIDUAL' ? unblockIndividualMutation : unblockBusinessMutation;

  const handleProfileMutation = action === 'block' ? blockProfileMutation : unblockProfileMutation;

  const onConfirm = async () => {
    if (!note) {
      setErrorMessage(translate('ra.validation.required'));
      throw new Error();
    }
    if (note) {
      await handleProfileMutation.mutateAsync(note);
      forceRefresh();
      dispatch(updateEventLog());
      notify({ type: 'success', message: t('profileStatusUpdated') });
    }
  };

  return (
    <Confirmation
      onConfirm={onConfirm}
      confirmButtonProps={{
        loading: handleProfileMutation.isLoading,
      }}
      confirmationSettings={{
        variant: 'modal',
        modal: {
          heading: t('attention'),
          content: (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="warning">{t('alert')}</Alert>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  autoFocus
                  label={t('reason')}
                  onChange={handleNoteInput}
                  value={note}
                  multiline
                  rows={5}
                  rowsMax={20}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  required
                />
              </Grid>
            </Grid>
          ),
        },
      }}
    >
      {action === 'block' ? (
        <EditButton label="Block profile" icon={<BlockIcon />} red />
      ) : (
        <EditButton label="Unblock profile" icon={<UnblockIcon />} red />
      )}
    </Confirmation>
  );
};

export default BlockOrUnblockProfile;
