import React, { Fragment, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import {
  Grid,
  Divider,
  Chip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AccordionDetails, DeleteButton, StatusField, TextField } from '~/components';
import { useBeneficiaryAccountsDelete, useBeneficiaryAccountsQuery } from '~/hooks/beneficiaries';
import { useNotify } from '~/hooks';
import { Beneficiary } from '~/types/beneficiaries';
import { Alert } from '@material-ui/lab';
import { ShieldCheckmarkIcon } from '~/img';

export const l = (key: string): string => `resources.beneficiaries.accounts.${key}`;

type Props = {
  beneficiary: Beneficiary;
};

const BeneficiaryAccountList = ({ beneficiary }: Props) => {
  const classes = useStyles();

  const notify = useNotify();

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const { data } = useBeneficiaryAccountsQuery(beneficiary.id);

  const { mutate: mutateDelete } = useBeneficiaryAccountsDelete({
    onSuccess: () => {
      notify({ message: t('deleteAccountSuccess'), type: 'success' });
    },
    onError: () => {
      notify({ message: t('deleteAccountError'), type: 'error' });
    },
  });

  return (
    <>
      {data &&
        data.map((account, index) => {
          const parameters = account.parameters;

          const hasDeleteAction = !account.primary;

          const onDeleteClick = () => {
            closeDialog();
            mutateDelete({
              beneficiaryId: beneficiary.id,
              id: account.id,
            });
          };

          return (
            <Fragment key={index}>
              <Divider />
              <AccordionDetails className={classes.topSpacing}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <TextField label={t('accountName')}>
                      <>
                        {account.name}{' '}
                        {account.primary ? (
                          <Chip label={t('primary')} color="primary" size="small" />
                        ) : (
                          account.status === 'PENDING' &&
                          account.setAsPrimary && (
                            <Chip label={t('setAsPrimaryTag')} variant="outlined" size="small" />
                          )
                        )}
                      </>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label={t('accountStatus')}>
                      <StatusField status={account.status} />
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label={t('transferType')}>{account.serviceId}</TextField>
                  </Grid>
                  {Object.keys(parameters)?.map((paramId, i) => {
                    const fieldValue = parameters[paramId].value;
                    const fieldName = parameters[paramId].name;
                    return (
                      <Grid key={i} item xs={4}>
                        <TextField label={fieldName}>{fieldValue}</TextField>
                      </Grid>
                    );
                  })}
                  {hasDeleteAction && (
                    <Grid item xs={12} alignItems="center">
                      <Box display="flex" justifyContent="right">
                        <DeleteButton onClick={openDialog} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
              <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle>{t('deleteAccount')}</DialogTitle>
                <DialogContent>
                  <Alert severity="warning">{t('deleteAccountWarning')}</Alert>
                </DialogContent>
                <DialogActions>
                  <Button label="ra.action.cancel" onClick={closeDialog} />
                  <Button label={t('deleteAccount')} variant="contained" onClick={onDeleteClick}>
                    <SvgIcon>
                      <ShieldCheckmarkIcon />
                    </SvgIcon>
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          );
        })}
    </>
  );
};

export default BeneficiaryAccountList;

const useStyles = makeStyles((theme) => ({
  headerText: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    color: theme.palette.grey['500'],
    display: 'flex',
    alignItems: 'center',
  },
  topSpacing: {
    marginTop: theme.spacing(3),
  },
}));
