import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { HeadTransaction, HeadTransactionFilters } from '~/types/HeadTransaction';

type GetTransactionsResponse = {
  cursors: {
    next?: string;
  };
  transactions?: HeadTransaction[];
};

interface getTransactionsProps {
  next?: string | null;
  filters?: HeadTransactionFilters;
}

export const getTransactions = (props: getTransactionsProps) => {
  const { filters, next } = props;
  const url = `/headTransactions`;

  return axios.get<GetTransactionsResponse>(urlWithParams(url, { next, limit: 50, ...filters }));
};

interface getTransactionProps {
  id: string;
}

export const getTransaction = ({ id }: getTransactionProps) => {
  const url = `/headTransactions/${id}`;
  return axios.get<HeadTransaction>(url);
};

export const checkCancellation = ({ id }: getTransactionProps) => {
  const url = `/headTransactions/${id}/checkCancellation`;
  return axios.get<{ status: 'ALLOWED' | string }>(url);
};

export const cancelAuthorization = ({ id }: getTransactionProps) => {
  const url = `/headTransactions/${id}/cancelAuthorization`;
  return axios.post(url, { id });
};
