import React, { FC, Fragment, useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { SvgIcon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DocumentsIcon } from '~/img';
import { resource } from '~/resources/actionReports/ActionReportsList';
import { makeStyles } from '@material-ui/core/styles';
import ManualReportDrawer from './ManualReportDrawer';
import { useApi } from '~/hooks';
import { checkActionReport, CheckActionReportRes } from '~/api/actionReports';
import { ActionReport } from '~/types';

interface Props {
  objectId: string;
  objectType: ActionReport['objectType'];
}

const ManualReportAction: FC<Props> = (props) => {
  const { objectId, objectType } = props;

  const [apiFlag, setApiFlag] = useState<any>({});

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);
  const classes = useStyles();

  const [{ data, loading }] = useApi<CheckActionReportRes>(
    () => checkActionReport(objectId, objectType),
    [apiFlag]
  );

  const initialValues = data &&
    data.length && {
      system: data[0].system,
      reportType: data[0].reportTypes && data[0].reportTypes[0],
    };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setApiFlag({});
  };
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <ManualReportDrawer
        open={open}
        onClose={handleClose}
        formConfig={data!}
        initialValues={initialValues}
        objectId={objectId}
        objectType={objectType}
      />
      <Button
        label={t('manualReport')}
        className={classes.root}
        onClick={handleOpen}
        disabled={!initialValues}
      >
        {loading ? (
          <CircularProgress size={18} thickness={2} classes={{ svg: classes.root }} />
        ) : (
          <SvgIcon>
            <DocumentsIcon />
          </SvgIcon>
        )}
      </Button>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.warning.main,
  },
}));

export default ManualReportAction;
