import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import BarChartIcon from '@material-ui/icons/BarChart';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Drawer, TextField, PhoneField, H3, ChipField } from '~/components';
import { DeleteButton, TextField as CustomRaTextField } from '~/components/ra';
import { Business, Employee, Terminal } from '~/types';
import BooleanField from '~/components/BooleanField';
import GetAccessCode from './components/GetAccessCode';
import EmployeeEdit from './EmployeeEdit';
import TerminalShow from '~/resources/terminals/TerminalShow';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';
import { useApi } from '~/hooks';
import { getEmployeeMetrics } from '~/api/employees';
import Metric from '~/types/metrics';
import { MetricField } from '~/components/MetricField';
const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    marginTop: theme.spacing(1),
  },
  metrics: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  showData: Employee | null;
  onClose: () => void;
  businessRecord: Business;
  parentProps: any;
  permissions: string[];
}

interface MetricsProps {
  employeeId: string;
}

const Metrics = ({ employeeId }: MetricsProps) => {
  const [{ data: metrics }] = useApi<Metric[]>(() => getEmployeeMetrics(employeeId), [employeeId]);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.employees.${key}`);

  const classes = useStyles();

  return metrics && metrics.length > 0 ? (
    <>
      <H3 icon={<BarChartIcon />} className={classes.topSpacing}>
        {t('metrics._')}
      </H3>
      <Grid
        container
        direction='column'
        spacing={2}
        className={cx(classes.metrics, classes.bottomSpacing)}
      >
        {metrics.map((metric) => (
          <Grid item key={metric.key}>
            <MetricField name={metric.name} value={metric.value} target={metric.target} />
          </Grid>
        ))}
      </Grid>
    </>
  ) : null;
};

const EmployeeShow = (props: Props) => {
  const { showData, onClose, businessRecord, parentProps, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.employees.${key}`);
  const { data: tEmployees } = useEmployeeRolesTranslations();
  const classes = useStyles();
  return (
    <Drawer
      heading={`${showData?.firstName || ''} ${showData?.lastName || ''}`}
      open={!!showData}
      onClose={onClose}
    >
      {showData && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label={t('firstName')} icon={<PersonIcon />}>
              {showData.firstName}
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={t('lastName')}>{showData.lastName}</TextField>
            </Grid>
            <Grid item>
              <TextField label={t('phoneNumber')}>
                <PhoneField number={showData.phoneNumber} />
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('email')}>{showData.email}</TextField>
            </Grid>
          </Grid>
          <H3 icon={<InsertDriveFileIcon />}>{t('document._')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            {showData?.document && Object.keys(showData?.document).length !== 0 ? (
              Object.entries(showData?.document).map(([name, value], index) => (
                <Grid key={index} item>
                  <TextField label={translate(`components.ra.fields.DocumentCard.${name}`)}>
                    {name === 'countryOfIssue' ? translate(`countries.${value}`) : value}
                  </TextField>
                </Grid>
              ))
            ) : (
              <Grid item>{translate('resources.individuals.fields.noDocuments')}</Grid>
            )}
          </Grid>
          <H3 icon={<FingerprintIcon />}>{t('authority._')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <TextField label={t('authority.employeeRole._')} multiline>
                {showData?.roles?.map((item) => {
                  if (!item || !tEmployees) return <></>;
                  return <ChipField label={tEmployees[item]} />;
                })}
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('authority.position')} textWrap>
                {showData.authority?.position}
              </TextField>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <BooleanField label={t('authority.owner')} value={!!showData.authority?.owner} />
              </Grid>
              <Grid item xs={6}>
                <BooleanField
                  label={t('authority.executive')}
                  value={!!showData.authority?.executive}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='column' spacing={2} className={classes.topSpacing}>
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={showData}
                source='reportsTo'
                label={t('reportsTo')}
                reference='employees'
                link={false}
              >
                <CustomRaTextField
                  label={t('reportsTo')}
                  fn={(e: Employee) => `${e.firstName} ${e.lastName}`}
                  icon={<PersonIcon />}
                  showComponent={(props) => {
                    const { open, handleClose } = props;
                    const employeeRecord: Employee = props.record;
                    return (
                      <EmployeeShow
                        permissions={permissions}
                        showData={open ? employeeRecord : null}
                        businessRecord={businessRecord}
                        onClose={handleClose}
                        parentProps={parentProps}
                      />
                    );
                  }}
                />
              </ReferenceField>
            </Grid>
          </Grid>
          <Grid
            container
            direction='column'
            spacing={2}
            className={cx(classes.topSpacing, classes.bottomSpacing)}
          >
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={showData}
                source='terminalId'
                label={t('linkedTerminals')}
                reference='terminals'
                link={false}
              >
                <CustomRaTextField
                  label={t('linkedTerminals')}
                  source='label'
                  icon={<SmartphoneIcon />}
                  showComponent={(props) => {
                    const { open, handleClose } = props;
                    const terminalRecord: Terminal = props.record;
                    return (
                      <TerminalShow
                        permissions={permissions}
                        showData={open ? terminalRecord : null}
                        businessRecord={businessRecord}
                        onClose={handleClose}
                        parentProps={parentProps}
                      />
                    );
                  }}
                />
              </ReferenceField>
            </Grid>
          </Grid>
          <Metrics employeeId={showData.id} />
          <Grid container item spacing={2} justify='flex-end' className={classes.topSpacing}>
            <Grid item>
              <GetAccessCode permissions={permissions} employeeRecord={showData} />
            </Grid>
            <Grid item>
              <DeleteButton
                resource='employees'
                target='businesses'
                targetId={businessRecord.id}
                record={showData}
                disabled={!permissions.includes('business.employee.delete')}
                onClose={onClose}
              />
            </Grid>
            <Grid item>
              <EmployeeEdit
                permissions={permissions}
                employeeRecord={showData}
                businessRecord={businessRecord}
                closeParent={onClose}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

export default EmployeeShow;
