export default {
  name: 'Payment Service |||| Payment Services',
  subtitle: 'All payment services',
  list: {
    id: 'ID',
    name: 'Name',
    group: 'Group',
    partnerId: 'Partner external ID',
    templateId: 'Template service ID',
    index: 'Index',
    paymentGroupsSetup: 'Setup payment groups',
  },
  tabs: {
    details: 'Details',
    references: 'Groups',
  },
  show: {
    detailsHeading: 'Service details',
    recordTitle: 'Service',
    referencesHeading: 'List of groups',
  },
  create: {
    title: 'Create new service',
    subtitle: 'Input service details',
  },
  edit: {
    title: 'Edit service',
    subtitle: 'Edit fields and press Save to continue',
  },
  delete: {
    attention: 'Delete service',
    alert: 'Are you sure you want to delete this service? This action is irreversable!',
    success: 'Service has been deleted successfully!',
  },
  fields: {
    id: 'ID',
    name: 'Name',
    templateId: 'Template ID',
    partnerId: 'Partner ID',
    accountId: 'Account ID',
    operationType: 'Operation type',
    category: 'Category',
    description: 'Description',
    instruction: 'Instruction',
    status: 'Status',
    parameterGroups: 'Parameter groups',
    parameters: 'Parameters',
    index: 'Index',
    region: 'Region (deprecated)',
    amount: 'Amount configuration',
    senderAmount: 'Sender amount configuration',
    amountMode: {
      _: 'Amount mode',
      FIXED: 'Fixed',
      SUGGESTED: 'Suggester',
      DEFAULT: 'Default',
    },
    recipientExternalSource: 'Recipient external source configuration',
    integration: 'Integration configuration',
    deal: 'Deal configuration',
    i18n: 'Localization',
    tags: 'Tags',
    additionalProperties: 'Additional properties',
    tagsHelperText: "To add tag input it's name and press Enter",
    fallbackServiceId: 'Fallback services',
    fxRates: 'Direct FX rates',
  },
  references: {
    group: 'Group references',
    addReference: 'Add to group',
    addReferenceDialogTitle: 'Add to group',
    addReferenceLabel: 'Group to reference',
    confirm: 'Confirm',
    deleteReference: 'Delete reference',
    empty: 'This service is not added to any group for a while.',
    createSuccess: 'Service has been added to the group successfully!',
    createFailure: 'Failed to add service to the group.',
    deleteSuccess: 'Service has been removed from the group successfully!',
    deleteFailure: 'Failed to remove service from the group.',
  },
};
