import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '&:last-child': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

interface Props {
  [x: string]: any;
  children: JSX.Element | JSX.Element[] | string;
  addClassName?: any;
  addLabel?: boolean;
}

const DivLayout = ({ children, addClassName = '', addLabel, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <SimpleShowLayout
      className={cx(...addClassName.split(' '), { [classes.root]: true })}
      addLabel={addLabel}
      {...rest}
    >
      {children}
    </SimpleShowLayout>
  );
};

DivLayout.defaultProps = {
  addLabel: false,
};

export default DivLayout;
