import React from 'react';
import {
  FormDataConsumer,
  TextInput,
  required as requiredFunc,
  number as numberFunc,
} from 'react-admin';
import lodashGet from 'lodash/get';

import { CountryInput, DateInput, DocumentTypeSelect } from '~/components/ra';
import { useHandbook } from '~/hooks';
import { time } from '~/utils';

interface GetValidateProps {
  required?: boolean;
  number?: boolean;
}

const getValidate = ({ required, number }: GetValidateProps): any[] => {
  const validateArray = [];
  if (required) validateArray.push(requiredFunc());
  if (number) validateArray.push(numberFunc());
  return validateArray;
};

export interface Props {
  source?: string;
  solutionId?: string;
  country?: string;
  legalType?: string;
  scopedFormData?: any;
  getSource: (source: string) => string;
  labelBase: string;
  onlyPrimary?: boolean;
  edit?: boolean;
  [x: string]: any;
}

const BusinessDocumentForm = ({
  solutionId,
  country,
  legalType,
  getSource,
  selector,
  scopedFormData,
  onlyPrimary,
  edit,
  ...rest
}: Props) => {
  const { data } = useHandbook(solutionId);

  if (data && country && legalType) {
    const countryData = (data?.countries || []).find((c) => c.id === country);
    const legalTypeData = (countryData?.businessLegalTypes || []).find(
      (lt) => lt.name === legalType
    );
    const documents = legalTypeData?.documents || [];

    const selectedType = scopedFormData && lodashGet(scopedFormData, selector('type'));

    const documentData = documents.find((d) => d.type === selectedType);
    const parameterInputs: any[] = [];
    if (documentData) {
      const parameters = documentData.parameters || [];
      parameters.forEach((p) => {
        const { type, name, mandatory } = p;
        const sourceName = name;
        switch (type) {
          case 'date':
          case 'futureDate':
          case 'pastDate': {
            const minDate = type === 'futureDate' ? time().add(1, 'day').format() : undefined;
            const maxDate = type === 'pastDate' ? time().format() : undefined;
            parameterInputs.push(
              <div key={name}>
                <DateInput
                  source={getSource(sourceName)}
                  record={scopedFormData}
                  {...rest}
                  options={{
                    fullWidth: true,
                    autoFocus: !!(edit && onlyPrimary),
                    minDate,
                    maxDate,
                  }}
                  validate={getValidate({ required: mandatory })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                />
              </div>
            );
            break;
          }
          case 'country':
            parameterInputs.push(
              <div key={name}>
                <CountryInput
                  source={getSource(sourceName)}
                  record={scopedFormData}
                  {...rest}
                  validate={getValidate({ required: mandatory })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                  fullWidth
                />
              </div>
            );
            break;
          default: {
            parameterInputs.push(
              <div key={name}>
                <TextInput
                  source={getSource(sourceName)}
                  record={scopedFormData}
                  default
                  fullWidth
                  {...rest}
                  validate={getValidate({ required: mandatory, number: type === 'number' })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                />
              </div>
            );
          }
        }
      });
    }

    const renderForm = () => {
      return (
        <>
          <div>
            <DocumentTypeSelect
              source={getSource('type')}
              record={scopedFormData}
              country={country}
              solutionId={solutionId as string}
              legalType={legalType}
              validate={requiredFunc()}
              onlyPrimary={onlyPrimary}
              disabled={edit && onlyPrimary}
              fullWidth
              autoFocus
            />
          </div>
          {parameterInputs}
        </>
      );
    };

    return <FormDataConsumer>{() => renderForm()}</FormDataConsumer>;
  } else {
    return (
      <>
        <div>
          <DocumentTypeSelect
            source={getSource('type')}
            record={scopedFormData}
            country={country as string}
            solutionId={solutionId as string}
            legalType={legalType}
            validate={requiredFunc()}
            onlyPrimary={onlyPrimary}
            disabled
          />
        </div>
      </>
    );
  }
};

export default BusinessDocumentForm;
