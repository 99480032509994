import React, { Fragment } from 'react';
import { FormDataConsumer, required, NumberInput, useTranslate } from 'react-admin';
import { l } from '~/resources/pricings/PricingShow';

export const billingDateFormat = 'MMMM DD';

interface Props {
  getSource: (key: string) => string;
  defaultValue?: number;
}

const DefaultCountLimitForm = (props: Props) => {
  const { getSource, defaultValue } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  const isPositive = (value: Number) => {
    if (typeof value !== undefined) {
      if (value <= 0) {
        return { message: t('limits_.countMustBePositive') };
      }
    }
  };

  return (
    <FormDataConsumer>
      {() => {
        return (
          <Fragment>
            <NumberInput
              label={t(`limits_.count`)}
              source={getSource('count')}
              fullWidth
              validate={[required(), isPositive]}
              initialValue={defaultValue}
            />
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default DefaultCountLimitForm;
