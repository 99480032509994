import React, { Fragment, useState, useCallback, FC } from 'react';
import {
  ReferenceManyField,
  useTranslate,
  Filter,
  TextField,
  Datagrid,
  SelectInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce, set as lodashSet } from 'lodash';

import {
  NoPermissions,
  StatusField,
  AddressField,
  MerchantCategoryCodeField,
  AvatarField,
} from '~/components/ra';
import { TextInput } from '~/components';
import { AutocompleteArrayTextInput } from '~/components/ra';
import LocationShow from './LocationShow';
import LocationCreate from './LocationCreate';
import { Longdash } from '~/utils';
import RaPagination from '~/components/Pagination/RaPagination';
import usePath from '~/hooks/usePath';

const LocationsList: FC<any> = (props) => {
  const { record, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.locations.${key}`);

  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    setShowData(data);
  };
  const handleClose = () => setShowData(null);

  const [filters, setFilters] = useState<any>({});
  const classes = useStyles();

  if (!record) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.location.list')) return <NoPermissions />;

  return (
    <Fragment>
      <LocationShow
        showData={showData}
        onClose={handleClose}
        businessRecord={record}
        permissions={permissions}
      />
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Filters {...props} setFilters={setFilters} hideFilter={() => null} />
        </Grid>
        <Grid item className={classes.bottomSpacing}>
          <LocationCreate permissions={permissions} businessRecord={record} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label="Locations"
        reference="locations"
        target="businesses"
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        filter={filters}
        {...props}
      >
        <Datagrid rowClick={(id: string, basePath: string, record: any) => handleOpen(record)}>
          <AvatarField
            source="branding.icon"
            updatable={false}
            fullScreenView={false}
            canDownload={false}
            label=""
            size={28}
            style={negativeVerticalMargins}
          />
          <TextField label={t('name')} source="name" />
          <AddressField />
          <MerchantCategoryCodeField label={t('MCC')} />
          <StatusField label={t('status._')} />
        </Datagrid>
      </ReferenceManyField>
    </Fragment>
  );
};

const Filters = (props: any) => {
  const { setFilters } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.locations.${key}`);
  const { changePath } = usePath();

  const debouncedSetFilters = useCallback(debounce(setFilters, 400), [setFilters]);
  const handleChange = (e: any) => {
    if (!e?.target) return null;
    const { name, value } = e.target;
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return { ...lodashSet(prev, name, value) };
    });
  };

  const classes = useStyles();
  return (
    <Filter className={classes.filters} {...props} setFilters={() => null} hideFilter={() => null}>
      <TextInput
        name="name"
        source="name"
        label={t('name')}
        // disableHelperText
        alwaysOn
        onChange={handleChange}
      />
      <TextInput
        name="region"
        source="region"
        label={t('address.region')}
        // disableHelperText
        alwaysOn
        onChange={handleChange}
      />
      <TextInput
        name="city"
        source="city"
        label={t('address.city')}
        // disableHelperText
        alwaysOn
        onChange={handleChange}
      />
      <SelectInput
        name="status"
        source="status"
        label={t('status._')}
        choices={[
          { id: 'ACTIVE', name: t('status.ACTIVE') },
          { id: 'BLOCKED', name: t('status.BLOCKED') },
          { id: 'CLOSED', name: t('status.CLOSED') },
        ]}
        // disableHelperText
        alwaysOn
        emptyText={Longdash}
        onChange={handleChange}
      />
      <AutocompleteArrayTextInput
        name="groups"
        source="groups"
        label={t('groups')}
        regularHelperText={t('groupsHelperText')}
        onChange={(e: any, value: string[], reason: string) => {
          switch (reason) {
            case 'create-option':
            case 'remove-option':
              handleChange({ target: { name: 'groups', value } });
              break;
            case 'clear':
              handleChange({ target: { name: 'groups', value: [] } });
              break;
            default:
              break;
          }
        }}
        alwaysOn
      />
    </Filter>
  );
};

const negativeVerticalMargins = { marginTop: -5, marginBottom: -5 };

const perPage = 10;

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  filters: {
    alignItems: 'flex-start',
  },
}));

export default LocationsList;
