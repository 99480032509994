import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import { Longdash } from '~/utils';
import { useHandbook } from '~/hooks';

export interface Props {
  solutionId?: string;
  source?: string;
  record?: any;
  [x: string]: any;
}

const MerchantCategoryCodeInput = ({ solutionId, record, ...rest }: Props) => {
  const { data } = useHandbook(solutionId);

  const translate = useTranslate();

  if (data?.merchantCategoryCodes) {
    const codes = (data?.merchantCategoryCodes || []).sort((a, b) => {
      return a.id.localeCompare(b.id);
    });
    return <SelectInput choices={codes} record={record} default {...rest} />;
  } else {
    return (
      <SelectInput
        choices={[{ id: undefined, name: translate('utils.noHandbookValues') }]}
        {...rest}
        allowEmpty={false}
      />
    );
  }
};

MerchantCategoryCodeInput.defaultProps = {
  emptyText: Longdash,
  source: 'merchantCategoryCode',
};

export default MerchantCategoryCodeInput;
