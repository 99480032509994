import { urlWithParams } from '~/utils';
import axios from '~/utils/axios';

import { Moment, Transfer } from '~/types';

interface getBankDaySummaryProps {
  id: string;
  next?: string | null;
  filters?: { [x: string]: any };
}

export type BankSummaryType = {
  bankAccountId: string;
  date: string;
  amount: number;
  count: number;
};

export const getBankDaySummary = (props: getBankDaySummaryProps) => {
  const { id, filters, next } = props;
  const resultURL = `bank/accounts/${id}/summary`;

  return axios.get<BankSummaryType[]>(urlWithParams(resultURL, { next, ...filters }, 'YYYY-MM-DD'));
};

type GetTransfersResponse = {
  cursors: {
    next?: string;
  };
  transfers?: Transfer[];
};

export type TransferFilters = {
  fromTimestamp?: Moment | undefined;
  toTimestamp?: Moment | undefined;
  bankAccountId?: string;
  transactionId?: string;
  [x: string]: any;
};
interface GetTransfersProps {
  next?: string | null;
  filters?: TransferFilters;
  limit?: number;
}

export const getTransfers = (props: GetTransfersProps) => {
  const { filters, next, limit = 50 } = props;
  const url = `/bank/transfers`;

  return axios.get<GetTransfersResponse>(urlWithParams(url, { next, limit, ...filters }));
};
