import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import { l } from '~/resources/partners/PartnerCreate';
import PartnerForm from '~/resources/partners/PartnerForm';
import { Partner } from '~/types';
import { useDispatch } from 'react-redux';
import { createNote } from '~/ducks/notes';

export interface PartnerEditProps {
  resource?: string;
  record?: Partner;
  permissions?: string[];
}

const PartnerEdit = (props: PartnerEditProps) => {
  const { permissions, resource, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const dispatch = useDispatch();

  const handleSubmit = (
    newRecord: any,
    submitQuery: (newRecord: any, onSuccess?: () => void, onFailure?: () => void) => void
  ) => {
    const reason = newRecord.reason;
    newRecord.reason = undefined;

    submitQuery(newRecord, () => {
      if (record && reason) {
        dispatch(
          createNote(
            {
              text: `${t('partnerChangedPrefix')}: ${reason}`,
              referenceId: record.id,
              createdAt: new Date().toISOString(),
              creatorName: JSON.parse(localStorage.identity).name,
            },
            record.id,
            'PARTNER'
          )
        );
      }
      setOpen(false);
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('partner.update')} />
      <Drawer heading={t('editPartnerDetails')} open={open} onClose={handleClose}>
        {resource && record && (
          <EditForm
            resource={resource}
            record={record}
            onSubmit={handleSubmit}
            saveButtonProps={{ color: 'red' }}
          >
            <PartnerForm partnerDetailsEdit />
          </EditForm>
        )}
      </Drawer>
    </Fragment>
  );
};

export default PartnerEdit;
