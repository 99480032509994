import React, { Fragment } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';

import { Column } from './components/list/Column';

import { VirtualizedList, Title, TextField, StatusField } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';

import Filters from './components/list/ListFilters';
import { KycLevelField, NoPermissions, SolutionField } from '~/components/ra';
import theme from '~/theme';
import { Individual, IndividualFilters } from '~/types/individual';
import { getIndividuals } from '~/api/individual';
import { CellProps } from './components/list/CellProps';
import { Longdash, hasTranslation, time } from '~/utils';
import ellipsis from '~/utils/ellipsis';

export const resource = 'individuals';
export const i18nPath = 'resources.individuals.';

interface Props {
  permissions: string[];
  history: any;
}

const IndividualList = (props: Props) => {
  const { history } = props;
  const { permissions } = usePermissions();

  const translate = useTranslate();
  const translateField = (path: string, key: string) => {
    const fullPath = `${path}${key}`;
    return hasTranslation(fullPath) ? translate(fullPath) : key;
  };

  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<IndividualFilters>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getIndividuals({
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.individuals as any[],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column[] = [
    {
      selectCellData: (i: Individual) => [i.firstName, i.lastName].filter(Boolean).join(' '),
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <div style={ellipsis}>{props.children}</div>
      )),
      header: translate(i18nPath + 'fields.name'),
      style: {
        flexBasis: 150,
        minWidth: 120,
        maxWidth: 200,
      },
    },
    {
      selectCellData: (i: Individual) => translateField(`countries.`, i.country),
      cellDataWrapperComponent: RowField((props: CellProps) => <>{props.children}</>),
      header: translate(i18nPath + 'fields.country'),
      style: {
        flexBasis: 150,
        minWidth: 100,
        maxWidth: 250,
      },
    },
    {
      selectCellData: (i: Individual) => i.phoneNumber,
      cellDataWrapperComponent: RowField((props: CellProps) => <>{props.children}</>),
      header: translate(i18nPath + 'fields.phoneNumber'),
      style: {
        flexBasis: 130,
        minWidth: 150,
        maxWidth: 250,
      },
    },
    {
      selectCellData: (i: Individual) => i,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <SolutionField record={props.rowData} source='solutionId' />
      )),
      header: translate(i18nPath + 'fields.solutionId'),
      style: {
        flexBasis: 150,
        minWidth: 100,
        maxWidth: 250,
      },
    },
    {
      selectCellData: (i: Individual) => i,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <KycLevelField record={props.children} source='kycLevel' />
      )),
      header: translate(i18nPath + 'fields.kycLevel'),
      style: {
        flexBasis: 150,
        minWidth: 100,
        maxWidth: 200,
      },
    },
    {
      selectCellData: (i: Individual) => i.kycStatus,
      cellDataWrapperComponent: RowField((props: CellProps) => {
        const path = 'resources.individuals.fields.';
        const kycStatus = translateField(path, props.children);
        return <TextField>{kycStatus}</TextField>;
      }),
      header: translate(i18nPath + 'fields.kycStatus'),
      style: {
        flexBasis: 100,
        minWidth: 80,
        maxWidth: 150,
      },
    },
    {
      selectCellData: (i: Individual) => i.createdAt,
      cellDataWrapperComponent: RowField((props: CellProps) => {
        const createdAt = time(props.children);
        return (
          <TextField compact label={createdAt.format('LL')}>
            {createdAt.format('LT')}
          </TextField>
        );
      }),
      header: translate(i18nPath + 'fields.createdAt'),
      width: 120,
    },
    {
      selectCellData: (i: Individual) => i.documents,
      cellDataWrapperComponent: RowField((props: CellProps) => {
        const documents = props.children || [];
        const documentType = documents[0]?.type || Longdash;
        const documentNumber = documents[0]?.number || Longdash;
        const path = 'components.ra.fields.DocumentCard.documentType.';
        return <TextField label={translateField(path, documentType)}>{documentNumber}</TextField>;
      }),
      header: translate(i18nPath + 'fields.primaryDocument'),
      style: {
        flexBasis: 150,
        minWidth: 120,
        maxWidth: 200,
      },
    },
    {
      selectCellData: (i: Individual) => i,
      cellDataWrapperComponent: RowField((props: CellProps) => (
        <StatusField status={props.children.status} deleted={props.children.deleted} />
      )),
      header: translate(i18nPath + 'fields.status'),
      width: 100,
    },
  ];

  const getRowLink = (data: Individual) => {
    if (permissions?.includes('individual.view')) {
      return `/individuals/${data.id}/show`;
    }
  };

  const { hasNextPage, isNextPageLoading, data } = list;
  if (!permissions) return null;
  if (!permissions.includes('individual.list')) return <NoPermissions />;
  return (
    <Fragment>
      <Title resource={resource} />
      <Filters cleanFilters={history.action !== 'POP'} />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<Individual>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          filters={filters}
          data={data}
          loadNextPage={loadNextPage}
          outterTable
          rowHeight={48}
          getRowLink={getRowLink}
        />
      </Paper>
    </Fragment>
  );
};

export const RowField = (Field: any) => {
  return (props: CellProps) => {
    const style =
      props.rowData.status === 'REJECTED' ? { color: theme.palette.error.main } : undefined;
    return (
      <Field {...props} style={style}>
        {props.children}
      </Field>
    );
  };
};

const fixBottomShadow = { paddingBottom: 2 };

export default IndividualList;
