import React, { FC, Fragment, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import lodashGet from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import FileSaver from 'file-saver';
import { CSSProperties } from 'styled-components';

import { getFile, FileType } from '~/api/utils';
import { useLoadImage } from '~/hooks';
import onError from '~/errorsHandler';

export interface ImageFieldProps {
  resource?: string;
  record?: any;
  source?: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  objectFit?: CSSProperties['objectFit'];
  backgroundColor?: CSSProperties['backgroundColor'];
  fullScreenView?: boolean;
  addLabel?: boolean;
  [x: string]: any;
}

const ImageField: FC<ImageFieldProps> = (props) => {
  const {
    resource,
    record,
    source,
    noText,
    width = '200px',
    height,
    objectFit = 'cover',
    backgroundColor = 'rgba(0, 0, 0, .1)',
    fullScreenView = true,
    canDownload = true,
  } = props;
  const file = source ? lodashGet(record, source) : record;
  const { dataURL, loading, error } = useLoadImage(file, resource);

  const classes = useStyles();

  const download = async () => {
    if (!canDownload) return;
    if (file && file.id) {
      try {
        const { data } = await getFile(file);
        FileSaver.saveAs(data, getFileName(file));
      } catch (error) {
        onError(error);
      }
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        width={width}
        classes={{ root: classes.skeletonRoot, pulse: classes.skeletonPulse }}
        height={height || width}
      />
    );
  }
  if (error || !file) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ width, height, backgroundColor }}
      >
        <ImageIcon />
      </Grid>
    );
  }
  return (
    <Fragment>
      <div onClick={fullScreenView ? handleOpen : download} style={{ cursor: 'zoom-in' }}>
        {noText ? null : getFileName(file)}
        <div style={{ width }}>
          <img
            src={dataURL}
            style={{ width, height, objectFit, backgroundColor }}
            alt="This is an img"
          />
        </div>
      </div>
      <Backdrop style={{ zIndex: 9999, cursor: 'zoom-out' }} open={open} onClick={handleClose}>
        <img style={{ maxHeight: '90vh' }} src={dataURL} alt="full screen view" />
      </Backdrop>
    </Fragment>
  );
};

const getFileName = (file: FileType) =>
  file ? (file.rawFile ? file.rawFile.name : file.name) : '???';

const useStyles = makeStyles((theme) => ({
  skeletonRoot: {
    backgroundColor: theme.palette.grey[300],
  },
  skeletonPulse: {
    animation: 'MuiSkeleton-keyframes-pulse 900ms ease-in-out 0.5s infinite',
  },
}));

export default ImageField;
