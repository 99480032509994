import React from 'react';
import { time } from '~/utils';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { colors } from '@material-ui/core';
import { Changelog as ChangelogType } from '~/types/Changelog';
import cx from 'classnames';
import { getTypeOfIdentity } from '~/utils/identities';
import { useTranslate } from 'react-admin';
import palette from '~/theme/palette';
import ReferenceFiled from './ReferenceFiled';

interface Props {
  changelogId: number;
  changelogData: ChangelogType;
  handleTurnOnChangelogMode: (id: number) => void;
  hasChangelogPermissionView: boolean;
  selectedChangelog: number | undefined;
}

const useStyles = makeStyles((theme) => {
  return {
    gridContainer: {
      display: 'flex',
      padding: '15px 10px',
      borderRadius: '5px',
      backgroundColor: colors.grey[200],
      marginBottom: '10px',
    },
    pointer: {
      transition: `${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.grey[100],
      },
    },
    yellow: {
      backgroundColor: palette.changelog.yellow,
      '&:hover': {
        backgroundColor: palette.changelog.yellowDarker,
      },
    },
    root: {
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      alignItems: 'center',
    },
    avatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
    },
    icon: {
      backgroundColor: colors.grey[200],
      borderRadius: '50%',
      border: `10px solid ${colors.grey[600]}`,
      color: colors.grey[600],
      width: '20px',
      height: '20px',
    },
  };
});

const Changelog = (props: Props) => {
  const {
    changelogId,
    changelogData,
    handleTurnOnChangelogMode,
    hasChangelogPermissionView,
    selectedChangelog,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const label = (key: string) => translate(`components.OperationTypeChangelog.${key}`);
  const originatedById: string = changelogData?.originatorIdentity?.id ?? '';
  const originatedByType: string = getTypeOfIdentity(
    changelogData?.originatorIdentity?.type ?? 'SYSTEM'
  );
  const record = { id: originatedById, type: originatedByType };
  return (
    <div>
      <div
        className={cx(
          classes.gridContainer,
          {
            [classes.yellow]: changelogId === selectedChangelog,
          },
          hasChangelogPermissionView && classes.pointer
        )}
        onClick={() => (hasChangelogPermissionView ? handleTurnOnChangelogMode(changelogId) : null)}
      >
        <div className={classes.root}>
          <div className={classes.avatar}>
            <PersonIcon className={classes.icon} />
          </div>
          <ReferenceFiled
            label={`${label(changelogData?.operation)} ${time(changelogData?.timestamp).format(
              'l'
            )} ${time(changelogData?.timestamp).format('LT')}`}
            record={record}
          />
        </div>
      </div>
    </div>
  );
};

export default Changelog;
