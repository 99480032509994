import React from 'react';
import { useTranslate } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { TextField } from '~/components';
import { l } from '~/resources/pricings/PricingShow';

const DefaultCountLimitView = (props: { record: any }) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(`limits_.${key}`), variant);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={4}>
        <TextField label={t('count')}>
          <TextField>{record.count}</TextField>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default DefaultCountLimitView;
