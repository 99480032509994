import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import FXMappingForm from './FXMappingForm';
import { l } from '../CurrenciesList';
import { Solution } from '~/types';

interface FXMappingCreateProps {
  resource: string;
  solutionRecord?: Solution;
  permissions?: string[];
}

const FXMappingCreate: FC<FXMappingCreateProps> = (props) => {
  const { permissions, solutionRecord } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        variant="add"
        onClick={handleOpen}
        disabled={!permissions?.includes('fxrates.mapping.create')}
      />
      <Drawer heading={t('createRatesMapping')} open={open} onClose={handleClose}>
        <CreateForm
          resource="fxrates/mappings"
          target="solutions"
          id={solutionRecord?.id}
          record={{ solutionId: solutionRecord?.id }}
          closeParent={handleClose}
        >
          <FXMappingForm providedSolutionId={solutionRecord?.id} />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default FXMappingCreate;
