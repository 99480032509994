import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { time } from '~/utils';

import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { debounce } from 'lodash';
import {
  SelectTransactionStatus,
  SelectTransactionType,
} from '~/components/TransactionsHistory/Filters';
import { l } from '../PnlAccountList';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';

interface Props {
  target: 'tenant';
  record: any | undefined;
  resource: string;
}

const Filters = (props: Props) => {
  const { record, resource } = props;

  const filters = useSelector(selectors.getFilters<{ [x: string]: any }>(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`transactionsHistory.${key}`));

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };
  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);

  const handleChange = (name: string) => (eventOrData: any) => {
    switch (name) {
      case 'fromCreatedAt':
      case 'toCreatedAt': {
        setLocalValues((prev) => ({
          ...prev,
          [name]: eventOrData as Moment,
        }));
        dispatchFilterChange(name, eventOrData as Moment);
        break;
      }
      case 'status': {
        const value = eventOrData?.target?.value || '';
        setLocalValues((prev) => ({
          ...prev,
          [name]: value as string,
        }));
        dispatchFilterChange(name, value as string);
        break;
      }
      default: {
        const value = eventOrData?.target?.value || '';
        setLocalValues((prev) => ({
          ...prev,
          [name]: value as string,
        }));
        debouncedDispatchFilterChange(name, value as string);
      }
    }
  };

  const recordId = record.id;
  const profileId = record.profileId;

  useEffect(() => {
    dispatch(
      filterChange({
        resource,
        filters: {
          fromCreatedAt: time().subtract(1, 'months'),
          toCreatedAt: time(),
          accountId: recordId,
          profileId: profileId,
        },
      })
    );
    return () => {
      dispatch(cleanupFilters({ resource }));
    };
  }, [dispatch, resource, recordId, profileId]);
  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.inputsGroup}>
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={t('fromCreatedAt')}
              value={localValues.fromCreatedAt}
              onChange={handleChange('fromCreatedAt')}
            />
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={t('toCreatedAt')}
              value={localValues.toCreatedAt}
              onChange={handleChange('toCreatedAt')}
            />
            <SelectTransactionType
              onChange={handleChange('operationType')}
              value={localValues.operationType || ''}
              className={classes.formControl}
            />
            <SelectTransactionStatus
              onChange={handleChange('status')}
              value={localValues.status}
              className={classes.formControl}
            />
            <TextField
              label={t('search')}
              className={classes.formControl}
              onChange={handleChange('text')}
              value={localValues.text}
              name='text'
            />
          </div>
          <BackgroundTaskExport type='BALANCE_TRANSACTION_EXPORT' queryArguments={filters} />
        </div>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 200,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default Filters;
