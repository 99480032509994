import React from 'react';

import TablePagination, { TablePaginationProps } from './TablePagination';
import ContainedPagination, { ContainedPaginationProps } from './ContainedPagination';
import RaPagination from './RaPagination';
interface Props {
  variant?: 'table' | 'contained' | 'ra';
}

type PaginationProps = Props & TablePaginationProps & ContainedPaginationProps;

const Pagination = (props: PaginationProps) => {
  const { variant = 'table', ...paginationProps } = props;

  if (variant === 'table') {
    return <TablePagination {...paginationProps} />;
  }
  if (variant === 'contained') {
    return <ContainedPagination {...paginationProps} />;
  }
  if (variant === 'ra') {
    return <RaPagination {...paginationProps} />;
  }
  return null;
};

export default Pagination;
