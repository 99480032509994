import { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import ExternalProfileLinkList from './ExternalProfileLinkList';
import ExternalProfileLinkShow from './ExternalProfileLinkShow';

export const resource = 'externalProfileLinks';

export const useExternalProfileLinkTranslate = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.${key}`), [translate]);
};

export default {
  list: ExternalProfileLinkList,
  show: ExternalProfileLinkShow,
};
