import React from 'react';
import { useTranslate } from 'react-admin';
import { JSONSchema6 } from 'json-schema';
import { Box, Divider, Typography } from '@material-ui/core';
import { time } from '~/utils';

import AmountField from '~/components/AmountField';
import BooleanField from '~/components/BooleanField';
import TextField from '~/components/TextField';

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const RegularFeeField = ({ formData, uiSchema }: Props) => {
  const i18nPrefix = uiSchema['ui:i18nPrefix'];
  const raTranslate = useTranslate();

  const translate = (key: string): string => {
    return raTranslate(`${i18nPrefix}.${key}`);
  };

  const title = uiSchema['ui:title'] ? (
    <Typography variant="h5">{uiSchema['ui:title']}</Typography>
  ) : null;

  return (
    <Box>
      {title}
      <Divider />
      <Box mt={2}>
        <TextField label={translate('type._')}>
          {`${translate(`period.${formData.period}`)} ${translate(`type.${formData.type}`)}`}
        </TextField>
      </Box>
      <TextField label={translate('name')}>{formData.name || '—'}</TextField>
      <Box pt={1} pb={1}>
        <AmountField amount={formData.amount} label={translate('amount')} />
      </Box>
      <TextField label={translate('billingDateType._')}>
        {translate(`billingDateType.${formData.billingDateType}`)}
      </TextField>
      {formData.billingDateType === 'fixed' &&
        (formData.period === 'monthly' ? (
          <TextField label={translate('billingDate')}>
            {formData.billingDate ? time().date(formData.billingDate).format('Do') : '—'}
          </TextField>
        ) : (
          <TextField label={translate('billingDate')}>
            {formData.billingDate ? time().dayOfYear(formData.billingDate).format('MMMM Do') : '—'}
          </TextField>
        ))}
      {formData.period === 'monthly' && (
        <BooleanField
          label={translate('payOnTheFirstCalendarMonth')}
          value={formData.payOnTheFirstCalendarMonth || false}
        />
      )}
    </Box>
  );
};

export default RegularFeeField;
