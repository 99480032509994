import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { l } from '~/resources/pricings/PricingShow';
import { TextField } from '~/components';
import { RibbonIcon } from '~/img';
import { ByFeature } from '.';
import { useFeatures } from '~/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  schema: ByFeature;
}

const PricingInfo = (props: Props) => {
  const { schema } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const classes = useStyles();

  const { list: featuresList } = useFeatures();

  return (
    <Grid item container xs={12} spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <TextField label={t('type')} icon={<RibbonIcon />} noPaddingLeft multiline>
          {schema['v:category'] && t(schema['v:category'])}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField label={t('feature')} textWrap>
          {featuresList && featuresList[schema['v:feature']]}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('fields.name')} textWrap>
          {schema['v:name']}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default PricingInfo;
