import { copy } from '~/utils/clipboard';
import { useDispatch } from 'react-redux';
import { useTranslate, showNotification } from 'react-admin';

export const useCopy = () => {
  const translate = useTranslate();
  const t = (key: string): string => translate(`utils.clipboard.${key}`);
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(showNotification(t('copied')));
  };

  return (value: string) => copy(value, onSuccess);
};
