export default {
  jan: { short: 'Jan', full: 'January' },
  feb: { short: 'Feb', full: 'February' },
  mar: { short: 'Mar', full: 'March' },
  apr: { short: 'Apr', full: 'April' },
  may: { short: 'May', full: 'May' },
  jun: { short: 'Jun', full: 'June' },
  jul: { short: 'Jul', full: 'July' },
  aug: { short: 'Aug', full: 'August' },
  sep: { short: 'Sep', full: 'September' },
  oct: { short: 'Oct', full: 'October' },
  nov: { short: 'Nov', full: 'November' },
  dec: { short: 'Dec', full: 'December' },
};
