import { isEmpty } from 'lodash';
import { AddressFieldDashboardConfig } from '~/hooks/useAddressConfig';
import { ExcludeSource } from '.';

export const applyExclude = (
  data: AddressFieldDashboardConfig[] | null,
  excludeSource: ExcludeSource[] | undefined
) => {
  if (isEmpty(excludeSource) || isEmpty(data)) return data;
  return data!.filter(
    (item) => item.type !== 'country' && !excludeSource?.includes(item.source as any)
  );
};

type ConfigBaseType = { source: string };

export const groupFields = (addressConfig: ConfigBaseType[] | null) => (
  fieldsGroups: string[][]
): AddressFieldDashboardConfig[] | null => {
  if (!addressConfig) return addressConfig;
  const sourceToGroupIndexMap: Record<string, number | undefined> = {};
  fieldsGroups.forEach((row, groupIndex) => {
    row.forEach((source) => {
      sourceToGroupIndexMap[source] = groupIndex;
    });
  });
  const markedAddressConfig = addressConfig.map((field) => {
    const groupIndex = sourceToGroupIndexMap[field.source];
    if (typeof groupIndex === 'number') {
      return {
        field,
        groupIndex,
      };
    }
    return {
      field,
      groupIndex: null,
    };
  });

  let groupByMarker: (ConfigBaseType | ConfigBaseType[])[] = [];
  markedAddressConfig.forEach((curr, index, arr) => {
    const currGroupableField = typeof curr.groupIndex === 'number';
    if (index === 0) {
      if (currGroupableField) {
        groupByMarker = [[curr.field]];
        return;
      }
      groupByMarker = [curr.field];
      return;
    }

    const prev = arr[index - 1];
    const shouldGroupWithPrev =
      typeof curr.groupIndex === 'number' &&
      typeof prev.groupIndex === 'number' &&
      curr.groupIndex === prev.groupIndex;

    if (shouldGroupWithPrev) {
      const prevlastElement = groupByMarker[groupByMarker.length - 1];
      const newLastElement = !prevlastElement
        ? [prev.field, curr.field]
        : (prevlastElement as ConfigBaseType[]).concat([curr.field]);
      groupByMarker[groupByMarker.length - 1] = newLastElement;
      return;
    }
    if (currGroupableField) {
      groupByMarker = groupByMarker.concat([[curr.field]]);
      return;
    }
    groupByMarker = groupByMarker.concat([curr.field]);
  });

  const result: (ConfigBaseType | ConfigBaseType[])[] = [];
  // flat single grouped fields
  groupByMarker.forEach((item) => {
    if (Array.isArray(item) && item.length === 1) {
      result.push(item[0]);
      return;
    }
    result.push(item);
  });
  return result as AddressFieldDashboardConfig[];
};
