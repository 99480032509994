import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { time } from '~/utils';

import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Business, Individual, Moment, TransactionsFilters } from '~/types';
import { resource } from './index';
import { SelectInput } from '..';
import BackgroundTaskExport from '../BackgroundTaskCSVExport';

interface Props {
  onChange: (e: any) => void;
  value: string;
  name?: string;
  label?: string;
  [x: string]: any;
}

export const SelectTransactionType = (props: Props) => {
  const { onChange, value, name, label, ...rest } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(SelectTransactionType.l(key));

  return (
    <SelectInput
      label={label || t('operationType')}
      options={SelectTransactionType.getChoices(translate, false) as any}
      onChange={onChange}
      value={value || ''}
      name={name || 'operationType'}
      {...rest}
    />
  );
};
SelectTransactionType.l = (key: string): string =>
  `components.TransactionsHistory.cellComponents.OperationTypeField.${key}`;
SelectTransactionType.getChoices = (translate: (str: string) => string, forReactAdmin = true) => {
  const t = (key: string) => translate(SelectTransactionType.l(key));
  const choices = [
    { value: '', text: t('any') },
    {
      value: 'P2P_TRANSFER',
      text: t('P2P_TRANSFER'),
    },
    { value: 'PURCHASE', text: t('PURCHASE') },
    { value: 'MEMBERSHIP', text: t('MEMBERSHIP') },
    { value: 'BILL_PAYMENT', text: t('BILL_PAYMENT') },
    { value: 'TOP_UP', text: t('TOP_UP') },
    { value: 'CASH_DEPOSIT', text: t('CASH_DEPOSIT') },
    { value: 'CASH_WITHDRAWAL', text: t('CASH_WITHDRAWAL') },
    { value: 'OWN_ACCOUNTS_TRANSFER', text: t('OWN_ACCOUNTS_TRANSFER') },
    { value: 'PAYOUT', text: t('PAYOUT') },
    { value: 'REMITTANCE', text: t('REMITTANCE') },
    { value: 'REMITTANCE_PAYOUT', text: t('REMITTANCE_PAYOUT') },
    { value: 'REVERSAL', text: t('REVERSAL') },
    { value: 'SERVICE_CHARGE', text: t('SERVICE_CHARGE') },
    { value: 'SETTLEMENT', text: t('SETTLEMENT') },
    { value: 'LOYALTY_TRANSFER', text: t('LOYALTY_TRANSFER') },
    { value: 'WRITE_OFF', text: t('WRITE_OFF') },
    { value: 'ACCOUNT_TRANSFER', text: t('ACCOUNT_TRANSFER') },
    { value: 'FORCED_APPROVAL', text: t('FORCED_APPROVAL') },
    { value: 'INCOMING_TRANSFER', text: t('INCOMING_TRANSFER') },
    { value: 'CURRENCY_CONVERSION', text: t('CURRENCY_CONVERSION') },
  ].sort((a, b) => {
    if (!a.value) return -1;
    if (!b.value) return 1;
    return a.text.localeCompare(b.text);
  });
  return forReactAdmin ? choices.map((item) => ({ id: item.value, name: item.text })) : choices;
};

export const SelectTransactionStatus = (props: Props) => {
  const { onChange, value, name, label, ...rest } = props;
  const translate = useTranslate();
  const t = (string: string) => translate(`${i18nFilters}.status.${string}`);
  return (
    <SelectInput
      label={label || t('_')}
      options={[
        { value: '', text: t('any') },
        { value: 'PENDING', text: t('PENDING') },
        { value: 'PROCESSING', text: t('PROCESSING') },
        { value: 'COMPLETED', text: t('COMPLETED') },
        { value: 'REJECTED', text: t('REJECTED') },
        { value: 'AUTHORIZED', text: t('AUTHORIZED') },
        { value: 'APPROVED', text: t('APPROVED') },
        // { value: 'REVERSED', text: t('REVERSED') },
      ]}
      onChange={onChange}
      value={value || ''}
      name={name || 'status'}
      {...rest}
    />
  );
};

export const SelectDealsStatus = (props: Props) => {
  const { onChange, value, name, label, ...rest } = props;
  const translate = useTranslate();
  const t = (string: string) => translate(`dealsStatus.${string}`);
  return (
    <SelectInput
      label={t('status')}
      options={[
        { value: '', text: t('ANY') },
        { value: 'CREATED', text: t('CREATED') },
        { value: 'SENDING', text: t('SENDING') },
        { value: 'SENT', text: t('SENT') },
        { value: 'CANCELLING', text: t('CANCELLING') },
        { value: 'CANCELLED', text: t('CANCELLED') },
        { value: 'PAYING_OUT', text: t('PAYING_OUT') },
        { value: 'PAID_OUT', text: t('PAID_OUT') },
        { value: 'FAILED', text: t('FAILED') },
        { value: 'COMPLETED', text: t('COMPLETED') },
      ]}
      onChange={onChange}
      value={value || ''}
      name={name || 'status'}
      {...rest}
    />
  );
};

export const SelectSettled = (props: Props) => {
  const { onChange, value, name, label, ...rest } = props;
  const translate = useTranslate();
  const t = (string: string) => translate(`${i18nFilters}.SelectSettled.${string}`);
  return (
    <SelectInput
      label={label || t('_')}
      options={[
        { value: '', text: t('any') },
        { value: 'true', text: t('true') },
        { value: 'false', text: t('false') },
      ]}
      onChange={onChange}
      value={value || ''}
      name={name || 'settled'}
      {...rest}
    />
  );
};

interface FitlersProps {
  record: Individual | Business;
}

const Filters = (props: FitlersProps) => {
  const { record } = props;
  const filters = useSelector(selectors.getFilters<TransactionsFilters>(resource));
  const [localValues, setLocalValues] = useState(filters);
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const translateDateTime = (string: string) =>
    translate(`${i18nFilters}.KeyboardDateTimePicker.${string}`);

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    debouncedDispatchFilterChange(inputType, data);
  };

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    dispatchFilterChange(name, value);
  };

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  useEffect(() => {
    dispatch(
      filterChange({
        resource,
        filters: {
          fromCreatedAt: time().subtract(1, 'months'),
          toCreatedAt: time(),
          profileId: record.id,
        },
      })
    );
    return () => {
      dispatch(cleanupFilters({ resource }));
    };
  }, [dispatch, record]);

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.inputsGroup}>
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={translateDateTime('from')}
              value={localValues.fromCreatedAt}
              onChange={(date) => handleDateTimeChange(date as Moment, 'fromCreatedAt')}
            />
            <DateTimePicker
              className={classes.datePickers}
              format='L - LT'
              margin='normal'
              label={translateDateTime('to')}
              value={localValues.toCreatedAt}
              onChange={(date) => handleDateTimeChange(date as Moment, 'toCreatedAt')}
            />
            <SelectTransactionType
              onChange={handleInput}
              value={localValues.operationType || ''}
              className={classes.formControl}
            />
            <SelectTransactionStatus
              onChange={handleInput}
              value={localValues.status || ''}
              className={classes.formControl}
            />
          </div>
          <BackgroundTaskExport type='BALANCE_TRANSACTION_EXPORT' queryArguments={filters} />
        </div>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    marginRight: theme.spacing(1),
    width: '100%',
    maxWidth: 180,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    minWidth: 170,
    maxWidth: 180,
    flexGrow: 1,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

const i18nFilters = 'components.TransactionsHistory.Filters';

export default Filters;
