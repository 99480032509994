import { Target } from './../components/TransactionsHistory/index';
import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { TransactionsFilters, Transaction, Transfer } from '~/types';

type Response = {
  cursors: {
    next?: string;
  };
  transactions?: Transaction[];
  transfers?: Transfer[];
};

interface getTransactionsProps {
  target: Target;
  resourceId?: string;
  type?: 'transactions' | 'transfers';
  next?: string | null;
  filters?: TransactionsFilters;
}

export const getTransactions = (props: getTransactionsProps) => {
  const { target, resourceId, filters, next, type = 'transactions' } = props;
  const resultURL = `${target}${resourceId ? `/${resourceId}` : ''}/${type}`;

  return axios.get<Response>(urlWithParams(resultURL, { next, limit: 50, ...filters }));
};
