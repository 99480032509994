import React, { CSSProperties } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { Transaction, Column } from '~/types';
import { time } from '~/utils';
import { AmountField, StatusField } from '~/components';
import { hasTranslation, Longdash } from '~/utils';
import { ShowController } from '~/components/ra';
import moment from 'moment';
import ellipsis from '~/utils/ellipsis';

const ColoredCell = (props: Props) => {
  const { children, style } = props;
  return <div style={{ ...style }}>{children}</div>;
};

interface Props {
  children: any;
  rowData: Transaction;
  column: Column;
  style?: CSSProperties;
}

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;
  const translate = useTranslate();

  switch (column.id) {
    case 'dateAndTime':
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children && time(children).format('L LT')}
        </ColoredCell>
      );
    case 'bankingDay': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children && moment(children).format('L')}
        </ColoredCell>
      );
    }
    case 'amount':
    case 'postedAmount': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <AmountField amount={children} />
        </ColoredCell>
      );
    }
    case 'cpBankAccount': {
      return (
        <ColoredCell column={column} rowData={rowData} style={ellipsis}>
          <ReferenceField
            source='bankAccountId'
            reference='bank/accounts'
            link={false}
            addLabel={false}
            record={children}
            basePath='/bank/accounts'
          >
            <ShowController>
              {({ showRecord }) => {
                return <div style={ellipsis}>{showRecord?.alias || Longdash}</div>;
              }}
            </ShowController>
          </ReferenceField>
        </ColoredCell>
      );
    }
    case 'type': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children &&
          hasTranslation(
            `components.TransactionsHistory.cellComponents.OperationTypeField.${children}`
          )
            ? translate(
                `components.TransactionsHistory.cellComponents.OperationTypeField.${children}`
              )
            : children}
        </ColoredCell>
      );
    }
    case 'status': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <StatusField status={children} />
        </ColoredCell>
      );
    }
    case 'party':
    case 'partyAccount':
    case 'cpAccount':
    case 'counterparty': {
      return (
        <ColoredCell column={column} rowData={rowData} style={ellipsis}>
          {children}
        </ColoredCell>
      );
    }
    default: {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children}
        </ColoredCell>
      );
    }
  }
};

export default CustomCell;
