import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import { EditForm } from '~/components/ra';
import onError from '~/errorsHandler';

import LocalizationForm from './LocalizationForm';
import { Drawer } from '~/components';

interface EditDrawerProps {
  record?: any;
  open: boolean;
  onClose: () => void;
}

const useEditTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.localization.edit.${key}`);
};

const EditDrawer: FC<EditDrawerProps> = (props) => {
  const { record, open, onClose } = props;

  const handleError = (error: any) => {
    onError(error);
  };

  const handleClose = () => {
    onClose();
  };

  const t = useEditTranslate();

  return (
    <Drawer open={open} onClose={handleClose} heading={t('title')}>
      <EditForm
        resource="localization"
        record={record}
        onSuccess={() => {
          handleClose();
        }}
        onError={handleError}
        withDelete={!!record.value}
        onDeleteSuccess={handleClose}
      >
        <LocalizationForm edit />
      </EditForm>
    </Drawer>
  );
};

export default EditDrawer;
