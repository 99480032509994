import React, { Fragment, useEffect, useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Title, VirtualizedList } from '~/components';
import { RootState } from '~/types';
import { EventType, EventFilters } from '~/types/Event';
import {
  selectors,
  ResourceListType,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column } from '~/components/VirtualizedList';
import { getEvents } from '~/api/events';
import CustomCell from './components/CustomCell';
import Filters from './components/Filters';
import { NoPermissions } from '~/components/ra';
import { useTranslateEventsList, resource } from '.';
import { invalidateEventEntities } from '~/hooks/eventEntities';

interface Props {
  history: any;
}

const EventsList = (props: Props) => {
  const { history } = props;
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const { data, hasNextPage, isNextPageLoading } = useSelector<
    RootState,
    ResourceListType<EventType>
  >(selectors.getList(resource));
  const filters = useSelector(selectors.getFilters<EventFilters>(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const t = useTranslateEventsList();

  const firstEntering = history.action !== 'POP';
  useEffect(() => {
    if (firstEntering) invalidateEventEntities();
  }, [firstEntering]);

  const loadNextPage = async () => {
    if (isNextPageLoading) return;
    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } = await getEvents({
        next: cursors.next || undefined,
        ...filters,
      });
      dispatch(
        getVirtualizedListSuccess({
          resource,
          responseData: { cursors: data.cursors, records: data.events },
          queryToken,
        })
      );
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns = useMemo<Column<EventType>[]>(
    () => [
      {
        selectCellData: (rowData) => rowData.timestamp,
        cellDataWrapperComponent: CustomCell,
        id: 'createdAt',
        header: t('timestamp'),
        style: {
          flexBasis: 100,
          minWidth: 100,
          maxWidth: 150,
        },
      },
      {
        selectCellData: (rowData) => rowData.topic,
        cellDataWrapperComponent: CustomCell,
        id: 'topic',
        header: t('topic'),
        style: {
          flexBasis: 300,
          minWidth: 300,
        },
      },
      {
        selectCellData: (rowData) => rowData.actors,
        id: 'actor',
        cellDataWrapperComponent: CustomCell,
        header: t('actor'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 250,
        },
      },
      {
        selectCellData: (rowData) => rowData.objects,
        cellDataWrapperComponent: CustomCell,
        id: 'object',
        header: t('object'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 250,
        },
      },
      {
        selectCellData: (rowData) => rowData.tags,
        cellDataWrapperComponent: CustomCell,
        id: 'tags',
        header: t('tags'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 200,
        },
      },
      {
        selectCellData: (rowData) => Object.entries(rowData?.processing?.state ?? {}),
        cellDataWrapperComponent: CustomCell,
        id: 'processingStatuses',
        header: t('processingStatuses'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 180,
        },
      },
    ],
    [t]
  );

  const getRowLink = (data: EventType) => {
    return `/events/${data.id}/show`;
  };

  if (!permissions) return null;
  if (!permissions.includes('event.list')) return <NoPermissions />;

  return (
    <Fragment>
      <Title resource={resource} />
      <Filters cleanFilters={history.action !== 'POP'} />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<EventType>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          filters={filters}
          data={data}
          loadNextPage={loadNextPage}
          outterTable
          rowHeight={48}
          emptyText={t('empty')}
          getRowLink={getRowLink}
        />
      </Paper>
    </Fragment>
  );
};

const fixBottomShadow = { paddingBottom: 2 };

export default EventsList;
