import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  deleteExternalProfileLink,
  getExternalProfileLinks,
  getExternalProfileLinkSystems,
} from '~/api/externalProfileLinks';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';

const externalProfileLinksByProfileId = (profileId: string): string =>
  `externalProfileLinks/${profileId}`;

export const useClearExternalProfileLinksCacheByProfileId = (profileId: string) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(externalProfileLinksByProfileId(profileId));
  };
};

export const useExternalProfileLinkQuery = (
  profileId: string,
  options?: {
    onSuccess?: (res: ExternalProfileLink[]) => void;
    enabled?: boolean;
  }
) => {
  const key = externalProfileLinksByProfileId(profileId);
  return useQuery<ExternalProfileLink[]>(
    key,
    () => getExternalProfileLinks(profileId).then((res) => res.data),
    options
  );
};

export const useExternalProfileLinkDelete = (id: string, profileId: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteExternalProfileLink(id), {
    onSuccess() {
      queryClient.invalidateQueries(externalProfileLinksByProfileId(profileId));
    },
  });
};

export const useExternalProfileLinkSystems = (options?: {
  onSuccess?: (res: string[]) => void;
  enabled?: boolean;
}) => {
  return useQuery<string[]>(
    `externalProfileLinks/systems`,
    () => getExternalProfileLinkSystems().then((res) => res.data),
    options
  );
};
