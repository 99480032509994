import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { useAddressConfig } from '~/hooks';
import { groupFields } from './AddressForm/utils';
import { AddressFieldDashboardConfig } from '~/hooks/useAddressConfig';
import CountryField from '../CountryField';
import { Address, useTranslateAddress } from './AddressForm';
import TextField from '../TextField';
import H3 from '../H3';
import { useGetMapApiKey } from '../GoogleMap/controller';
import GoogleMap from '../GoogleMap';
import MapMarker from '../GoogleMap/MapMarker';
import keyToLabel from '~/utils/keyToLabel';

interface Prpos {
  address: Address;
  className?: string;
  withIcon?: boolean;
}

const AddressView: FC<Prpos> = (props) => {
  const { address, withIcon = false, className } = props;
  const conf = useAddressConfig(address.country || 'default');
  const t = useTranslateAddress();
  const { data: mapApiKey } = useGetMapApiKey();

  const getField = (fieldConfig: AddressFieldDashboardConfig): JSX.Element => {
    const value = lodashGet(address, fieldConfig.source);
    return (
      <TextField label={fieldConfig.label} textWrap>
        {fieldConfig.type === 'country' && value ? <CountryField data={value} /> : value}
      </TextField>
    );
  };

  const geolocation =
    address?.geolocation?.latitude &&
    address?.geolocation?.longitude &&
    !isNaN(Number(address.geolocation.latitude)) &&
    !isNaN(Number(address.geolocation.longitude))
      ? {
          lat: Number(address.geolocation.latitude),
          lng: Number(address.geolocation.longitude),
        }
      : undefined;

  if (!address || !conf.data) return null;

  const otherProperties = Object.keys(address).filter((key) => {
    return key !== 'geolocation' && conf.data?.find((conf) => conf.source === key) === undefined;
  });

  return (
    <div className={className}>
      <Grid container spacing={2} direction="column">
        {groupFields(conf.data)([['city', 'cityLocalized']])?.map((item) => {
          if (Array.isArray(item)) {
            return (
              <Grid container item spacing={1} key={item[0].source}>
                {item.map((fieldConfig) => {
                  return (
                    <Grid item xs={Math.floor(12 / item.length) as any}>
                      {getField(fieldConfig)}
                    </Grid>
                  );
                })}
              </Grid>
            );
          }
          return (
            <Grid item xs={12} key={item.source}>
              {getField(item)}
            </Grid>
          );
        })}
        {otherProperties.map((otherProperty) => (
          <Grid item xs={12} key={otherProperty}>
            <TextField label={keyToLabel(otherProperty)} textWrap>
              {address[otherProperty]}
            </TextField>
          </Grid>
        ))}
      </Grid>
      <H3 icon={withIcon ? <LocationOnIcon /> : undefined} noPaddingLeft={withIcon}>
        {t('geolocation')}
      </H3>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label={t('latitude')}>{address.geolocation?.latitude}</TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField label={t('longitude')}>{address.geolocation?.longitude}</TextField>
        </Grid>
        {geolocation && mapApiKey && (
          <Grid item xs={12} style={mapStyle}>
            <GoogleMap center={geolocation} zoom={14} options={{ fullscreenControl: false }}>
              <MapMarker lat={geolocation.lat} lng={geolocation.lng} />
            </GoogleMap>
          </Grid>
        )}
        {!!address.note && (
          <Grid item xs={12}>
            <TextField label={t('note')} multiline>
              {address.note}
            </TextField>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStyle = {
  height: 323,
};

export default AddressView;
