import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import isEmpty from 'lodash/isEmpty';
import { TableCell } from '~/components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useApi } from '~/hooks';
import { getPermissions } from '~/api/roles';
import { EditForm } from '~/components/ra';
import { Role } from '~/types';

const NO_PERMISSIONS: string[] = [];

const PermissionsEdit = (props: any) => {
  const { resource, record, handleClose } = props;

  const rolePermissions: string[] = record?.permissions || NO_PERMISSIONS;

  const [{ data: allPermissions, loading }] = useApi<string[]>(() => getPermissions());

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.roles.permissions.${key}`);

  const classes = useStyles();

  const [state, setState] = useState<{ [x: string]: boolean }>({});

  const isListEmpty = isEmpty(allPermissions);
  const isStateEmpty = isEmpty(state);

  useEffect(() => {
    if (!isListEmpty && isStateEmpty) {
      const result: { [x: string]: boolean } = {};
      rolePermissions.forEach((p) => (result[p] = true));
      setState(result);
    }
  }, [isListEmpty, rolePermissions, isStateEmpty]);

  const handleChange = (key: string, checked: boolean) => () => {
    setState((prev) => ({ ...prev, [key]: !checked }));
  };

  const newPermissions = Object.keys(state).filter((key) => state[key]);
  const handleSubmit = (_: any, submitQuery: (newRecord: any) => void): void => {
    submitQuery({
      ...record,
      permissions: newPermissions,
    });
  };

  return (
    <EditForm<Role>
      closeParent={handleClose}
      resource={resource}
      record={record}
      onSubmit={handleSubmit}
      externalState={{
        ...record,
        permissions: newPermissions,
      }}
    >
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('name')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allPermissions?.map((p) => {
            const checked = Boolean(state[p]);
            return (
              <TableRow key={p}>
                <TableCell classes={{ root: classes.checkboxCell }}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange(p, checked)}
                    className={classes.checkbox}
                  />
                </TableCell>
                <TableCell>{t(p)}</TableCell>
              </TableRow>
            );
          })}
          {loading &&
            new Array(10).fill(0).map((_, index) => (
              <TableRow key={index}>
                <TableCell loading={loading} />
                <TableCell loading={loading} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </EditForm>
  );
};

export default PermissionsEdit;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  checkbox: {
    margin: -16,
    marginLeft: -11,
  },
  checkboxCell: {
    paddingRight: 0,
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));
