import React, { useEffect, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, makeStyles, colors, ClickAwayListener } from '@material-ui/core';
import lodashGet from 'lodash/get';
import { setSidebarVisibility } from 'ra-core';
import NoSsr from '@material-ui/core/NoSsr';

import { useLocation } from '~/hooks';
import { useIsSmallLaptop } from '~/hooks/styles';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    drawerPaper: {
      position: 'relative',
      overflowX: 'hidden',
      width: (props) =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.default,
      marginTop: 0,
      marginRight: ({ isSmallLaptop }) => (isSmallLaptop ? 0 : 10),
      height: 'calc(100vh - 48px)',
      minHeight: '100%',
      borderRight: 'none',
      paddingTop: '10px',
      boxSizing: 'border-box',
      '& > div': {
        paddingBottom: '10px !important',
      },
      zIndex: 'inherit',
      '& .MuiDivider-root': {
        backgroundColor: colors.blueGrey[100],
        marginTop: 4,
        marginBottom: 4,
      },
    },
  }),
  { name: 'RaSidebar' }
);

export const sidebarMenuToggler = 'sidebarMenuToggler';

const Sidebar = ({ children, classes: classesOverride, ...rest }) => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.locale); // force redraw on locale change

  const isSmallLaptop = useIsSmallLaptop();
  const classes = useStyles({ classes: classesOverride, open, isSmallLaptop });

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/') {
      dispatch(setSidebarVisibility(true));
    } else {
      dispatch(setSidebarVisibility(false));
    }
  }, [pathname, dispatch]);

  const handleClickAway = (e) => {
    const isSidebarMenuTogglerClicked = document
      .getElementById(sidebarMenuToggler)
      .contains(e.target);
    if (open === true && pathname !== '/' && !isSidebarMenuTogglerClicked) {
      dispatch(setSidebarVisibility(false));
    }
  };

  return (
    <NoSsr>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: classes.drawerPaper,
          }}
          {...rest}
        >
          {cloneElement(Children.only(children), { dense: true })}
        </Drawer>
      </ClickAwayListener>
    </NoSsr>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
