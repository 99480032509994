import React, { Fragment } from 'react';
import { TextInput, required } from 'react-admin';

import { Role } from '~/types';

import { l } from './RoleShow';

interface Props {
  edit?: boolean;
  role?: Role;
}

const RoleForm = (props: Props) => {
  const { edit } = props;
  return (
    <Fragment>
      {!edit && <TextInput source="id" label={l('id')} validate={required()} />}
      <TextInput source="name" label={l('name')} validate={required()} />
    </Fragment>
  );
};

export default RoleForm;
