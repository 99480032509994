import React from 'react';
import Grid from '@material-ui/core/Grid';

import { H2, JsonField } from '~/components';
import { Section } from '~/layout';
import { useExternalProfileLinkTranslate } from '..';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';

type Props = {
  record?: ExternalProfileLink;
  [x: string]: any;
};

const AuthData = (props: Props) => {
  const { record } = props;
  const t = useExternalProfileLinkTranslate();

  if (!record) return null;
  return (
    <Section noPaddings>
      <Grid container justify="space-between">
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('authenticationDetails')}
          </H2>
        </Grid>
        <Grid item id="section-actions"></Grid>
      </Grid>
      <Grid container justify="space-between">
        <Section>
          <JsonField json={record.authenticationDetails || {}} />
        </Section>
      </Grid>
    </Section>
  );
};

export default AuthData;
