import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import TextInput from '@material-ui/core/TextField';

import validatePhoneNumber from '~/utils/validatePhoneNumber';
import { parse } from '~/components/ra/PhoneNumberInput';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  [x: string]: any;
}

const PhoneNumberInput = (props: Props) => {
  const { onChange, value, ...rest } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (errorMessage) setErrorMessage('');
    onChange(parse(value));
  };

  const handleValidation = () => {
    if (!value) return;
    const obj = validatePhoneNumber(value);
    if (obj) setErrorMessage(t(obj.message));
  };

  const t = useTranslate();

  return (
    <TextInput
      label={t('components.ra.inputs.PhoneNumberInput')}
      value={value}
      onChange={handleChange}
      error={!!errorMessage}
      helperText={errorMessage}
      onBlur={handleValidation}
      {...rest}
    />
  );
};

export default PhoneNumberInput;
