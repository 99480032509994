import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2 } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './IntegrationTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Longdash } from '~/utils';
import collectProperties from '~/utils/collectProperties';

const OtherTechnicalDataSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`otherTechnicalData.${key}`));

  const properties = collectProperties(trx.details, [
    'deal',
    'remittance',
    'senderExternalSource',
    'recipientExternalSource',
    'reversalTransaction',
    'revertedTransaction',
    'approvalTransaction',
    'approvedTransaction',
    'settlementTransaction',
    'settledTransaction',
    'color',
    'icon',
    'tradeName',
    'terminal',
    'employee',
    'location',
    'termsAndConditions',
    'recipientName',
    'recipientPhoneNumber',
    'payer',
    'payee',
    'settlement',
    'originator',
    'response',
    'idPool',
    'senderName',
    'baseAmount',
    'beneficiaryAmount',
    'service',
    'report',
    'comment',
    'currencyConversion.sender.rate',
    'currencyConversion.sender.instructedRate',
    'currencyConversion.recipient.rate',
    'currencyConversion.recipient.instructedRate',
    'serviceCharge',
  ]);

  return (
    <>
      <H2 topSpacing={0} bottomSpacing={2}>
        {t('_')}
      </H2>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container item xs={12}>
          <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
            <TableHead>
              <TableRow>
                <TableCell>{t('parameter')}</TableCell>
                <TableCell>{t('data')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.length > 0 ? (
                properties.map((item) => (
                  <TableRow key={item.key}>
                    <TableCell>{item.key}</TableCell>
                    <TableCell>
                      {typeof item.value === 'undefined'
                        ? Longdash
                        : typeof item.value === 'boolean'
                        ? item.value
                          ? 'Y'
                          : 'N'
                        : `${item.value}`}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} style={{ textAlign: 'center', height: '2.5em' }}>
                    {t('noOtherTechnicalData')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Section>
    </>
  );
};

export default OtherTechnicalDataSection;
