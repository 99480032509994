import React, { Fragment } from 'react';
import { TextInput, SelectInput, required, FormDataConsumer, useTranslate } from 'react-admin';
import { LocaleSelect } from '~/components/ra';
import { MessageTemplate } from '~/types';

interface Props {
  edit?: boolean;
  record?: any;
}

const l = (key: string): string => `resources.localization.${key}`;

const LocalizationForm = (props: Props) => {
  const { edit } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const contextFormatter = (context: Object) => JSON.stringify(context).slice(1, -1);

  return (
    <FormDataConsumer>
      {({ formData }: { formData: MessageTemplate }) => {
        return (
          <Fragment>
            <TextInput source="key" label={t('key')} validate={required()} disabled={edit} />
            <LocaleSelect
              source="locale"
              label={t('locale')}
              validate={required()}
              disabled={edit}
            />
            <SelectInput
              source="templateEngine"
              choices={[
                { id: 'plain', name: t('templateEngine.plain') },
                { id: 'thymeleaf', name: t('templateEngine.thymeleaf') },
              ]}
              label={t('templateEngine._')}
              validate={required()}
            />
            <TextInput
              defaultValue={formData?.defaultValue}
              source="value"
              label={t('value')}
              validate={required()}
              multiline
            />
            {edit && (
              <TextInput
                source="context"
                label={t('context')}
                validate={required()}
                disabled={edit}
                multiline
                format={contextFormatter}
              />
            )}
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default LocalizationForm;
