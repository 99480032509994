import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { time } from '~/utils';

import { TextInput, SelectInput } from '~/components';
import { selectors, filterChange } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { resource, useTranslateBatchesList } from '../index';
import { useTranslate } from 'react-admin';
import { BatchFilters } from '~/types/Batch';
import { statusLabel } from '~/components/StatusField';

const Filters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<BatchFilters>(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment | string[] | undefined) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);
  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const classes = useStyles();
  const translate = useTranslate();
  const translateBatches = useTranslateBatchesList();
  const t = (key: string) => translateBatches(`filters.${key}`);

  return (
    <Fragment>
      {filters && (
        <div className={classes.root}>
          <div className={classes.inputsGroup}>
            <SelectInput
              name="type"
              onChange={debouncedHandleInput}
              value={localValues.type}
              label={t('type')}
              className={classes.input}
              options={[{ value: 'PAYROLL', text: t('batchType.PAYROLL') }]}
              allowEmpty
            />
            <TextInput
              name="actorId"
              onChange={debouncedHandleInput}
              value={localValues.actorId}
              label={t('actor')}
              className={classes.input}
              disableHelperText
            />
            <TextInput
              name="objectId"
              onChange={debouncedHandleInput}
              value={localValues.objectId}
              label={t('object')}
              className={classes.input}
              disableHelperText
            />
            <DateTimePicker
              className={classes.input}
              format="L - LT"
              margin="normal"
              label={t('fromCreatedAt')}
              value={localValues.fromCreatedAt || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'fromCreatedAt')}
              maxDate={localValues.toCreatedAt || time().format()}
              clearable
            />
            <DateTimePicker
              className={classes.input}
              format="L - LT"
              margin="normal"
              label={t('toCreatedAt')}
              value={localValues.toCreatedAt || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'toCreatedAt')}
              maxDate={time().format()}
              minDate={localValues.fromCreatedAt || undefined}
              clearable
            />
            <SelectInput
              name="status"
              label={t('status')}
              value={localValues.status}
              className={classes.input}
              onChange={debouncedHandleInput}
              options={[
                { value: 'CONSENT', text: translate(statusLabel('CONSENT')) },
                { value: 'INITIAL', text: translate(statusLabel('INITIAL')) },
                { value: 'IN_PROGRESS', text: translate(statusLabel('IN_PROGRESS')) },
                {
                  value: 'COMPLETED_WITH_ERRORS',
                  text: translate(statusLabel('COMPLETED_WITH_ERRORS')),
                },
                { value: 'COMPLETED', text: translate(statusLabel('COMPLETED')) },
                { value: 'REJECTED', text: translate(statusLabel('REJECTED')) },
              ]}
              allowEmpty
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const inputWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexGrow: 1,
      marginTop: 0,
    },
    flexWrap: 'wrap',
  },
  input: {
    width: inputWidth,
    maxWidth: inputWidth,
  },
  tagsInput: {
    maxWidth: 2 * inputWidth + theme.spacing(1),
  },
}));

export default Filters;
