import React from 'react';
import { TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

import validatePhoneNumber from '~/utils/validatePhoneNumber';

const useStyles = makeStyles({
  root: {
    maxWidth: 256,
  },
});

export const parse = (value: string) => {
  if (isValidPhoneNumber(value)) {
    try {
      const number = parsePhoneNumber(value);
      return number!.formatInternational();
    } catch (error) {
      return value;
    }
  } else {
    return value;
  }
};

export const format = (value: string) => {
  if (isValidPhoneNumber(value)) {
    try {
      const number = parsePhoneNumber(value);
      return number!.formatInternational();
    } catch (error) {
      return value;
    }
  } else {
    return value;
  }
};

interface Props {
  source?: string;
  validate?: any;
  [x: string]: any;
}

const PhoneNumberInput = (props: Props) => {
  const { source = 'phoneNumber', fullWidth, validate = [], ...rest } = props;
  const classes = useStyles();

  return (
    <TextInput
      className={cx({ [classes.root]: !fullWidth })}
      fullWidth={fullWidth}
      source={source}
      {...rest}
      validate={[validatePhoneNumber, ...[validate].flat()]}
      parse={parse}
    />
  );
};

PhoneNumberInput.defaultProps = {
  label: 'components.ra.inputs.PhoneNumberInput',
  fullWidth: false,
};

export default PhoneNumberInput;
