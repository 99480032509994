export default {
  accounts: 'Accounts',
  createdAt: 'Added',
  status: 'Status',
  country: 'Country',
  primaryDocument: 'Primary document',
  phoneNumber: 'Phone',
  email: 'Email',
  verify: 'Verify',
  transferType: 'Transfer type',
  addBeneficiary: 'Add beneficiary',
  editBeneficiary: 'Edit beneficiary',
  BUSINESS: 'Business',
  INDIVIDUAL: 'Individual',
  firstName: 'First name',
  lastName: 'Last name',
  number: 'Number',
  payout: 'Payout',
  payouts: 'Payouts',
  searchPlaceholder: 'Search by name',
  title: 'Beneficiaries',
  notFound: 'No beneficiaries found.',
  badSchema:
    "Beneficiary data schema is not provided or invalid. Check 'Deal' feature configuration of corresponding solution.",
  type: 'Type',
  iAmAdding: "I'm adding",
  selectCountry: 'Select a country',
  legalType: 'Legal type',
  legalName: 'Legal name',
  addIdentificationInfo: 'Add identification information',
  identificationMethod: 'Identification method',
  identificationNumber: 'Identification number',
  companyLegalName: 'Company legal name',
  addAddress: 'Add address',
  addContacts: 'Add contacts',
  selectTransferType: 'Select an transfer type',
  makePayout: 'Make payout',
  sendMoney: 'Send money',
  addAccount: 'Add account',
  sureDelete: 'Are you sure delete this item?',
  deleteDescription: 'This action is irreversible!',
  inputPutName: "Input person's full name",
  toAccount: 'To account',
  fromAccount: 'From account',
  LIMIT_REACHED: 'Limit reached',
  NOT_SUFFICIENT_FUNDS: 'Not sufficient funds',
  transfer: 'Transfer {{amount}}',
  transferDone: 'Transfer done!',
  transferId: 'Transfer ID: {{id}}',
  transferDetails: 'Transfer details',
  transferDetailsPlaceholder: 'Input transfer details for the recipient',
  reference: 'Reference',
  convertedAmount: 'Converted amount',
  documents: 'Documents',
};
