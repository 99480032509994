import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2, TextField } from '~/components';
import { Section } from '~/layout';
import { useExternalSource } from '~/hooks/externalSources';
import { ExternalSourceDetails } from '~/types/TransactionsHistory';
import collectProperties from '~/utils/collectProperties';
import { makeLabel } from './ContextTab';
import useStyles from './useStyles';

export const ExternalSourceField = (props: { id: string; label?: string }) => {
  const { id, label } = props;
  const { data: externalSource, isLoading } = useExternalSource(id);

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`externalSource.${key}`));

  return (
    <TextField label={label || t('name')} loading={isLoading} textWrap>
      {externalSource && externalSource.name}
    </TextField>
  );
};

const ExternalSourceSection = (props: { externalSource: ExternalSourceDetails; label: string }) => {
  const { externalSource, label } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`externalSource.${key}`));

  const properties = collectProperties(externalSource, ['id', `type`, `name`]);

  return (
    <>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {label}
        </H2>
      </header>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          {externalSource.id && (
            <Grid item xs={4}>
              <ExternalSourceField id={externalSource.id} />
            </Grid>
          )}
          {externalSource.type && (
            <Grid item xs={4}>
              <TextField label={t('type')}>{externalSource.type}</TextField>
            </Grid>
          )}
        </Grid>
        {properties.length > 0 && (
          <Grid container item xs={12}>
            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('parameter')}</TableCell>
                  <TableCell>{t('data')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map((item) => (
                  <TableRow key={item.key}>
                    <TableCell title={item.key}>{item.key}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Section>
    </>
  );
};

export default ExternalSourceSection;
