export default {
  name: 'Profile link |||| Profile links',
  filter: {
    profileType: 'Profile type',
    profileId: 'Profile ID',
    system: 'System',
    referenceId: 'Reference ID',
    status: 'Status',
    createdFrom: 'Created from',
    createdTo: 'Created to',
    updatedFrom: 'Updated from',
    updatedTo: 'Updated to',
  },
  fields: {
    profile: 'Profile',
    system: 'System',
    referenceId: 'Reference IDs',
    created: 'Created',
    updated: 'Last update',
    status: 'Status',
    profileTitle: 'Profile title',
    profileType: 'Profile type',
  },
  profileField: {
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
  },
  profileTypeInput: {
    any: 'Any',
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
  },
  statusInput: {
    any: 'Any',
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    NOT_READY: 'Not ready',
    REJECTED: 'Rejected',
    REQUESTED: 'Requested',
  },
  systemInput: {
    any: 'Any',
  },
  tabs: {
    details: 'Details',
    authData: 'Auth data',
    systemLog: 'System log',
  },
  commonDetails: 'Details',
  identificationDetails: 'Identification details',
  authenticationDetails: 'Authentication details',
};
