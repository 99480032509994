import React, { FC } from 'react';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';

type Props = Omit<LinearProgressProps, 'variant'>;

const ProgressBar: FC<Props> = (props) => {
  const { value, ...rest } = props;
  const classes = useStyles(props);
  const infinityClasses = useInfinityStyles();
  const isFinite = Number.isFinite(value);

  return isFinite ? (
    <LinearProgress
      value={isFinite ? value : 0}
      variant="determinate"
      classes={classes}
      {...rest}
    />
  ) : (
    <div className={infinityClasses.root} />
  );
};

const height = 2;
const borderRadius = height / 2;

const useStyles = makeStyles((theme) => ({
  root: {
    height,
    borderRadius,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius,
    backgroundColor: ({ value = 0 }: Props): string => {
      const yellowThreshold = 50;
      const scaledValue = (() => {
        if (value < yellowThreshold) return 0;
        const scale = 100 / yellowThreshold;
        return (value - yellowThreshold) * scale;
      })();
      const green = Color(theme.palette.success.main);
      const yellow = Color(theme.palette.warning.main);
      const red = Color(theme.palette.error.main);
      if (value <= 90) {
        return green
          .mix(yellow, scaledValue / 100)
          .mix(red, scaledValue / 200)
          .hex();
      }
      return theme.palette.error.main;
    },
  },
}));

const useInfinityStyles = makeStyles((theme) => ({
  root: {
    height: 0,
    width: '100%',
    borderBottom: `${height}px dashed ${theme.palette.grey[200]}`,
  },
}));

export default ProgressBar;
