import React from 'react';
import { Link } from 'react-admin';
import lodashGet from 'lodash/get';

class ReferenceLink extends React.Component {
  render() {
    const { record, source, referenceType, parentId, parentName, parentType } = this.props;
    return (
      <Link
        to={{
          pathname: `/${referenceType}/${record.id}/show`,
          state: { parentId, parentName, parentType },
        }}
      >
        {lodashGet(record, source || 'name')}
      </Link>
    );
  }
}

export default ReferenceLink;
