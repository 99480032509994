import React from 'react';
import { useTranslate } from 'react-admin';
import { JSONSchema6 } from 'json-schema';
import { Box, Divider, Typography } from '@material-ui/core';

import AmountField from '~/components/AmountField';
import TextField from '~/components/TextField';

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const FeeField = ({ formData, uiSchema }: Props) => {
  const i18nPrefix = uiSchema['ui:i18nPrefix'];
  const raTranslate = useTranslate();

  const translate = (key: string): string => {
    return raTranslate(`${i18nPrefix}.${key}`);
  };

  let fieldForType = <></>;
  switch (formData.type) {
    case 'fixedDebitFee':
    case 'fixedDebitFeeDecrease':
    case 'fixedCreditFee':
      fieldForType = (
        <Box pt={1} pb={1}>
          <AmountField amount={formData.amount} label={translate('amount')} />
        </Box>
      );
      break;
    case 'percentDebitFee':
    case 'percentDebitFeeDecrease':
    case 'percentCreditFee':
      fieldForType = (
        <>
          <TextField label={translate('percent')}>{`${formData.percent}%`}</TextField>
          <Box pt={1} pb={1}>
            <AmountField amount={formData.minAmount} label={translate('minAmount')} />
          </Box>
          <Box pt={1} pb={1}>
            <AmountField amount={formData.maxAmount} label={translate('maxAmount')} />
          </Box>
        </>
      );
      break;
    default:
      break;
  }

  const title = uiSchema['ui:title'] ? (
    <Typography variant="h5">{uiSchema['ui:title']}</Typography>
  ) : null;

  return (
    <Box>
      {title}
      <Divider />
      <Box mt={2}>
        <TextField label={translate('type._')}>{translate(`type.${formData.type}`)}</TextField>
      </Box>
      <TextField label={translate('name')}>{formData.name || '—'}</TextField>
      {fieldForType}
    </Box>
  );
};

export default FeeField;
