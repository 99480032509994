import React from 'react';
import { number, minValue, maxValue, NumberInput, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import { Address } from '../AddressForm';
import GoogleMap, { isValidCoords } from '../../GoogleMap';
import { Coords } from 'google-map-react';
import { useField, useForm } from 'react-final-form';
import MapMarker from '../../GoogleMap/MapMarker';
import { useGetMapApiKey } from '../../GoogleMap/controller';

interface Props {
  [x: string]: any;
  source?: string;
  record?: any;
  defaultCountry?: string;
}

const GeolocationInput = (props: Props) => {
  const { source = 'geolocation', defaultCountry, ...rest } = props;
  const { data: mapApiKey } = useGetMapApiKey();

  const latSource = `${source}.latitude`;
  const lngSource = `${source}.longitude`;
  const latState = useField<number | undefined>(latSource);
  const lngState = useField<number | undefined>(lngSource);
  const form = useForm();

  const geolocation = {
    lat: Number(latState.input.value || 0),
    lng: Number(lngState.input.value || 0),
  };

  const handleMapClick = ({ lat, lng }: Coords) => {
    form.change(source, { latitude: lat, longitude: lng });
  };

  const translate = useTranslate();
  const label = (key: string): string => translate(`components.ra.inputs.GeolocationInput.${key}`);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NumberInput
          {...rest}
          source={`${source}.latitude`}
          label={label('latitude')}
          validate={[number(), minValue(-90), maxValue(90), validateGeolocationLatitude(source)]}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          {...rest}
          source={`${source}.longitude`}
          label={label('longitude')}
          validate={[number(), minValue(-180), maxValue(180), validateGeolocationLongitude(source)]}
        />
      </Grid>
      {mapApiKey && (
        <Grid item xs={12} style={mapStyle}>
          <GoogleMap
            onClick={handleMapClick}
            center={geolocation}
            zoom={isValidCoords(geolocation) ? 14 : undefined}
            options={{ fullscreenControl: false }}
            defaultCountry={defaultCountry}
          >
            <MapMarker lat={geolocation.lat} lng={geolocation.lng} />
          </GoogleMap>
        </Grid>
      )}
    </Grid>
  );
};

const validateGeolocationLatitude = (source: string) => (_: any, values: any) => {
  const geolocation: Address['geolocation'] = lodashGet(values, source);
  if (geolocation?.longitude) {
    const { latitude } = geolocation;
    if (!latitude)
      return {
        message: 'ra.validation.required',
        isRequired: true,
      };
  }
};

const validateGeolocationLongitude = (source: string) => (_: any, values: any) => {
  const geolocation: Address['geolocation'] = lodashGet(values, source);
  if (geolocation?.latitude) {
    const { longitude } = geolocation;
    if (!longitude)
      return {
        message: 'ra.validation.required',
        isRequired: true,
      };
  }
};

const mapStyle = {
  height: 371,
  marginTop: -16,
  marginBottom: 16,
};

GeolocationInput.defaultProps = {
  addLabel: true,
  label: 'components.ra.inputs.GeolocationInput._',
};

export default GeolocationInput;
