import { crudGetList } from 'react-admin';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { history } from './../App';
import { deleteRole } from '~/api/roles';

export const useDeleteRole = () => {
  const dispatch = useDispatch();

  return useMutation(deleteRole, {
    onSuccess() {
      dispatch(crudGetList('roles', { page: 1, perPage: 25 }, { field: 'id', order: 'DESC' }, {}));
      history.push('/roles?page=1');
    },
  });
};
