import { getFile, FileType } from '~/api/utils';
import { useDeepEffect, useReduxState, useMutableCallback } from '~/hooks';

type LoadImageType = {
  dataURL: string | undefined;
  loading: boolean;
  error: null | string;
};

const initialState: LoadImageType = {
  dataURL: undefined,
  loading: false,
  error: null,
};

const useImagesCache = () => {
  return useReduxState<Record<string, LoadImageType>>('imagesCache', {});
};

const useLoadImage = (file: FileType | undefined, resource?: string): LoadImageType => {
  const [cache, setCache] = useImagesCache();
  const fileId = file?.id || file?.rawFile?.name!;
  const state = cache[fileId] || initialState;
  const setState = (newState: LoadImageType) =>
    file && setCache((prev) => ({ ...prev, [fileId]: newState }));

  const loadImage = useMutableCallback(async () => {
    if (state.dataURL || state.loading) return;
    const isRaw = Boolean(file?.rawFile);
    const actualFile = file?.rawFile || file;
    setState({ ...initialState, loading: true });
    try {
      if (!actualFile || !isImage(actualFile)) {
        throw new Error('This file is not an image');
      }
      let image = null;
      if (isRaw) {
        image = actualFile;
      } else {
        const { data } = await getFile(actualFile, resource);
        image = data;
      }
      const dataURL = URL.createObjectURL(image);

      setState({ dataURL, loading: false, error: null });
    } catch (err) {
      const error: any = err;
      setState({ dataURL: undefined, loading: false, error: error.message });
    }
  });

  useDeepEffect(() => {
    file && loadImage();
  }, [file]);

  return state;
};

const isImage = (file: FileType) => {
  return file && (file.type || file.mimeType || '').startsWith('image');
};

export default useLoadImage;
