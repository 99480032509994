import React from 'react';
import { useTranslate, Button } from 'react-admin';
import BackgroundTask from '~/components/BackgroundTask';
import PublishIcon from '@material-ui/icons/Publish';
import { usePermissions } from 'react-admin';

const ImportAction = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.individuals.fields.${key}`);
  const { permissions } = usePermissions();
  const hasIndividualImportPermission = permissions?.includes('individual.import');
  if (!hasIndividualImportPermission) return null;
  return (
    <BackgroundTask
      type='INNBUCKS_INDIVIDUAL_IMPORT'
      dialogTitle={t('importCSV')}
      dialogType='import'
    >
      <Button label='import'>
        <PublishIcon />
      </Button>
    </BackgroundTask>
  );
};

export default ImportAction;
