import React from 'react';
import { ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import LanguageIcon from '@material-ui/icons/Language';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { TextField, KycLevelField } from '~/components/ra';
import { CountryField, TextField as TextFieldComponent, StatusField } from '~/components';
import { ShieldCheckmarkIcon, RibbonIcon } from '~/img';
import { Business } from '~/types';
import { AsideBase } from '~/layout';
import { useQuery } from 'react-admin';
import palette from '~/theme/palette';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  }
}));

const label = (key: string): string => `resources.businesses.fields.${key}`;

interface Props {
  [x: string]: any;
  record?: Business;
  changelogMode?: boolean;
}

const Aside = (props: Props) => {
  const classes = useStyles();
  const { changelogMode } = props;
  const { id, country, status, kycStatus, deleted } = props.record || {};
  const { data: counters, loading } = useQuery({
    type: 'getResource',
    payload: {
      url: `businesses/${id}/counters`,
    },
  });

  return (
    <AsideBase className={changelogMode ? classes.changelogMode : undefined}>
      <Grid container spacing={2} className={classes.layout}>
        <Grid item xs={12}>
          <TextField label={label('status._')} icon={<RibbonIcon />} {...props}>
            <StatusField status={status} deleted={deleted} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <ReferenceField
            source="solutionId"
            reference="solutions"
            link={false}
            addLabel={false}
            {...props}
          >
            <TextField source="name" label={label('solutionId')} icon={<StarIcon />} />
          </ReferenceField>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent label={label('country')} icon={<LanguageIcon />}>
            <CountryField>{country}</CountryField>
          </TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TextField label={label('kycLevel')} icon={<ShieldCheckmarkIcon />} {...props}>
            <KycLevelField source="kycLevel" type="text" addLabel={false} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={label('kycStatus')} icon={<FindInPageIcon />} {...props}>
            <StatusField status={kycStatus || 'ACTIVE'} withColor={false} />
          </TextField>
        </Grid>
        <Grid item>
          <TextFieldComponent
            label={label('locations')}
            icon={<LocationOnIcon />}
            loading={loading}
            skeletonWidth={20}
          >
            {counters?.locations ? Number(counters.locations) : 0}
          </TextFieldComponent>
        </Grid>
        <Grid item>
          <TextFieldComponent
            label={label('employees')}
            icon={<PeopleIcon />}
            loading={loading}
            skeletonWidth={20}
          >
            {counters?.employees ? Number(counters.employees) : 0}
          </TextFieldComponent>
        </Grid>
        <Grid item>
          <TextFieldComponent
            label={label('terminals')}
            icon={<SmartphoneIcon />}
            loading={loading}
            skeletonWidth={20}
          >
            {counters?.terminals ? Number(counters.terminals) : 0}
          </TextFieldComponent>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

export default Aside;
