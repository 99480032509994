import axios from '~/utils/axios';
import _axios from 'axios';
import querystring from 'querystring';

import { LYC_API_URL } from '~/env';

export const beginKycCheck = (profileType: string, profileId: string, targetKycLevel: string) => {
  return axios.post(
    `/kyc/startKycLevelWorkflow?profileType=${profileType}&profileId=${profileId}&targetKycLevel=${targetKycLevel}`
  );
};

export const resetKycProgress = (profileType: string, profileId: string) => {
  return axios.post(`/kyc/resetKycProgress?profileType=${profileType}&profileId=${profileId}`);
};

export const getKycCredentials = () => {
  return axios.get<{ username: string; password: string }>('/kyc/kycAppCredentials');
};

export const kycAppLogin = (username: string, password: string) => {
  return _axios.post(
    `${LYC_API_URL}/camunda/api/admin/auth/user/default/login/tasklist`,
    querystring.stringify({
      username,
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

export const openKycApp = () => {
  window.open(`/camunda/app/tasklist/default/`);
};
