import React from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

type Props = {
  helperText?: string | boolean;
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  name?: string;
  className?: string;
  disableHelperText?: boolean;
  focused?: boolean;
  [x: string]: any;
};

const TextInput = (props: Props) => {
  const {
    helperText,
    label,
    required,
    error,
    fullWidth,
    className = '',
    disableHelperText = false,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <FormControl
      className={cx({ [classes.fullWidth]: fullWidth, [className]: !!className })}
      error={!!error}
    >
      <InputLabel required={required} htmlFor={`input-${props.name}`}>
        {label}
      </InputLabel>
      <FilledInput
        id={`input-${props.name}`}
        className={cx({ [classes.fullWidth]: fullWidth })}
        error={!!error}
        {...rest}
      />
      {!disableHelperText && (
        <FormHelperText id={`input-${props.name}`}>{helperText || '\u0020'}</FormHelperText>
      )}
    </FormControl>
  );
};

export default TextInput;
