import React from 'react';
import { useTranslate, required, SelectInput } from 'react-admin';

import JsonInput from '~/components/ra/JsonInput';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { EditForm } from '../ra';
import { useClearExternalProfileLinksCacheByProfileId } from '~/hooks/externalProfileLinks';

interface Props {
  link: ExternalProfileLink;
  handleClose?: () => void;
}

const ExternalProfileLinkForm = (props: Props) => {
  const { link, handleClose } = props;

  const translate = useTranslate();
  const label = (key: string) => `components.ExternalProfileLinks.form.${key}`;
  const t = (key: string): string => translate(label(key));

  const statusLabel = (status: string) => `components.ra.fields.StatusField.${status}`;

  const statusOptions = [
    { id: 'ACTIVE', name: statusLabel('ACTIVE') },
    { id: 'REQUESTED', name: statusLabel('REQUESTED') },
    { id: 'NOT_READY', name: statusLabel('NOT_READY') },
    { id: 'REJECTED', name: statusLabel('REJECTED') },
    { id: 'CLOSED', name: statusLabel('CLOSED') },
  ];

  const clearExternalProfileLinksCacheByProfileId = useClearExternalProfileLinksCacheByProfileId(
    link.profileId
  );

  return (
    <EditForm
      record={link}
      resource="externalProfileLinks"
      closeParent={handleClose}
      onSuccess={() => {
        clearExternalProfileLinksCacheByProfileId();
      }}
    >
      <SelectInput choices={statusOptions} source="status" validate={required()} />
      <JsonInput
        source="identificationDetails"
        label={label('identificationDetails')}
        validationError={t('Invalid JSON')}
        required
        rows={30}
      />
    </EditForm>
  );
};

export default ExternalProfileLinkForm;
