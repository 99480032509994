import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useRightContentWidth } from '~/hooks/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    width: ({ width }: any) => width,
    minWidth: ({ width }: any) => width,
    padding: theme.spacing(2),
  },
}));

interface Props {
  width?: number;
  className?: string;
}

const AsideBase: FC<Props> = (props) => {
  const { children, width, className } = props;

  const defaultWidth = useRightContentWidth();
  const resultWidth = width ?? defaultWidth;
  const classes = useStyles({ width: resultWidth });

  return <Paper className={cx(classes.root, className)}>{children}</Paper>;
};

export default AsideBase;
