import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { H2, TextField, StatusField } from '~/components';
import { Section } from '~/layout';
import { Partner } from '~/types';
import PartnerEdit, { PartnerEditProps } from '../PartnerEdit';
import { l } from '../PartnerCreate';
import { PartnerClose } from './index';

interface Props extends PartnerEditProps {
  loading?: boolean;
  record?: Partner;
  permissions?: string[];
}

const Profile = (props: Props) => {
  const { permissions, resource, loading, record } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const classes = useStyles();

  return (
    <Section noPaddings>
      <Grid container justify="space-between" className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
            {t(`partnerDetails`)}
          </H2>
        </Grid>
        <Grid item container xs={4} id="section-actions" spacing={2} justify="flex-end">
          <Grid item>
            <PartnerEdit permissions={permissions} resource={resource} record={record} />
          </Grid>
          <Grid item>
            <PartnerClose permissions={permissions} resource={resource} record={record} />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        <Grid container spacing={8}>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('name')} loading={loading}>
                {record?.name}
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('transfersDirection')} loading={loading}>
                {record?.transfersDirection && t(record.transfersDirection)}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('status')} loading={loading}>
                {record?.status && <StatusField status={record.status} deleted={record.deleted} />}
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('externalId')} loading={loading}>
                {record?.externalId}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('description')} multiline loading={loading}>
                {record?.description}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default Profile;
