const detachKeys = <D = any>(arr: any[] | undefined, key = 'id'): [Record<string, D>, string[]] => {
  const data: Record<string, D> = {};
  const keysArr: string[] = [];

  arr &&
    arr.forEach((item) => {
      const keyValue = item[key] as string;
      keysArr.push(keyValue);
      data[keyValue] = item;
    });

  return [data, keysArr];
};

export default detachKeys;
