import React from 'react';
import { useTranslate } from 'react-admin';
import Button, { ButtonProps } from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}));

interface Props {
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  label?: string;
}

type SaveButtonProps = Props & ButtonProps;

const SaveButton = (props: SaveButtonProps) => {
  const {
    onClick,
    type = 'submit',
    loading,
    className,
    variant = 'contained',
    label,
    ...rest
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Button
      color="primary"
      variant={variant}
      size="medium"
      type={type}
      onClick={onClick}
      className={cx(classes.button, className)}
      disabled={loading}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
      ) : (
        <SaveIcon className={cx(classes.leftIcon, classes.icon)} />
      )}
      {label ? label : translate('ra.action.save')}
    </Button>
  );
};

export default SaveButton;
