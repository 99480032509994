import React from 'react';
import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';

import ExportButton from './ExportButton';

interface Props<D = any> {
  data: (() => object[]) | object[];
  fields?: (keyof D)[];
  fileName: string;
}

function ExportToCSV<D>(props: Props<D>) {
  const { data, fields, fileName } = props;

  const handleDownloadCSV = () => {
    const dataToExport = typeof data === 'function' ? data() : data;
    const csv = convertToCSV(dataToExport, {
      // select and order fields in the export
      columns: (fields as string[]) || Object.keys(dataToExport[0]),
      delimiter: ';',
    });
    downloadCSV(csv, fileName); // download as 'fileName.csv` file
  };

  return <ExportButton onClick={handleDownloadCSV} />;
}

export default ExportToCSV;
