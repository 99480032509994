import React from 'react';
import { usePermissions } from 'react-admin';

import { NoPermissions } from '~/components/ra';

interface Props {
  permission: string;
  children: any;
}

const CheckPermissions = (props: Props) => {
  const { children, permission } = props;
  const { permissions } = usePermissions();
  if (!permissions) return null;

  return permissions.includes(permission) ? children : <NoPermissions />;
};

export default CheckPermissions;
