import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { OpenIcon } from '~/img';
import { Button } from 'react-admin';
import { Link } from 'react-admin';
import { usePermissions } from 'react-admin';
import { useTranslate } from 'react-admin';

interface Props {
  id: string;
}

const LinkToAccount = (props: Props) => {
  const { id } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.LinkToAccount.${key}`);
  const { permissions } = usePermissions();
  if (!permissions?.includes('account.view')) return null;
  return (
    <Link to={`/accounts/${id}/show`} target='_blank'>
      <Button label={t('goToAccount')}>
        <SvgIcon>
          <OpenIcon title='show' />
        </SvgIcon>
      </Button>
    </Link>
  );
};

export default LinkToAccount;
