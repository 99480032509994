import React, { useState } from 'react';
import { usePermissions, useTranslate, useRefresh, useGetOne } from 'react-admin';
import { useQuery } from 'react-query';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Confirmation, EditButton } from '~/components';
import { cancelAuthorization, checkCancellation, getTransaction } from '~/api/headTransactions';

interface Props {
  transaction: HeadTransaction;
  history?: any;
}

const ShowSuccess = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  return <Alert severity="success">{t('cancelSuccess')} </Alert>;
};

const ShowFailure = (props: any) => {
  const error = props.error;
  return <Alert severity="error">{error}</Alert>;
};

type DialogState = 'CLOSED' | 'PROGRESS' | 'SUCCESS' | 'ERROR';
const DELAY_MS = 2000;

const CancelAction = (props: Props) => {
  const { transaction } = props;
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  const [dialogState, setDialogState] = useState<DialogState>('CLOSED');
  const [error, setError] = useState<string>();

  const forceRefresh = useRefresh();

  // we need to wait for resource loading in order to eliminate unnecessary request after forced component update
  const { loading } = useGetOne('headTransactions', transaction.id);
  const needToCheckCancellation = transaction.status === 'PROCESSING' && !loading;

  const { data: checkStatus } = useQuery(
    ['checkCancellation', transaction.id],
    () => checkCancellation({ id: transaction.id }),
    {
      enabled: needToCheckCancellation,
      onError: () => {},
      retry: false,
    }
  );

  const checkForTransaction = () => {
    getTransaction({ id: transaction.id })
      .then(({ data: transactionWithNewStatus }) => {
        if (transactionWithNewStatus.status === 'PROCESSING') {
          checkForTransactionAfterDelay();
        } else {
          setDialogState('SUCCESS');
        }
      })
      .catch(() => {
        checkForTransactionAfterDelay();
      });
  };

  const checkForTransactionAfterDelay = () => setTimeout(() => checkForTransaction(), DELAY_MS);

  const onCancelTransaction = () => {
    setDialogState('PROGRESS');
    setError(undefined);
    cancelAuthorization({ id: transaction.id })
      .then(() => {
        checkForTransactionAfterDelay();
      })
      .catch((e) => {
        const errorText = e?.body?.message || 'Unknown error occurred';
        setError(errorText);
        setDialogState('ERROR');
      });
  };

  const isCancelAllowed = checkStatus?.data.status === 'ALLOWED' && needToCheckCancellation;

  return (
    <>
      {isCancelAllowed && (
        <Confirmation
          onConfirm={onCancelTransaction}
          confirmationSettings={{
            variant: 'modal',
            modal: { content: <Alert severity="warning">{t('cancelDanger')}</Alert> },
          }}
        >
          <EditButton
            label={t('cancelTransaction')}
            icon={<CancelIcon />}
            disabled={!permissions?.includes('headTransaction.cancel')}
            red
          />
        </Confirmation>
      )}
      <Dialog
        open={dialogState !== 'CLOSED'}
        onClose={() => {
          forceRefresh();
          setDialogState('CLOSED');
        }}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="cancel-dialog-title">{t('cancelDialogTitle')}</DialogTitle>
        <DialogContent>
          {dialogState === 'PROGRESS' ? (
            <Box mt={1} mb={3}>
              <LinearProgress />
            </Box>
          ) : dialogState === 'ERROR' ? (
            <ShowFailure error={error} />
          ) : dialogState === 'SUCCESS' ? (
            <ShowSuccess />
          ) : null}
        </DialogContent>
        <DialogActions>
          {dialogState !== 'PROGRESS' ? (
            <Button
              onClick={() => {
                forceRefresh();
                setDialogState('CLOSED');
              }}
            >
              {t(`closeDialog`)}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelAction;
