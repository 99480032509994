import React from 'react';
import Drawer from './Drawer';
import Changelog from './Changelog';
import { Skeleton } from '@material-ui/lab';
import { Changelog as ChangelogType } from '~/types/Changelog';

interface Props {
  open: boolean;
  handleClose: () => void;
  translate: (key: string) => string;
  handleTurnOnChangelogMode: (id: number) => void;
  changelogsData: ChangelogType[];
  changelogsLoading: boolean;
  hasChangelogPermissionView: boolean;
  selectedChangelog: number | undefined;
}

const ProfileHistoryDrawer = (props: Props) => {
  const {
    open,
    handleClose,
    translate,
    handleTurnOnChangelogMode,
    changelogsData,
    changelogsLoading,
    hasChangelogPermissionView,
    selectedChangelog,
  } = props;
  return (
    <Drawer heading={translate('viewProfileHistory')} open={open} onClose={handleClose}>
      {changelogsLoading && <Skeleton width='100%' height='50px' />}
      {!changelogsLoading &&
        changelogsData &&
        changelogsData.map((changelogItem: ChangelogType) => {
          return (
            <Changelog
              changelogId={changelogItem.id}
              changelogData={changelogItem}
              handleTurnOnChangelogMode={handleTurnOnChangelogMode}
              hasChangelogPermissionView={hasChangelogPermissionView}
              selectedChangelog={selectedChangelog}
            />
          );
        })}
    </Drawer>
  );
};

export default ProfileHistoryDrawer;
