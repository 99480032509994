import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import { OpenIcon } from '~/img';

const LinkButton = (props: { handleOpen?: () => void; [x: string]: any }) => {
  const { handleOpen, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classes.openIcon}>
      <IconButton onClick={handleOpen} {...rest}>
        <SvgIcon>
          <OpenIcon title="show" />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  openIcon: {
    display: 'inline-block',
    position: 'relative',
    width: 14,
    height: 14,
    '& button': {
      position: 'absolute',
      top: -6,
      width: 14,
      height: 14,
      '& svg': {
        width: 14,
        height: 14,
      },
    },
  },
}));

export default LinkButton;
