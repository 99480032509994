import React, { FC } from 'react';
import { useTranslate, useInput } from 'react-admin';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';

const TimeInput: FC<any> = (props) => {
  const { label, source, validate, minTime, maxTime, ...rest } = props;
  const translate = useTranslate();

  const input = useInput({
    source,
    validate: [validate, validateMinValue(minTime), validateMaxValue(maxTime)].flat(1),
    ...rest,
  });
  const {
    input: { value, onChange },
    meta: { submitFailed, error },
    isRequired,
  } = input;

  return (
    <TimePicker
      label={label}
      value={parseToValue(value)}
      onChange={(date) => {
        onChange(date?.format('HH:mm'));
      }}
      required={isRequired}
      error={error && submitFailed}
      helperText={error && submitFailed ? translate(error) : ' '}
      {...rest}
    />
  );
};

const validateMinValue = (minValue: string | undefined) => (value: string | undefined) => {
  const min = parseToValue(minValue);
  const curr = parseToValue(value);
  if (min && curr && min?.isSameOrAfter(curr)) {
    return `Must be greather ${min.format('LT')}`;
  }
};

const validateMaxValue = (maxValue: string | undefined) => (value: string | undefined) => {
  const max = parseToValue(maxValue);
  const curr = parseToValue(value);
  if (max && curr && max?.isBefore(curr)) {
    return `Must be less ${max.format('LT')}`;
  }
};

const parseToValue = (hhMm?: string) => {
  if (!hhMm) return null;
  const hours = Number(hhMm.split(':')[0]);
  const minutes = Number(hhMm.split(':')[1]);
  return moment()
    .set('hours', hours)
    .set('minutes', minutes)
    .set('seconds', 0)
    .set('milliseconds', 0);
};

export default TimeInput;
