import React from 'react';
import lodashGet from 'lodash/get';

import HorizontalLabeledFieldComponent from '~/components/HorizontalLabeledField';

interface Props {
  source: string;
  label: string;
  className?: string;
  spaceBetween?: boolean;
  [x: string]: any;
}

const HorizontalLabeledField = (props: Props) => {
  const { record, source } = props;
  const data: string = lodashGet(record, source);

  return <HorizontalLabeledFieldComponent data={data} {...props} />;
};

export default HorizontalLabeledField;
