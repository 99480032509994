import React from 'react';
import lodashGet from 'lodash/get';

import PhoneFieldComponent from '~/components/PhoneField';

interface Props {
  record?: any;
  source?: string;
  label?: string;
}

const PhoneField = (props: Props) => {
  const { record, source = 'phoneNumber' } = props;
  return <PhoneFieldComponent number={lodashGet(record, source)} />;
};

export default PhoneField;
