import React, { useState, Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import FXMappingForm from './FXMappingForm';
import { l } from '../CurrenciesList';
import { Solution } from '~/types';

interface FXMappingEditProps {
  record: any;
  solutionRecord?: Solution;
  onClose: () => void;
  permissions?: string[];
}

const FXMappingEdit: FC<FXMappingEditProps> = (props) => {
  const { permissions, record, solutionRecord, onClose } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions?.includes('fxrates.mapping.update')}
      />
      <Drawer heading={t('editRatesMapping')} open={open} onClose={handleClose}>
        <EditForm
          resource="fxrates/mappings"
          resourceId={record?.id}
          target="solutions"
          id={solutionRecord?.id}
          record={record}
          closeParent={handleClose}
          withDelete={permissions?.includes('fxrates.mapping.delete')}
        >
          <FXMappingForm />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default FXMappingEdit;
