import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
  table: {
    width: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td': {
          width: '50%',
          maxWidth: 500,
          overflowWrap: 'break-word',
        },
      },
    },
    '& > thead': {
      '& > tr': {
        '& > th': {
          width: '50%',
        },
      },
    },
    marginBottom: theme.spacing(2),
  },
  responsesTable: {
    width: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td:last-child': {
          width: '50%',
          overflowWrap: 'break-word',
        },
      },
    },
    '& > thead': {
      '& > tr': {
        '& > th:last-child': {
          width: '50%',
        },
      },
    },
    marginBottom: theme.spacing(2),
  },
  autoWidth: {},
}));

export default useStyles;
