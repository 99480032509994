import React from 'react';
import { Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import i18n from '~/i18n/en';

const useStyles = makeStyles(() => {
  return {
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '16px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
  };
});

interface Props {
  resource: string;
  hasFilters?: boolean;
}

const ListTitle = ({ resource, hasFilters }: Props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const subtitle = `resources.${resource}.subtitle`;

  return (
    <div>
      <Typography variant="h2" className={classes.headline}>
        {translate(`resources.${resource}.name`, 2)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {!lodashGet(i18n, subtitle) ? (
          hasFilters ? (
            <React.Fragment>
              To search for {translate(`resources.${resource}.name`, 1)} type in some values into
              filter inputs below
            </React.Fragment>
          ) : (
            <React.Fragment>Press on record in list to show it's details</React.Fragment>
          )
        ) : (
          translate(subtitle)
        )}
      </Typography>
    </div>
  );
};

export default ListTitle;
