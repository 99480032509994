import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  TextField: {
    marginBottom: 7,
    '& input': {
      height: 18,
    },
  },
}));

interface Props {
  label: string;
  required?: boolean;
  errorMessage?: string;
  onChange: (e: any, option: any, reason: string) => void;
  value: any;
  options?: any[];
  disableHelperText?: boolean;
  regularHelperText?: string;
  className?: string;
  [x: string]: any;
}

const AutocompleteArrayTextInput = (props: Props) => {
  const {
    label,
    required,
    errorMessage,
    onChange,
    value,
    options,
    disableHelperText,
    regularHelperText,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      options={options || []}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip {...getTagProps({ index })} size="small" label={option} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          required={!!required}
          helperText={!disableHelperText && (errorMessage || regularHelperText || '\u00A0')}
          error={(errorMessage || '').length > 0}
          className={classes.TextField}
          fullWidth
          {...rest}
        />
      )}
      value={value || []}
      onChange={onChange}
      className={className}
    />
  );
};

export default AutocompleteArrayTextInput;
