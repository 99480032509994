import React, { FC } from 'react';
import { useTranslate, NumberInput, minValue, maxValue } from 'react-admin';
import { Grid } from '@material-ui/core';

import { H3, TextInput as CustomTextImput } from '~/components';
import { useSourceSystemRate, useSourceSystemSchema } from '~/hooks/fxrates';
// import SettlementSystemSelect from './SettlementSystemSelect';
// import SettlementAccountSelect from './SettlementAccountSelect';
import { l } from '../CurrenciesList';
import { Longdash } from '~/utils';
import { HiddenInput } from '~/components/ra';
import TradingAccountSelect from './TradingAccountSelect';
import { uniq } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  sourceSystem: string;
  useForFxProcessing: boolean;
}

const SourceSystemSchemaForm: FC<Props> = (props) => {
  const { sourceSystem, useForFxProcessing } = props;
  const schemaQuery = useSourceSystemSchema(sourceSystem);
  const allCurrencies = uniq(
    schemaQuery.data?.pairs.map((item) => [item.baseCurrency, item.quoteCurrency]).flat()
  );

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  // const form = useForm();
  // const hasSettlementData = !!form.getState().values?.settlementData;
  // const [isAutoSettlement, setAutoSettlement] = useState(hasSettlementData);
  // const handlers = {
  //   autoSettlement: (e: any, checked: boolean) => {
  //     setAutoSettlement(checked);
  //     if (!checked) {
  //       form.change('settlementData', undefined);
  //     }
  //   },
  // };

  if (schemaQuery.isLoading) return <CircularProgress />;
  return (
    <>
      <H3>{t('buyAndSellRates')}</H3>
      {schemaQuery.data?.pairs.map((item, index) => {
        return (
          <Grid container wrap="nowrap" alignItems="center" key={index} spacing={2}>
            <Grid item>
              <div style={currencyPairStyle}>
                {item.baseCurrency}/{item.quoteCurrency}
                {item.country && (
                  <>
                    <br />({item.country})
                  </>
                )}
              </div>
            </Grid>
            <HiddenInput source={`data[${index}].baseCurrency`} defaultValue={item.baseCurrency} />
            <HiddenInput
              source={`data[${index}].quoteCurrency`}
              defaultValue={item.quoteCurrency}
            />
            <HiddenInput source={`data[${index}].country`} defaultValue={item.country} />
            <HiddenInput source={`data[${index}].buyRate`} defaultValue={item.buyRate} />
            <HiddenInput source={`data[${index}].sellRate`} defaultValue={item.sellRate} />
            <Grid item>
              {item.buyRate ? (
                item.buyMargin ? (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={5}>
                      <RateFild
                        label={t('sourceBuyRate')}
                        sourceSystem={sourceSystem}
                        baseCurrency={item.baseCurrency}
                        quoteCurrency={item.quoteCurrency}
                        country={item.country}
                        operation="buy"
                        enabled={!!item.buyRate}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <NumberInput
                        source={`data[${index}].buyMargin`}
                        label={t('buyMarginNotManual')}
                        validate={[minValue(0), maxValue(1)]}
                        step={0.01}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={12}>
                      <RateFild
                        label={t('sourceBuyRate')}
                        sourceSystem={sourceSystem}
                        baseCurrency={item.baseCurrency}
                        quoteCurrency={item.quoteCurrency}
                        country={item.country}
                        operation="buy"
                        enabled={!!item.buyRate}
                      />
                    </Grid>
                  </Grid>
                )
              ) : (
                item.buyMargin && (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={12}>
                      <NumberInput
                        source={`data[${index}].buyMargin`}
                        label={t('buyMarginNotManual')}
                        validate={[minValue(0), maxValue(1)]}
                        step={0.01}
                      />
                    </Grid>
                  </Grid>
                )
              )}
              {item.fallbackBuyMargin && (
                <Grid item container wrap="nowrap" spacing={1}>
                  <Grid item xs={12}>
                    <NumberInput
                      source={`data[${index}].fallbackBuyMargin`}
                      label={t('fallbackBuyMargin')}
                      validate={[minValue(0), maxValue(1)]}
                      step={0.01}
                    />
                  </Grid>
                </Grid>
              )}
              {item.sellRate ? (
                item.sellMargin ? (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={5}>
                      <RateFild
                        label={t('sourceSellRate')}
                        sourceSystem={sourceSystem}
                        baseCurrency={item.baseCurrency}
                        quoteCurrency={item.quoteCurrency}
                        country={item.country}
                        operation="sell"
                        enabled={!!item.sellRate}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <NumberInput
                        source={`data[${index}].sellMargin`}
                        label={t('sellMarginNotManual')}
                        validate={[minValue(0), maxValue(1)]}
                        step={0.01}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={12}>
                      <RateFild
                        label={t('sourceSellRate')}
                        sourceSystem={sourceSystem}
                        baseCurrency={item.baseCurrency}
                        quoteCurrency={item.quoteCurrency}
                        country={item.country}
                        operation="sell"
                        enabled={!!item.sellRate}
                      />
                    </Grid>
                  </Grid>
                )
              ) : (
                item.sellMargin && (
                  <Grid item container wrap="nowrap" spacing={1}>
                    <Grid item xs={12}>
                      <NumberInput
                        source={`data[${index}].sellMargin`}
                        label={t('sellMarginNotManual')}
                        validate={[minValue(0), maxValue(1)]}
                        step={0.01}
                      />
                    </Grid>
                  </Grid>
                )
              )}
              {item.fallbackSellMargin && (
                <Grid item container wrap="nowrap" spacing={1}>
                  <Grid item xs={12}>
                    <NumberInput
                      source={`data[${index}].fallbackSellMargin`}
                      label={t('fallbackSellMargin')}
                      validate={[minValue(0), maxValue(1)]}
                      step={0.01}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}

      {useForFxProcessing && (
        <>
          <H3>{t('fxProcessing')}</H3>
          {allCurrencies.map((c) => {
            return <TradingAccountSelect key={c} currency={c as string} />;
          })}
        </>
      )}

      {/* <H3>{t('settlement')}</H3>
      <FormControlLabel
        control={<Checkbox checked={isAutoSettlement} onChange={handlers.autoSettlement} />}
        label={t('autoSettlement')}
      />
      {isAutoSettlement && (
        <>
          <SettlementSystemSelect disabled={!isAutoSettlement} />
          {allCurrencies.map((c) => {
            return (
              <SettlementAccountSelect
                key={c}
                currency={c as string}
                disabled={!isAutoSettlement}
              />
            );
          })}
        </>
      )} */}
    </>
  );
};

const RateFild: FC<{
  label: string;
  sourceSystem: string;
  baseCurrency: string;
  quoteCurrency: string;
  country?: string;
  operation: 'sell' | 'buy';
  enabled: boolean;
}> = (props) => {
  const { label, sourceSystem, baseCurrency, quoteCurrency, country, operation, enabled } = props;
  const rateQuery = useSourceSystemRate(sourceSystem, {
    baseCurrency,
    quoteCurrency,
    country,
    operation,
    enabled,
  });

  return (
    <CustomTextImput label={label} disabled value={rateQuery.data?.rate} defaultValue={Longdash} />
  );
};

const currencyPairStyle = {
  display: 'flex',
  paddingBottom: 20,
  fontSize: 18,
  fontWeight: 500,
};

export default SourceSystemSchemaForm;
