import axios from '~/utils/axios';

export const getCurrencies = () => {
  return axios.get<CurrencyType[]>(`/currencies`);
};

export interface CurrencyType {
  code: string;
  isMaster?: boolean;
  created?: string;
  deleted?: boolean;
}

export const registerCurrency = (body: CurrencyType) => {
  return axios.post<CurrencyType>(`/currencies`, body);
};

export interface CurrencyPairsType {
  id: string;
  baseCurrency: string;
  quoteCurrency: string;
  isMaster: boolean;
  enabled: boolean;
  created?: string;
  deleted?: boolean;
}

export const getCurrenciesPairs = () => {
  return axios.get<CurrencyPairsType[]>(`/currencies/pairs`).then((res) => {
    const { data, ...rest } = res;
    const compareCurrencies = (a: CurrencyPairsType, b: CurrencyPairsType) =>
      `${a.baseCurrency}/${a.quoteCurrency}` > `${b.baseCurrency}/${b.quoteCurrency}` ? 1 : -1;
    const masterCurrencies = data.filter((item) => item.isMaster).sort(compareCurrencies);
    const otherCurrencies = data.filter((item) => !item.isMaster).sort(compareCurrencies);
    return { data: masterCurrencies.concat(otherCurrencies), ...rest };
  });
};

export const updateCurrenciesPairs = (body: CurrencyPairsType[]) => {
  return axios.put<CurrencyPairsType[]>(`/currencies/pairs`, body);
};
