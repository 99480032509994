import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import DocumentCardFields from '~/components/DocumentCardFields';

import { Beneficiary } from '~/types/beneficiaries';
import BeneficiaryEdit from './BeneficiaryEdit';
import { l } from './BeneficiaryInfo';

interface Props {
  record: Beneficiary | undefined;
  dealId: string;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
}

const getSource = (source: string) => `document.${source}`;

const BeneficiaryDocumentEdit: FC<Props> = (props) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <BeneficiaryEdit heading={t('editDocument')} {...props}>
      <DocumentCardFields getSource={getSource} primary={false} edit documentForm="INDIVIDUAL" />
    </BeneficiaryEdit>
  );
};

export default BeneficiaryDocumentEdit;
