import React, { Children, cloneElement, Fragment } from 'react';
import lodashGet from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { time } from '~/utils';

type SortType = { field: string; order: 'ASC' | 'DESC' };

interface Props {
  data?: any;
  ids?: string[];
  currentSort?: SortType;
  children: any;
}

const LocalSort = (props: Props) => {
  const { data = {}, ids = [], currentSort, children, ...rest } = props;

  const sortData = (
    data: any,
    ids: string[],
    sort?: SortType
  ): { data: any; ids: string[]; currentSort?: SortType } => {
    const getField = (item: any): string | number => {
      const fieldData: string = lodashGet(item, sort?.field || '') || '';
      switch (sort?.field) {
        case 'created':
        case 'createdAt':
        case 'updated':
        case 'updatedAt':
        case 'activeTill':
        case 'activeFrom':
          return time(fieldData).valueOf();
        default:
          return fieldData;
      }
    };
    const getOrder = (n: -1 | 1): number => {
      switch (sort?.order) {
        case 'ASC':
          return n;
        case 'DESC':
          return n * -1;
        default:
          return n;
      }
    };
    if (!isEmpty(ids) && !isEmpty(sort)) {
      const sortedIds = ids.sort((a, b) =>
        getField(data[a]) < getField(data[b]) ? getOrder(-1) : getOrder(1)
      );
      return { data, ids: sortedIds, currentSort: sort };
    }
    return { data, ids, currentSort: sort };
  };

  return (
    <Fragment>
      {children &&
        Children.toArray(children).map((element: any) =>
          cloneElement(element, { ...sortData(data, ids, currentSort), ...rest })
        )}
    </Fragment>
  );
};

export default LocalSort;
