import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Drawer } from '~/components';
import { ByFee } from './PerFees';
import { FeeContent } from '~/resources/pricings/components/PerFeature/ConfirmationDrawers/FeeConfirmationField';
import { RegularFeeContent } from '~/resources/pricings/components/PerFeature/ConfirmationDrawers/RegularFeeConfirmationField';
import { AcquiringFeeContent } from '~/resources/pricings/components/PerFeature/ConfirmationDrawers/AcquiringFeeConfirmationField';
import PricingInfo from '~/resources/pricings/components/PerFeature/PricingInfo';
import { Section } from '~/layout';
import PricingDataEdit, { PricingDataEditProps } from './PerFeature/PricingDataEdit';
import { FeeLoyaltyContent } from './PerFeature/ConfirmationDrawers/FeeLoyaltyConfirmationField';
import { DefaultAmountLimitContent } from './PerFeature/ConfirmationDrawers/DefaultAmountLimitConfirmationField';
import { DefaultCountLimitContent } from './PerFeature/ConfirmationDrawers/DefaultCountLimitConfirmationField';
import ForkInfo from './PerFeature/ForkInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: 0,
  },
}));

interface Props {
  record: ByFee[];
  propertyKey: string | undefined;
  open: boolean;
  onClose: () => void;
  pricingDataEditProps: PricingDataEditProps;
}

const PricingShowDrawer = (props: Props) => {
  const { record, propertyKey, open, onClose, pricingDataEditProps } = props;
  const [data, setData] = useState<ByFee | null>(null);
  useEffect(() => {
    if (propertyKey && record.length) {
      const res = record.find((item) => item.propertyKey === propertyKey);
      if (res) setData(res);
    }
  }, [propertyKey, record]);

  const classes = useStyles();

  return (
    <Drawer heading={data ? data['v:name'] : ''} open={open} onClose={onClose}>
      {data && <PricingInfo schema={data} />}
      {data?.data?.tags && <ForkInfo entity={data.data} />}
      <Section className={classes.root}>
        <Grid container spacing={2}>
          {(function () {
            switch (data?.type) {
              case 'veengu_fee_membership':
                return <RegularFeeContent record={data.data} prevRecord={data.data} />;
              case 'veengu_fee_default':
                return <FeeContent record={data.data} prevRecord={data.data} />;
              case 'veengu_fee_acquiring':
                return <AcquiringFeeContent record={data.data} prevRecord={data.data} />;
              case 'veengu_fee_loyalty_default':
                return <FeeLoyaltyContent record={data.data} prevRecord={data.data} />;
              case 'veengu_limit_default_amount': {
                const defaultValue = data['v:default'];
                const dataWithDefault = data.data?.amount
                  ? data.data
                  : { amount: { currency: pricingDataEditProps.currency, value: defaultValue } };
                return (
                  <DefaultAmountLimitContent
                    record={dataWithDefault}
                    prevRecord={dataWithDefault}
                  />
                );
              }
              case 'veengu_limit_default_count': {
                const defaultValue = data['v:default'];
                const dataWithDefault =
                  data.data?.count !== undefined ? data.data : { count: defaultValue };
                return (
                  <DefaultCountLimitContent record={dataWithDefault} prevRecord={dataWithDefault} />
                );
              }
              default:
                return null;
            }
          })()}
          <Grid item container spacing={2} xs={12} justify="flex-end">
            <Grid item>
              <PricingDataEdit {...pricingDataEditProps} schema={data as ByFee} />
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Drawer>
  );
};

export default PricingShowDrawer;
