/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import Localizations from './Localizations';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
  },
});

export interface Props {
  value?: string;
  label?: string;
  onChange?: (e: any) => void;
  required?: boolean;
  helperText?: string;
  error?: boolean;
}

const LocalizationAutocomplete = (props: Props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      options={Object.keys(Localizations)}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => {
        return option ? `${Localizations[option]} (${option})` : '';
      }}
      renderOption={(option) => {
        return (
          <React.Fragment>
            {Localizations[option]} ({option})
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || 'Choose a localization'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          fullWidth
          required={props.required}
          helperText={props.helperText}
          error={props.error}
        />
      )}
      filterOptions={createFilterOptions({
        stringify: (option) => `${Localizations[option]}`,
      })}
      value={props.value}
      onChange={(e: any, option: any) => {
        props.onChange &&
          props.onChange({
            target: {
              value: option,
            },
          });
      }}
    />
  );
};

export default LocalizationAutocomplete;
