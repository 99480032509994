import React from 'react';
import { CellProps } from './CellProps';

const grey = { color: '#CACACA' };

const ColoredField = ({ children, rowData }: CellProps) => {
  if (rowData.status === 'CREATED') {
    return <div style={grey}>{children}</div>;
  } else return children;
};

export default ColoredField;
