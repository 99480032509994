import axios from '~/utils/axios';

export const issueActivationCode = (terminalId: string, phoneNumber?: string) => {
  return axios.post(`/terminals/${terminalId}/activationCode`, {
    phone: phoneNumber ? phoneNumber : undefined,
  });
};

export const getActivationCode = (terminalId: string) => {
  return axios.get(`/terminals/${terminalId}/activationCode`);
};

export const deactivateTerminal = (terminalId: string) => {
  return axios.post(`/terminals/${terminalId}/deactivate`);
};
