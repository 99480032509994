import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { Localizer } from './Localizer';

const mapUiProperties = (object: any): any[][] => {
  return Object.keys(object)
    .filter((key) => {
      return key.startsWith('ui:');
    })
    .map((key) => {
      return [key, object[key]];
    });
};

const hasUiFieldOrWidget = (jsonSchema: JSONSchema6): boolean => {
  const uiOptions = (jsonSchema as any)['ui:options'];
  return (
    (uiOptions && (uiOptions['field'] || uiOptions['widget'])) ||
    (jsonSchema as any)['ui:field'] ||
    (jsonSchema as any)['ui:widget']
  );
};

// generates UI schema from given JSON schema
const uiSchemaOf = (jsonSchema: JSONSchema6, localizer: Localizer, path: string = ''): any => {
  const uiProperties = mapUiProperties(jsonSchema);

  // add localization
  const title = localizer.getTitle(path);
  if (title) {
    uiProperties.push(['ui:title', title]);
  }
  const description = localizer.getDescription(path);
  if (description) {
    uiProperties.push(['ui:description', description]);
  }
  uiProperties.push(['ui:i18nPrefix', localizer.getFieldPrefix(path)]);

  switch (jsonSchema.type) {
    case 'object':
    case 'array':
      // check if it has 'ui:field' or 'ui:widget' property
      if (!hasUiFieldOrWidget(jsonSchema)) {
        if (jsonSchema.type === 'object' && jsonSchema.properties) {
          Object.keys(jsonSchema.properties).forEach((key) => {
            const property = (jsonSchema.properties as any)[key] as JSONSchema6Definition;
            if (typeof property === 'object') {
              uiProperties.push([key, uiSchemaOf(property, localizer, `${path}.${key}`)]);
            } else {
              throw new Error(`property ${key} type is not an object; not supported`);
            }
          });
        } else if (jsonSchema.type === 'array' && jsonSchema.items) {
          if (typeof jsonSchema.items === 'object') {
            uiProperties.push([
              'items',
              uiSchemaOf(jsonSchema.items as JSONSchema6, localizer, path),
            ]);
          } else {
            // not supported
            throw new Error("unexpected 'items' type; not an object; not supported");
          }
        }
      } // else do not process children

      return Object.fromEntries(uiProperties);
    default:
      return Object.fromEntries(uiProperties);
  }
};

export default uiSchemaOf;
