import React from 'react';
import {
  TabbedShowLayout,
  Tab,
  useShowController,
  useTranslate,
  usePermissions,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Show } from '~/layout';
import { TextField } from '~/components/ra';
import { H2 } from '~/components';
import RoleEdit from './RoleEdit';
import { Role } from '~/types';
import RoleDelete from './RoleDelete';
import PermissionsGrid from './components/PermissionsGrid';

export const l = (key: string) => `resources.roles.fields.${key}`;

const RoleShow = (props: any) => {
  const { permissions } = usePermissions();

  const showController = useShowController(props);
  const role: Role | undefined = showController.record;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  if (!permissions) return null;

  return (
    <Show {...props} actions={null}>
      <TabbedShowLayout className={classes.tabbedShowLayout}>
        <Tab label={translate(`resources.roles.tabTitle`)}>
          <div className={classes.section}>
            <Grid
              container
              className={classes.bottomSpacing2}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translate(`resources.roles.data`)}
                </H2>
              </Grid>
              <Grid item container xs={4} spacing={2} id="section-actions" justify="flex-end">
                {permissions?.includes('dashboardRole.update') && !role?.readonly && (
                  <Grid item>
                    <RoleEdit role={role} permissions={permissions} />
                  </Grid>
                )}
                {permissions.includes('dashboardRole.delete') && !role?.readonly && (
                  <Grid item>
                    <RoleDelete roleId={role?.id as any} />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                xs={4}
                spacing={2}
                id="section-actions"
                justify="flex-end"
              ></Grid>
            </Grid>
            <div className={classes.container}>
              <Grid container item spacing={2} xs={10}>
                <Grid item xs={12}>
                  <TextField source="id" label={t('id')} {...showController} />
                </Grid>
                <Grid item xs={12}>
                  <TextField source="name" label={t('name')} {...showController} />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.permissions}>
            <PermissionsGrid {...showController} permissions={permissions} />
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const useStyles = makeStyles((theme) => ({
  tabbedShowLayout: {
    minHeight: 500,
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  permissions: {
    marginTop: theme.spacing(2),
  },
  section: {
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default RoleShow;
