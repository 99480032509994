export default {
  _: 'Profile links',
  sourceSystem: 'Source system',
  status: 'Status',
  lastUpdate: 'Last update',
  referenceId: 'Reference ID',
  delete: 'Delete',
  deleteWarning:
    'Please confirm the deletion of the link. You will not be able to cancel the deletion. The deleted link may cause the non-operability of external sources of funds connected to the profile.',
  deleteConfirmationTitle: 'Delete profile link',
  deleteSuccessMessage: 'Profile link is deleted successfully!',
  editDrawerTitle: 'Edit profile link',
  form: {
    identificationDetails: 'Identification details',
  },
  empty: 'There are no profile links yet.',
  linkAction: 'View details',
};
