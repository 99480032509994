import * as React from 'react';
import lodashGet from 'lodash/get';
import AmountFieldComponent from '~/components/AmountField';

import { Amount } from '~/types';

export interface Props {
  [x: string]: any;
  record?: any;
  source: string;
  label?: string | undefined;
  style?: object;
  className?: string;
}

const AmountField = (props: Props) => {
  const { record = {}, source, label, className } = props;
  const amount: Amount = lodashGet(record, source);

  return <AmountFieldComponent amount={amount} label={label} className={className} />;
};

AmountField.defaultProps = {
  addLabel: true,
  amount: {},
};

export default AmountField;
