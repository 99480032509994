import { createAction, handleActions } from 'redux-actions';

const SET_INITIAL_STATE = 'stackState/setInitialState';
const SET_STATE = 'stackState/setState';
const SET_STATE_WITH_CALLBACK = 'stackState/setState/withCallback';
const UPDATE_STATE = 'stackState/updateState';

export type StackStatePayload<T = any> = {
  key: string;
  data: T;
};
export type StackStateCallbackPayload<T = any> = {
  key: string;
  callback: (prev: T) => T;
};
export const setStackInitialStateAction = createAction<StackStatePayload>(SET_INITIAL_STATE);
export const setStackStateAction = createAction<StackStatePayload>(SET_STATE);
export const setStackStateWithCallbackAction = createAction<StackStateCallbackPayload>(
  SET_STATE_WITH_CALLBACK
);
export const updateStackStateAction = createAction<StackStatePayload>(UPDATE_STATE);

const initialState: Record<string, any> = {};

export default handleActions(
  {
    [SET_STATE]: (state, { payload }) => {
      const { key, data } = payload;
      return {
        ...state,
        [key]: data,
      };
    },
    [SET_STATE_WITH_CALLBACK]: (state, { payload }) => {
      const { key } = payload;
      const prev = { ...(state[key] || {}) };
      return {
        ...state,
        [key]: payload.callback(prev),
      };
    },
    [SET_INITIAL_STATE]: (state, { payload }) => {
      const { key, data } = payload;
      if (!state[key]) {
        return {
          ...state,
          [key]: data,
        };
      }
      return state;
    },
    [UPDATE_STATE]: (state, { payload }) => {
      const { key, data } = payload;
      return {
        ...state,
        [key]: {
          ...(state[key] || {}),
          ...data,
        },
      };
    },
  },
  initialState
);

export const getStackState = (key: string) => (state: any) => state.stackState[key];
