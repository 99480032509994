import React, { useState, Fragment } from 'react';
import {
  ArrayInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  useTranslate,
  usePermissions,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';

import { useFeatures } from '~/hooks';
import { AccordionSummary } from '~/components';
import { EditForm, ReferenceInput, SolutionSelect } from '~/components/ra';
import { useApi } from '~/hooks';
import { getConfiguration, Configuration } from '~/api/solutions';
import { isEmpty } from 'lodash';
import { required, minValue } from 'react-admin';

export const l = (key: string): string => `resources.solutions.${key}`;

interface JsonInputProps {
  label: string;
  source: string;
  className?: string;
  validationError: string;
}

const JsonInput = (props: JsonInputProps) => {
  const { label, source, className, validationError } = props;
  return (
    <TextInput
      multiline
      label={label}
      source={source}
      className={className}
      initialValue="{}"
      validate={(v: string) => {
        if (v) {
          try {
            JSON.parse(v);
            return '';
          } catch (e) {
            return validationError;
          }
        }
      }}
      format={(v: any) => {
        if (typeof v === 'object') {
          return JSON.stringify(v, null, 2);
        } else if (!v) {
          return '{}';
        }
        return v;
      }}
    />
  );
};

const FeaturesConfig = (props: any) => {
  const { record, basePath, resource } = props;

  const { list } = useFeatures();
  const features: string[] = (record?.features || []).sort((f1: string, f2: string) => {
    return list[f1]?.localeCompare(list[f2]);
  });

  console.log(`list: `, list);

  const [{ data: configurationRecord }] = useApi<Configuration>(
    () => getConfiguration(record.id),
    []
  );

  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string =>
    translate(l(`featuresConfig.${key}`), variant);

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  const { permissions } = usePermissions();
  const classes = useStyles();

  return (
    <EditForm
      resource={resource}
      record={configurationRecord as any}
      resourceId={record.id}
      subresource="configuration"
      disabled={!permissions?.includes('solution.settings.update')}
    >
      <Fragment>
        <Grid container style={{ width: '100%' }}>
          {!isEmpty(features) ? (
            features.map((key) => {
              const isExpanded = Boolean(expanded[key]);
              return (
                <Grid key={key} item xs={12}>
                  <Accordion
                    disabled={!permissions?.includes('solution.settings.view')}
                    expanded={isExpanded}
                    onChange={handleChange(key, isExpanded)}
                  >
                    <AccordionSummary
                      aria-controls={`${key}-content`}
                      id={`${key}-header`}
                      expanded={isExpanded}
                      heading={list[key]}
                    />
                    <AccordionDetails className={classes.AccordionDetails}>
                      {(() => {
                        switch (key) {
                          case 'veengu_individual_onboarding_self':
                          case 'veengu_individual_onboarding_dashboard':
                          case 'veengu_individual_onboarding_integration':
                          case 'veengu_business_onboarding_self':
                          case 'veengu_business_onboarding_dashboard':
                            return (
                              <>
                                <ReferenceInput
                                  source={`${key}.product`}
                                  reference="products"
                                  filter={{ solutionId: record.id }}
                                  basePath={basePath}
                                  label={t('defaultProduct')}
                                  style={{ width: 256 }}
                                  allowEmpty
                                >
                                  <SelectInput optionText="name" />
                                </ReferenceInput>
                                <ReferenceInput
                                  source={`${key}.metaProduct`}
                                  reference="products"
                                  filter={{ solutionId: record.id }}
                                  basePath={basePath}
                                  label={t('defaultMetaProduct')}
                                  style={{ width: 256 }}
                                  allowEmpty
                                >
                                  <SelectInput optionText="name" />
                                </ReferenceInput>
                              </>
                            );
                          case 'veengu_payroll':
                            return (
                              <>
                                <ReferenceInput
                                  source={`${key}.product`}
                                  reference="products"
                                  filter={{ solutionId: record.id }}
                                  basePath={basePath}
                                  label={t('defaultProduct')}
                                  style={{ width: 256 }}
                                  allowEmpty
                                >
                                  <SelectInput optionText="name" />
                                </ReferenceInput>
                              </>
                            );
                          case 'veengu_business_profile':
                          case 'veengu_individual_profile':
                            return (
                              <>
                                <JsonInput
                                  label={t('profileSchema')}
                                  source={`${key}.profileSchema`}
                                  className={classes.schemaInput}
                                  validationError={t('profileSchemaValidationError')}
                                />
                                {key === 'veengu_business_profile' && (
                                  <>
                                    <JsonInput
                                      label={t('employeeConfig')}
                                      source={`${key}.employeeConfig`}
                                      className={classes.schemaInput}
                                      validationError={t('employeeConfigValidationError')}
                                    />
                                    <TextInput
                                      label={t('businessPortalBaseUrl')}
                                      source={`${key}.businessPortalBaseUrl`}
                                    />
                                  </>
                                )}
                              </>
                            );
                          case 'veengu_individual_beneficiary_management':
                          case 'veengu_business_beneficiary_management':
                            return (
                              <>
                                <JsonInput
                                  label={t('beneficiarySchema')}
                                  source={`${key}.beneficiarySchema`}
                                  className={classes.schemaInput}
                                  validationError={t('beneficiarySchemaValidationError')}
                                />
                              </>
                            );
                          case 'veengu_quick_quote':
                            return (
                              <>
                                <JsonInput
                                  label={t('countriesAndCurrencies')}
                                  source={`${key}.countriesAndCurrencies`}
                                  className={classes.schemaInput}
                                  validationError={t('countriesAndCurrenciesValidationError')}
                                />
                              </>
                            );
                          case 'veengu_individual_remittance':
                            return (
                              <>
                                <ReferenceArrayInput
                                  source={`${key}.accounts`}
                                  reference="tenant/accounts"
                                  basePath={basePath}
                                  label={t('tenantAccounts')}
                                  style={{ width: 256 }}
                                  perPage={100}
                                >
                                  <SelectArrayInput optionText="alias" />
                                </ReferenceArrayInput>
                                <JsonInput
                                  label={t('remittanceAdditionalPropertiesSchema')}
                                  source={`${key}.additionalPropertiesSchema`}
                                  className={classes.schemaInput}
                                  validationError={t(
                                    'remittanceAdditionalPropertiesSchemaValidationError'
                                  )}
                                />
                              </>
                            );
                          case 'veengu_business_remittance_payout':
                            return (
                              <>
                                <ReferenceArrayInput
                                  source={`${key}.services`}
                                  reference="paymentServices"
                                  basePath={basePath}
                                  label={t('remittancePayoutServices')}
                                  style={{ maxWidth: 600 }}
                                  perPage={1000}
                                  validate={required()}
                                >
                                  <SelectArrayInput
                                    optionText={(r: any) => `${r.name} (${r.id})`}
                                  />
                                </ReferenceArrayInput>
                                <ReferenceArrayInput
                                  source={`${key}.accounts`}
                                  reference="tenant/accounts"
                                  basePath={basePath}
                                  label={t('tenantAccounts')}
                                  style={{ maxWidth: 600 }}
                                  perPage={100}
                                >
                                  <SelectArrayInput optionText="alias" />
                                </ReferenceArrayInput>
                                <JsonInput
                                  label={t('remittancePayoutBeneficiarySchema')}
                                  source={`${key}.beneficiarySchema`}
                                  className={classes.schemaInput}
                                  validationError={t('beneficiarySchemaValidationError')}
                                />
                              </>
                            );
                          case 'veengu_service_charges':
                            return (
                              <ArrayInput
                                source={`${key}.items`}
                                label=""
                                className={classes.simpleArrayInput}
                              >
                                <SimpleFormIterator>
                                  <TextInput label={t('serviceChargeId')} source="id" />
                                  <TextInput label={t('serviceChargeName')} source="name" />
                                </SimpleFormIterator>
                              </ArrayInput>
                            );
                          case 'veengu_individual_invitation':
                            return (
                              <>
                                <NumberInput
                                  label={t('invitationExpirationPeriod')}
                                  source={`${key}.invitationExpirationPeriod`}
                                  validate={minValue(1)}
                                />
                                <NumberInput
                                  label={t('maxNumberOfInvitationsToPhoneNumberPerMonth')}
                                  source={`${key}.maxNumberOfInvitationsToPhoneNumberPerMonth`}
                                />
                                <NumberInput
                                  label={t('maxNumberOfInvitationsByActorPerDay')}
                                  source={`${key}.maxNumberOfInvitationsByActorPerDay`}
                                />
                                <TextInput
                                  label={t('termsAndConditionsLink')}
                                  source={`${key}.termsAndConditionsLink`}
                                />
                                <TextInput label={t('websiteLink')} source={`${key}.websiteLink`} />
                                <TextInput
                                  label={t('phoneNumberPrefixes')}
                                  source={`${key}.phoneNumberPrefixes`}
                                />
                                <SolutionSelect
                                  label={t('inviteToSolution')}
                                  source={`${key}.solutionId`}
                                  validate={required()}
                                  allowEmpty={false}
                                />
                              </>
                            );
                          default:
                            return <div>{t('noData')}</div>;
                        }
                      })()}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })
          ) : (
            <div>{t('noFeatures')}</div>
          )}
        </Grid>
      </Fragment>
    </EditForm>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
  section: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  schemaInput: {
    '& textarea': {
      fontFamily: 'monospace',
      minHeight: 100,
    },
  },
  simpleArrayInput: {
    '& li:not(:first-child)': {
      paddingTop: 20,
    },
  },
}));

export default FeaturesConfig;
