import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import { AmountField } from '~/components';
import { Amount } from '~/types';
import { Props } from './';

import ColoredField from './ColoredField';

const ColoredAmountField = (props: Props<Amount>) => {
  const { column, children } = props;

  const theme = useTheme();

  const { value } = children || {};
  let style = {};

  if (column.id === 'postedAmount') {
    if (value > 0) {
      style = {
        color: theme.palette.success.main,
      };
    }
  } else {
    style = {
      fontWeight: 400,
    };
  }

  return (
    <ColoredField {...props}>
      <AmountField amount={children} style={style} />
    </ColoredField>
  );
};

export default ColoredAmountField;
