export default {
  alias: 'Account alias',
  accountNumber: 'Account number',
  currency: 'Currency',
  description: 'Description',
  status: 'Status',
  accountSummary: 'Account summary',
  balance: 'Balance',
  amount: 'Amount',
  accountDetails: 'Account details',
  total: 'Total',
  tabs: {
    details: 'Details',
    bankDaySummary: 'Bank day summary',
    transactions: 'Transactions',
  },
  addBank: 'Add bank account',
  editBank: 'Edit bank account',
  transactionsHistory: {
    bankingDay: 'Banking day',
    totalOperations: 'Total operations number',
    postedAmount: 'Posted amount',
    cpBankAccount: 'CP bank account',
    partyAccount: 'e-Account',
    cpAccount: 'CP e-Account',
    from: 'Sender',
    to: 'Recipient',
    dateAndTime: 'Date & Time',
    trnId: 'Transaction ID',
    originalTransactionId: 'Original transaction ID',
    transferFrom: 'Transfer from',
    transferTo: 'Transfer to',
    fromCreatedAt: 'From created at',
    toCreatedAt: 'To created at',
    fromTimestamp: 'From timestamp',
    toTimestamp: 'To timestamp',
  },
  bankDaySummary: {
    toDate: 'To date',
    fromDate: 'From date',
    bankingDay: 'Banking day',
    totalOperations: 'Total operations number',
    totalAmount: 'Total amount',
  },
};
