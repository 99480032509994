import React from 'react';
import { TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import cx from 'classnames';

import Cell from './Cell';
import { Column } from './index';
import { Link } from '~/components';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  noResult: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '18%',
    fontSize: theme.typography.fontSize,
  },
}));

interface Props<RowData> {
  index: number;
  style: React.CSSProperties;
  data: RowData[];
  isItemLoaded: (index: number) => boolean;
  columns: Column<RowData>[];
  loadIndicatorRowsCount: number;
  onRowClick?: (data: RowData) => any;
  emptyText?: string;
  getRowLink?: (data: RowData) => string | undefined;
  openLinkInNewTab?: boolean;
}

const Row = (props: Props<any>) => {
  const {
    index,
    style,
    data,
    isItemLoaded,
    columns,
    loadIndicatorRowsCount,
    onRowClick,
    emptyText,
    getRowLink,
    openLinkInNewTab = false,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const handleClick = () => {
    if (isItemLoaded(index) && data.length) {
      if (typeof onRowClick === 'function') onRowClick({ ...data[index] });
    }
  };

  // Render an item or a loading indicator.
  let content = null;
  if (!isItemLoaded(index)) {
    const skeletonCellIndex = index - data.length;
    let opacity = 1 - skeletonCellIndex / loadIndicatorRowsCount;
    if (opacity < 0.3) opacity = 0.3;

    content = columns.map((column, i) => (
      <Cell style={{ opacity }} key={i} column={column}>
        <Skeleton width="100%" variant="text" />
      </Cell>
    ));
  } else if (data.length) {
    content = columns.map((column, i) => {
      let cellContent = null;
      const cellData = column.selectCellData(data[index]);
      const Wrapper = column.cellDataWrapperComponent;
      if (Wrapper) {
        cellContent = (
          <Wrapper column={column} rowData={data[index]}>
            {cellData}
          </Wrapper>
        );
      } else {
        cellContent = cellData;
      }

      return (
        <Cell key={i} align={column.cellAlign} column={column}>
          {cellContent}
        </Cell>
      );
    });
  } else {
    content = (
      <div className={classes.noResult}>
        {emptyText ? emptyText : translate('components.ra.fields.VirtualizedList.empty')}
      </div>
    );
  }

  if (getRowLink)
    return (
      <TableRow
        className={cx({ [classes.row]: !!data.length || !isItemLoaded(index) })}
        component={(props) => <Link {...props} target={openLinkInNewTab ? '_blank' : undefined} />}
        style={style}
        to={getRowLink({ ...data[index] })}
      >
        {content}
      </TableRow>
    );

  return (
    <TableRow
      className={cx({ [classes.row]: !!data.length || !isItemLoaded(index) })}
      component="div"
      style={style}
      onClick={handleClick}
    >
      {content}
    </TableRow>
  );
};

export default Row;
