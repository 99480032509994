import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import TerminalForm from './TerminalForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { Business, Terminal } from '~/types';

export const sanitize = (record: Terminal): Terminal => {
  const { device } = record;
  const sanitizedRecord = { ...record };
  if (!device) delete sanitizedRecord.device;
  return sanitizedRecord;
};

interface Props {
  terminalRecord: Terminal;
  businessRecord: Business;
  permissions: string[];
}

const TerminalEdit = (props: Props) => {
  const { terminalRecord, businessRecord, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.terminals.${key}`);

  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions.includes('business.terminal.update')}
      />
      <Drawer
        heading={`${translateLabel('editTerminal')} "${terminalRecord.label}"`}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource="terminals" record={sanitize(terminalRecord)}>
          <TerminalForm businessRecord={businessRecord} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default TerminalEdit;
