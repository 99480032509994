import { isValidPhoneNumber } from 'libphonenumber-js';

const validatePhoneNumber = (value: string) => {
  if (value) {
    try {
      // const number = phoneNumberUtil.parse(value);
      if (!isValidPhoneNumber(value)) {
        return { message: 'utils.validatePhoneNumber.error' };
      }
    } catch (error) {
      return { message: 'utils.validatePhoneNumber.error' };
    }
  }
};

export default validatePhoneNumber;
