import * as React from 'react';
import { Moment } from '~/types';
import ColoredField from './ColoredField';
import { CellProps } from './CellProps';

import { time } from '~/utils';

const ListDateTimeField = (props: CellProps<Moment>) => {
  const { children } = props;
  const data = time(children).format('lll');

  return <ColoredField {...props}>{data}</ColoredField>;
};

export default ListDateTimeField;
