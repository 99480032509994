import React, { Fragment } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';

import { ColoredField, ListDateTimeField, ListAmountField } from './components';

import { StatusField, VirtualizedList, Title } from '~/components';
import { NoPermissions } from '~/components/ra';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';

import { getDeals } from '~/api/deals';
import { Deal, DealFilters } from '~/types/Deal';
import { Column } from './Column';

import { CellProps } from './components/CellProps';
import Filters from './components/ListFilters';
import { buildName } from '~/utils';
import OperationTypeField from '~/components/OperationTypeField';

const resource = 'deals';

const i18nPath = 'resources.deals.';

interface Props {
  permissions: string[];
  history: any;
}

const DealList = (props: Props) => {
  const { history } = props;
  const { permissions } = usePermissions();

  const translate = useTranslate();
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<DealFilters>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getDeals({
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.deals as any[],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  /*
  const getSenderAccount = (t: Deal) => {
    return t.accounts.find((a) => a.id === t.sender);
  };

  const getRecipientAccount = (t: Deal) => {
    return t.accounts.find((a) => a.id === t.recipient);
  };
   */

  const getRemitter = (r: Deal) => {
    if (r.remitter) {
      const senderData = r.remitter?.senderData || {};
      const recipientData = r.remitter?.recipientData || {};
      return {
        id: r.remitter.id,
        ...senderData,
        ...recipientData,
      };
    }
    return undefined;
  };

  const getBeneficiary = (r: Deal) => {
    const senderData = r.beneficiary.senderData || {};
    const recipientData = r.beneficiary.recipientData || {};
    return {
      id: r.beneficiary.id,
      ...senderData,
      ...recipientData,
    };
  };

  const columns: Column[] = [
    {
      selectCellData: (r: Deal) => r.createdAt,
      cellDataWrapperComponent: ListDateTimeField,
      header: translate(i18nPath + 'list.created'),
      style: {
        flexBasis: 150,
      },
    },
    {
      selectCellData: (r: Deal) => r.operationType,
      cellDataWrapperComponent: (props: CellProps) => (
        <ColoredField {...props}>
          <OperationTypeField operationType={props.children} />
        </ColoredField>
      ),
      header: translate(i18nPath + 'list.operationType'),
      width: 130,
    },
    {
      selectCellData: (r: Deal) => r.remitterAmount,
      cellDataWrapperComponent: ListAmountField,
      id: 'remitterAmount',
      header: translate(i18nPath + 'list.remitterAmount'),
      headerAlign: 'right',
      cellAlign: 'right',
      style: {
        flexBasis: 120,
      },
    },
    {
      selectCellData: (r: Deal) => r.sender.accountNumber,
      cellDataWrapperComponent: (props: CellProps) => (
        <ColoredField {...props}>{props.children}</ColoredField>
      ),
      header: translate(i18nPath + 'list.sender'),
      style: {
        flexBasis: 150,
      },
    },
    {
      selectCellData: (r: Deal) => getRemitter(r),
      cellDataWrapperComponent: (props: CellProps) => (
        <ColoredField {...props}>
          {buildName('individuals', props.children) ||
            buildName('businesses', props.children) ||
            ''}
        </ColoredField>
      ),
      header: translate(i18nPath + 'list.remitter'),
      style: {
        flexBasis: 120,
      },
    },
    {
      selectCellData: (r: Deal) => getBeneficiary(r),
      cellDataWrapperComponent: (props: CellProps) => (
        <ColoredField {...props}>
          {buildName('individuals', props.children) ||
            buildName('businesses', props.children) ||
            ''}
        </ColoredField>
      ),
      header: translate(i18nPath + 'list.beneficiary'),
      style: {
        flexBasis: 120,
      },
    },
    {
      selectCellData: (r: Deal) => r.recipient?.accountNumber,
      cellDataWrapperComponent: (props: CellProps) => (
        <ColoredField {...props}>{props.children || ''}</ColoredField>
      ),
      header: translate(i18nPath + 'list.recipient'),
      style: {
        flexBasis: 150,
      },
    },
    {
      selectCellData: (t: Deal) => t.beneficiaryAmount,
      cellDataWrapperComponent: ListAmountField,
      id: 'beneficiaryAmount',
      header: translate(i18nPath + 'list.beneficiaryAmount'),
      headerAlign: 'right',
      cellAlign: 'right',
      style: {
        flexBasis: 120,
      },
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: (props: CellProps) => <StatusField status={props.children} />,
      id: 'status',
      header: translate(i18nPath + 'list.status'),
      style: {
        flexBasis: 100,
      },
    },
  ];

  const { hasNextPage, isNextPageLoading, data } = list;
  if (!permissions) return null;
  if (!permissions?.includes('deal.list')) return <NoPermissions />;
  return (
    <Fragment>
      <Title resource={resource} headline={translate(i18nPath + 'list.title')} />
      <Filters cleanFilters={history.action !== 'POP'} />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<Deal>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          filters={filters}
          data={data}
          loadNextPage={loadNextPage}
          getRowLink={(data) => `/deals/${data.id}/show`}
          outterTable
        />
      </Paper>
    </Fragment>
  );
};

const fixBottomShadow = { paddingBottom: 2 };

export default DealList;
