import { crudGetList } from 'react-admin';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  createReference,
  deletePaymentGroup,
  deletePaymentService,
  deleteReference,
  getAllGroups,
  getAllServices,
  getReferencesByGroup,
  getReferencesByService,
  getService,
} from '~/api/paymentServices';
import { PaymentGroup, PaymentService, PaymentServiceReference } from '~/types/paymentServices';

export const useDeletePaymentService = (props?: any) => {
  const dispatch = useDispatch();

  return useMutation(deletePaymentService, {
    onSuccess() {
      dispatch(
        crudGetList('paymentServices', { page: 1, perPage: 10 }, { field: 'id', order: 'DESC' }, {})
      );
      props?.onSuccess && props.onSuccess();
    },
  });
};

export const useDeletePaymentGroup = (props?: any) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(deletePaymentGroup, {
    onSuccess() {
      queryClient.invalidateQueries('paymentServices/groups').then(() => {
        dispatch(
          crudGetList(
            'paymentServices/groups',
            { page: 1, perPage: 10 },
            { field: 'id', order: 'DESC' },
            {}
          )
        );
        props?.onSuccess && props.onSuccess();
      });
    },
  });
};

export const useAllGroupsQuery = (options?: {
  onSuccess?: (res: PaymentGroup[]) => void;
  enabled?: boolean;
}) => {
  const key = `paymentServices/groups`;
  return useQuery<PaymentGroup[]>(key, () => getAllGroups().then((res) => res.data), options);
};

export const useAllServicesQuery = (options?: {
  onSuccess?: (res: PaymentService[]) => void;
  enabled?: boolean;
}) => {
  const key = `paymentServices`;
  return useQuery<PaymentService[]>(key, () => getAllServices().then((res) => res.data), options);
};

export const useGetServiceQuery = (
  serviceId: string,
  forPublicApi: boolean = false,
  options?: {
    onSuccess?: (res: PaymentService) => void;
    enabled?: boolean;
  }
) => {
  const key = `paymentServices/${serviceId}?forPublicApi=${forPublicApi}`;
  return useQuery<PaymentService>(
    key,
    () => getService(serviceId, forPublicApi).then((res) => res.data),
    options
  );
};

export const useReferencesByServiceQuery = (
  serviceId: string,
  options?: {
    onSuccess?: (res: PaymentServiceReference[]) => void;
    enabled?: boolean;
  }
) => {
  const key = `paymentServices/${serviceId}/references`;
  return useQuery<PaymentServiceReference[]>(
    key,
    () => getReferencesByService(serviceId).then((res) => res.data),
    options
  );
};

export const useReferencesByGroupQuery = (
  groupId: string,
  options?: {
    onSuccess?: (res: PaymentServiceReference[]) => void;
    enabled?: boolean;
  }
) => {
  const key = `paymentServices/groups/${groupId}/references`;
  return useQuery<PaymentServiceReference[]>(
    key,
    () => getReferencesByGroup(groupId).then((res) => res.data),
    options
  );
};

type UseMutationType = typeof useMutation;
type UseMutationParams = Parameters<UseMutationType>;

type DeleteReferenceRequest = {
  serviceId: string;
  groupId: string;
};

export const useReferenceDelete = (options?: UseMutationParams[2]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: DeleteReferenceRequest) => deleteReference(request.serviceId, request.groupId),
    {
      onMutate(request) {
        const keyByService = `paymentServices/${request.serviceId}/references`;
        const keyByGroup = `paymentServices/groups/${request.groupId}/references`;

        if (options?.onMutate) options.onMutate(request);
        const prevDataByService = queryClient.getQueryData<PaymentServiceReference[]>(keyByService);
        const prevDataByGroup = queryClient.getQueryData<PaymentServiceReference[]>(keyByGroup);

        const rollback = () => {
          queryClient.setQueryData(keyByService, prevDataByService);
          queryClient.setQueryData(keyByGroup, prevDataByGroup);
        };

        queryClient.setQueryData<PaymentServiceReference[] | undefined>(keyByService, (prev) => {
          if (prev) {
            return prev.filter((item) => item.groupId !== request.groupId);
          }
          return prev;
        });
        queryClient.setQueryData<PaymentServiceReference[] | undefined>(keyByGroup, (prev) => {
          if (prev) {
            return prev.filter((item) => item.serviceId !== request.serviceId);
          }
          return prev;
        });

        const successMessage = () => {};
        const errorMessage = () => {};

        return {
          rollback,
          successMessage,
          errorMessage,
        };
      },
      onSuccess(res, request, context: any) {
        const keyByService = `paymentServices/${request.serviceId}/references`;
        const keyByGroup = `paymentServices/groups/${request.groupId}/references`;

        if (options?.onSuccess) void options.onSuccess(res, request, context);
        context.successMessage();

        void queryClient.invalidateQueries(keyByService).then(() => {
          void queryClient.invalidateQueries(keyByGroup);
        });
      },
      onError(error: any, newData, context) {
        if (options?.onError) void options.onError(error, newData, context);
        context.rollback();
      },
    }
  );
};

type CreateReferenceRequest = {
  serviceId: string;
  groupId: string;
};

export const useReferenceCreate = (options?: UseMutationParams[2]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: CreateReferenceRequest) => createReference(request.serviceId, request.groupId),
    {
      onMutate(request) {
        const keyByService = `paymentServices/${request.serviceId}/references`;
        const keyByGroup = `paymentServices/groups/${request.groupId}/references`;

        if (options?.onMutate) options.onMutate(request);
        const prevDataByService = queryClient.getQueryData<PaymentServiceReference[]>(keyByService);
        const prevDataByGroup = queryClient.getQueryData<PaymentServiceReference[]>(keyByGroup);

        const rollback = () => {
          queryClient.setQueryData(keyByService, prevDataByService);
          queryClient.setQueryData(keyByGroup, prevDataByGroup);
        };

        const successMessage = () => {};
        const errorMessage = () => {};

        return {
          rollback,
          successMessage,
          errorMessage,
        };
      },
      onSuccess(res, request, context: any) {
        const keyByService = `paymentServices/${request.serviceId}/references`;
        const keyByGroup = `paymentServices/groups/${request.groupId}/references`;

        if (options?.onSuccess) void options.onSuccess(res, request, context);
        context.successMessage();

        void queryClient.invalidateQueries(keyByService).then(() => {
          void queryClient.invalidateQueries(keyByGroup);
        });
      },
      onError(error: any, newData, context) {
        if (options?.onError) void options.onError(error, newData, context);
        context.rollback();
      },
    }
  );
};
