import React, { useState } from 'react';
import { useInput, useTranslate } from 'react-admin';

import lodashGet from 'lodash/get';

import OperationTypeInputComponent from '~/components/OperationTypeInput';

type Props = {
  onChange?: (e: any) => void;
  record?: any;
  source: string;
  label?: string;
  initialValue?: string;
  [x: string]: any;
};

const OperationTypeInput = (props: Props) => {
  const { record, source, label, onChange: providedOnChange, ...rest } = props;
  const translate = useTranslate();

  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useInput({
    source,
    ...rest,
  });

  const initialValue = props.initialValue || (record && lodashGet(record, source)) || [];
  const [value, setValue] = useState(initialValue);

  return (
    <OperationTypeInputComponent
      label={label || source}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
        setValue(e.target.value);
        providedOnChange && providedOnChange(e);
      }}
      required={isRequired}
      errorMessage={touched && error && translate(error)}
      {...rest}
    />
  );
};

export default OperationTypeInput;
