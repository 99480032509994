import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { SettingsIcon } from '~/img';
import { TextField, H3, AmountField, HilightChanges } from '~/components';
import { TextField as RaTextFiled } from '~/components/ra';

import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';
import { FeeLoyaltyDiscountType } from '~/api/pricings';
import DateTimeField from '../../../../../components/DateTimeField';

type FeeLoyaltyType = {
  [x: string]: any;
};

interface FeeLoyaltyContentContentProps {
  record?: FeeLoyaltyType;
  prevRecord?: FeeLoyaltyType;
}

export const FeeLoyaltyContent = (props: FeeLoyaltyContentContentProps) => {
  const { record = {}, prevRecord = {} } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`loyalty_.${key}`), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('feeLoyaltyConfiguration')}
        </H3>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('loyaltySourceAccount')}>
          <HilightChanges
            curr={record?.loyaltySourceAccountId}
            prev={prevRecord?.loyaltySourceAccountId}
          >
            {record.loyaltySourceAccountId && (
              <ReferenceField
                record={record}
                source="loyaltySourceAccountId"
                reference="tenant/accounts"
                link={false}
                addLabel={false}
                basePath="/tenant/accounts"
              >
                <RaTextFiled source="alias" addLabel={false} />
              </ReferenceField>
            )}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('discountSourceAccount')}>
          <HilightChanges
            curr={record?.discountSourceAccountId}
            prev={prevRecord?.discountSourceAccountId}
          >
            {record.discountSourceAccountId && (
              <ReferenceField
                record={record}
                source="discountSourceAccountId"
                reference="tenant/accounts"
                link={false}
                addLabel={false}
                basePath="/tenant/accounts"
              >
                <RaTextFiled source="alias" addLabel={false} />
              </ReferenceField>
            )}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('discountType._')}>
          <HilightChanges curr={record?.discountType} prev={prevRecord?.discountType}>
            {record?.discountType && t(`discountType.${record.discountType}`)}
          </HilightChanges>
        </TextField>
      </Grid>
      {(function () {
        switch (record.discountType as FeeLoyaltyDiscountType | undefined) {
          case 'FULL':
            return null;
          case 'PERCENT':
            return (
              <Grid item xs={12}>
                <TextField label={t('discountPercent')}>
                  <HilightChanges curr={record.discountPercent} prev={prevRecord.discountPercent}>
                    {record.discountPercent?.toString() && `${record.discountPercent}%`}
                  </HilightChanges>
                </TextField>
              </Grid>
            );
          case 'FIXED':
            return (
              <Grid item xs={12}>
                <TextField label={t('discountAmount')}>
                  <HilightChanges
                    curr={record.discoundiscountAmounttValue}
                    prev={prevRecord.discountAmount}
                  >
                    <AmountField amount={record.discountAmount} />
                  </HilightChanges>
                </TextField>
              </Grid>
            );
          default:
            return null;
        }
      })()}
      {!!record?.expiration && (
        <Grid item xs={12}>
          <TextField label={t('expiration')}>
            <HilightChanges curr={record?.expiration} prev={prevRecord?.expiration}>
              <DateTimeField date={record?.expiration} format="LL" />
            </HilightChanges>
          </TextField>
        </Grid>
      )}
    </Fragment>
  );
};

interface Props {
  record?: FeeLoyaltyType;
  prevRecord?: FeeLoyaltyType;
}

const FeeLoyaltyConfirmationField = (props: Props) => {
  const { record, prevRecord } = props;

  return (
    <Section>
      <Grid item container spacing={2}>
        <FeeLoyaltyContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default FeeLoyaltyConfirmationField;
