import * as React from 'react';
import { Moment } from '~/types';
import ColoredField from './ColoredField';
import { CellProps } from './CellProps';
import { TextField } from '~/components';
import { time } from '~/utils';

const ListDateTimeField = (props: CellProps<Moment>) => {
  const { children } = props;
  const created = time(children);

  return (
    <ColoredField {...props}>
      <TextField compact label={created.format('LL')}>
        {created.format('LT')}
      </TextField>
    </ColoredField>
  );
};

export default ListDateTimeField;
