import React, { CSSProperties, FC, Fragment, useState } from 'react';
import { useTranslate, Button, usePermissions } from 'react-admin';
import { SvgIcon, Avatar, Card, Grid, Accordion } from '@material-ui/core';
import { time } from '~/utils';
import Skeleton from '@material-ui/lab/Skeleton';
// import { makeStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  Title,
  H2,
  AccordionSummary,
  AccordionDetails,
  EditButton,
  TableCell,
  AmountField,
} from '~/components';
import { SettingsIcon, SyncCircleIcon as CurrenciesIcon } from '~/img';
import { useApi } from '~/hooks';
import { CurrencyPairsType, getCurrenciesPairs } from '~/api/currencies';
import { getCurrenciesRates, CurrenciesRates } from '~/api/fxrates';
import RaTabbedShowLayout from '~/theme/overrides/RaTabbedShowLayout';
import theme from '~/theme';
import { detachKeys, Longdash } from '~/utils';
import RateDataTableEdit from './components/RateDataTableEdit';
import { NoPermissions } from '~/components/ra';
import { MANUAL_SOURCE_SYSTEM } from './components/RatesSourceSelect';
import { useFxRatesCheck } from '~/hooks/fxrates';

export const l = (key: string): string => `resources.fxrates.${key}`;

const CurrenciesList: FC<any> = (props) => {
  const { history } = props;
  const { permissions } = usePermissions();

  const [{ data, loading }, refetch] = useApi<CurrenciesRates[]>(getCurrenciesRates);
  const lastUpdate = data
    ?.filter((item) => item.updated)
    .map((item) => new Date(item.updated as string).getTime())
    .sort((a, b) => b - a)[0];
  const lastUpdateStr = lastUpdate ? time(lastUpdate).format('LL') : Longdash;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  if (!permissions) return null;
  if (!permissions?.includes('fxrates.list')) return <NoPermissions />;

  return (
    <Fragment>
      <Title
        resource={props.resource}
        icon={<CurrenciesPageIcon />}
        headline={t('name')}
        subtitle={`${t('lastUpdate')} ${lastUpdateStr}`}
      />
      <Card>
        <div style={RaTabbedShowLayout.content}>
          <Grid container spacing={2} direction='column'>
            <Grid container item justify='space-between'>
              <Grid item>
                <H2 topSpacing={1} bottomSpacing={0}>
                  {t('showHeading')}
                </H2>
              </Grid>
              {(permissions?.includes('currency.create') ||
                permissions?.includes('currency.pair.update') ||
                permissions?.includes('fxrates.create') ||
                permissions?.includes('fxrates.update') ||
                permissions?.includes('fxrates.mapping.create') ||
                permissions?.includes('fxrates.mapping.update') ||
                permissions?.includes('fxrates.mapping.delete')) && (
                <Grid item>
                  <Button
                    label={t('currencyConversionSetup')}
                    onClick={() => history.push('/fxrates/setup')}
                    style={colorYellow}
                  >
                    <SvgIcon>
                      <SettingsIcon />
                    </SvgIcon>
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <RateData permissions={permissions} data={data} loading={loading} refetch={refetch} />
            </Grid>
          </Grid>
        </div>
      </Card>
    </Fragment>
  );
};

export const getRateData = (
  rate: CurrenciesRates,
  pairs: CurrencyPairsType[] | undefined
): CurrenciesRates['data'] => {
  if (!pairs) return [];
  const rateData = rate.data;
  const [rateDataDict] = detachKeys<CurrenciesRates['data'][0]>(rateData, 'currencyPair');
  const [pairsDict] = detachKeys<CurrencyPairsType>(pairs);

  const masterPairs = pairs.filter((item) => item.isMaster);
  const extraPairs = rate.currencyPairs.map((pairId) => pairsDict[pairId]);
  const resultPairs = masterPairs.concat(extraPairs);

  return resultPairs.map((item): CurrenciesRates['data'][0] => {
    if (rateDataDict[item.id]) return rateDataDict[item.id];
    const { id, baseCurrency, quoteCurrency } = item;
    return {
      currencyPair: id,
      baseCurrency,
      quoteCurrency,
      midRate: 0,
      buyRate: 0,
      sellRate: 0,
      updatedAt: undefined,
    };
  });
};

interface RateDataProps {
  data: CurrenciesRates[] | undefined;
  loading: boolean;
  refetch: () => void;
  permissions?: string[];
}

const RateData: FC<RateDataProps> = (props) => {
  const { data, loading, permissions, refetch } = props;
  const [{ data: pairs }] = useApi<CurrencyPairsType[]>(getCurrenciesPairs);

  return (
    <Fragment>
      {loading &&
        [{}, {}, {}].map((_, key) => {
          return (
            <Grid key={key} item xs={12}>
              <Accordion>
                <AccordionSummary loading />
              </Accordion>
            </Grid>
          );
        })}
      {data &&
        data.map((item: CurrenciesRates) => {
          return (
            <RateDataAccordion
              item={item}
              refetch={refetch}
              permissions={permissions}
              pairs={pairs}
            />
          );
        })}
    </Fragment>
  );
};

interface RateDataAccordionProps {
  item: CurrenciesRates;
  permissions?: string[];
  refetch: () => void;
  pairs?: CurrencyPairsType[] | undefined;
}

const RateDataAccordion: FC<RateDataAccordionProps> = (props) => {
  const { item, permissions, pairs, refetch } = props;
  const key = item.id as string;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const rateDataArr = getRateData(item, pairs);
  const isManual = item.sourceSystem === MANUAL_SOURCE_SYSTEM || !item.sourceSystem;
  const { data: rates, isLoading: ratesIsLoading } = useFxRatesCheck(item, {
    enabled: isExpanded && !isManual,
  });

  const handleChange = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleEdit = () => {
    if (!isExpanded) handleChange();
    setIsEditing((prev) => !prev);
  };

  return (
    <Grid key={key} item xs={12}>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary
          aria-controls={`${key}-content`}
          id={`${key}-header`}
          expanded={isExpanded}
          heading={item.name}
          actionNode={
            isManual ? (
              !isEditing ? (
                <EditButton
                  onClick={handleEdit}
                  disabled={!permissions?.includes('fxrates.data.update')}
                />
              ) : (
                <Button label='ra.action.cancel' onClick={handleEdit} />
              )
            ) : undefined
          }
        />
        <AccordionDetails>
          {permissions?.includes('fxrates.data.view') &&
            (isEditing ? (
              <RateDataTableEdit
                refetch={refetch}
                onClose={handleEdit}
                record={item}
                data={rateDataArr}
              />
            ) : (
              <RateDataTable
                rates={rates}
                ratesIsLoading={ratesIsLoading}
                data={isManual ? rateDataArr : item.data}
                sourceSystem={item.sourceSystem}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

interface RateDataTableProps {
  data: CurrenciesRates['data'];
  sourceSystem: string | undefined;
  rates?: Record<string, number>;
  ratesIsLoading?: boolean;
}

const RateDataTable: FC<RateDataTableProps> = (props) => {
  const { data, sourceSystem, rates, ratesIsLoading } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const isManual = sourceSystem === MANUAL_SOURCE_SYSTEM || !sourceSystem;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('pair')}</TableCell>
          <TableCell align='right'>{t('baseCurrency')}</TableCell>
          <TableCell>{t('mid')}</TableCell>
          <TableCell>{t('buy')}</TableCell>
          <TableCell>{t('sell')}</TableCell>
          <TableCell>{t('lastUpdate')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, index) => {
          const {
            baseCurrency,
            quoteCurrency,
            country,
            midRate,
            buyRate,
            sellRate,
            updatedAt,
          } = item;
          const pairString = `${baseCurrency}/${quoteCurrency}${country ? ` (${country})` : ''}`;

          return (
            <TableRow key={pairString}>
              <TableCell>{pairString}</TableCell>
              <TableCell align='right'>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <AmountField
                    amount={{ value: 1, currency: baseCurrency }}
                    style={{ marginRight: 8 }}
                  />
                  <div>=</div>
                </div>
              </TableCell>
              <TableCell>
                {midRate ? (
                  <AmountField
                    amount={{ value: midRate, currency: quoteCurrency }}
                    maximumFractionDigits={6}
                  />
                ) : (
                  Longdash
                )}
              </TableCell>
              <TableCell>
                {buyRate ? (
                  isManual ? (
                    <AmountField
                      amount={{ value: buyRate, currency: quoteCurrency }}
                      maximumFractionDigits={6}
                    />
                  ) : (
                    <RateField
                      rate={rates?.[`${index}.buy`]}
                      isLoading={ratesIsLoading}
                      quoteCurrency={quoteCurrency}
                      operation='buy'
                      margin={item.buyMargin}
                    />
                  )
                ) : (
                  Longdash
                )}
              </TableCell>
              <TableCell>
                {sellRate ? (
                  isManual ? (
                    <AmountField
                      amount={{ value: sellRate, currency: quoteCurrency }}
                      maximumFractionDigits={6}
                    />
                  ) : (
                    <RateField
                      rate={rates?.[`${index}.sell`]}
                      isLoading={ratesIsLoading}
                      quoteCurrency={quoteCurrency}
                      operation='sell'
                      margin={item.sellMargin}
                    />
                  )
                ) : (
                  Longdash
                )}
              </TableCell>
              <TableCell>{updatedAt ? time(updatedAt).format('LL LT') : Longdash}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const RateField: FC<{
  quoteCurrency: string;
  operation: 'sell' | 'buy';
  margin: number | undefined;
  isLoading?: boolean;
  rate?: number;
}> = (props) => {
  const { isLoading, rate, quoteCurrency /*, operation, margin = 0 */ } = props;

  // const classes = useRateFieldStyles();

  if (isLoading) return <Skeleton width={40} height={10} />;
  if (!rate) return <>{Longdash}</>;
  // const m = operation === 'sell' ? 1 + margin : 1 - margin;
  // const rateWithoutMargin = margin ? rate / m : null;
  const resultRate = Math.round(rate * 10 ** MAXIMUM_FRACTION) / 10 ** MAXIMUM_FRACTION;
  /*
  const resultRateWithoutMargin =
    rateWithoutMargin &&
    Math.round(rateWithoutMargin * 10 ** MAXIMUM_FRACTION) / 10 ** MAXIMUM_FRACTION;
   */
  return (
    <AmountField
      amount={{ value: resultRate, currency: quoteCurrency }}
      maximumFractionDigits={MAXIMUM_FRACTION}
    />
  );
};

const MAXIMUM_FRACTION = 6;

/*
const useRateFieldStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  baseRate: {
    opacity: 0.5,
    marginLeft: theme.spacing(1) / 2,
  },
}));
 */

export const CurrenciesPageIcon: FC<any> = () => {
  return (
    <Avatar>
      <SvgIcon>
        <CurrenciesIcon />
      </SvgIcon>
    </Avatar>
  );
};

const colorYellow: CSSProperties = { color: theme.palette.warning.main };

export default CurrenciesList;
