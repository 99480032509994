import * as React from 'react';
import { usePermissions } from 'react-admin';
import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId } from './utils';
import IndividualDocumentDetails from './IndividualDocumentDetails';

export interface Props {
  country: hb.Country;
  setCountry: hb.SetCountry;
}

const defaultParameters: hb.DocumentParameter[] = [
  {
    name: 'number',
    mandatory: true,
    type: 'text',
    mask: '',
  },
  {
    name: 'dateOfExpiry',
    mandatory: false,
    type: 'date',
    mask: '',
  },
  {
    name: 'dateOfIssue',
    mandatory: false,
    type: 'date',
    mask: '',
  },
];

const IndividualDocumentTable = ({ country, setCountry }: Props) => {
  const documents: hb.IndividualDocument[] = (country.individualDocuments || []).slice();

  const isValid = (
    newDoc: hb.IndividualDocument,
    oldDoc: hb.IndividualDocument | undefined = undefined
  ) => !validateId(documents, newDoc.type, (oldDoc || {}).type, true);

  const setDocument: hb.SetIndividualDocument = (document: hb.IndividualDocument) => {
    const index = documents.findIndex((d) => d.type === document.type);
    if (index >= 0) {
      documents[index] = document;
      return setCountry({ ...country, individualDocuments: documents });
    } else {
      return Promise.reject();
    }
  };

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Type',
          field: 'type',
          required: true,
          validate: validateId,
          cellStyle: {
            minWidth: 200,
          },
        },
        {
          type: 'string',
          title: 'Pages',
          field: 'pageNames',
          label: 'Page names',
          cellStyle: {
            minWidth: 300,
          },
          required: true,
          enum: {},
          render: (rowData: hb.IndividualDocument) => {
            return (rowData.pageNames || []).join(', ');
          },
        },
      ]}
      components={{
        Container: (props: any) => (
          <Paper variant="outlined" style={{ borderRightWidth: 0 }}>
            {props.children}
          </Paper>
        ),
      }}
      data={documents}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newDoc: hb.IndividualDocument) => {
            if (isValid(newDoc)) {
              const newDocWithDefaultParameters = { ...newDoc, parameters: defaultParameters };
              documents.push(newDocWithDefaultParameters);
              return setCountry({ ...country, individualDocuments: documents });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newDoc: hb.IndividualDocument, oldDoc: hb.IndividualDocument) => {
            if (isValid(newDoc, oldDoc)) {
              const index = documents.indexOf(oldDoc);
              documents[index] = newDoc;
              return setCountry({ ...country, individualDocuments: documents });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldDoc: hb.IndividualDocument) => {
            const index = documents.indexOf(oldDoc);
            documents.splice(index, 1);
            return setCountry({ ...country, individualDocuments: documents });
          },
        }
      }
      detailPanel={(rowData: hb.IndividualDocument) => {
        return <IndividualDocumentDetails document={rowData} setDocument={setDocument} />;
      }}
      onRowClick={(event: any, rowData: hb.Country, togglePanel: () => void) => togglePanel()}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        search: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default IndividualDocumentTable;
