import { useCallback } from 'react';
import { useTranslate } from 'react-admin';

import EventsList from './EventsList';
import EventShow from './EventShow';
import { EventReferenceEntity } from '~/types/Event';

export const resource = 'events';

export const useTranslateEventsList = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.list.${key}`), [translate]);
};

export const useTranslateEventsShow = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.show.${key}`), [translate]);
};

export const chooseEntityWithMinIndex = (a?: EventReferenceEntity, b?: EventReferenceEntity) =>
  (a?.index ?? Infinity) < (b?.index ?? Infinity) ? a : b;

export default {
  list: EventsList,
  show: EventShow,
};
