import { useState, useCallback, useEffect } from 'react';

type State<Data> = {
  data?: Data;
  loading: boolean;
  error?: any;
  response?: any;
};

const initialState = {
  data: undefined,
  loading: false,
  error: undefined,
};

const useApiLazy = <Data = any>(
  call: (props: any) => Promise<any>,
  deps: any[] = [],
  options: any = null
): [(props: any) => void, State<Data>] => {
  const [state, setState] = useState<State<Data>>(initialState);

  useEffect(() => {
    return () =>
      setState({
        ...initialState,
      });
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  const request = useCallback(
    (props: any) => {
      setState({
        ...initialState,
        loading: true,
      });
      call(props)
        .then((response) => {
          setState({
            data: response?.data,
            loading: false,
            response,
          });
          if (options?.onSuccess) {
            options.onSuccess();
          }
        })
        .catch((error) => {
          setState({
            loading: false,
            error,
          });
          if (options?.onError) {
            options.onError();
          }
        });
    },
    [call, options]
  );

  return [request, state];
};

export default useApiLazy;
