import { isEqual } from 'lodash';
import { Path } from 'path-parser';
import { cleanupFormValues } from '~/utils';
import { history } from '~/App';
import useMutableCallback from '~/hooks/useMutableCallback';

const path = new Path('/:route/:id/:mode/:subroute?filter&order&sort&displayMode&page&perPage');

const defaultPageSize = 10;

type Params = {
  params: {
    page?: number;
    perPage?: number;
    filter?: Record<string, string | number>;
    [x: string]: any;
  };
  changePath: (newParams: any) => void;
  setPath: (newParams: any) => void;
  pagination: {
    defaultCurrent: number;
    defaultPageSize: number;
    hideOnSinglePage: boolean;
    onChange: (page: number, perPage?: number) => void;
  };
};

const usePath = (): Params => {
  const { pathname, search } = history.location;
  const pathParams = handlePathParams(path.partialTest(pathname + search)) || {};

  const params = pathParams.filter
    ? { ...pathParams, filter: JSON.parse(pathParams.filter) }
    : pathParams;

  const changePath = useMutableCallback((newParams: any) => {
    const resultNewParams = { ...newParams };
    if (params.filter || newParams.filter) {
      const newFilter = { ...params.filter, ...newParams.filter };
      if (!isEqual(params.filter, newFilter)) {
        resultNewParams.page = 1;
      }
      resultNewParams.filter = JSON.stringify(cleanupFormValues(newFilter));
    }
    console.log('newParams', { ...params, ...resultNewParams });
    history.replace(path.build({ ...params, ...resultNewParams }));
  });

  const setPath = (newParams: any) => {
    history.replace(path.build(newParams));
  };

  const pagination = {
    defaultCurrent: Number(params.page) || 1,
    defaultPageSize: Number(params.perPage) || defaultPageSize,
    current: params.page ? Number(params.page) : undefined,
    perPage: params.page ? Number(params.perPage) : undefined,
    hideOnSinglePage: true,
    onChange: (page: number, perPage = defaultPageSize) => changePath({ page, perPage }),
  };

  return { params, changePath, setPath, pagination };
};

const handlePathParams = (params: any) => {
  if (params) {
    const { page, perPage, ...rest } = params;
    return { page: getNumber(page), perPage: getNumber(perPage), ...rest };
  }
};

const getNumber = (value: any): number => {
  if (typeof value === 'string') return Number(value);
  return 0;
};

usePath.defaultPageSize = defaultPageSize;

export default usePath;
