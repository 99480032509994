export default {
  name: 'Feedback |||| Feedbacks',
  subtitle: 'All feedback record',
  fields: {
    empty: 'No feedback found',
    timestamp: 'Timestamp',
    comment: 'Comment',
    feedback: 'Feedback',
    fromCreatedAtStr: 'Created from',
    toCreatedAtStr: 'Created to',
    subjectId: 'Rate by (profile ID)',
    groupId: 'Rate for (profile ID)',
    lastFeedback: 'Last feedback',
    viewAllFeedback: 'View all feedbacks',
    rateBy: 'Rate by',
    rateFor: 'Rate for',
    statistic: 'Statistic',
    count: 'Count',
  },
};
