import React, { FC } from 'react';
import { Tab, useTranslate, usePermissions } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';

import { PaperTabbedShowLayout, Show } from '~/layout';
import { resource } from './ActionReportsList';
import ReportData from './Tabs/ReportData';
import { ActionReport } from '~/types';
import getObjectTypeIcon from '~/utils/getObjectTypeIcon';
import { NoPermissions } from '~/components/ra';

const ActionReportsShow: FC<any> = (props) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);

  if (!permissions) return null;
  if (!permissions.includes('report.view')) return <NoPermissions />;
  return (
    <Show
      {...props}
      iconFn={(record: ActionReport) => <Avatar>{getObjectTypeIcon(record?.objectType)}</Avatar>}
    >
      <PaperTabbedShowLayout>
        <Tab label={t('reportData')}>
          <ReportData />
        </Tab>
      </PaperTabbedShowLayout>
    </Show>
  );
};

export default ActionReportsShow;
