import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { H2 } from '~/components';

import { useRightContentSpacing } from '~/hooks/styles';
import { Reference } from '~/types';
import { isEmpty } from 'lodash';
import AddAffiliatedObject from './AddAffiliatedObject';
import ViewAffiliatedObjects from './ViewAffiliatedObjects';
import ReferenceCard from './ReferenceCard';

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  id: string;
  references?: Reference[];
  resource: 'accounts' | 'individuals';
  refetch?: () => void;
  changelogMode?: boolean;
  withAdd?: boolean;
  withArchive?: boolean;
  withViewAll?: boolean;
}

const AffiliatedObjects = (props: Props) => {
  const {
    id,
    references,
    resource,
    refetch,
    changelogMode,
    withAdd,
    withArchive,
    withViewAll,
  } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.AffiliatedObjects.${key}`);

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  const active = references?.filter((reference) => !reference.deleted);
  return (
    <Grid item className={classes.rightContent}>
      <div className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={1}>
          {t('affiliatedObjects')}
        </H2>
        <div>
          {withAdd && <AddAffiliatedObject id={id} refetch={refetch} resource={resource} />}
          {withViewAll && (
            <ViewAffiliatedObjects
              references={references}
              id={id}
              refetch={refetch}
              withArchive={withArchive}
            />
          )}
        </div>
      </div>
      {isEmpty(active) ? (
        <div>{t('noAffiliatedObjects')}</div>
      ) : (
        active?.map((reference) => (
          <ReferenceCard key={reference.id} reference={reference} changelogMode={changelogMode} />
        ))
      )}
    </Grid>
  );
};

export default AffiliatedObjects;
