import React from 'react';

import { AmountInput } from '~/components/ra';
import { TextInput, NumberInput, SelectInput, useTranslate } from 'react-admin';

import lodashGet from 'lodash/get';

interface Value {
  type: string | unknown;
  [key: string]: any;
}

export interface Props {
  source: string;
  value?: Value;
  record?: { [key: string]: any };
  label?: string;
  types?: string[];
  [x: string]: any;
}

const FeeInput = ({ name, source, value, record, label, types, ...rest }: Props) => {
  const actualValue = value || lodashGet(record, source);

  const actualTypes = types || [
    'fixedDebitFee',
    'fixedDebitFeeDecrease',
    'percentDebitFee',
    'percentDebitFeeDecrease',
    'fixedCreditFee',
    'percentCreditFee',
  ];

  const [state, setState] = React.useState<Value>(value || { type: actualTypes[0] });

  const handleTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newState = {
      ...state,
      type: event.target.value,
    };
    setState(newState);
  };

  const translate = useTranslate();

  let inputForType = <></>;
  switch (actualValue?.type || actualTypes[0]) {
    case 'fixedDebitFee':
    case 'fixedDebitFeeDecrease':
    case 'fixedCreditFee':
      inputForType = (
        <>
          <div>
            <TextInput
              {...rest}
              label={`${label}.value.name`}
              source={`${source}.value.name`}
              value={actualValue?.value?.name || ''}
            />
          </div>
          <AmountInput
            {...rest}
            label={`${label}.value.amount`}
            source={`${source}.value.amount`}
          />
        </>
      );
      break;
    case 'percentDebitFee':
    case 'percentDebitFeeDecrease':
    case 'percentCreditFee':
      inputForType = (
        <>
          <div>
            <TextInput {...rest} label={`${label}.value.name`} source={`${source}.value.name`} />
          </div>
          <div>
            <NumberInput
              {...rest}
              label={`${label}.value.percent`}
              source={`${source}.value.percent`}
            />
          </div>
          <AmountInput
            {...rest}
            label={`${label}.value.minAmount`}
            source={`${source}.value.minAmount`}
          />
          <AmountInput
            {...rest}
            label={`${label}.value.maxAmount`}
            source={`${source}.value.maxAmount`}
          />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div>
      <SelectInput
        label={label && `${label}.type._`}
        source={`${source}.type`}
        choices={actualTypes.map((key) => {
          return { id: key, name: translate(`${label}.type.${key}`) };
        })}
        onChange={handleTypeChange}
        value={actualValue?.type || actualTypes[0]}
      />
      {inputForType}
    </div>
  );
};

export default FeeInput;
