import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#442b85', // colors.teal[900],
    main: '#623FBF', // colors.teal[800],
    light: '#795bc9', // colors.teal[700],
  },
  secondary: {
    contrastText: white,
    dark: '#623FBF', //colors.teal[700],
    main: '#795bc9', // colors.teal[600],
    light: '#9078d2', // colors.teal[500],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: '#65c9a7',
    light: colors.green[400],
  },
  action: {
    hover: colors.grey[200],
    disabledOpacity: 0.7,
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: '#F29B26', //colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: '#E34035', //colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[800],
    link: colors.blue[600],
  },
  background: {
    default: '#F5F7F9',
    header: white,
    selection: '#F4F6F8',
    paper: white,
    table: colors.grey[100],
    outterTable: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300],
  charts: {
    0: '#623FBF', // colors.teal[500],
    1: '#442b85', // colors.teal[200],
    2: '#9078d2', // colors.teal[800],
  },
  changelog: {
    yellow: '#FFE8B4',
    yellowDarker: '#FFE099',
    yellowEvenDarker: '#FFD166',
    yellowHover: '#FFDD8F',
  },
  profileMenu: {
    iconGray: 'rgba(0, 0, 0, 0.54)',
    textGray: '#37474f',
  },
  imgFiles: {
    wrapperBackground: 'hsla(0, 0%, 96%, 0.5)',
  },
};
