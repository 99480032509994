import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { time } from '~/utils';
import makeStyles from '@material-ui/core/styles/makeStyles';

import StarIcon from '@material-ui/icons/Star';
import { CubeIcon, TimeIcon } from '~/img';

import { TextField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { AsideBase } from '~/layout';
import { Pricing } from '~/types';
import { l } from '~/resources/pricings/PricingShow';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
  },
  ActiveTimeField: {
    display: 'flex',
    color: theme.palette.success.main,
    '& > div': {
      display: 'flex',
      maxHeight: 16,
    },
  },
  infiniteIcon: {
    fontSize: 20,
    marginTop: -5,
  },
  svg: {
    fontSize: '16px',
  },
  arrow: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
  },
}));

export const InfiniteIcon = () => {
  const classes = useStyles();
  return <div className={classes.infiniteIcon}>&#8734;</div>;
};

const ActiveTimeField = (props: any) => {
  const { activeFrom, activeTill } = props;
  const classes = useStyles();

  return (
    <div className={classes.ActiveTimeField}>
      {!activeFrom && !activeTill ? (
        <div>
          <InfiniteIcon />
        </div>
      ) : (
        <Fragment>
          <div>
            {activeFrom ? time(activeFrom).format('L') : <InfiniteIcon />}
            <div className={classes.arrow}>&#10230;</div>
          </div>
          <div>{activeTill ? time(activeTill).format('L') : <InfiniteIcon />}</div>
        </Fragment>
      )}
    </div>
  );
};

interface Props {
  record?: Pricing;
  loading?: boolean;
  [x: string]: any;
}

const Aside = (props: Props) => {
  const { record, loading } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const classes = useStyles();

  return (
    <AsideBase width={368} className={classes.root}>
      <Grid container spacing={2} justify='space-between'>
        <Grid item xs={12}>
          <ReferenceField
            source='solutionId'
            reference='solutions'
            link={false}
            addLabel={false}
            {...props}
          >
            <RaTextField
              source='name'
              label={t('solution')}
              icon={<StarIcon />}
              showLink={`/solutions/${record?.solutionId}/show/pricingTables`}
            />
          </ReferenceField>
        </Grid>
        {record?.productId && (
          <Grid item xs={12}>
            <ReferenceField
              source='productId'
              reference='products'
              link={false}
              addLabel={false}
              {...props}
            >
              <RaTextField source='name' label={t('paymentInstrument')} icon={<CubeIcon />} />
            </ReferenceField>
          </Grid>
        )}
        {record?.externalSourceType && (
          <Grid item xs={12}>
            <TextField label={t('paymentInstrument')} icon={<CubeIcon />}>
              {record?.externalSourceType}
            </TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label={t('active')} loading={loading} icon={<TimeIcon />}>
            <ActiveTimeField activeFrom={record?.activeFrom} activeTill={record?.activeTill} />
          </TextField>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

export default Aside;
