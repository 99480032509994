import { useQuery } from 'react-query';
import {
  getPartnerByExternalId,
  IntegrationIdentityAction,
  listIdentityActions,
} from '~/api/partners';

export const useIntegrationIdentityActionsQuery = (
  partnerId: string,
  options?: {
    onSuccess?: (res: IntegrationIdentityAction[]) => void;
    enabled?: boolean;
  }
) => {
  const key = `integrationIdentityActions`;
  return useQuery<IntegrationIdentityAction[]>(
    key,
    () => listIdentityActions(partnerId).then((res) => res.data),
    options
  );
};

export const useGetPartnerByExternalId = (
  externalId: string,
  options?: {
    onSuccess?: (res: any) => void;
    enabled?: boolean;
  }
) => {
  const key = `partners/byExternalId/${externalId}`;
  return useQuery<any>(
    key,
    () => getPartnerByExternalId(externalId).then((res) => res.data),
    options
  );
};
