import React from 'react';
import { AmountField, H3 } from '~/components';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { Balance } from '~/types/account';

interface Props {
  balances: Balance[];
  loading?: boolean;
  resource?: string;
}

const label = (key: string): string => `components.ra.AccountList.${key}`;

const BalancesTable = (props: Props) => {
  const { balances, loading, resource } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const t = (key: string): string => translate(label(key));
  return (
    <Grid container item xs={12}>
      <Grid item container xs className={classes.table}>
        <H3 icon={<AttachMoneyIcon />} noPaddingLeft loading={loading}>
          {t('balances._')}
        </H3>
        {loading ? (
          <Skeleton variant='rect' width={420} height={148} />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('balances.balance')}</TableCell>
                <TableCell>{t('balances.amount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {balances?.map((balance, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {t(
                        `balances.code.${
                          resource === 'individuals' || resource === 'INDIVIDUAL'
                            ? 'individuals_'
                            : ''
                        }${balance.code}`
                      )}
                    </TableCell>
                    <TableCell className={classes.amountCell}>
                      <AmountField amount={balance.amount} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
}));

export default BalancesTable;
