import moment from 'moment-timezone';

import { TenantType } from '~/api/utils';
import { queryClient } from '~/App';
import { tenantQueryKey } from '~/hooks/tenant';

const time = (time?: moment.MomentInput, timeZone?: string): moment.Moment => {
  const tenant = queryClient.getQueryData<TenantType>(tenantQueryKey);
  return moment(time).tz(tenant?.data.timeZone || timeZone || '');
};

export default time;
