import React, { FC } from 'react';
import { TextInput, required, SaveButton } from 'react-admin';
import { useMutation } from 'react-query';
import { Alert } from '@material-ui/lab';

import { Drawer } from '~/components';
import { SimpleForm } from '~/components/ra';
import Toolbar from '~/components/ra/Toolbar';
import { Deal } from '~/types/Deal';
import axios from '~/utils/axios';
import { useTranslateDealDetails } from '.';

interface Props {
  open: boolean;
  onClose: () => void;
  record: Deal | undefined;
}

const EditDrawer: FC<Props> = (props) => {
  const { open, onClose, record } = props;
  const t = useTranslateDealDetails();

  const { mutateAsync, isLoading } = useMutation(updateDeal);

  const onSave = async (values: FormValues) => {
    if (!record) return null;
    try {
      await mutateAsync({ record, values });
      onClose();
    } catch {
      //
    }
  };

  return (
    <Drawer heading={t('editDealDetails')} open={open} onClose={onClose}>
      <Alert severity="warning" style={{ marginBottom: 24 }}>
        {t('allChangesWillBeReported')}
      </Alert>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton onSave={onSave} />
          </Toolbar>
        }
        saving={isLoading}
      >
        <TextInput
          source="beneficiaryFirstName"
          label={t('beneficiaryFirstName')}
          defaultValue={record?.beneficiary.senderData?.firstName}
          validate={required()}
          fullWidth
        />
        <TextInput
          source="beneficiaryLastName"
          label={t('beneficiaryLastName')}
          defaultValue={record?.beneficiary.senderData?.lastName}
          validate={required()}
          fullWidth
        />
        <TextInput
          source="reason"
          label={t('reason')}
          multiline
          rows={5}
          rowsMax={20}
          validate={required()}
          fullWidth
        />
      </SimpleForm>
    </Drawer>
  );
};

type FormValues = {
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  reason: string;
};

const updateDeal = ({ record, values }: { record: Deal; values: FormValues }) => {
  return axios
    .post('/plugins/clicksendnow/updateDeal', {
      id: record.id,
      ...values,
    })
    .then((res) => res.data);
};

export default EditDrawer;
