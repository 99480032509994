import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Props } from './';
import ColoredField from './ColoredField';
import { hasTranslation } from '~/utils';

const OperationTypeField = (props: Props<string>) => {
  const { children } = props;
  const translate = useTranslate();
  const l = (key: string): string =>
    `components.TransactionsHistory.cellComponents.OperationTypeField.${key}`;
  const result = hasTranslation(l(children)) ? translate(l(children)) : children;
  return <ColoredField {...props}>{result}</ColoredField>;
};

export default OperationTypeField;
