import React, { FC } from 'react';
import { useTranslate, FormDataConsumer, FileInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import lodashGet from 'lodash/get';

import { H3 } from '~/components';
import { BusinessDocumentForm } from '~/resources/businesses/components';
import { File } from '~/types';
import IndividualDocumentForm from '~/resources/individuals/components/IndividualDocumentForm';
import { FileCard } from './FileCard';

interface Props {
  getSource: (source: string) => string;
  primary: boolean | undefined;
  edit: boolean | undefined;
  documentForm: 'INDIVIDUAL' | 'BUSINESS';
  solutionId?: string;
}

type FormData = {
  formData: any;
  [x: string]: any;
};

const DocumentCardFields: FC<Props> = (props) => {
  const { getSource, primary, edit, documentForm, solutionId } = props;

  const label = (key: string): string => `resources.businesses.fields.${key}`;
  const translate = useTranslate();

  const classes = useStyles();

  return (
    <FormDataConsumer>
      {({ formData }: FormData) => {
        return (
          <>
            {documentForm === 'BUSINESS' ? (
              <BusinessDocumentForm
                labelBase={label('documents')}
                solutionId={formData.solutionId || solutionId}
                country={formData.country}
                legalType={formData.legalType}
                getSource={getSource}
                selector={getSource}
                scopedFormData={formData}
                onlyPrimary={primary}
                edit={edit}
              />
            ) : documentForm === 'INDIVIDUAL' ? (
              <IndividualDocumentForm
                labelBase={label('documents')}
                solutionId={formData.solutionId || solutionId}
                country={formData.country}
                getSource={getSource}
                selector={getSource}
                scopedFormData={formData}
                onlyPrimary={primary}
                edit={edit}
              />
            ) : null}
            <H3>{translate(`components.ra.fields.DocumentCard.scans`)}</H3>
            <Grid container spacing={1}>
              {lodashGet(formData, getSource('files'))?.map((item: File, index: number) => {
                return (
                  <Grid item xs={6} key={index}>
                    <FileCard index={index} item={item} formData={formData} getSource={getSource} />
                  </Grid>
                );
              })}
              <Grid item xs={6}>
                <FileInput
                  label=''
                  source={getSource('files')}
                  multiple
                  className={classes.fileInput}
                  options={{
                    disabled: !formData.legalType && documentForm === 'BUSINESS',
                  }}
                  children={<EmptyComponent />}
                  placeholder={<Placeholder />}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const EmptyComponent = () => <div />;
const Placeholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.placeholder}>
      <AddIcon />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fileInput: {
    opacity: 0.4,
    transition: `opacity ${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    '&:hover': {
      opacity: 1,
    },
    '& div': {
      padding: 0,
    },
    '& .previews': {
      display: 'none',
    },
  },
  placeholder: {
    display: 'flex',
    height: 170,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 42,
    },
  },
}));

export default DocumentCardFields;
