import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NokIcon from '@material-ui/icons/HighlightOffTwoTone';
import OkIcon from '@material-ui/icons/CheckCircleTwoTone';

interface Props {
  password: string;
}

const PasswordStrengthChecklist: FC<Props> = (props) => {
  const { password } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Li ok={passwordValidators.enothLength(password)}>
        Password length must be at least {PASSWORD_MIN_LENGTH} characters
      </Li>
      <Li ok={passwordValidators.containsLetters(password)}>Password must contain letters</Li>
      <Li ok={passwordValidators.containsDigits(password)}>
        Password must contain at least 1 digit
      </Li>
    </div>
  );
};

const Li: FC<any> = (props) => {
  const { children, ok = false } = props;
  const classes = useLiStyles({ ok });
  return (
    <div className={classes.root}>
      {ok ? <OkIcon className={classes.icon} /> : <NokIcon className={classes.icon} />}
      <div>{children}</div>
    </div>
  );
};

export const passwordValidators = {
  containsLetters(password: string): boolean {
    const isCharacterALetter = (char: string): boolean => {
      return /[a-zA-Z]/.test(char);
    };
    return !!password.split('').find(isCharacterALetter);
  },
  containsDigits(password: string): boolean {
    return !!password.split('').find((item) => !isNaN(parseInt(item)));
  },
  enothLength(password: string): boolean {
    return password.length >= 7;
  },
};

export const PASSWORD_MIN_LENGTH = 7;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

const useLiStyles = makeStyles<Theme, { ok: boolean }>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: ({ ok }) => (ok ? theme.palette.success.main : theme.palette.grey[400]),
    marginRight: theme.spacing(1),
    fontSize: '1rem',
  },
}));

export default PasswordStrengthChecklist;
