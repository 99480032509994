export default {
  editWithReporting: 'Edit (with reporting to SARB)',
  editDealDetails: 'Edit deal details',
  allChangesWillBeReported:
    'All changes will be immediately reported to the South African Reserve Bank (SARB).',
  reportingCategory: 'Remitter reporting category',
  beneficiaryFirstName: 'Beneficiary first name',
  beneficiaryLastName: 'Beneficiary last name',
  reason: 'Reason',
};
