import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import GeolocationIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../TableCell';

import CountryField from '../CountryField';
import { Address } from '../ra/AddressForm';
import { Longdash } from '~/utils';
import { isEqual } from 'lodash';
import AddressShow from './AddressShow';
import palette from '~/theme/palette';
import cx from 'classnames';

interface Props {
  address?: Address;
  loading?: boolean;
  oldAddress?: Address; // to compare
  type?: string;
  drawerHeading?: string;
  changelogMode?: boolean;
}

const AddressTable: FC<Props> = (props) => {
  const { address, oldAddress, type, loading, drawerHeading, changelogMode } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.address.${key}`);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AddressShow
        address={address}
        heading={drawerHeading}
        open={open}
        onClose={handleClose}
        changelogMode={changelogMode}
      />
      <Table className={changelogMode ? classes.changelogMode : undefined}>
        <TableHead>
          <TableRow className={changelogMode ? classes.changelogMode : undefined}>
            {type && <TableCell loading={loading}>{t('type')}</TableCell>}
            <TableCell loading={loading}>{t('country')}</TableCell>
            <TableCell loading={loading}>{t('postalCode')}</TableCell>
            <TableCell loading={loading}>{t('region')}</TableCell>
            <TableCell loading={loading}>{t('city')}</TableCell>
            <TableCell loading={loading}>{t('addressLine')}</TableCell>
            {address?.geolocation && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            hover
            onClick={handleOpen}
            className={cx(classes.row, changelogMode ? classes.changelogModeHover : undefined)}
          >
            {type && <TableCell loading={loading}>{type}</TableCell>}
            <TableCell
              loading={loading}
              isDiff={oldAddress ? isEqual(address?.country, oldAddress.country) : false}
            >
              <CountryField data={address?.country} />
            </TableCell>
            <TableCell
              loading={loading}
              isDiff={oldAddress ? isEqual(address?.postalCode, oldAddress.postalCode) : false}
            >
              {address?.postalCode || Longdash}
            </TableCell>
            <TableCell
              loading={loading}
              isDiff={oldAddress ? isEqual(address?.region, oldAddress.region) : false}
            >
              {address?.region || Longdash}
            </TableCell>
            <TableCell
              loading={loading}
              isDiff={oldAddress ? isEqual(address?.city, oldAddress.city) : false}
            >
              {address?.city || Longdash}
            </TableCell>
            <TableCell
              loading={loading}
              isDiff={oldAddress ? isEqual(address?.addressLine, oldAddress.addressLine) : false}
            >
              {address?.addressLine || Longdash}
            </TableCell>
            {address?.geolocation && (
              <TableCell>
                <Tooltip
                  title={`${address.geolocation.latitude}, ${address.geolocation.longitude}`}
                >
                  <GeolocationIcon className={classes.geolocationIcon} />
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  geolocationIcon: {
    marginTop: -4,
    marginBottom: -4,
    fontSize: 18,
    color: theme.palette.grey[500],
  },
  row: {
    cursor: 'pointer',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellowDarker,
    '& > thead > tr:last-child': {
      borderColor: palette.changelog.yellowEvenDarker,
    },
  },
  changelogModeHover: {
    '&:hover': {
      backgroundColor: `${palette.changelog.yellowHover} !important`,
    },
  },
}));

export default AddressTable;
