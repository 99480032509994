import React, { useState, FC, Fragment, useEffect } from 'react';
import { useTranslate, Button } from 'react-admin';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { time } from '~/utils';
import { H3, AccordionSummary, TextField, ConfirmationBase } from '~/components';
import { l } from '../PartnerCreate';
import { Partner } from '~/types';
import {
  getIntegrationApiIdentity,
  IntegrationApiIdentityType,
  createIntegrationApiIdentity,
  CreatedIntegrationApiIdentityType,
  updateIntegrationApiIdentity,
} from '~/api/product';
import { useApiLazy, useApi, useNotify } from '~/hooks';
import { FlashIcon } from '~/img';
import ExternalSourceTable from './ExternalSourceTable';
import { getPartnerConfiguration, setPartnerConfiguration } from '~/api/partners';
import onError from '~/errorsHandler';
import { useIntegrationIdentityActionsQuery } from '~/hooks/partners';

interface Props {
  record?: Partner;
  [x: string]: any;
  permissions?: string[];
}

const Configuration: FC<Props> = (props) => {
  const { permissions, record } = props;
  const partnerID = record?.id;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));
  const notify = useNotify();
  const classes = useStyles();

  const [{ data: initialConfiguration }] = useApi<any>(() => {
    return getPartnerConfiguration(partnerID as string);
  });

  const [configuration, setConfiguration] = useState<any>({});

  useEffect(() => {
    setConfiguration(initialConfiguration);
  }, [initialConfiguration]);

  const [{ data: identityData, loading: identityLoading }] = useApi<IntegrationApiIdentityType>(
    () => getIntegrationApiIdentity(partnerID || ''),
    [partnerID]
  );

  const { data: integrationIdentityActions } = useIntegrationIdentityActionsQuery(partnerID || '');

  const [
    createIntegrationApiIdentityQuery,
    { data: createdIdentityData, loading: createdIdentitiyLoading },
  ] = useApiLazy<CreatedIntegrationApiIdentityType>(createIntegrationApiIdentity);

  const [
    updateIntegrationApiIdentityQuery,
    { data: updatedIdentityData, loading: updatedIdentitiyLoading },
  ] = useApiLazy<IntegrationApiIdentityType>(updateIntegrationApiIdentity, [], {
    onSuccess: () => {
      notify({ message: t('identityUpdateSuccess'), type: 'success' });
    },
    onError: () => {
      notify({ message: t('identityUpdateError'), type: 'error' });
    },
  });

  const [identity, setIdentity] = useState<IntegrationApiIdentityType | null>(null);
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    if (identityData) setIdentity(identityData);
  }, [identityData]);
  useEffect(() => {
    if (createdIdentityData) {
      setToken(createdIdentityData.token);
      setIdentity(createdIdentityData.identity);
    }
  }, [createdIdentityData]);
  useEffect(() => {
    if (updatedIdentityData) {
      setIdentity(updatedIdentityData);
    }
  }, [updatedIdentityData]);

  const copyApiCode = () => {
    navigator.clipboard.writeText(token || '').then(
      () => {
        notify({ message: t('copied'), type: 'info' });
      },
      (error) => {
        onError(error);
      }
    );
  };

  // checkboxes
  const [actions, setActions] = useState<any>({});
  useEffect(() => {
    const newActions: any = {};
    (identity?.actions || []).forEach((a) => {
      newActions[a] = true;
    });
    setActions(newActions);
  }, [identity]);

  const onCheckboxChange = (key: string) => (e: any) => {
    const newActions = { ...actions };
    if (e.target.checked) {
      newActions[key] = true;
    } else {
      delete newActions[key];
    }
    setActions(newActions);
  };

  // Confirmation modal
  const [confirmOpen, setConfirmOpen] = useState(false);
  const openConfirm = () => setConfirmOpen(true);
  const closeConfirm = () => setConfirmOpen(false);

  // ExpansionPanel
  const [apiIdentityExpanded, setApiIdentityExpanded] = useState<boolean>(false);
  const handleApiIdentityExpandedChange = (isExpanded: boolean) => () => {
    setApiIdentityExpanded(!isExpanded);
  };

  const [channelConfigurationExpanded, setChannelConfigurationExpanded] = useState<boolean>(false);
  const handleChannelConfigurationExpandedChange = (isExpanded: boolean) => () => {
    setChannelConfigurationExpanded(!isExpanded);
  };

  const [externalSourcesExpanded, setExternalSourcesExpanded] = useState<boolean>(false);
  const handleExternalSourcesExpandedChange = (isExpanded: boolean) => () => {
    setExternalSourcesExpanded(!isExpanded);
  };

  return (
    <Fragment>
      <ConfirmationBase
        open={confirmOpen}
        handleClose={closeConfirm}
        confirmationSettings={{
          variant: 'modal',
          modal: {
            content: t('warningApiCode'),
          },
        }}
        onConfirm={() => createIntegrationApiIdentityQuery(partnerID)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Accordion
            expanded={apiIdentityExpanded}
            onChange={handleApiIdentityExpandedChange(apiIdentityExpanded)}
          >
            <AccordionSummary
              aria-controls={`${1}-content`}
              id={`${1}-header`}
              expanded={apiIdentityExpanded}
              heading={t('apiIdentity')}
            />
            <AccordionDetails key="incoming-details" className={classes.AccordionDetails}>
              <Grid item container spacing={2}>
                <Grid item container spacing={2} xs={4}>
                  {identity || identityLoading ? (
                    <Fragment>
                      <Grid item xs={12}>
                        <TextField label={t('tokenPrefix')} loading={identityLoading}>
                          {identity?.tokenPrefix}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField label={t('createdAt')} loading={identityLoading}>
                          {identity?.createdAt && time(identity.createdAt).format('L LTS')}
                        </TextField>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid item xs={12}>
                      <H3 topSpacing={0} bottomSpacing={0}>
                        {t('noIdentity')}
                      </H3>
                    </Grid>
                  )}
                  {permissions?.includes('partner.configuration.update') && (
                    <>
                      <Grid item xs={12}>
                        <Button
                          label={t('getCode')}
                          onClick={
                            identity
                              ? openConfirm
                              : () => createIntegrationApiIdentityQuery(partnerID)
                          }
                          disabled={createdIdentitiyLoading || identityLoading}
                          variant="contained"
                        >
                          <SvgIcon>
                            <FlashIcon />
                          </SvgIcon>
                        </Button>
                      </Grid>
                      {identity && (
                        <>
                          <Grid item xs={12}>
                            <H3>{t('identityPermissions')}</H3>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell>{t('identityPermissionName')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {integrationIdentityActions &&
                                  integrationIdentityActions.map((action) => (
                                    <TableRow key={action.key}>
                                      <TableCell classes={{ root: classes.checkboxCell }}>
                                        <Checkbox
                                          className={classes.checkbox}
                                          checked={!!actions[action.key]}
                                          onChange={onCheckboxChange(action.key)}
                                        />
                                      </TableCell>
                                      <TableCell>{action.name}</TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              label={t('saveIdentityPermissions')}
                              onClick={() => {
                                updateIntegrationApiIdentityQuery({
                                  partnerId: partnerID,
                                  data: {
                                    actions: Object.keys(actions),
                                  },
                                });
                              }}
                              disabled={updatedIdentitiyLoading || identityLoading}
                              variant="contained"
                            >
                              <SvgIcon>
                                <FlashIcon />
                              </SvgIcon>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                {token && (
                  <Grid item xs={8}>
                    <Alert
                      severity="success"
                      onClose={() => setToken(null)}
                      className={classes.breakWord}
                    >
                      <AlertTitle>{t('yourCode')}</AlertTitle>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {token}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            label={t('copyCode')}
                            onClick={copyApiCode}
                            className={classes.copyButton}
                          >
                            <FileCopyOutlinedIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={channelConfigurationExpanded}
            onChange={handleChannelConfigurationExpandedChange(channelConfigurationExpanded)}
          >
            <AccordionSummary
              key="outgoing-summary"
              aria-controls={`${1}-content`}
              id={`${1}-header`}
              expanded={channelConfigurationExpanded}
              heading={t('channelConfiguration')}
            />
            <AccordionDetails key="outgoing-details" className={classes.AccordionDetails}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField label={t('connectionStatus')}>{t('notConfigured')}</TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('key')}>{t('noKey')}</TextField>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={externalSourcesExpanded}
            onChange={handleExternalSourcesExpandedChange(externalSourcesExpanded)}
          >
            <AccordionSummary
              key="external-sources-summary"
              aria-controls={`${1}-content`}
              id={`${1}-header`}
              expanded={externalSourcesExpanded}
              heading={t('externalSources')}
            />
            <AccordionDetails key="external-sources-details" className={classes.AccordionDetails}>
              <H3>{t('supportedExternalSourceTypes')}</H3>
              <ExternalSourceTable
                sourceTypes={configuration?.externalSourceTypes || []}
                setSourceTypes={(sourceTypes: string[]) => {
                  const updateConfiguration = {
                    ...configuration,
                    externalSourceTypes: sourceTypes,
                  };
                  return setPartnerConfiguration(partnerID as string, updateConfiguration).then(
                    () => {
                      setConfiguration(updateConfiguration);
                    }
                  );
                }}
              />
              <br />
              <H3>{t('paymentExternalSourceTypes')}</H3>
              <ExternalSourceTable
                sourceTypes={configuration?.paymentExternalSourceTypes || []}
                setSourceTypes={(sourceTypes: string[]) => {
                  const updateConfiguration = {
                    ...configuration,
                    paymentExternalSourceTypes: sourceTypes,
                    allowedExternalSourceTypes: null,
                  };
                  return setPartnerConfiguration(partnerID as string, updateConfiguration).then(
                    () => {
                      setConfiguration(updateConfiguration);
                    }
                  );
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
  heading: {
    fontSize: '16px',
  },
  secondaryHeading: {
    fontSize: '16px',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  copyButton: {
    marginLeft: -5,
  },
  table: {
    minWidth: 400,
  },
  checkbox: {
    margin: -16,
    marginLeft: -11,
  },
  checkboxCell: {
    paddingRight: 0,
  },
}));

export default Configuration;
