import React, { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { useTranslate } from 'react-admin';

import { Grid } from '@material-ui/core';

import { TextInput } from '~/components';

import { createConsent, CreateOrUpdateConsentRequest, updateConsent } from '~/api/accountTransfer';
import { Amount } from '~/types';
import { HeadTransaction } from '~/types/HeadTransaction';
import { ConsentHolder, RunTransferActionFormProps } from './RunTransferActionTypes';

import { l } from './RunTransferAction';
import AmountInput from '~/components/AmountInput';
import { useGetAllAccounts } from '~/hooks/accounts';
import AutocompleteInput from '../AutocompleteInput';

type AccountTransferSession = {
  isSending: boolean;
  nextRequest?: CreateOrUpdateConsentRequest;
  lastConsent?: HeadTransaction;
};

const ACCOUNT_TRANSFER_SESSION: AccountTransferSession = {
  isSending: false,
};

const NO_CONSENT: ConsentHolder = {};

const AccountTransferForm = ({ resource, profileId, onConsent }: RunTransferActionFormProps) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [debitAccountNumber, setDebitAccountNumber] = useState<string | undefined>();
  const [creditAccountNumber, setCreditAccountNumber] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [amount, setAmount] = useState<Amount | null>();

  const { data: profileAccounts } = useGetAllAccounts({
    resource,
    profileId,
  });
  console.log(`profileAccounts:`, profileAccounts);
  const accounts = (profileAccounts || []).filter(
    (a) => a.type === 'REGULAR' && a.status === 'ACTIVE'
  );

  const debitAccount = debitAccountNumber
    ? accounts.find((a) => a.number === debitAccountNumber)
    : undefined;
  const creditAccount = creditAccountNumber
    ? accounts.find((a) => a.number === creditAccountNumber)
    : undefined;

  const debitOptions = creditAccount ? accounts.filter((a) => a.id !== creditAccount.id) : accounts;
  const creditOptions = debitAccount ? accounts.filter((a) => a.id !== debitAccount.id) : accounts;

  useEffect(() => {
    ACCOUNT_TRANSFER_SESSION.isSending = false;
    ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;
    ACCOUNT_TRANSFER_SESSION.lastConsent = undefined;
  }, []);

  // const [session, setSession] = useState<SettlementSession>({ isSending: false });

  const throttledMakeConsent = useCallback(
    throttle(
      (
        debitAccountNumber: string,
        creditAccountNumber: string,
        amount: Amount,
        comment?: string,
        reference?: string
      ): any => {
        console.log('update, amount:', amount);
        const request = {
          senderAccountNumber: debitAccountNumber,
          recipientAccountNumber: creditAccountNumber,
          amount: amount,
          comment: comment,
          referenceId: reference,
        };

        const sendNextRequest = (request: CreateOrUpdateConsentRequest) => {
          if (ACCOUNT_TRANSFER_SESSION.isSending) {
            ACCOUNT_TRANSFER_SESSION.nextRequest = request;
          } else {
            ACCOUNT_TRANSFER_SESSION.isSending = true;
            ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;

            if (ACCOUNT_TRANSFER_SESSION.lastConsent) {
              updateConsent(ACCOUNT_TRANSFER_SESSION.lastConsent.id, request)
                .then((response) => {
                  console.log(`updated consent: `, response.data);
                  ACCOUNT_TRANSFER_SESSION.isSending = false;
                  ACCOUNT_TRANSFER_SESSION.lastConsent = response.data;

                  const nextRequest = ACCOUNT_TRANSFER_SESSION.nextRequest;
                  if (nextRequest) {
                    ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ consents: [response.data], accounts });
                })
                .catch((e) => {
                  const errorCode = e?.body?.code;
                  console.log(`update consent failed: `, errorCode, e.body);
                  ACCOUNT_TRANSFER_SESSION.isSending = false;

                  const nextRequest = ACCOUNT_TRANSFER_SESSION.nextRequest;
                  if (nextRequest) {
                    ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ error: errorCode, accounts });
                });
            } else {
              createConsent(request)
                .then((response) => {
                  console.log(`created consent: `, response.data);
                  ACCOUNT_TRANSFER_SESSION.isSending = false;
                  ACCOUNT_TRANSFER_SESSION.lastConsent = response.data;

                  const nextRequest = ACCOUNT_TRANSFER_SESSION.nextRequest;
                  if (nextRequest) {
                    ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ consents: [response.data], accounts });
                })
                .catch((e) => {
                  const errorCode = e?.body.code;
                  console.log(`create consent failed: `, errorCode, e.body);
                  ACCOUNT_TRANSFER_SESSION.isSending = false;

                  const nextRequest = ACCOUNT_TRANSFER_SESSION.nextRequest;
                  if (nextRequest) {
                    ACCOUNT_TRANSFER_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ error: errorCode, accounts });
                });
            }
          }
        };

        console.log(`sending request`);
        sendNextRequest(request);
      },
      1000,
      {
        leading: true,
      }
    ),
    []
  );

  useEffect(() => {
    if (debitAccountNumber && creditAccountNumber && amount && comment) {
      onConsent(NO_CONSENT);
      throttledMakeConsent(debitAccountNumber, creditAccountNumber, amount, comment);
    } else {
      onConsent(undefined);
    }
  }, [onConsent, throttledMakeConsent, debitAccountNumber, creditAccountNumber, amount, comment]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          label={t('comment')}
          style={{ maxWidth: 490 }}
          multiline
          required
          onBlur={(e: any) => {
            setComment(e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <AutocompleteInput<string | undefined>
          options={debitOptions.map((a) => ({
            value: a.number,
            text: a.number,
          }))}
          label={t('debitAccountNumber')}
          value={debitAccountNumber}
          onChange={(e) => {
            setDebitAccountNumber(e.target.value);
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          style={{
            width: 240,
          }}
          allowEmpty
        />
      </Grid>
      <Grid item>
        <AutocompleteInput<string>
          options={creditOptions.map((a) => ({
            value: a.number,
            text: a.number,
          }))}
          label={t('creditAccountNumber')}
          value={creditAccountNumber}
          onChange={(e) => {
            setCreditAccountNumber(e.target.value);
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          style={{
            width: 240,
          }}
          allowEmpty
        />
      </Grid>
      <Grid item>
        <AmountInput
          onChange={(newAmount) => {
            if (newAmount?.value !== amount?.value || newAmount?.currency !== amount?.currency) {
              setAmount(newAmount);
            }
          }}
          label={t('amount')}
          disabled={!debitAccountNumber || !creditAccountNumber}
          currencyDisabled={!debitAccountNumber || !creditAccountNumber}
        />
      </Grid>
    </Grid>
  );
};

export default AccountTransferForm;
