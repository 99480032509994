import React from 'react';
import { useTranslate } from 'react-admin';
import { SelectInput } from 'react-admin';
import { useAllSolutions } from '~/hooks/useSolution';

export interface Props {
  source: string;
  label: string;
  className?: string;
  alwaysOn?: boolean;
  onChange?: (e: any) => void;
  validate?: () => void;
  allowEmpty?: boolean;
}

const SolutionSelect = ({
  source,
  label,
  className,
  alwaysOn,
  onChange,
  validate,
  allowEmpty,
}: Props) => {
  const solutions = useAllSolutions();
  const data = solutions?.data?.data || [];
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ra.fields.SolutionSelect.${key}`);
  const solutionsWithIndividual = data.filter((solution) =>
    solution.features.includes('veengu_individual_profile')
  );
  const choices = solutionsWithIndividual.map((solution) => {
    return {
      id: solution.id,
      name: solution.name,
    };
  });
  allowEmpty && choices.unshift({ id: '', name: t('any') });
  return (
    <SelectInput
      choices={choices}
      className={className}
      source={source}
      label={label}
      helperText={false}
      allowEmpty={false}
      defaultValue=''
      alwaysOn={alwaysOn}
      onChange={onChange}
      validate={validate}
    />
  );
};

export default SolutionSelect;
