import React from 'react';
import { Button as RaButton } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps as RaButtonProps } from 'ra-ui-materialui';
import cx from 'classnames';

export interface ButtonProps extends RaButtonProps {
  loading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { loading, disabled, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <RaButton
      disabled={disabled || loading}
      className={cx(className, { [classes.noIcon]: !children && !loading })}
      {...rest}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : children}
    </RaButton>
  );
};

const useStyles = makeStyles(() => ({
  noIcon: {
    '& > span ': {
      '& > span': {
        paddingLeft: 0,
      },
    },
  },
}));

export default Button;
