import React, { FC, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import cx from 'classnames';
import axios from '~/utils/axios';
import {
  IconButton,
  Theme,
  CircularProgress,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DoneIcon from '@material-ui/icons/CheckCircleTwoTone';
import FailIcon from '@material-ui/icons/HighlightOffTwoTone';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  getFileName,
  useBackgroundTasks,
  useCreateBackgroundTask,
  useCreateBackgroundTaskImport,
} from '~/hooks/useBackgroundTasks';
import { Button, FileField, FileInput, SimpleForm, SolutionSelect } from '~/components/ra';
import {
  BackgroundTaskStatus,
  CreateBackgroundTaskParams,
  ImportBackgroundTaskResult,
} from '~/api/backgroundTasks';
import { SaveButton, required } from 'react-admin';
import Toolbar from './ra/Toolbar';
import FileSaver from 'file-saver';
import onError from '~/errorsHandler';

export const useBackgroundTaskTranslate = () => {
  const translate = useTranslate();
  return (key: string, args?: any): string => translate(`components.BackgroundTask.${key}`, args);
};

export type BackgroundTaskDialogType = 'import' | 'export';

interface Props {
  type: CreateBackgroundTaskParams['type'];
  dialogType: BackgroundTaskDialogType;
  dialogTitle?: string;
  children: JSX.Element;
  queryArguments?: any;
}

const BackgroundTaskDialog: FC<Props> = (props) => {
  const { type, queryArguments = {}, dialogType } = props;

  const [isImportStart, setIsImportStart] = useState(dialogType === 'import');

  const t = useBackgroundTaskTranslate();

  const classes = useStyles();

  const createBackgroundTaskImport = useCreateBackgroundTaskImport({
    onError: (error: any) => onError(error),
  });

  const createBackgroundTaskExport = useCreateBackgroundTask({ onError: () => null });

  const {
    currentBackgroundTasks,
    currentBackgroundTaskRemove,
    backgroundTaskResultData,
  } = useBackgroundTasks({ type, queryArguments, dialogType, isEnabled: !isImportStart });

  const mutationStatus = createBackgroundTaskExport.isError && 'FAILED';
  const mutationErrorMessgage = (createBackgroundTaskExport.error as any)?.body?.message;
  const status = mutationStatus || currentBackgroundTasks?.status;
  const statusMessage = mutationErrorMessgage || currentBackgroundTasks?.statusMessage;

  useEffect(() => {
    currentBackgroundTaskRemove();
    if (dialogType === 'export') {
      try {
        createBackgroundTaskExport.mutateAsync({
          type,
          arguments: queryArguments,
        });
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async (formData: any) => {
    await createBackgroundTaskImport.mutateAsync({
      type: type,
      formData: formData,
      queryArguments: queryArguments,
    });
    setIsImportStart(false);
  };
  return (
    <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
      {isImportStart ? (
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton onSave={handleSave} />
            </Toolbar>
          }
          saving={createBackgroundTaskImport.isLoading}
        >
          <SolutionSelect
            validate={required()}
            label={t('solutionId')}
            source='solution'
            allowEmpty={false}
          />
          <FileInput
            accept='text/csv'
            label={t('file')}
            validate={required()}
            source='file'
            className={classes.fileInput}
          >
            <FileField ellipsis={false} />
          </FileInput>
        </SimpleForm>
      ) : (
        <ProgressContent
          status={status}
          statusMessage={statusMessage}
          dialogType={dialogType}
          backgroundTaskResultData={backgroundTaskResultData}
          type={type}
        />
      )}
    </Grid>
  );
};

const BackgroundTask: FC<Props> = (props) => {
  const { children, dialogTitle } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleExportClick = () => {
    handleOpen();
  };

  const classes = useStyles();

  return (
    <>
      {React.cloneElement(children, { onClick: handleExportClick })}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='demo-alert-dialog-title'
        aria-describedby='demo-alert-dialog-description'
      >
        <DialogTitle id='demo-alert-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <BackgroundTaskDialog {...props} />
        </DialogContent>
        <DialogActions>
          <Button label='ra.action.close' onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ProgressContentProps {
  status?: BackgroundTaskStatus;
  statusMessage?: string;
  dialogType: BackgroundTaskDialogType;
  backgroundTaskResultData?: ImportBackgroundTaskResult;
  type: CreateBackgroundTaskParams['type'];
}

const ProgressContent: FC<ProgressContentProps> = (props) => {
  const { status, statusMessage, dialogType, backgroundTaskResultData, type } = props;
  const classes = useProgressContentStyles();
  const t = useBackgroundTaskTranslate();

  const download = () => {
    if (backgroundTaskResultData && backgroundTaskResultData.resultFile) {
      const fileName = getFileName({ type });
      axios
        .get(`/files/${backgroundTaskResultData.resultFile}`, { responseType: 'blob' })
        .then((response) => {
          FileSaver.saveAs(response.data, fileName);
        })
        .catch(() => null);
    }
  };

  return (
    <div className={classes.root}>
      {status === 'DONE' ? (
        <DoneIcon className={cx(classes.icon, classes.DONE)} />
      ) : status === 'FAILED' ? (
        <FailIcon className={cx(classes.icon, classes.FAILED)} />
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress color='primary' />
        </div>
      )}
      <Typography variant='h5' className={classes.text}>
        {status ? t(status) : t('loading')}
      </Typography>
      {statusMessage ||
        (status === 'DONE' ? (
          dialogType === 'import' ? (
            <>
              {backgroundTaskResultData && (
                <Typography variant='subtitle1' align='center'>
                  {t('recordsAndErrors', {
                    records: backgroundTaskResultData.records,
                    errors: backgroundTaskResultData.errors,
                  })}
                </Typography>
              )}
              <Typography variant='subtitle1' align='center'>
                {t('downloadTheResult')}
              </Typography>
              <IconButton
                id='downloadButton'
                onClick={download}
                disabled={!backgroundTaskResultData}
              >
                <GetAppIcon />
              </IconButton>
            </>
          ) : (
            <Typography variant='subtitle1' align='center'>
              {t('downloadWillStart')}
            </Typography>
          )
        ) : (
          ''
        ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 250,
    minHeight: 120,
  },
  fileInput: {
    width: 256,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    '& .previews': {
      '& > div > button': {
        display: 'none',
      },
    },
  },
}));

const useProgressContentStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '64px',
  },
  loadingContainer: {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1) / 2,
  },
  DONE: {
    color: theme.palette.success.main,
  },
  FAILED: {
    color: theme.palette.error.main,
  },
}));

export default BackgroundTask;
