export default {
  name: 'Event |||| Events',
  subtitle: 'Business events log',
  list: {
    filters: {
      timestampFrom: 'Date & time from',
      timestampTo: 'Date & time to',
      topic: 'Topic',
      actor: 'Actor (ID)',
      object: 'Object (ID)',
      tags: 'Tags',
      status: 'Processing status',
      tagsHelperText: "To add tag input it's name and press Enter",
      processingStatuses: 'Processing statuses',
      taskType: 'Event task type',
      taskState: 'Processing status',
    },
    empty: 'No events found',
    timestamp: 'Timestamp',
    topic: 'Topic and description',
    actor: 'Actor',
    object: 'Object',
    tags: 'Tags',
    processingStatuses: 'Processing statuses',
    individual: 'Individual',
    tenant: 'Tenant',
    business: 'Business',
    identity: 'Identity',
    dashboardIdentity: 'Dashboard user',
    employee: 'Employee',
    terminal: 'Terminal',
    externalSource: 'External Source',
    card: 'Card',
    transaction: 'Transaction',
    lastEvents: 'Last events',
    viewAllEvents: 'View all events',
    partner: 'Partner',
    externalProfileLink: 'External Profile Link',
    account: 'Account',
    location: 'Location',
    integrationIdentity: 'Integration User',
    beneficiary: 'Beneficiary',
    beneficiaryAccount: 'Beneficiary Account',
    solution: 'Solution',
    product: 'Product',
    status: 'Status',
  },
  show: {
    tabs: {
      eventData: 'Event Data',
    },
    actors: 'Actors',
    objects: 'Objects',
    references: 'References',
    contextData: 'Context data',
    processingData: 'Processing data',
    type: 'Type',
    name: 'Name',
    linkType: 'Link type',
    objectType: 'Object type',
    id: 'ID',
    topic: 'Topic',
    eventDate: 'eventDate',
    timestamp: 'Event date',
    tags: 'Tags',
    processingStatuses: 'Processing statuses',
    INDIVIDUAL: 'Individual',
    TENANT: 'Tenant',
    BUSINESS: 'Business',
    API_IDENTITY: 'Identity',
    DASHBOARD_IDENTITY: 'Officer',
    EMPLOYEE: 'Employee',
    TERMINAL: 'Terminal',
    EXTERNAL_SOURCE: 'External source',
    CARD: 'Card',
    HEAD_TRANSACTION: 'Head Transaction',
    PARTNER: 'Partner',
    EXTERNAL_PROFILE_LINK: 'Profile link',
    ACCOUNT: 'Account',
    LOCATION: 'Location',
    INTEGRATION_IDENTITY: 'Identity',
    BENEFICIARY: 'Beneficiary',
    BENEFICIARY_ACCOUNT: 'Beneficiary account',
    SOLUTION: 'Solution',
    PRODUCT: 'Product',
    DEAL: 'Deal',
    MANUAL_CASE: 'User Task (BPM)',
    PROCESS_INSTANCE: 'Process (BPM)',
    TRANSACTION_TASK: 'Transaction task',
    INDIVIDUAL_INVITATION: 'Individual invitation',
    EVENT: 'Event',
  },
};
