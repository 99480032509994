import { createAction, handleActions } from 'redux-actions';

import axios from '~/utils/axios';
import { getFeaturesRequest } from '~/api/solutions';

const HANDBOOK_LOAD_BEGIN = 'handbook/load/begin';
const HANDBOOK_LOAD_END = 'handbook/load/end';
const HANDBOOK_UPDATE = 'handbook/update';

const CONFIGURATION_LOAD_BEGIN = 'configuration/load/begin';
const CONFIGURATION_LOAD_END = 'configuration/load/end';
const CONFIGURATION_UPDATE = 'configuration/update';

const SOLUTION_LOAD_BEGIN = 'solution/load/begin';
const SOLUTION_LOAD_END = 'solution/load/end';
const SOLUTION_UPDATE = 'solution/update';

const FEATURES_LOAD_BEDIG = 'features/load/begin';
const FEATURES_LOAD_SUCCESS = 'features/load/success';
const FEATURES_LOAD_FAIL = 'features/load/fail';

const handbookLoadBegin = createAction(HANDBOOK_LOAD_BEGIN);
const handbookLoadEnd = createAction(HANDBOOK_LOAD_END);
export const handbookUpdate = createAction(HANDBOOK_UPDATE);

const configurationLoadBegin = createAction(CONFIGURATION_LOAD_BEGIN);
const configurationLoadEnd = createAction(CONFIGURATION_LOAD_END);
export const configurationUpdate = createAction(CONFIGURATION_UPDATE);

const solutionLoadBegin = createAction(SOLUTION_LOAD_BEGIN);
const solutionLoadEnd = createAction(SOLUTION_LOAD_END);
export const solutionUpdate = createAction(SOLUTION_UPDATE);

const featuresLoadBegin = createAction(FEATURES_LOAD_BEDIG);
const featuresLoadSuccess = createAction(FEATURES_LOAD_SUCCESS);
const featuresLoadFail = createAction(FEATURES_LOAD_FAIL);

export const getFeatures = () => {
  return async (dispatch, getState) => {
    if (getState().solutions.features.data.length === 0) {
      dispatch(featuresLoadBegin());
      try {
        const { data } = await getFeaturesRequest();
        dispatch(featuresLoadSuccess(data));
      } catch (error) {
        dispatch(featuresLoadFail(error));
      }
    }
  };
};

export const loadHandbook = (solutionId) => {
  return (dispatch, getState) => {
    const state = getState();
    let solution = state.solutions.byId[solutionId];
    if (!solution || !solution.handbook) {
      dispatch(handbookLoadBegin({ solutionId }));
      return axios
        .get(`/solutions/${solutionId}/handbook`)
        .then((response) => {
          return dispatch(
            handbookLoadEnd({
              solutionId,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
};

export const loadConfiguration = (solutionId) => {
  return (dispatch, getState) => {
    const state = getState();
    let solution = state.solutions.byId[solutionId];
    if (!solution || !solution.configuration) {
      dispatch(configurationLoadBegin({ solutionId }));
      return axios
        .get(`/solutions/${solutionId}/configuration`)
        .then((response) => {
          return dispatch(
            configurationLoadEnd({
              solutionId,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
};

export const loadSolution = (solutionId) => {
  return (dispatch, getState) => {
    const state = getState();
    let solution = state.solutions.byId[solutionId];
    if (!solution || !solution.solution) {
      dispatch(solutionLoadBegin({ solutionId }));
      return axios
        .get(`/solutions/${solutionId}`)
        .then((response) => {
          return dispatch(
            solutionLoadEnd({
              solutionId,
              data: response.data,
            })
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
};

const initialState = {
  byId: {},
  features: {
    data: [],
    loading: false,
    error: null,
  },
};

export default handleActions(
  {
    [HANDBOOK_LOAD_BEGIN]: (state, { payload }) => {
      const { solutionId } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        handbook: {
          loading: true,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [HANDBOOK_LOAD_END]: (state, { payload }) => {
      const { solutionId, data, error } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        handbook: {
          loading: false,
          data,
          error,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [HANDBOOK_UPDATE]: (state, { payload }) => {
      const { solutionId, data } = payload;
      const actualSolution = state.byId[solutionId] || {};

      const newSolution = {
        ...actualSolution,
        handbook: {
          ...actualSolution.handbook,
          data,
        },
      };

      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [CONFIGURATION_LOAD_BEGIN]: (state, { payload }) => {
      const { solutionId } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        configuration: {
          loading: true,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [CONFIGURATION_LOAD_END]: (state, { payload }) => {
      const { solutionId, data, error } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        configuration: {
          loading: false,
          data,
          error,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [CONFIGURATION_UPDATE]: (state, { payload }) => {
      const { solutionId, data } = payload;
      const actualSolution = state.byId[solutionId] || {};

      const newSolution = {
        ...actualSolution,
        configuration: {
          ...actualSolution.configuration,
          data,
        },
      };

      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [SOLUTION_LOAD_BEGIN]: (state, { payload }) => {
      const { solutionId } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        solution: {
          loading: true,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [SOLUTION_LOAD_END]: (state, { payload }) => {
      const { solutionId, data, error } = payload;
      const newSolution = {
        ...(state.byId[solutionId] || {}),
        solution: {
          loading: false,
          data,
          error,
        },
      };
      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [SOLUTION_UPDATE]: (state, { payload }) => {
      const { solutionId, data } = payload;
      const actualSolution = state.byId[solutionId] || {};

      const newSolution = {
        ...actualSolution,
        solution: {
          ...actualSolution.solution,
          data,
        },
      };

      const newById = { ...state.byId, [solutionId]: newSolution };
      return { ...state, byId: newById };
    },
    [FEATURES_LOAD_BEDIG]: (state) => {
      return { ...state, features: { ...state.features, loading: true } };
    },
    [FEATURES_LOAD_SUCCESS]: (state, { payload }) => {
      return { ...state, features: { ...state.features, data: payload, loading: false } };
    },
    [FEATURES_LOAD_FAIL]: (state, { payload }) => {
      return { ...state, features: { ...state.features, loading: false, error: payload } };
    },
  },
  initialState
);

export const selectors = {
  getHandbookForSolution: (solutionId) => (state) => {
    return (state.solutions.byId[solutionId] || {}).handbook;
  },
  getConfigurationForSolution: (solutionId) => (state) => {
    return (state.solutions.byId[solutionId] || {}).configuration;
  },
  getFeaturesSeletor: (state) => state.solutions.features,
  getFullHandbook: (state) => Object.values(state.solutions.byId).map((item) => item.handbook),
  getFullHandbookWithSolutionId: (state) =>
    Object.entries(state.solutions.byId).map((item) => {
      const solutionId = item[0];
      const handbook = item[1].handbook;
      return { ...handbook, solutionId: solutionId };
    }),
  getSolution: (solutionId) => (state) => {
    return (state.solutions.byId[solutionId] || {}).solution;
  },
};
