import React, { Fragment, useState } from 'react';
import { useTranslate, TextInput, required, SelectInput, FormDataConsumer } from 'react-admin';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getExternalSourceTypes } from '~/api/externalSources';

import { DateInput, HiddenInput, KycLevelInput, ReferenceInput } from '~/components/ra';
import { selectors } from '~/ducks/context';
import { Pricing } from '~/types';
import { Alert } from '@material-ui/lab';

interface Props {
  pricingRecord?: Pricing;
  disabled?: boolean;
  edit?: boolean;
  format?: string;
  create?: boolean;
}

const PricingForm = (props: Props) => {
  const { pricingRecord, disabled = false, edit = false, format, create } = props;

  const initialValue = create ? '' : pricingRecord?.productId ? 'product' : 'externalSourceType';

  const [instrument, setInstrument] = useState(initialValue);

  const handleInstrumentChange = (e: any) => {
    setInstrument(e.target.value);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.pricings.${key}`);

  const solutionId = useSelector(selectors.get('solutionId'));

  const { data: externalSourceTypesData, isLoading } = useQuery(['externalSourceTypes'], () =>
    getExternalSourceTypes().then((res) => res.data)
  );
  const externalSourceTypes = isLoading
    ? []
    : externalSourceTypesData?.map((type) => ({ id: type, name: type }));
  return (
    <FormDataConsumer>
      {() => {
        return (
          <Fragment>
            {!edit && <HiddenInput source='solutionId' disabled />}
            <HiddenInput source='data' disabled />
            {pricingRecord?.format === 'v2' || format === 'v2' ? (
              <>
                <HiddenInput source='format' defaultValue='v2' disabled />
                <TextInput
                  source='name'
                  label={t('name')}
                  fullWidth
                  disabled={disabled}
                  validate={required()}
                />
                <SelectInput
                  initialValue={initialValue}
                  source='paymentInstrument'
                  label={t('paymentInstrument')}
                  onChange={handleInstrumentChange}
                  choices={[
                    { id: 'product', name: t('product') },
                    { id: 'externalSourceType', name: t('externalSourceType') },
                  ]}
                  fullWidth
                  validate={required()}
                />
                {instrument === 'product' && (
                  <ReferenceInput
                    label={t('product')}
                    source='productId'
                    reference='products'
                    filter={{ solutionId }}
                  >
                    <SelectInput optionText='name' validate={required()} />
                  </ReferenceInput>
                )}
                {instrument === 'externalSourceType' && (
                  <SelectInput
                    source='externalSourceType'
                    label={t('externalSourceType')}
                    choices={externalSourceTypes}
                    fullWidth
                    validate={required()}
                  />
                )}
                <KycLevelInput
                  source='kycLevel'
                  label={t('profileKycLevel')}
                  fullWidth
                  disabled={disabled}
                  allowEmpty
                />
                <SelectInput
                  source='accountStatus'
                  label={t('profileStatus')}
                  choices={[
                    { id: 'ACTIVE', name: t('Active') },
                    { id: 'BLOCKED', name: t('Blocked') },
                    { id: 'CLOSED', name: t('Closed') },
                  ]}
                  fullWidth
                  disabled={disabled}
                  allowEmpty
                  emptyText={t('any')}
                />
                {/*<AutocompleteArrayTextInput source="tags" label={t('profileTags')} record={formData} />*/}
                <DateInput
                  source='activeFrom'
                  label={t('validityFrom')}
                  options={{ format: 'LL', clearable: true }}
                  fullWidth
                  disabled={disabled}
                />
                <DateInput
                  source='activeTill'
                  label={t('validityTo')}
                  options={{ format: 'LL', clearable: true }}
                  fullWidth
                  disabled={disabled}
                />
              </>
            ) : (
              <>
                <Alert severity='warning'>{t('editOldWarning')}</Alert>
                <br />
                <TextInput
                  source='name'
                  label={t('name')}
                  fullWidth
                  disabled={disabled}
                  validate={required()}
                />
                <ReferenceInput source='productId' reference='products' filter={{ solutionId }}>
                  <SelectInput optionText='name' validate={required()} />
                </ReferenceInput>
                <KycLevelInput
                  source='kycLevel'
                  label={t('kycLevel')}
                  fullWidth
                  disabled={disabled}
                  allowEmpty
                />
                <SelectInput
                  source='accountStatus'
                  label={t('accountStatus')}
                  choices={[
                    { id: 'ACTIVE', name: t('Active') },
                    { id: 'BLOCKED', name: t('Blocked') },
                    { id: 'CLOSED', name: t('Closed') },
                  ]}
                  fullWidth
                  disabled={disabled}
                  allowEmpty
                  emptyText={t('any')}
                />
                {/*<AutocompleteArrayTextInput source="tags" label={t('tags')} record={formData} />*/}
                <DateInput
                  source='activeFrom'
                  label={t('activeFrom')}
                  options={{ format: 'LL', clearable: true }}
                  fullWidth
                  disabled={disabled}
                />
                <DateInput
                  source='activeTill'
                  label={t('activeTill')}
                  options={{ format: 'LL', clearable: true }}
                  fullWidth
                  disabled={disabled}
                />
              </>
            )}
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default PricingForm;
