import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const ajv = new Ajv();
addFormats(ajv);
ajv.addFormat('country', true);
ajv.addFormat('solution-country', true);
ajv.addFormat('document', true);

ajv.addKeyword({
  keyword: 'enumNames',
  type: 'string',
  schemaType: 'array',
});

ajv.addKeyword({
  keyword: 'disabled',
  schemaType: 'boolean',
});

export default ajv;
