class NumberEx {
  static positive = (value: string | number) => {
    if (value === '') return value;
    const numberValue = parseFloat(value.toString());
    return numberValue >= 0 ? numberValue : 0;
  };
  static round = (value: number, pre = 6): number => Math.ceil(value * 10 ** pre) / 10 ** pre;
}

export default NumberEx;
