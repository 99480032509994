import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { H3 } from '~/components';
import InfoIcon from '@material-ui/icons/Info';
import { ChipField } from '~/components';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { useFeatures } from '~/hooks';

import { AsideBase } from '~/layout';
import { useProductTemplates } from '~/hooks/useProductTemplates';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(9),
    marginLeft: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const Aside = () => {
  const { data, loading, error } = useFeatures();
  const { data: productTemplates } = useProductTemplates();

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.Aside.${key}`);

  const classes = useStyles();

  return (
    <AsideBase className={classes.root}>
      <Grid container>
        <H3 icon={<InfoIcon />} loading={loading} noPaddingLeft topSpacing={0}>
          {t('availableFeatures')}
        </H3>
        <div className={classes.container}>
          {!loading
            ? data?.map((item, index) => {
                return <ChipField key={index} label={item.name} />;
              })
            : new Array(8)
                .fill(0)
                .map((item, index) => <ChipField key={index} loading={loading} />)}
        </div>
        {error?.message && <div>{error.message}</div>}
        <H3 icon={<LocalOfferIcon />} loading={loading} noPaddingLeft topSpacing={2}>
          {t('availableProductTemplates')}
        </H3>
        <div className={classes.container}>
          {productTemplates
            ? productTemplates?.map((item, index) => {
                return <ChipField key={index} label={item.alias} />;
              })
            : new Array(3)
                .fill(0)
                .map((item, index) => <ChipField key={index} loading={loading} />)}
        </div>
      </Grid>
    </AsideBase>
  );
};

export default Aside;
