import { useQuery } from 'react-query';
import { AccountNumbering, getAccountNumberingList } from '~/api/utils';

interface UseAccountNumberingListProps {
  enabled?: boolean;
  onSuccess?: (res: AccountNumbering[]) => void;
  onError?: (err?: any) => void;
}

export const useAccountNumberingList = (props?: UseAccountNumberingListProps) => {
  const { enabled, onSuccess, onError } = props || {};
  return useQuery('accountNumberingList', getAccountNumberingList, {
    staleTime: Infinity,
    enabled,
    onSuccess(res) {
      onSuccess?.(res);
    },
    onError(err) {
      onError?.(err);
    },
  });
};
