export default {
  name: 'Payment Group |||| Payment Groups',
  subtitle: 'All payment groups',
  tabs: {
    details: 'Details',
    references: 'Services',
  },
  list: {
    id: 'ID',
    name: 'Name',
    parentId: 'Parent group',
    backToServices: 'Go back to services',
  },
  create: {
    title: 'Create new group',
    subtitle: 'Input group details',
  },
  fields: {
    id: 'ID',
    name: 'Name',
    parentId: 'Parent group',
    parentMustBeDifferent: 'Group must not be a parent to itself',
  },
  show: {
    recordTitle: 'Group',
    detailsHeading: 'Group details',
    referencesHeading: 'List of services',
  },
  edit: {
    title: 'Edit group',
    subtitle: 'Edit fields and press Save to continue',
  },
  delete: {
    attention: 'Delete group',
    alert: 'Are you sure you want to delete this group? This action is irreversable!',
    success: 'Group has been deleted successfully!',
  },
  references: {
    service: 'Service references',
    addReference: 'Add service',
    addReferenceDialogTitle: 'Add service',
    addReferenceLabel: 'Service to add',
    confirm: 'Confirm',
    deleteReference: 'Delete reference',
    empty: 'No services added to this group for a while.',
    createSuccess: 'Service has been added to this group successfully!',
    createFailure: 'Failed to add service to this group.',
    deleteSuccess: 'Service has been removed from this group successfully!',
    deleteFailure: 'Failed to remove service from this group.',
  },
};
