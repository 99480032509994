import React from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(() => ({
  MenuPaper: {},
}));

type Option<Value> = {
  value: Value;
  text: React.ReactNode;
};

export interface SelectInputProps<Value> {
  options: Option<Value>[];
  label: string;
  name?: string;
  value: Value | Value[];
  onChange?: (e: any) => void;
  className?: string;
  allowEmpty?: boolean;
  emptyText?: string;
  multiple?: boolean;
  [x: string]: any;
}

function SelectInput<Value>(props: SelectInputProps<Value>) {
  const {
    options,
    label,
    name,
    value,
    onChange,
    className = '',
    allowEmpty,
    emptyText,
    multiple,
    ...rest
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.SelectInput.${key}`);

  return (
    <FormControl className={cx({ [className]: className })}>
      <InputLabel variant='filled' id={'labelId' + name}>
        {label}
      </InputLabel>
      <Select
        labelId={'labelId' + name}
        name={name}
        value={value || emptyValue}
        label={label}
        onChange={onChange}
        multiple={multiple}
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
          classes: {
            paper: classes.MenuPaper,
          },
        }}
        {...rest}
      >
        {allowEmpty && (
          <MenuItem key={'-1'} value={emptyValue}>
            {emptyText || t('any')}
          </MenuItem>
        )}
        {options.map((option, i) => {
          const { value, text } = option;
          return (
            <MenuItem key={i} value={value as any}>
              {text || value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const emptyValue = '';

export default SelectInput;
