import operationType from '../operationType';
import transactionStatus from '../transactionStatus';

export default {
  postedAmount: 'Posted amount',
  baseAmount: 'Base amount',
  type: 'Type',
  operationType: 'Operation type',
  details: 'Details',
  account: 'Account',
  datetime: 'Date & time',
  external: 'External',
  cellComponents: {
    OperationTypeField: {
      _: 'Type',
      operationType: 'Operation type',
      any: 'Any',
      ...operationType,
    },
    RowMenu: {
      COPY_UUID: 'Copy UUID',
      GO_TO_COUNTERPARTY: 'Go to Counterparty',
      copied: 'UUID copied to clipboard',
    },
  },
  Row: {
    thereAreNoTransactions: 'There are no transactions matching the specified filters.',
  },
  Filters: {
    KeyboardDateTimePicker: {
      from: 'From',
      to: 'To',
    },
    SelectAccount: {
      accounts: 'Accounts',
      any: 'Any',
    },
    status: {
      _: 'Status',
      any: 'Any',
      ...transactionStatus,
    },
    TextField: {
      search: 'Search',
    },
    SelectSettled: {
      any: 'Any',
      true: 'Only settled',
      false: 'Only not settled',
    },
  },
};
