import React from 'react';
import { useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import TrueIcon from '@material-ui/icons/Done';
import { Longdash, hasTranslation } from '~/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  labeledField: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: 2,
      fontSize: 10,
    },
  },
  content: {
    height: 19,
    '& > svg': {
      height: 19,
    },
  },
}));

export interface Props {
  label?: string;
  value: boolean;
  nullOnFalse?: boolean;
  [x: string]: any;
}

const BooleanField = ({ label, value, nullOnFalse, addLabel = true }: Props) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.labeledField}>
        {addLabel && label && <label>{hasTranslation(label) ? translate(label) : label}</label>}
        <div className={classes.content}>
          {value === true ? <TrueIcon /> : nullOnFalse ? null : Longdash}
        </div>
      </div>
    </div>
  );
};

export default BooleanField;
