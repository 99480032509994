import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AsideBase } from '~/layout';
import { ChipField, H2, H3, LinkToAccount, AffiliatedWith, AccountOwner } from '~/components';
import { useGetAccountLimits, useGetAllAccounts } from '~/hooks/accounts';
import { isEmpty, sortBy } from 'lodash';
import { Account, Limit } from '~/types';
import { amountToString } from '~/utils/amountFormat';
import MetricProgressField from '~/components/MetricField/MetricProgressField';
import MetricSingleValueField from '~/components/MetricField/MetricSingleValueField';
import ViewOwnLimits from './ViewOwnLimits';
import AccountCreate from '~/components/ra/Accounts/AccountCreate';
import palette from '~/theme/palette';
import { Skeleton } from '@material-ui/lab';

export const useTranslateProfileLimits = () => {
  const translate = useTranslate();
  return (key: string): string => translate(`components.ProfileLimits.${key}`);
};

interface Props {
  profileId?: string;
  resource: 'businesses' | 'individuals' | 'partners';
  solutionId?: string;
  permissions: string[];
  changelogMode?: boolean;
}

const ProfileLimits: FC<Props> = (props) => {
  const { profileId, resource, solutionId, permissions, changelogMode } = props;
  const { data: accounts, isLoading, refetch: refetchAccounts } = useGetAllAccounts({
    resource: resource,
    profileId: profileId,
  });
  const t = useTranslateProfileLimits();
  const classes = useStyles();

  let hasPermissionToAddAccount = false;
  if (resource === 'businesses') {
    hasPermissionToAddAccount = permissions.includes('business.account.create');
  } else if (resource === 'individuals') {
    hasPermissionToAddAccount = permissions.includes('individual.account.create');
  } else if (resource === 'partners') {
    hasPermissionToAddAccount = false; // permissions.includes('partner.account.create');
  }

  const cnlAccounts = accounts?.filter(
    ({ productTemplate, status }) =>
      productTemplate.category === 'CNL_ACCOUNT' && status === 'ACTIVE'
  );
  const ownAccounts = cnlAccounts?.filter((item) => item.profileId === profileId);
  const sharedAccounts = cnlAccounts?.filter((item) => item.profileId !== profileId);
  return (
    <div className={changelogMode ? classes.changelogMode : undefined}>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={1} className={classes.h2}>
          {t('_')}
        </H2>
        <div id='section-actions'>
          {hasPermissionToAddAccount && (
            <AccountCreate
              refetch={() => {
                refetchAccounts();
              }}
              resource={resource}
              solutionId={solutionId ?? ''}
              profileId={profileId ?? ''}
              switchToLimits
            />
          )}
          {accounts?.some(({ productTemplate }) => productTemplate.category === 'CNL_ACCOUNT') && (
            <ViewOwnLimits profileId={profileId} permissions={permissions} resource={resource} />
          )}
        </div>
      </header>
      {!isLoading && isEmpty(cnlAccounts) ? (
        <div>{t('empty')}</div>
      ) : isLoading ? (
        <Skeleton height='32px' />
      ) : (
        <>
          {ownAccounts?.map((account, index) => (
            <ProfileLimitsAside key={index} account={account} profileId={profileId} />
          ))}
          {sharedAccounts?.map((account, index) => (
            <ProfileLimitsAside key={index} account={account} profileId={profileId} />
          ))}
        </>
      )}
    </div>
  );
};

interface ProfileLimitsAsideProps {
  account: Account;
  profileId?: string;
}

export const ProfileLimitsAside: FC<ProfileLimitsAsideProps> = (props) => {
  const { account, profileId } = props;
  const { data: limits, isLoading } = useGetAccountLimits(account.id);

  if (isLoading)
    return (
      <Grid container direction='column' spacing={4}>
        <Grid item xs={12}>
          <AsideBase>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Skeleton />
                {[1, 2, 3].map((_i) => (
                  <Grid item xs={12}>
                    <MetricSingleValueField loading />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </AsideBase>
        </Grid>
      </Grid>
    );
  if (!limits) return null;
  return (
    <Grid container direction='column' spacing={4} wrap='nowrap'>
      <Grid item xs={12}>
        <ProfileLimitPanel limits={limits} account={account} profileId={profileId} />
      </Grid>
    </Grid>
  );
};

interface ProfileLimitPanelProps {
  limits: Limit[];
  account?: Account;
  width?: number;
  profileId?: string;
}

export const ProfileLimitPanel: FC<ProfileLimitPanelProps> = (props) => {
  const { limits, account, width, profileId } = props;
  const t = useTranslateProfileLimits();
  return (
    <AsideBase width={width}>
      <Grid container direction='column' spacing={2} wrap='nowrap'>
        {account && (
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <H3 topSpacing={0} bottomSpacing={0} style={{ marginRight: '8px' }}>
                {account.alias || ''}
              </H3>
              {account.profileId !== profileId && (
                <ChipField style={{ margin: '0' }} label={t('shared')} />
              )}
            </Grid>
          </Grid>
        )}
        {sortBy(limits, (d) => d.type)
          .reverse()
          .map((item) => {
            const limitName = item.name;
            switch (item.type) {
              case 'VELOCITY_CONTROL': {
                return (
                  <VelocityControlGridItems
                    id={item.id}
                    key={item.id}
                    name={item.name}
                    velocityControl={item.velocityControl}
                  />
                );
              }
              case 'SINGLE_AMOUNT': {
                return (
                  <Grid item xs={12} key={item.id}>
                    <MetricSingleValueField
                      name={limitName}
                      value={amountToString(item.singleAmount!)}
                    />
                  </Grid>
                );
              }
              default:
                return (
                  <Grid item xs={12} key={item.id}>
                    {limitName}
                  </Grid>
                );
            }
          })}
        {account && account.profileId !== profileId && (
          <Grid item xs={12}>
            <AccountOwner
              profileId={account.profileId}
              profileType={account.profileType}
              topSpacing={0}
              bottomSpacing={1}
            />
          </Grid>
        )}
        {account && (
          <Grid item xs={12}>
            <AffiliatedWith references={account.references} type='SHARED_WITH' topSpacing={0} />
          </Grid>
        )}
        {account && (
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <LinkToAccount id={account.id} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </AsideBase>
  );
};

interface VelocityControlGridItemsProps {
  id: string;
  name: string;
  velocityControl: Limit['velocityControl'];
  inline?: boolean;
}

export const VelocityControlGridItems = (props: VelocityControlGridItemsProps) => {
  const { id, name, velocityControl, inline } = props;
  const {
    amountRange,
    countRange,
    amountOutOfInfinity,
    countOutOfInfinity,
  } = getVelocityControlVariants(velocityControl);
  const result: JSX.Element[] = [];
  if (amountRange) {
    const { progressProcent, currentText, limitText } = amountRange;
    result.push(
      <Grid item key={`${id}-amount`}>
        <MetricProgressField
          name={name}
          progressPercent={progressProcent!}
          value={currentText}
          target={limitText}
          inline={inline}
        />
      </Grid>
    );
  }
  if (countRange) {
    const { progressProcent, currentText, limitText } = countRange;
    result.push(
      <Grid item key={`${id}-count`}>
        <MetricProgressField
          name={`${name}, times`}
          progressPercent={progressProcent!}
          value={currentText}
          target={limitText}
          inline={inline}
        />
      </Grid>
    );
  }
  if (amountOutOfInfinity) {
    const { progressProcent, currentText, limitText } = amountOutOfInfinity;
    result.push(
      <Grid item key={`${id}-amount-infinity`}>
        <MetricProgressField
          name={name}
          progressPercent={progressProcent}
          value={currentText}
          target={limitText}
          inline={inline}
        />
      </Grid>
    );
  }
  if (countOutOfInfinity) {
    const { progressProcent, currentText, limitText } = countOutOfInfinity;
    result.push(
      <Grid item key={`${id}-count-infinity`}>
        <MetricProgressField
          name={`${name}, times`}
          progressPercent={progressProcent}
          value={currentText}
          target={limitText}
          inline={inline}
        />
      </Grid>
    );
  }
  return <>{result}</>;
};

const getVelocityControlVariants = (velocityControl: Limit['velocityControl']) => {
  const currentAmount = velocityControl?.window.amount;
  const limitAmount = velocityControl?.amount;
  const currentCount = velocityControl?.window.count;
  const limitCount = velocityControl?.count;

  const variants: Record<
    'amountRange' | 'countRange' | 'amountOutOfInfinity' | 'countOutOfInfinity',
    { progressProcent: number; currentText: string; limitText: string } | null
  > = {
    amountRange: null, // [current, limit]
    countRange: null, // [current, limit]
    amountOutOfInfinity: null,
    countOutOfInfinity: null, // [current, Infinity]
  };

  if (currentAmount && limitAmount) {
    variants.amountRange = {
      progressProcent: getProgressProcent(currentAmount.value, limitAmount.value),
      currentText: amountToString(currentAmount),
      limitText: amountToString(limitAmount),
    };
  }
  if (currentCount !== undefined && limitCount !== undefined) {
    variants.countRange = {
      progressProcent: getProgressProcent(currentCount, limitCount),
      currentText: currentCount.toString(),
      limitText: limitCount.toString(),
    };
  }
  if (currentAmount !== undefined && limitAmount === undefined) {
    variants.amountOutOfInfinity = {
      progressProcent: Infinity,
      currentText: amountToString(currentAmount),
      limitText: '∞',
    };
  }
  if (currentCount !== undefined && limitCount === undefined) {
    variants.countOutOfInfinity = {
      progressProcent: Infinity,
      currentText: currentCount.toString(),
      limitText: '∞',
    };
  }

  return variants;
};

const getProgressProcent = (currentValue: number, limitValue: number) => {
  const current = Math.ceil(currentValue);
  const limit = Math.ceil(limitValue);
  return Math.ceil((current / limit) * 100);
};

const useStyles = makeStyles(() => ({
  h2: {
    lineHeight: '30px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  },
}));

export default ProfileLimits;
