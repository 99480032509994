import axios from '~/utils/axios';
import { HeadTransaction } from '~/types/HeadTransaction';

interface createConsentProps {
  transactionId: string;
  comment: string;
}

export const createConsent = ({ transactionId, comment }: createConsentProps) => {
  const url = `/transactionApproval/consent`;
  return axios.post<HeadTransaction>(url, { transactionId, comment });
};

interface createTransactionProps {
  consentId: string;
}

export const createTransaction = ({ consentId }: createTransactionProps) => {
  const url = `/transactionApproval`;
  return axios.post<HeadTransaction>(url, { consentId });
};
