import React, { Fragment, useState, useCallback, useEffect, FC } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { time } from '~/utils';
import cx from 'classnames';

import { SelectInput, TextInput } from '~/components';
import { selectors, filterChange } from '~/ducks/virtualizedList';
import { resource } from './ActionReportsList';
import { ActionReport, Moment } from '~/types';
import { statusLabel } from '~/components/StatusField';
import { useAllReportSystemsAndTypes } from '~/hooks/actionReports';
import { useTranslateActionReports } from '.';

const Filters = () => {
  const filters = useSelector(selectors.getFilters(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment | undefined) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);
  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };
  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };
  const handleSystemChange = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
      reportType: '',
    });
    dispatchFilterChange(name, value);
    dispatchFilterChange('reportType', '');
  };
  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const schemaQuery = useAllReportSystemsAndTypes();
  const getSystemOptions = () => {
    return (
      schemaQuery.data?.map(({ system }) => ({
        value: system,
        text: system,
      })) || []
    );
  };
  const getReportTypeOptions = (system: string | undefined) => {
    if (!system) return [];
    return (
      schemaQuery.data
        ?.find((item) => item.system === system)
        ?.reportTypes.map((reportType) => ({
          value: reportType,
          text: reportType,
        })) || []
    );
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslateActionReports();

  return (
    <Fragment>
      {filters && (
        <div className={classes.root}>
          <div className={classes.container}>
            <SelectInput<ActionReport['objectType'] | undefined>
              label={t('objectType')}
              name="objectType"
              value={localValues.objectType}
              className={classes.input}
              options={[
                // { value: 'TRANSACTION', text: t('TRANSACTION') },
                { value: 'HEAD_TRANSACTION', text: t('HEAD_TRANSACTION') },
              ]}
              onChange={handleSelectInput}
              allowEmpty
            />
            <TextInput
              name="objectId"
              onChange={debouncedHandleInput}
              value={localValues.objectId}
              label={t('objectId')}
              className={classes.input}
              disableHelperText
            />
            <SelectInput<ActionReport['system'] | undefined>
              label={t('system')}
              name="system"
              value={localValues.system}
              className={classes.input}
              options={getSystemOptions()}
              onChange={handleSystemChange}
              disabled={schemaQuery.isLoading}
              allowEmpty
            />
            <SelectInput<ActionReport['reportType'] | undefined>
              label={t('reportType')}
              name="reportType"
              value={localValues.reportType}
              className={classes.input}
              options={getReportTypeOptions(localValues.system)}
              onChange={handleSelectInput}
              disabled={schemaQuery.isLoading || !localValues.system}
              allowEmpty
              defaultValue=""
            />
            <ReportStatusSelectInput
              name="status"
              value={localValues.status}
              className={classes.input}
              onChange={handleSelectInput}
            />
            <DateTimePicker
              className={classes.input}
              format="L - LT"
              margin="normal"
              label={t('createdFrom')}
              value={localValues.createdFrom || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'createdFrom')}
              maxDate={localValues.createdTo || time().format()}
              clearable
            />
            <DateTimePicker
              className={classes.input}
              format="L - LT"
              margin="normal"
              label={t('createdTo')}
              value={localValues.createdTo || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'createdTo')}
              maxDate={time().format()}
              minDate={localValues.createdFrom || undefined}
              clearable
            />
            <SelectInput<string>
              label={t('hasWarnings')}
              name="hasWarnings"
              value={localValues.hasWarnings + ''}
              className={classes.input}
              options={[
                {
                  value: 'true',
                  text: t('hasSomeWarnings'),
                },
                {
                  value: 'false',
                  text: t('hasNoWarnings'),
                },
              ]}
              onChange={handleSelectInput}
              allowEmpty
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export const ReportStatusSelectInput: FC<{
  name: string;
  label?: string;
  value: ActionReport['status'] | undefined;
  onChange: (e: any) => void;
  className?: string;
  [x: string]: any;
}> = (props) => {
  const { name, value, onChange, className, label, ...rest } = props;
  const translate = useTranslate();
  const t = useTranslateActionReports();

  return (
    <SelectInput<ActionReport['status'] | undefined>
      label={label || t('status')}
      name={name}
      value={value}
      className={cx(className)}
      options={[
        { value: 'ACCEPTED', text: translate(statusLabel('ACCEPTED')) },
        { value: 'FAILED', text: translate(statusLabel('FAILED')) },
        { value: 'INITIAL', text: translate(statusLabel('INITIAL')) },
        { value: 'REJECTED', text: translate(statusLabel('REJECTED')) },
        { value: 'SUBMITTED', text: translate(statusLabel('SUBMITTED')) },
        { value: 'INVALID', text: translate(statusLabel('INVALID')) },
        { value: 'REVOKED', text: translate(statusLabel('REVOKED')) },
      ]}
      allowEmpty
      onChange={onChange}
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1),
      flexGrow: 1,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  input: {
    maxWidth: 200,
  },
}));

export default Filters;
