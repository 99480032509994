import React, { FC } from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useNotify } from '~/hooks';
import Confirmation from '~/components/Confirmation';
import DeleteButton from '~/components/DeleteButton';
import { useExternalProfileLinkDelete } from '~/hooks/externalProfileLinks';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';

const label = (key: string): string => `components.ExternalProfileLinks.${key}`;

interface Props {
  link: ExternalProfileLink;
}

const DeleteAction: FC<Props> = (props) => {
  const { link } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  const notify = useNotify();

  const forceRefresh = useRefresh();

  const deleteMutation = useExternalProfileLinkDelete(link.id!, link.profileId);

  const onConfirm = async () => {
    await deleteMutation.mutateAsync();
    forceRefresh();
    notify({ type: 'success', message: t('deleteSuccessMessage') });
  };

  return (
    <Confirmation
      onConfirm={onConfirm}
      confirmButtonProps={{
        loading: deleteMutation.isLoading,
      }}
      confirmationSettings={{
        variant: 'modal',
        modal: {
          heading: t('deleteConfirmationTitle'),
          content: (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="warning">{t('deleteWarning')}</Alert>
              </Grid>
            </Grid>
          ),
        },
      }}
    >
      <DeleteButton label={t('delete')} />
    </Confirmation>
  );
};

export default DeleteAction;
