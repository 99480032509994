import React, { Fragment, useState, useRef } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import InfoIcon from '@material-ui/icons/Info';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { FlagIcon } from '~/img';
import { CountryField, TextField, H3, Drawer, AddActionButtons } from '~/components';
import { FileField, ImageField } from '~/components/ra';
import { useHandbook } from '~/hooks';
import { Document } from '~/types/businesses';
import { DeleteDocument } from '~/resources/businesses/components';

import { Individual, Business } from '~/types';
import { BusinessDocument, DocumentParameter, IndividualDocument } from '~/types/Handbook';
import { hasTranslation, time } from '~/utils';

import palette from '~/theme/palette';
import { Beneficiary } from '~/types/beneficiaries';
import { FileType } from '~/api/utils';

interface Props {
  record?: Business | Individual | Beneficiary;
  resource?: 'individuals' | 'businesses';
  solutionId?: string;
  country?: string;
  documentFamily: 'INDIVIDUAL' | 'BUSINESS';
  legalType?: string;
  document?: Document;
  primary?: boolean;
  fullWidth?: boolean;
  index?: number;
  permissions?: string[];
  editDocument?: (index: number, primary: boolean, handleClose: () => void) => JSX.Element;
  changelogMode?: boolean;
}

const DocumentCard = (props: Props) => {
  const {
    record,
    resource,
    solutionId,
    country,
    documentFamily,
    legalType,
    document,
    primary,
    fullWidth,
    index,
    editDocument,
    changelogMode,
  } = props;
  const { files, type, number, dateOfExpiry, ...restDocumentFields } = document || ({} as Document);

  const classes = useStyles();
  const imgRef: any = useRef(null);
  const translate = useTranslate();
  const translateLabel = (key: string): string => {
    const path = `components.ra.fields.DocumentCard.${key}`;
    return hasTranslation(path) ? translate(path) : key;
  };

  const translateDocumentType = (docType: string): string => {
    const key = `components.ra.fields.DocumentCard.documentType.${docType}`;
    const translation = translate(key);
    return key === translation ? docType : translation;
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (e: any) => {
    if (
      imgRef?.current?.contains(e.target) &&
      files &&
      (files[0].type || files[0].mimeType)?.startsWith('image')
    )
      return;
    setOpen(true);
  };

  const { data: handbook } = useHandbook(solutionId);

  const countryData = (handbook?.countries || []).find((c) => c.id === country);
  let documentType: BusinessDocument | IndividualDocument | undefined;
  switch (documentFamily) {
    case 'INDIVIDUAL':
      documentType = countryData?.individualDocuments?.find(
        (docType) => docType.type === document?.type
      );
      break;
    case 'BUSINESS':
      const legalTypeData = (countryData?.businessLegalTypes || []).find(
        (lt) => lt.name === legalType
      );
      documentType = legalTypeData?.documents?.find((docType) => docType.type === document?.type);
      break;
    default:
      break;
  }

  const findParameterType = (key: string): DocumentParameter | undefined => {
    return documentType?.parameters?.find((p) => p.name === key);
  };
  return (
    <Fragment>
      <div
        className={cx(changelogMode ? classes.changelogMode : undefined, classes.paper, {
          [classes.fullWidth]: fullWidth,
        })}
        onClick={handleOpen}
      >
        <Grid container wrap='nowrap'>
          <div ref={imgRef} className={classes.photoContainer}>
            <ImageField
              source='files[0]'
              record={document}
              width='88px'
              height='88px'
              objectFit='contain'
              addLabel={false}
              fullScreenView
              noText
            />
          </div>
          <Grid item container xs spacing={2}>
            <Grid item xs={6} container direction='column' spacing={2}>
              <Grid item xs>
                <TextField label={translateLabel('type')} title={type}>
                  <div className={classes.documentType}>
                    <div className={classes.ellipsis}>{translateDocumentType(type)}</div>
                    {primary && <FlagIcon />}
                  </div>
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField label={translateLabel('number')} title={number}>
                  {number}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item>
                <TextField label={translateLabel('dateOfExpiry')}>
                  {dateOfExpiry && (
                    <span className={cx({ [classes.outdated]: isOutdated(dateOfExpiry) })}>
                      {time(dateOfExpiry).format('L')}
                    </span>
                  )}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Drawer
        changelogMode={changelogMode}
        heading={translateLabel('viewDocument')}
        open={open}
        onClose={handleClose}
      >
        <Grid item container>
          <H3 topSpacing={0} icon={<InsertDriveFileIcon />}>
            {translateLabel('document')}
          </H3>
          <Grid item container spacing={2} direction='column' className={classes.innerContend}>
            <Grid item xs>
              <TextField label={translateLabel('type')}>
                <div className={classes.documentType}>
                  <div>{translateDocumentType(type)}</div>
                  {primary && <FlagIcon />}
                </div>
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField label={translateLabel('number')}>{number}</TextField>
            </Grid>
            <Grid item xs>
              <TextField label={translateLabel('dateOfExpiry')}>
                {dateOfExpiry && (
                  <span className={cx({ [classes.outdated]: isOutdated(dateOfExpiry) })}>
                    {dateOfExpiry && time(dateOfExpiry).format('L')}
                  </span>
                )}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        {Object.keys(restDocumentFields || {}).length > 0 && (
          <Grid item>
            <H3 icon={<InfoIcon />}>{translateLabel('additionalInfo')}</H3>
            <Grid item container spacing={2} direction='column' className={classes.innerContend}>
              {Object.keys(restDocumentFields).map((key, index) => {
                const restFields = restDocumentFields as any;
                const fieldContent = restFields[key];

                if (typeof fieldContent !== 'object') {
                  const parameter = findParameterType(key);
                  switch (parameter?.type) {
                    case 'date':
                      return (
                        <Grid item xs key={index}>
                          <TextField label={translateLabel(key)}>
                            {time(fieldContent).format('L')}
                          </TextField>
                        </Grid>
                      );
                    case 'country':
                      return (
                        <Grid item xs key={index}>
                          <TextField label={translateLabel(key)}>
                            <CountryField>{fieldContent}</CountryField>
                          </TextField>
                        </Grid>
                      );
                    default:
                      return (
                        <Grid item xs key={index}>
                          <TextField
                            label={translateLabel(key)}
                            textWrap
                            className={classes.textWrap}
                          >
                            {fieldContent}
                          </TextField>
                        </Grid>
                      );
                  }
                }
                return null;
              })}
            </Grid>
          </Grid>
        )}
        {files && Array.isArray(files) && (
          <Grid item>
            <H3 icon={<AttachmentIcon />}>{translateLabel('scans')}</H3>
            <Grid container spacing={1}>
              {files.map((item, index) => {
                return (
                  <Grid item xs={6} key={item.id}>
                    <AddActionButtons record={record} file={item as FileType}>
                      <FileField
                        key={index}
                        record={item}
                        withPreview
                        imageFieldProps={{
                          fullScreenView: true,
                          width: '100%',
                          height: 170,
                          objectFit: 'contain',
                          noText: true,
                        }}
                      />
                    </AddActionButtons>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {(record || editDocument) && !changelogMode && (
          <Grid container item className={classes.toolbar} spacing={2} justify='flex-end'>
            {resource && record && !primary && (
              <Grid item>
                <DeleteDocument
                  closeDrawer={handleClose}
                  resource={resource}
                  record={record}
                  index={index!}
                />
              </Grid>
            )}
            {editDocument && <Grid item>{editDocument(index!, primary!, handleClose)}</Grid>}
          </Grid>
        )}
      </Drawer>
    </Fragment>
  );
};

const isOutdated = (date?: string): boolean => {
  if (!date) return false;
  const currentDate = new Date().getTime();
  const receivedDate = new Date(date).getTime();
  return currentDate - receivedDate > 0;
};

const useStyles = makeStyles((theme) => ({
  // DocumentCard
  paper: {
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    overflowY: 'unset',
  },
  fullWidth: {
    flexGrow: 1,
  },
  ellipsis: {
    display: 'inline',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  documentType: {
    display: 'flex',
    '& svg': {
      marginLeft: 3,
      marginRight: 3,
    },
  },
  photoContainer: {
    paddingRight: theme.spacing(2),
  },
  outdated: {
    color: theme.palette.error.main,
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellowDarker,
    '&:hover': {
      backgroundColor: palette.changelog.yellowHover,
    },
  },
  // Drawer
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    width: 395,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContend: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    paddingTop: theme.spacing(2),
  },
  textWrap: {
    wordBreak: 'break-all',
  },
}));

export default DocumentCard;
