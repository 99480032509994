import React, { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Amount } from '~/types';
import { Longdash } from '~/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.typography.body2.color,
  },
  labeledField: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: 2,
      fontSize: 10,
    },
  },
  boldText: {
    fontWeight: 500,
  },
  amountText: {
    fontSize: 14,
  },
}));

interface Props {
  amount?: Amount;
  icon?: any;
  label?: string | undefined;
  style?: CSSProperties;
  className?: string;
  maximumFractionDigits?: number;
}

const AmountField = ({
  amount,
  icon,
  label,
  style = {},
  className = '',
  maximumFractionDigits = 6,
}: Props) => {
  const translate = useTranslate();
  const classes = useStyles();

  let amountText: string = Longdash;
  if (!amount || (amount.value !== 0 && !amount.value) || !amount.currency) {
    amountText = Longdash;
  } else {
    amountText = amount.value.toLocaleString('en-US', {
      style: 'currency',
      currency: amount.currency,
      maximumFractionDigits: maximumFractionDigits,
    });
  }

  return (
    <div className={classes.root}>
      {icon && <SvgIcon className={classes.icon}>{icon}</SvgIcon>}
      <div className={classes.labeledField}>
        {label && <label>{translate(label)}</label>}
        <div
          className={cx(classes.amountText, {
            [classes.boldText]: amount?.value,
            //[className]: className?.includes('RaReferenceField-link'),
            [className]: className,
          })}
          style={style}
        >
          {amountText}
        </div>
      </div>
    </div>
  );
};

export default AmountField;
