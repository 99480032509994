import React from 'react';
import { useTranslate } from 'react-admin';
import BlockIcon from '@material-ui/icons/Block';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey[600],
    width: '9em',
    height: '9em',
  },
  h1: {
    color: theme.palette.grey[600],
    fontWeight: 900,
  },
  subtitle: {
    color: theme.palette.grey[600],
  },
}));

const NoPermissions = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Grid container spacing={3} justify="center" alignItems="center" direction="column">
      <Grid item>
        <BlockIcon className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography variant="h1" className={classes.h1}>
          {translate('components.ra.fields.NoPermissions.permissionDenied')}
        </Typography>
      </Grid>
      <Grid item className={classes.subtitle}>
        {translate('components.ra.fields.NoPermissions.subtitle')}
      </Grid>
    </Grid>
  );
};

export default NoPermissions;
