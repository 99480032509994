import React, { FC, Fragment } from 'react';

import { Longdash } from '~/utils';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';

interface Props {
  record?: ExternalProfileLink;
  label?: string;
}

const ReferenceIdField: FC<Props> = (props) => {
  const { record } = props;

  if (!record) {
    return null;
  }

  const { referenceId } = record;
  return referenceId && referenceId.length > 0 ? (
    <>
      {referenceId.map((refId) => (
        <Fragment key={refId}>
          {refId}
          <br />
        </Fragment>
      ))}
    </>
  ) : (
    <>{Longdash}</>
  );
};

export default ReferenceIdField;
