import React, { Fragment } from 'react';
import {
  Button,
  Datagrid,
  Filter,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { SettingsIcon } from '~/img';
import { List } from '~/layout';
import { NoPermissions, StatusField } from '~/components/ra';
import PaymentGroupCreateAction from './components/PaymentGroupCreateAction';
import { Longdash } from '~/utils';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { ReferenceField } from 'react-admin';

export const l = (key: string): string => `resources.paymentServices/groups.list.${key}`;

const PaymentGroupListActions = () => {
  const { permissions } = usePermissions();

  const history = useHistory();

  if (!permissions) return null;

  return (
    <TopToolbar>
      <PaymentGroupCreateAction />
      <Button label={l('backToServices')} onClick={() => history.push('/paymentServices')}>
        <SvgIcon>
          <SettingsIcon />
        </SvgIcon>
      </Button>
    </TopToolbar>
  );
};

const PaymentGroupList = (props: any) => {
  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('paymentService.list')) return <NoPermissions />;

  return (
    <Fragment>
      <List
        bulkActionButtons={false}
        {...props}
        actions={<PaymentGroupListActions {...props} />}
        filters={
          <Filter>
            <TextInput label={l('id')} source="id" alwaysOn style={{ minWidth: 200 }} />
            <TextInput label={l('name')} source="name" alwaysOn style={{ minWidth: 200 }} />
            <ReferenceInput
              source="parentId"
              reference="paymentServices/groups"
              allowEmpty
              emptyText={Longdash}
              alwaysOn
              style={{ minWidth: 200 }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Filter>
        }
      >
        <Datagrid rowClick="show" size="medium" selectedIds={null}>
          <TextField source="id" label={l('id')} sortable={false} />
          <TextField source="name" label={l('name')} sortable={false} />
          <ReferenceField
            source="parentId"
            reference="paymentServices/groups"
            link={false}
            label={l('parentId')}
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <StatusField source="status" defaultValue="ACTIVE" sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default PaymentGroupList;
