import lodashGet from 'lodash/get';
import i18n from '~/i18n';
import { i18nProvider } from '~/App';

const hasTranslation = (path?: string): boolean => {
  if (!path) return false;
  const locale = i18nProvider.getLocale();
  return !!lodashGet(i18n, `${locale}.${path}`);
};

export default hasTranslation;
