import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

import axios from '../../utils/axios';
import FileSaver from 'file-saver';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '15px',
  },
});

const QRCodeField = (props) => {
  const classes = useStyles();
  const { record } = props;

  if (!record) {
    return null;
  }

  const download = () => {
    axios.get(`/paymentDetails/${record.id}/qrcode`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(response.data, `${record.name}.png`);
    });
  };

  return (
    <div className={classes.root}>
      <Link onClick={download} to={false}>
        Download QR code
      </Link>
    </div>
  );
};

QRCodeField.defaultProps = {
  addLabel: true,
};

export default QRCodeField;
