import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Container, Grid, Typography, SvgIcon } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { ActionReport } from '~/types';
import H2 from '../H2';
import { resource } from '~/resources/actionReports/ActionReportsList';
import ReportData from '~/resources/actionReports/Tabs/ReportData';
import { ArrowForwardCircleIcon, CloseCircleIcon } from '~/img';
import { Button } from '../ra';
import { useNotify } from '~/hooks';
import { useSubmitActionReport } from '~/hooks/actionReports';
import onError from '~/errorsHandler';

interface Props {
  record: { report: ActionReport; reason: string } | undefined;
  onClose: () => void;
}

const ActionReportDraftDrawer: FC<Props> = (props) => {
  const { record, onClose } = props;
  const report = record?.report;
  const reason = record?.reason;

  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);

  const [initialState, setInitialState] = useState(report);
  const [state, setState] = useState(report);
  useEffect(() => {
    setInitialState(report);
  }, [report]);
  useEffect(() => {
    if (initialState) {
      setState(initialState);
    }
  }, [initialState]);

  const handleClose = () => {
    onClose();
    setState(undefined);
  };

  const { mutateAsync, isLoading } = useSubmitActionReport();

  const onSubmit = async () => {
    try {
      await mutateAsync({ report: state!, reason: reason! });
      handleClose();
      notify({ message: t('actionReportSubmitted'), type: 'success' });
    } catch (error) {
      onError(error);
    }
  };

  const [isDataEdit, setDataEdit] = useState(false);
  const handleEditStart = () => {
    setDataEdit(true);
  };
  const handleEditEnd = () => {
    setDataEdit(false);
  };

  return (
    <Drawer anchor="bottom" open={!!report} onClose={handleClose}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={7}>
          <Grid container item xs={12} alignItems="center" justify="space-between">
            <Grid item xs>
              <H2 topSpacing={0} bottomSpacing={0} className={classes.title}>
                {t('reviewReportData')}
              </H2>
              <Typography variant="subtitle1">{t('pleaseReviewReportData')}</Typography>
            </Grid>
            <Grid container item justify="flex-end" spacing={2} xs={4}>
              <Grid item>
                <Button
                  label="ra.action.cancel"
                  onClick={handleClose}
                  className={classes.red}
                  size="large"
                >
                  <SvgIcon>
                    <CloseCircleIcon />
                  </SvgIcon>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  label={t('approve')}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={onSubmit}
                  loading={isLoading}
                  disabled={isDataEdit}
                >
                  <SvgIcon>
                    <ArrowForwardCircleIcon />
                  </SvgIcon>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {state && (
            <Grid item xs>
              <ReportData
                record={state}
                initialState={initialState}
                onChange={setState}
                onEditStart={handleEditStart}
                onEditEnd={handleEditEnd}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: '32px',
  },
  red: {
    color: theme.palette.error.main,
  },
}));

export default ActionReportDraftDrawer;
