import React, { FC } from 'react';
import { Tab } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import ApiIdentitiesIcon from '@material-ui/icons/Fingerprint';

import { NoPermissions } from '~/components/ra';
import { PaperTabbedShowLayout, ShowView, RecordTitle } from '~/layout';
import { useApiIdentitiesT } from './utils';
import { useRightContentSpacing } from '~/hooks/styles';
import Aside from './components/Aside';
import { ApiIdentity } from '~/types/apiIdentity';
import LinkedIndividualAside from './components/LinkedIndividualAside';
import { isEmpty } from 'lodash';
import { H2 } from '~/components';
import LinkedBusinessAside from './components/LinkedBusinessAside';
import AuthenticationMethods from '~/components/ApiIdentity/AuthenticationMethods';
import ActiveIdentityLocks from '~/components/ApiIdentity/lockIdentity/ActiveIdentityLocks';
import { useApiIdentityById } from '~/hooks/apiIdentity';
import AuditLogTable from '../auditLog/AuditLogTable';
import AuthLogFilters from './components/AuthLogFilters';
import { useSelector } from 'react-redux';
import { selectors } from '~/ducks/virtualizedList';
import { resource } from '~/resources/auditLog/AuditLogList';

const ApiIdentitiesShow: FC<any> = (props) => {
  const { permissions, id } = props;

  const t = useApiIdentitiesT('');
  const apiIdentityQuery = useApiIdentityById({ identityId: id });

  if (!permissions) return null;
  if (!permissions?.includes('apiIdentity.view')) return <NoPermissions />;

  return (
    <ShowView
      {...props}
      record={apiIdentityQuery.data}
      loading={apiIdentityQuery.isLoading}
      loaded={apiIdentityQuery.isSuccess}
      title={
        <RecordTitle
          {...props}
          record={apiIdentityQuery.data}
          headline={() => t('name', 1)}
          icon={
            <Avatar>
              <ApiIdentitiesIcon />
            </Avatar>
          }
        />
      }
    >
      <Content />
      <RightContent />
    </ShowView>
  );
};

const Content: FC<{ record?: ApiIdentity; loading?: boolean }> = (props) => {
  const { record } = props;
  const tTabs = useApiIdentitiesT('tabs');
  const filters = useSelector(selectors.getFilters(resource));

  return (
    <PaperTabbedShowLayout {...props}>
      <Tab label={tTabs('authMethods')}>
        <AuthenticationMethods />
      </Tab>
      <Tab label={tTabs('identityLocks')} path="identityLocks">
        <ActiveIdentityLocks />
      </Tab>
      <Tab label={tTabs('authLogs')} path="authLogs">
        {record && (
          <>
            <AuthLogFilters defaultFilters={{ identityId: record.id, tags: ['o.auth'] }} />
            <AuditLogTable filters={filters} />
          </>
        )}
      </Tab>
    </PaperTabbedShowLayout>
  );
};

const RightContent: FC<{ record?: ApiIdentity; loading?: boolean }> = (props) => {
  const { record, loading } = props;
  const t = useApiIdentitiesT();

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  return (
    <Grid container item xs direction="column" spacing={2}>
      <Grid item className={classes.rightContent}>
        <Aside record={record} loading={loading} />
      </Grid>
      {!isEmpty(record?.profiles) && (
        <Grid item className={classes.rightContent}>
          <H2 topSpacing={1} bottomSpacing={0}>
            {t('linkedProfiles')}
          </H2>
        </Grid>
      )}
      {record?.profiles
        .sort((a, b) => (a.profileType < b.profileType ? 1 : -1))
        .map((item) => {
          if (item.profileType === 'INDIVIDUAL') {
            return (
              <Grid key={item.profileId} item className={classes.rightContent}>
                <LinkedIndividualAside profileId={item.profileId} loading={loading} />
              </Grid>
            );
          }
          if (item.profileType === 'BUSINESS') {
            return (
              <Grid key={item.profileId} item className={classes.rightContent}>
                <LinkedBusinessAside
                  profileId={item.profileId}
                  identityId={record.id}
                  loading={loading}
                />
              </Grid>
            );
          }
        })}
    </Grid>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
}));

export default ApiIdentitiesShow;
