import React, { FC } from 'react';
import { ReferenceField } from 'react-admin';
import { TextField as RaTextField } from '~/components/ra';
import { buildProfileLink, Longdash } from '~/utils';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { useExternalProfileLinkTranslate } from '..';

interface Props {
  record?: ExternalProfileLink;
  label?: string;
}

const ProfileField: FC<Props> = (props) => {
  const { record } = props;
  const t = useExternalProfileLinkTranslate();

  if (!record) {
    return null;
  }

  const { profileId, profileType } = record;

  const makeProps = (reference: string, source?: string) => ({
    ...props,
    reference,
    basePath: `/${reference}`,
    source: source || 'profileId',
    link: false,
    addLabel: false,
  });

  switch (profileType) {
    case 'INDIVIDUAL':
      return (
        <ReferenceField {...makeProps('individuals')}>
          <RaTextField
            fn={(p) => `${p.firstName || Longdash} ${p.lastName}`}
            label={t(`profileField.${profileType}`)}
            showLink={buildProfileLink(undefined, profileId, profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'BUSINESS':
      return (
        <ReferenceField {...makeProps('businesses')}>
          <RaTextField
            fn={(p) => p.legalName}
            label={t(`profileField.${profileType}`)}
            showLink={buildProfileLink(undefined, profileId, profileType)}
            textWrap
          />
        </ReferenceField>
      );
    default:
      return null;
  }
};

export default ProfileField;
