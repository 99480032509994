export default {
  AddressField: 'Address',
  DocumentCard: {
    type: 'Type',
    number: 'Number',
    dateOfIssue: 'Date of issue',
    dateOfExpiry: 'Date of expiry',
    dateOfBirth: 'Date of birth',
    issuer: 'Issuer',
    countryOfIssue: 'Country of issue',
    authority: 'Authority',
    nationality: 'Nationality',
    viewDocument: 'View document',
    document: 'Document',
    additionalInfo: 'Additional Information',
    scans: 'Scans and files',
    mrz: 'MRZ',
    documentType: {
      ZAF_SMART_CARD_ID: 'South African Smart Card ID',
      FOREIGN_PASSPORT: 'Foreign passport',
      ZAF_ID_BOOKLET: 'South African ID Booklet',
      REFUGEE_ASYLUM_PERMIT: 'Asylum Seeker Temporary Visa',
      PERMIT: 'Temporary Residence Permit',
    },
  },
  StatusField: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    CANCELLED: 'Cancelled',
    CANCELLING: 'Cancelling',
    ISSUING: 'Issuing',
    REISSUING: 'Reissuing',
    CLOSED: 'Closed',
    COMPLETED: 'Completed',
    CREATED: 'Created',
    DELETED: 'Deleted',
    FAILED: 'Failed',
    LOST: 'Lost',
    STOLEN: 'Stolen',
    LOCKED: 'Locked',
    TO_CLOSE: 'To close',
    EXPIRED: 'Expired',
    RESTRICTED: 'Restricted',
    NOT_ACTIVATED: 'Not activated',
    PAID_OUT: 'Paid out',
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    AUTHORIZED: 'Authorized',
    REJECTED: 'Rejected',
    REVERSED: 'Reversed',
    REVOKED: 'Revoked',
    SENT: 'Sent',
    SENDING: 'Sending',
    INITIAL: 'Initial',
    SUBMITTED: 'Submitted',
    ACCEPTED: 'Accepted',
    INVALID: 'Invalid',
    ARCHIVED: 'Archived',
    APPROVED: 'Approved',
    REQUESTED: 'Requested',
    NOT_READY: 'Not ready',
    CONSENT: 'Consent',
    IN_PROGRESS: 'In Progress',
    COMPLETED_WITH_ERRORS: 'Completed with errors',
    PASSED: 'Passed',
  },
  AvatarField: {
    editAvatar: 'Edit avatar',
  },
  BlockButton: 'Block',
  CloseButton: 'Close',
  UnblockButton: 'Unblock',
  NoPermissions: {
    permissionDenied: 'Permission denied',
    subtitle: 'Either you typed a wrong URL, or you followed a bad link.',
  },
  VirtualizedList: {
    empty: 'There is no data matching the specified filters.',
  },
  ProfileEditContacts: {
    addContact: 'Add contact',
    contacts: 'Contacts',
    editContacts: 'Edit contacts',
    noContacts: 'No contacts added',
  },
  ProfileEditAddress: {
    address: 'Address',
    editAddress: 'Edit address',
  },
  SolutionSelect: {
    any: 'Any',
  },
};
