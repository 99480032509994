import * as React from 'react';
import { useState } from 'react';

import { useLocale, usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { CountryAutocomplete, MaterialTable } from '~/components';
import i18n from '~/i18n';

import CountryDetails from './CountryDetails';
import * as hb from '~/types/Handbook'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { validateId } from './utils';

const CountryTable = ({
  handbook,
  setState,
}: {
  handbook: Partial<hb.Handbook>;
  setState: hb.SetState;
}) => {
  const locale: keyof any = useLocale();
  const allCountries: { [key: string]: string } = (i18n as any)[locale].countries;

  const countries: hb.Country[] = (handbook.countries || []).slice();

  const isValid = (newRecord: hb.Country, oldRecord: hb.Country | undefined = undefined) =>
    !validateId(countries, newRecord.id, (oldRecord || {}).id, true);

  const setCountry: hb.SetCountry = (country: hb.Country) => {
    const index = countries.findIndex((c) => c.id === country.id);
    if (index >= 0) {
      countries[index] = country;
      return setState({ countries });
    } else {
      return Promise.reject();
    }
  };

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Country',
          field: 'id',
          required: true,
          validate: validateId,
          render: (rowData: hb.Country) => `${allCountries[rowData.id]} (${rowData.id})`,
          cellStyle: {
            minWidth: 500,
          },
        },
      ]}
      components={{
        Container: (props: any) => <Paper variant="outlined">{props.children}</Paper>,
        EditField: (props: any) => {
          const [initialState] = useState(props.value);

          const errorMessage =
            (props.columnDef.validate
              ? props.columnDef.validate(countries, props.value, initialState)
              : null) || '';
          return (
            <CountryAutocomplete
              value={props.value || ''}
              label={props.columnDef.title}
              onChange={(event) => {
                props.onChange(event.target.value);
              }}
              required={props.columnDef.required}
              helperText={errorMessage || '\u00A0'}
              error={errorMessage.length > 0}
            />
          );
        },
      }}
      data={countries}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newCountry: hb.Country) => {
            if (isValid(newCountry)) {
              newCountry.name = allCountries[newCountry.id];
              newCountry.nameLocalized = newCountry.name; // FIXME add localized name
              countries.push(newCountry);
              return setState({ countries });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newCountry: hb.Country, oldCountry: hb.Country) => {
            if (isValid(newCountry, oldCountry)) {
              newCountry.name = allCountries[newCountry.id];
              newCountry.nameLocalized = newCountry.name; // FIXME add localized name
              const index = countries.indexOf(oldCountry);
              countries[index] = newCountry;
              return setState({ countries });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldRecord: hb.Country) => {
            const index = countries.indexOf(oldRecord);
            countries.splice(index, 1);
            return setState({ countries });
          },
        }
      }
      detailPanel={(rowData: hb.Country) => {
        return <CountryDetails country={rowData} setCountry={setCountry} />;
      }}
      onRowClick={(event: any, rowData: hb.Country, togglePanel: () => void) => togglePanel()}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default CountryTable;
