import React, { Fragment, useEffect } from 'react';
import {
  useTranslate,
  TextInput,
  required,
  email,
  BooleanInput,
  FormDataConsumer,
  required as requiredFunc,
  number as numberFunc,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';

import { H3, StatusField } from '~/components';
import {
  PhoneNumberInput,
  HiddenInput,
  DocumentTypeSelect,
  ModalOrSelectInput,
  SelectArrayInput,
  ReferenceInput,
  DateInput,
  CountryInput,
} from '~/components/ra';
import { Business, Employee, Terminal } from '~/types';
import { Longdash } from '~/utils';
import useEmployeeRoles from '~/hooks/useEmployeeRoles';

import { useHandbook } from '~/hooks';

interface Props {
  businessRecord: Business;
  source?: string;
  disabled?: boolean;
  businessCreateForm?: boolean;
  fullWidth?: boolean;
  scopedFormData?: any;
}

interface GetValidateProps {
  required?: boolean;
  number?: boolean;
}

const getValidate = ({ required, number }: GetValidateProps): any[] => {
  const validateArray = [];
  if (required) validateArray.push(requiredFunc());
  if (number) validateArray.push(numberFunc());
  return validateArray;
};

const EmployeeForm = (props: Props) => {
  const {
    businessRecord,
    disabled,
    businessCreateForm,
    source = 'employee',
    fullWidth = true,
    scopedFormData,
  } = props;
  const { country, solutionId, id: businessId } = businessRecord;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.employees.${key}`);
  const { data: handbook } = useHandbook(solutionId);
  const form = useForm<Employee>();
  const initialValues = form.getState().initialValues;
  const { data: employeeRoles, isLoading } = useEmployeeRoles(solutionId);
  useEffect(() => {
    const state = form.getState().values;
    if (!state.phoneNumber) {
      form.change('phoneNumber', undefined);
    }
  });
  useEffect(() => {
    form.destroyOnUnregister = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getSource = (subsource: string): string => {
    if (businessCreateForm) return `${source}.${subsource}`;
    return subsource;
  };
  const parameterInputs: any[] = [];
  if (handbook && country) {
    // Find in handbooks object based oncountry of current Employee.
    const countryData = (handbook?.countries || []).find((c) => c.id === country);
    // Find documents for individual.
    const documents = countryData?.individualDocuments || [];
    // Get type of selected document.
    const selectedType = scopedFormData?.document?.type;
    // Find document's data from handbook.
    const documentData = documents.find((d) => d.type === selectedType);
    // Set document's fields.
    if (documentData) {
      const parameters = documentData.parameters || [];
      parameters.forEach((p) => {
        const { type, name, mandatory } = p;
        const sourceName = name;
        switch (type) {
          case 'date': {
            parameterInputs.push(
              <div key={name}>
                <DateInput
                  source={getSource(`document.${sourceName}`)}
                  record={scopedFormData}
                  options={{ fullWidth: true }}
                  validate={getValidate({ required: mandatory })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                />
              </div>
            );
            break;
          }
          case 'country': {
            parameterInputs.push(
              <div key={name}>
                <CountryInput
                  source={getSource(`document.${sourceName}`)}
                  record={scopedFormData}
                  fullWidth
                  validate={getValidate({ required: mandatory })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                />
              </div>
            );
            break;
          }
          default: {
            parameterInputs.push(
              <div key={name}>
                <TextInput
                  source={`document.${sourceName}`}
                  record={scopedFormData}
                  default
                  fullWidth
                  validate={getValidate({ required: mandatory, number: type === 'number' })}
                  label={`components.ra.fields.DocumentCard.${name}`}
                />
              </div>
            );
          }
        }
      });
    }
  }
  return (
    <Fragment>
      <HiddenInput source={getSource('solutionId')} defaultValue={solutionId} />
      <HiddenInput
        source={getSource('businessId')}
        defaultValue={businessId || '00000000-0000-0000-0000-000000000000'}
      />
      <TextInput
        source={getSource('firstName')}
        label={t('firstName')}
        validate={required()}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      <TextInput
        source={getSource('lastName')}
        label={t('lastName')}
        validate={required()}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      <PhoneNumberInput fullWidth={fullWidth} defaultValue={undefined} disabled={disabled} />
      <TextInput
        source={getSource('email')}
        label={t('email')}
        validate={businessCreateForm ? [email(), required()] : email()}
        disabled={disabled || !!initialValues.email}
        fullWidth={fullWidth}
      />
      <H3>{t('document._')}</H3>
      <DocumentTypeSelect
        source={getSource('document.type')}
        country={country}
        solutionId={solutionId as string}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      {parameterInputs}
      <H3>{t('authority._')}</H3>
      {businessCreateForm ? (
        <HiddenInput source={getSource('roles')} defaultValue={[]} />
      ) : (
        <SelectArrayInput
          source={getSource('roles')}
          label={t('authority.employeeRole._')}
          choices={employeeRoles?.map(({ name, role }) => ({ name, id: role })) ?? []}
          validate={[required()]}
          disabled={disabled || isLoading}
          fullWidth={fullWidth}
        />
      )}
      <TextInput
        source={getSource('authority.position')}
        label={t('authority.position')}
        fullWidth={fullWidth}
        disabled={disabled}
      />
      <ReferenceInput
        source={getSource('reportsTo')}
        reference="employees"
        filter={{ businessId }}
        allowEmpty
        emptyText={Longdash}
        label={t('reportsTo')}
        disabled={disabled}
      >
        <ModalOrSelectInput<Employee>
          optionText={(item) => `${item.firstName} ${item.lastName}`}
          fullWidth={fullWidth}
        >
          {(item) => {
            return (
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item>
                  {item.firstName} {item.lastName}
                </Grid>
              </Grid>
            );
          }}
        </ModalOrSelectInput>
      </ReferenceInput>
      {!businessCreateForm && (
        <ReferenceInput
          source={getSource('terminalId')}
          reference="terminals"
          filter={{ businessId }}
          allowEmpty
          emptyText={Longdash}
          label={t('terminalId')}
          disabled={disabled}
        >
          <ModalOrSelectInput<Terminal> optionText="label" fullWidth={fullWidth}>
            {(item) => {
              return (
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <StatusField status={item.status} variant="dot" />
                  </Grid>
                  <Grid item>{item.label}</Grid>
                </Grid>
              );
            }}
          </ModalOrSelectInput>
        </ReferenceInput>
      )}
      <FormDataConsumer>
        {({ formData, ...rest }: any) => {
          // eslint-disable-line
          return (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BooleanInput
                  source={getSource('authority.owner')}
                  label={t('authority.owner')}
                  fullWidth={fullWidth}
                  defaultValue={false}
                  disabled={disabled}
                  {...rest}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source={getSource('authority.executive')}
                  label={t('authority.executive')}
                  defaultValue={false}
                  disabled={disabled}
                  fullWidth={fullWidth}
                  {...rest}
                />
              </Grid>
            </Grid>
          );
        }}
      </FormDataConsumer>
    </Fragment>
  );
};

export default EmployeeForm;
