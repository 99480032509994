import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { CreateButton, EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { Business } from '~/types';
import DocumentCardFields from '~/components/DocumentCardFields';

interface Props {
  record: Business | undefined;
  actionsId?: string; // used to apply some css from BusinessShow
  index?: number;
  edit?: boolean;
  primary?: boolean;
  closeParentDrawer?: () => void;
  permissions: string[];
}

const AddOrEditDocument = (props: Props) => {
  const {
    record,
    index,
    edit,
    primary,
    actionsId = 'section-actions',
    closeParentDrawer,
    permissions,
  } = props;
  const [open, setOpen] = useState(false);
  const label = (key: string): string => `resources.businesses.fields.${key}`;
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (closeParentDrawer) {
      closeParentDrawer();
    }
  };

  const lastDocIndex = index || (!edit && record?.documents?.length) || 0;
  const getSource = (source: string): string =>
    primary ? `primaryDocument.${source}` : `documents.[${lastDocIndex}].${source}`;

  if (!record) return null;

  return (
    <Fragment>
      <div id={actionsId}>
        {edit ? (
          <EditButton onClick={handleOpen} disabled={!permissions?.includes('business.update')} />
        ) : (
          <CreateButton
            onClick={handleOpen}
            variant="add"
            disabled={!permissions?.includes('business.update')}
          />
        )}
      </div>
      <Drawer
        heading={t(edit ? 'documents.edit' : 'documents.add')}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource="businesses" record={record} closeParent={handleClose}>
          <DocumentCardFields
            getSource={getSource}
            edit={edit}
            primary={primary}
            documentForm="BUSINESS"
          />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default AddOrEditDocument;
