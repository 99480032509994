import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DealIcon from '@material-ui/icons/Public';

import { TextField } from '~/components';
import { ReaderIcon as TransactionIcon } from '~/img';
import { AsideBase } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';

interface Props {
  [x: string]: any;
  record?: HeadTransaction;
}

const AsideReferences = (props: Props) => {
  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.aside.details.${key}`);

  const { record: trx } = props;

  if (!trx) {
    return null;
  }

  const dealId = trx.details.deal?.id || trx.details.remittance?.id;

  return (
    <AsideBase className={classes.root}>
      <Grid container spacing={2} className={classes.layout}>
        {dealId && (
          <Grid item xs={12}>
            <TextField label={t('deal')} showLink={`/deals/${dealId}/show`} icon={<DealIcon />}>
              {`${dealId.substr(0, 32)}`}
            </TextField>
          </Grid>
        )}
        {trx.details.reversalTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('reversalTransaction')}
              showLink={`/headTransactions/${trx.details.reversalTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              <span>{`${trx.details.reversalTransaction.id.substr(0, 32)}`}</span>
            </TextField>
          </Grid>
        )}
        {trx.details.revertedTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('revertedTransaction')}
              showLink={`/headTransactions/${trx.details.revertedTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              {`${trx.details.revertedTransaction.id.substr(0, 32)}`}
            </TextField>
          </Grid>
        )}
        {trx.details.approvalTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('approvalTransaction')}
              showLink={`/headTransactions/${trx.details.approvalTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              <span>{`${trx.details.approvalTransaction.id.substr(0, 32)}`}</span>
            </TextField>
          </Grid>
        )}
        {trx.details.approvedTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('approvedTransaction')}
              showLink={`/headTransactions/${trx.details.approvedTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              <span>{`${trx.details.approvedTransaction.id.substr(0, 32)}`}</span>
            </TextField>
          </Grid>
        )}
        {trx.details.settlementTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('settlementTransaction')}
              showLink={`/headTransactions/${trx.details.settlementTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              <span>{`${trx.details.settlementTransaction.id.substr(0, 32)}`}</span>
            </TextField>
          </Grid>
        )}
        {trx.details.settledTransaction?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('settledTransaction')}
              showLink={`/headTransactions/${trx.details.settledTransaction.id}/show`}
              icon={<TransactionIcon />}
            >
              {`${trx.details.settledTransaction.id.substr(0, 32)}`}
            </TextField>
          </Grid>
        )}
        {trx.details.payroll?.id && (
          <Grid item xs={12}>
            <TextField
              label={t('payroll')}
              showLink={`/batches/${trx.details.payroll.id}/show`}
              icon={<TransactionIcon />}
            >
              {`${trx.details.payroll.id.substr(0, 32)}`}
            </TextField>
          </Grid>
        )}
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
}));

export default AsideReferences;
