import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { NotesProfileType } from '~/api/notes';

import { CreateButton, Drawer } from '~/components';
import NoteForm from './NoteForm';

interface Props {
  referenceId: string;
  profileType: NotesProfileType;
}

const NoteCreate = (props: Props) => {
  const { referenceId, profileType } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.Notes.${key}`);

  return (
    <Fragment>
      <CreateButton variant="add" onClick={handleOpen} />
      <Drawer heading={t('addNote')} open={open} onClose={handleClose}>
        <NoteForm referenceId={referenceId} profileType={profileType} handleClose={handleClose} />
      </Drawer>
    </Fragment>
  );
};

export default NoteCreate;
