import React from 'react';
import Table from '@material-ui/core/Table';

import { useTheme } from '@material-ui/core/styles';

const OutterTable = (props: any) => {
  const theme = useTheme();
  return (
    <Table {...props} style={{ backgroundColor: (theme.palette.background as any).outterTable }} />
  );
};

export default OutterTable;
