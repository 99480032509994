import React, { Fragment } from 'react';
import {
  Button,
  Datagrid,
  Filter,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';

import { List } from '~/layout';
import { SettingsIcon } from '~/img';
import { NoPermissions, ReferenceInput, StatusField } from '~/components/ra';

import PaymentServiceCreateAction from './components/PaymentServiceCreateAction';
import { Longdash } from '~/utils';
import { SelectInput } from 'react-admin';

const l = (key: string): string => `resources.paymentServices.list.${key}`;

const PaymentServiceListActions = () => {
  const { permissions } = usePermissions();

  const history = useHistory();

  if (!permissions) return null;

  return (
    <TopToolbar>
      {/*{hasSettlementPermission && <TransferAction />}*/}
      <PaymentServiceCreateAction />
      <Button
        label={l('paymentGroupsSetup')}
        onClick={() => history.push('/paymentServices/groups')}
      >
        <SvgIcon>
          <SettingsIcon />
        </SvgIcon>
      </Button>
    </TopToolbar>
  );
};

const PaymentServiceList = (props: any) => {
  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('paymentService.list')) return <NoPermissions />;

  return (
    <Fragment>
      <List
        bulkActionButtons={false}
        {...props}
        actions={<PaymentServiceListActions {...props} />}
        filters={
          <Filter>
            <TextInput label={l('id')} source="id" alwaysOn style={{ minWidth: 200 }} />
            <TextInput label={l('name')} source="name" alwaysOn style={{ minWidth: 200 }} />
            <ReferenceInput
              source="group"
              reference="paymentServices/groups"
              allowEmpty
              emptyText={Longdash}
              alwaysOn
              style={{ minWidth: 200 }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
              label={l('partnerId')}
              source="partnerId"
              alwaysOn
              style={{ minWidth: 200 }}
            />
            <TextInput
              label={l('templateId')}
              source="templateId"
              alwaysOn
              style={{ minWidth: 200 }}
            />
          </Filter>
        }
      >
        <Datagrid rowClick="show" size="medium" selectedIds={null}>
          <TextField source="id" label={l('id')} sortable={false} />
          <TextField source="name" label={l('name')} sortable={false} />
          <TextField source="templateId" label={l('templateId')} sortable={false} />
          <TextField source="partnerId" label={l('partnerId')} sortable={false} />
          <TextField source="index" label={l('index')} sortable={false} />
          <StatusField source="status" defaultValue="ACTIVE" sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default PaymentServiceList;
