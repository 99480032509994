import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import SmartphoneIcon from '@material-ui/icons/Smartphone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import { TextField, PhoneField } from '~/components';
import { KeyIcon } from '~/img';
import { AsideBase } from '~/layout';
import { useApiIdentitiesT } from '../utils';
import { ApiIdentity } from '~/types/apiIdentity';

const Aside: FC<{ record?: ApiIdentity; loading?: boolean }> = (props) => {
  const { record, loading } = props;
  const { emailAddress, phoneNumber, username } = record || {};

  const classes = useStyles();
  const t = useApiIdentitiesT();

  return (
    <AsideBase>
      <Grid container spacing={2} className={classes.layout}>
        <Grid item xs={12}>
          <TextField
            label={t('emailAddress')}
            icon={<AlternateEmailIcon />}
            loading={loading}
            ellipsis
          >
            {emailAddress}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('phoneNumber')} icon={<SmartphoneIcon />} loading={loading} ellipsis>
            <PhoneField number={phoneNumber} />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('username')} icon={<KeyIcon />} loading={loading} ellipsis>
            {username}
          </TextField>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
}));

export default Aside;
