const FEE = {
  type: {
    _: 'Fee type',
    fixedDebitFee: 'Fixed Debit Fee',
    fixedDebitFeeDecrease: 'Fixed Debit Fee (decrease)',
    percentDebitFee: 'Percent Debit Fee',
    percentDebitFeeDecrease: 'Percent Debit Fee (decrease)',
    fixedCreditFee: 'Fixed Credit Fee',
    percentCreditFee: 'Percent Credit Fee',
  },
  name: 'Name',
  defaultName: 'Fee',
  percent: 'Percent',
  amount: 'Amount',
  currency: 'Currency',
  minAmount: 'Min amount',
  maxAmount: 'Max amount',
};

const REGULAR_FEE = {
  name: 'Name',
  defaultName: 'Fee',
  type: {
    _: 'Type',
    fixedFee: 'Fixed fee',
    tierBasedTurnover: 'Tier Based Turnover',
    tierBasedBalance: 'Tier Based Balance',
  },
  period: {
    _: 'Period',
    monthly: 'Monthly',
    annually: 'Annually',
  },
  amount: 'Amount',
  currency: 'Currency',
  billingDateType: {
    _: 'Billing Date',
    sliding: 'Sliding',
    fixed: 'Fixed',
  },
  billingDate: 'Billing Day',
  payOnTheFirstCalendarMonth: 'Pay on the first calendar month',
};

export default {
  name: 'Pricing & Limits',
  reference: {
    show: 'Go to configuration',
  },
  fields: {
    name: 'Name',
  },
  veengu_individual_p2p_transfer: {
    _title: 'P2P',
    sender: {
      _title: 'P2P / Sender',
      transferFee: {
        _title: 'P2P / Sender / Transfer fee',
        ...FEE,
      },
    },
    recipient: {
      _title: 'P2P / Recipient',
      transferFee: {
        _title: 'P2P / Recipient / Transfer fee',
        ...FEE,
      },
    },
  },
  veengu_individual_purchase_byQr: {
    _title: 'Purchase',
    sender: {
      _title: 'Purchase / Sender',
      transferFee: {
        _title: 'Purchase / Sender / Transfer fee',
        ...FEE,
      },
    },
  },
  veengu_individual_membership: {
    _title: 'Membership',
    fee: {
      _title: 'Membership / Fee',
      ...REGULAR_FEE,
    },
  },
  veengu_business_purchase_byStaticQr: {
    _title: 'Purchase acceptance / By static QR',
    recipient: {
      _title: 'Purchase acceptance / By static QR / Recipient',
      transferFee: {
        _title: 'Purchase acceptance / By static QR / Recipient / Transfer fee',
        ...FEE,
      },
    },
  },
  veengu_business_purchase_byMobilePos: {
    _title: 'Purchase acceptance / By mobile POS',
    recipient: {
      _title: 'Purchase acceptance / By mobile POS / Recipient',
      transferFee: {
        _title: 'Purchase acceptance / By mobile POS / Recipient / Transfer fee',
        ...FEE,
      },
    },
  },
  veengu_business_membership: {
    _title: 'Membership',
    fee: {
      _title: 'Membership / Fee',
      ...REGULAR_FEE,
    },
  },
};
