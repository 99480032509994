import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { TextField } from '~/components';
import { useGetFXRatesById } from '~/hooks/useGetFXRates';

interface Props {
  directFxRatesId: string;
  label: string;
}

const FXRatesField = (props: Props) => {
  const { directFxRatesId, label } = props;
  const { data, isLoading } = useGetFXRatesById(directFxRatesId);
  return <TextField label={label}>{isLoading ? <Skeleton /> : data?.name}</TextField>;
};

export default FXRatesField;
