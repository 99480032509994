export default {
  name: 'Pricing |||| Pricings',
  subtitle: 'Pricing plans keep financial configuration.',
  // Show
  tabs: {
    perFeatures: 'Per Features',
    limits: 'Limits',
    fees: 'Fees',
    loyalty: 'Loyalty',
  },
  feature: 'Feature',
  configuration: 'Configuration',
  statementTitle: 'Statement title',
  fields: {
    name: 'Name',
  },
  pnlAccount: "Tenant's Account",
  // Aside
  solution: 'Solution',
  product: 'Product',
  active: 'Active',
  status: 'Account Status',
  kycLevels: 'KYC Levels',
  kycLevel: 'KYC Level',
  profileStatus: 'Profile status',
  profileTags: 'Profile tags',
  paymentInstrument: 'Payment Instrument',
  // PerFeatures
  fee: 'Fee',
  service: 'Service charge',
  counter: 'Counter',
  limit: 'Limit',
  interest: 'Interest',
  editFee: 'Edit configuration',
  confirmConfigurationChanges: 'Confirm configuration changes?',
  confirm: 'Confirm',
  // RegularFeeForm
  period: 'Period',
  annually: 'Annually',
  monthly: 'Monthly',
  type: 'Type',
  fixedFee: 'Fixed fee',
  tierBasedTurnover: 'Tier Based Turnover',
  tierBasedBalance: 'Tier Based Balance',
  billingDateType: 'Billing Date',
  fixed: 'Fixed',
  sliding: 'Sliding',
  billingDate: 'Billing Day',
  payOnTheFirstCalendarMonth: 'Pay on the first calendar month',
  // RegularFeeConfirmationField
  feeConfiguration: 'Fee configuration',
  amount: 'Amount',
  // FeeForm
  feeType: 'Fee type',
  percent: 'Percent (%) |||| Percent',
  minAmount: 'Min amount',
  maxAmount: 'Max amount',
  sender: 'Sender',
  recipient: 'Recipient',
  fixedDebitFee: 'Fixed Debit Fee',
  fixedDebitFeeDecrease: 'Fixed Debit Fee (decrease)',
  percentDebitFee: 'Percent Debit Fee',
  percentDebitFeeDecrease: 'Percent Debit Fee (decrease)',
  fixedCreditFee: 'Fixed Credit Fee',
  percentCreditFee: 'Percent Credit Fee',
  FIXED_INCREASE: 'Fixed Increase Fee',
  FIXED_DECREASE: 'Fixed Decrease Fee',
  PERCENT_INCREASE: 'Percent Increase Fee',
  PERCENT_DECREASE: 'Percent Decrease Fee',
  loyalty: 'Loyalty',
  loyalty_: {
    discountType: {
      _: 'Discount type',
      FULL: 'Full',
      PERCENT: 'Percent',
      FIXED: 'Fixed',
    },
    discountAmount: 'Discount amount',
    discountPercent: 'Discount percent',
    discountSourceAccount: 'Discount source account',
    loyaltySourceAccount: 'Loyalty source account',
    feeLoyaltyConfiguration: 'Fee Loyalty configuration',
    expiration: 'Expiration date',
  },
  limits: 'Limits',
  limits_: {
    amount: 'Amount',
    count: 'Count',
    countMustBePositive: 'Must be greater than 0',
  },
  limitConfiguration: 'Limit configuration',
  fork: 'Fork',
  forkHeading: 'Fork pricing position',
  tags: 'Transaction tags',
  index: 'Index (priority)',
};
