import { createAction, handleActions } from 'redux-actions';

const UPDATE_EVENT_LOG = 'update/eventLog';

export const updateEventLogAction = createAction<number>(UPDATE_EVENT_LOG);

export const updateEventLog = () => updateEventLogAction(new Date().getTime());

export type State = {
  lastUpdate: number;
};

const initialState: State = {
  lastUpdate: new Date().getTime(),
};

export default handleActions<State, any>(
  {
    [UPDATE_EVENT_LOG]: (state, { payload }: { payload: number }) => {
      return {
        ...state,
        lastUpdate: payload,
      };
    },
  },
  initialState
);
