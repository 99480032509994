export default {
  validatePhoneNumber: {
    error: 'Invalid phone format. Use international format: + (country code) phone number',
  },
  snackbarNotifications: {
    dataUpdatedSuccess: 'Data updated',
    dataCreateSuccess: 'Data created',
    dataUpdatedFail: 'Data is not updated',
  },
  noHandbookValues: 'No options. Please, complete the solution handbook.',
  confirmation: {
    warning: 'Warning!',
    content: 'Do you really want to do this?',
    confirm: 'Confirm',
  },
  KycLevelInput: {
    label: 'KYC level',
  },
  clipboard: {
    copied: 'Copied to clipboard',
  },
};
