import React from 'react';
import { useTranslate } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { l } from '~/resources/pricings/PricingShow';
import { HilightChanges, TextField } from '~/components';
import ForkIcon from '@material-ui/icons/CallSplit';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  oldEntity?: any;
  entity: any;
}

const ForkInfo = (props: Props) => {
  const { entity, oldEntity } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const classes = useStyles();

  return (
    <Grid item container xs={12} spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <TextField label={t('tags')} icon={<ForkIcon />} noPaddingLeft multiline>
          {entity.tags.map((tag: string) => (
            <Chip key={tag} label={tag} size="small" style={{ margin: 1 }} />
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('index')} textWrap>
          <HilightChanges curr={(oldEntity || entity).index} prev={entity.index}>
            {entity.index}
          </HilightChanges>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default ForkInfo;
