import React from 'react';
import lodashGet from 'lodash/get';

import { useHandbook } from '~/hooks';
import { Longdash } from '~/utils';

interface Props {
  [x: string]: any;
  source?: string;
  solutionId?: string;
  solutionIdSource?: string;
  lebel?: string;
}

const MerchantCategoryCodeField = (props: Props) => {
  const { record, source, solutionIdSource } = props;

  const getSolutionId = () => {
    if (props.solutionId) return props.solutionId;
    return lodashGet(record, solutionIdSource || 'solutionId');
  };

  const merchantCode = lodashGet(record, source || 'merchantCategoryCode');
  const { data } = useHandbook(getSolutionId());

  let result = '';

  if (data && merchantCode) {
    const merchant = data.merchantCategoryCodes?.find((item) => item.id === merchantCode);
    result = merchant?.name || '';
  }

  return <div>{result || merchantCode || Longdash}</div>;
};

export default MerchantCategoryCodeField;
