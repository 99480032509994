import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { ByFeature } from '.';
import RegularFeeForm from './Forms/RegularFeeForm';
import FeeForm from './Forms/FeeForm';
import AcquiringFeeForm from './Forms/AcquiringFeeForm';
import RegularFeeConfirmationField from './ConfirmationDrawers/RegularFeeConfirmationField';
import FeeConfirmationField from './ConfirmationDrawers/FeeConfirmationField';
import AcquiringFeeConfirmationField from './ConfirmationDrawers/AcquiringFeeConfirmationField';
import { Pricing } from '~/types';
import PricingInfo from './PricingInfo';
import FeeLoyaltyForm from './Forms/FeeLoyaltyForm';
import FeeLoyaltyConfirmationField from './ConfirmationDrawers/FeeLoyaltyConfirmationField';
import DefaultAmountLimitForm from './Forms/DefaultAmountLimitForm';
import DefaultCountLimitForm from './Forms/DefaultCountLimitForm';
import DefaultAmountLimitConfirmationField from './ConfirmationDrawers/DefaultAmountLimitConfirmationField';
import DefaultCountLimitConfirmationField from './ConfirmationDrawers/DefaultCountLimitConfirmationField';
import { NumberInput } from 'react-admin';
import { required } from 'react-admin';
import ForkInfo from './ForkInfo';

export interface PricingDataEditProps {
  schema: ByFeature;
  pricingRecord: Pricing;
  record: any;
  setMainState: (newState: any) => void;
  currency: string;
}

export const buildGetSource = (sourcePrefix: string) => (source: string) =>
  `${sourcePrefix}${source && `.${source}`}`;

const PricingDataEdit = (props: PricingDataEditProps) => {
  const { schema, record, setMainState, pricingRecord, currency } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  const getItemSource = buildGetSource(`${schema.propertyKey}`);
  const currentEntity = lodashGet(record, getItemSource(''));

  const handleSubmit = (data: any, submitQuery?: (a: any) => any) => {
    const cleanup = (record: any, source: string) => {
      const scopedRecord = lodashGet(record, source);

      if (typeof scopedRecord === 'object') {
        const { period, billingDateType, type, discountType } = scopedRecord;

        if (
          (schema.type === 'veengu_fee_default' || schema.type === 'veengu_fee_acquiring') &&
          !type
        ) {
          return lodashSet(
            record,
            source,
            scopedRecord.tags
              ? {
                  tags: scopedRecord.tags,
                  index: scopedRecord.index,
                }
              : null
          );
        }

        let result = { ...scopedRecord };
        if (billingDateType === 'sliding') {
          delete result.billingDate;
        }
        if (period === 'annually') {
          delete result.payOnTheFirstCalendarMonth;
        }
        if (type) {
          if (type.toLowerCase().includes('fixed')) {
            delete result.minAmount;
            delete result.maxAmount;
            delete result.percent;
          } else if (type.toLowerCase().includes('percent')) {
            delete result.amount;
            if (typeof result.maxAmount?.value !== 'number') {
              delete result.maxAmount;
            }
            if (typeof result.minAmount?.value !== 'number') {
              delete result.minAmount;
            }
          }
        }
        if (discountType) {
          if (discountType.toLowerCase().includes('full')) {
            delete result.discountPercent;
            delete result.discountAmount;
          }
          if (discountType.toLowerCase().includes('percent')) {
            delete result.discountAmount;
          }
          if (discountType.toLowerCase().includes('fixed')) {
            delete result.discountPercent;
          }
        }
        return lodashSet(record, source, result);
      }
      return record;
    };
    const newData = cleanup({ ...record, ...data }, getItemSource(''));

    setMainState(newData);
    if (submitQuery) {
      submitQuery({
        ...pricingRecord,
        data: newData,
      });
    }
  };

  const handleDelete = (deleteQuery: () => void, submitQuery?: (a: any) => any) => {
    const newData = { ...record };
    if (!schema.propertyKey.startsWith('serviceCharges.')) {
      delete newData[schema.propertyKey];
    } else {
      newData.serviceCharges = {
        ...newData['serviceCharges'],
        [schema.propertyKey.substring('serviceCharges.'.length)]: null,
      };
    }

    setMainState(newData);
    if (submitQuery) {
      submitQuery({
        ...pricingRecord,
        data: newData,
      });
    }
  };

  return (
    <Fragment>
      <EditButton onClick={handleOpen} style={editButtonStyles} />
      <Drawer heading={t('editFee')} open={open} onClose={handleClose}>
        <PricingInfo schema={schema} />
        {currentEntity?.tags && <ForkInfo entity={currentEntity} />}
        <EditForm<any>
          record={record}
          resource='pricings'
          id={pricingRecord.id}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          withSaveConfirmation
          withDelete={currentEntity?.tags && currentEntity.tags.length > 0}
          saveConfirmationSettings={{
            variant: 'drawer',
            drawer: {
              heading: t('confirmConfigurationChanges'),
              content: (prevRecord, record) => {
                const oldEntity = lodashGet(prevRecord, getItemSource('')) || {};
                const newEntity = lodashGet(record, getItemSource('')) || {};
                return (
                  <Fragment>
                    <PricingInfo schema={schema} />
                    {oldEntity?.tags && <ForkInfo oldEntity={oldEntity} entity={newEntity} />}
                    {(function () {
                      switch (schema.type) {
                        case 'veengu_fee_membership':
                          return (
                            <RegularFeeConfirmationField
                              prevRecord={oldEntity}
                              record={newEntity}
                            />
                          );
                        case 'veengu_fee_default':
                          return <FeeConfirmationField prevRecord={oldEntity} record={newEntity} />;
                        case 'veengu_fee_acquiring':
                          return (
                            <AcquiringFeeConfirmationField
                              prevRecord={oldEntity}
                              record={newEntity}
                            />
                          );
                        case 'veengu_service_charges':
                          return <FeeConfirmationField prevRecord={oldEntity} record={newEntity} />;
                        case 'veengu_fee_loyalty_default':
                          return (
                            <FeeLoyaltyConfirmationField
                              prevRecord={oldEntity}
                              record={newEntity}
                            />
                          );
                        case 'veengu_limit_default_amount':
                          return (
                            <DefaultAmountLimitConfirmationField
                              prevRecord={oldEntity}
                              record={newEntity}
                            />
                          );
                        case 'veengu_limit_default_count':
                          return (
                            <DefaultCountLimitConfirmationField
                              prevRecord={oldEntity}
                              record={newEntity}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Fragment>
                );
              },
            },
          }}
        >
          {(function () {
            switch (schema.type) {
              case 'veengu_fee_membership':
                return (
                  <RegularFeeForm
                    currency={currency}
                    getSource={getItemSource}
                    prevRecord={record}
                  />
                );
              case 'veengu_fee_default':
                return (
                  <FeeForm
                    currency={currency}
                    getSource={getItemSource}
                    schema={schema}
                    format={pricingRecord.format}
                  />
                );
              case 'veengu_fee_acquiring':
                return (
                  <AcquiringFeeForm currency={currency} getSource={getItemSource} schema={schema} />
                );
              case 'veengu_service_charges':
                return (
                  <FeeForm
                    currency={currency}
                    getSource={getItemSource}
                    schema={schema}
                    format={pricingRecord.format}
                  />
                );
              case 'veengu_fee_loyalty_default':
                return (
                  <FeeLoyaltyForm currency={currency} getSource={getItemSource} schema={schema} />
                );
              case 'veengu_limit_default_amount': {
                const defaultValue = schema['v:default'];
                return (
                  <DefaultAmountLimitForm
                    currency={currency}
                    getSource={getItemSource}
                    defaultValue={defaultValue}
                  />
                );
              }
              case 'veengu_limit_default_count': {
                const defaultValue = schema['v:default'];
                return (
                  <DefaultCountLimitForm getSource={getItemSource} defaultValue={defaultValue} />
                );
              }
              default:
                return null;
            }
          })()}
          {currentEntity?.tags && (
            <NumberInput
              label={t('index')}
              source={getItemSource('index')}
              style={{ width: '100%' }}
              validate={[required()]}
            />
          )}
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

const editButtonStyles = { marginTop: -6 };

export default PricingDataEdit;
