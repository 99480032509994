import React, { useState, Fragment } from 'react';
import { useTranslate, FormDataConsumer } from 'react-admin';
import { JSONSchemaType } from 'ajv';

import { EditButton, Drawer } from '~/components';
import EditForm from '~/components/ra/EditForm';
import { makeStyles } from '@material-ui/core/styles';
import { Business } from '~/types';
import JsonSchemaForm from '~/components/ra/JsonSchemaForm';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: 360,
    boxSizing: 'border-box',
  },
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: 0,
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  jsonForm: {
    width: 350,
  },
}));

type FormData = {
  formData: Business;
  [x: string]: any;
};

const label = (key: string): string => `resources.businesses.fields.${key}`;

const AdditionalDataForm = (props: any) => {
  const { formData, schema } = props;
  const classes = useStyles();
  return <JsonSchemaForm data={formData} schema={schema} className={classes.jsonForm} />;
};

interface EditAdditionalPropertiesProps {
  record: Business;
  actionsId?: string;
  permissions: string[];
  schema: JSONSchemaType<Record<string, any>>;
}

const EditAdditionalProperties = (props: EditAdditionalPropertiesProps) => {
  const { record, actionsId = 'section-actions', permissions, schema } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  return (
    <Fragment>
      <div id={actionsId}>
        <EditButton onClick={handleOpen} disabled={!permissions?.includes('business.update')} />
      </div>
      <Drawer heading={t('additionalProperties.edit')} open={open} onClose={handleClose}>
        <EditForm record={record} resource="businesses">
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return <AdditionalDataForm formData={formData} schema={schema} />;
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default EditAdditionalProperties;
