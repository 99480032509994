import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExploreIcon from '@material-ui/icons/Explore';
import ReportsIcon from '@material-ui/icons/Assessment';

import { useRightContentSpacing } from '~/hooks/styles';
import { AmountField, StatusField, TextField } from '~/components';
import { RibbonIcon, TimeIcon } from '~/img';
import { time } from '~/utils';
import { AsideBase } from '~/layout';
import { Batch } from '~/types/Batch';
import { useTranslateBatchesShow } from '~/resources/batches';
import ReferenceFiled from '~/components/ReferenceFiled';

type Props = {
  record?: Batch;
};

const RightPanel: FC<any> = (props: Props) => {
  const { record: batch } = props;

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });
  const t = useTranslateBatchesShow();

  if (!batch) {
    return null;
  }

  return (
    <Grid container item xs direction="column" spacing={spacing}>
      <Grid item className={classes.asideContent}>
        <AsideBase className={classes.root}>
          <Grid container spacing={2} className={classes.layout}>
            <Grid item xs={12}>
              <TextField label={t('timestamp')} icon={<TimeIcon />}>
                {time(batch.created).format('ll, LTS')}
              </TextField>
            </Grid>
            <Grid xs={12} item>
              <TextField label={t('status')} icon={<RibbonIcon />}>
                <StatusField status={batch.status} />
              </TextField>
            </Grid>
            <Grid xs={12} item>
              <TextField label={t('numberOfRecords')} icon={<></>}>
                {batch.recordsTotal}
              </TextField>
            </Grid>
            <Grid xs={12} item>
              <TextField label={t('totalAmount')} icon={<></>}>
                <AmountField amount={batch.data.instructedAmount} />
              </TextField>
            </Grid>
            <Grid xs={12} item style={{ paddingBottom: 0 }}>
              <TextField icon={<ExploreIcon />}>{t('actor')}</TextField>
            </Grid>
            <Grid xs={12} item>
              <div className={classes.referenceContainer}>
                <ReferenceFiled record={{ id: batch.actorId, type: batch.actorType }} />
              </div>
            </Grid>
            <Grid xs={12} item style={{ paddingBottom: 0 }}>
              <TextField icon={<ReportsIcon />}>{t('object')}</TextField>
            </Grid>
            <Grid xs={12} item>
              <div className={classes.referenceContainer}>
                <ReferenceFiled record={{ id: batch.objectId, type: batch.objectType }} />
              </div>
            </Grid>
          </Grid>
        </AsideBase>
      </Grid>
    </Grid>
  );
};

type StylesProps = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  asideContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  wrapTopic: {
    wordBreak: 'break-all',
  },
  referenceContainer: {
    marginLeft: theme.spacing(4),
  },
}));

export default RightPanel;
