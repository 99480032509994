import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { withDataProvider, withTranslate, Query, usePermissions } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import IndividualsIcon from '@material-ui/icons/People';
import BusinessesIcon from '@material-ui/icons/ShoppingCart';

import NumberTile from './NumberTile';
import OnboardingChart from './OnboardingChart';
import PartnerAccountsTable from './PartnerAccountsTable';
import PnlAccountsTable from './PnlAccountsTable';
import DemoWelcome from '~/components/demoWelcome';

const styles = {
  pointer: {
    cursor: 'pointer',
  },
};

const Dashboard = (props) => {
  const { translate, history, classes } = props;
  const { roleId } = (localStorage.identity && JSON.parse(localStorage.identity)) || {};
  const { permissions } = usePermissions();

  const hasTenantAccountsPermission = permissions?.includes('tenant.account.list');
  const hasPartnerAccountsPermission = permissions?.includes('partner.account.list');

  if (!roleId) return null;
  return (
    <Fragment>
      {roleId === 'demo' && <DemoWelcome />}
      <div style={{ marginTop: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Query type="getResource" resource="" payload={{ url: 'utilities/profileStats' }}>
                {({ data, error }) => {
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      style={{ minWidth: 'calc(100% + 16px)' }}
                    >
                      <Grid key="individuals" item xs>
                        <NumberTile
                          title={translate('resources.individuals.name', 2)}
                          icon={IndividualsIcon}
                          number={data && data.individuals}
                          inc={data && data.individualsInc}
                          error={error}
                          onClick={() => history.push('/individuals')}
                          className={classes.pointer}
                        />
                      </Grid>
                      <Grid key="businesses" item xs>
                        <NumberTile
                          title={translate('resources.businesses.name', 2)}
                          icon={BusinessesIcon}
                          number={data && data.businesses}
                          inc={data && data.businessesInc}
                          error={error}
                          onClick={() => history.push('/businesses')}
                          className={classes.pointer}
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              </Query>
              <Query
                type="getResource"
                resource=""
                payload={{ url: 'utilities/onboardingStatsByDay' }}
              >
                {({ data, loading, error }) => {
                  return (
                    <Grid item xs={12}>
                      <OnboardingChart data={data} loading={loading} error={error} />
                    </Grid>
                  );
                }}
              </Query>
              {hasPartnerAccountsPermission && (
                <Query
                  type="getList"
                  resource="partners"
                  payload={{ pagination: { page: 1, perPage: 10 } }}
                >
                  {({ data, loading, error }) => {
                    return (
                      <Grid item xs={12}>
                        <PartnerAccountsTable
                          data={data}
                          loading={loading}
                          error={error}
                          history={history}
                        />
                      </Grid>
                    );
                  }}
                </Query>
              )}
            </Grid>
          </Grid>
          {hasTenantAccountsPermission && (
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Query
                  type="getList"
                  resource="tenant/accounts"
                  payload={{ pagination: { page: 1, perPage: 50 } }}
                >
                  {({ data, loading, error }) => {
                    return (
                      <Grid item xs={12}>
                        <PnlAccountsTable
                          data={data}
                          loading={loading}
                          error={error}
                          history={history}
                        />
                      </Grid>
                    );
                  }}
                </Query>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  version: state.admin.ui.viewVersion,
});

export default withStyles(styles)(
  withTranslate(compose(connect(mapStateToProps), withDataProvider)(Dashboard))
);
