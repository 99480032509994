import React, { useState, Fragment, useRef } from 'react';
import { useTranslate, FormDataConsumer } from 'react-admin';
import { EditButton, Drawer } from '~/components';
import { AddressForm, EditForm } from '~/components/ra';
import { Individual, Business } from '~/types';

const label = (key: string): string => `components.ra.fields.ProfileEditAddress.${key}`;

type FormData = {
  formData: Business;
  [x: string]: any;
};

interface Props {
  resource: 'businesses' | 'individuals';
  record: Business | Individual | undefined;
  actionsId?: string; // used to apply some css from BusinessShow
  permissions: string[];
}

const EditAddress = (props: Props) => {
  const { resource, record, actionsId = 'section-actions', permissions } = props;
  const formRef = useRef<any>(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  if (!record) return null;

  return (
    <Fragment>
      <div id={actionsId}>
        <EditButton
          label={translate(label('editAddress'))}
          onClick={handleOpen}
          disabled={!permissions?.includes('business.update')}
        />
      </div>
      <Drawer heading={translateLabel('editAddress')} open={open} onClose={handleClose}>
        <EditForm resource={resource} record={record} closeParent={handleClose}>
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return (
                <div ref={formRef}>
                  <AddressForm solutionId={formData.solutionId} fullWidth />
                </div>
              );
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default EditAddress;
