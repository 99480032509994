export default {
  bankAccountsTable: {
    title: 'Bank accounts',
    alias: 'Account alias',
    balance: 'Current balance',
    countHint: 'Up to first 10 bank accounts listed',
  },
  onboardingChart: {
    title: 'New onboardings',
  },
  moneyChart: {
    title: 'Total balance',
    cashIn: { title: 'Cash In' },
    cashOut: { title: 'Cash Out' },
    moneyInSystem: { title: 'Money in system' },
  },
  partnersTable: {
    title: 'Partners',
  },
  partnerAccountsTable: {
    title: 'Partners',
    name: 'Partner name',
    balance: 'Current balance',
    countHint: 'Up to first 10 partners listed',
    viewAll: 'View all partners',
  },
  pnlAccountsTable: {
    title: "Tenant's Accounts",
    alias: 'Account alias',
    balance: 'Current balance',
    countHint: "Up to first 10 Tenant's Accounts listed",
  },
};
