import React, { FC } from 'react';
import { SelectInput, useTranslate, required } from 'react-admin';
import { usePnlAccounts } from '~/hooks/pnlAccounts';
import { l } from '../CurrenciesList';

interface Props {
  currency: string;
}

const TradingAccountSelect: FC<Props> = (props) => {
  const { currency } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const pnlAccountsQuery = usePnlAccounts();

  return (
    <SelectInput
      source={`tradingAccounts.${currency}`}
      choices={pnlAccountsQuery.data
        ?.filter((item) => item.currency === currency && item.type !== 'SETTLEMENT')
        .map((item) => ({ id: item.id, name: item.alias }))}
      label={`${currency} ${t('tradingAccountId')}`}
      validate={required()}
      disabled={pnlAccountsQuery.isLoading}
    />
  );
};

export default TradingAccountSelect;
