import React, { FC, useCallback } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import { useFeedbackStatistic } from '~/hooks/feedback';
import { ProfileType } from '~/types/profile';
import FeedbackField from './FeedbackField';
import { H2, TableCell } from '..';
import { useTranslate } from 'react-admin';

interface Props {
  groupId: string | undefined;
  groupType: ProfileType;
}

const resource = 'feedback';

const useTranslateFeedback = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.fields.${key}`), [
    translate,
  ]);
};

const FeedbackStatistic: FC<Props> = (props) => {
  const { groupId, groupType } = props;

  const t = useTranslateFeedback();

  const statisticQuery = useFeedbackStatistic(
    {
      groupId: groupId!,
      groupType,
    },
    { enabled: !!groupId }
  );

  const loading = statisticQuery.isLoading;

  return (
    <>
      <H2 topSpacing={0}>{t('statistic')}</H2>
      <Table style={maxWidth}>
        <TableHead>
          <TableRow>
            <TableCell loading={loading}>{t('feedback')}</TableCell>
            <TableCell loading={loading}>{t('count')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(statisticQuery.data?.statistic || {})
            .sort((a, b) => (a[1] < b[1] ? 1 : -1))
            .map(([tag, count]) => {
              return (
                <TableRow key={tag}>
                  <TableCell>
                    <FeedbackField tag={tag} />
                  </TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

const maxWidth = { maxWidth: 400 };

export default FeedbackStatistic;
