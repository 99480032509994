import React, { useState, useEffect } from 'react';
import { usePermissions } from 'react-admin';
import { Paper } from '@material-ui/core';
import { MaterialTable } from '~/components';

import { getExternalSourceTypes } from '~/api/externalSources';
import { useApi } from '~/hooks';

interface Row {
  value: string;
  tableData?: any;
}

export interface Props {
  sourceTypes: string[];
  setSourceTypes: (sourceTypes: string[]) => Promise<any>;
}

const ExternalSourceTable = ({ sourceTypes, setSourceTypes }: Props) => {
  const [{ data: externalSourceTypes }] = useApi<string[]>(getExternalSourceTypes);

  const [rows, setRows] = useState<Row[]>(
    sourceTypes.map((sourceType) => ({
      value: sourceType,
    }))
  );

  useEffect(() => {
    setRows(
      sourceTypes.map((sourceType) => ({
        value: sourceType,
      }))
    );
  }, [sourceTypes]);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <div style={{ maxWidth: 500 }}>
      <MaterialTable
        columns={[
          {
            title: 'External source type',
            type: 'select',
            field: 'value',
            options: (externalSourceTypes || []).map((t) => ({ value: t, text: t })),
            required: true,
            validate: (row: Row) => !!row.value,
          },
        ]}
        components={{
          Container: (props: any) => <Paper variant="outlined">{props.children}</Paper>,
        }}
        data={rows}
        editable={
          permissions?.includes('partner.update') && {
            onRowAdd: (newRow: Row) => {
              const newRows: Row[] = [...rows, newRow];
              setRows(newRows);
              return setSourceTypes(newRows.map((r) => r.value));
            },
            onRowUpdate: (newRow: Row, oldRow: Row) => {
              const newRows: Row[] = [];
              rows.forEach((r) => {
                if (r === oldRow) {
                  newRows.push(newRow);
                } else {
                  newRows.push(r);
                }
              });
              setRows(newRows);
              return setSourceTypes(newRows.map((r) => r.value));
            },
            onRowDelete: (oldRow: Row) => {
              const newRows = rows.filter((r) => r !== oldRow);
              setRows(newRows);
              return setSourceTypes(newRows.map((r) => r.value));
            },
          }
        }
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          showTitle: false,
          paging: false,
          addRowPosition: 'first',
          search: false,
        }}
      />
    </div>
  );
};

export default ExternalSourceTable;
