import React from 'react';
import { FileInput as RaFileInput } from 'react-admin';

const FileInput = (props: any) => {
  if (props.anonymous) {
    return (
      <RaFileInput
        {...props}
        format={(json: any) => {
          if (Array.isArray(json)) {
            const result = (json as any[]).map((item) => {
              if (item.rawFile) {
                item.anonymous = props.anonymous;
                return item;
              } else {
                return item;
              }
            });
            return result;
          } else if (json && json.rawFile) {
            json.anonymous = props.anonymous;
            return json;
          } else {
            return json;
          }
        }}
      />
    );
  }
  return <RaFileInput {...props} />;
};

export default FileInput;
