import React, { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';
import { time } from '~/utils';
// import makeStyles from '@material-ui/core/styles/makeStyles';

import { Column } from '~/components/VirtualizedList';
import { ActionReport } from '~/types';
import { BooleanField, StatusField, TextField } from '~/components';
import { resource } from './ActionReportsList';
import { hasTranslation } from '~/utils';

interface Props {
  children: any;
  rowData: ActionReport;
  column: Column<ActionReport>;
  style?: CSSProperties;
}

// const useStyles = makeStyles((theme) => ({
// }));

const ColoredCell = (props: Props) => {
  const { children, style } = props;
  return <div style={{ ...style }}>{children}</div>;
};

const CustomCell = (props: Props) => {
  const { column, rowData, children } = props;
  const translate = useTranslate();
  const l = (key: string) => `resources.${resource}.fields.${key}`;
  const t = (key: string) => translate(l(key));

  switch (column.id) {
    case 'created': {
      const created = time(children);
      return (
        <ColoredCell column={column} rowData={rowData}>
          <TextField compact label={created.format('LL')}>
            {created.format('LT')}
          </TextField>
        </ColoredCell>
      );
    }
    case 'updated': {
      const updated = time(children);
      return (
        <ColoredCell column={column} rowData={rowData}>
          <TextField compact label={updated.format('LL')}>
            {updated.format('LT')}
          </TextField>
        </ColoredCell>
      );
    }
    case 'objectType': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <TextField compact label={hasTranslation(l(children)) ? t(children) : children}>
            {rowData.objectName}
          </TextField>
        </ColoredCell>
      );
    }
    case 'status': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <StatusField status={rowData.status} />
        </ColoredCell>
      );
    }
    case 'hasWarnings': {
      return (
        <ColoredCell column={column} rowData={rowData}>
          <BooleanField value={rowData.hasWarnings} nullOnFalse={true} />
        </ColoredCell>
      );
    }
    default: {
      return (
        <ColoredCell column={column} rowData={rowData}>
          {children}
        </ColoredCell>
      );
    }
  }
};

export default CustomCell;
