import React, { useState, useEffect, Fragment } from 'react';
import {
  ReferenceArrayField,
  Datagrid,
  TextField as RaTextField,
  useTranslate,
  Button,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { EditForm } from '~/components/ra';
import { H2, TableCell, EditButton, TextField } from '~/components';
import { useFeatures } from '~/hooks';
import { Section } from '~/layout';
import SolutionEdit from '../SolutionEdit';
import { Solution } from '~/types';
import { useProductTemplates } from '~/hooks/useProductTemplates';

interface EditProps {
  handleClose: () => void;
  resource: string;
  record: Solution;
  t: (key: string) => string;
}

const FeaturesEdit = (props: EditProps) => {
  const { handleClose, resource, record, t } = props;
  const features: string[] = record.features;
  const { data, loading, list } = useFeatures();

  const [state, setState] = useState<{ [x: string]: boolean }>({});

  const isListEmpty = isEmpty(list);
  const isStateEmpty = isEmpty(state);

  useEffect(() => {
    if (!isListEmpty && isStateEmpty) {
      const result: { [x: string]: boolean } = {};
      features.forEach((key) => (result[key] = true));
      setState(result);
    }
  }, [isListEmpty, features, isStateEmpty]);

  const handleChange = (key: string, checked: boolean) => () => {
    setState((prev) => ({ ...prev, [key]: !checked }));
  };

  const newFeatures = Object.keys(state).filter((key) => state[key]);
  const handleSubmit = (_: any, submitQuery: (newRecord: any) => void): void => {
    submitQuery({
      ...record,
      features: newFeatures,
    });
  };

  const classes = useStyles();

  return (
    <EditForm<Solution>
      closeParent={handleClose}
      resource={resource}
      record={record}
      onSubmit={handleSubmit}
      externalState={{
        ...record,
        features: newFeatures,
      }}
    >
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('featureName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => {
            const checked = Boolean(state[item.key]);
            return (
              <TableRow key={item.key}>
                <TableCell classes={{ root: classes.checkboxCell }}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange(item.key, checked)}
                    className={classes.checkbox}
                  />
                </TableCell>
                <TableCell>{item.name}</TableCell>
              </TableRow>
            );
          })}
          {loading &&
            new Array(10).fill(0).map((_, index) => (
              <TableRow key={index}>
                <TableCell loading={loading} />
                <TableCell loading={loading} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </EditForm>
  );
};

const ProductTemplatesEdit = (props: EditProps) => {
  const { handleClose, resource, record, t } = props;
  const productTemplates: string[] = record.productTemplates || [];

  const { data } = useProductTemplates();

  const [state, setState] = useState<{ [x: string]: boolean }>({});

  const isListEmpty = isEmpty(data);
  const isStateEmpty = isEmpty(state);

  useEffect(() => {
    if (!isListEmpty && isStateEmpty) {
      const result: { [x: string]: boolean } = {};
      productTemplates.forEach((key) => (result[key] = true));
      setState(result);
    }
  }, [isListEmpty, productTemplates, isStateEmpty]);

  const handleChange = (key: string, checked: boolean) => () => {
    setState((prev) => ({ ...prev, [key]: !checked }));
  };

  const newProductTemplate = Object.keys(state).filter((key) => state[key]);
  const handleSubmit = (_: any, submitQuery: (newRecord: any) => void): void => {
    submitQuery({
      ...record,
      productTemplates: newProductTemplate,
    });
  };

  const classes = useStyles();

  return (
    <EditForm<Solution>
      closeParent={handleClose}
      resource={resource}
      record={record}
      onSubmit={handleSubmit}
      externalState={{
        ...record,
        productTemplates: newProductTemplate,
      }}
    >
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('templateAlias')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => {
            const checked = Boolean(state[item.code]);

            return (
              <TableRow key={item.id}>
                <TableCell classes={{ root: classes.checkboxCell }}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange(item.code, checked)}
                    className={classes.checkbox}
                  />
                </TableCell>
                <TableCell>{item.alias}</TableCell>
              </TableRow>
            );
          })}
          {!data &&
            [{}, {}, {}].map((_, index) => (
              <TableRow key={index}>
                <TableCell loading={true} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </EditForm>
  );
};

const FeaturesGrid = (props: SummaryTabProps) => {
  const { resource, record, permissions, ...rest } = props;
  const features: string[] = record?.features || [];

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.fields.${key}`);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const editAllowed = permissions?.includes('solution.update');

  return (
    <Grid container xs={4} className={classes.table} item direction="column" spacing={1}>
      <Grid item container justify="space-between" alignItems="center">
        <Grid item>
          <H2 bottomSpacing={0} topSpacing={0}>
            {t('features')}
          </H2>
        </Grid>
        {editAllowed && (
          <Grid item>
            {!open ? (
              <EditButton onClick={handleOpen} />
            ) : (
              <Button label={'ra.action.cancel'} onClick={handleClose} />
            )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        {open ? (
          <FeaturesEdit handleClose={handleClose} resource={resource} record={record} t={t} />
        ) : !isEmpty(features) ? (
          <ReferenceArrayField record={record} {...rest} source="features" reference="features">
            <Datagrid>
              <RaTextField source="name" label={t('featureName')} />
            </Datagrid>
          </ReferenceArrayField>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('featureName')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('noFeatures')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

const ProductTemplatesGrid = (props: SummaryTabProps) => {
  const { resource, record, permissions } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.fields.${key}`);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const editAllowed = permissions?.includes('solution.update');

  const { data: productTemplates } = useProductTemplates();

  const selectedProductTemplates = productTemplates?.filter(
    (pt) => !!(record.productTemplates || []).find((code) => pt.code === code)
  );

  return (
    <Fragment>
      <Grid container xs={4} className={classes.table} item direction="column" spacing={1}>
        <Grid item container justify="space-between" alignItems="center">
          <Grid item>
            <H2 bottomSpacing={0} topSpacing={0}>
              {t('productTemplates')}
            </H2>
          </Grid>
          {editAllowed && (
            <Grid item>
              {!open ? (
                <EditButton onClick={handleOpen} />
              ) : (
                <Button label={'ra.action.cancel'} onClick={handleClose} />
              )}
            </Grid>
          )}
        </Grid>
        <Grid item>
          {open ? (
            <ProductTemplatesEdit
              handleClose={handleClose}
              resource={resource}
              record={record}
              t={t}
            />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('templateAlias')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(selectedProductTemplates) ? (
                  selectedProductTemplates!.map((pt) => {
                    return (
                      <TableRow key={pt.code}>
                        <TableCell>{pt.alias}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>{t('noProductTemplate')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

interface SummaryTabProps {
  resource: string;
  record: Solution;
  permissions: string[];
  [x: string]: any;
}

const SummaryTab = (props: SummaryTabProps) => {
  const { resource, record, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.fields.${key}`);

  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      <FeaturesGrid {...props} />
      <ProductTemplatesGrid {...props} />
      <Section noPaddings>
        <Grid container item justify="space-between" className={classes.heading}>
          <Grid item>
            <H2 bottomSpacing={0} topSpacing={0}>
              {t('solutionDefinition')}
            </H2>
          </Grid>
          <Grid item id="section-actions">
            <SolutionEdit record={record} resource={resource} permissions={permissions} />
          </Grid>
        </Grid>
        <Section>
          <Grid container item spacing={2}>
            <Grid item>
              <TextField label={t('name')}>{record?.name}</TextField>
            </Grid>
          </Grid>
        </Section>
      </Section>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  checkbox: {
    margin: -16,
    marginLeft: -11,
  },
  checkboxCell: {
    paddingRight: 0,
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default SummaryTab;
