import React from 'react';
import { CellProps } from './CellProps';

const grey = { color: '#CACACA' };

const ColoredField = ({ children, rowData, style }: CellProps) => {
  let actualStyle;
  if (style) {
    actualStyle = style;
  } else {
    if (rowData.status === 'PENDING') {
      actualStyle = grey;
    }
  }

  return <div style={actualStyle}>{children}</div>;
};

export default ColoredField;
