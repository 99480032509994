export default {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
  mon: {
    full: 'Monday',
  },
  tue: {
    full: 'Tuesday',
  },
  wed: {
    full: 'Wednesday',
  },
  thu: {
    full: 'Thursday',
  },
  fri: {
    full: 'Friday',
  },
  sat: {
    full: 'Saturday',
  },
  sun: {
    full: 'Sunday',
  },
};
