import React from 'react';
import { SelectInput } from 'react-admin';
import { useLoadHandbooksBySolutions } from '~/hooks/useHandbook';
import { useAllSolutions } from '~/hooks/useSolution';
import palette from '../../theme/palette';
import { useTranslate } from 'react-admin';
import { Solution } from '~/types';
import { Handbook, KycLevel } from '~/types/Handbook';

export interface Props {
  source: string;
  label?: string;
  className?: string;
  alwaysOn: boolean;
  withFeature?: string;
}

const formatChoices = (choice: { id: string; name: string }) => {
  const startIndex = choice.name.indexOf('(');
  const endIndex = choice.name.indexOf(')');
  if (startIndex === -1 && endIndex === -1) {
    return <span>{choice.name}</span>;
  } else {
    return (
      <span>
        {choice.name.substring(0, startIndex)}
        <span style={{ color: palette.text.secondary, opacity: 0.5 }}>
          {choice.name.substring(startIndex, endIndex + 1)}
        </span>
      </span>
    );
  }
};

const KycLevelSelect = ({ source, label, className, alwaysOn = true, withFeature }: Props) => {
  const translate = useTranslate();
  const path = (key: string): string => `resources.individuals.fields.${key}`;

  // Form choices for select.
  const choices: { id: string; name: string }[] = [{ id: '', name: translate(path('any')) }];

  // Load all solutions.
  const { data: allSolutions, isLoading } = useAllSolutions();

  // Filter solutions with feature if needed.
  const solutions = withFeature
    ? allSolutions?.data.filter((solution: Solution) => solution.features.includes(withFeature))
    : allSolutions?.data;

  // Load handbooks using solutions.
  const { data: handbooks, loading } = useLoadHandbooksBySolutions(solutions || []);

  // Map solutions for faster name lookup.
  const solutionMap = new Map(solutions?.map((solution) => [solution.id, solution.name]));

  // Go through handbooks and create the name for the selector.
  if (!loading && handbooks) {
    handbooks.forEach((handbook: Handbook & { solutionId: string }) => {
      const { kycLevels, solutionId } = handbook;
      if (!kycLevels) return;
      return kycLevels.forEach((kycLevel: KycLevel) => {
        const solutionName = solutionMap.get(solutionId);
        // And push it to choices.
        choices.push({ id: kycLevel.id, name: `${kycLevel.name} (${solutionName})` });
      });
    });
  }

  if (isLoading || loading) return null;

  return (
    <SelectInput
      choices={choices}
      className={className}
      source={source}
      label={label}
      helperText={false}
      optionText={formatChoices}
      allowEmpty={false}
      defaultValue=''
      alwaysOn={alwaysOn}
      translateChoice={false}
    />
  );
};

export default KycLevelSelect;
