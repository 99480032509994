import React, { FC } from 'react';
import { TextInput } from 'react-admin';
import { useTranslate } from 'react-admin';
import { DateInput } from '~/components/ra';

import { Beneficiary } from '~/types/beneficiaries';
import BeneficiaryEdit from './BeneficiaryEdit';
import { l } from './BeneficiaryInfo';

interface Props {
  record: Beneficiary | undefined;
  dealId: string;
  counterparty: 'remitterData' |'senderData' | 'recipientData';
}

const BeneficiaryPersonalInfoEdit: FC<Props> = (props) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <BeneficiaryEdit heading={t('editPersonalInfo')} {...props}>
      <TextInput source="firstName" label={t('firstName')} fullWidth />
      <TextInput source="lastName" label={t('lastName')} fullWidth />
      <DateInput source="birthDate" label={t('birthDate')} fullWidth />
    </BeneficiaryEdit>
  );
};

export default BeneficiaryPersonalInfoEdit;
