import axios from '~/utils/axios';

export const getPartner = (id: string) => {
  return axios.get<void>(`/partners/${id}`);
};

export const getPartnerByExternalId = (externalId: string) => {
  return axios.get<void>(`/partners/byExternalId/${externalId}`);
};

export const closePartner = (id: string) => {
  return axios.post<void>(`/partners/${id}/close`);
};

export const getPartnerConfiguration = (partnerId: string) => {
  return axios.get<any>(`/partners/${partnerId}/configuration`);
};

export const setPartnerConfiguration = (partnerId: string, configuration: any) => {
  return axios.put<any>(`/partners/${partnerId}/configuration`, configuration);
};

export type IntegrationIdentityAction = {
  key: string;
  name: string;
};

export const listIdentityActions = (partnerId: string) => {
  return axios.get<IntegrationIdentityAction[]>(`/partners/${partnerId}/identity/actions`);
};
