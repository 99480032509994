import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import Accordion from '@material-ui/core/Accordion';

import { Deal } from '~/types/Deal';
import { AccordionSummary, AccordionDetails, H2 } from '~/components';
import BeneficiaryDetails from './BeneficiaryDetails';
import BeneficiaryPersonalInfo from './BeneficiaryPersonalInfo';
import { Section } from '~/layout';
import Grid from '@material-ui/core/Grid';
import CustomModule from '~/modules';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const l = (key: string) => `resources.deals.show.beneficiary.${key}`;

const BeneficiaryInfo: FC<any> = (props) => {
  const { record } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(l(key));

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !prevProps[key] }));
  };

  const deal = record as Deal;
  const remitterData = deal?.remitter?.senderData;
  const senderData = deal?.beneficiary?.senderData;
  const recipientData = deal?.beneficiary?.recipientData;
  const classes = useStyles();

  if (!record) return null;
  return (
    <div>
      <Section noPaddings>
        <Grid container spacing={2}>
          <Grid item>
            <H2 topSpacing={0} bottomSpacing={2}>
              {t('remitter')}
            </H2>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={2} className={classes.bottomSpacing}>
          <Grid item xs={12}>
            <Accordion expanded={!!expanded['remitter']} onChange={handleChange('remitter')}>
              <AccordionSummary
                expanded={!!expanded['remitter']}
                content={<BeneficiaryPersonalInfo record={remitterData} type='originatorData'/>}
                compact={true}
              />
              <AccordionDetails>
                <BeneficiaryDetails
                  record={remitterData}
                  dealId={deal.id}
                  counterparty='remitterData'
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Section>
      <Section noPaddings>
        <Grid container justify='space-between'>
          <Grid item>
            <H2 topSpacing={0} bottomSpacing={2}>
              {t('beneficiary')}
            </H2>
          </Grid>
          <Grid item id='section-actions'>
            <CustomModule name='EditDealDetails' record={record} />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container className={classes.bottomSpacing}>
          <Grid item xs={12}>
            <Accordion expanded={!!expanded['sender']} onChange={handleChange('sender')}>
              <AccordionSummary
                expanded={!!expanded['sender']}
                content={<BeneficiaryPersonalInfo record={senderData} type='originatorData'/>}
                compact={true}
              />
              <AccordionDetails>
                <BeneficiaryDetails
                  record={senderData}
                  dealId={deal.id}
                  counterparty='senderData'
                />
              </AccordionDetails>
            </Accordion>

            {recipientData && (
              <Accordion expanded={!!expanded['recipient']} onChange={handleChange('recipient')}>
                <AccordionSummary
                  expanded={!!expanded['recipient']}
                  content={<BeneficiaryPersonalInfo record={recipientData} type='recipientData'/>}
                  compact={true}
                />
                <AccordionDetails>
                  <BeneficiaryDetails
                    record={recipientData}
                    dealId={deal.id}
                    counterparty='recipientData'
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
}));

export default BeneficiaryInfo;
