import React, { useState, ChangeEvent } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import { Confirmation, EditButton, TextInput } from '~/components';
import Button from '@material-ui/core/Button';
import UndoIcon from '@material-ui/icons/Undo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HeadTransaction } from '~/types/HeadTransaction';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import { getTransaction } from '~/api/headTransactions';
import { createConsent as reversalCreateConsent } from '~/api/transactionReversal';
import { createTransaction as reversalCreateTransaction } from '~/api/transactionReversal';
import DetailsTab from '../DetailsTab';
import { Account } from '~/types/account';

interface Props {
  transaction: HeadTransaction;
  history?: any;
}

const ShowSuccess = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);
  return <Alert severity='success'>{t('revertSuccess')} </Alert>;
};

const ShowFailure = (props: any) => {
  const error = props.error;
  return <Alert severity='error'>{error}</Alert>;
};

const ShowDanger = (props: any) => {
  const { hasPartner } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);
  if (hasPartner) {
    return (
      <>
        <Alert severity='error'>{t('hasPartner')}</Alert>
        <br />
        <Alert severity='warning'>{t('revertDanger')}</Alert>
      </>
    );
  }
  return <Alert severity='warning'>{t('revertDanger')}</Alert>;
};

const checkForPartner = (transaction: HeadTransaction): Boolean => {
  const recipient: Account | undefined = transaction.accounts.find(
    (account) => account.id === transaction.recipient
  );
  const sender: Account | undefined = transaction.accounts.find(
    (account) => account.id === transaction.sender
  );
  const isRecipientPartner: boolean = recipient?.profileType === 'PARTNER';
  const isSenderPartner: boolean = sender?.profileType === 'PARTNER';
  return isRecipientPartner || isSenderPartner;
};

const ReversalAction = (props: Props) => {
  const { transaction, history } = props;
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  const [reversalConsent, setReversalConsent] = useState<HeadTransaction>();
  const [reversalOpen, setReversalOpen] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [reversalTransaction, setReversalTransaction] = useState<HeadTransaction>();
  const [reasonValue, setReasonValue] = useState<string>('');

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const hasPartner = checkForPartner(transaction);

  const onRevertTransaction = () => {
    console.log(`trying to revert transaction ${transaction.id}`);
    setError(undefined);
    setInProgress(true);
    setReversalOpen(true);
    reversalCreateConsent({ transactionId: transaction.id })
      .then(({ data: consent }) => {
        console.log('consent created:', consent);
        setReversalConsent(consent);
        setInProgress(false);
      })
      .catch((e) => {
        let errorText = e?.body?.message || 'Unknown error occurred';
        setError(errorText);
        setInProgress(false);
      });
  };

  const checkForTransaction = (pendingTransaction: HeadTransaction) => {
    getTransaction({
      id: pendingTransaction.id,
    })
      .then(() => {
        setReversalTransaction(pendingTransaction);
        setInProgress(false);
      })
      .catch(() => {
        setTimeout(() => {
          checkForTransaction(pendingTransaction);
        }, 2000);
      });
  };

  const onConfirmReversal = () => {
    if (reversalConsent) {
      console.log(`confirm reversal consent ${reversalConsent.id}`);
      setInProgress(true);
      reversalCreateTransaction({ consentId: reversalConsent.id, reason: reasonValue })
        .then(({ data: transaction }) => {
          console.log('transaction created:', transaction);
          setTimeout(() => {
            checkForTransaction(transaction);
          }, 2000);
        })
        .catch((e) => {
          const errorText = e?.response?.data?.message || 'Unknown error occurred';
          setError(errorText);
          setInProgress(false);
        });
      setReasonValue('');
    }
  };

  const isReversalAllowed =
    (transaction.status === 'COMPLETED' || transaction.status === 'APPROVED') &&
    transaction.operationType !== 'REVERSAL' &&
    transaction.operationType !== 'FORCED_APPROVAL' &&
    !transaction.details.reversalTransaction;

  return (
    <>
      {isReversalAllowed && (
        <EditButton
          onClick={onRevertTransaction}
          label={t('revert')}
          icon={<UndoIcon />}
          disabled={!permissions?.includes('headTransaction.revert')}
          red
        />
      )}
      <Dialog
        open={reversalOpen}
        onClose={() => {
          setReversalOpen(false);
        }}
        aria-labelledby='reversal-dialog-title'
        aria-describedby='reversal-dialog-description'
        fullWidth
        maxWidth={inProgress || reversalTransaction || error ? 'xs' : 'lg'}
      >
        <DialogTitle id='reversal-dialog-title'>
          {inProgress || reversalTransaction || error ? (
            t('revertDialogTitle')
          ) : (
            <ShowDanger hasPartner={hasPartner} />
          )}
        </DialogTitle>
        <DialogContent>
          {inProgress ? (
            <Box mt={1} mb={3}>
              <LinearProgress />
            </Box>
          ) : error ? (
            <ShowFailure error={error} />
          ) : reversalTransaction ? (
            <ShowSuccess />
          ) : reversalConsent ? (
            <DetailsTab record={reversalConsent} />
          ) : null}
        </DialogContent>
        {!inProgress && (
          <DialogActions>
            {error ? (
              <Button
                onClick={() => {
                  setReversalOpen(false);
                }}
              >
                {t(`closeDialog`)}
              </Button>
            ) : reversalTransaction ? (
              <>
                <Button
                  onClick={() => {
                    setReversalOpen(false);
                  }}
                >
                  {t(`closeDialog`)}
                </Button>
                <Button
                  onClick={() => {
                    history && history.push(`/headTransactions/${reversalTransaction.id}/show`);
                  }}
                  color='primary'
                >
                  {t(`goToTransaction`)}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setReversalOpen(false);
                  }}
                >
                  {t('cancel')}
                </Button>
                <Confirmation
                  onConfirm={onConfirmReversal}
                  confirmButtonProps={{ disabled: reasonValue.length === 0 }}
                  confirmationSettings={{
                    variant: 'modal',
                    modal: {
                      content: (
                        <>
                          <ShowDanger hasPartner={hasPartner} />
                          <br />
                          <TextInput
                            value={reasonValue}
                            onChange={handleReasonChange}
                            multiline
                            rows={5}
                            rowsMax={20}
                            required
                            label={t('reason')}
                          />
                        </>
                      ),
                    },
                  }}
                >
                  <Button color='primary'>{t('confirm')}</Button>
                </Confirmation>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ReversalAction;
