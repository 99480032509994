import { useQuery } from 'react-query';
import { getFeedback, getFeedbackStatistic } from '~/api/feedback';
import { FeedbackFilters, FeedbackStatisticFilters } from '~/types/Feedback';

export const useFeedbackList = (filters: FeedbackFilters) => {
  return useQuery(['feedback'], () => getFeedback(filters).then((res) => res.data), {
    // staleTime: Infinity,
  });
};

export const useFeedbackStatistic = (
  filters: FeedbackStatisticFilters,
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['feedback', 'statistic', filters.groupId],
    () => getFeedbackStatistic(filters).then((res) => res.data),
    options
  );
};
