import React from 'react';
import { useTranslate } from 'react-admin';

import { DivLayout, HorizontalLabeledField } from '~/components/ra';

const GeolocationField = (props: any) => {
  const { record, source } = props;

  const translate = useTranslate();
  const label = (key: string): string => translate(`components.ra.inputs.GeolocationInput.${key}`);

  return (
    <DivLayout record={record}>
      <HorizontalLabeledField source={`${source}.latitude`} label={label('latitude')} />
      <HorizontalLabeledField source={`${source}.longitude`} label={label('longitude')} />
    </DivLayout>
  );
};

GeolocationField.defaultProps = {
  source: 'geolocation',
  addLabel: true,
  label: 'components.ra.inputs.GeolocationInput._',
};

export default GeolocationField;
