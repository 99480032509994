import React from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import { JSONSchemaType } from 'ajv';

import { CountryInput } from '.';
import { BooleanInput } from 'react-admin';

interface Props {
  data: any;
  schema: JSONSchemaType<Record<string, any>>;
  exlude?: string[];
  className?: string;
}

const JsonSchemaForm = (props: Props) => {
  const { data, schema, exlude, className } = props;

  const properties: any = schema.properties || {};

  const propertyItems: JSX.Element[] = [];

  Object.keys(properties)
    .filter((propertyKey) => !(exlude || []).includes(propertyKey))
    .forEach((propertyKey) => {
      const property: any = properties[propertyKey];
      switch (property.type) {
        case 'object':
        case 'document':
          break;
        default: {
          console.log(`schema.required: `, schema.required);
          const isRequired = (schema.required as string[])?.includes(propertyKey);

          let input: JSX.Element | undefined = undefined;
          switch (property.type) {
            case 'boolean': {
              input = (
                <BooleanInput
                  key={propertyKey}
                  label={property.title}
                  source={propertyKey}
                  record={data}
                  validate={isRequired && required()}
                  disabled={property.disabled}
                />
              );
              break;
            }
            default: {
              if (property.enum) {
                const names = property.enumNames || property.enum;
                const choices = [];
                for (let i = 0; i < property.enum.length && i < names.length; ++i) {
                  choices.push({ id: property.enum[i], name: names[i] });
                }
                input = (
                  <SelectInput
                    key={propertyKey}
                    label={property.title}
                    source={propertyKey}
                    record={data}
                    choices={choices}
                    validate={isRequired && required()}
                    disabled={property.disabled}
                  />
                );
              } else {
                switch (property.format) {
                  case 'country':
                    input = (
                      <CountryInput
                        key={propertyKey}
                        label={property.title}
                        source={propertyKey}
                        fullWidth
                        record={data}
                        disabled={property.disabled}
                      />
                    );
                    break;
                  case 'solution-country':
                    input = (
                      <CountryInput
                        key={propertyKey}
                        label={property.title}
                        source={propertyKey}
                        record={data}
                        fullWidth
                        solutionId={data.solutionId}
                        disabled={property.disabled}
                      />
                    );
                    break;
                  default:
                    input = (
                      <TextInput
                        key={propertyKey}
                        label={property.title}
                        source={propertyKey}
                        record={data}
                        validate={isRequired && required()}
                        disabled={property.disabled}
                      />
                    );
                    break;
                }
              }
              break;
            }
          }
          if (input) {
            propertyItems.push(input);
          }
          break;
        }
      }
    });

  return <div className={className}>{propertyItems}</div>;
};

export default JsonSchemaForm;
