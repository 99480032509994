import axios from '~/utils/axios';

export const updateBeneficiaryData = (params: {
  dealId: string;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
  data: any;
}) => {
  const { dealId, counterparty, data } = params;
  return axios
    .put<void>(`/deals/${dealId}/beneficiary/${counterparty}`, data)
    .then((res) => res.data);
};
