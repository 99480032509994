export default {
  P2P_TRANSFER: 'P2P Transfer',
  PURCHASE: 'Purchase',
  REFUND: 'Refund',
  CASH_OUT: 'Cash-out',
  CASH_IN: 'Cash-in',
  MEMBERSHIP: 'Membership',
  BILL_PAYMENT: 'Outgoing transfer',
  TENANT_FEE: 'Fee',
  TOP_UP: 'Account Topup',
  CASH_DEPOSIT: 'Cash Deposit',
  CASH_WITHDRAWAL: 'Cash Withdrawal',
  OWN_ACCOUNTS_TRANSFER: 'Own Account Transfer',
  PAYOUT: 'Payout',
  REMITTANCE: 'Remittance',
  REMITTANCE_PAYOUT: 'Remittance Payout',
  REVERSAL: 'Reversal',
  SERVICE_CHARGE: 'Service Charge',
  CURRENCY_CONVERSION: 'Currency Conversion',
  CURRENCY_CONVERSION_PROFIT: 'Currency Conversion Profit',
  SETTLEMENT: 'Settlement',
  LOYALTY_TRANSFER: 'Loyalty Transfer',
  WRITE_OFF: 'Write Off',
  ACCOUNT_TRANSFER: 'Account Transfer',
  FORCED_APPROVAL: 'Forced Approval',
  INCOMING_TRANSFER: 'Incoming Transfer',
  ACQUIRING_FEE: 'Acquiring Fee',
};
