import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import CurrenciesList from './CurrenciesList';
import CurrencySetup from './CurrencySetup';

const CurrenciesConvesion: FC<any> = (props) => {
  return (
    <Switch>
      <Route path="/fxrates" exact>
        <CurrenciesList {...props} />
      </Route>
      <Route path="/fxrates/setup">
        <CurrencySetup {...props} />
      </Route>
    </Switch>
  );
};

export default { list: CurrenciesConvesion };
