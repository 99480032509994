import { capitalize } from 'lodash';
import React from 'react';
import { useTranslate } from 'react-admin';

import { TextField } from '~/components';
import { Account } from '~/types';
import { CellProps } from './CellProps';

const ListAccountField = ({ children, style }: CellProps) => {
  const account: Account = children as Account;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  return (
    <div style={style}>
      <TextField compact label={capitalize(`${account.profileType} ${t('account')}`)}>
        {account.number}
      </TextField>
    </div>
  );
};

export default ListAccountField;
