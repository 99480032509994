import React, { Fragment, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Grid } from '@material-ui/core';

import { Drawer, StatusField } from '~/components';
import { EditForm, ModalOrSelectInput, ReferenceInput } from '~/components/ra';
import { NavigateIcon } from '~/img';
import { Location, Terminal } from '~/types';
import { Longdash } from '~/utils';
import { sanitize } from '../TerminalEdit';
interface Props {
  terminalRecord: Terminal;
  businessId: string;
  permissions: string[];
}

const SetLocationDrawer = (props: Props) => {
  const { terminalRecord, businessId, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);

  return (
    <Fragment>
      <Button
        label={t('setLocation')}
        onClick={handleOpen}
        disabled={!permissions.includes('business.terminal.update')}
      >
        <SvgIcon>
          <NavigateIcon />
        </SvgIcon>
      </Button>
      <Drawer
        heading={`${t('setLocationFor')} "${terminalRecord.label}"`}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource="terminals" record={sanitize(terminalRecord)}>
          <ReferenceInput
            source="locationId"
            reference="locations"
            filter={{ businessId }}
            allowEmpty
            emptyText={Longdash}
            label={t('location')}
          >
            <ModalOrSelectInput<Location> optionText="name" fullWidth>
              {(item) => {
                return (
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <StatusField status={item.status} variant="dot" />
                    </Grid>
                    <Grid item>{item.name}</Grid>
                  </Grid>
                );
              }}
            </ModalOrSelectInput>
          </ReferenceInput>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default SetLocationDrawer;
