import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import ProductForm from './ProductForm';
import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import { Solution } from '~/types';
import { productLabel as l } from './';

interface Props {
  solutionRecord: Solution;
  permissions: string[];
}

const ProductCreate = (props: Props) => {
  const { solutionRecord, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        onClick={handleOpen}
        variant="add"
        disabled={!permissions.includes('solution.product.create')}
      />
      <Drawer heading={t('addProduct')} open={open} onClose={handleClose}>
        <CreateForm
          resource="products"
          target="solutions"
          id={solutionRecord.id}
          record={{ solutionId: solutionRecord?.id }}
          closeParent={handleClose}
        >
          <ProductForm solutionRecord={solutionRecord} />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default ProductCreate;
