import React from 'react';
import { TextInput, required } from 'react-admin';

interface JsonInputProps {
  label: string;
  source: string;
  validationError: string;
  initialValue?: string;
  required?: boolean;
  rows?: number;
}

const JsonInput = (props: JsonInputProps) => {
  const { label, source, validationError, initialValue, required: isRequired, rows } = props;

  const validate = [];
  if (isRequired) {
    validate.push(required());
  }
  validate.push((v: string) => {
    if (v && typeof v !== 'object') {
      try {
        JSON.parse(v);
        return '';
      } catch (e) {
        return validationError;
      }
    }
  });

  return (
    <TextInput
      multiline
      label={label}
      source={source}
      initialValue={initialValue}
      validate={validate}
      format={(v: any) => {
        if (typeof v === 'object') {
          return JSON.stringify(v, null, 2);
        } else if (!v) {
          return null;
        }
        return v;
      }}
      rows={rows}
    />
  );
};

export default JsonInput;
