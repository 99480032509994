import { PaymentGroup, PaymentService, PaymentServiceReference } from '~/types/paymentServices';
import axios from '~/utils/axios';

export const deletePaymentService = (id: string) => {
  return axios.delete<void>(`/paymentServices/${id}`).then((res) => res.data);
};

export const deletePaymentGroup = (id: string) => {
  return axios.delete<void>(`/paymentServices/groups/${id}`).then((res) => res.data);
};

export const getAllGroups = () => {
  return axios.get<PaymentGroup[]>(`/paymentServices/groups?page=1&perPage=9999`);
};

export const getAllServices = () => {
  return axios.get<PaymentService[]>(`/paymentServices?page=1&perPage=9999`);
};

export const getService = (serviceId: string, forPublicApi: boolean = false) => {
  return axios.get<PaymentService>(`/paymentServices/${serviceId}?forPublicApi=${forPublicApi}`);
};

export const getReferencesByService = (serviceId: string) => {
  return axios.get<PaymentServiceReference[]>(`/paymentServices/${serviceId}/references`);
};

export const getReferencesByGroup = (groupId: string) => {
  return axios.get<PaymentServiceReference[]>(`/paymentServices/groups/${groupId}/references`);
};

export const deleteReference = (serviceId: string, groupId: string) => {
  return axios
    .delete<void>(`/paymentServices/references/${groupId}/${serviceId}`)
    .then((res) => res.data);
};

export const createReference = (serviceId: string, groupId: string) => {
  return axios
    .post<PaymentServiceReference>(`/paymentServices/references`, {
      serviceId,
      groupId,
    })
    .then((res) => res.data);
};
