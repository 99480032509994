import React, { Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
  ResourceListType,
} from '~/ducks/virtualizedList';
import { Business, Column, Individual, RootState } from '~/types';
import CustomCell from '~/resources/events/components/CustomCell';
import { EventType } from '~/types/Event';
import { useTranslateEventsList, resource } from '~/resources/events';
import { getBusinessesEvents, getIndividualEvents } from '~/api/events';
import { Paper } from '@material-ui/core';

interface Props {
  objectType: 'individual' | 'business';
  object: Individual | Business | undefined;
}

const EventLogKYC = (props: Props) => {
  const { objectType, object } = props;
  const dispatch = useDispatch();

  const { data, hasNextPage, isNextPageLoading } = useSelector<
    RootState,
    ResourceListType<EventType>
  >(selectors.getList(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const t = useTranslateEventsList();

  const requestParams = {
    next: cursors.next || undefined,
    timestampFrom: object?.createdAt,
    topic: `${objectType}.check.`,
  };
  const objectId = object?.id;
  const loadNextPage = async () => {
    if (isNextPageLoading) return;
    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } =
        objectType === 'individual' && objectId
          ? await getIndividualEvents(objectId, requestParams)
          : objectType === 'business' && objectId
          ? await getBusinessesEvents(objectId, requestParams)
          : { data: { cursors: {}, events: [] } };
      dispatch(
        getVirtualizedListSuccess({
          resource,
          responseData: { cursors: data.cursors, records: data.events },
          queryToken,
        })
      );
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns = useMemo<Column<EventType>[]>(
    () => [
      {
        selectCellData: (rowData) => rowData.timestamp,
        cellDataWrapperComponent: CustomCell,
        id: 'createdAt',
        header: t('timestamp'),
        style: {
          flexBasis: 100,
          minWidth: 100,
          maxWidth: 150,
        },
      },
      {
        selectCellData: (rowData) => rowData.topic,
        cellDataWrapperComponent: CustomCell,
        id: 'topic',
        header: t('topic'),
        style: {
          flexBasis: 300,
          minWidth: 300,
        },
      },
      {
        selectCellData: (rowData) => rowData.tags,
        cellDataWrapperComponent: CustomCell,
        id: 'status',
        header: t('status'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 200,
        },
      },
    ],
    [t]
  );

  const getRowLink = (data: EventType) => {
    return `/events/${data.id}/show`;
  };

  return (
    <Fragment>
      <Paper>
        <VirtualizedList<EventType>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          data={data}
          loadNextPage={loadNextPage}
          outterTable
          rowHeight={48}
          emptyText={t('empty')}
          getRowLink={getRowLink}
          openLinkInNewTab
        />
      </Paper>
    </Fragment>
  );
};

export default EventLogKYC;
