import React, { FC, useState } from 'react';
import { TextInput } from 'react-admin';
import { SelectInput, required, useTranslate } from 'react-admin';
import { Grid, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import { CountryInput, CreateForm, DocumentTypeSelect, ReferenceInput } from '~/components/ra';
import FullscreenDrawer from '~/components/FullscreenDrawer';
import { Section } from '~/layout';
import BusinessLegalTypeInput from './BusinessLegalTypeInput';
import { history } from '~/App';
import { Alert, AlertTitle } from '@material-ui/lab';
import onError from '~/errorsHandler';

interface Props {
  open: boolean;
  onClose: () => void;
}

const BusinessCreateDrawer: FC<Props> = (props) => {
  const { open, onClose } = props;

  const [isAlreadyRegistered, setAlreadyRegistered] = useState(false);

  const resetAlreadyRegistered = () => {
    isAlreadyRegistered && setAlreadyRegistered(false);
  };

  const handleError = (error: any) => {
    const code: string | undefined = error?.body?.code;
    const message: string | undefined = error?.body?.message;
    if (code === 'PARAMETER_INVALID' && message?.includes('primaryDocumentNumber')) {
      setAlreadyRegistered(true);
    } else {
      onError(error);
    }
  };

  const handleClose = () => {
    onClose();
    resetAlreadyRegistered();
  };

  const t = useTranslateBusinessCreate();
  const classes = useStyles();

  return (
    <FullscreenDrawer
      open={open}
      onClose={handleClose}
      heading={t('createNewProfile')}
      subtitle={t('enterThePrimaryDetails')}
    >
      <CreateForm
        resource="businesses"
        onSuccess={(res: any) => history.push(`/businesses/${res.data.id}/show/profile`)}
        onError={handleError}
        style={fullWidth}
        classes={classes}
      >
        <BusinessForm
          isAlreadyRegistered={isAlreadyRegistered}
          resetAlreadyRegistered={resetAlreadyRegistered}
        />
      </CreateForm>
    </FullscreenDrawer>
  );
};

const BusinessForm: FC<{ isAlreadyRegistered: boolean; resetAlreadyRegistered: () => void }> = (
  props
) => {
  const { isAlreadyRegistered, resetAlreadyRegistered } = props;
  const formValues = useFormState().values;
  const tc = useTranslateBusinessCreate();
  const t = useTranslateBusinessFields();

  const secondFormActive = formValues.solutionId && formValues.country;

  return (
    <>
      <Grid container>
        <Stepper nonLinear orientation="vertical" style={fullWidth}>
          <Step active expanded>
            <StepLabel>{tc('1row')}</StepLabel>`
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ReferenceInput
                    source="solutionId"
                    reference="solutions"
                    filter={{
                      hasFeatures: ['veengu_business_onboarding_dashboard'],
                    }}
                    validate={required()}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <CountryInput
                    solutionId={formValues.solutionId}
                    source="country"
                    validate={required()}
                    label={t('country')}
                    disabled={!formValues.solutionId}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
          <Step active={secondFormActive} expanded={secondFormActive}>
            <StepLabel>{tc('2row')}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BusinessLegalTypeInput
                    solutionId={formValues.solutionId}
                    country={formValues.country}
                    source="legalType"
                    validate={required()}
                    label={t('legalType')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    source="legalName"
                    validate={required()}
                    disabled={!formValues.legalType}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <DocumentTypeSelect
                    source={'primaryDocument.type'}
                    label={t('primaryDocument.type')}
                    record={formValues}
                    country={formValues.country}
                    solutionId={formValues.solutionId as string}
                    legalType={formValues.legalType}
                    disabled={
                      !formValues.country || !formValues.solutionId || !formValues.legalType
                    }
                    validate={required()}
                    onlyPrimary
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    source="primaryDocument.number"
                    label={t('primaryDocument.number')}
                    validate={required()}
                    disabled={!formValues.primaryDocument?.type}
                    fullWidth
                    onChange={resetAlreadyRegistered}
                    options={
                      isAlreadyRegistered
                        ? {
                            error: isAlreadyRegistered,
                          }
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
      {isAlreadyRegistered && (
        <Section>
          <Alert severity="error">
            <AlertTitle>{tc('alreadyRegistered')}</AlertTitle>
            {tc('youCantCreateProfile')}
          </Alert>
        </Section>
      )}
    </>
  );
};

const useTranslateBusinessCreate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.businesses.create.${key}`);
};
const useTranslateBusinessFields = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.businesses.fields.${key}`);
};

const fullWidth = { width: '100%' };

const useStyles = makeStyles((theme) => ({
  bottomToolbar: {
    marginLeft: theme.spacing(3),
  },
}));

export default BusinessCreateDrawer;
