export default {
  firstName: 'First name',
  lastName: 'Last name',
  addNames: 'Additional names',
  phoneNumber: 'Phone',
  email: 'Email',
  birthDate: 'Birth date',
  gender: {
    _: 'Gender',
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
  },
  documents: {
    _: 'ID documents & scans',
    type: 'Document type',
    number: 'Document number',
    scans: 'Images',
  },
  addresses: {
    _: 'Addresses',
    type: 'Type',
    country: 'Country',
    postalCode: 'Postal code',
    state: 'State / Region / Province',
    city: 'City',
    address: 'Address lines',
  },
};
