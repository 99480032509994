import React, { useState, Fragment, FC } from 'react';
import { useTranslate, SelectInput } from 'react-admin';
import { Card, CardDetails } from '~/types/Card';

import { Drawer, EditButton } from '~/components';
import CreateAndEditForm from '../CreateAndEditForm';
import { l } from './index';
import { useApi } from '~/hooks';
import { getDetails } from '~/api/cards';

interface CardEditStatusProps {
  refetch?: () => void;
  record: Card | undefined;
}

const CardEditStatus: FC<CardEditStatusProps> = (props) => {
  const { /* refetch: refetchCard, */ record } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const tStatus = (key: string): string => translate(`components.ra.fields.StatusField.${key}`);

  const [{ data: details /*,  */ }, refetch] = useApi<CardDetails>(() => {
    return record ? getDetails(record.id) : Promise.resolve([{}]);
  });

  return (
    <Fragment>
      <EditButton label={t('setStatus')} onClick={handleOpen} />
      <Drawer heading={t('editCardDetails')} open={open} onClose={handleClose}>
        <CreateAndEditForm
          type="update"
          resource="cards"
          subresource="status"
          record={record}
          closeParent={handleClose}
          updateAfterSubmit={refetch}
          parseRecordBeforeSubmit={(r: any) => r.status}
        >
          <Fragment>
            <SelectInput
              source="status"
              choices={details?.capabilities.setStatus.map((status) => ({
                id: status,
                name: tStatus(status),
              }))}
              label={t('status')}
            />
          </Fragment>
        </CreateAndEditForm>
      </Drawer>
    </Fragment>
  );
};

export default CardEditStatus;
