import axios from '~/utils/axios';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Amount } from '~/types';

export interface CreateOrUpdateConsentRequest {
  senderAccountNumber: string;
  recipientAccountNumber: string;
  amount: Amount;
  comment?: string;
  referenceId?: string;
}

export const createConsent = (request: CreateOrUpdateConsentRequest) => {
  const url = `/accountTransfer/consent`;
  return axios.post<HeadTransaction>(url, request);
};

export const updateConsent = (consentId: string, request: CreateOrUpdateConsentRequest) => {
  const url = `/accountTransfer/consent/${consentId}`;
  return axios.put<HeadTransaction>(url, request);
};

interface CreateTransactionProps {
  consentId: string;
}

export const createTransaction = ({ consentId }: CreateTransactionProps) => {
  const url = `/accountTransfer`;
  return axios.post<HeadTransaction>(url, { consentId });
};
