import React, { Fragment } from 'react';
import { useTranslate, TextInput, SelectInput, FormDataConsumer, required } from 'react-admin';

import { CurrencySelect, ReferenceInput } from '~/components/ra';
import { Solution } from '~/types';
import { isEmpty } from 'lodash';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import { useAccountNumberingList } from '~/hooks/useAccountNumberingList';

export const l = (key: string): string => `resources.solutions.products.${key}`;

interface Props {
  solutionRecord: Solution;
  edit?: boolean;
}

const ProductForm = (props: Props) => {
  const { solutionRecord, edit } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { data: productTemplates } = useProductTemplates();

  const productTemplateOptions = (() => {
    const solutionProductTemplates: string[] = solutionRecord?.productTemplates || [];
    const options = productTemplates
      ?.filter((pt) => !!solutionProductTemplates.find((code) => pt.code === code))
      .map((pt) => ({ id: pt.code, name: pt.alias }));

    if (!isEmpty(options)) {
      return options;
    } else {
      return [{ id: undefined, name: t('noData') }];
    }
  })();

  const { data: accountNumberingOptions } = useAccountNumberingList();

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        return (
          <Fragment>
            <TextInput source="name" label={t('name')} validate={required()} />
            {!edit && (
              <SelectInput
                source="templateCode"
                choices={productTemplateOptions}
                label={t('template')}
                validate={required()}
              />
            )}
            {!edit && (
              <ReferenceInput
                source="bankAccountId"
                reference="bank/accounts"
                label={t('bankAccount')}
                allowEmpty
                // validate={required()}
              >
                <SelectInput
                  optionText={(item: any) => (
                    <>
                      <span style={{ width: '100%', display: 'table-cell' }}>{item.alias}</span>
                      <span
                        style={{
                          opacity: 0.5,
                          display: 'table-cell',
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.currency}
                      </span>
                    </>
                  )}
                  allowEmpty
                  emptyText={'—'}
                />
              </ReferenceInput>
            )}
            <CurrencySelect
              source="currency"
              label={l('currency')}
              record={formData}
              solutionId={solutionRecord.id}
              validate={required()}
              fullWidth
            />
            <SelectInput
              source="accountNumbering"
              choices={accountNumberingOptions}
              label={t('accountNumbering')}
              validate={required()}
            />
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default ProductForm;
