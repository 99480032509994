import { createAction, handleActions } from 'redux-actions';
import { RootState, AppThunk } from '~/types';
import {
  Note,
  getNotesReq,
  createNoteReq,
  updateNoteReq,
  deleteNoteReq,
  NotesProfileType,
} from '~/api/notes';
import { notification } from './snackbarNotifications';
import { updateEventLog } from '~/ducks/eventLog';

const GET_NOTES_BEGIN = 'get/notes/begin';
const GET_NOTES_SUCCESS = 'get/notes/success';
const GET_NOTES_FAIL = 'get/notes/fail';

const CREATE_NOTE_BEGIN = 'create/note/begin';
const CREATE_NOTE_SUCCESS = 'create/note/success';
const CREATE_NOTE_FAIL = 'create/note/fail';

const UPDATE_NOTE_BEGIN = 'update/note/begin';
const UPDATE_NOTE_SUCCESS = 'update/note/success';
const UPDATE_NOTE_FAIL = 'update/note/fail';

const DELETE_NOTE_BEGIN = 'delete/note/begin';
const DELETE_NOTE_SUCCESS = 'delete/note/success';
const DELETE_NOTE_FAIL = 'delete/note/fail';

type GetNotesPayloadType = {
  referenceId: string;
  notes: Note[];
};
type NoteAndTempIdType = { note: Note; tempId: string };

export const getNotesBegin = createAction(GET_NOTES_BEGIN);
export const getNotesSuccess = createAction<GetNotesPayloadType>(GET_NOTES_SUCCESS);
export const getNotesFail = createAction(GET_NOTES_FAIL);

export const createNoteBegin = createAction<Note>(CREATE_NOTE_BEGIN);
export const createNoteSuccess = createAction<NoteAndTempIdType>(CREATE_NOTE_SUCCESS);
export const createNoteFail = createAction<Note>(CREATE_NOTE_FAIL);

export const updateNoteBegin = createAction<Note>(UPDATE_NOTE_BEGIN);
export const updateNoteSuccess = createAction<Note>(UPDATE_NOTE_SUCCESS);
export const updateNoteFail = createAction<Note>(UPDATE_NOTE_FAIL);

type DeleteNotePayloadType = {
  id: string;
  referenceId: string;
};
type DeleteNoteFailPayloadType = {
  oldNote: Note;
  referenceId: string;
};
export const deleteNoteBegin = createAction<DeleteNotePayloadType>(DELETE_NOTE_BEGIN);
export const deleteNoteSuccess = createAction<void>(DELETE_NOTE_SUCCESS);
export const deleteNoteFail = createAction<DeleteNoteFailPayloadType>(DELETE_NOTE_FAIL);

export type State = {
  notes: {
    [referenceId: string]: Note[] | undefined;
  };
  isFetching: boolean;
};

const initialState: State = {
  notes: {},
  isFetching: false,
};

interface GetNotesProps {
  referenceId: string;
  profileType: NotesProfileType;
}

export const getNotes = ({
  referenceId,
  profileType,
}: GetNotesProps): AppThunk<GetNotesPayloadType> => {
  return async (dispatch) => {
    dispatch(getNotesBegin());
    try {
      const { data } = await getNotesReq(referenceId, profileType);
      dispatch(
        getNotesSuccess({
          referenceId,
          notes: data,
        })
      );
    } catch (error) {
      dispatch(getNotesFail());
      notification({
        severity: 'error',
        message: error.message,
      });
    }
  };
};

export const createNote = (
  note: Note,
  referenceId: string,
  profileType: NotesProfileType
): AppThunk<Note | NoteAndTempIdType | number> => {
  const tempId = new Date().getTime().toString();
  const noteWithTimestamp = { ...note, id: tempId };
  return async (dispatch) => {
    dispatch(createNoteBegin(noteWithTimestamp));
    try {
      const { data } = await createNoteReq(note, referenceId, profileType);
      dispatch(createNoteSuccess({ note: data, tempId }));
      dispatch(updateEventLog());
    } catch (error) {
      dispatch(createNoteFail(noteWithTimestamp));
      dispatch(
        notification({
          severity: 'error',
          message: error.message,
        })
      );
    }
  };
};

export const updateNote = (note: Note, profileType: NotesProfileType): AppThunk<Note | number> => {
  const referenceId = note.referenceId;
  return async (dispatch, getState) => {
    const oldNote = getState().notes.notes[referenceId]?.find((item) => item.id === note.id);
    dispatch(updateNoteBegin(note));
    try {
      const { updatedAt, updatedBy, updatedName, ...noteBase } = note; // eslint-disable-line
      const { data } = await updateNoteReq(noteBase, referenceId, profileType);
      dispatch(updateNoteSuccess(data));
      dispatch(updateEventLog());
    } catch (error) {
      dispatch(updateNoteFail(oldNote as Note));
      dispatch(
        notification({
          severity: 'error',
          message: error.message,
        })
      );
    }
  };
};

interface DeleteNote {
  id: string;
  referenceId: string;
  profileType: NotesProfileType;
}

export const deleteNote = ({ id, referenceId, profileType }: DeleteNote): AppThunk<any> => {
  return async (dispatch, getState) => {
    const oldNote = getState().notes.notes[referenceId]?.find((item) => item.id === id) as Note;
    dispatch(deleteNoteBegin({ id, referenceId }));
    try {
      await deleteNoteReq(id, referenceId, profileType);
      dispatch(deleteNoteSuccess());
      dispatch(updateEventLog());
    } catch (error) {
      dispatch(deleteNoteFail({ oldNote, referenceId }));
      dispatch(
        notification({
          severity: 'error',
          message: error.message,
        })
      );
    }
  };
};

export default handleActions<State, any>(
  {
    [GET_NOTES_BEGIN]: (state) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_NOTES_SUCCESS]: (state, { payload }: { payload: GetNotesPayloadType }) => {
      const { referenceId, notes } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: notes,
        },
        isFetching: false,
      };
    },
    [GET_NOTES_FAIL]: (state) => {
      return {
        ...state,
        isFetching: false,
      };
    },

    [CREATE_NOTE_BEGIN]: (state, { payload }: { payload: Note }) => {
      const { referenceId } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: [payload, ...(state.notes[referenceId] || [])],
        },
        isFetching: true,
      };
    },
    [CREATE_NOTE_SUCCESS]: (state, { payload }: { payload: NoteAndTempIdType }) => {
      const { note, tempId } = payload;
      const referenceId = note.referenceId;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.map((item) => {
            if (item.id === tempId) return note;
            return item;
          }),
        },
        isFetching: false,
      };
    },
    [CREATE_NOTE_FAIL]: (state, { payload }: { payload: Note }) => {
      const tempId = payload.id;
      const referenceId = payload.referenceId;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.filter((item) => item.id !== tempId),
        },
        isFetching: false,
      };
    },

    [UPDATE_NOTE_BEGIN]: (state, { payload }: { payload: Note }) => {
      const { referenceId } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.map((item) => {
            if (item.id === payload.id) return payload;
            else return item;
          }),
        },
        isFetching: true,
      };
    },
    [UPDATE_NOTE_SUCCESS]: (state, { payload }: { payload: Note }) => {
      const { referenceId } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.map((item) => {
            if (item.id === payload.id) return payload;
            else return item;
          }),
        },
        isFetching: false,
      };
    },
    [UPDATE_NOTE_FAIL]: (state, { payload }: { payload: Note }) => {
      const { referenceId } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.map((item) => {
            if (item.id === payload.id) return payload;
            else return item;
          }),
        },
        isFetching: false,
      };
    },

    [DELETE_NOTE_BEGIN]: (state, { payload }: { payload: DeleteNotePayloadType }) => {
      const { referenceId, id } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: state.notes[referenceId]?.filter((item) => item.id !== id),
        },
        isFetching: true,
      };
    },
    [DELETE_NOTE_SUCCESS]: (state) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [DELETE_NOTE_FAIL]: (state, { payload }: { payload: DeleteNoteFailPayloadType }) => {
      const { referenceId, oldNote } = payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [referenceId]: [oldNote, ...(state.notes[referenceId] || [])],
        },
        isFetching: false,
      };
    },
  },
  initialState
);

export const selectors = {
  getNotes: (referenceId: string) => (state: RootState) => ({
    notes: state.notes.notes[referenceId],
    loading: state.notes.isFetching,
  }),
};
