import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { cleanupFormValues, time } from '~/utils';

import { TextInput, AutocompleteArrayTextInput, SelectInput } from '~/components';
import { selectors, filterChange, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { resource } from '../index';
import { EventFilters } from '~/types/Event';
import { useTranslate } from 'react-admin';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { useApi } from '~/hooks';
import { getTaskTypes } from '~/api/events';
import { isEmpty } from 'lodash';

const TASK_STATES = [
  'INITIAL',
  'IN_PROGRESS',
  'RETRY_IS_SCHEDULED',
  'DONE',
  'FAILED',
  'CANCELLED',
  'ERROR',
];

interface Props {
  cleanFilters?: boolean;
}

const Filters = (props: Props) => {
  const { cleanFilters = true } = props;
  const dispatch = useDispatch();

  const filters = useSelector(selectors.getFilters<EventFilters>(resource));
  console.log(`rerender; filters:`, filters);

  const [localValues, setLocalValues] = useState(filters);

  useEffect(() => {
    console.log(`set local values:`, filters);
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment | string[] | undefined) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const [{ data: taskTypes, loading: taskTypesLoading }] = useApi<string[]>(() => {
    return getTaskTypes();
  });

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);
  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const filtersAreEmpty = isEmpty(filters);
  const withFilters = !!window.location.search;
  useEffect(() => {
    if (filtersAreEmpty || (cleanFilters && !withFilters)) {
      console.log(`clean; withFilters: ${withFilters}`);
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            timestampFrom: time().subtract(1, 'months'),
            timestampTo: time(),
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty, withFilters]);

  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.events.list.filters.${key}`);

  const taskStateOptions = TASK_STATES.map((state) => {
    return {
      value: state,
      text: translate(`eventProcessingState.${state}`),
    };
  });

  console.log(`localValues.objectId=${localValues.objectId}`);

  return (
    <Fragment>
      {filters && (
        <div className={classes.root}>
          <div>
            <div className={classes.inputsGroup}>
              <DateTimePicker
                className={classes.input}
                format='L - LT'
                margin='normal'
                label={t('timestampFrom')}
                value={localValues.timestampFrom || null}
                onChange={(date) => handleDateTimeChange(date as Moment, 'timestampFrom')}
                maxDate={localValues.timestampTo || time().format()}
              />
              <DateTimePicker
                className={classes.input}
                format='L - LT'
                margin='normal'
                label={t('timestampTo')}
                value={localValues.timestampTo || null}
                onChange={(date) => handleDateTimeChange(date as Moment, 'timestampTo')}
                maxDate={time().format()}
                minDate={localValues.timestampFrom || undefined}
              />
              <TextInput
                name='topic'
                onChange={debouncedHandleInput}
                value={localValues.topic ?? ''}
                label={t('topic')}
                className={classes.input}
                disableHelperText
              />
              <TextInput
                name='actorId'
                onChange={debouncedHandleInput}
                value={localValues.actorId ?? ''}
                label={t('actor')}
                className={classes.input}
                disableHelperText
              />
              <TextInput
                name='objectId'
                onChange={debouncedHandleInput}
                value={localValues.objectId ?? ''}
                label={t('object')}
                className={classes.input}
                disableHelperText
              />
            </div>
            <div className={classes.inputsGroup}>
              <AutocompleteArrayTextInput
                name='tags'
                label={t('tags')}
                value={localValues.tags}
                regularHelperText={t('tagsHelperText')}
                onChange={(e: any, value: string[], reason: string) => {
                  switch (reason) {
                    case 'create-option':
                    case 'remove-option':
                      debouncedHandleInput({ target: { name: 'tags', value } });
                      break;
                    case 'clear':
                      debouncedHandleInput({ target: { name: 'tags', value: [] } });
                      break;
                    default:
                      break;
                  }
                }}
                className={classes.tagsInput}
              />
              <SelectInput<string | undefined>
                label={t('taskType')}
                name='taskType'
                value={localValues.taskType}
                className={classes.input}
                options={
                  taskTypes?.map((taskType) => {
                    return {
                      value: taskType,
                      text: taskType,
                    };
                  }) || []
                }
                onChange={debouncedHandleInput}
                disabled={taskTypesLoading}
                allowEmpty
              />
              <SelectInput<string | undefined>
                label={t('taskState')}
                name='taskState'
                value={localValues.taskState}
                className={classes.input}
                options={taskStateOptions}
                onChange={debouncedHandleInput}
                allowEmpty
              />
            </div>
          </div>
          <div>
            <BackgroundTaskExport type='EVENT_EXPORT' queryArguments={handleFilters(localValues)} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const handleFilters = (filters: any) => {
  if (!filters) return {};
  const { timestampFrom, timestampTo, ...rest } = filters;
  return cleanupFormValues({
    timestampFrom: handleDate(timestampFrom),
    timestampTo: handleDate(timestampTo),
    ...rest,
  });
};

const handleDate = (date?: Moment | null) => {
  if (!date) return undefined;
  return date.toISOString();
};

const inputWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexGrow: 1,
      marginTop: 0,
    },
    flexWrap: 'wrap',
  },
  input: {
    width: inputWidth,
    maxWidth: inputWidth,
  },
  tagsInput: {
    maxWidth: 2 * inputWidth + theme.spacing(1),
  },
}));

export default Filters;
