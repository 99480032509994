import axios from '~/utils/axios';

export const createAccessCode = (employeeId: string, phoneNumber?: string) => {
  return axios.post(`/employees/${employeeId}/accessCode`, {
    phone: phoneNumber ? phoneNumber : undefined,
  });
};

export const getEmployeesRoles = (solutionId: string) => {
  return axios
    .get<{ role: string; name: string }[]>(`/solutions/${solutionId}/employeeRoles`)
    .then((res) => res.data);
};

export const getEmployeesRolesTranslations = () => {
  return axios
    .get<Record<string, string>>(`/localization/static/apiIdentityRole/all`)
    .then((res) => res.data);
};

export const getEmployeeMetrics = (employeeId: string) => {
  return axios.get(`/employees/${employeeId}/metrics`);
};
