import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslate, usePermissions } from 'react-admin';
import { time } from '~/utils';
import H2 from '../H2';
import { resource } from '~/resources/actionReports/ActionReportsList';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';
import { StatusField } from '..';
import { ActionReport } from '~/types';
import { useHistory } from 'react-router';
import ManualReportAction from './ManualReportAction';
import { useActionReports, useActionReportSchema } from '~/hooks/actionReports';
import { NoPermissions } from '../ra';

interface Prpos {
  objectType: ActionReport['objectType'];
  objectId: ActionReport['objectId'];
}

const ActionReportsTab: FC<Prpos> = (props) => {
  const { objectType, objectId } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);
  const { permissions } = usePermissions();

  // prefetch shema from ReportData component
  useActionReportSchema();

  const { data, isLoading } = useActionReports({ objectType, objectId });

  const history = useHistory();

  if (!permissions) return null;
  if (!permissions.includes('report.list')) return <NoPermissions />;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('actionsReportingLog')}
          </H2>
        </Grid>
        <Grid item id="section-actions">
          <ManualReportAction objectId={objectId} objectType={objectType} />
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>{t('system')}</TableCell>
              <TableCell style={{ minWidth: 150 }}>{t('reportType')}</TableCell>
              <TableCell style={{ minWidth: 150 }}>{t('created')}</TableCell>
              <TableCell style={{ minWidth: 150 }}>{t('status')}</TableCell>
              <TableCell style={{ minWidth: 150 }}>{t('updated')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell loading={isLoading} />
                <TableCell loading={isLoading} />
                <TableCell loading={isLoading} />
                <TableCell loading={isLoading} />
                <TableCell loading={isLoading} />
              </TableRow>
            )}
            {data?.reports?.map(({ system, reportType, created, status, updated, id }) => {
              return (
                <TableRow
                  key={id}
                  onClick={() => history.push(`/${resource}/${id}/show`)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{system}</TableCell>
                  <TableCell>{reportType}</TableCell>
                  <TableCell>{time(created).format('LL LT')}</TableCell>
                  <TableCell>
                    <StatusField status={status} />
                  </TableCell>
                  <TableCell>{time(updated).format('LL LT')}</TableCell>
                </TableRow>
              );
            })}
            {!data?.reports.length && !isLoading && (
              <TableRow>
                <TableCell>{t('empty')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default ActionReportsTab;
