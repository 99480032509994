import React, { FC, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { EditButton } from '~/components';
import FullscreenDrawer from '~/components/FullscreenDrawer';
import { EditForm } from '~/components/ra';
import onError from '~/errorsHandler';

import PaymentServiceForm from './PaymentServiceForm';
import { PaymentService } from '~/types/paymentServices';

interface EditDrawerProps {
  record?: PaymentService;
  open: boolean;
  onClose: () => void;
}

const useEditTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.paymentServices.edit.${key}`);
};

const EditDrawer: FC<EditDrawerProps> = (props) => {
  const { record, open, onClose } = props;

  const handleError = (error: any) => {
    onError(error);
  };

  const handleClose = () => {
    onClose();
  };

  const t = useEditTranslate();
  const classes = useStyles();

  return (
    <FullscreenDrawer
      open={open}
      onClose={handleClose}
      heading={t('title')}
      subtitle={t('subtitle')}
    >
      <EditForm
        resource="paymentServices"
        record={record}
        onSuccess={() => {
          handleClose();
        }}
        onError={handleError}
        style={fullWidth}
        classes={classes}
      >
        <PaymentServiceForm edit />
      </EditForm>
    </FullscreenDrawer>
  );
};

interface Props {
  record?: PaymentService;
}

const PaymentServiceEditAction = ({ record }: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { permissions } = usePermissions();
  if (!permissions?.includes('paymentService.update')) return null;
  return (
    <>
      <EditButton onClick={handleOpen} />
      <EditDrawer open={open} onClose={handleClose} record={record} />
    </>
  );
};

const fullWidth = { width: '100%' };

const useStyles = makeStyles((theme) => ({
  bottomToolbar: {
    marginLeft: theme.spacing(3),
  },
}));

export default PaymentServiceEditAction;
