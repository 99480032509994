import React from 'react';
import { TextField } from '~/components';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { Longdash, time } from '~/utils';

interface Props {
  record?: ExternalProfileLink;
  source: string;
  label?: string;
  sortable?: boolean;
}

const ListDateTimeField = (props: Props) => {
  const { record, source } = props;

  if (!record) {
    return null;
  }

  const dt = record[source] && time(record[source]);
  return dt ? <TextField label={dt.format('ll')}>{dt.format('LT')}</TextField> : <>{Longdash}</>;
};

export default ListDateTimeField;
