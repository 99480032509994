const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const keyToLabel = (key: string): string => {
  if (!key) {
    return key;
  }
  const parts = key.split(/(?=[A-Z])/);
  return parts
    .map((part, index) => (index === 0 ? capitalizeFirstLetter(part) : part.toLowerCase()))
    .join(' ');
};

export default keyToLabel;
