import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { AddressTable, H2, H3, TextField } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './ContextTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';

const ServiceChargeSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`serviceCharge.${key}`));

  const serviceCharge = trx.details.serviceCharge;
  if (!serviceCharge) {
    return null;
  }

  return (
    <>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('_')}
        </H2>
      </header>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          <Grid item xs={4}>
            <TextField label={t('id')}>{serviceCharge.id}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('name')}>{serviceCharge.name}</TextField>
          </Grid>
        </Grid>
        {serviceCharge.details?.deliveryAddress && (
          <div style={{ marginBottom: '1em' }}>
            <H3 topSpacing={1} bottomSpacing={1}>
              {t('deliveryAddress')}
            </H3>
            <AddressTable address={serviceCharge.details?.deliveryAddress} />
          </div>
        )}
      </Section>
    </>
  );
};

export default ServiceChargeSection;
