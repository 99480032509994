import React from 'react';
import { TextInput, email, required } from 'react-admin';
import lodashGet from 'lodash/get';

import { PhoneNumberInput } from '~/components/ra';
import { Channel } from '~/types/profile';

interface Props {
  [x: string]: any;
  source: string;
  selectInputSource: string;
  record?: any;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const ChannelIdInput = (props: Props) => {
  const { source, selectInputSource, record, label, fullWidth, disabled } = props;

  const selectInputValue: Channel = lodashGet(record, selectInputSource);

  const addValidate = (inputType: Channel) => {
    switch (inputType) {
      case 'EMAIL':
        return email();
      default:
        return undefined;
    }
  };

  if (selectInputValue === 'PHONE')
    return (
      <PhoneNumberInput
        source={source}
        record={record}
        label={label}
        fullWidth={fullWidth}
        disabled={disabled || !selectInputValue}
      />
    );

  return (
    <TextInput
      source={source}
      record={record}
      label={label}
      fullWidth={fullWidth}
      disabled={disabled || !selectInputValue}
      validate={[required(), addValidate(selectInputValue)]}
    />
  );
};

ChannelIdInput.defaultProps = {
  label: 'components.ra.inputs.ChannelIdInput',
  fullWidth: true,
  disabled: false,
};

export default ChannelIdInput;
