import React, { FC } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import { TextField as RaTextField } from '~/components/ra';
import { buildProfileLink, Longdash } from '~/utils';
import { ProfileType } from '~/types/profile';
import { TextField } from '.';
import { useTenant } from '~/hooks/tenant';

const makeLabel = (key: string): string => `resources.deals.show.details.${key}`;

type AccountActor = {
  profileType: ProfileType;
  profileId: string;
  accountId: string;
  accountNumber: string;
};

type ProfileActor = {
  profileType: ProfileType;
  profileId: string;
};

interface Props {
  actor: AccountActor | ProfileActor;
  label?: string;
}

const ProfileField: FC<Props> = (props) => {
  const { actor, label } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(makeLabel(key));

  const { data: tenant } = useTenant();

  const makeProps = (reference: string, source?: string) => ({
    reference,
    basePath: `/${reference}`,
    source: source || 'profileId',
    record: actor,
    link: false,
    addLabel: false,
  });

  switch (actor.profileType) {
    case 'INDIVIDUAL':
      return (
        <ReferenceField {...makeProps('individuals')}>
          <RaTextField
            fn={(r) => `${r.firstName || Longdash} ${r.lastName}`}
            label={label || t('individual')}
            showLink={buildProfileLink(undefined, actor.profileId, actor.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'BUSINESS':
      return (
        <ReferenceField {...makeProps('businesses')}>
          <RaTextField
            source='legalName'
            label={label || t('business')}
            allowEmpty
            showLink={buildProfileLink(undefined, actor.profileId, actor.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'PARTNER':
      return (
        <ReferenceField {...makeProps('partners')}>
          <RaTextField
            source='name'
            label={label || t('partner')}
            allowEmpty
            showLink={buildProfileLink(undefined, actor.profileId, actor.profileType)}
            textWrap
          />
        </ReferenceField>
      );
    case 'TENANT':
      if ((actor as any).accountId) {
        return (
          <ReferenceField {...makeProps('tenant/accounts', 'accountId')}>
            <RaTextField
              source='name'
              label={label || t('tenant')}
              allowEmpty
              showLink={buildProfileLink(
                (actor as AccountActor).accountId,
                actor.profileId,
                actor.profileType
              )}
              textWrap
            />
          </ReferenceField>
        );
      } else {
        return <TextField label={label || t('tenant')}>{tenant?.name}</TextField>;
      }
    default:
      return null;
  }
};

export default ProfileField;
