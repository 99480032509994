import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePermissions } from 'react-admin';

import {
  clearSecretWord,
  createBlock,
  getApiIdentities,
  resetAttemptsCounter,
  clearBlock,
  getApiIdentityById,
} from '~/api/apiIdentity';

const getApiIdentitiesKey = (profileId: string) => ['apiIdentities', profileId];

interface ApiIdentitiesByIdProps {
  identityId: string | undefined;
}

export const useApiIdentityById = (props: ApiIdentitiesByIdProps) => {
  const { identityId } = props;
  const { permissions } = usePermissions();

  return useQuery(getApiIdentitiesKey(identityId!), () => getApiIdentityById(identityId!), {
    enabled: !!identityId && !!permissions?.includes('apiIdentity.view'),
  });
};

interface ApiIdentitiesProps {
  profileId: string | undefined;
}

export const useApiIdentityByProfileId = (props: ApiIdentitiesProps) => {
  const { profileId } = props;
  const { permissions } = usePermissions();

  return useQuery(getApiIdentitiesKey(profileId!), () => getApiIdentities({ profileId }), {
    enabled: !!profileId && !!permissions?.includes('apiIdentity.list'),
  });
};

const useRefetchApiIdentities = () => {
  const queryCLient = useQueryClient();
  return () => queryCLient.invalidateQueries('apiIdentities');
};

export const useClearSecretWord = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(clearSecretWord, {
    onSuccess() {
      refetch();
    },
  });
};

export const useResetAttemptsCounter = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(resetAttemptsCounter, {
    onSuccess() {
      refetch();
    },
  });
};

export const useCreateBlock = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(createBlock, {
    onSuccess() {
      refetch();
    },
  });
};

export const useClearBlock = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(clearBlock, {
    onSuccess() {
      refetch();
    },
  });
};
