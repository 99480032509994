export default {
  PhoneNumberInput: 'Phone number',
  GeolocationInput: {
    _: 'Geolocation',
    latitude: 'Latitude',
    longitude: 'Longitude',
  },
  DocumentTypeSelect: 'Type',
  ChannelSelect: {
    _: 'Type ',
    EMAIL: 'Email',
    PHONE: 'Phone',
    WEBSITE: 'Website',
    TELEGRAM: 'Telegram',
    WECHAT: 'Wechat',
    WHATSAPP: 'Whatsapp',
  },
  ChannelIdInput: 'Identifier',
  ColorInput: 'Color',
  ImageInput: 'Image',
  LanguageSelect: 'Language',
  country: 'Country',
  AmountInput: {
    amount: 'Amount',
    currency: 'Currency',
    nonNullable: 'Must be greater than 0',
  },
  account: 'Account',
};
