import React, { FC } from 'react';
import { Tab, useTranslate, usePermissions } from 'react-admin';

import { PaperTabbedShowLayout, RecordTitle, Show } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import { amountToString } from '~/utils/amountFormat';
import DetailsTab from './components/DetailsTab';
import { NoPermissions } from '~/components/ra';
import ActionReportsTab from '~/components/ActionReportsTab';

import Aside from './components/aside/Aside';
import ContextTab from './components/context/ContextTab';
import IntegrationTab from './components/integration/IntegrationTab';

const label = (key: string): string => `resources.headTransactions.${key}`;

const TransactionShow: FC<any> = (implicitProps) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  if (!permissions) return null;
  if (!permissions.includes('headTransaction.view')) return <NoPermissions />;

  return (
    <Show
      {...implicitProps}
      title={
        <RecordTitle
          headline={(record: HeadTransaction) => {
            const amount = record.details.baseAmount || record.amount;
            const amountText = amountToString(amount);

            const operationType = translate(`operationType.${record.operationType}`);

            return `${operationType} ${amountText}`;
          }}
        />
      }
      actions={null}
    >
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.details')}>
          <DetailsTab {...implicitProps} />
        </Tab>
        <Tab label={t('tabs.context')} path="context">
          <ContextTab {...implicitProps} />
        </Tab>
        <Tab label={t('tabs.integration')} path="integration">
          <IntegrationTab {...implicitProps} />
        </Tab>
        <Tab
          label={translate('resources.actionReports.name', 2)}
          disabled={!permissions?.includes('report.list')}
          path="actionReports"
        >
          <ActionReportsTab objectId={implicitProps.id} objectType="HEAD_TRANSACTION" />
        </Tab>
      </PaperTabbedShowLayout>
      <Aside />
    </Show>
  );
};

export default TransactionShow;
