import React from 'react';
import { useForm } from 'react-final-form';
import { FileField } from '~/components/ra';
import { AddActionButtons } from '~/components';
import lodashGet from 'lodash/get';
import { FileType } from '~/api/utils';

export const FileCard = (props: {
  index: number;
  item: object;
  getSource: (key: string) => string;
  formData: any;
}) => {
  const { index, item, getSource, formData } = props;
  const form = useForm();
  const files = lodashGet(formData, getSource('files'));

  return (
    <AddActionButtons
      record={formData}
      file={item as FileType}
      name={formData.legalName || ''}
      onDeleteClick={() =>
        form.change(
          getSource('files'),
          files.filter((_item: any, i: number) => i !== index)
        )
      }
    >
      <FileField
        key={index}
        record={item}
        withPreview
        imageFieldProps={{
          width: '100%',
          height: 170,
          objectFit: 'contain',
          noText: true,
        }}
      />
    </AddActionButtons>
  );
};
