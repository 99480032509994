import React from 'react';
import { Button } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
}));
interface Props {
  onClick?: () => void;
  icon?: any;
  red?: boolean;
  yellow?: boolean;
  label?: string;
  [x: string]: any;
}

const EditButton = (props: Props) => {
  const { onClick, icon, className, red, yellow, label, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      label={label || 'ra.action.edit'}
      onClick={onClick}
      className={cx({ [classes.red]: red, [classes.yellow]: yellow }, className)}
      {...rest}
    >
      {icon ? <SvgIcon>{icon}</SvgIcon> : <EditIcon />}
    </Button>
  );
};

export default EditButton;
