import React, { FC } from 'react';
import { DeleteButtonProps } from 'ra-ui-materialui';
import { DeleteButton as RaDeleteButton } from 'react-admin';
import { useNotify } from 'ra-core';

import useCrudGetManyReference from '~/hooks/useCrudGetManyReference';

interface Props extends DeleteButtonProps {
  resource: string;
  target: string;
  targetId: string;
  record: any;
  onClose?: () => void;
}

const DeleteButton: FC<Props> = (props) => {
  const { resource, target, record, onClose, targetId, ...rest } = props;
  const crudGetManyReference = useCrudGetManyReference(resource, target, targetId);
  const raNotify = useNotify();

  const onSuccess = () => {
    raNotify('ra.notification.deleted', 'info', { smart_count: 1 });
    crudGetManyReference();
    if (onClose) onClose();
  };

  return (
    <RaDeleteButton
      mutationMode="pessimistic"
      resource={resource}
      record={record}
      redirect={false}
      onSuccess={onSuccess}
      {...rest}
    />
  );
};

export default DeleteButton;
