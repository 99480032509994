import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import MuiAccordionDetails, { AccordionActionsProps } from '@material-ui/core/AccordionActions';

const AccordionDetails: FC<AccordionActionsProps> = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return <MuiAccordionDetails className={cx(classes.AccordionDetails, className)} {...rest} />;
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
}));

export default AccordionDetails;
