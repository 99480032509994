import React from 'react';
import lodashGet from 'lodash/get';

import { time } from '~/utils';
interface Props {
  record?: any;
  source: string;
  format?: string;
  emptyText?: string;
  [x: string]: any;
}

const DateTimeField = (props: Props) => {
  const { record, source, format = 'LL LT', emptyText } = props;
  const date = lodashGet(record, source);
  if (date) {
    return <span>{time(date).format(format)}</span>;
  } else return <span>{emptyText || null}</span>;
};

export default DateTimeField;
