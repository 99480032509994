import React from 'react';
import { Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslate } from 'react-admin';
import { time } from '~/utils';

import { AvatarField } from '~/components/ra';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    avatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
    },
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '20px',
      paddingBottom: '7px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
  };
});

const defaultSubtitle = (record: any, resource: string, translate: any): string => {
  const parts = [];
  if (record.id) {
    parts.push(`${translate(`resources.${resource}.name`, 1)} #${record.id}`);
  }
  const created = record.created || record.createdAt;
  if (created) {
    const m = time(created);
    parts.push(`created at ${m.format('l')} ${m.format('LT')}`);
  }
  const modified =
    record.modifiedAt || record.modified || record.lastModified || record.lastModifiedAt;
  if (modified) {
    const m = time(modified);
    parts.push(`last modified at ${m.format('l')} ${m.format('LT')}`);
  }
  return parts.join(', ');
};

type Props = {
  record: any;
  resource: string;
  avatarSource: string;
  colorSource?: string;
  headline?: (record: any, resource: string, translate: any) => string;
  subtitle?: (record: any, resource: string, translate: any) => string;
  translate: any;
};

const RecordTitleWithAvatar = ({
  record,
  resource,
  avatarSource,
  colorSource,
  headline,
  subtitle = defaultSubtitle,
  translate,
}: Props) => {
  const classes = useStyles();

  return record ? (
    <div className={classes.root}>
      <div className={classes.avatar}>
        <AvatarField
          record={record}
          source={avatarSource}
          resource={resource}
          noText={true}
          size="50px"
          colorSource={colorSource}
        />
      </div>
      <div>
        {headline ? (
          <Typography variant="h2" className={classes.headline}>
            {headline(record, resource, translate)}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle(record, resource, translate)}
          </Typography>
        ) : null}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default withTranslate(RecordTitleWithAvatar);
