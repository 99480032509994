import React from 'react';
import { Button } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import ForkIcon from '@material-ui/icons/CallSplit';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
}));
interface Props {
  onClick?: () => void;
  red?: boolean;
  yellow?: boolean;
  label: string;
  [x: string]: any;
}

const ForkButton = (props: Props) => {
  const { onClick, className, red, yellow, label, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      label={label}
      onClick={onClick}
      className={cx({ [classes.red]: red, [classes.yellow]: yellow }, className)}
      {...rest}
    >
      <ForkIcon />
    </Button>
  );
};

export default ForkButton;
