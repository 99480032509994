import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column, Partner, Transaction, TransactionsFilters } from '~/types';
import { getTransactions } from '~/api/transactionsHistory';
import CustomCell from './CustomCell';
import { RowMenu } from '~/components/TransactionsHistory/cellComponents';
import { Longdash } from '~/utils';
import TransactionView from '~/components/TransactionsHistory/TransactionView';
import Filters from './Filters';

export const resource = 'transactions';
const target = 'partners';

interface Props {
  record?: Partner;
  permissions?: string[];
}

const TransactionsHistory = (props: Props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.partners.transactionsHistory.${key}`);
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<TransactionsFilters>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading || !record) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));

      const { data } = await getTransactions({
        target,
        resourceId: record.id,
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions as any[],
      };

      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns: Column[] = [
    {
      selectCellData: (rowData) => rowData.createdAt,
      cellDataWrapperComponent: CustomCell,
      id: 'dateAndTime',
      header: t('dateAndTime'),
      //width: 160,
      style: {
        minWidth: 130,
        flexBasis: 130,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.baseAmount,
      cellDataWrapperComponent: CustomCell,
      id: 'baseAmount',
      header: t('baseAmount'),
      cellAlign: 'right',
      headerAlign: 'right',
      style: {
        minWidth: 100,
        flexBasis: 100,
        maxWidth: 150,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData.operationType,
      cellDataWrapperComponent: CustomCell,
      id: 'type',
      header: t('type'),
      style: {
        minWidth: 95,
        flexBasis: 95,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) =>
        rowData.amount.value > 0 ? rowData.details.senderName : rowData.details.recipientName,
      cellDataWrapperComponent: CustomCell,
      header: t('counterParty'),
      style: {
        minWidth: 120,
        maxWidth: 160,
        flexBasis: 120,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) =>
        rowData.amount.value > 0 ? rowData.sender?.accountNumber : rowData.recipient?.accountNumber,
      cellDataWrapperComponent: CustomCell,
      header: t('otherAccountNumber'),
      id: 'otherAccountNumber',
      style: {
        minWidth: 110,
        flexBasis: 110,
        maxWidth: 200,
        display: 'inline',
        paddingRight: 0,
      },
    },
    {
      selectCellData: () => Longdash,
      cellDataWrapperComponent: CustomCell,
      header: t('referenceNumber'),
      width: 100,
    },
    {
      selectCellData: (rowData) => rowData.status,
      cellDataWrapperComponent: CustomCell,
      id: 'status',
      header: t('status'),
      //width: 120,
      style: {
        minWidth: 100,
        flexBasis: 100,
        maxWidth: 200,
        paddingRight: 0,
      },
    },
    {
      selectCellData: (rowData) => rowData,
      cellDataWrapperComponent: RowMenu,
      header: '',
      //width: 50,
      cellAlign: 'right',
      style: {
        minWidth: 50,
        flexBasis: 50,
        paddingLeft: 10,
      },
    },
  ];

  const [showData, setShowData] = useState<Transaction | null>(null);
  const handleShowOpen = (data: Transaction) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  const { hasNextPage, isNextPageLoading, data } = list;
  const classes = useStyles();
  if (!record) return null;
  return (
    <Fragment>
      <Filters record={record} target={target} resource={resource} />
      <VirtualizedList<Transaction>
        resource={resource}
        columns={columns}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        data={data}
        filters={filters}
        loadNextPage={loadNextPage}
        onRowClick={handleShowOpen}
        className={classes.root}
      />
      <TransactionView
        record={record}
        target={target}
        transaction={showData}
        onClose={handleShowClose}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    // minWidth: 1060,
  },
}));

export default TransactionsHistory;
