import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFeatures, selectors } from '~/ducks/solutions';
import { Feature } from '~/api/solutions';
import { RootState } from '~/types';

interface Selector {
  data: Feature[];
  loading: boolean;
  error: any;
}

interface State extends Selector {
  list: { [x: string]: string };
}

const useFeatures = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeatures());
  }, [dispatch]);

  const [features, setFeatures] = useState<State>({
    data: [],
    loading: false,
    error: null,
    list: {},
  });
  const data = useSelector<RootState, Selector>(selectors.getFeaturesSeletor);
  useEffect(() => {
    if (data) {
      setFeatures((prevState) => ({ ...prevState, ...data }));
    }
  }, [data]);
  useEffect(() => {
    const featuresListArray = data.data;
    if (featuresListArray.length) {
      const result: { [x: string]: string } = {};
      featuresListArray.forEach((item) => {
        const { key, name } = item;
        result[key] = name;
      });
      setFeatures((prevState) => ({ ...prevState, list: result }));
    }
  }, [data]);
  return features;
};

export default useFeatures;
