import React from 'react';
import lodashGet from 'lodash/get';

import { useHandbook } from '~/hooks';
import { TextField as TextFieldComponent } from '~/components';
import { Longdash } from '~/utils';

import { Business } from '~/types/businesses';

interface Props {
  source: string;
  record?: Business;
  label?: any;
}

const IndustryField = (props: Props) => {
  const { source, record, label } = props;
  const recordData = lodashGet(record, source);
  const { data } = useHandbook(props.record && props.record.solutionId);

  if (data?.industries) {
    const result = data.industries.find((item) => item.id === recordData);
    return <TextFieldComponent children={result?.name || Longdash} label={label} />;
  } else {
    return <TextFieldComponent children={Longdash} label={label} />;
  }
};

export default IndustryField;
