import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

interface Props {
  name?: string;
  value?: string;
  loading?: boolean;
}

const MetricSingleValueField: FC<Props> = (props) => {
  const { name, value, loading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!loading ? <div className={classes.name}>{name}</div> : <Skeleton width={150} height={16} />}
      {!loading ? (
        <div className={classes.value}>{value}</div>
      ) : (
        <Skeleton width={60} height={16} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // typography
  name: {
    fontSize: '14px',
    fontWeight: 500,
  },
  value: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginLeft: 3,
    whiteSpace: 'nowrap',
  },
}));

export default MetricSingleValueField;
