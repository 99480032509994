import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../TableCell';

import { ApiIdentity } from '~/types/apiIdentity';
import { ChipField } from '..';
import ClearSecretWordAction from './ClearSecretWordAction';
import { useApiIdentityTranslate } from '.';

interface Props {
  record: ApiIdentity | undefined;
  loading?: boolean;
}

const ApiIdentityCard: FC<Props> = (props) => {
  const classes = useApiIdentityCardStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <ApiIdentityCardContent {...props} />
    </Card>
  );
};

const useApiIdentityCardStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    padding: theme.spacing(2),
  },
}));

export const ApiIdentityCardContent: FC<Props> = (props) => {
  const { record, loading } = props;
  const classes = useApiIdentityCardContentStyles();
  const t = useApiIdentityTranslate();

  const { permissions } = usePermissions();

  const hasClearSecretWordPermission = permissions?.includes('apiIdentity.update');

  return (
    <div className={classes.root}>
      <div>
        <Table className={classes.table}>
          <TableBody>
            <Row label={t('name')} value={record?.name} loading={loading} />
            <Row label={t('username')} value={record?.username} loading={loading} />
            <Row label={t('email')} value={record?.emailAddress} loading={loading} />
            <Row label={t('phone')} value={record?.phoneNumber} loading={loading} />
            <Row
              label={t('secretWord')}
              value={record?.hasSecretWord}
              loading={loading}
              action={
                hasClearSecretWordPermission && (
                  <ClearSecretWordAction className={classes.button} identityId={record?.id} />
                )
              }
              actionDisabled={!record?.hasSecretWord}
            />
            <Row label={t('password')} value={record?.hasPassword} loading={loading} />
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const Row: FC<{
  label: string;
  value?: string | boolean;
  action?: JSX.Element;
  actionDisabled?: boolean;
  loading?: boolean;
}> = (props) => {
  const { label, value, action, loading, actionDisabled } = props;
  const classes = useApiIdentityCardContentStyles();
  const t = useApiIdentityTranslate();
  return (
    <TableRow className={classes.row}>
      <TableCell className={cx(classes.cell, classes.labelCell)} loading={loading}>
        {label}
      </TableCell>
      <TableCell className={classes.cell} loading={loading}>
        {typeof value === 'boolean' ? (
          <ChipField className={classes.chip} label={t(value ? 'valueTrue' : 'valueFalse')} />
        ) : (
          value
        )}
      </TableCell>
      <TableCell
        className={cx(classes.cell, { [classes.actionCell]: !!action && !actionDisabled })}
      >
        {action && <action.type {...action.props} disabled={actionDisabled} />}
      </TableCell>
    </TableRow>
  );
};

const useApiIdentityCardContentStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  chip: {
    margin: 0,
  },
  button: {
    marginTop: -8,
    marginBottom: -8,
  },
  table: {
    backgroundColor: 'transparent',
  },
  row: {
    borderBottom: 'unset',
    height: 45,
  },
  cell: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
  },
  labelCell: {
    color: theme.palette.grey[600],
    paddingLeft: 0,
  },
  actionCell: {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export default ApiIdentityCard;
