import { useQuery } from 'react-query';
import { getCurrentUser } from '~/api/user';
import { Identity } from '~/types';

interface UseLoggedUserOptions {
  enabled?: boolean;
  onSuccess?: (res: {
    identity: Identity;
    role: {
      id: string;
      name: string;
      created: string;
      deleted: false;
      dependentRoles: string[];
      permissions: string[];
      readonly: boolean;
    };
  }) => void;
  onError?: (err?: any) => void;
}

export const useLoggedUser = (options: UseLoggedUserOptions = {}) => {
  return useQuery('loggedUser', getCurrentUser, { staleTime: Infinity, retry: false, ...options });
};
