import React from 'react';
import { Datagrid, TextField, usePermissions } from 'react-admin';

import { List } from '~/layout';
import { BooleanField } from '~/components/ra';
import RoleCreate from './RoleCreate';

const RoleList = (props: any) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <List {...props} bulkActionButtons={false} actions={<RoleCreate permissions={permissions} />}>
      <Datagrid rowClick="show" size="medium" selectedIds={null}>
        <TextField source="id" label="resources.roles.fields.id" sortable={false} />
        <TextField source="name" label="resources.roles.fields.name" sortable={false} />
        <BooleanField
          source="readonly"
          label="resources.roles.fields.readonly"
          sortable={false}
          addLabel={false}
          nullOnFalse
        />
      </Datagrid>
    </List>
  );
};

export default RoleList;
