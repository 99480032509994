import React, { FC } from 'react';
import { Section } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import useStyles from './useStyles';
import ResponsesSection from './ResponsesSection';
import OtherTechnicalDataSection from './OtherTechnicalDataSection';

export const makeLabel = (key: string) => `resources.headTransactions.show.integration.${key}`;

const IntegrationTab: FC<any> = (props) => {
  const { record } = props;

  const classes = useStyles();

  const trx = record as HeadTransaction;

  if (!trx) return null;

  return (
    <Section noPaddings className={classes.section}>
      <ResponsesSection trx={trx} />
      <OtherTechnicalDataSection trx={trx} />
    </Section>
  );
};

export default IntegrationTab;
