import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { Deal, DealFilters } from '~/types/Deal';

type Response = {
  cursors: {
    next?: string;
  };
  deals?: Deal[];
};

interface getDealsProps {
  next?: string | null;
  filters?: DealFilters;
}

export const getDeals = (props: getDealsProps) => {
  const { filters, next } = props;
  const url = `/deals`;

  return axios.get<Response>(urlWithParams(url, { next, limit: 50, ...filters }));
};

export const showDealCode = (dealId: string, reason: string) => {
  return axios.post<string>(`/deals/${dealId}/code`, { reason });
};
