import React, { FC } from 'react';
import { ReferenceField, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Store';
import SvgIcon from '@material-ui/core/SvgIcon';

import { TextField, StatusField, ChipField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { AsideBase } from '~/layout';
import { useApiIdentitiesT } from '../utils';
import { KycLevelField } from '~/components/ra';
import GoToProfile from './GoToProfile';
import { useBusinessById, useBusinessEmployess } from '~/hooks/businesses';
import { buildName } from '~/utils';
import { PersonCircleIcon as IndividualsIcon } from '~/img';
import { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';

interface Props {
  profileId?: string;
  identityId?: string;
  loading?: boolean;
}

const LinkedBusinessAside: FC<Props> = (props) => {
  const { profileId, identityId, loading } = props;

  const businessQuery = useBusinessById(profileId);
  const employeesQuery = useBusinessEmployess(profileId);
  const linkedEmployee = employeesQuery.data?.find((item) => {
    console.log('item cashier=' + item.cashierIdentityId);
    console.log('item general=' + item.generalIdentityId);
    console.log('props=' + identityId);
    console.log(item.generalIdentityId === identityId || item.cashierIdentityId === identityId);
    return item.generalIdentityId === identityId || item.cashierIdentityId === identityId;
  });
  console.log(linkedEmployee);
  const resultLoading = loading || businessQuery.isLoading;

  const classes = useStyles();
  const t = useApiIdentitiesT();
  const translate = useTranslate();
  const { data: tEmployees } = useEmployeeRolesTranslations();
  const tRole = (key: string) => (tEmployees ? tEmployees[key] : '');

  return (
    <AsideBase>
      <Grid container spacing={2} className={classes.layout}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('BUSINESS')}
              icon={<BusinessIcon />}
              loading={resultLoading}
              ellipsis
              noPaddingLeft
            >
              {businessQuery.data?.legalName}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('status')} loading={resultLoading} ellipsi>
              <StatusField status={businessQuery.data?.status} />
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('kybLevel')} loading={resultLoading} ellipsis>
              {businessQuery.data?.kycLevel && (
                <KycLevelField
                  record={businessQuery.data as any}
                  source="kycLevel"
                  type="text"
                  addLabel={false}
                />
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ReferenceField
              record={businessQuery.data}
              source="solutionId"
              reference="solutions"
              link={false}
              addLabel={false}
              basePath="/"
            >
              <RaTextField source="name" label={t('solution')} loading={resultLoading} ellipsis />
            </ReferenceField>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          {!!linkedEmployee && (
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t('employee')}
                  icon={
                    <SvgIcon>
                      <IndividualsIcon />
                    </SvgIcon>
                  }
                  loading={employeesQuery.isLoading}
                  ellipsis
                  noPaddingLeft
                >
                  {buildName('employees', linkedEmployee)}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={translate('resources.businesses.employees.authority.employeeRole._')}
                  loading={employeesQuery.isLoading}
                >
                  {linkedEmployee.roles?.map((role) => {
                    return <ChipField label={tRole(role)} />;
                  })}
                </TextField>
              </Grid>
            </Grid>
          )}
          <Grid container item justify="flex-end">
            {profileId && (
              <Grid item>
                <GoToProfile profileType="BUSINESS" profileId={profileId} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    marginLeft: theme.spacing(3),
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
}));

export default LinkedBusinessAside;
