import React, { FC } from 'react';
import {
  useTranslate,
  TextInput,
  NumberInput,
  SelectInput,
  required,
  minValue,
  maxValue,
} from 'react-admin';
import { useField } from 'react-final-form';
import { isEmpty } from 'lodash';

import { CurrencyPairsType, getCurrenciesPairs } from '~/api/currencies';
import { H3 } from '~/components';
import { HiddenInput, SelectArrayInput } from '~/components/ra';
import { useApi } from '~/hooks';

import { l } from '../CurrenciesList';
import RatesSourceSelect, { MANUAL_SOURCE_SYSTEM } from './RatesSourceSelect';
import SourceSystemSchemaForm from './SourceSystemSchemaForm';
import TradingAccountSelect from './TradingAccountSelect';
import { useAllCurrencies } from '~/hooks/currencies';
import ConversionProfitAccountSelect from './ConversionProfitAccountSelect';
import { BooleanInput } from 'react-admin';
import FxRatesTablesSelect from './FxRatesTablesSelect';
import { CurrenciesRates } from '~/api/fxrates';

const EMPTY_ARRAY: any[] = [];

interface Props {
  record: CurrenciesRates;
}

const TableForm: FC<any> = (props: Props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [{ data: pairs, loading }] = useApi<CurrencyPairsType[]>(getCurrenciesPairs);
  const allCurrenciesQuery = useAllCurrencies();

  const pairsChoices = pairs
    ?.filter((item) => !item.isMaster)
    .map((item) => ({
      id: item.id,
      name: `${item.baseCurrency}/${item.quoteCurrency}`,
    }));
  const emptyPairs = [{ id: '', name: t('noPairsTableForm'), disabled: true }];

  const rateSourceChoices = [
    { id: 'INPUT', name: t('INPUT') },
    { id: 'CALCULATION', name: t('CALCULATION') },
    { id: 'MID_RATE', name: t('MID_RATE') },
  ];

  const buyRateSourceInput = useField('buyRateSource');
  const sellRateSourceInput = useField('sellRateSource');
  const sourceSystemInput = useField('sourceSystem');
  const useForFxProcessingInput = useField('useForFxProcessing');
  const buyRateSource = buyRateSourceInput.input.value;
  const sellRateSource = sellRateSourceInput.input.value;
  const sourceSystem = sourceSystemInput.input.value;
  const useForFxProcessing = useForFxProcessingInput.input.value;
  return (
    <>
      <HiddenInput source='data' defaultValue={[]} />
      <TextInput source='name' label={t('form.name')} validate={required()} />
      <TextInput source='key' label={t('key')} />
      <RatesSourceSelect />
      <BooleanInput
        source='useForFxProcessing'
        label={t('useForFxProcessing')}
        defaultValue={false}
        initialValue={false}
      />
      {sourceSystem === MANUAL_SOURCE_SYSTEM || !sourceSystem ? (
        <>
          <SelectArrayInput
            source='currencyPairs'
            choices={isEmpty(pairsChoices) ? emptyPairs : pairsChoices}
            label={t('crossCurrencyPairs')}
            initialValue={EMPTY_ARRAY}
            disabled={loading}
          />
          <H3>{t('buyAndSellRates')}</H3>
          <SelectInput
            source='buyRateSource'
            label={t('buyRateSource')}
            choices={rateSourceChoices}
            validate={required()}
          />
          <SelectInput
            source='sellRateSource'
            label={t('sellRateSource')}
            choices={rateSourceChoices}
            validate={required()}
          />
          {buyRateSource === 'CALCULATION' ? (
            <NumberInput
              source='buyMargin'
              label={t('buyMargin')}
              validate={[required(), minValue(0), maxValue(1)]}
              step={0.01}
            />
          ) : (
            <TextInput source='buyMargin' label={t('buyMargin')} value={undefined} disabled />
          )}
          {sellRateSource === 'CALCULATION' ? (
            <NumberInput
              source='sellMargin'
              label={t('sellMargin')}
              validate={[required(), minValue(0), maxValue(1)]}
              step={0.01}
            />
          ) : (
            <TextInput source='sellMargin' label={t('sellMargin')} value={undefined} disabled />
          )}
          {useForFxProcessing && (
            <>
              <H3>{t('fxProcessing')}</H3>
              <ConversionProfitAccountSelect />
              {allCurrenciesQuery.data?.map((currency) => {
                return <TradingAccountSelect key={currency.code} currency={currency.code} />;
              })}
            </>
          )}
        </>
      ) : sourceSystem === 'BEST_RATES_OF' || sourceSystem === 'TWO_STEP_CROSSRATE' ? (
        <FxRatesTablesSelect sourceSystem={sourceSystem} record={record} />
      ) : (
        <SourceSystemSchemaForm
          sourceSystem={sourceSystem}
          useForFxProcessing={useForFxProcessing}
        />
      )}
    </>
  );
};

export default TableForm;
