import { CSSProperties } from 'react';

const ellipsis: CSSProperties = {
  display: 'inline',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export default ellipsis;
