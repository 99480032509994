import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import { Longdash } from '~/utils';
import { useHandbook } from '~/hooks';

export interface Props {
  solutionId?: string;
  [x: string]: any;
}

const IndustryInput = ({ solutionId, record, ...rest }: Props) => { // eslint-disable-line
  const { data } = useHandbook(solutionId);

  const translate = useTranslate();

  if (data?.industries) {
    const industries = (data?.industries || []).sort((a, b) => {
      return a.id.localeCompare(b.id);
    });
    return <SelectInput choices={industries} default {...rest} />;
  } else {
    return (
      <SelectInput
        choices={[{ id: undefined, name: translate('utils.noHandbookValues') }]}
        {...rest}
        allowEmpty={false}
      />
    );
  }
};

IndustryInput.defaultProps = {
  emptyText: Longdash,
};

export default IndustryInput;
