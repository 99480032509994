import React from 'react';
import { useQuery, withTranslate } from 'react-admin';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import ColoredAmountField from './ColoredAmountField';
import { PartnerAccount } from '~/types/account';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    // justifyContent: 'flex-end',
  },
  subtitle: {
    opacity: 0.4,
  },
}));

type Props = {
  data?: any[];
  loading?: boolean;
  error?: any;
  className?: any;
  translate?: any;
  history?: any;
  [x: string]: any;
};

const PartnerAccountField = (props: any) => {
  const { partner } = props;
  const { data }: { data: PartnerAccount[] | undefined; loading: boolean } = useQuery({
    type: 'getResource',
    payload: {
      url: `partners/${partner.id}/accounts?page=1&perPage=25`,
    },
  });

  if (data && data[0]) {
    const partnerAccount = data[0];
    return <ColoredAmountField amount={partnerAccount.balances[0].amount} />;
  }
  return null;
};

const PartnerAccountsTable = ({ className, translate, data, history, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={translate('home.partnerAccountsTable.title')} />
      <CardContent>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('home.partnerAccountsTable.name')}</TableCell>
              <TableCell>{translate('home.partnerAccountsTable.balance')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((partner) => (
              <TableRow key={partner.id}>
                <TableCell component="th" scope="row">
                  {partner.name}
                </TableCell>
                <TableCell align="right">
                  <PartnerAccountField partner={partner} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Box p={1}>
          <Typography variant="body2" className={classes.subtitle}>
            {translate('home.partnerAccountsTable.countHint')}
          </Typography>
        </Box>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => {
            history.push('/partners');
          }}
          style={{ marginLeft: 'auto' }}
        >
          {translate('home.partnerAccountsTable.viewAll')} <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default withTranslate(PartnerAccountsTable);
