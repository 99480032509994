import { useQuery, useMutation, useQueryClient } from 'react-query';

import { Beneficiary, BeneficiaryAccount } from '~/types/beneficiaries';

import {
  getBeneficiariesList,
  deleteBeneficiary,
  deleteBeneficiaryAccount,
  getBeneficiaryAccounts,
} from '~/api/beneficiaries';

type UseMutationType = typeof useMutation;
type UseMutationParams = Parameters<UseMutationType>;

export const useBeneficiariesQuery = (
  profileId: string,
  profileType: string,
  options?: {
    onSuccess?: (res: Beneficiary[]) => void;
    enabled?: boolean;
  }
) => {
  const key = `beneficiaries/${profileId}`;
  return useQuery<Beneficiary[]>(
    key,
    () =>
      getBeneficiariesList(profileId as string, profileType as 'businesses' | 'individuals').then(
        (res) => res.data
      ),
    options
  );
};

type DeleteBeneficiaryRequest = {
  profileId: string;
  id: string;
};

export const useBeneficiariesDelete = (options?: UseMutationParams[2]) => {
  const queryClient = useQueryClient();

  return useMutation((request: DeleteBeneficiaryRequest) => deleteBeneficiary(request.id), {
    onMutate(request) {
      const key = `beneficiaries/${request.profileId}`;

      if (options?.onMutate) options.onMutate(request);
      const prevData = queryClient.getQueryData<Beneficiary[]>(key);
      const rollback = () => {
        queryClient.setQueryData(key, prevData);
      };

      queryClient.setQueryData<Beneficiary[] | undefined>(key, (prev) => {
        if (prev) {
          return prev.filter((item) => item.id !== request.id);
        }
        return prev;
      });

      const successMessage = () => {};
      const errorMessage = () => {};

      return {
        rollback,
        successMessage,
        errorMessage,
      };
    },
    onSuccess(res, request, context: any) {
      const key = `beneficiaries/${request.profileId}`;
      if (options?.onSuccess) void options.onSuccess(res, request, context);
      context.successMessage();
      void queryClient.invalidateQueries(key);
    },
    onError(error: any, newData, context) {
      if (options?.onError) void options.onError(error, newData, context);
      context.rollback();
    },
  });
};

export const useBeneficiaryAccountsQuery = (
  beneficiaryId: string,
  options?: {
    onSuccess?: (res: BeneficiaryAccount[]) => void;
    enabled?: boolean;
  }
) => {
  const key = `beneficiaries/${beneficiaryId}/accounts`;
  return useQuery<BeneficiaryAccount[]>(
    key,
    () => getBeneficiaryAccounts(beneficiaryId, { page: 1, perPage: 9999 }).then((res) => res.data),
    options
  );
};

type DeleteBeneficiaryAccountRequest = {
  beneficiaryId: string;
  id: string;
};

export const useBeneficiaryAccountsDelete = (options?: UseMutationParams[2]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: DeleteBeneficiaryAccountRequest) =>
      deleteBeneficiaryAccount(request.beneficiaryId, request.id),
    {
      onMutate(request) {
        const key = `beneficiaries/${request.beneficiaryId}/accounts`;

        if (options?.onMutate) options.onMutate(request);
        const prevData = queryClient.getQueryData<BeneficiaryAccount[]>(key);
        const rollback = () => {
          queryClient.setQueryData(key, prevData);
        };

        queryClient.setQueryData<BeneficiaryAccount[] | undefined>(key, (prev) => {
          if (prev) {
            return prev.filter((item) => item.id !== request.id);
          }
          return prev;
        });

        const successMessage = () => {};
        const errorMessage = () => {};

        return {
          rollback,
          successMessage,
          errorMessage,
        };
      },
      onSuccess(res, request, context: any) {
        const key = `beneficiaries/${request.beneficiaryId}/accounts`;
        if (options?.onSuccess) void options.onSuccess(res, request, context);
        context.successMessage();
        void queryClient.invalidateQueries(key);
      },
      onError(error: any, request, context) {
        if (options?.onError) void options.onError(error, request, context);
        context.rollback();
      },
    }
  );
};
