import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as context from '~/ducks/context';
import { useDeepEffect } from '~/hooks';

export interface ContextData {
  [key: string]: any;
}

export interface Props {
  data?: ContextData;
  mode?: 'add' | 'set';
  record?: any;
  fromRecord?: (
    record: any,
    prevData: ContextData,
    setData: (data: ContextData) => void
  ) => ContextData | null;
}

const UpdateContext = ({ data = {}, mode = 'set', record, fromRecord }: Props): null => {
  const dispatch = useDispatch();

  const [state, setState] = useState<ContextData>(data);

  useDeepEffect(() => {
    setState(data);
  }, [data]);

  useDeepEffect(() => {
    if (fromRecord && record) {
      const dataFromRecord = fromRecord(record, state, setState);
      if (dataFromRecord) {
        setState(dataFromRecord);
      }
    }
  }, [record]);

  useDeepEffect(() => {
    switch (mode) {
      case 'add':
        dispatch(context.add(state));
        break;
      case 'set':
        dispatch(context.set(state));
        break;
      default:
        break;
    }
  }, [state, mode, dispatch]);

  return null;
};

export default UpdateContext;
