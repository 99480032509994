import { getSolutions } from './../api/solutions';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';

import { selectors, loadSolution } from '~/ducks/solutions';
import { selectors as context } from '~/ducks/context';
import { Solution } from '~/types';
import { useQuery } from 'react-query';

export interface Result {
  loading: boolean;
  data?: Solution;
  error?: any;
}

const useSolution = (solutionId?: string) => {
  const solutionIdFromStore = useSelector(context.get('solutionId'));
  const actualSolutionId = solutionId || solutionIdFromStore;

  const result = useSelector((state) => {
    if (actualSolutionId) {
      return selectors.getSolution(actualSolutionId)(state);
    } else {
      return undefined;
    }
  }) as Result | undefined;

  const dispatch = useDispatch();

  const throttledLoadSolution = useCallback(
    throttle((actualSolutionId: any): any => {
      dispatch(loadSolution(actualSolutionId));
    }, 1000),
    [dispatch]
  );

  useEffect(() => {
    if (actualSolutionId) {
      throttledLoadSolution(actualSolutionId);
    }
  }, [actualSolutionId, throttledLoadSolution]);

  return result || { loading: false };
};

export const useAllSolutions = (options?: {
  staleTime?: number;
  onSuccess?: (res: Awaited<ReturnType<typeof getSolutions>>) => any;
}) => {
  return useQuery(['solutions'], getSolutions, { staleTime: Infinity, ...(options || {}) });
};

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export default useSolution;
