import React from 'react';
import lodashGet from 'lodash/get';

import StatusFieldComponent from '~/components/StatusField';

const StatusField = (props: any) => {
  const { record, source = 'status', defaultValue, ...rest } = props;
  const value = lodashGet(record, source) || defaultValue;
  const deleted = Boolean(lodashGet(record, 'deleted'));

  return <StatusFieldComponent status={value} deleted={deleted} {...rest} />;
};

export default StatusField;
