import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import PricingForm from './PricingForm';
import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import { Solution } from '~/types';

interface Props {
  solutionRecord: Solution;
  permissions: string[];
}

const PricingCreate = (props: Props) => {
  const { solutionRecord, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.pricings.${key}`);

  return (
    <Fragment>
      <CreateButton
        onClick={handleOpen}
        variant='add'
        disabled={!permissions.includes('solution.pricing.create')}
      />
      <Drawer heading={t('addPricing')} open={open} onClose={handleClose}>
        <CreateForm
          resource='pricings'
          target='solutions'
          id={solutionRecord.id}
          record={{ solutionId: solutionRecord?.id }}
          closeParent={handleClose}
          initialValues={{
            kycLevels: [],
            tags: [],
            data: {},
          }}
        >
          <PricingForm format={'v2'} create />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default PricingCreate;
