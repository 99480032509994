import React from 'react';
import { SelectInput, useTranslate, required } from 'react-admin';
import { Channel } from '~/types/profile';

type Choice = {
  id: Channel;
  name: string;
};

interface Props {
  [x: string]: any;
  source: string;
  record?: any;
  disabled?: boolean;
  fullWidth?: boolean;
}

const ChannelSelect = (props: Props) => {
  const { source, record, label, disabled, fullWidth } = props;

  const translate = useTranslate();
  const name = (key: string): string => translate(`components.ra.inputs.ChannelSelect.${key}`);

  const choices: Choice[] = [
    { id: 'EMAIL', name: name('EMAIL') },
    { id: 'PHONE', name: name('PHONE') },
    { id: 'WEBSITE', name: name('WEBSITE') },
    { id: 'TELEGRAM', name: name('TELEGRAM') },
    { id: 'WECHAT', name: name('WECHAT') },
    { id: 'WHATSAPP', name: name('WHATSAPP') },
  ];

  return (
    <SelectInput
      source={source}
      record={record}
      label={label}
      choices={choices}
      disabled={disabled}
      fullWidth={fullWidth}
      validate={required()}
    />
  );
};

ChannelSelect.defaultProps = {
  disabled: false,
  fullWidth: true,
  label: 'components.ra.inputs.ChannelSelect._',
};

export default ChannelSelect;
