import { useTranslate } from 'react-admin';

export { default as ApiIdentityCard } from './ApiIdentityCard';
export { ApiIdentityCardContent } from './ApiIdentityCard';

export { default as ApiIdentityDetails } from './ApiIdentityDetails';

export const useApiIdentityTranslate = () => {
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ApiIdentity.${key}`);
  return t;
};
