import React from 'react';
import Grid from '@material-ui/core/Grid';
import H3 from './H3';
import ReferenceFiled from './ReferenceFiled';
import { Reference } from '~/types';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { useTranslate } from 'react-admin';
import { ReferenceType } from '~/types/Reference';
import { isEmpty } from 'lodash';

interface Props {
  references?: Reference[];
  type: ReferenceType;
  noPaddingLeft?: boolean;
  topSpacing?: number;
  withIcon?: boolean;
}

const AffiliatedWith = (props: Props) => {
  const { references, type, noPaddingLeft, topSpacing, withIcon } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.AffiliatedObjects.${key}`);
  if (!references) return null;
  if (
    isEmpty(
      references?.filter((reference) => reference.referenceType === type && !reference.deleted)
    )
  )
    return null;
  return (
    <>
      <H3
        topSpacing={topSpacing}
        noPaddingLeft={noPaddingLeft}
        icon={withIcon ? <SupervisorAccountIcon /> : undefined}
      >
        {t(type)}
      </H3>
      <Grid container spacing={1}>
        {references
          .filter((item) => item.referenceType === type && !item.deleted)
          .map((item) => (
            <Grid item xs={12}>
              <ReferenceFiled
                withDefaultIcon
                record={{ id: item.objectId, type: item.objectType }}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default AffiliatedWith;
