import {
  Feedback,
  FeedbackFilters,
  FeedbackStatistic,
  FeedbackStatisticFilters,
} from '~/types/Feedback';
import { urlWithParams } from '~/utils';
import axios from '~/utils/axios';

export const getFeedback = (params: FeedbackFilters) => {
  return axios.get<{ cursors: { next?: string }; feedbacks: Feedback[] }>(
    urlWithParams('/events', params)
  );
};

export const getFeedbackStatistic = (params: FeedbackStatisticFilters) => {
  return axios.get<FeedbackStatistic>(urlWithParams('/feedback/statistic', params));
};
