import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { Deal } from '~/types/Deal';
import { TextField } from '~/components';

const DealAdditionalProperties: FC<any> = (props) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.deals.show.details.${key}`);
  const deal = record as Deal;

  return (
    <>
      <Grid item xs={4}>
        <TextField label={t('reportingCategory')}>{deal.details.reportingCategory}</TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('comment')}>{deal.details.comment}</TextField>
      </Grid>
    </>
  );
};

export default DealAdditionalProperties;
