export default {
  name: 'Message template |||| Message templates',
  list: {
    key: 'Key',
    group: 'Group',
    value: 'Value',
    locale: 'Language',
    templateEngine: 'Template engine',
  },
  edit: {
    title: 'Update message template',
  },
  create: {
    title: 'Add new message template',
  },
  fields: {
    key: 'Key',
    group: 'Group',
    locale: 'Locale',
    context: 'Context',
    templateEngine: {
      _: 'Template engine',
      plain: 'Plain',
      thymeleaf: 'Thymeleaf',
    },
    value: 'Value',
  },
  export: {
    dialog: {
      title: 'Export localization file',
      content:
        'Message templates are being exported to CSV format. Check browsers downloads panel for the exported file and close this dialog once download completes.',
    },
  },
  import: {
    dialog: {
      title: 'Import localization file',
    },
    fileInputMessage: "Drag 'n' drop localization file here, or click to select file",
    fileInputHint: 'only *.csv files will be accepted',
    fileInputResult: 'You have selected file:',
    successMessage: 'The file has been imported successfully!',
    errorMessage: 'Error occurred during file import!',
  },
};
