import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { H2, TextField, StatusField } from '~/components';
import { Section } from '~/layout';
import { ApiIdentity } from '~/types/apiIdentity';
import AuthenticationMethods from './AuthenticationMethods';
import ActiveIdentityLocks from './lockIdentity/ActiveIdentityLocks';
import { useApiIdentityTranslate } from '.';

interface Props {
  record: ApiIdentity | undefined;
  loading?: boolean;
}

const ApiIdentityDetails: FC<Props> = (props) => {
  const { loading, record } = props;

  const t = useApiIdentityTranslate();

  const classes = useStyles();

  return (
    <>
      <Grid container justify="space-between" className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
            {t(`identificationData`)}
          </H2>
        </Grid>
      </Grid>
      <Section className={classes.bottomSpacing3}>
        <Grid container spacing={8}>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('name')} loading={loading}>
                {record?.name}
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('phone')} loading={loading}>
                {record?.phoneNumber}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('email')} loading={loading}>
                {record?.emailAddress}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="column" xs={4}>
            <Grid item>
              <TextField label={t('status')} loading={loading}>
                <StatusField status="ACTIVE" />
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <div className={classes.bottomSpacing3}>
        <ActiveIdentityLocks record={record} loading={loading} />
      </div>
      <AuthenticationMethods record={record} loading={loading} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
  bottomSpacing3: {
    marginBottom: theme.spacing(3),
  },
}));

export default ApiIdentityDetails;
