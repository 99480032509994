import React, { FC, useState } from 'react';
import { useTranslate, required } from 'react-admin';
import { Checkbox, FormControlLabel, Grid, IconButton } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import DeleteIcon from '@material-ui/icons/HighlightOff';

import { useHandbook } from '~/hooks';
import { DayName } from '~/types';
import TimeInput from './TimeInput';
import { Button } from '~/components/ra';
import { cloneDeep, first, get, last } from 'lodash';

interface Props {
  country?: string;
  source: string;
}

export const useFirstWeekDay = (country?: string) => {
  const hb = useHandbook();
  const firstWeekDay =
    hb.data?.countries.find((item) => item.id === country)?.firstDayOfWeek || 'MO';
  return { ...hb, data: firstWeekDay };
};

const WorkingHoursInput: FC<Props> = (props) => {
  const { country, source } = props;
  const { data: firstWeekDay, loading } = useFirstWeekDay(country);

  if (loading) return null;
  return (
    <Grid container spacing={1} direction="column">
      {getDaysOfWeek(firstWeekDay)?.map((day) => {
        return <DayItem day={day} source={[source, day, 'workingHours'].join('.')} />;
      })}
    </Grid>
  );
};

export const useTranslateDay = () => {
  const translate = useTranslate();
  return (day: string) => translate(`days.${day}`);
};

interface DayItemProps {
  day: DayName;
  source: string;
}

const DayItem: FC<DayItemProps> = (props) => {
  const { day, source } = props;
  const t = useTranslateDay();

  const formValues = useFormState().values;
  const value = get(formValues, source);
  const form = useForm();
  const [open, setOpen] = useState(!!value);
  const handleChecked = (checked: boolean) => {
    if (!checked) {
      setOpen(false);
      form.change(source, undefined);
    } else {
      setOpen(true);
      form.change(source, [emptyTimeRange]);
    }
  };

  const timeRanges: [
    string | undefined,
    string | undefined
  ][] = value?.map?.(({ from, to }: TimeRange) => [from, to]);
  const lastIndex = Array.isArray(value) ? value.length : 0;
  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={open} onChange={(_, checked) => handleChecked(checked)} />}
          label={t(day)}
        />
      </Grid>
      {open && (
        <>
          {Array.isArray(value) &&
            value.map((item, index) => {
              const tr = cloneDeep(timeRanges);
              const prevRanges = tr.slice(0, index);
              const prevAndCurrentRanges = tr.slice(0, index + 1);
              const futureAndCurentRanges = tr.slice(index);
              const futureRanges = tr.slice(index + 1);

              const minFromTime = last(prevRanges.flat(1).filter(Boolean));
              const minToTime = last(withoutLastTo(prevAndCurrentRanges).flat(1).filter(Boolean));
              const maxFromTime = first(
                withoutFirstFrom(futureAndCurentRanges).flat(1).filter(Boolean)
              );
              const maxToTime = first(futureRanges.flat(1).filter(Boolean));

              return (
                <Grid key={index} container item spacing={1}>
                  <Grid item xs>
                    <TimeInput
                      key={`${index} ${timeRanges} + ${maxFromTime}`}
                      label="From"
                      source={[source, `[${index}]`, 'from'].join('.')}
                      validate={required()}
                      minTime={minFromTime}
                      maxTime={maxFromTime}
                    />
                  </Grid>
                  <Grid item xs>
                    <TimeInput
                      key={`${index} ${minToTime} + ${maxToTime}`}
                      label="To"
                      source={[source, `[${index}]`, 'to'].join('.')}
                      validate={required()}
                      minTime={minToTime}
                      maxTime={maxToTime}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        form.change(
                          source,
                          value.filter((_, i) => i !== index)
                        );
                      }}
                      disabled={index === 0}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          <Button
            label="Add time range"
            onClick={() => {
              form.change([source, `[${lastIndex}]`].join('.'), emptyTimeRange);
            }}
          />
        </>
      )}
    </Grid>
  );
};

const withoutLastTo = (ranges: [string | undefined, string | undefined][]) => {
  if (!ranges.length) return [];
  const lastRangeFrom = last(ranges)?.[0];
  return ranges.slice(0, ranges.length - 1).concat([lastRangeFrom, undefined]);
};

const withoutFirstFrom = (ranges: [string | undefined, string | undefined][]) => {
  if (!ranges.length) return [];
  const firstRangeTo = first(ranges)?.[1];
  return [[undefined, firstRangeTo]].concat(ranges.slice(1, ranges.length));
};

type TimeRange = { from: string | undefined; to: string | undefined };
const emptyTimeRange: TimeRange = { from: undefined, to: undefined };

export const getDaysOfWeek = (firstWeekDay: 'MO' | 'SU'): DayName[] | undefined => {
  const [su, weekdays, sa] = [['SU'], ['MO', 'TU', 'WE', 'TH', 'FR'], ['SA']] as const;
  return firstWeekDay === 'MO' ? [...weekdays, ...sa, ...su] : [...su, ...weekdays, ...sa];
};

export default WorkingHoursInput;
