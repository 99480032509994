import React from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import AppBar from './AppBar';
import Sidebar from './Sidebar';
import { makeStyles } from '@material-ui/core/styles';

export default (props: any) => {
  /*
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
     */
  const classes = useStyles();
  return (
    <Layout
      classes={{ content: classes.content }}
      {...props}
      sidebar={Sidebar}
      menu={Menu}
      appBar={AppBar}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: theme.spacing(2),
  },
}));
