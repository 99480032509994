import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import { TextField, AddressTable, H3, DocumentCard } from '~/components';
import Grid from '@material-ui/core/Grid';
import { Beneficiary } from '~/types/beneficiaries';
import BeneficiaryContactAndAddressEdit from './BeneficiaryContactAndAddressEdit';
import { Section } from '~/layout';
import BeneficiaryDocumentEdit from './BeneficiaryDocumentEdit';
import { DateTimeField } from '~/components/ra';
import { Longdash } from '~/utils';
import BeneficiaryPersonalInfoEdit from './BeneficiaryPersonalInfoEdit';

interface Props {
  record: Beneficiary | undefined;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
  dealId: string;
}

const BeneficiaryDetails: FC<Props> = (props) => {
  const { record, counterparty, dealId } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.deals.show.beneficiary.${key}`);
  const drawerHeading = `Main address for beneficiary ${record?.firstName || ''} ${
    record?.lastName || ''
  }`;
  if (!record) return null;
  return (
    <div>
      <Section noPaddings>
        <Section.Heading
          topSpacing={0}
          bottomSpacing={counterparty === 'senderData' ? 0 : 1}
          actions={
            counterparty === 'senderData' ? (
              <BeneficiaryPersonalInfoEdit
                record={record}
                counterparty={counterparty}
                dealId={dealId}
              />
            ) : undefined
          }
        >
          <H3 topSpacing={0} bottomSpacing={0}>
            {t('individualData')}
          </H3>
        </Section.Heading>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField label={t('firstName')}>{record?.firstName}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('lastName')}>{record?.lastName}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('birthDate')}>
              <DateTimeField record={record} source='birthDate' format='LL' emptyText={Longdash} />
            </TextField>
          </Grid>
        </Grid>

        <Section.Heading
          topSpacing={3}
          bottomSpacing={counterparty === 'senderData' ? 0 : 1}
          actions={
            counterparty === 'senderData' ? (
              <BeneficiaryContactAndAddressEdit
                record={record}
                counterparty={counterparty}
                dealId={dealId}
              />
            ) : undefined
          }
        >
          <H3 topSpacing={0} bottomSpacing={0}>
            {t('contactsAndAddress')}
          </H3>
        </Section.Heading>

        {record.address || record.phoneNumber ? (
          <Grid container spacing={2}>
            {record?.phoneNumber && (
              <Grid container item>
                <Grid item xs={4}>
                  <TextField label={t('phone')}>{record.phoneNumber}</TextField>
                </Grid>
              </Grid>
            )}
            {record?.address && (
              <Grid item>
                <AddressTable
                  address={record.address}
                  type='Main Address'
                  drawerHeading={drawerHeading}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          t('noData')
        )}
      </Section>

      <H3 topSpacing={3} bottomSpacing={1}>
        {t('documents')}
      </H3>
      {record?.document ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DocumentCard
              record={record}
              documentFamily='INDIVIDUAL'
              document={record.document}
              index={0}
              primary={false}
              editDocument={() => {
                return (
                  <BeneficiaryDocumentEdit
                    record={record}
                    dealId={dealId}
                    counterparty={counterparty}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      ) : (
        t('noData')
      )}
    </div>
  );
};

export default BeneficiaryDetails;
