export default {
  toolbar: {
    flex: 1,
    alignItems: 'center',
    minHeight: 'unset',
    marginTop: 16,
    marginBottom: 13,
  },
  actions: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 'unset',
  },
};
