import React, { useState, Fragment } from 'react';
import { useTranslate, FormDataConsumer, FileInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import lodashGet from 'lodash/get';

import { CreateButton, EditButton, Drawer, H3 } from '~/components';
import { EditForm } from '~/components/ra';
import { Individual, File } from '~/types';
import IndividualDocumentForm from './IndividualDocumentForm';
import { FileCard } from '~/components/FileCard';

const useStyles = makeStyles((theme) => ({
  fileInput: {
    opacity: 0.4,
    transition: `opacity ${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    '&:hover': {
      opacity: 1,
    },
    '& div': {
      padding: 0,
    },
    '& .previews': {
      display: 'none',
    },
  },
  placeholder: {
    display: 'flex',
    height: 170,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 42,
    },
  },
}));

const EmptyComponent = () => <div />;
const Placeholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.placeholder}>
      <AddIcon />
    </div>
  );
};

type FormData = {
  formData: Individual;
  [x: string]: any;
};
interface Props {
  record: Individual | undefined;
  actionsId?: string; // used to apply some css from BusinessShow
  index?: number;
  edit?: boolean;
  primary?: boolean;
  closeParentDrawer?: () => void;
  permissions: string[];
}

const AddOrEditDocument = (props: Props) => {
  const {
    record,
    index,
    edit,
    primary,
    actionsId = 'section-actions',
    closeParentDrawer,
    permissions,
  } = props;
  const [open, setOpen] = useState(false);
  const label = (key: string): string => `resources.individuals.fields.${key}`;
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (closeParentDrawer) {
      closeParentDrawer();
    }
  };

  const lastDocIndex = index || (!edit && record?.documents?.length) || 0;
  const getSource = (source: string): string =>
    primary ? `primaryDocument.${source}` : `documents.[${lastDocIndex}].${source}`;

  if (!record) return null;

  return (
    <Fragment>
      <div id={actionsId}>
        {edit ? (
          <EditButton onClick={handleOpen} disabled={!permissions?.includes('individual.update')} />
        ) : (
          <CreateButton
            onClick={handleOpen}
            variant='add'
            disabled={!permissions?.includes('individual.update')}
          />
        )}
      </div>
      <Drawer
        heading={translateLabel(edit ? 'documents.edit' : 'documents.add')}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource='individuals' record={record} closeParent={handleClose}>
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return (
                <Fragment>
                  <IndividualDocumentForm
                    labelBase={label('documents')}
                    solutionId={formData.solutionId}
                    country={formData.country}
                    getSource={getSource}
                    selector={getSource}
                    scopedFormData={formData}
                    onlyPrimary={primary}
                    edit={edit}
                  />
                  <H3>{translate(`components.ra.fields.DocumentCard.scans`)}</H3>
                  <Grid container spacing={1}>
                    {lodashGet(formData, getSource('files'))?.map((item: File, index: number) => {
                      return (
                        <Grid item xs={6} key={index}>
                          <FileCard
                            index={index}
                            item={item}
                            formData={formData}
                            getSource={getSource}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item xs={6}>
                      <FileInput
                        label=''
                        source={getSource('files')}
                        multiple
                        className={classes.fileInput}
                        children={<EmptyComponent />}
                        placeholder={<Placeholder />}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default AddOrEditDocument;
