import React, { Fragment } from 'react';
import {
  useTranslate,
  BooleanInput,
  NumberInput,
  TextInput,
  SelectInput,
  FormDataConsumer,
  required,
  minValue,
  maxValue,
} from 'react-admin';
import { AmountInput, DateInput, ReferenceInput } from '~/components/ra';
import lodashGet from 'lodash/get';
import { useForm } from 'react-final-form';

import { l } from '~/resources/pricings/PricingShow';
import { Moment } from '~/types';
import { getDateFromDayNumber } from '~/utils';

export const billingDateFormat = 'MMMM DD';

interface Props {
  getSource: (key: string) => string;
  prevRecord: any;
  currency: string;
}

const RegularFeeForm = (props: Props) => {
  const { getSource, prevRecord, currency } = props;
  const scopedPrevRecord = lodashGet(prevRecord, getSource('')) || {};

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  const form = useForm();
  const resetBillingDate = () => {
    const scopedFormData = lodashGet(form.getState().values, getSource(''));
    let day = 1;
    if (scopedPrevRecord.period === scopedFormData.period) {
      const billingDate = scopedPrevRecord.billingDate;
      if (typeof billingDate === 'number') {
        day = billingDate;
      }
    }
    form.change(getSource('billingDate'), day);
  };

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        const scopedFormData = lodashGet(formData, getSource(''));
        return (
          <Fragment>
            <SelectInput
              source={getSource('type')}
              label={t('type')}
              choices={[
                { id: 'fixedFee', name: t('fixedFee') },
                { id: 'tierBasedTurnover', name: t('tierBasedTurnover'), disabled: true },
                { id: 'tierBasedBalance', name: t('tierBasedBalance'), disabled: true },
              ]}
              validate={required()}
            />
            <ReferenceInput
              source={getSource('pnlAccountId')}
              reference="tenant/accounts"
              validate={required()}
              label={t('pnlAccount')}
              filter={{ currency }}
            >
              <SelectInput optionText="alias" required />
            </ReferenceInput>
            <SelectInput
              source={getSource('period')}
              label={t('period')}
              choices={[
                { id: 'annually', name: t('annually') },
                { id: 'monthly', name: t('monthly') },
              ]}
              onChange={resetBillingDate}
              validate={required()}
            />
            <TextInput source={getSource('name')} label={t('statementTitle')} />
            <AmountInput
              source={getSource('amount')}
              permanentSelectedCurrency={currency}
              validate={required()}
              fullWidth
            />
            <SelectInput
              source={getSource('billingDateType')}
              label={t('billingDateType')}
              choices={[
                { id: 'fixed', name: t('fixed') },
                { id: 'sliding', name: t('sliding') },
              ]}
              validate={required()}
            />
            {scopedFormData &&
              scopedFormData.period === 'annually' &&
              scopedFormData.billingDateType === 'fixed' && (
                <DateInput
                  source={getSource('billingDate')}
                  label={t('billingDate')}
                  options={{ initialFocusedDate: '02/01/2020', format: billingDateFormat }}
                  parse={(x: Moment) => {
                    return x.dayOfYear();
                  }}
                  format={getDateFromDayNumber}
                  validate={required()}
                />
              )}
            {scopedFormData &&
              scopedFormData.period === 'monthly' &&
              scopedFormData.billingDateType === 'fixed' && (
                <NumberInput
                  source={getSource('billingDate')}
                  label={t('billingDate')}
                  validate={[required(), minValue(1), maxValue(31)]}
                  // Doesn't work https://github.com/marmelab/react-admin/issues/4900
                  // min={1}
                  // max={31}
                />
              )}
            {scopedFormData && scopedFormData.period === 'monthly' && (
              <BooleanInput
                source={getSource('payOnTheFirstCalendarMonth')}
                label={t('payOnTheFirstCalendarMonth')}
                defaultValue={false}
              />
            )}
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default RegularFeeForm;
