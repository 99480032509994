interface Props {
  children: (...props: any) => any;
  record?: any;
  basePath?: string;
  resource?: string;
  [x: string]: any;
}

const ShowController = (props: Props) => {
  const { children, record, ...rest } = props;

  return children({ showRecord: record, ...rest });
};

export default ShowController;
