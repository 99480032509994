import { useTranslate } from 'react-admin';
import { hasTranslation } from '~/utils';

type AddressFieldConfig =
  | {
    type: 'text';
    source: string;
    defaultlabel: string;
    required?: boolean;
    multiline?: boolean;
  }
  | {
    type: 'enum';
    source: string;
    values: string[];
    defaultlabel: string;
    required?: boolean;
  }
  | {
    type: 'country';
    source: string;
    defaultlabel: string;
  };

export type AddressFieldDashboardConfig =
  | {
    type: 'text';
    source: string;
    defaultlabel: string;
    label: string;
    required?: boolean;
    multiline?: boolean;
  }
  | {
    type: 'enum';
    source: string;
    values: string[];
    choices: { id: string; name: string }[];
    defaultlabel: string;
    label: string;
    required?: boolean;
  }
  | {
    type: 'country';
    source: string;
    defaultlabel: string;
    label: string;
  };

export const useAddressConfig = (
  country: string | undefined
): { data: AddressFieldDashboardConfig[] | null; isLoading: boolean } => {
  const translate = useTranslate();

  const config = country ? addressConfig[country] || addressConfig.default : null;
  return { data: toDashboardConfig(config, translate), isLoading: false };
};

const toDashboardConfig = (
  data: AddressFieldConfig[] | null,
  translate: (key: string) => string
): AddressFieldDashboardConfig[] | null => {
  if (!data) return data;
  const l = (key: string) => `components.address.${key}`;
  const t = (key: string) => translate(l(key));

  return data.map((item) => {
    switch (item.type) {
      case 'text': {
        return {
          ...item,
          label: hasTranslation(l(item.source)) ? t(item.source) : item.defaultlabel,
        };
      }
      case 'enum': {
        return {
          ...item,
          label: hasTranslation(l(item.source)) ? t(item.source) : item.defaultlabel,
          choices: item.values.map((value) => {
            const name = hasTranslation(l(value)) ? t(value) : value;
            return { id: value, name };
          }),
        };
      }
      default:
        return {
          ...item,
          label: hasTranslation(l(item.source)) ? t(item.source) : item.defaultlabel,
        };
    }
  });
};

const addressConfig: Record<string, AddressFieldConfig[]> = {
  default: [
    {
      type: 'country',
      source: 'country',
      defaultlabel: 'Country',
    },
    {
      type: 'text',
      source: 'postalCode',
      defaultlabel: 'Postal Code',
    },
    {
      type: 'text',
      source: 'region',
      defaultlabel: 'Region',
    },
    {
      type: 'text',
      source: 'city',
      defaultlabel: 'City',
    },
    {
      type: 'text',
      source: 'cityLocalized',
      defaultlabel: 'City localized',
    },
    {
      type: 'text',
      source: 'addressLine',
      defaultlabel: 'Street address',
      multiline: true,
    },
  ],
  ZA: [
    {
      type: 'text',
      source: 'building',
      defaultlabel: 'Complex or Building',
    },
    {
      type: 'text',
      source: 'addressLine',
      defaultlabel: 'Street address',
      required: true,
      multiline: true,
    },
    {
      type: 'text',
      source: 'suburb',
      defaultlabel: 'Suburb',
      required: true,
    },
    {
      type: 'text',
      source: 'city',
      defaultlabel: 'City/Town',
      required: true,
    },
    {
      type: 'enum',
      source: 'region',
      values: [
        'Eastern Cape',
        'Free State',
        'Gauteng',
        'KwaZulu-Natal',
        'Limpopo',
        'Mpumalanga',
        'North West',
        'Northern Cape',
        'Western Cape',
      ],
      defaultlabel: 'Province',
      required: true,
    },
    {
      type: 'text',
      source: 'postalCode',
      defaultlabel: 'Postal Code',
      required: true,
    },
    {
      type: 'country',
      source: 'country',
      defaultlabel: 'Country',
    },
  ],
  ZW: [
    {
      type: 'text',
      source: 'building',
      defaultlabel: 'Complex or Building',
    },
    {
      type: 'text',
      source: 'addressLine',
      defaultlabel: 'Street address',
      required: true,
      multiline: true,
    },
    {
      type: 'text',
      source: 'suburb',
      defaultlabel: 'Suburb',
      required: false,
    },
    {
      type: 'text',
      source: 'city',
      defaultlabel: 'City/Town',
      required: true,
    },
    {
      type: 'enum',
      source: 'region',
      values: [
        'Bulawayo Province',
        'Harare Province',
        'Manicaland Province',
        'Mashonaland Central Province',
        'Mashonaland East Province',
        'Mashonaland West Province',
        'Masvingo Province',
        'Matabeleland North Province',
        'Matabeleland South Province',
        'Midlands Province',
      ],
      defaultlabel: 'Province',
      required: true,
    },
    {
      type: 'text',
      source: 'postalCode',
      defaultlabel: 'Postal Code',
    },
    {
      type: 'country',
      source: 'country',
      defaultlabel: 'Country',
    },
  ],
};

export default useAddressConfig;
