import React from 'react';
import { Field } from 'react-final-form';
import { NumberInput, SelectInput, TextInput } from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';

import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { FeeInput, ReferenceInput } from '~/components/ra';

const useStyles = makeStyles({
  input: { color: '#aaa !important' },
});

const withDefaultValue = (BaseInput, parse) => {
  const InputWrapper = ({ source, defaultValue, ...inputProps }) => {
    const classes = useStyles();
    return (
      <Field name={source}>
        {(props) => {
          const onChange = (e) => {
            const value = e.target.value;
            const f =
              parse ||
              ((v) => {
                return v;
              });
            props.input.onChange(value === defaultValue ? '' : f(value));
          };
          const actualValue = props.input.value || defaultValue;
          return (
            <BaseInput
              source={source}
              value={actualValue}
              onChange={onChange}
              {...inputProps}
              className={classnames({
                [classes.default]: actualValue === defaultValue,
              })}
              InputProps={actualValue === defaultValue ? { className: classes.input } : null}
            />
          );
        }}
      </Field>
    );
  };

  return InputWrapper;
};

const wrappedTextInput = withDefaultValue(TextInput);
const wrappedSelectInput = withDefaultValue(SelectInput);
const wrappedNumberInput = withDefaultValue(NumberInput, (v) => {
  return v ? parseFloat(v) : v;
});
const wrappedReferenceInput = withDefaultValue(ReferenceInput);

const wrappedFeeInput = withDefaultValue(FeeInput);

export {
  DateInput,
  DateTimeInput,
  wrappedTextInput as TextInput,
  wrappedSelectInput as SelectInput,
  wrappedNumberInput as NumberInput,
  wrappedReferenceInput as ReferenceInput,
  wrappedFeeInput as FeeInput,
};
