import onError from '~/errorsHandler';

export const copy = (text: string, onSuccess?: () => void) => {
  return navigator.clipboard.writeText(text || '').then(
    () => {
      onSuccess?.();
      return Promise.resolve(true);
    },
    (error) => {
      onError(error);
      return Promise.resolve(false);
    }
  );
};
