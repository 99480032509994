import { store } from '~/App';
import { updateEventLogAction } from '~/ducks/eventLog';

const eventLogUpdate = (resource: string) => {
  switch (resource) {
    case 'individuals':
    case 'businesses':
    case 'partners':
      store.dispatch(updateEventLogAction(new Date().getTime()));
      break;
    default:
      break;
  }
};

export default eventLogUpdate;
