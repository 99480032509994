const FEE = {
  type: {
    _: 'Fee type',
    fixedDebitFee: 'Fixed Debit Fee',
    fixedDebitFeeDecrease: 'Fixed Debit Fee (decrease)',
    percentDebitFee: 'Percent Debit Fee',
    percentDebitFeeDecrease: 'Percent Debit Fee (decrease)',
    fixedCreditFee: 'Fixed Credit Fee',
    percentCreditFee: 'Percent Credit Fee',
  },
  value: {
    name: 'Fee name',
    percent: 'Percent',
    amount: { currency: 'Currency', value: 'Amount' },
    minAmount: { currency: 'Currency', value: 'Min amount (optional)' },
    maxAmount: { currency: 'Currency', value: 'Max amount (optional)' },
  },
};

export default {
  data: {
    veengu: {
      individual: {
        onboarding: {
          self: {
            _: 'Individual Self-onboarding',
            product: {
              _: 'Product mapping',
              code: 'Default product code',
            },
          },
        },
        p2p: {
          transfer: {
            _: 'P2P Transfer',
            recipient: {
              _: 'Recipient fee',
              transferFee: FEE,
            },
            sender: {
              _: 'Sender fee',
              transferFee: FEE,
            },
          },
        },
        purchase: {
          byQr: {
            _: 'Purchase by QR',
            sender: {
              _: 'Sender fee',
              transferFee: FEE,
            },
          },
        },
      },
      business: {
        onboarding: {
          dashboard: {
            _: 'Business onboarding via Dashboard',
            product: {
              _: 'Product mapping',
              code: 'Default product code',
            },
          },
        },
        purchase: {
          byStaticQr: {
            _: 'Purchase acceptance by static QR',
            recipient: {
              _: 'Recipient fee',
              transferFee: FEE,
            },
          },
          byMobilePos: {
            _: 'Purchase acceptance by Mobile POS',
            recipient: {
              _: 'Recipient fee',
              transferFee: FEE,
            },
          },
        },
      },
      email: {
        transmission: {
          _: 'Email Transmission',
          connection: {
            _: 'Connection',
            transport: {
              _: 'Transport',
              SMTP: 'STMP',
              SMTPS: 'STMP SSL',
              SMTP_TLS: 'STMP TLS',
            },
            host: 'Host',
            port: 'Port',
            username: 'Username',
            password: 'Password',
            proxy: {
              _: 'Proxy server',
              host: 'Host',
              port: 'Port',
              username: 'Username',
              password: 'Password',
            },
          },
          sender: {
            _: 'Sender',
            address: 'Address',
            name: 'Name',
          },
          other: {
            _: 'Other',
            sessionTimeout: 'Session timeout',
          },
        },
      },
    },
  },
};
