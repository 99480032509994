import React, { useState, Fragment, FC } from 'react';
import { SaveButton } from 'react-admin';
import { usePermissionsOptimized } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { SimpleForm } from '~/components/ra';
import Toolbar from '~/components/ra/Toolbar';
import { handleFiles } from '~/dataProvider';
import { useBeneficiaryDataMutation } from '~/hooks/dealBeneficiary';
import { Beneficiary } from '~/types/beneficiaries';

interface Props {
  record: Beneficiary | undefined;
  dealId: string;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
  heading: string;
}

const BeneficiaryEdit: FC<Props> = (props) => {
  const { record, dealId, counterparty, children, heading } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const beneficiaryMutation = useBeneficiaryDataMutation({ dealId, counterparty });
  const handleSave = async (formData: any) => {
    await handleFiles('deals', formData);
    await beneficiaryMutation.mutateAsync({ ...record, ...formData });
    handleClose();
  };

  const { permissions } = usePermissionsOptimized();
  if (!permissions?.includes('deal.update')) return null;
  return (
    <Fragment>
      <EditButton onClick={handleOpen} />
      <Drawer heading={heading} open={open} onClose={handleClose}>
        <SimpleForm
          initialValues={record}
          record={record}
          saving={beneficiaryMutation.isLoading}
          toolbar={
            <Toolbar>
              <SaveButton onSave={handleSave} />
            </Toolbar>
          }
        >
          {children}
        </SimpleForm>
      </Drawer>
    </Fragment>
  );
};

export default BeneficiaryEdit;
