import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ProgressBar from './MetricProgressBar';

export const useTranslateProfileLimits = () => {
  const translate = useTranslate();
  return (key: string): string => translate(`components.MetricField.${key}`);
};

interface Props {
  name: string;
  value: string;
  target: string;
  progressPercent: number;
  inline?: boolean;
}

const MetricProgressField: FC<Props> = (props) => {
  const { name, value, target, progressPercent } = props;
  const classes = useStyles(props);
  const t = useTranslateProfileLimits();

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        <div className={classes.name}>{name}</div>
        <div className={classes.valueWrapper}>
          <div className={classes.value}>{value}</div>
          <div className={classes.value}>
            {Number.isFinite(progressPercent) ? (
              <span className={classes.outOf}>{t('outOf')}</span>
            ) : (
              ''
            )}{' '}
            {target}
          </div>
        </div>
      </div>
      <ProgressBar value={progressPercent} />
    </div>
  );
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  textWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ({ inline }) => (inline ? 'row' : 'column'),
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  valueWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  // typography
  name: {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: ({ inline }) => (inline ? 8 : 0),
    marginBottom: ({ inline }) => (inline ? 0 : 3),
  },
  value: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginLeft: 3,
    whiteSpace: 'nowrap',
  },
  outOf: {
    fontSize: '12px',
  },
}));

export default MetricProgressField;
