import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { SettingsIcon } from '~/img';
import { TextField, H3, AmountField, HilightChanges } from '~/components';
import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';
import { AcquiringFeeType } from '~/api/pricings';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
  },
}));

interface Props {
  record: any;
  prevRecord: any;
}

type AcqusitionFeeType = {
  [x: string]: any;
};

interface FeeFieldProps {
  record?: AcqusitionFeeType;
  prevRecord?: AcqusitionFeeType;
}

const AcquiringFeeField = (props: FeeFieldProps) => {
  const { record = {}, prevRecord = {} } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField label={t('feeType')}>
          <HilightChanges curr={record.type} prev={prevRecord.type}>
            {record.type && t(record.type)}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('statementTitle')}>
          <HilightChanges curr={record.name} prev={prevRecord.name}>
            {record.name}
          </HilightChanges>
        </TextField>
      </Grid>
      {(function () {
        switch (record.type as AcquiringFeeType | undefined) {
          case 'FIXED_INCREASE':
          case 'FIXED_DECREASE':
            return (
              <Grid item xs={12}>
                <TextField label={t('amount')}>
                  <HilightChanges curr={record.amount} prev={prevRecord.amount}>
                    <AmountField amount={record.amount} />
                  </HilightChanges>
                </TextField>
              </Grid>
            );
          case 'PERCENT_INCREASE':
          case 'PERCENT_DECREASE':
            return (
              <Fragment>
                <Grid item xs={12}>
                  <TextField label={t('percent', 2)}>
                    <HilightChanges curr={record.percent} prev={prevRecord.percent}>
                      {record.percent?.toString() && `${record.percent}%`}
                    </HilightChanges>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('minAmount')}>
                    <HilightChanges curr={record.minAmount} prev={prevRecord.minAmount}>
                      <AmountField amount={record.minAmount} />
                    </HilightChanges>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('maxAmount')}>
                    <HilightChanges curr={record.maxAmount} prev={prevRecord.maxAmount}>
                      <AmountField amount={record.maxAmount} />
                    </HilightChanges>
                  </TextField>
                </Grid>
              </Fragment>
            );
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

interface FeeContentProps {
  record?: AcqusitionFeeType;
  prevRecord?: AcqusitionFeeType;
}

export const AcquiringFeeContent = (props: FeeContentProps) => {
  const { record = {}, prevRecord = {} } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('feeConfiguration')}
        </H3>
      </Grid>
      <AcquiringFeeField record={record} prevRecord={prevRecord} />
    </Fragment>
  );
};

const FeeConfirmationField = (props: Props) => {
  const { record = {}, prevRecord = {} } = props;
  const classes = useStyles();

  return (
    <Section className={classes.root}>
      <Grid item container spacing={2}>
        <AcquiringFeeContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default FeeConfirmationField;
