import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { H3 } from '~/components';
import { AddressForm, PhoneNumberInput } from '~/components/ra';

import { Beneficiary } from '~/types/beneficiaries';
import BeneficiaryEdit from './BeneficiaryEdit';
import { l } from './BeneficiaryInfo';

interface Props {
  record: Beneficiary | undefined;
  dealId: string;
  counterparty: 'remitterData' | 'senderData' | 'recipientData';
}

const BeneficiaryContactAndAddressEdit: FC<Props> = (props) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <BeneficiaryEdit heading={t('editContactsAndAddress')} {...props}>
      <H3>{t('contacts')}</H3>
      <PhoneNumberInput source='phoneNumber' label={t('phone')} fullWidth />
      <H3>{t('address')}</H3>
      <AddressForm fullWidth />
    </BeneficiaryEdit>
  );
};

export default BeneficiaryContactAndAddressEdit;
