import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  onClick: () => void;
  label: string;
}

const RecordTitleButton = (props: Props) => {
  const { onClick, label } = props;
  const classes = useStyles();
  return (
    <button className={classes.underlined} onClick={onClick}>
      {label}
    </button>
  );
};

export default RecordTitleButton;

const useStyles = makeStyles((theme) => ({
  underlined: {
    display: 'inline',
    border: 'none',
    background: 'none',
    fontSize: 'inherit',
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    padding: 0,
    margin: 0,
    transition: `${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    '&:hover': {
      opacity: 0.8,
    }
  },
}));
