import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { TextField, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { CountryAutocomplete, SelectInput, SolutionSelect, KycLevelSelect } from '~/components';
import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { usePermissions } from 'react-admin';
import { IndividualFilters } from '~/types/individual';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import IndividualCreateAction from '../../IndividualCreateAction';
import CustomModule from '~/modules';

//--------------------------------------------
const resource = 'individuals';

interface Props {
  cleanFilters?: boolean;
}

const Filters = (props: Props) => {
  const { cleanFilters = true } = props;
  const filters = useSelector(selectors.getFilters<IndividualFilters>(resource));
  const [localValues, setLocalValues] = useState<IndividualFilters>(filters);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const t = (key: string): string => translate(`resources.individuals.fields.${key}`);

  const { permissions } = usePermissions();
  const hasExtendedSearchPermission = permissions?.includes('individual.search.extended');

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 500), []);

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    debouncedDispatchFilterChange(inputType, data);
  };

  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const filtersAreEmpty = isEmpty(filters);
  useEffect(() => {
    if (cleanFilters || filtersAreEmpty) {
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            withDeleted: false,
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty]);

  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };

  console.log(`localValues:`, localValues);

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div style={{ maxWidth: 'calc(100% - 310px)' }}>
            {hasExtendedSearchPermission ? (
              <div className={classes.inputsGroup}>
                <TextField
                  className={classes.formControl}
                  label={t('name')}
                  onChange={debouncedHandleInput}
                  value={localValues.name ?? ''}
                  name='name'
                />
                <TextField
                  className={classes.formControl}
                  label={t('phoneNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.phoneNumber ?? ''}
                  name='phoneNumber'
                  placeholder='+'
                />
                <TextField
                  className={classes.formControl}
                  label={t('accountNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.accountNumber ?? ''}
                  name='accountNumber'
                />
                <TextField
                  className={classes.formControl}
                  label={t('documentNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.primaryDocumentNumber ?? ''}
                  name='primaryDocumentNumber'
                />
                <SolutionSelect
                  className={classes.formControl}
                  label={t('solutionId')}
                  onChange={handleSelectInput}
                  value={localValues.solution ?? ''}
                  name='solution'
                  withFilter={(solution) => solution.features.includes('veengu_individual_profile')}
                  allowEmpty
                />
                <CountryAutocomplete
                  className={classes.formControl}
                  label={t('country')}
                  value={localValues.country}
                  name='country'
                  onChange={handleSelectInput}
                  fullWidth
                />
                <KycLevelSelect
                  className={classes.formControl}
                  label={t('kycLevel')}
                  onChange={debouncedHandleInput}
                  value={localValues.kycLevel ?? ''}
                  name='kycLevel'
                  withFeature='veengu_individual_profile'
                  allowEmpty
                />
                <SelectInput
                  className={classes.formControl}
                  value={localValues.kycStatus}
                  label={t('kycStatus')}
                  name='kycStatus'
                  onChange={handleSelectInput}
                  allowEmpty={false}
                  options={[
                    { value: '', text: t('any') },
                    { value: 'ACTIVE', text: t('active') },
                    { value: 'PENDING', text: t('pending') },
                  ]}
                  defaultValue=''
                />
                <DateTimePicker
                  className={classes.datePickers}
                  format='L - LT'
                  margin='normal'
                  label={t('createdAtFrom')}
                  value={localValues.fromCreatedAt || null}
                  onChange={(date) => handleDateTimeChange(date as Moment, 'fromCreatedAt')}
                  clearable
                />
                <DateTimePicker
                  className={classes.datePickers}
                  format='L - LT'
                  margin='normal'
                  label={t('createdAtTo')}
                  value={localValues.toCreatedAt || null}
                  onChange={(date) => handleDateTimeChange(date as Moment, 'toCreatedAt')}
                  clearable
                />
                <SelectInput
                  className={classes.formControl}
                  value={localValues.status}
                  label={t('status')}
                  name='status'
                  onChange={handleSelectInput}
                  allowEmpty={false}
                  options={[
                    { value: '', text: t('any') },
                    { value: 'ACTIVE', text: t('active') },
                    { value: 'BLOCKED', text: t('blocked') },
                    { value: 'CLOSED', text: t('closed') },
                  ]}
                  defaultValue=''
                />
                <SelectInput
                  className={classes.formControl}
                  value={localValues.affiliationType}
                  label={t('affiliationType')}
                  name='affiliationType'
                  onChange={handleSelectInput}
                  allowEmpty={false}
                  options={[
                    { value: '', text: t('any') },
                    { value: 'INVITED_BY', text: t('INVITED_BY') },
                    { value: 'CLONED_FROM', text: t('CLONED_FROM') },
                  ]}
                  defaultValue=''
                />
                <TextField
                  className={classes.formControl}
                  label={t('affiliatedObjectId')}
                  onChange={debouncedHandleInput}
                  value={localValues.affiliatedObjectId ?? ''}
                  name='affiliatedObjectId'
                />
                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Switch
                      onChange={(e) => {
                        debouncedHandleInput({
                          target: {
                            value: e.target.checked,
                            name: 'withDeleted',
                          },
                        });
                      }}
                      value={localValues.withDeleted ?? ''}
                    />
                  }
                  label={t('withDeleted')}
                />
              </div>
            ) : (
              <div className={classes.inputsGroup}>
                <TextField
                  className={classes.formControl}
                  label={t('name')}
                  onChange={debouncedHandleInput}
                  value={localValues.name ?? ''}
                  name='name'
                />
                <TextField
                  className={classes.formControl}
                  label={t('phoneNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.phoneNumber ?? ''}
                  name='phoneNumber'
                  placeholder='+'
                />
                <TextField
                  className={classes.formControl}
                  label={t('accountNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.accountNumber ?? ''}
                  name='accountNumber'
                />
                <TextField
                  className={classes.formControl}
                  label={t('documentNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.primaryDocumentNumber ?? ''}
                  name='primaryDocumentNumber'
                />
              </div>
            )}
          </div>
          <div style={{ minWidth: 306, textAlign: 'right' }}>
            <IndividualCreateAction />
            <BackgroundTaskExport
              type='INDIVIDUAL_EXPORT'
              queryArguments={handleFilters(localValues)}
            />
            <CustomModule name='IndividualImport' />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const handleFilters = (filters: IndividualFilters) => {
  if (!filters) return {};
  return filters;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  inputsGroup: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  formControl: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '&:last-child': {
      marginRight: 0,
    },
  },
  datePickers: {
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: 200,
    '& > div': {
      paddingRight: 0,
      '& > div': {
        marginLeft: 0,
      },
    },
  },
}));

export default Filters;
