import React from 'react';
import lodashGet from 'lodash/get';

import BooleanFieldComponent from '~/components/BooleanField';

interface Props {
  record?: any;
  source?: string;
  label?: string;
  nullOnFalse?: boolean;
  [x: string]: any;
}

const BooleanField = (props: Props) => {
  const { record, source = '', label = '', ...rest } = props;
  return <BooleanFieldComponent label={label} value={lodashGet(record, source)} {...rest} />;
};

export default BooleanField;
