import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useForm, useFormState } from 'react-final-form';

import { AddDeleteButton } from '~/components';
import { FileField } from '~/components/ra';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import FileInput from './FileInput';

interface Props {
  source: string;
  multiple?: boolean;
  maxCount?: number;
  anonymous?: boolean;
}

const ImageInput: FC<Props> = (props) => {
  const { anonymous, source, multiple = false, maxCount = Infinity } = props;

  const formValues = useFormState().values;
  const icon = get(formValues, source);
  const classes = useStyles();

  const isLimitReached = multiple ? icon?.length === maxCount : !!icon;

  return (
    <Grid container spacing={1}>
      {Array.isArray(icon) ? (
        icon.map((item, index) => {
          return (
            <Grid item xs={6}>
              <FileCard index={index} icon={item} formValues={formValues} source={source} />
            </Grid>
          );
        })
      ) : icon ? (
        <Grid item xs={6}>
          <FileCard icon={icon} formValues={formValues} source={source} />
        </Grid>
      ) : null}
      <Grid item xs={6}>
        <div style={{ display: isLimitReached ? 'none' : 'unset' }}>
          <FileInput
            label=""
            source={source}
            accept="image/*"
            multiple={multiple}
            className={classes.fileInput}
            children={<EmptyComponent />}
            placeholder={<Placeholder />}
            anonymous={anonymous}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const FileCard = (props: { source: string; icon: any; formValues: any; index?: number }) => {
  const { index, icon, source, formValues } = props;
  const form = useForm();
  const files = get(formValues, source);

  return (
    <AddDeleteButton
      onDeleteClick={() =>
        form.change(
          source,
          Array.isArray(files) ? files.filter((icon: any, i: number) => i !== index) : undefined
        )
      }
    >
      <FileField
        record={icon}
        withPreview
        imageFieldProps={{
          width: '100%',
          height: 170,
          objectFit: 'contain',
          noText: true,
        }}
      />
    </AddDeleteButton>
  );
};

const EmptyComponent = () => <div />;
const Placeholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.placeholder}>
      <AddIcon />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fileInput: {
    opacity: 0.4,
    transition: `opacity ${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    '&:hover': {
      opacity: 1,
    },
    '& div': {
      padding: 0,
    },
    '& .previews': {
      display: 'none',
    },
  },
  placeholder: {
    display: 'flex',
    height: 170,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 42,
    },
  },
}));

export default ImageInput;
