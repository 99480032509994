import React, { useCallback, useEffect, useState } from 'react';
import { throttle, uniqBy } from 'lodash';
import { useTranslate } from 'react-admin';

import { Grid } from '@material-ui/core';

import { SelectInput, TextInput } from '~/components';

import { createConsent, CreateOrUpdateConsentRequest, updateConsent } from '~/api/settlement';
import { Amount } from '~/types';
import { HeadTransaction } from '~/types/HeadTransaction';
import { ConsentHolder, RunTransferActionFormProps } from './RunTransferActionTypes';

import { l } from './RunTransferAction';
import AmountInput from '~/components/AmountInput';
import { useTenantAccounts } from '~/hooks/tenant';
import { useGetAllAccounts } from '~/hooks/accounts';

type SettlementSession = {
  isSending: boolean;
  nextRequest?: CreateOrUpdateConsentRequest;
  lastConsent?: HeadTransaction;
};

const SETTLEMENT_SESSION: SettlementSession = {
  isSending: false,
};

const NO_CONSENT: ConsentHolder = {};

const SettlementForm = ({ resource, profileId, onConsent }: RunTransferActionFormProps) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [debitAccountId, setDebitAccountId] = useState<string | undefined>();
  const [creditAccountId, setCreditAccountId] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [reference, setReference] = useState<string | undefined>();
  const [amount, setAmount] = useState<Amount | null>();

  const { data: tenantAccounts } = useTenantAccounts();
  const { data: profileAccounts } = useGetAllAccounts({
    resource,
    profileId,
  });

  const settlementAccounts = (tenantAccounts || []).filter((a) => a.type === 'SETTLEMENT');
  const accounts = uniqBy((profileAccounts || []).concat(settlementAccounts), ({ id }) => id);

  const debitAccount = debitAccountId ? accounts.find((a) => a.id === debitAccountId) : undefined;
  const creditAccount = creditAccountId
    ? accounts.find((a) => a.id === creditAccountId)
    : undefined;

  const debitOptions = creditAccount
    ? accounts.filter((a) => a.type !== creditAccount.type && a.currency === creditAccount.currency)
    : accounts;
  const creditOptions = debitAccount
    ? accounts.filter((a) => a.type !== debitAccount.type && a.currency === debitAccount.currency)
    : accounts;

  useEffect(() => {
    SETTLEMENT_SESSION.isSending = false;
    SETTLEMENT_SESSION.nextRequest = undefined;
    SETTLEMENT_SESSION.lastConsent = undefined;
  }, []);

  console.log(`all accounts:`, accounts);

  // const [session, setSession] = useState<SettlementSession>({ isSending: false });

  const throttledMakeConsent = useCallback(
    throttle(
      (
        debitAccountId: string,
        creditAccountId: string,
        amount: Amount,
        comment?: string,
        reference?: string
      ): any => {
        console.log('update, amount:', amount);
        const request = {
          senderAccountId: debitAccountId,
          recipientAccountId: creditAccountId,
          amount: amount,
          comment: comment,
          referenceId: reference,
        };

        const sendNextRequest = (request: CreateOrUpdateConsentRequest) => {
          if (SETTLEMENT_SESSION.isSending) {
            SETTLEMENT_SESSION.nextRequest = request;
          } else {
            SETTLEMENT_SESSION.isSending = true;
            SETTLEMENT_SESSION.nextRequest = undefined;

            if (SETTLEMENT_SESSION.lastConsent) {
              updateConsent(SETTLEMENT_SESSION.lastConsent.id, request)
                .then((response) => {
                  console.log(`updated consent: `, response.data);
                  SETTLEMENT_SESSION.isSending = false;
                  SETTLEMENT_SESSION.lastConsent = response.data;

                  const nextRequest = SETTLEMENT_SESSION.nextRequest;
                  if (nextRequest) {
                    SETTLEMENT_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ consents: [response.data], accounts });
                })
                .catch((e) => {
                  const errorCode = e?.body?.code;
                  console.log(`update consent failed: `, errorCode, e.body);
                  SETTLEMENT_SESSION.isSending = false;

                  const nextRequest = SETTLEMENT_SESSION.nextRequest;
                  if (nextRequest) {
                    SETTLEMENT_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ error: errorCode, accounts });
                });
            } else {
              createConsent(request)
                .then((response) => {
                  console.log(`now accounts:`, accounts);

                  console.log(`created consent: `, response.data);
                  SETTLEMENT_SESSION.isSending = false;
                  SETTLEMENT_SESSION.lastConsent = response.data;

                  const nextRequest = SETTLEMENT_SESSION.nextRequest;
                  if (nextRequest) {
                    SETTLEMENT_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ consents: [response.data], accounts });
                })
                .catch((e) => {
                  const errorCode = e?.body.code;
                  console.log(`create consent failed: `, errorCode, e.body);
                  SETTLEMENT_SESSION.isSending = false;

                  const nextRequest = SETTLEMENT_SESSION.nextRequest;
                  if (nextRequest) {
                    SETTLEMENT_SESSION.nextRequest = undefined;
                    sendNextRequest(nextRequest);
                  } // else do nothing

                  onConsent({ error: errorCode, accounts });
                });
            }
          }
        };

        console.log(`sending request`);
        sendNextRequest(request);
      },
      1000
    ),
    [tenantAccounts, profileAccounts]
  );

  useEffect(() => {
    if (debitAccountId && creditAccountId && amount) {
      onConsent(NO_CONSENT);
      throttledMakeConsent(debitAccountId, creditAccountId, amount, comment, reference);
    } else {
      onConsent(undefined);
    }
  }, [
    onConsent,
    throttledMakeConsent,
    debitAccountId,
    creditAccountId,
    amount,
    comment,
    reference,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          label={t('reference')}
          style={{ maxWidth: 490 }}
          onChange={(e: any) => {
            setReference(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={t('comment')}
          style={{ maxWidth: 490 }}
          multiline
          onChange={(e: any) => {
            setComment(e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <SelectInput<string | undefined>
          options={debitOptions.map((a) => ({
            value: a.id,
            text: a.alias || a.id,
          }))}
          label={t('debitAccount')}
          value={debitAccountId}
          onChange={(e) => {
            setDebitAccountId(e.target.value);
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          style={{
            width: 240,
          }}
          allowEmpty
        />
      </Grid>
      <Grid item>
        <SelectInput<string | undefined>
          options={creditOptions.map((a) => ({
            value: a.id,
            text: a.alias || a.id,
          }))}
          label={t('creditAccount')}
          value={creditAccountId}
          onChange={(e) => {
            setCreditAccountId(e.target.value);
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          style={{
            width: 240,
          }}
          allowEmpty
        />
      </Grid>
      <Grid item>
        <AmountInput
          onChange={(newAmount) => {
            if (newAmount?.value !== amount?.value || newAmount?.currency !== amount?.currency) {
              setAmount(newAmount);
            }
          }}
          label={t('amount')}
          permanentSelectedCurrency={debitAccount?.currency || creditAccount?.currency}
          disabled={!debitAccount && !creditAccount}
          currencyDisabled
        />
      </Grid>
    </Grid>
  );
};

export default SettlementForm;
