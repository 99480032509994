import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import { OpenIcon } from '~/img';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { useTranslate } from 'react-admin';
import { buildProfileLink } from '~/utils/identities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  PersonIcon as IndividualsIcon,
  TrailSign as PartnersIcon,
  ReaderIcon as TransactionIcon,
} from '~/img';
import ApiIdentitiesIcon from '@material-ui/icons/Fingerprint';
import DealIcon from '@material-ui/icons/Public';
import ReportsIcon from '@material-ui/icons/Assessment';
import BusinessesIcon from '@material-ui/icons/Store';
import SvgIcon from '@material-ui/core/SvgIcon';
import palette from '~/theme/palette';
import FeedbackIcon from '@material-ui/icons/StarHalfRounded';

const useStyles = makeStyles((theme) => ({
  openIcon: {
    width: '20px',
    height: '20px',
  },
  profileLink: {
    width: '450px',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  profileInput: {
    flex: 1,
  },
  profileLinkContainer: {
    padding: theme.spacing(2),
  },
  menuItem: {
    color: palette.profileMenu.textGray,
    gap: theme.spacing(2),
  },
  icon: {
    color: palette.profileMenu.iconGray,
  },
  button: {
    height: '45px',
  },
}));

const LinkToProfile = () => {
  const translate = useTranslate();
  const t = (key: string, params?: {}) =>
    translate(`components.AppBar.LinkToProfile.${key}`, params);
  const classes = useStyles();

  const validate = (textFieldValue: string): boolean => {
    const regexp = /^\S*$/;
    return !regexp.test(textFieldValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileType, setProfileType] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleClick = (event: any) => {
    setProfileType('');
    setTextFieldValue('');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileTypeChange = (type: string) => {
    setProfileType(type);
  };

  const handleOpenInNewTab = () => {
    const link = buildProfileLink(profileType, textFieldValue);
    const profile =
      profileType === 'INDIVIDUAL' || profileType === 'BUSINESS' || profileType === 'PARTNER'
        ? '/profile'
        : '';

    window.open(`${window.location.origin}${link}${profile}`, '_blank');
    handleClose();
  };

  const handlePressEnter = (event: any) => {
    if (event.key === 'Enter' && !validate(textFieldValue)) {
      handleOpenInNewTab();
    }
  };

  const handleBack = () => {
    setProfileType('');
    setTextFieldValue('');
  };

  return (
    <>
      <Tooltip title={t('openObject')}>
        <IconButton
          aria-controls='simple-menu'
          aria-haspopup='true'
          color='inherit'
          onClick={handleClick}
        >
          <OpenIcon className={classes.openIcon} />
        </IconButton>
      </Tooltip>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {profileType === '' ? (
          <div>
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('INDIVIDUAL')}
            >
              <SvgIcon className={classes.icon}>
                <IndividualsIcon />
              </SvgIcon>
              {t('INDIVIDUAL')}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('BUSINESS')}
            >
              <SvgIcon className={classes.icon}>
                <BusinessesIcon />
              </SvgIcon>
              {t('BUSINESS')}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('PARTNER')}
            >
              <SvgIcon className={classes.icon}>
                <PartnersIcon />
              </SvgIcon>
              {t('PARTNER')}
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem} onClick={() => handleProfileTypeChange('DEAL')}>
              <SvgIcon className={classes.icon}>
                <DealIcon />
              </SvgIcon>
              {t('DEAL')}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('HEAD_TRANSACTION')}
            >
              <SvgIcon className={classes.icon}>
                <TransactionIcon />
              </SvgIcon>
              {t('HEAD_TRANSACTION')}
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem} onClick={() => handleProfileTypeChange('EVENT')}>
              <SvgIcon className={classes.icon}>
                <FeedbackIcon />
              </SvgIcon>
              {t('EVENT')}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('API_IDENTITY')}
            >
              <SvgIcon className={classes.icon}>
                <ApiIdentitiesIcon />
              </SvgIcon>
              {t('API_IDENTITY')}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleProfileTypeChange('REPORT')}
            >
              <SvgIcon className={classes.icon}>
                <ReportsIcon />
              </SvgIcon>
              {t('REPORT')}
            </MenuItem>
          </div>
        ) : (
          <div className={classes.profileLinkContainer}>
            <Button
              color='primary'
              size='small'
              startIcon={<ArrowBackIosIcon />}
              onClick={handleBack}
            >
              back
            </Button>
            <div className={classes.profileLink}>
              <TextField
                label={t(`${profileType}_UUID`)}
                autoFocus
                InputProps={{ autoComplete: 'off', spellCheck: 'false' }}
                value={textFieldValue}
                onChange={(event) => setTextFieldValue(event.target.value)}
                className={classes.profileInput}
                onKeyDown={handlePressEnter}
                error={validate(textFieldValue)}
                helperText={validate(textFieldValue) ? t('incorrectLink') : undefined}
              />
              <Button
                color='primary'
                variant='contained'
                size='medium'
                onClick={handleOpenInNewTab}
                disabled={textFieldValue === '' || validate(textFieldValue)}
                className={classes.button}
              >
                {t('go')}
              </Button>
            </div>
          </div>
        )}
      </Menu>
    </>
  );
};

export default LinkToProfile;
