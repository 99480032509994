import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2 } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './IntegrationTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Longdash } from '~/utils';

const ResponsesSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`responses.${key}`));

  const responseEntries: { system?: string; code?: string; message?: string }[] = [];
  if (trx.details.response?.code || trx.details.response?.message) {
    responseEntries.push({
      system: undefined,
      code: trx.details.response?.code,
      message: trx.details.response?.message,
    });
  }
  if (trx.details.response?.systems) {
    Object.entries(trx.details.response!.systems!).forEach(([system, response]) => {
      responseEntries.push({
        system,
        code: response.code,
        message: response.message,
      });
    });
  }

  return (
    <>
      <H2 topSpacing={0} bottomSpacing={2}>
        {t('_')}
      </H2>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container item xs={12}>
          <Table className={cx(classes.responsesTable, { [classes.autoWidth]: true })}>
            <TableHead>
              <TableRow>
                <TableCell>{t('system')}</TableCell>
                <TableCell>{t('code')}</TableCell>
                <TableCell>{t('message')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseEntries.length > 0 ? (
                responseEntries.map((entry) => (
                  <TableRow key={`${entry.system}.${entry.code}.${entry.message}`}>
                    <TableCell>{entry.system || Longdash}</TableCell>
                    <TableCell>{entry.code || Longdash}</TableCell>
                    <TableCell>{entry.message || Longdash}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} style={{ textAlign: 'center', height: '2.5em' }}>
                    {t('noResponses')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Section>
    </>
  );
};

export default ResponsesSection;
