import React from 'react';
import { Datagrid, TextField, Filter, TextInput, usePermissions } from 'react-admin';

import { List } from '~/layout';
import { LocalSort, NoPermissions, StatusField, TranslateField } from '~/components/ra';
import PartnerCreate from './PartnerCreate';

const PartnersList = (props: any) => {
  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('partner.list')) return <NoPermissions />;

  return (
    <List
      bulkActionButtons={false}
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<PartnerCreate permissions={permissions} />}
      filters={
        <Filter>
          <TextInput
            label="Account number"
            source="accountNumber"
            alwaysOn
            style={{ minWidth: 212 }}
          />
        </Filter>
      }
    >
      <LocalSort>
        <Datagrid rowClick="show" size="medium" selectedIds={null}>
          <TextField source="name" sortable={false} />
          <TextField source="description" sortable={false} />
          <TranslateField
            source="transfersDirection"
            i18nPath="resources.partners.fields"
            sortable={false}
          />
          <TextField source="externalId" sortable={false} />
          <TextField source="accountNumber" sortable={false} />
          <TextField source="accountAlias" sortable={false} />
          <StatusField source="status" sortable={false} />
        </Datagrid>
      </LocalSort>
    </List>
  );
};

export default PartnersList;
