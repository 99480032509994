import { useQuery, useMutation, useQueryClient } from 'react-query';
import { blockIndividual, getIndividualById, unblockIndividual } from '~/api/individual';

const individualByIdKey = (id: string) => ['individual', id];

export const useIndividualById = (id: string | undefined) => {
  return useQuery(individualByIdKey(id!), () => getIndividualById(id!), {
    enabled: !!id,
  });
};

export const useBlockIndividualMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((note: string) => blockIndividual({ id, note }), {
    onSuccess() {
      queryClient.invalidateQueries(individualByIdKey(id));
    },
  });
};

export const useUnblockIndividualMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((note: string) => unblockIndividual({ id, note }), {
    onSuccess() {
      queryClient.invalidateQueries(individualByIdKey(id));
    },
  });
};
