import * as React from 'react';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import lodashGet from 'lodash/get';

import AmountField from './AmountField';
import { Longdash } from '~/utils';

export interface Props {
  label?: string;
  record: any;
  source: string;
}

const FeeField = ({ label, record = {}, source }: Props) => {
  const fee = lodashGet(record, source);
  const translate = useTranslate();

  if (fee) {
    let feeValueField = null;
    switch (fee.type) {
      case 'fixedDebitFee':
      case 'fixedDebitFeeDecrease':
      case 'fixedCreditFee':
        feeValueField = (
          <div>
            {translate(`${label || source}.value.amount.value`)}:{' '}
            <AmountField record={fee.value} source="amount" />
          </div>
        );
        break;
      case 'percentDebitFee':
      case 'percentDebitFeeDecrease':
      case 'percentCreditFee':
        feeValueField = (
          <div>
            {translate(`${label || source}.value.percent`)}: {`${fee.value?.percent || Longdash}%`}
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <Typography variant="body2">
        <div>
          {translate(`${label || source}.type._`)}:{' '}
          {fee.type ? translate(`${label || source}.type.${fee.type}`) : Longdash}
        </div>
        <div>
          {translate(`${label || source}.value.name`)}: {fee.value?.name || Longdash}
        </div>
        {feeValueField}
      </Typography>
    );
  } else {
    return <Typography variant="body2">{Longdash}</Typography>;
  }
};

export default FeeField;
