import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-factor';
// import loadLanguages from 'prismjs/components/index';
import 'prismjs/themes/prism.css';
import cx from 'classnames';

// loadLanguages(['bash']);

type CodeProps = {
  code: string;
  language: string;
  className?: string;
  inline?: boolean;
};

export default function Code(props: CodeProps) {
  const { code, language, className, inline } = props;

  const anchor = useRef();

  useEffect(() => {
    Prism.highlightElement(anchor.current as any);
  }, [code]);

  return inline ? (
    <code ref={anchor as any} className={cx(`language-${language}`, className)}>
      {code}
    </code>
  ) : (
    <pre className={className}>
      <code ref={anchor as any} className={cx(`language-${language}`)}>
        {code}
      </code>
    </pre>
  );
}
