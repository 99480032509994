import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { toggleSidebar } from 'ra-core';
import * as colors from '@material-ui/core/colors';
import Color from 'color';
import cx from 'classnames';

import DefaultUserMenu from './UserMenu';
import HideOnScroll from './HideOnScroll';
import Breadcrumbs from './Breadcrumbs';
import { sidebarMenuToggler } from './Sidebar';
import { useTenant } from '~/hooks/tenant';
import { time } from '~/utils';
import { LinkToProfile } from '~/components';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    toolbar: {
      paddingRight: 24,
      maxWidth: 1920 - theme.spacing(3),
      flexGrow: 1,
    },
    menuButtonOpen: {
      transition: theme.transitions.create(['margin'], {
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: '4px',
      marginRight: '4px',
    },
    menuButtonClosed: {
      transition: theme.transitions.create(['margin'], {
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: '4px',
      marginRight: '4px',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    breadcrumbs: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '30vw',
    },
    environmentInfoWrapper: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    environmentInfoContainer: {
      display: 'flex',
      flexGrow: 1,
      maxWidth: 'calc(50vw + 77px)',
    },
    environmentInfo: {
      display: 'flex',
      flex: 0,
      minWidth: 300,
      paddingLeft: theme.spacing(2),
    },
    tenantCode: {
      fontSize: 20,
      lineHeight: '20px',
      fontWeight: 500,
      marginRight: 7,
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    appBarTag: {
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      fontSize: 14,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      marginLeft: 7,
      marginRight: 7,
    },
    environment: {
      color: ({ envStatus }: any) =>
        envStatus === PRODUCTION ? theme.palette.background.paper : colors.blue[300],
      border: ({ envStatus }: any) =>
        envStatus === PRODUCTION
          ? `1px solid ${Color(theme.palette.success.main).lightness(70).hex()}`
          : `1px solid ${colors.blue[300]}`,
      background: ({ envStatus }: any) =>
        envStatus === PRODUCTION ? theme.palette.success.main : undefined,
    },
    licenseWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.background.paper,
      cursor: 'help',
    },
    licenseError: {
      backgroundColor: theme.palette.error.main,
      // border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.background.paper,
      cursor: 'help',
    },
  }),
  { name: 'RaAppBar' }
);

const PRODUCTION = 'PRODUCTION';

const AppBar = ({
  classes: classesOverride,
  className,
  logo, // eslint-disable-line
  logout,
  open,
  title, // eslint-disable-line
  userMenu,
  location, // eslint-disable-line
  ...rest
}: any) => {
  const { data: tenantInfo } = useTenant();

  const translate = useTranslate();
  const t = (key: string, arg?: any) => translate(`components.AppBar.${key}`, arg);
  const classes = useStyles({ classes: classesOverride, envStatus: tenantInfo?.status });
  const dispatch = useDispatch();

  const nowDate = new Date();
  const tenantDate = new Date(tenantInfo?.data?.license?.activeBefore || '');
  const sevenDaysBefore = new Date(
    tenantDate.getFullYear(),
    tenantDate.getMonth(),
    tenantDate.getDate() - 7
  );
  return (
    <HideOnScroll>
      <MuiAppBar className={className} color='default' elevation={1} {...rest}>
        <div className={classes.container}>
          <Toolbar disableGutters variant='dense' className={classes.toolbar}>
            <div id={sidebarMenuToggler}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => dispatch(toggleSidebar())}
                className={
                  open ? classNames(classes.menuButtonOpen) : classNames(classes.menuButtonClosed)
                }
              >
                <MenuIcon
                  classes={{
                    root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                  }}
                />
              </IconButton>
            </div>
            <Breadcrumbs className={classes.breadcrumbs} />
            <div className={classes.environmentInfoWrapper}>
              {tenantInfo && (
                <div className={classes.environmentInfoContainer}>
                  <div className={classes.environmentInfo}>
                    <div className={classes.tenantCode}>{tenantInfo.name}</div>
                    <div className={cx(classes.appBarTag, classes.environment)}>
                      {t(tenantInfo.status)}
                    </div>
                    {tenantInfo.data.license ? (
                      <>
                        {tenantInfo.data.license.active && nowDate >= sevenDaysBefore && (
                          <div
                            className={cx(classes.appBarTag, classes.licenseWarning)}
                            title={t('licenseWillExpire', {
                              date: time(tenantInfo.data.license.activeBefore).format('L'),
                            })}
                          >
                            {t('licenseWillExpire', {
                              date: time(tenantInfo.data.license.activeBefore).format('L'),
                            })}
                          </div>
                        )}
                        {!tenantInfo.data.license.active && (
                          <div
                            className={cx(classes.appBarTag, classes.licenseError)}
                            title={t('licenseExpiredMessage', {
                              date: time(tenantInfo.data.license.activeBefore).format('L'),
                            })}
                          >
                            {t('licenseNotActive')}
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className={cx(classes.appBarTag, classes.licenseError)}
                        title={t('licenseMissingMessage')}
                      >
                        {t('licenseMissing')}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <LinkToProfile />
            {cloneElement(userMenu, { logout })}
          </Toolbar>
        </div>
      </MuiAppBar>
    </HideOnScroll>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

AppBar.defaultProps = {
  userMenu: <DefaultUserMenu />,
};

export default AppBar;
