import React, { FC, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import { CreateButton, Drawer } from '~/components';
import { CreateForm } from '~/components/ra';
import onError from '~/errorsHandler';

import LocalizationForm from './LocalizationForm';

interface CreateDrawerProps {
  open: boolean;
  onClose: () => void;
}

const useCreateTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.localization.create.${key}`);
};

const CreateDrawer: FC<CreateDrawerProps> = (props) => {
  const { open, onClose } = props;

  const [isAlreadyRegistered, setAlreadyRegistered] = useState(false);

  const resetAlreadyRegistered = () => {
    isAlreadyRegistered && setAlreadyRegistered(false);
  };

  const handleError = (error: any) => {
    const code: string | undefined = error?.body?.code;
    if (code === 'RESOURCE_ALREADY_EXISTS') {
      setAlreadyRegistered(true);
    } else {
      onError(error);
    }
  };

  const handleClose = () => {
    onClose();
    resetAlreadyRegistered();
  };

  const t = useCreateTranslate();

  return (
    <Drawer open={open} onClose={handleClose} heading={t('title')}>
      <CreateForm
        resource="localization"
        onSuccess={() => {
          handleClose();
        }}
        onError={handleError}
      >
        <LocalizationForm />
      </CreateForm>
    </Drawer>
  );
};

const LocalizationCreateAction = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { permissions } = usePermissions();
  if (!permissions?.includes('localization.create')) return null;
  return (
    <>
      <CreateButton onClick={handleOpen} />
      <CreateDrawer open={open} onClose={handleClose} />
    </>
  );
};

export default LocalizationCreateAction;
