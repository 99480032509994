import { useCallback } from 'react';
import { useTranslate } from 'react-admin';

import BatchesList from './BatchesList';
import BatchShow from './BatchShow';

export const resource = 'batches';

export const useTranslateBatchesList = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.list.${key}`), [translate]);
};

export const useTranslateBatchesShow = () => {
  const translate = useTranslate();
  return useCallback((key: string) => translate(`resources.${resource}.show.${key}`), [translate]);
};

export default {
  list: BatchesList,
  show: BatchShow,
};
