import React, { cloneElement, useState } from 'react';

import { ButtonProps } from '../ra/Button';
import ConfirmationBase, { ConfirmationSettings } from './ConfirmationBase';
export { default as ConfirmationBase } from './ConfirmationBase';
export type { ConfirmationSettings } from './ConfirmationBase';

interface Props<Record> {
  onConfirm: () => void | Promise<any>;
  onClose?: () => void;
  confirmationSettings?: ConfirmationSettings<Record>;
  prevRecord?: Partial<Record>;
  newRecord?: Record;
  confirmButtonProps?: ButtonProps;
  children: any;
}

export function Confirmation<Record>(props: Props<Record>) {
  const { children, onClose, ...confirmationBaseProps } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onClose?.();
    setOpen(false);
  };

  const handleChildrenClick = () => {
    handleOpen();
  };

  return (
    <>
      {cloneElement(children, {
        onClick: handleChildrenClick,
      })}
      <ConfirmationBase<Record> open={open} handleClose={handleClose} {...confirmationBaseProps} />
    </>
  );
}

export default Confirmation;
