import React from 'react';
import { ChipField } from 'react-admin';
import lodashGet from 'lodash/get';

import { useHandbook } from '~/hooks';
import { Longdash } from '~/utils';
import { isEmpty } from 'lodash';

export interface Props {
  record?: {
    solutionId: string;
    [key: string]: any;
  };
  source?: string;
  type?: 'text' | 'chipField';
  allowEmpty?: boolean;
  emptyText?: string;
  [x: string]: any;
}

const KycLevelField = ({
  record,
  source,
  type = 'chipField',
  allowEmpty,
  emptyText = Longdash,
  ...rest
}: Props) => {
  const { data } = useHandbook(record?.solutionId);
  const value = typeof record !== 'string' ? lodashGet(record, source || '') : record;
  if (!value) return <>{Longdash}</>;
  const renderWithOptions = () => {
    const kycLevels = data?.kycLevels || [];
    const kycLevel = kycLevels.find((level) => level.id === value);
    if (type === 'text') return <div>{kycLevel?.name || Longdash}</div>;
    return (
      <ChipField record={{ value: kycLevel?.name || `[${value}]` }} source='value' {...rest} />
    );
  };

  const renderDisabled = () => {
    return <ChipField record={{ value: '...' }} source='value' />;
  };

  if ((!value || isEmpty(value)) && allowEmpty) return <span>{emptyText}</span>;

  const field = data ? renderWithOptions() : renderDisabled();
  return field;
};

KycLevelField.defaultProps = {
  addLabel: true,
};

export default KycLevelField;
