import React, { useState } from 'react';
import { usePermissions } from 'react-admin';
import { CreateButton } from '~/components';
import IndividualCreateDrawer from './components/IndividualCreateDrawer';

const IndividualCreateAction = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { permissions } = usePermissions();
  if (!permissions?.includes('individual.create')) return null;
  return (
    <>
      <CreateButton onClick={handleOpen} />
      <IndividualCreateDrawer open={open} onClose={handleClose} />
    </>
  );
};

export default IndividualCreateAction;
