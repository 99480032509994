import { useTranslate } from 'react-admin';

import ActionReportsList, { resource } from './ActionReportsList';
import ActionReportsShow from './ActionReportsShow';

export const useTranslateActionReports = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.${resource}.fields.${key}`);
};

export default {
  list: ActionReportsList,
  show: ActionReportsShow,
};
