import React, { useEffect, useState } from 'react';
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';

import { H2 } from '~/components';
import { RootState } from '~/types';
import { openDrawer, closeDrawer } from '~/ducks/ui';
import palette from '~/theme/palette';

interface StyledDrawerProps extends DrawerProps {
  $noBackgroundShadow?: boolean;
}

const StyledDrawer = styled(MuiDrawer)<StyledDrawerProps>`
  ${(props) =>
    props.$noBackgroundShadow &&
    css`
      .MuiBackdrop-root {
        background-color: transparent;
      }
    `}
`;

const useStyles = makeStyles((theme) => ({
  MuiDrawer: {
    overflowY: 'unset',
  },
  root: {
    width: 395,
    maxWidth: 395,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    '& > button': {
      padding: 3,
      marginTop: -3,
    },
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  noShadow: {
    '&:first-child': {
      backgroundColor: 'transparent',
    },
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  }
}));

interface Props {
  heading: string | JSX.Element;
  onClose: () => void;
  open: boolean;
  children: any;
  changelogMode?: boolean;
}

const Counter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(openDrawer());
    return () => {
      dispatch(closeDrawer());
    };
  }, [dispatch]);
  return null;
};

const Drawer = (props: Props) => {
  const classes = useStyles();
  const { heading, onClose, open, children, changelogMode } = props;

  const openDrawersCount = useSelector<RootState, number>((state) => state.ui.openDrawers);
  const [count, setCount] = useState<number | null>(null);
  useEffect(() => {
    if (count === null && open) {
      setCount(openDrawersCount);
    }
  }, [openDrawersCount, count, open]);

  return (
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.MuiDrawer }}
      $noBackgroundShadow={Boolean(count && count > 0)}
    >
      {open && <Counter />}
      <Scrollbars className={changelogMode ? classes.changelogMode : undefined} style={{ width: 395, height: '100vh' }}>
        <div className={classes.root}>
          <div className={classes.header}>
            <H2 topSpacing={0}>{heading}</H2>
            <IconButton aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid className={classes.content} container item xs={12} direction="column">
            {children}
          </Grid>
        </div>
      </Scrollbars>
    </StyledDrawer>
  );
};

export default Drawer;
