import React from 'react';
import { Button } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  onClick: () => void;
  variant?: 'create' | 'add';
  label?: string;
  disabled?: boolean;
}

const CreateButton = (props: Props) => {
  const { onClick, variant = 'create', label, disabled } = props;

  return (
    <Button label={label || `ra.action.${variant}`} onClick={onClick} disabled={disabled}>
      <AddIcon />
    </Button>
  );
};

export default CreateButton;
