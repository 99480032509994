const locales = {
  'sq-AL': 'Albanian (Albania)',
  sq: 'Albanian',
  'ar-DZ': 'Arabic (Algeria)',
  'ar-BH': 'Arabic (Bahrain)',
  'ar-EG': 'Arabic (Egypt)',
  'ar-IQ': 'Arabic (Iraq)',
  'ar-JO': 'Arabic (Jordan)',
  'ar-KW': 'Arabic (Kuwait)',
  'ar-LB': 'Arabic (Lebanon)',
  'ar-LY': 'Arabic (Libya)',
  'ar-MA': 'Arabic (Morocco)',
  'ar-OM': 'Arabic (Oman)',
  'ar-QA': 'Arabic (Qatar)',
  'ar-SA': 'Arabic (Saudi Arabia)',
  'ar-SD': 'Arabic (Sudan)',
  'ar-SY': 'Arabic (Syria)',
  'ar-TN': 'Arabic (Tunisia)',
  'ar-AE': 'Arabic (United Arab Emirates)',
  'ar-YE': 'Arabic (Yemen)',
  ar: 'Arabic',
  'be-BY': 'Belarusian (Belarus)',
  be: 'Belarusian',
  'bg-BG': 'Bulgarian (Bulgaria)',
  bg: 'Bulgarian',
  'ca-ES': 'Catalan (Spain)',
  ca: 'Catalan',
  'zh-CN': 'Chinese (China)',
  'zh-HK': 'Chinese (Hong Kong)',
  'zh-SG': 'Chinese (Singapore)',
  'zh-TW': 'Chinese (Taiwan)',
  zh: 'Chinese',
  'hr-HR': 'Croatian (Croatia)',
  hr: 'Croatian',
  'cs-CZ': 'Czech (Czech Republic)',
  cs: 'Czech',
  'da-DK': 'Danish (Denmark)',
  da: 'Danish',
  'nl-BE': 'Dutch (Belgium)',
  'nl-NL': 'Dutch (Netherlands)',
  nl: 'Dutch',
  'en-AU': 'English (Australia)',
  'en-CA': 'English (Canada)',
  'en-IN': 'English (India)',
  'en-IE': 'English (Ireland)',
  'en-MT': 'English (Malta)',
  'en-NZ': 'English (New Zealand)',
  'en-PH': 'English (Philippines)',
  'en-SG': 'English (Singapore)',
  'en-ZA': 'English (South Africa)',
  'en-GB': 'English (United Kingdom)',
  'en-US': 'English (United States)',
  en: 'English',
  'et-EE': 'Estonian (Estonia)',
  et: 'Estonian',
  'fi-FI': 'Finnish (Finland)',
  fi: 'Finnish',
  'fr-BE': 'French (Belgium)',
  'fr-CA': 'French (Canada)',
  'fr-FR': 'French (France)',
  'fr-LU': 'French (Luxembourg)',
  'fr-CH': 'French (Switzerland)',
  fr: 'French',
  'de-AT': 'German (Austria)',
  'de-DE': 'German (Germany)',
  'de-LU': 'German (Luxembourg)',
  'de-CH': 'German (Switzerland)',
  de: 'German',
  'el-CY': 'Greek (Cyprus)',
  'el-GR': 'Greek (Greece)',
  el: 'Greek',
  'iw-IL': 'Hebrew (Israel)',
  iw: 'Hebrew',
  'hi-IN': 'Hindi (India)',
  'hu-HU': 'Hungarian (Hungary)',
  hu: 'Hungarian',
  'is-IS': 'Icelandic (Iceland)',
  is: 'Icelandic',
  'in-ID': 'Indonesian (Indonesia)',
  in: 'Indonesian',
  'ga-IE': 'Irish (Ireland)',
  ga: 'Irish',
  'it-IT': 'Italian (Italy)',
  'it-CH': 'Italian (Switzerland)',
  it: 'Italian',
  'ja-JP': 'Japanese (Japan)',
  'ja-JP-JP': 'Japanese (Japan,JP)',
  ja: 'Japanese',
  'ko-KR': 'Korean (South Korea)',
  ko: 'Korean',
  'lv-LV': 'Latvian (Latvia)',
  lv: 'Latvian',
  'lt-LT': 'Lithuanian (Lithuania)',
  lt: 'Lithuanian',
  'mk-MK': 'Macedonian (Macedonia)',
  mk: 'Macedonian',
  'ms-MY': 'Malay (Malaysia)',
  ms: 'Malay',
  'mt-MT': 'Maltese (Malta)',
  mt: 'Maltese',
  'no-NO': 'Norwegian (Norway)',
  'no-NO-NY': 'Norwegian (Norway,Nynorsk)',
  no: 'Norwegian',
  'pl-PL': 'Polish (Poland)',
  pl: 'Polish',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  pt: 'Portuguese',
  'ro-RO': 'Romanian (Romania)',
  ro: 'Romanian',
  'ru-RU': 'Russian (Russia)',
  ru: 'Russian',
  'sr-BA': 'Serbian (Bosnia and Herzegovina)',
  'sr-ME': 'Serbian (Montenegro)',
  'sr-CS': 'Serbian (Serbia and Montenegro)',
  'sr-RS': 'Serbian (Serbia)',
  sr: 'Serbian',
  'sk-SK': 'Slovak (Slovakia)',
  sk: 'Slovak',
  'sl-SI': 'Slovenian (Slovenia)',
  sl: 'Slovenian',
  'es-AR': 'Spanish (Argentina)',
  'es-BO': 'Spanish (Bolivia)',
  'es-CL': 'Spanish (Chile)',
  'es-CO': 'Spanish (Colombia)',
  'es-CR': 'Spanish (Costa Rica)',
  'es-DO': 'Spanish (Dominican Republic)',
  'es-EC': 'Spanish (Ecuador)',
  'es-SV': 'Spanish (El Salvador)',
  'es-GT': 'Spanish (Guatemala)',
  'es-HN': 'Spanish (Honduras)',
  'es-MX': 'Spanish (Mexico)',
  'es-NI': 'Spanish (Nicaragua)',
  'es-PA': 'Spanish (Panama)',
  'es-PY': 'Spanish (Paraguay)',
  'es-PE': 'Spanish (Peru)',
  'es-PR': 'Spanish (Puerto Rico)',
  'es-ES': 'Spanish (Spain)',
  'es-US': 'Spanish (United States)',
  'es-UY': 'Spanish (Uruguay)',
  'es-VE': 'Spanish (Venezuela)',
  es: 'Spanish',
  'sv-SE': 'Swedish (Sweden)',
  sv: 'Swedish',
  'th-TH': 'Thai (Thailand)',
  'th-TH-TH': 'Thai (Thailand,TH)',
  th: 'Thai',
  'tr-TR': 'Turkish (Turkey)',
  tr: 'Turkish',
  'uk-UA': 'Ukrainian (Ukraine)',
  uk: 'Ukrainian',
  'vi-VN': 'Vietnamese (Vietnam)',
  vi: 'Vietnamese',
};

export default locales;
