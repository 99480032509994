import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2, TextField } from '~/components';
import { Section } from '~/layout';
import collectProperties from '~/utils/collectProperties';
import { makeLabel } from './ContextTab';
import useStyles from './useStyles';
import { useGetPartnerByExternalId } from '~/hooks/partners';
import { HeadTransaction } from '~/types/HeadTransaction';
import { useGetServiceQuery } from '~/hooks/paymentServices';

const ServicePartnerByExternalId = (props: { externalId: string }) => {
  const { externalId } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`service.${key}`));
  const { data: partner, isLoading } = useGetPartnerByExternalId(externalId);

  return (
    <TextField
      label={t('partner')}
      loading={isLoading}
      showLink={partner && `/partners/${partner.id}/show`}
    >
      {partner && partner.name}
    </TextField>
  );
};

const ServiceSection = (props: { serviceId: string; trx: HeadTransaction }) => {
  const { trx, serviceId } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`service.${key}`));
  const { data: service, isLoading: serviceIsLoading } = useGetServiceQuery(serviceId);

  const properties = collectProperties(trx.details.service?.parameters || {}, ['id']);

  return (
    <>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('_')}
        </H2>
      </header>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
          <Grid item xs={4}>
            <TextField
              label={t('_')}
              loading={serviceIsLoading}
              showLink={`/paymentServices/${serviceId}/show`}
            >
              {service && service.name}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            {!serviceIsLoading && service?.partnerId ? (
              <ServicePartnerByExternalId externalId={service.partnerId} />
            ) : (
              <TextField label={t('partner')} loading={serviceIsLoading}>
                {service && service.partnerId}
              </TextField>
            )}
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('integrationPlugin')} loading={serviceIsLoading}>
              {service && service.integration?.plugin}
            </TextField>
          </Grid>
        </Grid>
        {properties.length > 0 && (
          <Grid container item xs={12}>
            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('parameter')}</TableCell>
                  <TableCell>{t('data')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map((item) => (
                  <TableRow>
                    <TableCell title={item.key}>{item.key}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Section>
    </>
  );
};

export default ServiceSection;
