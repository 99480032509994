const DISABLED = 'DISABLED';

const buildProfileLink = (
  accountId?: string,
  profileId?: string,
  profileType?: string,
  ownerId?: string // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  // if (ownerId && (profileId === ownerId || accountId === ownerId)) return DISABLED;

  switch (profileType) {
    case 'INDIVIDUAL':
      return `/individuals/${profileId}/show`;
    case 'BUSINESS':
      return `/businesses/${profileId}/show`;
    case 'PARTNER':
      return `/partners/${profileId}/show`;
    case 'TENANT':
      return `/tenant/accounts/${accountId}/show`;
    case 'TRANSACTION':
    case 'HEAD_TRANSACTION':
      return `/headTransactions/${profileId}/show`;
    default:
      return '';
  }
};

buildProfileLink.disabled = DISABLED;

export default buildProfileLink;
