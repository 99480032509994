import React, { useState, Fragment, useEffect } from 'react';
import { SelectInput as RaSelectInput, Button, InputHelperText } from 'react-admin';
import { useForm } from 'react-final-form';
import { useInput } from 'ra-core';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  FormHelperText,
} from '@material-ui/core';
import lodashGet from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import { Longdash } from '~/utils';

const columns = 3;
const rowHeight = 36;

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  selectInput: {
    marginBottom: 3,
    minWidth: 156,
  },
  menuItem: {
    width: '100%',
    height: rowHeight,
  },
  dialog: {
    maxWidth: 'auto',
  },
  columnLayout: {
    display: 'block',
    columnCount: columns,
  },
  pagination: {
    '& > div': {
      backgroundColor: 'transparent',
      borderTop: 'none',
    },
  },
}));

type ChoiceBase = {
  id: string;
  [x: string]: any;
};

interface Props<Choice> {
  allowEmpty?: boolean;
  choices?: Choice[];
  className?: string;
  classes?: any; // change to more specific type
  emptyText?: string;
  fullWidth?: boolean;
  input?: any;
  isRequired?: boolean;
  label?: string;
  meta?: any;
  optionText: string | ((x: Choice) => string);
  pagination?: {
    page: number;
    perPage: number;
  };
  resource?: string;
  modalAfterCount?: number;
  source?: string;
  children?: (item: Choice, index: number, arr: Choice[]) => any;
  onChange?: (source: string, value: string) => void;
  [x: string]: any;
}

function ModalOrSelectInput<Choice extends ChoiceBase>(props: Props<Choice>) {
  const {
    choices = [],
    source = '',
    allowEmpty,
    emptyText = Longdash,
    label,
    optionText,
    children,
    modalAfterCount = 10,
    validate,
    fullWidth,
    onChange,
    ...rest
  } = props;

  const {
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({ source, validate, ...rest });

  const [rows, setRows] = useState<Choice[]>([]);
  useEffect(() => {
    setRows(allowEmpty ? [{ id: emptyText } as any, ...choices] : choices);
  }, [choices, allowEmpty, emptyText]);

  const [selected, setSelected] = useState(input?.value || '');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  const form = useForm();
  const classes = useStyles();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (!event.target) return;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const selectedValue = rows.find((item) => item.id === input.value);

  return rows.length > modalAfterCount ? (
    <Fragment>
      <FormControl
        className={cx(classes.selectInput, { [classes.fullWidth]: fullWidth })}
        onClick={handleOpen}
      >
        <InputLabel required={isRequired} variant="filled">
          {label}
        </InputLabel>
        <Select
          value={input.value}
          label={label}
          MenuProps={{ open: false }}
          open={false}
          error={!!(touched && error)}
        >
          <MenuItem value={input.value as any}>
            {!selectedValue
              ? ''
              : typeof optionText === 'string'
              ? lodashGet(selectedValue, optionText)
              : optionText(selectedValue)}
          </MenuItem>
        </Select>
        <FormHelperText error={!!(touched && error)}>
          {touched && error ? (
            <InputHelperText touched={touched} error={error} />
          ) : (
            <span>&#8203;</span>
          )}
        </FormHelperText>
      </FormControl>

      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{label}</DialogTitle>
        <DialogContent>
          <div
            className={classes.columnLayout}
            style={{ minHeight: (rowsPerPage / columns) * rowHeight }}
          >
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter(Boolean)
              .map((item, index, arr) => {
                if (item.id === emptyText) {
                  return (
                    <MenuItem
                      component="button"
                      selected={selected === ''}
                      onClick={() => setSelected('')}
                      value={''}
                      className={classes.menuItem}
                    >
                      {emptyText}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem
                    component="button"
                    selected={selected === item.id}
                    onClick={() => setSelected(item.id)}
                    key={index}
                    value={item.id}
                    className={classes.menuItem}
                  >
                    {typeof children === 'function'
                      ? children(item, index, arr)
                      : typeof optionText === 'string'
                      ? lodashGet(item, optionText) || ''
                      : optionText(item)}
                  </MenuItem>
                );
              })}
          </div>
          <TablePagination
            rowsPerPageOptions={[24, 48]}
            component="div"
            className={classes.pagination}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DialogContent>
        <DialogActions>
          <Button label={'ra.action.cancel'} onClick={handleClose} />
          <Button
            label={'ok'}
            onClick={() => {
              if (typeof selected === 'string') {
                form.change(source, selected);
                typeof onChange === 'function' && onChange(source, selected);
                handleClose();
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : (
    <RaSelectInput {...props} />
  );
}

export default ModalOrSelectInput;
