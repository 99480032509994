import { useMediaQuery } from '@material-ui/core';

export const useIsSmallLaptop = () => useMediaQuery('(max-width:1280px)');
export const useIsLargeScreen = () => useMediaQuery('(min-width:1600px)');

export const useRightContentWidth = (): number => {
  const isSmallLaptop = useIsSmallLaptop();
  return isSmallLaptop ? 300 : 360;
};

export const useRightContentSpacing = () => {
  const isSmallLaptop = useIsSmallLaptop();
  return isSmallLaptop ? 2 : 3;
};
