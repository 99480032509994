import React, { FC, useEffect } from 'react';
import { NumberInput, SelectInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useField, useForm } from 'react-final-form';
import lodashGet from 'lodash/get';

import { useHandbook } from '~/hooks';
import { SelectInput as MuiSelectInput } from '~/components';
import { CurrencySymbols, NumberEx } from '~/utils';
import { Amount } from '~/types';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  selectInput: {
    minWidth: 100,
  },
});

const validateAmountValue = (source = 'amount', nonNullable: boolean) => (_: any, values: any) => {
  const amount: Amount = lodashGet(values, source);
  if (amount?.currency) {
    const { value } = amount;
    if (!value?.toString())
      return {
        message: 'ra.validation.required',
        isRequired: true,
      };
    if (nonNullable && value.toString() === '0')
      return {
        message: 'components.ra.inputs.AmountInput.nonNullable',
      };
  }
};

const validateAmountCurrency = (source = 'amount') => (_: any, values: any) => {
  const amount: Amount = lodashGet(values, source);
  if (amount) {
    const { value, currency } = amount;
    if (value?.toString() && !currency)
      return {
        message: 'ra.validation.required',
        // in some reason it's field does not affect on "required={true}" SelectInput prop
        isRequired: true,
      };
  }
};
export interface Props {
  source?: string;
  amountLabel?: string;
  currencyLabel?: string;
  solutionId?: string;
  permanentSelectedCurrency?: string;
  currencyDisabled?: boolean;
  positiveOnly?: boolean;
  nonNullable?: boolean;
  required?: boolean;
  initialValue?: number;
  currencies?: { id: string; name: string }[];
  [k: string]: any;
}

const AmountInput = ({
  source = 'amount',
  amountLabel,
  currencyLabel,
  solutionId,
  permanentSelectedCurrency,
  currencyDisabled,
  positiveOnly = false,
  nonNullable = false,
  required = false,
  initialValue,
  currencies: currenciesFromProps,
  ...rest
}: Props) => {
  const classes = useStyles();

  const currencySymbols: { [key: string]: string } = CurrencySymbols;

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ra.inputs.AmountInput.${key}`);

  const { data } = useHandbook(solutionId);

  let currencies = [];
  if (currenciesFromProps) {
    currencies = currenciesFromProps;
  } else if (data && !permanentSelectedCurrency) {
    currencies = (data.currencies || []).map((c) => {
      return { id: c.id, name: currencySymbols[c.id] || c.id };
    });
  } else if (permanentSelectedCurrency) {
    currencies = [
      {
        id: permanentSelectedCurrency,
        name: currencySymbols[permanentSelectedCurrency] || permanentSelectedCurrency,
      },
    ];
  } else {
    currencies = Object.keys(CurrencySymbols).map((key) => {
      return { id: key, name: currencySymbols[key] };
    });
  }

  const valueField = useField(`${source}.value`);
  const currencyField = useField(`${source}.currency`);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <NumberInput
          {...rest}
          label={amountLabel || t(`amount`)}
          source={`${source}.value`}
          fullWidth
          parse={positiveOnly ? NumberEx.positive : undefined}
          min={positiveOnly ? 0 : undefined}
          required={
            required === false && !!permanentSelectedCurrency
              ? false
              : currencyField.input.value !== '' || required
          }
          validate={validateAmountValue(source, nonNullable)}
          initialValue={initialValue}
        />
      </Grid>
      <Grid item xs={4}>
        {typeof valueField.input.value === 'number' || !permanentSelectedCurrency || required ? (
          <CurrencySelect
            {...rest}
            className={classes.selectInput}
            label={currencyLabel || t(`currency`)}
            source={`${source}.currency`}
            choices={
              isEmpty(currencies)
                ? [{ id: undefined, name: translate('utils.noHandbookValues') }]
                : currencies
            }
            validate={validateAmountCurrency(source)}
            required={
              required === false && !!permanentSelectedCurrency
                ? false
                : valueField.input.value !== '' || required
            }
            defaultValue={permanentSelectedCurrency}
            disabled={currencyDisabled || permanentSelectedCurrency}
            fullWidth
          />
        ) : (
          <MuiSelectInput
            value={permanentSelectedCurrency}
            label={currencyLabel || t(`currency`)}
            options={[
              {
                value: permanentSelectedCurrency,
                text: currencySymbols[permanentSelectedCurrency] || permanentSelectedCurrency,
              },
            ]}
            disabled
          />
        )}
      </Grid>
    </Grid>
  );
};

const CurrencySelect: FC<any> = (props) => {
  const { source } = props;
  const form = useForm();

  useEffect(() => {
    return () => {
      form.change(source, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SelectInput {...props} />;
};

export default AmountInput;
