import React, { Children, cloneElement } from 'react';
import { TabbedShowLayout, TabbedShowLayoutTabs } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import palette from '~/theme/palette';

const useStyles = makeStyles(() => ({
  fullWidthGrow: {
    flexGrow: 1,
    width: '100%',
  },
  root: {
    boxSizing: 'border-box',
    flexGrow: 1,
    minWidth: 800,
  },
  TabbedShowLayout: {
    display: 'grid',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  }
}));

interface Props {
  bottomContent?: any;
  changelogMode?: boolean;
  [x: string]: any;
}

const PaperTabbedShowLayout = (props: Props) => {
  const { bottomContent, changelogMode, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classes.fullWidthGrow}>
      <Paper className={classes.root}>
        <TabbedShowLayout
          tabs={<TabbedShowLayoutTabs variant="scrollable" {...sanitizeProps(rest)} />}
          {...rest}
          className={cx(classes.TabbedShowLayout, changelogMode ? classes.changelogMode : undefined)}
        />
      </Paper>
      {bottomContent &&
        Children.toArray(bottomContent).map((element: any) => cloneElement(element, { ...rest }))}
    </div>
  );
};

const sanitizeProps = (props: any) => {
  const { basePath, ...rest } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  return rest;
};

export default PaperTabbedShowLayout;
