import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslate, SelectArrayInput } from 'react-admin';
import { CurrenciesRates, getCurrenciesRates } from '~/api/fxrates';
import { useApi } from '~/hooks';
import { l } from '../CurrenciesList';
import { CurrencySelect } from '~/components/ra';
import { required } from 'react-admin';
import { SelectInput } from 'react-admin';

interface Props {
  sourceSystem: string;
  record: CurrenciesRates;
}

const FxRatesTablesSelect = (props: Props) => {
  const { sourceSystem, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const [{ data, loading }] = useApi<CurrenciesRates[]>(getCurrenciesRates);
  const selectChoises = data
    ?.filter((item) => item.id !== record?.id)
    ?.map((item) => {
      return { id: item.id, name: item.name };
    });
  const emptyPairs = [{ id: '', name: t('noTables'), disabled: true }];
  if (sourceSystem === 'BEST_RATES_OF') {
    return (
      <SelectArrayInput
        source='additionalConfiguration.tablesIds'
        choices={isEmpty(selectChoises) ? emptyPairs : selectChoises}
        label={t('bestRatesOf')}
        disabled={loading}
        validate={required()}
        translateChoice={false}
      />
    );
  } else if (sourceSystem === 'TWO_STEP_CROSSRATE') {
    return (
      <>
        <SelectInput
          source='additionalConfiguration.leftTableId'
          choices={isEmpty(selectChoises) ? emptyPairs : selectChoises}
          label={t('firstTable')}
          disabled={loading}
          translateChoice={false}
          validate={required()}
          fullWidth
        />
        <SelectInput
          source='additionalConfiguration.rightTableId'
          choices={isEmpty(selectChoises) ? emptyPairs : selectChoises}
          label={t('secondTable')}
          disabled={loading}
          translateChoice={false}
          validate={required()}
          fullWidth
        />
        <CurrencySelect
          source='additionalConfiguration.intermediateCurrency'
          label={l('intermediateCurrency')}
          record={record}
          validate={required()}
          fullWidth
        />
      </>
    );
  } else return null;
};

export default FxRatesTablesSelect;
