import { crudGetList } from 'react-admin';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { history } from './../App';
import { deleteTeamMember } from '~/api/team';

export const useDeleteTeamMember = () => {
  const dispatch = useDispatch();

  return useMutation(deleteTeamMember, {
    onSuccess() {
      dispatch(crudGetList('team', { page: 1, perPage: 25 }, { field: 'id', order: 'DESC' }, {}));
      history.push('/team?page=1');
    },
  });
};
