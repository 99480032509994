import React from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { TextField, AmountField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { FeeLoyaltyDiscountType } from '~/api/pricings';

const FeeLoyaltyView = (props: { record: any }) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(`loyalty_.${key}`), variant);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={4}>
        {record.loyaltySourceAccountId ? (
          <ReferenceField
            source="loyaltySourceAccountId"
            record={record}
            reference="tenant/accounts"
            basePath="/tenant/accounts"
            link={false}
            addLabel={false}
          >
            <RaTextField source="alias" label={t('loyaltySourceAccount')} />
          </ReferenceField>
        ) : (
          <RaTextField label={t('loyaltySourceAccount')} allowEmpty />
        )}
      </Grid>
      <Grid item xs={4}>
        {record.discountSourceAccountId ? (
          <ReferenceField
            source="discountSourceAccountId"
            record={record}
            reference="tenant/accounts"
            basePath="/tenant/accounts"
            link={false}
            addLabel={false}
          >
            <RaTextField source="alias" label={t('discountSourceAccount')} />
          </ReferenceField>
        ) : (
          <RaTextField label={t('discountSourceAccount')} allowEmpty />
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('discountType._')}>
          {record.discountType && t(`discountType.${record.discountType}`)}
        </TextField>
      </Grid>
      {(() => {
        switch (record.discountType as FeeLoyaltyDiscountType | undefined) {
          case 'FULL':
            return null;
          case 'PERCENT':
            return (
              <Grid item xs={4}>
                <TextField label={t('discountPercent')}>
                  {record.discountPercent?.toString() && `${record.discountPercent}%`}
                </TextField>
              </Grid>
            );
          case 'FIXED':
            return (
              <Grid item xs={4}>
                <TextField label={t('discountAmount')}>
                  <AmountField amount={record.discountAmount} />
                </TextField>
              </Grid>
            );
          default:
            return null;
        }
      })()}
      {record.index && (
        <Grid item xs={4}>
          <TextField label={t('index')}>{record.index}</TextField>
        </Grid>
      )}
    </Grid>
  );
};

export default FeeLoyaltyView;
