import axios from '~/utils/axios';
import { ActionReport } from '~/types';
import { urlWithParams } from '~/utils';

export type ActionReportsRes = {
  cursors: {
    next?: string;
    prev?: string;
  };
  reports: ActionReport[];
};

export interface GetActionReportsParams {
  next?: string;
  prev?: string;
  limit?: number;
  createdFrom?: string;
  createdTo?: string;
  system?: ActionReport['system'];
  objectType?: ActionReport['objectType'];
  objectId?: ActionReport['objectId'];
  reportType?: ActionReport['reportType'];
  status?: ActionReport['status'];
}

export const getActionReports = (props: GetActionReportsParams) => {
  return axios.get<ActionReportsRes>(urlWithParams('/actionReports', { limit: 9999, ...props }));
};

export const getActionReportById = (reportId: string) => {
  return axios.get<ActionReport>(`actionReports/${reportId}`);
};

export type CheckActionReportRes = { reportTypes: string[]; system: string }[];

export const checkActionReport = (objectId: string, objectType: ActionReport['objectType']) => {
  return axios.post<CheckActionReportRes>(`actionReports/check`, {
    objectId,
    objectType,
  });
};

export type ActionReportSchema = {
  [system: string]: {
    [reportType: string]: {
      fields: {
        key: string;
        isEditable: boolean;
      }[];
    };
  };
};

export const getActionReportSchema = () => {
  return axios.get<ActionReportSchema>(`actionReports/schema`).then((res) => res.data);
};

interface CreateActionReportDraftParams {
  system: string;
  objectType: string;
  objectId: string;
  reportType: NonNullable<ActionReport['reportType']>;
}

export const createActionReportDraft = (props: CreateActionReportDraftParams) => {
  return axios
    .post<ActionReport>(`actionReports/new`, { ...props })
    .then((res) => res.data);
};

export const submitActionReport = (props: { report: ActionReport; reason: string }) => {
  return axios
    .post<ActionReport>(`actionReports`, { ...props })
    .then((res) => res.data);
};

export const getAllReportSystemsAndTypes = () => {
  return axios
    .get<{ reportTypes: string[]; system: string }[]>(`actionReports/types`)
    .then((res) => res.data);
};

interface RevokeActionReportParams {
  report: ActionReport;
  reason: string;
}

export const revokeActionReport = ({ report, reason }: RevokeActionReportParams) => {
  return axios
    .post<ActionReport>(`actionReports/${report.id}/revoke`, { reason })
    .then((res) => res.data);
};
