import React, { FC } from 'react';

import { SelectInput } from '~/components';
import { useAllSolutions } from '~/hooks/useSolution';
import { SelectInputProps } from './SelectInput';
import { Solution } from '~/types';

interface FilterProps {
  withFilter?: (s: Solution) => boolean;
}

const SolutionSelect: FC<Omit<SelectInputProps<any>, 'label' | 'options'> & FilterProps> = (
  props
) => {
  const { value, onChange, disabled, withFilter, ...rest } = props;

  const solutionQuery = useAllSolutions();
  const options =
    solutionQuery.data?.data
      ?.filter(withFilter ?? (() => true))
      ?.map((item) => ({ value: item.id, text: item.name })) || [];

  return (
    <SelectInput<string | undefined>
      label='Solution'
      name='solution'
      value={value}
      onChange={onChange}
      options={options}
      disabled={disabled || solutionQuery.isLoading}
      {...rest}
    />
  );
};

export default SolutionSelect;
