import { history } from '~/App';
import { useTenant } from '~/hooks/tenant';
import { useLoggedUser } from '~/hooks/user';

const Preloader = (props: any) => {
  const { children } = props;

  const currentUserQuery = useLoggedUser({
    onError() {
      gotToLoginScreen();
    },
  });
  const requiredHeadersReceived = currentUserQuery.isSuccess;
  const tenantQuery = useTenant({
    enabled: requiredHeadersReceived,
    onSuccess() {
      removePreloader();
    },
    onError() {
      gotToLoginScreen();
    },
  });

  const loading = currentUserQuery.isLoading || tenantQuery.isLoading;

  return loading ? null : children;
};

const removePreloader = () => {
  const ele = document.getElementById('ipl-progress-indicator');
  if (ele) {
    // fade out
    ele.classList.add('available');
    setTimeout(() => {
      // remove from DOM
      ele.outerHTML = '';
    }, 500);
  }
};

const gotToLoginScreen = () => {
  localStorage.removeItem('token');
  history.replace('/login');
  removePreloader();
};

export default Preloader;
