import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createAction, handleActions } from 'redux-actions';

const CONTEXT_SET = 'context/set';
const CONTEXT_ADD = 'context/add';

const setAction = createAction(CONTEXT_SET);
const addAction = createAction(CONTEXT_ADD);

export interface ContextData {
  [key: string]: any;
}

export const set = (data: ContextData) => {
  return (dispatch: ThunkDispatch<State, void, Action>): Promise<any> => {
    dispatch(setAction(data));
    return Promise.resolve();
  };
};

export const add = (data: ContextData) => {
  return (dispatch: ThunkDispatch<State, void, Action>): Promise<any> => {
    dispatch(addAction(data));
    return Promise.resolve();
  };
};

export interface State {
  data: ContextData;
}

const initialState: State = {
  data: {},
};

export default handleActions(
  {
    [CONTEXT_SET]: (state: State, { payload }: { payload: ContextData }) => {
      return {
        ...state,
        data: payload,
      };
    },
    [CONTEXT_ADD]: (state: State, { payload }: { payload: ContextData }) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    },
  },
  initialState
);

export const selectors = {
  get: (key?: string) => (state: any): any => {
    if (key) {
      return (state.context as State).data[key];
    } else {
      return state.context;
    }
  },
};
