import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { time } from '~/utils';
import cx from 'classnames';
import msk from 'msk';
import LockIcon from '@material-ui/icons/Lock';
import { useTenant } from '~/hooks/tenant';

interface Props {
  lastFour?: string;
  maskedPAN?: string;
  expirationDate?: string;
  cardholderName?: string;
  isExpanded?: boolean;
  cardNumber: string | undefined;
  isActive: boolean;
}

const CardImage: FC<Props> = (props) => {
  const {
    lastFour,
    maskedPAN,
    expirationDate,
    cardholderName,
    isExpanded,
    cardNumber,
    isActive,
  } = props;
  const classes = useStyles();
  const { data: tenant } = useTenant();
  return (
    <div className={cx(classes.root, { [classes.shrinkCard]: isExpanded === false })}>
      <div className={classes.imgWrapper}>
        <div>{!isActive && <LockIcon />}</div>
        <div className={classes.bankName}>{tenant?.name}</div>
      </div>
      <div className={classes.cardNumber}>
        {cardNumber
          ? msk(cardNumber, '**** **** **** ****')
          : maskedPAN
          ? msk(maskedPAN, '**** **** **** ****')
          : `**** **** **** ${lastFour || '****'}`}
      </div>
      <div className={classes.dateAndName}>
        <div>{cardholderName}</div>
        <div>
          {expirationDate
            ? expirationDate.length > 5
              ? time(expirationDate).format('MM/YY')
              : expirationDate
            : '??/??'}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 171,
    height: 108,
    borderRadius: 14,
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    boxShadow: theme.shadows[5],
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    maxHeight: 17,
    '& img': {
      height: '100%',
    },
    '& svg': {
      fontSize: 18,
      marginTop: -2,
    },
  },
  cardNumber: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 15,
    fontFamily: 'Courier Prime',
  },
  dateAndName: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
    textTransform: 'uppercase',
    alignItems: 'flex-end',
    flexGrow: 1,
    fontFamily: 'Courier Prime',
  },
  shrinkCard: {
    opacity: '.5',
    transform: 'scale(0.9)',
    marginTop: -5,
  },
  bankName: {
    fontWeight: 'bold',
  },
}));

export default CardImage;
