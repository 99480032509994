import React, { FC } from 'react';
import { ApiIdentityDetails } from '~/components/ApiIdentity';
import { useApiIdentityByProfileId } from '~/hooks/apiIdentity';
import { Individual } from '~/types';

interface Props {
  record: Individual | undefined;
}

const ApiIdentityTab: FC<Props> = (props) => {
  const { record } = props;
  const apiIdentitiesQuery = useApiIdentityByProfileId({ profileId: record?.id });
  return (
    <>
      {apiIdentitiesQuery.data?.map((item) => {
        return <ApiIdentityDetails record={item} key={item.id} />;
      })}
    </>
  );
};

export default ApiIdentityTab;
