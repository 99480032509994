import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  ListContextProvider,
  ListController,
  ReferenceField,
  TextField,
  TextInput,
  usePermissions,
  useListContext,
} from 'react-admin';

import { withStyles, createStyles } from '@material-ui/core/styles';

import { ListView } from '~/layout';

import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { KycLevelField, NoPermissions, StatusField } from '~/components/ra';
import { isEmpty } from 'lodash';
import BusinessCreateAction from './BusinessCreateAction';

const label = (field) => `resources.businesses.fields.${field}`;

const listBodyStyles = (theme) => {
  return createStyles({
    content: {
      fontSize: 14,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      paddingTop: 16,
      paddingBottom: 16,
    },
  });
};

const BusinessFilter = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  permanentFilter,
  ...props
}) => (
  <Filter {...props}>
    <TextInput label={label('legalName')} source='legalName' alwaysOn />
    <TextInput label={label('tradeName')} source='tradeName' alwaysOn />
    <TextInput
      label={label('primaryDocumentNumber')}
      style={{ minWidth: 202 }}
      source='primaryDocumentNumber'
      alwaysOn
    />
    <TextInput
      label={label('accountNumber')}
      source='accountNumber'
      alwaysOn
      style={{ minWidth: 212 }}
    />
  </Filter>
);

const filterIsEmpty = (props) => {
  const { filterValues } = props;
  const { emailAddress, legalName, tradeName, ...rest } = filterValues;
  if (!isEmpty(rest)) return false;
  return !emailAddress && !((legalName || '').length > 2) && !((tradeName || '').length > 2);
};

const ListBody = withStyles(listBodyStyles)((props) => {
  const { total, loading, classes } = props;
  if (loading) {
    return <div className={classes.content}>Search is in progress...</div>;
  } else if (filterIsEmpty(props)) {
    return <div className={classes.content}>To search for business set up the filter above</div>;
  } else if (total === 0) {
    return <div className={classes.content}>No results found</div>;
  } else {
    return (
      <Datagrid {...props} classes={undefined} rowClick='show' size='medium' selectedIds={null}>
        <FunctionField
          label={label('legalName')}
          render={(record) => [record.legalName, record.legalType].filter(Boolean).join(', ')}
          sortable={false}
        />
        <FunctionField
          label={label('tradeName')}
          render={(record) =>
            [record.tradeName, record.tradeNameLocalized].filter(Boolean).join(' / ')
          }
          sortable={false}
        />
        <TextField
          source='primaryDocument.number'
          label={label('primaryDocumentNumber')}
          sortable={false}
        />
        <ReferenceField
          source='solutionId'
          reference='solutions'
          link={false}
          label={label('solutionId')}
          sortable={false}
        >
          <ChipField source='name' />
        </ReferenceField>
        <KycLevelField source='kycLevel' label={label('kycLevel')} sortable={false} />
        <StatusField source='status' sortable={false} />
      </Datagrid>
    );
  }
});

const handleFilters = (filters) => {
  if (!filters) return {};
  return filters;
};

const ExportAction = () => {
  const { filterValues } = useListContext();
  return (
    <BackgroundTaskExport type='BUSINESS_EXPORT' queryArguments={handleFilters(filterValues)} />
  );
};

const BusinessList = (props) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('business.list')) return <NoPermissions />;
  return (
    <ListController {...props}>
      {(controllerProps) => {
        return (
          <ListContextProvider value={controllerProps}>
            <ListView
              filters={<BusinessFilter />}
              {...controllerProps}
              pagination={
                controllerProps.loading ||
                filterIsEmpty(controllerProps) ||
                controllerProps.total === 0
                  ? null
                  : undefined
              }
              bulkActionButtons={false}
              actions={
                <div style={{ minWidth: 240, marginTop: 'auto', textAlign: 'right' }}>
                  <BusinessCreateAction />
                  <ExportAction {...props} />
                </div>
              }
            >
              <ListBody />
            </ListView>
          </ListContextProvider>
        );
      }}
    </ListController>
  );
};

export default BusinessList;
