import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import EmployeeForm from './EmployeeForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { Business, Employee, Individual } from '~/types';
import { FormDataConsumer } from 'react-admin';

type FormData = {
  formData: Individual;
  [x: string]: any;
};

interface Props {
  employeeRecord: Employee;
  businessRecord: Business;
  closeParent: () => void;
  permissions: string[];
}

const EmployeeEdit = (props: Props) => {
  const { employeeRecord, businessRecord, closeParent, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeParent();
  };

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.employees.${key}`);

  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions.includes('business.employee.update')}
      />
      <Drawer
        heading={`${translateLabel('editEmployee')} "${employeeRecord.firstName || ''} ${
          employeeRecord.lastName || ''
        }"`}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource="employees" record={employeeRecord} closeParent={handleClose}>
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return <EmployeeForm businessRecord={businessRecord} scopedFormData={formData} />;
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default EmployeeEdit;
