import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import axios from '~/utils/axios';

export const getExternalProfileLinks = (profileId: string) => {
  return axios.get<ExternalProfileLink[]>(
    `/externalProfileLinks?page=1&perPage=999&profileId=${profileId}`
  );
};

export const deleteExternalProfileLink = (linkId: string) => {
  return axios.delete(`/externalProfileLinks/${linkId}`);
};

export const getExternalProfileLinkSystems = () => {
  return axios.get<string[]>(`/externalProfileLinks/systems`);
};
