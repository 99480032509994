import { useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { useQuery } from 'react-query';
import { getAccounts, getAccountsLimits, getProfileLimits } from '~/api/accounts';

export const useGetAllAccounts = (
  params: {
    resource: 'businesses' | 'individuals' | 'partners' | 'tenant/accounts';
    profileId?: string;
  },
  options: { enabled?: boolean } = {}
) => {
  const { resource, profileId } = params;

  const { permissions } = usePermissions();
  const hasAccess = useMemo((): boolean => {
    if (!permissions) return false;
    if (resource === 'businesses') {
      return permissions.includes('business.account.view');
    }
    if (resource === 'individuals') {
      return permissions.includes('individual.account.view');
    }
    if (resource === 'partners') {
      return permissions.includes('partner.account.view');
    }
    if (resource === 'tenant/accounts') {
      return permissions.includes('tenant.account.view');
    }
    return false;
  }, [permissions, resource]);

  return useQuery([resource, profileId, 'accounts'], () => getAccounts(resource, profileId!), {
    enabled: !!profileId && hasAccess,
    ...options,
  });
};

export const useGetProfileLimits = (profileId?: string, options?: { enabled?: boolean }) => {
  const { enabled = true } = options || {};
  return useQuery(['profiles', profileId, 'limits'], () => getProfileLimits(profileId!), {
    enabled: !!profileId && enabled,
  });
};

export const useGetAccountLimits = (accountId?: string, options?: { enabled?: boolean }) => {
  const { enabled = true } = options || {};
  return useQuery(['accounts', accountId, 'limits'], () => getAccountsLimits(accountId!), {
    enabled: !!accountId && enabled,
  });
};
