import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Column, Transaction } from '~/types';
import CopyButton from '~/components/CopyButton';

interface Props {
  children: Transaction;
  column: Column;
}

const RowOptionsButton = ({ children: rowData }: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <CopyButton
        value={
          rowData.headTransactionId || "It's seems that transaction hasn't 'headTransactionId'"
        }
        classes={classes}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 30,
    color: theme.palette.grey[600],
  },
  listItemText: {
    marginBottom: 0,
  },
  button: {
    padding: 3,
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: '1rem',
  },
}));

export default RowOptionsButton;
