import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Section } from '~/layout';
import { HeadTransaction } from '~/types/HeadTransaction';
import useStyles from './useStyles';
import ExternalSourceSection from './ExternalSourceSection';
import OriginatorSection from './OriginatorSection';
import ServiceSection from './ServiceSection';
import OtherParametersSection from './OtherParametersSection';
import ServiceChargeSection from './ServiceChargeSection';

export const makeLabel = (key: string) => `resources.headTransactions.show.context.${key}`;

const ContextTab: FC<any> = (props) => {
  const { record } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.context.${key}`);

  const classes = useStyles();

  const trx = record as HeadTransaction;

  if (!trx) return null;

  const serviceId = trx?.details.service?.id;

  return (
    <Section noPaddings className={classes.section}>
      <OriginatorSection trx={trx} />
      {serviceId && <ServiceSection serviceId={serviceId} trx={trx} />}
      {trx.details.serviceCharge && <ServiceChargeSection trx={trx} />}
      {trx.details.senderExternalSource && (
        <ExternalSourceSection
          externalSource={trx.details.senderExternalSource}
          label={t('senderExternalSource')}
        />
      )}
      {trx.details.recipientExternalSource && (
        <ExternalSourceSection
          externalSource={trx.details.recipientExternalSource}
          label={t('recipientExternalSource')}
        />
      )}
      <OtherParametersSection trx={trx} />
    </Section>
  );
};

export default ContextTab;
