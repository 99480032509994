import React, { Fragment, useEffect, useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Title, VirtualizedList } from '~/components';
import { RootState } from '~/types';
import {
  selectors,
  ResourceListType,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column } from '~/components/VirtualizedList';
import CustomCell from './components/CustomCell';
import Filters from './components/Filters';
import { NoPermissions } from '~/components/ra';
import { useTranslateBatchesList, resource } from '.';
import { invalidateEventEntities } from '~/hooks/eventEntities';
import { getBatches } from '~/api/batches';
import { Batch, BatchFilters } from '~/types/Batch';

interface Props {
  history: any;
}

const BatchesList = (props: Props) => {
  const { history } = props;
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const { data, hasNextPage, isNextPageLoading } = useSelector<RootState, ResourceListType<Batch>>(
    selectors.getList(resource)
  );
  const filters = useSelector(selectors.getFilters<BatchFilters>(resource));
  const { cursors } = useSelector(selectors.getList(resource));

  const t = useTranslateBatchesList();

  const firstEntering = history.action !== 'POP';
  useEffect(() => {
    if (firstEntering) invalidateEventEntities();
  }, [firstEntering]);

  const loadNextPage = async () => {
    if (isNextPageLoading) return;
    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } = await getBatches({
        next: cursors.next || undefined,
        limit: 30,
        ...filters,
      });
      dispatch(
        getVirtualizedListSuccess({
          resource,
          responseData: { cursors: data.cursors, records: data.payrolls },
          queryToken,
        })
      );
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const columns = useMemo<Column<Batch>[]>(
    () => [
      {
        selectCellData: (rowData) => rowData.created,
        cellDataWrapperComponent: CustomCell,
        id: 'created',
        header: t('timestamp'),
        style: {
          flexBasis: 100,
          minWidth: 100,
          maxWidth: 150,
        },
      },
      {
        selectCellData: (rowData) => rowData.data?.description,
        cellDataWrapperComponent: CustomCell,
        id: 'description',
        header: t('type'),
        style: {
          flexBasis: 300,
          minWidth: 300,
        },
      },
      {
        selectCellData: (rowData) => rowData?.actorId,
        id: 'actor',
        cellDataWrapperComponent: CustomCell,
        header: t('actor'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 250,
        },
      },
      {
        selectCellData: (rowData) => rowData?.objectId,
        cellDataWrapperComponent: CustomCell,
        id: 'object',
        header: t('object'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 250,
        },
      },
      {
        selectCellData: (rowData) => rowData.recordsTotal,
        cellDataWrapperComponent: CustomCell,
        id: 'numberOfRecords',
        header: t('numberOfRecords'),
        style: {
          flexBasis: 100,
          minWidth: 50,
          maxWidth: 100,
        },
      },
      {
        selectCellData: (rowData) => rowData.data.instructedAmount,
        cellDataWrapperComponent: CustomCell,
        headerAlign: 'right',
        cellAlign: 'right',
        id: 'instructedAmount',
        header: t('totalAmount'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 180,
        },
      },
      {
        selectCellData: (rowData) => rowData.status,
        cellDataWrapperComponent: CustomCell,
        id: 'status',
        header: t('status'),
        style: {
          flexBasis: 150,
          minWidth: 100,
          maxWidth: 180,
        },
      },
    ],
    [t]
  );

  const getRowLink = (data: Batch) => {
    return `/batches/${data.id}/show`;
  };

  if (!permissions) return null;
  if (!permissions.includes('batchPayment.list')) return <NoPermissions />;

  return (
    <Fragment>
      <Title resource={resource} />
      <Filters />
      <Paper style={fixBottomShadow}>
        <VirtualizedList<Batch>
          resource={resource}
          columns={columns}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          filters={filters}
          data={data}
          loadNextPage={loadNextPage}
          outterTable
          rowHeight={48}
          emptyText={t('empty')}
          getRowLink={getRowLink}
        />
      </Paper>
    </Fragment>
  );
};

const fixBottomShadow = { paddingBottom: 2 };

export default BatchesList;
