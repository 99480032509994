import { Identity } from '~/types';
import axios from '~/utils/axios';

export const getCurrentUser = () => {
  return axios
    .get<{
      identity: Identity;
      role: {
        id: string;
        name: string;
        created: string;
        deleted: false;
        dependentRoles: string[];
        permissions: string[];
        readonly: boolean;
      };
    }>('user')
    .then((res) => res.data);
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  return axios.post('/user/password', {
    oldPassword,
    newPassword,
  });
};

export const checkAccessToken = () => {
  return axios.get('user/check');
};
