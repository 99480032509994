import axios from '~/utils/axios';

export interface Note {
  referenceId: string;
  text: string;
  createdAt?: string;
  createdBy?: string;
  creatorName?: string;
  updatedAt?: string;
  updatedBy?: string;
  updatedName?: string;
  id?: string;
}

export type NotesProfileType = 'BUSINESS' | 'INDIVIDUAL' | 'PARTNER';

export const getNotesReq = (referenceId: string, profileType: NotesProfileType) => {
  return axios.get<Note[]>(`/notes`, { params: { referenceId, profileType } });
};

export const createNoteReq = (note: Note, referenceId: string, profileType: NotesProfileType) => {
  return axios.post<Note>(`/notes?referenceId=${referenceId}&profileType=${profileType}`, {
    ...note,
  });
};

export const updateNoteReq = (note: Note, referenceId: string, profileType: NotesProfileType) => {
  const id = note.id;
  return axios.put<Note>(`/notes/${id}?referenceId=${referenceId}&profileType=${profileType}`, {
    ...note,
  });
};

export const deleteNoteReq = (id: string, referenceId: string, profileType: NotesProfileType) => {
  return axios.delete<void>(`/notes/${id}?referenceId=${referenceId}&profileType=${profileType}`);
};
