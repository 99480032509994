import React, { useState, Fragment, FC } from 'react';
import { useTranslate, TextInput } from 'react-admin';
import { Card } from '~/types/Card';

import { Drawer, EditButton } from '~/components';
import CreateAndEditForm from '../CreateAndEditForm';
import { l } from './index';

interface CardEditProps {
  refetch?: () => void;
  record: Card | undefined;
}

const CardEdit: FC<CardEditProps> = (props) => {
  const { refetch, record } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <EditButton onClick={handleOpen} />
      <Drawer heading={t('editCardDetails')} open={open} onClose={handleClose}>
        <CreateAndEditForm
          type="update"
          resource="cards"
          record={record}
          closeParent={handleClose}
          updateAfterSubmit={refetch}
        >
          <Fragment>
            <TextInput source="alias" label={t('alias')} />
          </Fragment>
        </CreateAndEditForm>
      </Drawer>
    </Fragment>
  );
};

export default CardEdit;
